import React from 'react';
import { map } from 'lodash';
import ArrowBtnMobile from '../buttons/ArrowBtnMobile';

const MobileList = (props) => {
  const { listOptions = [], className = '' } = props;

  return (
    <div className={`d-flex flex-column ${className}`} style={{ paddingLeft: '16px', paddingRight: '16px' }}>
      {map(listOptions, (option, i) => (
        <ArrowBtnMobile
          key={i}
          label={option.label}
          icon="icon-ml-back"
          to={option.to}
          onClick={option.onClick}
        />
      ))}
    </div>
  );
};

export default MobileList;

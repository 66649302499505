import React from 'react';
import PropTypes from 'prop-types';
import { Card as AntdCard } from 'antd';
import '../../../static/styles/shared/cards/card.less';

const Card = (props) => {
  const { classes, children } = props;
  return (
    <AntdCard data-testid='card-details' className={`card-details card-evaluation ${classes}`}>
      {children}
    </AntdCard>
  );
};

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array
  ]),
  classes: PropTypes.string
};

Card.defaultProps = {
  children: <></>,
  classes: ''
};

export default Card;

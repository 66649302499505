/* eslint-disable no-shadow */
import React from 'react';
import {
  Button
} from 'antd';
import { sumBy, find } from 'lodash';

import InfoMessage from '../../../shared/InfoMessage';
import { completePath } from '../../../../helpers/strings';

const emoticons = [
  {
    numberEmoticon: 1,
    min: 0,
    max: 1.5
  },
  {
    numberEmoticon: 2,
    min: 1.6,
    max: 2.5
  },
  {
    numberEmoticon: 3,
    min: 2.6,
    max: 3.5
  },
  {
    numberEmoticon: 4,
    min: 3.6,
    max: 4.5
  },
  {
    numberEmoticon: 5,
    min: 4.6,
    max: 5
  }

];
const GeneralResult = (props) => {
  const {
    behaviors,
    skillSelected, messages, handleEditTest, getActions, loading
  } = props;

  const calculateAverageEmoticon = () => {
    const sum = sumBy(behaviors, (behavior) => behavior.choice);
    const average = sum / behaviors.length;
    const emoticon = find(emoticons, (item) => average >= item.min && average <= item.max);

    return emoticon ? emoticon.numberEmoticon : null;
  };

  const renderAverageEmoticon = () => {
    const emoticon = calculateAverageEmoticon();
    if (emoticon) {
      return (
        <div className='container-emoticons'>
          {messages.map((message, indexEmoticon) => (
            <div key={indexEmoticon}
            >
              <img
                className="img-fluid"
                src={completePath(
                  `web/mi-evolucion/servicios/inicio/emoticones-evolucionaTEST/${indexEmoticon + 1}${emoticon !== indexEmoticon + 1 ? '_gris' : ''}.png`
                )} alt=""
              />
            </div>
          ))
          }
        </div>
      );
    }
    return null;
  };
  return (
    <div>
      <InfoMessage message='Acá puedes ver el resultado de tus respuestas y editarlas si lo necesitas.' />
      <div className='card-general-result '>
        <div className='container-title'>
          <p>Resultado General</p>
        </div>
        <p className='text-skill'>{skillSelected.name}</p>
        <p className='text-description'>{skillSelected.description}</p>
        {renderAverageEmoticon()}
      </div>
      <div className='card-general-result '>
        <div className='container-title'>
          <p>Resultado por Conducta</p>
        </div>
        {
          behaviors.map((data, indexBehavior) => (
            <div key={indexBehavior} className='d-flex flex-column align-items-center' >
              <p className='text-behavior'>{`Conducta Nº${indexBehavior + 1}`}</p>
              <p className='text-description'>{data.name}</p>
              <div className='container-emoticons'>
                {messages.map((message, indexEmoticon) => (
                  <div key={indexEmoticon}
                  >
                    <img
                      className="img-fluid"
                      src={completePath(
                        `web/mi-evolucion/servicios/inicio/emoticones-evolucionaTEST/${indexEmoticon + 1}${data.choice !== indexEmoticon + 1 ? '_gris' : ''}.png`
                      )} alt=""
                    />
                  </div>
                ))
                }
              </div>
            </div>
          ))}
      </div>
      <div className='container-buttons-general-result'>
        <div className='d-flex justify-content-center'>
          <Button
            className='button-border-primary'
            onClick={handleEditTest}
          >
            Editar TEST
          </Button>
        </div>
        <div className='d-flex justify-content-center'>
          <Button
            loading={loading}
            type='primary'
            onClick={getActions}
          >
          Siguiente
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GeneralResult;

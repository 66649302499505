import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import Label from '../label';
import SkillsSelected from './SkillsSelected';
import '../../../../../../static/styles/mievolucion/myPerformance/feedback/createFeedback/skills.less';
import { MAX_SKILLS } from '../../../../../../helpers/myEvolution/feedback';

const Skills = (props) => {
  const {
    setModalOptions,
    feedbackType, listSkills, skillsSelected, setSkillsSelected
  } = props;

  const selectSkills = (value) => {
    const mySkillSelected = new Map();

    skillsSelected.forEach((skill) => {
      mySkillSelected.set(skill._id, { ...skill });
    });

    if (mySkillSelected.has(value._id)) {
      mySkillSelected.delete(value._id);
    } else {
      mySkillSelected.set(value._id, { ...value, content: '', otherSkillName: '' });
    }

    setSkillsSelected(
      mySkillSelected
    );
  };
  return (
    <div className='container-skills'>
      <Label label={`Seleccionar una o más competencias para ${feedbackType === 'Given' ? 'dar ' : 'pedir'} feedback`}/>
      <div className="d-flex flex-wrap">
        { map(listSkills, (skill, i) => (
          <div
            onClick={ (e) => {
              e.preventDefault();
              if (!skillsSelected.has(skill._id) && skillsSelected.size === MAX_SKILLS) {
                setModalOptions({
                  visible: true,
                  title: `No puedes escoger mas de ${MAX_SKILLS} competencias`,
                  type: 'error'
                });
              } else {
                selectSkills(skill);
              }
            }}
            className={skillsSelected.has(skill._id)
              ? 'skill-active'
              : 'skill-inactive'}
            key={i} >
            <div className='d-flex align-items-center' >
              <i className={ `${skillsSelected.has(skill._id)
                ? 'icon-me-competency-check'
                : 'icon-me-competency-add'} pr-2`}/>
              <p className="mb-0"> { skill.name}</p>
            </div>

          </div>
        ))}
      </div>
      <SkillsSelected
        skillsSelected={skillsSelected}
        feedbackType={feedbackType}
        selectSkills={selectSkills}
        setSkillsSelected={setSkillsSelected}/>

    </div>

  );
};

Skills.propTypes = {
  setModalOptions: PropTypes.func,
  type: PropTypes.oneOf(['Given', 'Requested']),
  listSkills: PropTypes.array,
  skillsSelected: PropTypes.object,
  setSkillsSelected: PropTypes.func
};

Skills.defaultProps = {
  setModalOptions: () => {},
  feedbackType: 'Given',
  listSkills: [],
  skillsSelected: {},
  setSkillsSelected: () => {}
};

export default Skills;

import { lazy } from 'react';

const MyEvolutionTeamEvaluationsFaq = lazy(() => import('../../../../../containers/myTeam/myEvolution/Evaluations/Faq'));

export default [
  {
    exact: true,
    path: '/mi-equipo/mi-evolucion/evaluaciones/preguntas-frecuentes',
    section: 'Mi Equipo - Mi Evolución - Evaluaciones - Preguntas frecuentes',
    component: MyEvolutionTeamEvaluationsFaq
  }
];

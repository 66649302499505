import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  Select, Form, Input, Radio
} from 'antd';
import { map, isEmpty } from 'lodash';
import {
  WITHOUTINFO, DEFAULT_PLATFORMS, COMMON_VARIABLES_LIST
} from '../../../../helpers/myEvolution/interestsAndSkills';
import LastPositions from './LastPositions';
import MLError from '../../../shared/Error';
import Loader from '../../../Loader';
import { Item, Button } from '../Form';
import {
  FIND_WORK_EXPERIENCE_AREA
} from '../../../../graphql/mievolucion/interestsAndSkills/queries';
import { MY_EVOLUTION_CONTEXT } from '../../../../helpers/apollo';

const context = MY_EVOLUTION_CONTEXT;

const { Option } = Select;

const WorkExperience = (props) => {
  const {
    form: {
      getFieldDecorator
    },
    updateData,
    loadingUpdate,
    yearsExperience: initialYearsExperience = WITHOUTINFO,
    previousWorkExperienceWalmart: initialPreviousWorkExperienceWalmart,
    ledTeamBefore: initialLedTeamBefore,
    urlLinks: initialLinkedin,
    ledTeams: { ledTeamMax: initialLedTeamMax },
    lastPositions: initialLastPositions
  } = props;

  const { listYearExperiences = [], listPersonsLead = [] } = props;

  const [yearsExperience, setYearsExperience] = useState(
    isEmpty(initialYearsExperience) ? WITHOUTINFO : initialYearsExperience
  );
  const [previousWorkExperienceWalmart, setPreviousWorkExperienceWalmart] = useState(
    initialPreviousWorkExperienceWalmart
  );
  const [ledTeamBefore, setLedTeamBefore] = useState(
    initialLedTeamBefore
  );
  const [linkedin, setLinkedin] = useState(
    !isEmpty(initialLinkedin) ? initialLinkedin : ''
  );
  const [ledTeamMax, setLedTeamMax] = useState(
    isEmpty(initialLedTeamMax) ? WITHOUTINFO : initialLedTeamMax
  );

  const [lastPositions, setLastPositions] = useState(
    isEmpty(initialLastPositions) ? DEFAULT_PLATFORMS : initialLastPositions
  );

  const {
    loading: loadingListAreas,
    error: errorListAreas,
    data: areasData,
    refetch: refetchListAreas
  } = useQuery(FIND_WORK_EXPERIENCE_AREA, {
    variables: COMMON_VARIABLES_LIST,
    context,
    fetchPolicy: 'network-only'
  });
  const { listOptionsWorkExperienceArea } = areasData || {};

  const onChangePreviousWorkExperienceWalmart = ({ target }) => {
    setPreviousWorkExperienceWalmart(target.value);
  };

  const onChangeLedTeamBefore = ({ target }) => {
    setLedTeamBefore(target.value);
  };
  const saveData = (e) => {
    e.preventDefault();
    const input = {
      ledTeamBefore,
      previousWorkExperienceWalmart,
      yearsExperience,
      urlLinks: linkedin,
      ledTeams: { ledTeamMax: ledTeamMax || WITHOUTINFO },
      lastPositions: []
    };
    if (previousWorkExperienceWalmart === 'YES' && !isEmpty(lastPositions)) {
      const validPositions = [];
      map(lastPositions, (item) => {
        const { area } = item;
        if (!isEmpty(item?.area?._id)) {
          validPositions.push(
            {
              area: area._id,
              company: item.company,
              duration: item.duration,
              period: {
                dateFinish: item.period.dateFinish,
                dateInit: item.period.dateInit
              },
              positionName: item.positionName
            }
          );
        }
      });
      input.lastPositions = validPositions;
    }
    updateData({ variables: { input } });
  };
  if (loadingListAreas) {
    return <Loader />;
  }

  if (errorListAreas) {
    return (<MLError
      reload={() => refetchListAreas({ variables: COMMON_VARIABLES_LIST })}
    />);
  }
  return (
    <>
      <Form onSubmit={saveData}>
        <div className='row'>
          <Item
            label='¿Cuántos años de experiencia laboral tienes?'
            className='col-12 col-md-12 col-lg-6'
          >
            <Select placeholder='Selecciona una opción'
              { ...(initialYearsExperience !== WITHOUTINFO
                ? { defaultValue: initialYearsExperience } : null)}
              onChange={setYearsExperience}
            >
              {map(listYearExperiences, (item, i) => (
                <Option key={i} value={item.codeValue}>{item.name}</Option>
              ))}
            </Select>
          </Item>
        </div>
        <div className='row'>
          <Item
            label='¿Tienes experiencia laboral previa a Walmart?'
            className='col-12 col-md-12 col-lg-6'
          >
            <Radio.Group
              className='ml-radio-button'
              defaultValue={previousWorkExperienceWalmart}
              onChange={onChangePreviousWorkExperienceWalmart} >
              <Radio value={'YES'}>
              Si
              </Radio>
              <Radio value={'NO'}>
              No
              </Radio>
            </Radio.Group>
          </Item>
        </div>

        {
          previousWorkExperienceWalmart === 'YES'
            ? <LastPositions
              {
                ...{
                  getFieldDecorator,
                  lastPositions,
                  setLastPositions,
                  listYearExperiences,
                  listOptionsWorkExperienceArea
                }
              }
            />
            : null
        }
        <div className='row'>
          <Item
            label='¿Has liderado a otros colaboradores de manera directa, en proyectos y/o mesas de trabajo dentro o fuera de la compañía?'
            className='col-12 col-md-12 col-lg-6'
          >
            <Radio.Group
              className='ml-radio-button'
              defaultValue={ledTeamBefore}
              onChange={onChangeLedTeamBefore} >
              <Radio value={'YES'}>
              Si
              </Radio>
              <Radio value={'NO'}>
              No
              </Radio>
            </Radio.Group>
          </Item>
        </div>
        <div className='row'>
          {
            ledTeamBefore === 'YES'
              ? <Item
                label='¿Cuánto ha sido el máximo de personas que has liderado?'
                className='col-12 col-md-12 col-lg-6'
              >
                <Select placeholder='Elige una opción'
                  { ...(initialLedTeamMax !== WITHOUTINFO
                    ? { defaultValue: initialLedTeamMax } : null)}
                  onChange={setLedTeamMax}
                >
                  {map(listPersonsLead, (item, i) => (
                    <Option key={i} value={item.codeValue}>{item.name}</Option>
                  ))}
                </Select>
              </Item>
              : null
          }
        </div>
        <div className='row'>

          <Item
            label='URL Linkedin:'
            className='col-12'
          >
            <Input
              value={linkedin}
              onChange={({ target }) => {
                setLinkedin(target.value);
              }}
              placeholder='Ej: https://www.linkedin.com/in/nombre-apellido' />

          </Item>
        </div>
        <Button
          loading={loadingUpdate}
          label='Guardar mi experiencia laboral'
        />
      </Form>
    </>
  );
};

WorkExperience.propTypes = {
  form: PropTypes.object,
  loadingUpdate: PropTypes.bool,
  updateData: PropTypes.func,
  yearsExperience: PropTypes.string,
  previousWorkExperienceWalmart: PropTypes.string,
  ledTeamBefore: PropTypes.string,
  urlLinks: PropTypes.string,
  ledTeams: PropTypes.object,
  lastPositions: PropTypes.array
};

WorkExperience.defaultProps = {
  form: () => {},
  loadingUpdate: false,
  updateData: () => {},
  yearsExperience: WITHOUTINFO,
  previousWorkExperienceWalmart: WITHOUTINFO,
  ledTeamBefore: WITHOUTINFO,
  urlLinks: '',
  ledTeam: {},
  lastPositions: []
};
export default WorkExperience;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { isEmpty, get } from 'lodash';

import {
  getFromUserAPI,
  addIndividualAPI,
  getSACategoriesAPI,
  addRegisterAPI,
  addAdvisoryAPI,
  addReviewAPI,
  closeCaseAPI,
  getLocalInfoAPI,
  addGroupRegisterAPI,
  deleteUserGroupAttentionAPI,
  editGroupRegisterAPI
} from '../api/socialAssistant';
import {
  actionTypes,
  getSAUserSuccess,
  addIndividualAttentionSuccess,
  getSACategoriesSuccess,
  addAdvisorySuccess,
  addRegisterSuccess,
  changeWizardStep,
  resetForm,
  addReviewSuccess,
  getLocalInfoSuccess,
  closeCase,
  closeCaseSuccess,
  getUserFromGrupalSuccess,
  addGroupRegisterSuccess
} from '../actions/socialAssistant';
import { failure, loading, loadComplete } from '../actions/commons';

es6promise.polyfill();

function* getSAUser(action) {
  try {
    yield put(loading());
    const userInfo = yield call(getFromUserAPI, action.payload);
    const { socialAttention } = userInfo;
    yield put(getSAUserSuccess(userInfo));
    if (!isEmpty(socialAttention)) {
      yield put(changeWizardStep(4));
    } else {
      yield put(changeWizardStep(1));
    }
    yield put(resetForm());
    yield put(loadComplete());
  } catch (err) {
    const data = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error: { error: true, message: data }, statusCode }));
    yield put(loadComplete());
  }
}

function* addRegister(action) {
  try {
    yield put(loading());
    const resp = yield call(addRegisterAPI, action.payload.id, action.payload.register);
    yield put(addRegisterSuccess(resp));
    yield put(loadComplete());
  } catch (err) {
    const statusCode = get(err, 'response.status');
    yield put(failure({ error: { error: true, message: 'Se ha producido un error' }, statusCode }));

    yield put(loadComplete());
  }
}

function* addAdvisory(action) {
  try {
    yield put(loading());
    const resp = yield call(addAdvisoryAPI, action.payload.id, action.payload.advisory);
    yield put(addAdvisorySuccess(resp));
    yield put(loadComplete());
  } catch (err) {
    const statusCode = get(err, 'response.status');
    yield put(failure({ error: { error: true, message: 'Se ha producido un error' }, statusCode }));
    yield put(loadComplete());
  }
}

function* addIndividual(action) {
  try {
    yield put(loading());
    const resp = yield call(addIndividualAPI, action.payload.rut, action.payload.simpleAttention);
    yield put(addIndividualAttentionSuccess(resp));
    yield put(loadComplete());
  } catch (err) {
    const statusCode = get(err, 'response.status');
    yield put(failure({ error: { error: true, message: 'Se ha producido un error' }, statusCode }));
    yield put(loadComplete());
  }
}

function* getSACategories(action) {
  try {
    yield put(loading());
    const resp = yield call(getSACategoriesAPI, action.payload);
    yield put(getSACategoriesSuccess(resp, action.payload));
    yield put(loadComplete());
  } catch (err) {
    const statusCode = get(err, 'response.status');
    yield put(failure({ error: { error: true, message: 'Se ha producido un error' }, statusCode }));
    yield put(loadComplete());
  }
}

function* addReview(action) {
  try {
    yield put(loading());
    if (action.payload.text) {
      const respReview = yield call(addReviewAPI, action.payload);
      yield put(addReviewSuccess(respReview));
    }
    if (action.payload.recordStatus !== null && !action.payload.recordStatus) {
      yield put(closeCase());
      const respClosed = yield call(closeCaseAPI, action.payload);
      yield put(closeCaseSuccess(respClosed));
    }
    yield put(loadComplete());
  } catch (err) {
    const statusCode = get(err, 'response.status');
    yield put(failure({ error: { error: true, message: 'Se ha producido un error' }, statusCode }));
    yield put(loadComplete());
  }
}

function* getLocalInfo(action) {
  try {
    yield put(loading());
    const resp = yield call(getLocalInfoAPI, action.payload);
    yield put(getLocalInfoSuccess(resp));
    yield put(loadComplete());
  } catch (err) {
    const data = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error: { error: true, message: data }, statusCode }));

    yield put(loadComplete());
  }
}

function* getUserFromGrupal(action) {
  try {
    yield put(loading());
    const userInfo = yield call(getFromUserAPI, action.payload);
    yield put(getUserFromGrupalSuccess({
      name: userInfo.fullName,
      fatherLastName: userInfo.fullName,
      position: userInfo.function,
      localNumber: userInfo.localNumber,
      rut: userInfo.rut
    }));
    yield put(loadComplete());
  } catch (err) {
    const statusCode = get(err, 'response.status');
    yield put(failure({ error: { error: true, message: 'Se ha producido un error' }, statusCode }));
    yield put(loadComplete());
  }
}

function* addGroupRegister(action) {
  try {
    yield put(loading());
    yield call(addGroupRegisterAPI, action.payload);
    yield put(addGroupRegisterSuccess());
    yield put(loadComplete());
  } catch (err) {
    const statusCode = get(err, 'response.status');
    yield put(failure({ error: { error: true, message: 'Se ha producido un error' }, statusCode }));
    yield put(loadComplete());
  }
}

function* deleteUserGroupAttention(action) {
  try {
    yield put(loading());
    yield call(deleteUserGroupAttentionAPI, action.payload);
    const userInfo = yield call(getFromUserAPI, {
      rut: action.payload.rut,
      basicInfo: false
    });
    yield put(getSAUserSuccess(userInfo));
    yield put(loadComplete());
  } catch (err) {
    const statusCode = get(err, 'response.status');
    yield put(failure({ error: { error: true, message: 'Se ha producido un error' }, statusCode }));
    yield put(loadComplete());
  }
}

function* editGroupRegister(action) {
  try {
    yield put(loading());
    const { activityId, rut, ...body } = action.payload;
    yield call(editGroupRegisterAPI, {
      activityId: action.payload.activityId,
      body
    });
    const userInfo = yield call(getFromUserAPI, {
      rut: action.payload.rut,
      basicInfo: false
    });
    yield put(getSAUserSuccess(userInfo));
  } catch (err) {
    const statusCode = get(err, 'response.status');
    yield put(failure({ error: { error: true, message: 'Se ha producido un error' }, statusCode }));
    yield put(loadComplete());
  }
}

function* socialAssistantSagas() {
  yield all([
    takeLatest(actionTypes.GET_SA_USER, getSAUser),
    takeLatest(actionTypes.ADD_INDIVIDUAL, addIndividual),
    takeLatest(actionTypes.GET_SA_CATEGORIES, getSACategories),
    takeLatest(actionTypes.ADD_REGISTER, addRegister),
    takeLatest(actionTypes.ADD_ADVISORY, addAdvisory),
    takeLatest(actionTypes.ADD_REVIEW, addReview),
    takeLatest(actionTypes.GET_LOCAL_INFO, getLocalInfo),
    takeLatest(actionTypes.GET_USER_FROM_GRUPAL, getUserFromGrupal),
    takeLatest(actionTypes.ADD_SA_GROUP_REGISTER, addGroupRegister),
    takeLatest(actionTypes.DELETE_USER_GROUP_ATTENTION, deleteUserGroupAttention),
    takeLatest(actionTypes.EDIT_GROUP_REGISTER, editGroupRegister)
  ]);
}

export default socialAssistantSagas;

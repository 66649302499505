import { gql } from '@apollo/client';

import CommunicationPage from './fragments';

export const GET_BOSS_LETTER_LIST = gql`
  query bossLetterList($type: String!) {
    bossLetterList(type: $type) {
      title
      type
      department
      letters {
        ...CommunicationPageLetterItem
        data {
          ...CommunicationPageLetterItemData
        }
      }
    }
  }
  ${CommunicationPage.fragments.letterItem}
  ${CommunicationPage.fragments.letterItemData}
`;

export const GET_USER_MERIT_LETTER_LIST = gql`
  query {
    userMeritLetterList {
      letters {
        letter {
          title
        }
        _id
        sent
        status
        file
        data {
          incrementPercentage
          notElegiblePhrase
        }
      }
      message
    }
  }
`;

export const GET_TEAM_COMPENSATION = gql`
  query myTeamCompensation {
    myTeamCompensation {
      rut
      fullName
      position
      positionOld
      dateOld
      talentCategory
      performanceCategory
      history {
        positioning
        band
        salary
        validDueDate
        reason
      }
    }
  }
`;

export const GET_USER_LETTER = gql`
  query adminLetterSearch($rut: String!) {
    adminLetterSearch(rut: $rut) {
      _id
      user {
        rut
        name
        secondName
        fatherLastName
        motherLastName
      }
      sent
      status
      file
      createdAt
      updatedAt
      letter {
        _id
        title
        createdAt
        updatedAt
        type
        department
      }
      data {
        ...CommunicationPageLetterItemData
      }
      evaluator
    }
  }
  ${CommunicationPage.fragments.letterItemData}
`;

export const GET_MY_SALARY = gql`
  query mySalary {
    mySalary {
      current {
        band
        positioning
        salary
        variation
        reason
        validDueDate
      }
      history {
        band
        positioning
        salary
        variation
        reason
        validDueDate
      }
    }
  }
`;

export const GET_PAYROLL_STORAGE_OR_SAP = gql`
  query getPayRollStorageOrSAP($countMonth: Int!) {
    getPayRollStorageOrSAP(countMonth: $countMonth) {
      success
      validUrls {
        key
        fileName
        url
        monthYear
        yearMonth
        message
      }

      invalidUrls {
        key
        fileName
        url
        monthYear
        yearMonth
        message
      }
      message
    }
  }
`;

export const SEND_MAIL_PAYROLL = gql`
  query sendMailPayRoll($countMonth: Int!) {
    sendMailPayRoll(countMonth: $countMonth)
  }
`;

export const GET_MY_BONO_VARIABLE = gql`
  query getVariableBonus($period: String) {
    getVariableBonus(period: $period) {
      success
      message
      variableBonus {
        code
        name
        payMonth
        companyName
        staffDivision
        indicators {
          name
          fulfillment
          payout
          description
          label
        }
        detail {
          field
          value
          label
        }
      }
    }
  }
`;

export const GET_MY_BONO_VARIABLE_ADMIN_QUERY = gql`
  query getVariableBonusAdmin($rut: String!, $period: String) {
    getVariableBonusAdmin(rut: $rut, period: $period) {
      success
      message
      variableBonus {
        code
        name
        payMonth
        companyName
        staffDivision
        indicators {
          name
          fulfillment
          payout
          description
          label
        }
        detail {
          field
          value
          label
        }
      }
    }
  }
`;

export const GET_SHOW_INTRODUCTION = gql`
  query getIntroduction($introductionName: IntroductionTypes!) {
    getIntroduction(introductionName: $introductionName)
  }
`;

export const GET_HISTORY_VARIABLE_BONUS = gql`
  query getHistoryVariableBonus {
    getHistoryVariableBonus {
      periods {
        label
        value
      }
    }
  }
`;

export const GET_HISTORY_VARIABLE_BONUS_ADMIN_QUERY = gql`
  query getHistoryVariableBonusAdmin($rut: String!) {
    getHistoryVariableBonusAdmin(rut: $rut) {
      periods {
        label
        value
      }
    }
  }
`;

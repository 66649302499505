import React, { Fragment, useEffect, useState } from 'react';
import {
  isMobile, isBrowser, osName
} from 'react-device-detect';
import { useSelector } from 'react-redux';
import LoginForm from '../components/login/LoginForm';
import MobileScreen from '../components/MobileScreen';
import '../static/styles/index.less';
import '../static/styles/login.less';
import '../static/styles/mio/benefitHeader.less';
import { isPWAInstalled, checkingIsAppIsIstalled, getEnvVar } from '../helpers/commons';
import { useWindowHeightSize } from '../helpers/browser';
import { logPageView, initGA } from '../helpers/googleAnalytics';
import { completePath } from '../helpers/strings';
import '../static/styles/login/loginForm/styles.less';
import Loading from './loading';
import LoginHelp from '../components/login/LoginHelp';

const REACT_APP_GA_TRACKING_CODE = getEnvVar('REACT_APP_GA_TRACKING_CODE');

const Page = () => {
  const [mobile, setMobile] = useState(false);

  const [appAndroidInstalled, setAppAndroidInstalled] = useState(false);
  const height = useWindowHeightSize();
  const
    loading = useSelector((state) => state.userReducer.loading);

  const renderContent = () => {
    if (isMobile) {
      if (osName === 'Android' || osName === 'iOS') {
        setMobile(true);
      } else {
        setMobile(false);
      }
    } else if (isBrowser) {
      setMobile(false);
    }
  };

  const webAccess = () => {
    setMobile(false);
  };

  useEffect(() => {
    if (mobile) {
      checkingIsAppIsIstalled(setAppAndroidInstalled);
    }
  }, [mobile]);

  useEffect(() => {
    document.title = 'Login - Mi Lugar';
    renderContent();
    initGA(REACT_APP_GA_TRACKING_CODE);
    logPageView(window.location.pathname);
  }, []);
  const isAppInstalled = mobile && (isPWAInstalled() || appAndroidInstalled);

  if (loading) return <Loading />;

  return (
    <Fragment>

      {
        !mobile || isAppInstalled
          ? (
            <div className='container-login'>
              <div className='container-bg-login '>
                <div
                  className='bg-login'
                  style={{
                    minHeight: height,
                    backgroundImage: `url(${completePath('web/milugar/bg/bg-login.jpg')})`
                  }}
                />
              </div>
              <div className="login">
                <LoginForm isAppInstalled={isAppInstalled} />
                <LoginHelp/>
              </div>
            </div>
          )
          : !isAppInstalled && (
            <MobileScreen
              logo={completePath('web/milugar/logos/logo-mi-lugar.svg')}
              webAccess={webAccess}
            />
          )

      }
    </Fragment>
  );
};

export default Page;

/* eslint-disable no-nested-ternary */
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Modal } from 'reactstrap';
import TransparentCard from '../../../../../shared/cards/TransparentCard';
import Stepper from '../../../../../shared/MLStep';
import Message from '../../../../../shared/InfoMessage';
import CollapsableRowTable from '../../../../../shared/tables/CollapsableRowTable/index';
import CollapsablePanel from '../../../../../shared/CollapsablePanel';
import Button from '../../../../../shared/buttons/MainBtn';
import '../../../../../../static/styles/mievolucion/myPerformance/new-evaluation.less';
import ResumeForm from './steps/ResumeForm';
import createStepperData from './createStepperData';
import {
  UPDATE_EDD,
  UPDATE_EDD_STATUS
} from '../../../../../../graphql/mievolucion/performanceEvaluation/mutations';
import { MY_EVOLUTION_CONTEXT } from '../../../../../../helpers/apollo';
import {
  EVALUATED,
  PENDING,
  COMMUNICATED,
  REJECTED
} from '../../../../../../helpers/myEvolution/evaluations';
import { capitalizeText } from '../../../../../../helpers/commons';
import MLLoading from '../../../../../shared/MLLoading';
import { smallDevice } from '../../../../../../helpers/deviceVariables';
import { useWindowWidthSize } from '../../../../../../helpers/browser';
import BarResumeChart from './steps/ResumeGraph';

const SHOW_CHART = false;

const omitTypeName = (object) => {
  const filteredObject = {};

  Object.keys(object).forEach((key) => {
    if (key === '__typename') return;

    const value = object[key];

    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      filteredObject[key] = omitTypeName(value);
    } else if (Array.isArray(value)) {
      filteredObject[key] = value.map((x) => {
        if (typeof x === 'object' && !Array.isArray(x) && x !== null) {
          return omitTypeName(x);
        }
        return x;
      });
    } else {
      filteredObject[key] = value;
    }
  });
  return filteredObject;
};

const elementsCompleted = (elements) => elements.every((element) => element.categorySelected?.name);

const PerformanceReviewModal = ({
  evaluation,
  categories,
  loading,
  onModalClose,
  startModalClose,
  isAutoevaluation,
  refetchQueries,
  currentStep,
  autoevaluation,
  reload
}) => {
  const width = useWindowWidthSize();
  const isMobile = width <= smallDevice;
  const [updateEDD, { loading: updateLoading }] = useMutation(UPDATE_EDD, {
    context: MY_EVOLUTION_CONTEXT
  });
  const [updateEDDStatus, { loading: statusLoading }] = useMutation(
    UPDATE_EDD_STATUS,
    {
      context: MY_EVOLUTION_CONTEXT
    }
  );

  const [step, setStep] = useState(currentStep);
  const [skills, setSkills] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [finalResults, setFinalResults] = useState({});
  const [resumeForm, setResumeForm] = useState({
    strengths: '',
    opportunities: ''
  });
  const [modified, setModified] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [statusSuccess, setStatusSuccess] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [errorMessageError, setMessageError] = useState(null);

  const canEdit = [PENDING, REJECTED].includes(evaluation?.status);

  const onSkillChangeHandler = (skillId, categoryId) => {
    const newSkillsArray = skills.map((skill) => {
      if (skill._id !== skillId) return skill;
      const categorySelected = categories.find((cat) => cat._id === categoryId);
      if (!categorySelected) return skill;
      return { ...skill, categorySelected };
    });
    setSkills(newSkillsArray);
    setModified(true);
  };

  const onObjectiveChangeHandler = (skillId, categoryId) => {
    const newObjectivessArray = objectives.map((obj) => {
      if (obj._id !== skillId) return obj;
      const categorySelected = categories.find((cat) => cat._id === categoryId);
      if (!categorySelected) return obj;
      return { ...obj, categorySelected };
    });
    setObjectives(newObjectivessArray);
    setModified(true);
  };

  useEffect(() => {
    if (evaluation) {
      const filteredSkills = evaluation.skills.map((skill) => omitTypeName(skill));
      setSkills(filteredSkills);
      const filteredObjectives = evaluation.objectives.map((obj) => omitTypeName(obj));
      setObjectives(filteredObjectives);
      setFinalResults(omitTypeName(evaluation.finalCategories));
      setResumeForm({
        strengths: evaluation.strengths,
        opportunities: evaluation.opportunities
      });
    }
  }, [evaluation]);

  const buildUpdatePayload = useCallback(
    () => ({
      evaluationId: evaluation._id,
      skills,
      objectives,
      strengths: resumeForm.strengths,
      opportunities: resumeForm.opportunities
    }),
    [skills, objectives, resumeForm]
  );

  const nextStep = () => {
    const next = step + 1;
    setStep(next <= 3 ? next : 3);
  };
  const callUpdatedEDD = !evaluation?.isAutoevaluation && !evaluation?.evaluatorCanEditObjectives;

  const onMoveForward = () => {
    if ((modified || callUpdatedEDD) && canEdit) {
      updateEDD({
        variables: buildUpdatePayload(),
        refetchQueries,
        onCompleted: (data) => {
          setModified(false);
          setFinalResults(data.updateEDD.finalCategories);
          nextStep();
        }
      });
    } else {
      nextStep();
    }
  };

  const prevStep = () => {
    const next = step - 1;
    setStep(next > 1 ? next : 1);
  };

  const onChangeResumeForm = (update) => {
    setResumeForm((prev) => ({ ...prev, ...update }));
    setModified(true);
  };

  const onSave = () => {
    if (canEdit && (modified || callUpdatedEDD)) {
      updateEDD({
        variables: buildUpdatePayload(),
        onCompleted: onModalClose,
        refetchQueries
      });
    } else {
      onModalClose();
    }
  };

  useEffect(() => {
    if (startModalClose) {
      onSave();
    }
  }, [startModalClose]);

  const onSendEvaluation = () => {
    if (canEdit) {
      updateEDDStatus({
        variables: {
          evaluationId: evaluation._id,
          statusInput: isAutoevaluation ? COMMUNICATED : EVALUATED,
          strengths: resumeForm.strengths,
          opportunities: resumeForm.opportunities
        },
        refetchQueries,
        onCompleted: () => {
          setStatusSuccess(true);
        },
        onError: (errorUpdating) => {
          setMessageError(errorUpdating?.message);
          setStatusError(true);
        }
      }).finally(() => {
        reload();
      });
    }
  };

  const stepperData = useMemo(
    () => (evaluation
      ? createStepperData(
        evaluation,
        autoevaluation,
        skills,
        categories,
        onSkillChangeHandler,
        objectives,
        onObjectiveChangeHandler,
        finalResults,
        isAutoevaluation,
        canEdit,
        setStep,
        isMobile
      )
      : {}),
    [evaluation, skills, objectives, finalResults, categories]
  );

  const stepData = evaluation ? stepperData?.stepsData[step] : {};

  const skillsCompleted = elementsCompleted(skills);
  const objectivesCompleted = elementsCompleted(objectives);

  const canMoveForward = (step === 1 && skillsCompleted) || (step === 2 && objectivesCompleted);

  return (
    stepperData && (
      <div className='performance-review-modal'>
        <TransparentCard
          className='ml-my-evolution-my-performance-pulses'
          loading={loading || updateLoading}
        >
          {evaluation && !loading && (
            <div>
              <Stepper
                {...{
                  finishedMark: false,
                  closedMark: false,
                  stepLabels: stepperData.stepLabels,
                  step: stepperData.stepLabels.find(
                    (st) => st.numberStatus === step
                  ).value,
                  isCompleted: step === 3,
                  classes: 'box-container-sunny-status'
                }}
              />
              {step < 3 && (
                <>
                  <Message color='warning' classes='w-100 mt-4'>
                    <p>
                      <b>{stepData.description}</b> Al finalizar, podrás ver el
                      resumen completo de la evaluación y si necesitas editar
                      alguna categoría, podrás hacerlo antes de enviar la
                      evaluación a la etapa de validación.
                    </p>
                  </Message>
                  <div className='mt-4 d-flex justify-content-between w-100 rounded ml-bg-box-message section-resume'>
                    <div className='mr-3 p-3 ml-flex-1 hide-mobile'>
                      <p className='m-0'>
                        Evaluación de{' '}
                        {step === 1 ? 'Competencias' : 'Objetivos'}
                      </p>
                    </div>
                    <div className=' p-3 ml-flex-1'>
                      <p className='m-0 with-dot'>
                        {step === 1 ? 'Competencias' : 'Objetivos'} completadas:{' '}
                        <b>{stepData.completePercent}</b>
                      </p>
                    </div>
                    <div className='p-3 rounded ml-flex-1'>
                      <p className='m-0 with-dot'>
                        Resultado {step === 1 ? 'Competencias' : 'Objetivos'}:{' '}
                        <b className='color-primary'>
                          {step === 1
                            ? stepData.skillsResult
                            : stepData.objectivesResult}
                        </b>
                      </p>
                    </div>
                  </div>
                  <div className='mt-4'>
                    <CollapsableRowTable
                      data={stepData.tableData}
                      expandableRender={stepData.expandableRender}
                      withColorStrap
                    />
                  </div>
                </>
              )}
              {step === 3 && (
                <div className='resume-container'>
                  <div
                    className={`w-100 mt-4 rounded pt-4 ${
                      isMobile ? 'pb-4' : 'pb-1'
                    } px-5 ml-bg-box-secondary d-flex flex-col align-items-start`}
                  >
                    {SHOW_CHART && (
                      <div className='mr-5 graph-container'>
                        <BarResumeChart
                          objectivesStatus={stepData.objectivesResult}
                          skillsStatus={stepData.skillsResult}
                          hidden
                        />
                      </div>
                    )}
                    <div>
                      <p
                        className={`${isMobile ? 'text-center' : 'mt-3'} mb-2`}
                      >
                        <b>Evaluación de Desempeño Final:</b>{' '}
                        {isMobile && <br />}
                        <b className='color-secondary'>
                          {stepData.generalResult}
                        </b>
                      </p>
                      <p className={`${isMobile && 'text-center'}`}>
                        {stepData.generalDesc}
                      </p>
                    </div>
                  </div>
                  <div className='w-100 mt-4 rounded py-4 px-5 ml-bg-box-message'>
                    <p className='text-center mb-2'>
                      <b>Categoría Competencias Final:</b> {isMobile && <br />}
                      <b className='color-primary'>{stepData.skillsResult}</b>
                    </p>
                    <p className='text-center'>{stepData.skillsDesc}</p>
                  </div>
                  <CollapsablePanel
                    text='resumen de evaluación'
                    className='mt-4'
                  >
                    <div>
                      <Message color='warning' classes='w-100'>
                        <p>
                          {
                            'Si necesitas editar la categoría que le asignaste a cada objetivo, puedes hacerlo en este resumen.'
                          }
                        </p>
                      </Message>
                      <div className='mt-4 skills-resume-table'>
                        <CollapsableRowTable
                          data={stepData.skillsTableData}
                          expandableRender={stepData.compExpandableRender}
                          size='middle'
                        />
                      </div>
                    </div>
                  </CollapsablePanel>
                  <div className='w-100 mt-4 rounded py-4 px-5 ml-bg-box-message'>
                    <p className='text-center mb-2'>
                      <b>Categoría Objetivos Final:</b> {isMobile && <br />}
                      <b className='color-primary'>
                        {stepData.objectivesResult}
                      </b>
                    </p>
                    <p className='text-center'>{stepData.objectivesDesc}</p>
                  </div>
                  <CollapsablePanel
                    text='resumen de evaluación'
                    className='mt-4'
                  >
                    <div>
                      <Message color='warning' classes='w-100'>
                        <p>
                          {
                            'Si necesitas editar la categoría que le asignaste a cada objetivo, puedes hacerlo en este resumen.'
                          }
                        </p>
                      </Message>
                      <div className='mt-4 objectives-resume-table'>
                        <CollapsableRowTable
                          data={stepData.objTableData}
                          expandableRender={stepData.objExpandableRender}
                          size='middle'
                        />
                      </div>
                    </div>
                  </CollapsablePanel>
                  <Message color='warning' classes='w-100 mt-4'>
                    <p>
                      <b>
                        {
                          'Para poder finalizar la evaluación, debes ingresar las fortalezas y oportunidades de mejora que hayas identificado durante este período.'
                        }
                      </b>
                      {!isAutoevaluation
                        && ` ${evaluation?.evaluated.name} ya las ingresó en su autoevaluación.`}
                    </p>
                  </Message>
                  <div className='mt-4'>
                    <ResumeForm
                      strengths={resumeForm.strengths}
                      opportunities={resumeForm.opportunities}
                      onChange={onChangeResumeForm}
                      canEdit={canEdit}
                      isAutoevaluation={isAutoevaluation}
                      autoevaluation={autoevaluation}
                      isMobile={isMobile}
                    />
                  </div>
                </div>
              )}
              <div className='d-flex justify-content-center mt-5 buttons-container'>
                <div className='mr-4'>
                  <Button
                    mlType='inverse-main'
                    text={'Guardar y Salir'}
                    disabled={!canEdit}
                    onClick={onSave}
                  />
                </div>
                <div>
                  {step < 3 ? (
                    <Button
                      mlType='main'
                      onClick={onMoveForward}
                      text={'Siguiente'}
                      disabled={(step < 3 && !canMoveForward) || !canEdit}
                    />
                  ) : (
                    <Button
                      mlType='main'
                      onClick={() => setModalOpen(true)}
                      text='Finalizar'
                      disabled={
                        !resumeForm.strengths.length
                        || !resumeForm.opportunities.length
                        || !canEdit
                      }
                    />
                  )}
                </div>
              </div>
              {step > 1 && (
                <div className='d-flex justify-content-center mt-2'>
                  <button
                    onClick={prevStep}
                    className='color-primary ml-no-border'
                  >
                    {'< Volver'}
                  </button>
                </div>
              )}
            </div>
          )}
          <Modal
            className='react-confirmation-modal'
            isOpen={modalOpen}
            fade={true}
            toggle={(e) => {
              e.stopPropagation();
              setModalOpen(false);
              setStatusError(false);
              setStatusSuccess(false);
            }}
          >
            <div className='d-flex w-100 justify-content-center align-items-center flex-column confirmation-modal p-5 rounded'>
              <i
                className={
                  statusSuccess
                    ? 'icon-me-check'
                    : statusError
                      ? 'icon-me-error'
                      : 'icon-ml-warning'
                }
              />
              <p className='text-dark text-center'>
                <b>
                  {statusSuccess
                    ? isAutoevaluation
                      ? '¡Se ha enviado tu autoevaluación con éxito a tu evaluador para que pueda tomarla en cuenta al momento de realizar tu evaluación.'
                      : `¡Se ha enviado la evaluación con éxito a ${capitalizeText(
                          evaluation?.validator?.name
                      )} para su validación!`
                    : statusError
                      ? 'No se pudo enviar la evaluación'
                      : isAutoevaluation
                        ? '¿Quieres finalizar tu autoevaluación? Una vez enviada no podrá ser modificada.'
                        : '¿Quieres finalizar la evaluación?'}
                </b>
              </p>
              {statusError && (
                <p>
                  {errorMessageError
                    || 'Estamos trabajando en solucionarlo, por favor intenta enviarlo de nuevo más tarde.'}
                </p>
              )}
              {!statusLoading ? (
                <div className='d-flex justify-content-center buttons-container'>
                  <div className='mr-4'>
                    <Button
                      mlType='inverse-main'
                      text={
                        !statusError && !statusSuccess ? 'Cancelar' : 'Cerrar'
                      }
                      onClick={() => {
                        if (statusSuccess) {
                          onModalClose();
                          return;
                        }
                        setModalOpen(false);
                        setStatusError(false);
                      }}
                    />
                  </div>
                  {!statusError && !statusSuccess && (
                    <div>
                      <Button
                        mlType='main'
                        onClick={onSendEvaluation}
                        text='Si, enviar'
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <MLLoading
                    className='pt-0 mt-0'
                    message={
                      'Por favor espera que el proceso puede tomar algunos segundos'
                    }
                  />
                </div>
              )}
            </div>
          </Modal>
        </TransparentCard>
      </div>
    )
  );
};

PerformanceReviewModal.propTypes = {
  evaluation: PropTypes.object,
  categories: PropTypes.array,
  loading: PropTypes.bool,
  setCustomOnCloseModal: PropTypes.func,
  isAutoevaluation: PropTypes.bool,
  refetchQueries: PropTypes.array,
  currentStep: PropTypes.number,
  reload: PropTypes.func
};

PerformanceReviewModal.defaultProps = {
  evaluation: {},
  categories: [],
  loading: false,
  setCustomOnCloseModal: () => {},
  isAutoevaluation: false,
  refetchQueries: [],
  currentStep: 1,
  reload: () => {}
};

export default PerformanceReviewModal;

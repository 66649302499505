import { get } from 'lodash';
import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import * as storageUtil from '../../helpers/cookiesUtil';

import {
  getExcelAPI, getAnalyticAPI, setInitialDateAPI, getExcelAPIBenefits
} from '../api/report';
import {
  actionTypes,
  getExcelSuccess,
  getExcelBenefitsSuccess,
  getAnalyticSuccess
} from '../actions/report';
import { failure, loading, loadComplete } from '../actions/commons';

es6promise.polyfill();

function* getExcel(actions) {
  try {
    yield put(loading());
    const token = storageUtil.getItem('token');
    let response;
    if (typeof actions.data !== 'undefined') {
      response = yield call(getExcelAPI, actions.data);
    } else {
      response = yield call(getExcelAPI, {
        token, from: null, to: null, reportType: actions.data.reportType
      });
    }
    yield put(getExcelSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const statusCode = get(err, 'response.status');
    yield put(failure({ error: { error: true, message: 'Se ha producido un error al intentar generar el reporte.' }, statusCode }));
    yield put(loadComplete());
  }
}

function* getExcelBenefits(actions) {
  try {
    yield put(loading());
    const response = yield call(getExcelAPIBenefits, actions.data);
    yield put(getExcelBenefitsSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const statusCode = get(err, 'response.status');
    yield put(failure({ error: { error: true, message: 'Se ha producido un error al intentar generar el reporte.' }, statusCode }));
    yield put(loadComplete());
  }
}

function* getAnalytic() {
  try {
    yield put(loading());
    const token = storageUtil.getItem('token');
    const response = yield call(getAnalyticAPI, { token });
    yield put(getAnalyticSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* setInitialDate(action) {
  try {
    yield put(loading());
    yield call(setInitialDateAPI, action.data);
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* reportSagas() {
  yield all([
    takeLatest(actionTypes.GET_EXCEL, getExcel),
    takeLatest(actionTypes.GET_EXCEL_BENEFITS, getExcelBenefits),
    takeLatest(actionTypes.GET_ANALYTIC, getAnalytic),
    takeLatest(actionTypes.SET_INITIAL_DATE, setInitialDate)
  ]);
}

export default reportSagas;

import React from 'react';
import PropTypes from 'prop-types';
import MLConfirmModal from '../Message';

const DefaultCancelConfirmModal = (mlModalMessageProps) => (
  <MLConfirmModal {...mlModalMessageProps}/>
);

DefaultCancelConfirmModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  okButtonText: PropTypes.string,
  buttons: PropTypes.array,
  defaultCancel: PropTypes.bool,
  defaultOk: PropTypes.bool
};

DefaultCancelConfirmModal.defaultProps = {
  title: '¿Quieres realizar esta acción?',
  message: 'Perderás la información que hayas ingresado.',
  type: 'warning',
  icon: 'icon-ml-warning pending-icon-color',
  okButtonText: 'Sí, salir',
  defaultCancel: true,
  defaultOk: true
};

export default DefaultCancelConfirmModal;

import React from 'react';
import { find } from 'lodash';
import { Button } from 'antd';

import Action from './Action';

const Behavior = (props) => {
  const {
    loadingEndTest,
    behaviorChoice: { behavior },
    behaviorChoices,
    setBehaviorChoices,
    endTest,
    indexBehavior, carouselState, next
  } = props;

  const handleAction = (checked, action) => {
    const newBehaviorChoices = behaviorChoices.map(
      (behaviorActions) => ({
        choice: behaviorActions.choice,
        behavior: {
          ...behaviorActions.behavior,
          actions: behaviorActions.behavior.actions.map((item) => (
            item.id === action.id && action.name === item.name ? { ...item, checked } : item
          ))

        }
      })
    );

    setBehaviorChoices(newBehaviorChoices);
  };

  return (
    <div key={indexBehavior} className='container-create-plan' >
      <div className='container-behavior'>

        <h6>Conducta Nº{behavior.id}</h6>
        <p>
          {behavior.name}
        </p>

      </div>
      {
        behavior.actions.map((action, indexAction) => (
          <Action
            key={indexAction}
            indexAction={indexAction}
            handleAction={handleAction}
            action={action} />
        ))
      }
      <div className='d-flex justify-content-center mt-3'>
        <Button type='primary'
          loading={loadingEndTest}
          disabled={!find(behavior.actions, (item) => item.checked)}
          onClick={
            behaviorChoices.length - 1 === carouselState.activeSlide
              ? () => endTest()
              : next
          }
        >
          { behaviorChoices.length - 1 === carouselState.activeSlide ? 'Finalizar' : 'Siguiente'}
        </Button>
      </div>

    </div>
  );
};

export default Behavior;

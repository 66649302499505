/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const ADMIN_UPDATE_USER_PERFORMANCE = gql`
  mutation updatePerformanceAdmin($rut: String, $status: StatusPerformanceWalmartInput) {
    updatePerformanceAdmin(rut: $rut, status: $status) {
      status {
        current
      }
      objectiveEdit {
        status
      }
    }
  }
`;

import {
  find, isEmpty, map, capitalize
} from 'lodash';
import moment from 'moment';
import { MY_FEEDBACKS, OTHERS_FEEDBACKS, MY_TEAM_FEEDBACKS } from '../../graphql/mievolucion/feedbacks/queries';
import { fullCapitalizeFormat } from '../strings';

export const skillsTypes = ['OLIVE', 'FOUR_C'];

export const feedbacksWithBehaviors = ['FOUR_C'];

export const hasBehaviors = (type) => feedbacksWithBehaviors.includes(type);

export const isSkill = (type) => skillsTypes.includes(type);

export const getTitleFeedbackDetail = ({ status, feedbackToMe }) => {
  if (status === 'Rechazado') {
    return 'Solicitud de feedback rechazada';
  }
  return `Feedbacks ${feedbackToMe ? 'recibidos' : 'realizados'}`;
};
export const addBehaviors = ({ comments, listSkillsBehaviors }) => {
  const newComments = comments.map((comment) => {
    const { skill } = comment || {};
    const { _id: skillID = '' } = skill || {};

    const findSkill = find(listSkillsBehaviors,
      ({ _id }) => _id && _id.toString() === skillID.toString());
    return findSkill ? { ...comment, skill: findSkill } : comment;
  });

  return newComments;
};
export const MAX_SKILLS = 2;
export const listTypeFeedbacks = [
  {
    key: 'PERFORMANCE',
    label: 'Desempeño',
    placeholder: 'Ej: Periodo sobre el que se solicita feedback de desempeño.',
    subject: 'Feedback por desempeño'
  },
  {
    key: 'PROJECT',
    label: 'Proyecto/Mesa',
    placeholder: 'Ej: Nombre del proyecto/Mesa.',
    subject: 'Feedback por Proyecto/Mesa'
  },
  {
    key: 'FOUR_C',
    label: 'Competencias',
    placeholder: '',
    subject: 'Feedback por Competencias'
  },
  {
    key: 'OTHERS',
    label: 'Otro',
    placeholder: 'Ej: Reunión coordinación',
    subject: 'Feedback por Otro'
  }
];
export const hasErrorsFormCreatingFeedback = ({
  skillsSelected, feedbackType, usersSelected, type, setModalOptions
}) => {
  let errors = false;
  let errorTitle = '';
  const skills = [];
  const destinedTo = [];
  let isEmptyContent = false;

  skillsSelected.forEach(({
    _id, content, otherSkillName
  }) => {
    if (feedbackType === 'Given' && type === 'FOUR_C') {
      if (isEmpty(content.trim())) {
        isEmptyContent = true;
      }
    }
    skills.push({ skillID: _id, content, otherSkillName });
  });
  usersSelected.forEach(({ rut }) => {
    destinedTo.push(rut);
  });
  if (isEmpty(destinedTo)) {
    errors = true;
    errorTitle = `Debes seleccionar al menos una persona para ${feedbackType === 'Given' ? 'dar' : 'pedir'} feedback`;
  } else
  if (isEmpty(skills) && type === 'FOUR_C') {
    errors = true;
    errorTitle = 'Debes seleccionar al menos una competencia';
  } else
  if (isEmptyContent) {
    errors = true;
    errorTitle = 'Debes ingresar  comentarios en todas las competencias';
  }
  if (errors) {
    setModalOptions({
      visible: true,
      title: errorTitle,
      type: 'error'
    });
  }
  return errors;
};
export const withoutInformation = {
  value: 'WITHOUT_INFORMATION',
  category: 'Sin información para evaluar esta competencia',
  content: 'Sin información para comentar esta competencia'
};

export const queryValidation = (listTab) => {
  switch (listTab) {
  case 1:
    return MY_FEEDBACKS;
  case 2:
    return OTHERS_FEEDBACKS;
  default:
    return MY_TEAM_FEEDBACKS;
  }
};

export const getParsedData = (data, listTab) => {
  let res;
  let srcData;
  let from = '';
  if (!data) return res;
  switch (listTab) {
  case 1:
    srcData = data.myFeedbacks;
    res = [];
    from = 'my';
    break;
  case 2:
    srcData = data.feedbackToOthers;
    res = [];
    from = 'others';
    break;
  default:
    srcData = [];
    res = {};
  }

  map(srcData, ({
    user: {
      name,
      fatherLastName,
      position,
      rut
    },
    fromUser,
    feedback,
    comments,
    answer
  }) => {
    const title = fullCapitalizeFormat(`${name} ${fatherLastName}`);
    const subTitle = fullCapitalizeFormat(position);
    const {
      _id,
      status,
      shared,
      feedbackType,
      isGroup,
      subject,
      isFeedbackNetwork,
      createdAt
    } = feedback;
    const itemData = {
      showStatusName: false,
      from,
      _id,
      subject,
      title,
      subTitle,
      status,
      isFeedbackNetwork,
      feedbackType,
      date: capitalize(moment.utc(createdAt).format('ddd DD MMM YYYY').replace('.', ',').replace('.', '')),
      comments,
      answer,
      shared,
      isGroup,
      showCalendar: true,
      rut,
      ...(fromUser ? {
        fromUser: fullCapitalizeFormat(`${fromUser.name} ${fromUser.fatherLastName}`)
      } : null),
      feedback
    };

    res.push(itemData);
  });

  return res;
};

export const setItemFromData = (parsedData, item, setItem) => {
  if (!isEmpty(parsedData) && !isEmpty(item)) {
    setItem(find(parsedData, ({ _id, rut }) => _id === item._id && rut === item.rut));
  }
};

export const onSelectItem = (setItem, itemID, itemRut, parsedData) => {
  if (!itemID && !itemRut) {
    setItem({});
  } else {
    const newItem = find(parsedData, ({ _id, rut }) => _id === itemID && rut === itemRut);
    setItem(newItem);
  }
};

export const setItemFromQueryParams = (search, setItem, parsedData) => {
  const queryParams = new URLSearchParams(search);
  const searchID = queryParams.get('id');
  const searchRut = queryParams.get('rut');

  if (searchID && searchRut) {
    onSelectItem(setItem, searchID, searchRut, parsedData);
  } else {
    onSelectItem(setItem);
  }
};

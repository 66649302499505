import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import { getThreePodium } from '../api/podium';
import { actionTypes, getPodiumError, getPodiumSuccess } from '../actions/podium';

es6promise.polyfill();

function* getTopThreePodium() {
  try {
    const response = yield call(getThreePodium);
    yield put(getPodiumSuccess(response.result));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(getPodiumError({ error, statusCode }));
  }
}

function* podiumSagas() {
  yield all([takeLatest(actionTypes.ALL_PODIUM, getTopThreePodium)]);
}
export default podiumSagas;

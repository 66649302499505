import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
import Select from '../../../../../../shared/forms/Select';
import MLTextArea from '../../../../../../shared/MLTextArea';
import { pillarName, SPECIFIC_OBJECTIVE_KEY } from '../../../../../../../helpers/myEvolution/myPerformance';

const Objective = ({
  form, FormItem, pillarSelected, onChangeOtherText,
  isSelected
}) => {
  const [options, setOptions] = useState([]);
  const { getFieldDecorator } = form;
  const handleChange = (_, name) => isSelected(name);

  useEffect(() => {
    if (pillarSelected?.objectives?.length > 0) {
      const optionsParsed = map(pillarSelected.objectives, (objective) => ({
        label: objective.name,
        value: objective._id
      }));
      setOptions(optionsParsed);
    }
  }, [pillarSelected]);

  if (isEmpty(pillarSelected)) return null;
  const isSpecificObjective = pillarSelected?._id === pillarName[SPECIFIC_OBJECTIVE_KEY];
  return (
    <FormItem
      className='objective-pillar-field'
      label={(
        <>
          <i className='icon-ml-pilar color-pillar'/>
          <label> { isSpecificObjective ? 'Nombre de objetivo' : 'Selecciona un objetivo'}:</label>
        </>
      )}
    >
      {
        isSpecificObjective
          ? getFieldDecorator('otherObjective', {
            rules: [{ required: isSpecificObjective, message: 'Describe brevemente tu objetivo' }]
          })(

            <MLTextArea
              onChange={(e) => onChangeOtherText(e.target.value)}
              placeholder='Describe la medida de éxito'/>
          )

          : getFieldDecorator('objective', {
            rules: [{
              required: !isSpecificObjective,
              message: 'Selecciona una opción para continuar.'
            }]
          })(
            <Select
              placeholder="Selecciona una opción"
              onSelect={handleChange}
              options={options}
              size="large"
              suffixIcon={<i className='icon-ml-select-dd'/>}
            />
          )

      }
    </FormItem>
  );
};

Objective.propTypes = {
  form: PropTypes.object,
  FormItem: PropTypes.elementType,
  pillarSelected: PropTypes.object,
  onChangeOtherText: PropTypes.func,
  isSelected: PropTypes.func
};

Objective.defaultProps = {
  form: {},
  FormItem: Fragment,
  pillarSelected: {},
  onChangeOtherText: () => {},
  isSelected: () => {}
};

export default Objective;

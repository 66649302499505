import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

export const getRegionsAPI = () => axios().get(`${BFF_ENDPOINT}/lists/geo/regions`).then((resp) => resp.data);

export const getCommunesAPI = () => axios().get(`${BFF_ENDPOINT}/lists/geo/communes`).then((resp) => resp.data);

export const getCountriesAPI = () => axios().get(`${BFF_ENDPOINT}/lists/geo/countries`).then((resp) => resp.data);

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAdminCredential } from '../../redux/actions/user';
import useSectionName from './useSectionName';
import shouldFetchRequest from '../helpers/shouldFetchRequest';

const useCredentialsAdminLoad = (leafPath) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.userReducer.data);
  const credentialAdmin = useSelector((state) => state.miCredencialReducer.credentialAdmin);
  const section = useSectionName();

  useEffect(() => {
    if (shouldFetchRequest(me, section, leafPath, credentialAdmin)) {
      dispatch(getUserAdminCredential(me.npres));
    }
  }, []);

  return credentialAdmin;
};

export default useCredentialsAdminLoad;

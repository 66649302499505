import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Message from '../../../../shared/InfoMessage';
import '../../../../../static/styles/mievolucion/objectives2022/advance-modal-header.less';

const AdvanceModalHeader = ({ DefaultHeader }) => {
  const [infoMessageHidden, showReturnedInfoMessageHidden] = useState(false);

  const onInfoMessageClose = () => showReturnedInfoMessageHidden(true);

  const getClassName = () => {
    let classNameResult = 'advance-modal-header';
    if (infoMessageHidden) classNameResult += ' advance-modal-header-without-info-message';
    return classNameResult;
  };

  return (
    <div className={getClassName()}>
      <DefaultHeader/>

      <Message {...{
        type: '',
        closable: true,
        color: 'warning',
        message: 'Los objetivos son anuales y no pueden editarse. En cada Pulso podrás agregar medidas de éxito y editar su avance.',
        onClose: onInfoMessageClose
      }}/>
    </div>
  );
};

AdvanceModalHeader.propTypes = {
  DefaultHeader: PropTypes.elementType
};

AdvanceModalHeader.defaultProps = {
  DefaultHeader: Fragment
};

export default AdvanceModalHeader;

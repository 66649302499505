import { actionTypes } from '../actions/section';

export const initialState = {
  data: {},
  loading: false,
  error: '',
  sectionButtonOffsetTop: 0
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_SECTION_FORM:
    return {
      ...state,
      loading: true,
      error: false
    };
  case actionTypes.GET_SECTION_FORM_SUCCESS:
    return {
      ...state,
      loading: false,
      data: {
        ...state.data,
        GET_SECTION_FORM: action.data
      }
    };
  case actionTypes.GET_SECTION_FORM_FAILURE:
    return {
      ...state,
      loading: false,
      error: {
        ...state.error,
        GET_SECTION_FORM: action.error
      }
    };
  case actionTypes.POST_SECTION_FEEDBACK:
    return {
      ...state,
      pushing: true,
      error: false
    };
  case actionTypes.POST_SECTION_FEEDBACK_SUCCESS:
    return {
      ...state,
      pushing: false,
      data: {
        ...state.data,
        POST_SECTION_FEEDBACK: action.data
      }
    };
  case actionTypes.POST_SECTION_FEEDBACK_FAILURE:
    return {
      ...state,
      pushing: false,
      error: {
        ...state.error,
        POST_SECTION_FEEDBACK: action.error
      }
    };
  case actionTypes.RESET_SECTION_POST:
    return {
      ...state,
      data: {
        ...state.data,
        POST_SECTION_FEEDBACK: null
      },
      error: {
        ...state.error,
        POST_SECTION_FEEDBACK: null
      }
    };
  case actionTypes.SET_SECTION_BUTTON_OFFSET_TOP:
    return {
      ...state,
      sectionButtonOffsetTop: action.data
    };
  default:
    return state;
  }
}

export default reducer;

import React from 'react';
import '../../../../../static/styles/mievolucion/objectives2022/advanceSuccessMeasureModalMessage.less';

const AdvanceSuccessMeasureModalMessage = (isMobile) => (<div
  className={isMobile ? 'advance-success-measure-modal-message-mobile' : 'advance-success-measure-modal-message'}
>
  {isMobile && <hr className="modal-hr" />}
  <div className="modal-message-row top">
    <span className="modal-message-title">No iniciada:</span>
    <span> Aún no comienzas a trabajar en el cumplimiento de esta medida de éxito.</span>
  </div>
  <div className="modal-message-row">
    <span className="modal-message-title">En proceso:</span>
    <span> Continúas trabajando en el cumplimiento esta medida de éxito.</span>
  </div>
  <div className="modal-message-row">
    <span className="modal-message-title">Finalizada:</span>
    <span> Cumpliste la medida de éxito planteada para este objetivo.</span>
  </div>
  <div className="modal-message-row">
    <span className="modal-message-title">Cancelada:</span>
    <span> Ya no trabajarás en esta medida de éxito. Puedes agregar una nueva en su lugar.</span>
  </div>
  {isMobile && <hr className="modal-hr" />}
</div>);

export default AdvanceSuccessMeasureModalMessage;

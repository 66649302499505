import React from 'react';
import PropTypes from 'prop-types';
import '../../../../../static/styles/mievolucion/shared/performance-evaluation/evaluation-summary.less';
import SkillsTable from './SkillsTable';
import Objectives from './Objectives';
import MLBoxMessage from '../../../../shared/MLBoxMessage';
import MLSimpleCollapse from '../../../../shared/MLSimpleCollapse';
import ContainerCategory from './ContainerCategory';

const EvaluationSummary = (props) => {
  const {
    evaluation,
    classes
  } = props;
  const {
    strengths,
    opportunities,
    isAutoevaluation,
    skills,
    objectives,
    finalCategories
  } = evaluation;

  const {
    evaluation: finalCategory,
    skills: finalSkillCategory,
    objectives: finalObjectiveCategory
  } = finalCategories || {};

  const messageEvaluation = isAutoevaluation ? 'autoevaluación' : 'evaluación';

  const columnsNames = ['Competencias', 'Categorías'];
  const columnsClasses = ['left', 'right'];

  const FinallyCategory = ({ className = '' }) => (<MLBoxMessage
    classes={className}
    color={'rose'}
    separateTitle={true}
    boxTitle={ {
      label: `Resultado de la ${messageEvaluation} de desempeño`,
      value: finalCategory?.name
    }}
  >
    <p>{finalCategory?.finalPerformanceDescription}</p>
  </MLBoxMessage>);
  return (
    <div className={`container-performance-evaluation-summary ${classes}`}>
      { !isAutoevaluation
      && <FinallyCategory />
      }
      <MLSimpleCollapse
        {
          ...({
            title: messageEvaluation,
            open: false,
            classes: 'collapse-see-more'
          })
        }
      > {finalCategory && isAutoevaluation
        && <FinallyCategory className='mt-3' />
        }

        <ContainerCategory {...{
          showCategory: true,
          category: finalSkillCategory,
          type: 'skill',
          keyDescription: 'finalSkillDescription'
        }}>
          <SkillsTable
            className={'mt-4'}
            columnsClasses={columnsClasses}
            columnsNames={columnsNames}
            skills={skills}
          />
        </ContainerCategory>
        <ContainerCategory {...{
          category: finalObjectiveCategory,
          type: 'objective',
          keyDescription: 'finalObjetivesDescription'
        }}>
          <Objectives
            objectives={objectives}
          />
        </ContainerCategory>

        <div>
          <div className='container-table separator'>
            <div className='container-header'>
              <div className='header-summary right'>
                <span>Fortalezas</span>
              </div>
            </div>
            <p>{strengths}</p>
          </div>
          <div className='container-table separator'>
            <div className='container-header'>
              <div className='header-summary right'>
                <span>Oportunidades de mejora</span>
              </div>
            </div>
            <p>{opportunities}</p>
          </div>
        </div>

      </MLSimpleCollapse>
    </div>

  );
};

EvaluationSummary.propTypes = {
  classes: PropTypes.string,
  evaluation: PropTypes.object
};

EvaluationSummary.defaultProps = {
  classes: '',
  evaluation: {}
};

export default EvaluationSummary;

export const actionTypes = {
  GET_CALIBRATIONS_REPORT: 'GET_CALIBRATIONS_REPORT',
  GET_CALIBRATIONS_REPORT_SUCCESS: 'GET_CALIBRATIONS_REPORT_SUCCESS',
  GET_CALIBRATIONS_REPORT_ERROR: 'GET_CALIBRATIONS_REPORT_ERROR',
  CLEAN_CALIBRATIONS_REPORT: 'CLEAN_CALIBRATIONS_REPORT'
};

export function getCalibrationsReport() {
  return {
    type: actionTypes.GET_CALIBRATIONS_REPORT
  };
}

export function getCalibrationsReportSuccess(status) {
  return {
    type: actionTypes.GET_CALIBRATIONS_REPORT_SUCCESS,
    data: status
  };
}

export function getCalibrationsReportError(error) {
  return {
    type: actionTypes.GET_CALIBRATIONS_REPORT_ERROR,
    error
  };
}

export function cleanCalibrationsReport(error) {
  return {
    type: actionTypes.CLEAN_CALIBRATIONS_REPORT,
    error
  };
}

import { useEffect, useState } from 'react';
import { fullCapitalizeFormat } from '../../../../helpers/strings';
import useIsSmallDevice from '../../responsive/useIsSmallDevice';

export default function useSearchUserSelection({
  blockedFirstName,
  canRefetchQuery,
  firstName,
  firstNameInput,
  setRefetchTrigger,
  setUnfeactiveFirstName,
  onSelect = () => null
}) {
  const isSmallDevice = useIsSmallDevice();
  const [hideResults, setHideResults] = useState(false);

  const selectUser = (selectedUser) => {
    setUnfeactiveFirstName(fullCapitalizeFormat(selectedUser.fullName));
    onSelect(selectedUser);
    setRefetchTrigger(false);
    if (isSmallDevice) setHideResults(true);
  };

  useEffect(() => {
    if (!hideResults && !firstName) {
      setHideResults(true);
    }
  }, [firstName]);

  useEffect(() => {
    if (!firstNameInput.inputProps.value && blockedFirstName) onSelect({});
  }, [firstNameInput]);

  return {
    canRefetchQuery, hideResults, selectUser, setHideResults
  };
}

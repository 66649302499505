import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import { getFaqsAPI } from '../api/faq';
import { actionTypes, getFaqsSuccess } from '../actions/faq';
import { failure, loading, loadComplete } from '../actions/commons';

es6promise.polyfill();

export function* faqs() {
  try {
    yield put(loading());
    const response = yield call(getFaqsAPI);
    yield put(getFaqsSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* faqsSagas() {
  yield all([
    takeLatest(actionTypes.GET_FAQS, faqs)
  ]);
}

export default faqsSagas;

import gql from 'graphql-tag';

export const LIST_SETTINGS = gql`
  query listSettings($keys: [String]) {
    listSettings(keys: $keys) {
      key
      year
      value{
        ...on IntValue {
          intValue
        }
        ...on StringValue {
          stringValue
        }
        ...on BooleanValue {
          booleanValue
        }
      }
    }
  }
`;
export const GET_SETTING = gql`
  query getSetting($key: String!) {
    getSetting(key: $key) {
      key
      year
      value{
        ...on IntValue {
          intValue
        }
        ...on StringValue {
          stringValue
        }
        ...on BooleanValue {
          booleanValue
        }
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row } from 'antd';
import MLModalWithFixedLayout from '../MLModal/WithFixedLayout';

const StepContainer = ({ children, modalWithFixedLayout }) => (
  <Row {...{ type: 'flex' }}>
    {modalWithFixedLayout ? (
      <MLModalWithFixedLayout {...{
        ...modalWithFixedLayout,
        OpenButton: (props) => (
          <Button {...{ ...props, type: 'link' }}>
            {children}
          </Button>
        )
      }}/>
    ) : (
      children
    )}
  </Row>
);

StepContainer.propTypes = {
  children: PropTypes.element,
  modalWithFixedLayout: PropTypes.object
};

StepContainer.defaultProps = {
  children: <></>,
  modalWithFixedLayout: null
};
export default StepContainer;

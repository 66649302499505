import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const MIO_BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

export const getLeadersPeopleStoresAPI = ({ rut }) => {
  const leadersPeopleStoreEndpoint = rut ? `/admin/leaders-people-store?rutUser=${rut}` : '/admin/leaders-people-store';
  return axios().get(`${MIO_BFF_ENDPOINT}${leadersPeopleStoreEndpoint}`).then((resp) => resp.data);
};

export const getLastFileUploadedPeopleStoresAPI = () => axios().get(`${MIO_BFF_ENDPOINT}/admin/leaders-people-store/download`, {
  responseType: 'blob'
}).then((resp) => resp.data);

import React from 'react';
import PulseExplain from '../../../components/mievolucion/MyPerformance/Performance/PulsesComponent/PulseExplain';
import usePerformance from '../../../hooks/myEvolution/myPerformance/usePerformance';
import { MONTH_ADVANCE_COMMENTS_BY_Q, PERFORMANCE_YEAR } from '.';

const getStepsData = () => {
  const { data: performance = {} } = usePerformance();
  const { quarterCurrent: currentQuarter } = performance || {};

  const qIndex = Number(currentQuarter?.split('q')?.pop());
  const classes = 'box-container-sunny-status';

  const step = (() => {
    switch (currentQuarter) {
    case 'q1': return 'PULSE_1';
    case 'q2': return 'PULSE_2';
    case 'q3': return 'PULSE_3';
    case 'q4': return 'PULSE_4';
    default:
    }

    return 'PULSE_1';
  })();

  const pulses = {
    PULSE_1: MONTH_ADVANCE_COMMENTS_BY_Q[1],
    PULSE_2: MONTH_ADVANCE_COMMENTS_BY_Q[2],
    PULSE_3: MONTH_ADVANCE_COMMENTS_BY_Q[3]
  };
  const PULSE_1 = 'PULSE_1';
  const PULSE_2 = 'PULSE_2';
  const PULSE_3 = 'PULSE_3';
  const stepLabels = [
    {
      value: PULSE_1,
      label: 'Fijación de Objetivos',
      description: `Comenzó en ${pulses[PULSE_1]}`,
      modalWithFixedLayout: {
        title: 'Pulso 1: Fijación de Objetivos',
        children: <PulseExplain {...{
          title: `El Pulso 1 comenzó en ${pulses[PULSE_1]}`,
          description: `En este Pulso iniciará el proceso de Desempeño ${PERFORMANCE_YEAR}. Definirás tus objetivos de este año de acuerdo con los objetivos que te compartió tu Líder.`
        }}/>
      },
      numberStatus: 1
    },
    {
      value: PULSE_2,
      label: 'Avance de objetivos',
      description: `${qIndex >= 2 ? 'Comenzó' : 'Comienza'} en ${pulses[PULSE_2]}`,
      modalWithFixedLayout: {
        title: 'Pulso 2: Avance de objetivos',
        children: <PulseExplain {...{
          title: `El Pulso 2 ${qIndex >= 2 ? 'comenzó' : 'comienza'} en ${pulses[PULSE_2]}`,
          description: 'En este pulso podrás registrar tus comentarios y avance de tus objetivos, así como de tu desempeño a nivel general. Se llevará a cabo una conversación con tu Líder para comprender cómo has ido progresando hasta esta fecha. Para identificar cuáles son tus fortalezas, oportunidades, motivaciones y aspiraciones, te recomendamos apoyarte en tu Red de Feedback y completar tu IDP para impulsar tu Desarrollo.'
        }}/>
      },
      numberStatus: 2
    },
    {
      value: PULSE_3,
      label: 'Cierre de Desempeño',
      description: `${qIndex >= 3 ? 'Comenzó' : 'Comienza'} en ${pulses[PULSE_3]}`,
      modalWithFixedLayout: {
        title: 'Cierre de Desempeño',
        children: <PulseExplain {...{
          title:
           `El Cierre de Desempeño ${
            qIndex >= 3 ? 'comenzó' : 'comienza'} en ${pulses[PULSE_3]}`,
          description: 'En este pulso podrás registrar el avance final de tus objetivos y poder cerrarlos en función de lo que completaste este año.'
        }}/>
      },
      numberStatus: 2
    }
  ];

  return { classes, step, stepLabels };
};

export default getStepsData;

import React from 'react';
import {
  isEmpty, map
} from 'lodash';
import { isMobile } from 'react-device-detect';
import { completePath, fullCapitalizeFormat } from '../strings';
import { getFullName } from '../user';
import { customFormatDateCard } from '../dates';

export const MENTOR_OPTIONS = [
  {
    value: true,
    label: 'Sí'
  },
  {
    value: false,
    label: 'No'
  }
];
export const DEFAULT_FORM_VALUE = '';
export const STATUS_IDP = {
  pending: 'PENDING',
  finished: 'FINISHED'
};
export const DEVELOPMENT_EDUCATION_SETTINGS = {
  title: 'Acciones de desarrollo: 10% educación (Máximo 3)',
  description: 'Instancias de formación y experiencias educativas que permitirán tu desarrollo.',
  placeholder: 'Ejemplo:  “Cursos en LinkedIn Learning, Harvad park, Conferencias...”',
  maxLength: 80,
  maxOptions: 3
};
export const DEVELOPMENT_EXPOSURE_SETTINGS = {
  title: 'Acciones de desarrollo: 20% exposición (Máximo 3)',
  description: 'Instancias de exposición y conexión con otras personas o actividades que te darán mayor visibilidad en la compañía.',
  placeholder: 'Ejemplo:  “Participación en mesas de comité de crisis...”',
  maxLength: 80,
  maxOptions: 3
};
export const DEVELOPMENT_EXPERIENCE_SETTINGS = {
  title: 'Acciones de desarrollo: 70% experiencia (Máximo 3)',
  description: 'Experiencias, proyectos o actividades que empujarán el logro de tus objetivos y aspiraciones.',
  placeholder: 'Ejemplo: “Liderazgo en Desarrollo de Talentos con foco en trabajo en equipo...”',
  maxLength: 80,
  maxOptions: 3
};

export const DEVELOPMENT_COMMENT_SETTINGS = {
  title: 'Comentarios adicionales (Opcional)',
  description: 'Observaciones, dudas o comentarios que tienes sobre tu plan de desarrollo individual.',
  maxLength: 300
};

export const STRENGTHS_SETTINGS = {
  title: 'Fortalezas clave (Máximo 3)',
  description: 'Fortalezas claves relacionadas con tus aspiraciones.',
  placeholder: 'Ejemplo: capacidad de liderazgo colaborartivo...',
  maxLength: 90,
  maxOptions: 3
};
export const DEVELOPMENT_AREA_SETTINGS = {
  title: 'Oportunidades de desarrollo (Máximo 3)',
  description: 'Principales oportunidades de desarrollo relacionadas con tus aspiraciones.',
  placeholder: 'Ejemplo: adquirir conocimientos en el área financiera...',
  maxLength: 90,
  maxOptions: 3
};
export const DEVELOPMENT_PRIORITY_SETTINGS = {
  title: 'Objetivos de desarrollo (Máximo 3)',
  description: 'Objetivos de desarrollo específicos y medibles.',
  placeholder: 'Ejemplo: consolidarme como gerente de mercado para...',
  maxLength: 90,
  maxOptions: 3
};

export const SUCCESSS_MEASURES_SETTINGS = {
  title: 'Medidas de éxito',
  description: 'Formas de medición del alcance de tus objetivos.',
  placeholder: 'Ejemplo:  “xxxx Xxxx”',
  maxLength: 90,
  maxOptions: 3
};

export const EVALUATIONS_HISTORICAL_SETTINGS = {
  title: 'Historial de evaluaciones  (Máximo 3)',
  description: 'Evaluaciones formales que hayas realizado .',
  placeholder: 'Ejemplo: evaluación de desempeño...',
  maxLength: 80,
  maxOptions: 3
};

export const EVALUATIONS_RESULTS_SETTINGS = {
  title: 'Resultados de evaluación (Máximo 3)',
  description: 'Fortalezas y áreas de oportunidad identificadas en la(s) evaluación(es) realizada(s).',
  placeholder: 'Ejemplo: gestionar las iniciativas grupales...',
  maxLength: 80,
  maxOptions: 3
};

export const ASPIRATIONS_SETTINGS = {
  title: 'Aspiraciones (Máximo 3)',
  description: 'Roles o responsabilidades que te gustaria tener en el corto, mediano y largo plazo.',
  placeholder: 'Ejemplo: liderar un equipo...',
  maxLength: 80,
  maxOptions: 3
};

export const MORE_INFO_ABOUT_IDP_URL = 'https://one.walmart.com/content/mi-lugar/mi-desarrollo.html';

export const MI_DESARROLLO_PATH = '/mi-evolucion/mi-desarrollo';

export const MANAGE_IDP_PATH = `${MI_DESARROLLO_PATH}/gestiona-tu-IDP`;

export const EVALUATIONS_IDP_ROUTER_SETTINGS = {
  headerTitle: 'Sección 1',
  headerDescription: 'Evaluaciones formales en las que hayas participado los últimos dos años identificando fortalezas y áreas de oportunidad, Roles o responsabilidades que te gustaría tener en el corto, mediano y largo plazo e Ingresa el nombre de tu Mentor en el caso de tener uno.',
  path: `${MANAGE_IDP_PATH}/evaluaciones-y-aspiraciones`,
  section: 'Mi Evolución -> Mi Desarrollo -> Gestiona tu IDP -> Evaluaciones y aspiraciones',
  statusKey: 'statusEvaluations'
};
export const STRENGTHS_IDP_ROUTER_SETTINGS = {
  headerTitle: 'Sección 2',
  headerDescription: 'Fortalezas y Oportunidades de desarrollo  relacionadas con tu aspiración. Objetivos de desarrollo específicos y medibles y el tiempo e indicadores clave según tus objetivos de desarrollo.',
  path: `${MANAGE_IDP_PATH}/fortalezas-y-áreas-de-desarrollo`,
  section: 'Mi Evolución -> Mi Desarrollo -> Gestiona tu IDP -> Fortalezas y áreas de desarrollo',
  statusKey: 'statusStrenghts'
};
export const ACTIONS_IDP_ROUTER_SETTINGS = {
  headerTitle: 'Sección 3',
  headerDescription: 'Modelo 70:20:10 (70% experiencias que empujarán el logro, 20%  instancias de exposición y conexión y 10% instancias de formación y experiencias educativas.',
  path: `${MANAGE_IDP_PATH}/accionables-del-plan-mi-desarrollo`,
  section: 'Mi Evolución -> Mi Desarrollo -> Gestiona tu IDP -> Accionables del plan Mi Desarrollo',
  statusKey: 'statusActions'
};

export const IDP_PATHS = [
  EVALUATIONS_IDP_ROUTER_SETTINGS.path,
  STRENGTHS_IDP_ROUTER_SETTINGS.path,
  ACTIONS_IDP_ROUTER_SETTINGS.path
];

export const MI_DESARROLLO_TABS = [
  {
    title: '¿Qué es Mi Desarrollo?',
    key: MI_DESARROLLO_PATH
  },
  {
    title: 'Gestiona tu IDP',
    key: MANAGE_IDP_PATH
  }
];
export const getLabelStatusIDP = (status = '') => {
  switch (status) {
  case 'FINISHED':
    return 'Completo';
  default:
    return 'Pendiente';
  }
};

export const getIconStatusIDP = (status = '') => {
  switch (status) {
  case 'FINISHED':
    return 'icon-me-check success-icon-color';
  default:
    return 'icon-me-feedback-pending pending-icon-color';
  }
};

export const SETTINGS_BANNER = {
  imageBanner: `${completePath('web/mi-evolucion/banner/nuevas-competencias-banner.jpg')}`,
  imageAvatar: `${completePath('web/mi-evolucion/avatars/nuevas-competencias-avatar.jpg')}`,
  title: 'Mi Desarrollo',
  className: 'pr-3'
};

export const ABOUT_STEPS = [
  {
    title: 'Descubre',
    description: 'Descúbrete a ti mismo',
    modal: {
      title: 'Descubre',
      subtitle: 'Descúbrete a ti mismo:',
      description: '¿Quieres descubrir en qué destacas? ¿Cuáles son tus intereses? ¿Dónde están tus fortalezas y desafíos? Vive la experiencia de descubrirte con las herramientas que disponibilizamos para ti. ',
      link: 'https://one.walmart.com/content/mi-lugar/mi-desarrollo/descubre.html'
    }
  },
  {
    title: 'Define',
    description: 'Define dónde te visualizas',
    modal: {
      title: 'Define',
      subtitle: 'Define dónde te visualizas:',
      description: '¿Conoces los diferentes equipos afines a tus intereses? ¿Habrán otros equipos con los cuales hacer match? ¡Conoce la Compañía e identifica con qué equipo haces match!. ',
      link: 'https://one.walmart.com/content/mi-lugar/mi-desarrollo/define.html'
    }
  },
  {
    title: 'Diseña',
    description: 'Diseña tu IDP',
    modal: {
      title: 'Diseña',
      subtitle: 'Diseña tu IDP:',
      description: '¿Ya sabes cómo poner en práctica tu desarrollo? Te invitamos a diseñar tu Plan de Desarrollo Individual (IDP). ',
      link: 'https://one.walmart.com/content/mi-lugar/mi-desarrollo/disena.html'
    }
  },
  {
    title: 'Desarrolla',
    description: 'Desarrolla tu camino',
    modal: {
      title: 'Desarrolla',
      subtitle: 'Desarrolla tu camino:',
      description: 'Tu desarrollo es un proceso continuo, por lo que te invitamos a actualizar tu IDP y si lo crees necesario puedes descubrir, definir y diseñar nuevamente tu plan. ',
      link: 'https://one.walmart.com/content/mi-lugar/mi-desarrollo/desarrolla.html'
    }
  }
];

export const getInitialSeveralOptionsData = (initialData) => {
  const initialArrayData = [];

  map(initialData, (data) => {
    if (!isEmpty(data?.trim())) {
      initialArrayData.push(data);
    }
  });
  return isEmpty(initialArrayData) ? [DEFAULT_FORM_VALUE] : initialArrayData;
};

export const getArrayFieldValues = ({ list }) => {
  const newList = [];
  map(list, (value) => {
    if (!isEmpty(value?.trim())) {
      newList.push(value);
    }
  });

  return isEmpty(newList) ? [DEFAULT_FORM_VALUE] : newList;
};

export const getActionsFieldsToUpdate = ({
  IDP,
  developmentExperience,
  developmentExposure,
  developmentEducation,
  additionalRemarks,
  status
}) => {
  const newIDP = { rut: IDP.rut, status };

  newIDP.development_experience = getArrayFieldValues(
    {
      maxOption: DEVELOPMENT_EXPERIENCE_SETTINGS.maxOptions,
      list: developmentExperience
    }
  );
  newIDP.development_exposure = getArrayFieldValues(
    {
      maxOption: DEVELOPMENT_EXPOSURE_SETTINGS.maxOptions,
      list: developmentExposure
    }
  );
  newIDP.development_education = getArrayFieldValues(
    {
      maxOption: DEVELOPMENT_EDUCATION_SETTINGS.maxOptions,
      list: developmentEducation
    }
  );

  newIDP.additional_remarks = additionalRemarks;

  return newIDP;
};
export const getStrengthsFieldsToUpdate = ({
  IDP,
  keyStrengths,
  areasForDevelopment,
  developementPriorities,
  measuresOfSuccess,
  status
}) => {
  const newIDP = { rut: IDP.rut, status };
  newIDP.key_strenghts = getArrayFieldValues(
    {
      maxOption: DEVELOPMENT_EXPERIENCE_SETTINGS.maxOptions,
      list: keyStrengths
    }
  );
  newIDP.areas_for_development = getArrayFieldValues(
    {
      maxOption: DEVELOPMENT_EXPOSURE_SETTINGS.maxOptions,
      list: areasForDevelopment
    }
  );
  newIDP.development_priorities = getArrayFieldValues(
    {
      maxOption: DEVELOPMENT_EDUCATION_SETTINGS.maxOptions,
      list: developementPriorities
    }
  );
  newIDP.measures_of_success = getArrayFieldValues(
    {
      maxOption: DEVELOPMENT_EDUCATION_SETTINGS.maxOptions,
      list: measuresOfSuccess
    }
  );

  return newIDP;
};
export const getEvaluationsFieldsToUpdate = ({
  IDP,
  historial,
  results,
  aspirations,
  status,
  mentor,
  mentorWorksInWalmart
}) => {
  const newIDP = { rut: IDP.rut, status };
  newIDP.assessment_history = getArrayFieldValues(
    {
      maxOption: EVALUATIONS_HISTORICAL_SETTINGS.maxOptions,
      list: historial
    }
  );
  newIDP.assessment_insights = getArrayFieldValues(
    {
      maxOption: EVALUATIONS_RESULTS_SETTINGS.maxOptions,
      list: results
    }
  );
  newIDP.aspirations = getArrayFieldValues(
    {
      maxOption: ASPIRATIONS_SETTINGS.maxOptions,
      list: aspirations
    }
  );
  newIDP.mentor_optional = mentor;
  newIDP.mentor_works_in_walmart = mentorWorksInWalmart;

  return newIDP;
};

export const showAlertBeforeSaveIDP = ({
  status, setModalMessage = () => {}, updateIDP, loading
}) => {
  setModalMessage({
    icon: 'icon-ml-warning',
    visible: true,
    title: `¿Quieres ${ status === STATUS_IDP.pending ? 'actualizar' : 'finalizar'} tu plan Mi desarrollo (IDP)?`,
    buttons: [
      {
        text: 'Cancelar',
        className: 'button-border-primary'
      },
      {
        text: status === STATUS_IDP.pending ? 'Actualizar' : 'Finalizar',
        type: 'primary',
        loadingButton: loading,
        onClickButton: updateIDP
      }
    ]
  });
};

export const isIDPFormSelected = (pathname) => IDP_PATHS.includes(pathname);

export const isIDPFormSelectedInMobile = (pathname) => isMobile && isIDPFormSelected(pathname);

export const getStatusIDP = (IDP) => {
  const {
    statusActions, statusEvaluations, statusStrenghts, status
  } = IDP;

  return {
    statusActions, statusEvaluations, statusStrenghts, status
  };
};
export const setFormatDateIDP = (date) => (date ? customFormatDateCard(date, 'dddd[,] DD MMM YYYY') : '-');
export const getProfileInfo = ({ position, linealBoss, IDP }) => ([
  { label: 'Cargo actual', value: fullCapitalizeFormat(position) },
  { label: 'Nombre de Líder', value: getFullName(linealBoss) },
  {
    label: 'Estado de IDP',
    customValue:
<div>
  {IDP?.status
  && <div>
    <i className={getIconStatusIDP(IDP.status)} />
    <span className='text-value ml-2'>{IDP.status === STATUS_IDP.pending ? 'Pendiente'
      : `Finalizado ${setFormatDateIDP(IDP?.last_changes)}`
    }</span>
  </div>
  }
</div>
  }
]);
export const UCMs_TO_FILL_IDP_IN_ENGLISH = ['Líder Senior', 'Vicepresidente'];
export const IDP_MORE_DETAIL_LINK = 'https://one.walmart.com/content/mi-lugar/mi-desarrollo.html';

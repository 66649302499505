import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import {
  getDetailsByCodeReportsHistoryAPI,
  setRequestByCodeAPI
} from '../api/reports';

import {
  actionTypes,
  getDetailsByCodeReportsHistoryError,
  getDetailsByCodeReportsHistorySuccess,
  setRequestByCodeError,
  setRequestByCodeSuccess
} from '../actions/reports';

es6promise.polyfill();

export function* getDetailsByCodeReportsHistory(action) {
  try {
    const response = yield call(getDetailsByCodeReportsHistoryAPI, action.code);
    yield put(getDetailsByCodeReportsHistorySuccess(response));
  } catch (err) {
    const error = err?.response?.data ? get(err, 'response.data') : err;
    yield put(getDetailsByCodeReportsHistoryError(error));
  }
}
export function* setRequestByCode(action) {
  try {
    const response = yield call(setRequestByCodeAPI, action.code);
    yield put(setRequestByCodeSuccess(response));
  } catch (err) {
    const error = err?.response?.data ? get(err, 'response.data') : err;
    yield put(setRequestByCodeError(error));
  }
}

function* reportsSagas() {
  yield all([
    takeLatest(actionTypes.SET_REQUEST_BY_CODE, setRequestByCode),
    takeLatest(actionTypes.GET_DETAILS_BY_CODE_REPORTS_HISTORY, getDetailsByCodeReportsHistory)

  ]);
}

export default reportsSagas;

import { actionTypes } from '../actions/mural';

export const initialState = {
  additionError: false,
  adding: false,
  called: false,
  mgmt: '',
  rut: '',
  mural: [],
  newMural: [],
  createdby: [],
  reactions: {},
  result: [],
  error: null,
  loading: false,
  loadingAtFirst: false,
  loadingAtLast: false,
  type: '',
  hasMorePosts: true,
  page: 0,
  limit: 10,
  filterOptions: {
    all: 1, valoration: 0, post: 0, birthday: 0
  }
};

function reducer(state = initialState, action) {
  const { error, payload, variables } = action;
  switch (action.type) {
  case actionTypes.ALL_MURAL:
    return {
      ...state,
      loading: !variables.page,
      loadingAtLast: !!variables.page,
      limit: variables.limit,
      mgmt: variables.mgmt,
      mural: variables.page ? state.mural : [],
      page: variables.page,
      rut: variables.rut
    };
  case actionTypes.ALL_MURAL_SUCCESS: {
    const { limit, mural, page } = state;

    return {
      ...state,
      called: true,
      loading: false,
      loadingAtLast: false,
      error: null,
      mural: [...(page ? mural : []), ...action.mural.result],
      hasMorePosts: action.mural.result.length >= limit,
      page,
      type: ''
    };
  }
  case actionTypes.ALL_MURAL_ERROR:
    return {
      ...state,
      called: true,
      error,
      loading: false,
      mural: []
    };
  case actionTypes.ADD_POSTS:
    return {
      ...state,
      adding: true
    };
  case actionTypes.ADD_POSTS_SUCCESS:
    return {
      ...state,
      additionCode: payload.code,
      adding: false
    };
  case actionTypes.ADD_POSTS_ERROR:
    return {
      ...state,
      additionCode: error.statusCode,
      adding: false
    };

  case actionTypes.UPDATE_REACTIONS: {
    const reaction = payload.result;

    return {
      ...state,
      reactions: {
        ...state.reactions,
        [reaction._id]: {
          loading: false
        }
      }
    };
  }

  case actionTypes.RESET_POSTS_RESULTS:
    return {
      ...state,
      hasMorePosts: true,
      page: 0,
      limit: 0
    };
  case actionTypes.NO_MORE_RESULTS:
    return {
      ...state,
      hasMorePosts: false,
      loading: false,
      loadingAtLast: false
    };
  case actionTypes.NO_RESULTS:
    return {
      ...state,
      loading: false,
      mural: []
    };
  case actionTypes.FILTER_OPTION_UPDATE:
    return {
      ...state,
      filterOptions: payload
    };
  case actionTypes.FILTER_OPTION_RESET:
    return {
      ...state,
      filterOptions: actionTypes.filterOptions
    };
  case actionTypes.RESET_POSTS:
    return {
      ...initialState
    };
  default:
    return state;
  }
}

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';

const DownloadReport = ({
  nameFile,
  title,
  classIconNameLeft,
  classIconNameRight
}) => (<>
  <div className="down-report">
    <i className={classIconNameLeft} />
    <span className="down-file"> {title} {nameFile} </span>
    <i className={classIconNameRight} />
  </div>
</>);

DownloadReport.propTypes = {
  nameFile: PropTypes.string,
  title: PropTypes.string,
  classIconNameLeft: PropTypes.string,
  classIconNameRigh: PropTypes.string
};

DownloadReport.defaultProps = {
  nameFile: '',
  title: 'Descargar Reporte ',
  classIconNameLeft: 'icon-ml-excel',
  classIconNameRigh: 'icon-ml-file-download'
};

export default DownloadReport;

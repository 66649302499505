import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

const ButtonSave = (props) => {
  const {
    loading, closeOpinion, disabledButton, saveOpinion, labelButton
  } = props;
  return (

    <div className='d-flex flex-column justify-content-center align-items-center'>
      <Button
        loading={loading}
        disabled={disabledButton}
        type='primary'
        onClick={saveOpinion}
      >
        {labelButton}
      </Button>

      {closeOpinion
    && <Button
      className='button-border-primary cancel-button'
      onClick={closeOpinion}
    >
      Cancelar
    </Button>
      }
    </div>
  );
};
ButtonSave.propTypes = {
  loading: PropTypes.bool,
  closeOpinion: PropTypes.func,
  saveOpinion: PropTypes.func,
  labelButton: PropTypes.string,
  disabledButton: PropTypes.bool

};

ButtonSave.defaultProps = {
  loading: false,
  closeOpinion: null,
  saveOpinion: () => {},
  labelButton: 'Guardar',
  disabledButton: false
};
export default ButtonSave;

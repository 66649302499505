import React from 'react';
import PropTypes from 'prop-types';
import BehaviorContentAccordion from '../BehaviorContentAccordion';
import { SkillDetail } from '../MyBehaviorContent';

const DetailProfile = ({ skills }) => {
  const makeSkillStructureAccordion = (data) => (
    data.map((skill, index) => ({
      key: `skill-${skill.shortName}`,
      title: skill.shortName,
      children: <SkillDetail key={index} columns={1} skill={skill} />
    }))
  );

  return (
    <BehaviorContentAccordion
      behaviors={makeSkillStructureAccordion(skills)}
      className='accordion-skills-4c'
    />

  );
};

DetailProfile.propTypes = {
  skill: PropTypes.array
};

DetailProfile.defaultProps = {
  skill: []
};

export default DetailProfile;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import {
  actionTypes,
  getBankListSuccess,
  getBankListError
} from '../actions/banks';

import {
  getAPIBankList
} from '../api/banks';

es6promise.polyfill();

function* getBankList() {
  try {
    const response = yield call(getAPIBankList);
    yield put(getBankListSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getBankListError(error));
  }
}

function* banksSagas() {
  yield all([
    takeLatest(actionTypes.GET_BANK_LIST, getBankList)
  ]);
}

export default banksSagas;

export const actionTypes = {
  FAILURE: 'FAILURE',
  GET_CONTACT_DATA: 'GET_CONTACT_DATA',
  GET_CONTACT_DATA_SUCCESS: 'GET_CONTACT_DATA_SUCCESS',
  GET_CONTACT_DATA_FAILURE: 'GET_CONTACT_DATA_FAILURE',
  UPDATE_CONTACT_DATA: 'UPDATE_CONTACT_DATA',
  UPDATE_CONTACT_DATA_SUCCESS: 'UPDATE_CONTACT_DATA_SUCCESS',
  UPDATE_CONTACT_DATA_FAILURE: 'UPDATE_CONTACT_DATA_FAILURE',
  SEND_CONTACT_INFO: 'SEND_CONTACT_INFO',
  SEND_CONTACT_INFO_SUCCESS: 'SEND_CONTACT_INFO_SUCCESS',
  RESET_CONTACT_VIEW: 'RESET_CONTACT_VIEW',
  HANDLE_MODAL_SUCCESS: 'HANDLE_MODAL_SUCCESS'
};

export function handleModalSuccess() {
  return {
    type: actionTypes.HANDLE_MODAL_SUCCESS
  };
}
export function failure(error) {
  return {
    type: actionTypes.FAILURE,
    error
  };
}

export function getContactDataSuccess(data) {
  return {
    type: actionTypes.GET_CONTACT_DATA_SUCCESS,
    payload: data
  };
}

export function getContactDataFailure() {
  return {
    type: actionTypes.GET_CONTACT_DATA_FAILURE
  };
}

export function getContactData(data) {
  return {
    type: actionTypes.GET_CONTACT_DATA,
    payload: data
  };
}

export function updateContactData(data) {
  return {
    type: actionTypes.UPDATE_CONTACT_DATA,
    payload: data
  };
}

export function updateContactDataSuccess(data) {
  return {
    type: actionTypes.UPDATE_CONTACT_DATA_SUCCESS,
    payload: data
  };
}
export function updateContactDataFailure(error) {
  return {
    type: actionTypes.UPDATE_CONTACT_DATA_FAILURE,
    error
  };
}

export function sendContactInfo(data) {
  return {
    type: actionTypes.SEND_CONTACT_INFO,
    payload: data
  };
}

export function sendContactInfoSuccess() {
  return { type: actionTypes.SEND_CONTACT_INFO_SUCCESS };
}

export function resetContactView() {
  return { type: actionTypes.RESET_CONTACT_VIEW };
}

export const actionTypes = {
  FAILURE: 'FAILURE',
  GET_FAQS: 'GET_FAQS',
  GET_FAQS_SUCCESS: 'GET_FAQS_SUCCESS'
};

export function failure(error) {
  return {
    type: actionTypes.FAILURE,
    error
  };
}

export function getFaqs() {
  return { type: actionTypes.GET_FAQS };
}

export function getFaqsSuccess(data) {
  return {
    type: actionTypes.GET_FAQS_SUCCESS,
    data
  };
}

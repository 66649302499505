import React from 'react';
import PropTypes from 'prop-types';
import '../../../../../../../static/styles/mievolucion/myPerformance/objective-form-render-field.less';

const RenderField = ({
  label, value, direction, className
}) => {
  const getClassName = () => {
    let classNameResult = 'objective-form-render-field';
    switch (direction) {
    case 'column':
    case 'row':
      classNameResult += ` objective-form-render-field-${direction}`;
      break;
    default:
      break;
    }

    if (className) classNameResult += ` ${className}`;

    return classNameResult;
  };

  return (
    <div className={getClassName()}>
      <label>{label}:</label>
      {direction === 'row' && <>&nbsp;</>}
      <span>{value}</span>
    </div>
  );
};

RenderField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  direction: PropTypes.string,
  className: PropTypes.string
};

RenderField.defaultProps = {
  label: PropTypes.string,
  value: PropTypes.string,
  direction: 'row',
  className: ''
};

export default RenderField;

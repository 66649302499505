import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import '../../../static/styles/shared/tabs/MLSecondaryTabs.less';

const { TabPane } = Tabs;

export const setUrl = (value) => {
  if (typeof value === 'string') {
    return value.replace(/\s/g, '-').toLowerCase();
  }
  return '';
};

const MLSecondaryTabsWithChildren = (props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    children, options, defaultActiveKey, activeTab, className, classNameTabPane, firstTab
  } = props;

  const onTabClick = (i) => {
    history.push(i);
  };

  const activeSubrouteTab = options?.find(({ key }) => !pathname.indexOf(key))?.key;

  if (firstTab) history.replace(options?.find(({ visible }) => visible)?.key);

  return (
    <Tabs
      onTabClick={onTabClick}
      activeKey={activeTab || activeSubrouteTab}
      defaultActiveKey={defaultActiveKey}
      className={`ml-secondary-tabs ${className}`}
      type="card"
    >
      { options.map((option, index) => (
        <TabPane
          tab={option.title}
          key={option.key || index}
          className={`content-tab ${classNameTabPane}` }
        >
          {option.children || children}
        </TabPane>
      ))}
    </Tabs>
  );
};
MLSecondaryTabsWithChildren.propTypes = {
  children: PropTypes.element,
  options: PropTypes.array,
  firstTab: PropTypes.bool,
  defaultActiveKey: PropTypes.string,
  className: PropTypes.string,
  classNameTabPane: PropTypes.string
};

MLSecondaryTabsWithChildren.defaultProps = {
  children: <></>,
  options: [],
  firstTab: false,
  defaultActiveKey: '0',
  className: '',
  classNameTabPane: ''
};

export default MLSecondaryTabsWithChildren;

import React from 'react';
import Slider from './Slider';

const MLStepSliders = ({
  arrows = false, dots = true, ...rest
}) => (
  <>
    {arrows && <Slider {...{ ...rest, arrows }}/>}
    {dots && <Slider {...{ ...rest, dots }}/>}
  </>
);

export default MLStepSliders;

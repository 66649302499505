import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import Skill from './Skill';

const SkillsTable = ({
  columnsNames,
  columnsClasses,
  skills,
  className
}) => (
  <div className={className}>
    <div className='container-table'>
      <div className={'container-header'}>
        {columnsNames.map((columnName, key) => (
          <div key={key} className={`header-summary ${columnsClasses?.[key] || 'left'}`}>
            <span>{columnName}</span>
          </div>
        ))}
      </div>

      {map(skills, (skill, i) => (
        <Skill key={i}
          skill={skill}
          index={i}
        />
      ))}
    </div>
  </div>
);

SkillsTable.propTypes = {
  columnsNames: PropTypes.array,
  skills: PropTypes.array,
  className: PropTypes.string,
  columnsClasses: PropTypes.array
};

SkillsTable.defaultProps = {
  columnsNames: [],
  skills: [],
  className: 'container-evaluation-summary',
  columnsClasses: null
};

export default SkillsTable;

import React from 'react';
import PropTypes from 'prop-types';
import {
  filter
} from 'lodash';
import ObjectivesTable from './ObjectivesTable';

const Objectives = (props) => {
  const {
    objectives
  } = props;

  const objectivesTribu = filter(objectives, ({ tribu = false }) => tribu);

  const objectivesOld = filter(objectives, ({ tribu = false }) => !tribu);
  return (

    <div>
      <ObjectivesTable {...{
        className: 'mt-4',
        title: objectivesOld?.length ? 'Objetivos del nuevo rol' : 'Objetivos',
        objectives: objectivesTribu
      }} />
      <ObjectivesTable {...{
        className: 'mt-4',
        objectivesTribu: objectivesTribu?.length || 0,
        title: objectivesTribu?.length > 0 ? 'Objetivos del cargo anterior' : 'Objetivos',
        objectives: objectivesOld
      }} />
    </div>
  );
};

Objectives.propTypes = {
  objectives: PropTypes.array
};

Objectives.defaultProps = {
  objectives: []
};

export default Objectives;

import getSuccessMeasures from './getSuccessMeasures';

const getInputForEditObjective = (
  values
) => {
  try {
    const { visible, weight, successMeasures } = values;

    const input = {
      ...values,
      successMeasures: getSuccessMeasures(successMeasures),
      visible: !visible,
      weight: Number(weight.replace(/[^\d.]/g, ''))
    };
    return input;
  } catch {
    return null;
  }
};

export default getInputForEditObjective;

import React from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import {
  POSITION
} from '../../../../helpers/myEvolution/interestsAndSkills';
import { Item } from '../Form';
import Position from './Position';

const LastPositions = (props) => {
  const {
    lastPositions,
    setLastPositions,
    listYearExperiences,
    getFieldDecorator,
    listOptionsWorkExperienceArea
  } = props;

  const updatePosition = (value, index) => {
    const newLastPositions = lastPositions.map(
      (item, i) => (index === i ? { ...item, ...value } : item)
    );
    setLastPositions(newLastPositions);
  };

  const addOtherPlatform = (e) => {
    e.preventDefault();
    const newLastPositions = Object.assign([], lastPositions);
    newLastPositions.push(
      POSITION
    );
    setLastPositions(newLastPositions);
  };

  return (
    <>
      <Item
        label='¿Cuáles fueron tus últimos 3 cargos fuera de la compañía?'
      >
        {map(lastPositions, (position, i) => (
          <Position
            key={i}
            index={i}
            updatePosition={updatePosition}
            listYearExperiences={listYearExperiences}
            position={position}
            lastPositions={lastPositions}
            setLastPositions={setLastPositions}
            getFieldDecorator={getFieldDecorator}
            listOptionsWorkExperienceArea={listOptionsWorkExperienceArea}
          />
        ))}
        { lastPositions.length < 3
            && <p className='text-add-other' onClick={addOtherPlatform}>Agregar otro</p>
        }
      </Item>
    </>
  );
};
LastPositions.propTypes = {
  lastPositions: PropTypes.array,
  setLastPositions: PropTypes.func,
  listYearExperiences: PropTypes.array,
  getFieldDecorator: PropTypes.func,
  listOptionsWorkExperienceArea: PropTypes.array
};

LastPositions.defaultProps = {
  lastPositions: [],
  setLastPositions: () => {},
  listYearExperiences: [],
  getFieldDecorator: () => {},
  listOptionsWorkExperienceArea: []
};

export default LastPositions;

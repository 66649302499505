import React from 'react';
import PropTypes from 'prop-types';
import {
  Select, Radio, Input
} from 'antd';
import { map } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Item } from '../Form';
import { WITHOUTINFO } from '../../../../helpers/myEvolution/interestsAndSkills';

const { Option } = Select;

const EnglishLevel = (props) => {
  const {
    getFieldDecorator,
    writtenEnglishLevel,
    oralEnglishLevel,
    englishCertification,
    haveEnglishCertification,
    setEnglishCertification,
    setHaveEnglishCertification,
    listOralEnglishLevel,
    listWrittenEnglishLevel
  } = props;

  const onChangeHaveEnglishCertification = ({ target }) => {
    setHaveEnglishCertification(target.value);
  };

  const addOtherEnglishCertification = (e) => {
    e.preventDefault();
    const newEnglishCertification = Object.assign([], englishCertification);
    newEnglishCertification.push({ name: '', _id: uuidv4() });
    setEnglishCertification(newEnglishCertification);
  };

  const onChangeEnglishCertification = (value, _id) => {
    const newEnglishCertification = englishCertification.map(
      (item) => (item._id === _id ? { ...item, name: value } : item)
    );
    setEnglishCertification(newEnglishCertification);
  };
  const removeEnglishCertification = (i) => {
    const newEnglishCertification = Object.assign([], englishCertification);
    newEnglishCertification.splice(i, 1);
    setEnglishCertification(newEnglishCertification);
  };
  return (
    <>

      <div className='row'>
        <Item
          className='col-12 col-md-12 col-lg-6'
          label='¿Cuál es tu nivel de inglés oral?'
          description='(Ej: Participar en reuniones)'
        >
          {getFieldDecorator('oralEnglishLevel',
            {
              ...(oralEnglishLevel !== 'WITHOUTINFO' ? { initialValue: oralEnglishLevel } : null)
            })(
            <Select placeholder='Selecciona el nivel'>
              {map(listOralEnglishLevel, (item, i) => (
                <Option key={i} value={item.codeValue}>{item.name}</Option>
              ))}

            </Select>
          )}
        </Item>
        <Item
          className='col-12 col-md-12 col-lg-6'
          label='¿Cuál es tu nivel de inglés escrito?'
          description='(Ej: Comunicación por mail)'
        >
          {getFieldDecorator('writtenEnglishLevel',
            {
              ...(writtenEnglishLevel !== 'WITHOUTINFO' ? { initialValue: writtenEnglishLevel } : null)
            })(
            <Select placeholder='Selecciona el nivel'>
              {map(listWrittenEnglishLevel, (item, i) => (
                <Option key={i} value={item.codeValue}>{item.name}</Option>
              ))}

            </Select>
          )}
        </Item>
      </div>
      <div className='row'>
        <Item
          className='col-12 col-md-12 col-lg-6'
          label='¿Cuentas con alguna certificación?'
        >
          <Radio.Group
            className='ml-radio-button'
            defaultValue={haveEnglishCertification}
            onChange={onChangeHaveEnglishCertification} >
            <Radio value={'YES'}>
              Si
            </Radio>
            <Radio value={'NO'}>
              No
            </Radio>
          </Radio.Group>
        </Item>
      </div>

      { haveEnglishCertification === 'YES'
        ? <div>
          {
            map(englishCertification, (item, i) => (
              <div className='row' key={i}>
                <Item

                  label='¿Cuál?'
                  className='col-12 col-md-12 col-lg-6'
                >
                  <Input
                    key={item._id}
                    value={item.name}
                    onChange={({ target }) => {
                      onChangeEnglishCertification(target.value, item._id);
                    }}
                    placeholder='Nombre de las certificación' />
                  { englishCertification.length > 1
            && <p className='text-delete' onClick={(e) => {
              e.preventDefault();
              removeEnglishCertification(i);
            }
            }
            >Eliminar</p>
                  }

                </Item>
              </div>
            ))
          }
          { englishCertification.length < 3
          && <p className='text-add-other' onClick={addOtherEnglishCertification}>Agregar otra</p>
          }
        </div>
        : null
      }

    </>

  );
};
EnglishLevel.propTypes = {
  getFieldDecorator: PropTypes.func,
  writtenEnglishLevel: PropTypes.string,
  oralEnglishLevel: PropTypes.string,
  englishCertification: PropTypes.array,
  haveEnglishCertification: PropTypes.string,
  setEnglishCertification: PropTypes.func,
  setHaveEnglishCertification: PropTypes.func,
  listOralEnglishLevel: PropTypes.array,
  listWrittenEnglishLevel: PropTypes.array
};

EnglishLevel.defaultProps = {
  getFieldDecorator: () => {},
  writtenEnglishLevel: '',
  oralEnglishLevel: '',
  englishCertification: [],
  haveEnglishCertification: WITHOUTINFO,
  setEnglishCertification: () => {},
  setHaveEnglishCertification: () => {},
  listOralEnglishLevel: [],
  listWrittenEnglishLevel: []
};

export default EnglishLevel;

import { formatDateHelp } from '../strings';

const searchTableHelp = (typeValue, originalData) => originalData.filter((data) => {
  const { closingDate, requestDate } = data;
  const convertedClosingDate = formatDateHelp(closingDate, 'DD/MM/YYYY');
  const convertedRequestDate = formatDateHelp(requestDate, 'DD/MM/YYYY');

  return data.idCaseNumber?.toLowerCase().includes(typeValue.toLowerCase())
  || data.status?.toLowerCase().includes(typeValue.toLowerCase())
  || convertedClosingDate?.includes(typeValue)
  || convertedRequestDate?.includes(typeValue)
  || data.comment?.toLowerCase().includes(typeValue.toLowerCase())
  || data.type?.toLowerCase().includes(typeValue.toLowerCase());
});

export default searchTableHelp;

import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import BannerLayout from '../../layouts/banner';
import MLTabs from '../../components/shared/MLTabs/MLTabsByRoute';
import {
  MANAGE_IDP_PATH,
  MI_DESARROLLO_TABS,
  SETTINGS_BANNER,
  isIDPFormSelected,
  isIDPFormSelectedInMobile
} from '../../helpers/myEvolution/miDesarrollo';
import '../../static/styles/mievolucion/miDesarrollo/styles.less';
import IDPProfile from '../../components/mievolucion/MiDesarrollo/IDPProfile';
import MainMessage from '../../components/mievolucion/MiDesarrollo/MainMessage';

const MiDesarrollo = (props) => {
  const { pathname } = useLocation();

  const {
    children
  } = props;

  const getActiveTab = () => {
    if (isIDPFormSelected(pathname)) {
      return MANAGE_IDP_PATH;
    }
    return pathname;
  };

  const Tabs = <MLTabs
    options={MI_DESARROLLO_TABS}
    defaultActiveKey={pathname}
    activeTab={getActiveTab()}
    className='no-padding-margin'
    tabsOnly
  />;

  const DesktopVersion = (
    <BannerLayout {...{
      ...SETTINGS_BANNER
    }}>
      <IDPProfile />
      <MainMessage />
      {Tabs}
      {children}
    </BannerLayout>
  );

  const MobileVersion = (
    <Fragment>
      {!isIDPFormSelectedInMobile(pathname) && Tabs}
      {
        <>
          {children}
        </>
      }
    </Fragment>
  );

  return (
    <div>
      {isMobile ? MobileVersion : DesktopVersion }
    </div>
  );
};

export default MiDesarrollo;

import React from 'react';

import { unmaskCurrency } from '../filters/salaryMyTeam';
import { dateFilter, numberFilter, stringFilter } from '../../table';

const renderPositioning = (percentage) => (
  <span className={ percentage >= 80 && percentage <= 120 ? 'green' : 'pink'}>{`${percentage}%`}</span>
);

const currencyFilter = (a, b, field) => unmaskCurrency(a[field]) - unmaskCurrency(b[field]);

export const columns = [
  {
    title: 'Colaborador',
    width: 150,
    dataIndex: 'fullName',
    key: '0',
    fixed: 'left',
    sorter: (a, b) => stringFilter(a, b, 'fullName'),
    sortDirections: ['descend', 'ascend']
  },
  {
    title: 'Cargo',
    dataIndex: 'position',
    key: '1',
    sorter: (a, b) => stringFilter(a, b, 'position'),
    sortDirections: ['descend', 'ascend'],
    width: 180
  },
  {
    title: 'Ingreso',
    dataIndex: 'dateOld',
    width: 120,
    key: '2',
    sorter: (a, b) => dateFilter(a, b, 'dateOld'),
    sortDirections: ['descend', 'ascend']
  },
  {
    title: 'Antigüedad último cargo',
    dataIndex: 'positionOld',
    key: '3',
    width: 150,
    sorter: (a, b) => dateFilter(a, b, 'positionOld'),
    sortDirections: ['descend', 'ascend']
  },
  {
    title: 'Banda',
    dataIndex: 'band',
    key: '4',
    width: 120,
    sorter: (a, b) => stringFilter(a, b, 'band'),
    sortDirections: ['descend', 'ascend']
  },
  {
    title: 'Posicionam.',
    dataIndex: 'positioning',
    key: '5',
    width: 130,
    render: renderPositioning,
    sorter: (a, b) => numberFilter(a, b, 'positioning'),
    sortDirections: ['descend', 'ascend']
  },
  {
    title: 'Sueldo base actual',
    dataIndex: 'salary',
    key: '6',
    width: 120,
    sorter: (a, b) => currencyFilter(a, b, 'salary'),
    sortDirections: ['descend', 'ascend']
  },
  {
    title: 'Último incremento',
    dataIndex: 'validDueDate',
    key: '7',
    width: 130,
    sorter: (a, b) => dateFilter(a, b, 'validDueDate'),
    sortDirections: ['descend', 'ascend']
  },
  {
    title: 'Motivo último incremento',
    dataIndex: 'reason',
    key: '8',
    width: 150,
    sorter: (a, b) => stringFilter(a, b, 'reason'),
    sortDirections: ['descend', 'ascend']
  },
  {
    title: 'Desempeño',
    dataIndex: 'performanceCategory',
    key: '9',
    width: 130,
    sorter: (a, b) => {
      if (!a.performanceCategory) return -1;
      if (!b.performanceCategory) return +1;
      return stringFilter(a, b, 'performanceCategory');
    },
    sortDirections: ['descend', 'ascend']
  },
  {
    title: 'Talento',
    dataIndex: 'talentCategory',
    key: '10',
    width: 130,
    sorter: (a, b) => {
      if (!a.talentCategory) return -1;
      if (!b.talentCategory) return +1;
      return stringFilter(a, b, 'talentCategory');
    },
    sortDirections: ['descend', 'ascend']
  }

];

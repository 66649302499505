/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { categoryFields } from '../performanceCategories/queries';

const feedback = `
    {
        _id
        subject
        isGroup
        status
        feedbackType
        createdBy
        createdAt
        shared
        isFeedbackNetwork
        type
        situation
        notFeedbackReason {
            comment
            defaultAnswer
        }
        toUserUCM
    }
`;

const user = `
    {
        name
        secondName
        motherLastName
        fatherLastName
        position
        rut
        area1
        division
        HOOPP
    }
`;
export const VALIDATE_USER_BEFORE_CREATE_FEEDBACK = gql`
    query validateUserBeforeCreateFeedback(
        $rut: String!
        $feedbackType: FeedbackType!
    ) {
        validateUserBeforeCreateFeedback(
            rut: $rut
            feedbackType: $feedbackType
        ) 
    }
`;

const comments = `
    {
        _id
        content
        otherSkillName
        skill {
            _id
            name
            description

            
        }
        performanceCategory ${categoryFields}
    }
`;

export const GET_INITIAL_DATA = gql`
    query {
        listDefaultFeedbackAnswers {
            _id
            name
            type
            hasComment
        }
    }
`;

export const MY_FEEDBACKS = gql`
    query myFeedbacks(
        $isBoss: BossInput
    ) {
        myFeedbacks(
            isBoss: $isBoss
        ) {
            feedback ${feedback}
            comments ${comments}
            user ${user}
        }
    }
`;

export const OTHERS_FEEDBACKS = gql`
    query {
        feedbackToOthers {
            feedback ${feedback}
            comments ${comments}
            user ${user}
        }
    }
`;

export const MY_TEAM_FEEDBACKS = gql`
    query {
        myTeamFeedbacks{
            name
            secondName
            fatherLastName
            motherLastName
            position
            rut
        }
    }
`;

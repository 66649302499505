import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SkillsTable from './SkillsTable';
import MLBoxMessage from '../../../../shared/MLBoxMessage';
import MLSimpleCollapse from '../../../../shared/MLSimpleCollapse';
import ContainerCategory from './ContainerCategory';
import '../../../../../static/styles/mievolucion/retro/evaluationSummary.less';
import { DRAFT, PENDING } from '../../../../../helpers/myEvolution/evaluations';

const EvaluationSummary = (props) => {
  const {
    showEdit,
    handelEditEvaluation,
    classes,
    evaluation,
    showFinalCategory
  } = props;
  const {
    strengths,
    opportunities,
    status,
    skills,
    finalCategory
  } = evaluation;

  const ContainerEvaluation = showEdit ? Fragment : MLSimpleCollapse;

  const columnsNames = ['Competencias', 'Categorías'];
  const columnsClasses = ['left', 'right'];

  const FinallyCategory = ({ className = '' }) => (<MLBoxMessage
    classes={className}
    color={'default'}
    separateTitle={false}
    boxTitle={ {
      label: 'Resultado de la evaluación',
      value: finalCategory.name
    }}
  >
    <p>{finalCategory?.finalSkillDescription}</p>
  </MLBoxMessage>);
  return (
    <div className={`container-retro-summary ${classes}`}>
      {finalCategory && showFinalCategory
      && <FinallyCategory />
      }
      <ContainerEvaluation
        {
          ...(!showEdit ? {
            title: 'evaluación',
            open: false,
            classes: 'collapse-see-more'
          } : null)
        }
      >
        <ContainerCategory {...{
          showCategory: false,
          category: finalCategory,
          type: 'skill',
          showEdit,
          keyDescription: 'description'
        }}>
          <SkillsTable
            columnsClasses={columnsClasses}
            columnsNames={columnsNames}
            skills={skills}
            showEdit={showEdit}
            handelEditEvaluation={handelEditEvaluation} />
        </ContainerCategory>
        { !status.includes(PENDING, DRAFT)
      && <div>
        <div className='container-table separator'>
          <div className='container-header'>
            <div className='header-summary right'>
              <span>Fortalezas</span>
            </div>
          </div>
          <p>{strengths}</p>
        </div>
        <div className='container-table separator'>
          <div className='container-header'>
            <div className='header-summary right'>
              <span>Oportunidades de mejora</span>
            </div>
          </div>
          <p>{opportunities}</p>
        </div>
      </div>
        }
      </ContainerEvaluation>
    </div>

  );
};

EvaluationSummary.propTypes = {
  handelEditEvaluation: PropTypes.func,
  classes: PropTypes.string,
  strengths: PropTypes.string,
  opportunities: PropTypes.string,
  showFinalCategory: PropTypes.bool,
  evaluation: PropTypes.object
};

EvaluationSummary.defaultProps = {
  handelEditEvaluation: () => {},
  showEdit: false,
  classes: '',
  strengths: null,
  opportunities: null,
  showFinalCategory: true,
  evaluation: {}
};

export default EvaluationSummary;

export const actionTypes = {
  WALIFAILURE: 'WALIFAILURE',
  GET_USER_INFO: 'GET_USER_INFO',
  GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
  GET_PENDING_USER: 'GET_PENDING_USER',
  GET_PENDING_USER_SUCCESS: 'GET_PENDING_USER_SUCCESS',
  GET_PENDING_USER_ERROR: 'GET_PENDING_USER_ERROR',
  GET_PENDING_USER_RESET_DATA: 'GET_PENDING_USER_RESET_DATA',
  VALIDATE_STATUS: 'VALIDATE_STATUS',
  VALIDATE_STATUS_SUCCESS: 'VALIDATE_STATUS_SUCCESS',
  VALIDATE_STATUS_RESET_DATA: 'VALIDATE_STATUS_RESET_DATA',
  VALIDATE_STATUS_ERROR: 'VALIDATE_STATUS_ERROR',
  GET_SURVEY: 'GET_SURVEY',
  GET_SURVEY_SUCCESS: 'GET_SURVEY_SUCCESS',
  SEND_SURVEY_RESPONSE: 'SEND_SURVEY_RESPONSE',
  SEND_SURVEY_RESPONSE_SUCCESS: 'SEND_SURVEY_RESPONSE_SUCCESS',
  GET_USER_INFO_COL: 'GET_USER_INFO_COL',
  GET_USER_INFO_COL_SUCCESS: 'GET_USER_INFO_COL_SUCCESS',
  CONTROL_ACCESS: 'CONTROL_ACCESS',
  CONTROL_ACCESS_SUCCESS: 'CONTROL_ACCESS_SUCCESS',
  CONTROL_ACCESS_RESET_DATA: 'CONTROL_ACCESS_RESET_DATA',
  CONTROL_ACCESS_ERROR: 'CONTROL_ACCESS_ERROR',
  SEND_SURVEY_RESPONSE_RESET: 'SEND_SURVEY_RESPONSE_RESET',
  GET_USER_INFO_COL_RESET: 'GET_USER_INFO_COL_RESET',
  GET_SURVEY_RESET: 'GET_SURVEY_RESET',
  GET_USER_INFO_COL_ERROR: 'GET_USER_INFO_COL_ERROR',
  SEND_SURVEY_RESPONSE_ERROR: 'SEND_SURVEY_RESPONSE_ERROR',
  SEND_ASSIGN_STATUS: 'SEND_ASSIGN_STATUS',
  SEND_ASSIGN_STATUS_SUCCESS: 'SEND_ASSIGN_STATUS_SUCCESS',
  SEND_ASSIGN_STATUS_RESET_DATA: 'SEND_ASSIGN_STATUS_RESET_DATA',
  SEND_ASSIGN_STATUS_ERROR: 'SEND_ASSIGN_STATUS_ERROR',
  SEND_CLOSING_CASE: 'SEND_CLOSING_CASE',
  SEND_CLOSING_CASE_SUCCESS: 'SEND_CLOSING_CASE_SUCCESS',
  SEND_CLOSING_CASE_RESET: 'SEND_CLOSING_CASE_RESET',
  SEND_CLOSING_CASE_ERROR: 'SEND_CLOSING_CASE_ERROR',
  SEND_EXTERNAL_USER: 'SEND_EXTERNAL_USER',
  SEND_EXTERNAL_USER_SUCCESS: 'SEND_EXTERNAL_USER_SUCCESS',
  SEND_EXTERNAL_USER_ERROR: 'SEND_EXTERNAL_USER_ERROR',
  SEND_EXTERNAL_USER_RESET: 'SEND_EXTERNAL_USER_RESET',
  GET_CONFIG: 'GET_CONFIG',
  GET_CONFIG_SUCCESS: 'GET_CONFIG_SUCCESS',
  GET_CONFIG_RESET_DATA: 'GET_CONFIG_RESET_DATA',
  GET_CONFIG_ERROR: 'GET_CONFIG_ERROR',
  GET_GAMA_REPORT: 'GET_GAMA_REPORT',
  GET_GAMA_REPORT_SUCCESS: 'GET_GAMA_REPORT_SUCCESS',
  GET_GAMA_REPORT_ERROR: 'GET_GAMA_REPORT_ERROR',
  GET_GAMA_REPORT_RESET: 'GET_GAMA_REPORT_RESET',
  GET_LOCATIONS_ON_CHARGE: 'GET_LOCATIONS_ON_CHARGE',
  GET_LOCATIONS_ON_CHARGE_SUCCESS: 'GET_LOCATIONS_ON_CHARGE_SUCCESS',
  GET_LOCATIONS_ON_CHARGE_ERROR: 'GET_LOCATIONS_ON_CHARGE_ERROR',
  GET_LOCATIONS_ON_CHARGE_RESET: 'GET_LOCATIONS_ON_CHARGE_RESET'
};

export function waliFailure(data) {
  return {
    type: actionTypes.WALIFAILURE,
    payload: data
  };
}

export function getUserInfo() {
  return {
    type: actionTypes.GET_USER_INFO
  };
}

export function getUserInfoSuccess(data) {
  return {
    type: actionTypes.GET_USER_INFO_SUCCESS,
    data
  };
}

export function getPendingUser() {
  return {
    type: actionTypes.GET_PENDING_USER
  };
}

export function getPendingUserSuccess(data) {
  return {
    type: actionTypes.GET_PENDING_USER_SUCCESS,
    data
  };
}

export function getPendingUserResetData() {
  return {
    type: actionTypes.GET_PENDING_USER_RESET_DATA
  };
}

export function getPendingUserError() {
  return {
    type: actionTypes.GET_PENDING_USER_ERROR
  };
}

export function validateStatus(data) {
  return {
    type: actionTypes.VALIDATE_STATUS,
    payload: data
  };
}

export function validateStatusSuccess(data) {
  return {
    type: actionTypes.VALIDATE_STATUS_SUCCESS,
    data
  };
}

export function getSurvey(payload) {
  return {
    type: actionTypes.GET_SURVEY,
    payload
  };
}

export function getSurveySuccess(data) {
  return {
    type: actionTypes.GET_SURVEY_SUCCESS,
    data
  };
}

export function sendSurveyResponse(payload) {
  return {
    type: actionTypes.SEND_SURVEY_RESPONSE,
    payload
  };
}

export function sendSurveyResponseSuccess(data) {
  return {
    type: actionTypes.SEND_SURVEY_RESPONSE_SUCCESS,
    data
  };
}

export function getUserInfoCol(payload) {
  return {
    type: actionTypes.GET_USER_INFO_COL,
    payload
  };
}

export function getUserInfoColSuccess(data) {
  return {
    type: actionTypes.GET_USER_INFO_COL_SUCCESS,
    data
  };
}

export function validateStatusResetData() {
  return {
    type: actionTypes.VALIDATE_STATUS_RESET_DATA
  };
}

export function validateStatusError() {
  return {
    type: actionTypes.VALIDATE_STATUS_ERROR
  };
}
export function controlAccess(data) {
  return {
    type: actionTypes.CONTROL_ACCESS,
    payload: data
  };
}

export function controlAccessSuccess(data) {
  return {
    type: actionTypes.CONTROL_ACCESS_SUCCESS,
    data
  };
}

export function controlAccessResetData() {
  return {
    type: actionTypes.CONTROL_ACCESS_RESET_DATA
  };
}

export function controlAccessError(error) {
  return {
    type: actionTypes.CONTROL_ACCESS_ERROR,
    error
  };
}

export function sendSurveyResponseReset() {
  return {
    type: actionTypes.SEND_SURVEY_RESPONSE_RESET
  };
}

export function getUserInfoColReset() {
  return {
    type: actionTypes.GET_USER_INFO_COL_RESET
  };
}

export function getSurveyReset() {
  return {
    type: actionTypes.GET_SURVEY_RESET
  };
}

export function getUserInfoColError(error) {
  return {
    type: actionTypes.GET_USER_INFO_COL_ERROR,
    error
  };
}

export function sendSurveyResponseError(error) {
  return {
    type: actionTypes.SEND_SURVEY_RESPONSE_ERROR,
    error
  };
}

export function sendAssignStatus(data) {
  return {
    type: actionTypes.SEND_ASSIGN_STATUS,
    payload: data
  };
}

export function sendAssignStatusSuccess(data) {
  return {
    type: actionTypes.SEND_ASSIGN_STATUS_SUCCESS,
    data
  };
}

export function sendClosingCase(payload) {
  return {
    type: actionTypes.SEND_CLOSING_CASE,
    payload
  };
}

export function sendClosingCaseSuccess(data) {
  return {
    type: actionTypes.SEND_CLOSING_CASE_SUCCESS,
    data
  };
}

export function sendAssignStatusResetData() {
  return {
    type: actionTypes.SEND_ASSIGN_STATUS_RESET_DATA
  };
}

export function sendAssignStatusError(error) {
  return {
    type: actionTypes.SEND_ASSIGN_STATUS_ERROR,
    error
  };
}

export function sendClosingCaseError(error) {
  return {
    type: actionTypes.SEND_CLOSING_CASE_ERROR,
    error
  };
}

export function sendClosingCaseReset() {
  return {
    type: actionTypes.SEND_CLOSING_CASE_RESET
  };
}

export function sendExternalUser(data) {
  return {
    type: actionTypes.SEND_EXTERNAL_USER,
    payload: data
  };
}

export function sendExternalUserSuccess(data) {
  return {
    type: actionTypes.SEND_EXTERNAL_USER_SUCCESS,
    data
  };
}

export function getConfig() {
  return {
    type: actionTypes.GET_CONFIG
  };
}

export function getConfigSuccess(data) {
  return {
    type: actionTypes.GET_CONFIG_SUCCESS,
    data
  };
}

export function sendExternalUserError(error) {
  return {
    type: actionTypes.SEND_EXTERNAL_USER_ERROR,
    error
  };
}

export function sendExternalUserReset() {
  return {
    type: actionTypes.SEND_EXTERNAL_USER_RESET
  };
}

export function getConfigResetData() {
  return {
    type: actionTypes.GET_CONFIG_RESET_DATA
  };
}

export function getConfigError(error) {
  return {
    type: actionTypes.GET_CONFIG_ERROR,
    error
  };
}
export function getGamaReport(data) {
  return {
    type: actionTypes.GET_GAMA_REPORT,
    payload: data
  };
}

export function getGamaReportSuccess(data) {
  return {
    type: actionTypes.GET_GAMA_REPORT_SUCCESS,
    data
  };
}

export function getGamaReportError(error) {
  return {
    type: actionTypes.GET_GAMA_REPORT_ERROR,
    error
  };
}

export function getGamaReportReset() {
  return {
    type: actionTypes.GET_GAMA_REPORT_RESET
  };
}

export function getLocationsOnCharge(data) {
  return {
    type: actionTypes.GET_LOCATIONS_ON_CHARGE,
    payload: data
  };
}

export function getLocationsOnChargeSuccess(data) {
  return {
    type: actionTypes.GET_LOCATIONS_ON_CHARGE_SUCCESS,
    data
  };
}

export function getLocationsOnChargeError(error) {
  return {
    type: actionTypes.GET_LOCATIONS_ON_CHARGE_ERROR,
    error
  };
}

export function getLocationsOnChargeReset() {
  return {
    type: actionTypes.GET_LOCATIONS_ON_CHARGE_RESET
  };
}

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import { getPayingAPI, sendPayingAPI } from '../api/paying';
import { actionTypes, getPayingSuccess, sendPayingSuccess } from '../actions/paying';
import {
  failure, loading, loadComplete, loadingAPI, loadingAPIComplete
} from '../actions/commons';

es6promise.polyfill();

export function* getPaying(data) {
  try {
    yield put(loading());
    const response = yield call(getPayingAPI, data.countMonth);
    yield put(getPayingSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

export function* sendPaying(actions) {
  try {
    yield put(loadingAPI(actions.payload.loader));
    const { countMonth } = actions.payload;
    const response = yield call(sendPayingAPI, countMonth);
    yield put(sendPayingSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
  } finally {
    yield put(loadingAPIComplete(actions.payload.loader));
  }
}

function* payingSagas() {
  yield all([
    takeLatest(actionTypes.GET_PAYING, getPaying),
    takeLatest(actionTypes.SEND_PAYING, sendPaying)
  ]);
}

export default payingSagas;

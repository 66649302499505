import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import {
  getUniformsByRutReportAPI,
  getUniformsByGarmentReportAPI,
  updateUniformsAPI,
  getUniformsAPI,
  getUniformsAdminAPI,
  getUniformsPermissionsAPI,
  deleteUniformsPermissionsAPI,
  updateConventionGroupAPI,
  getUniformsDeliveriesAPI,
  setUniformsDeliveriesAPI
} from '../api/uniforms';

import {
  actionTypes,
  getUniformsByRutReportSuccess,
  getUniformsByRutReportError,
  getUniformsByGarmentReportSuccess,
  getUniformsByGarmentReportError,
  updateUniformsSuccess,
  updateUniformsFailure,
  getUniformsSuccess,
  getUniformsFailure,
  getUniformsAdminSuccess,
  getUniformsAdminFailure,
  getUniformsPermissionsSuccess,
  getUniformsPermissionsFailure,
  deleteUniformsPermissionsSuccess,
  deleteUniformsPermissionsFailure,
  updateConventionGroupSuccess,
  updateConventionGroupFailure,
  getUniformsDeliveriesSuccess,
  getUniformsDeliveriesFailure,
  setUniformsDeliveriesSuccess,
  setUniformsDeliveriesFailure
} from '../actions/uniforms';
import { failure, loadComplete, loading } from '../actions/commons';

es6promise.polyfill();

export function* getUniforms(action) {
  try {
    const data = action.payload;
    const response = yield call(getUniformsAPI, data);
    yield put(getUniformsSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(getUniformsFailure());
    yield put(failure({ error: { error: true, message: error }, statusCode }));
    yield put(loadComplete());
  }
}

export function* updateUniforms(action) {
  try {
    yield put(loading());
    const data = action.payload;
    const resp = yield call(updateUniformsAPI, data);
    yield put(updateUniformsSuccess(resp));
    yield put(loadComplete());
  } catch (err) {
    const data = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(updateUniformsFailure());
    yield put(failure({ error: { error: true, message: data }, statusCode }));
    yield put(loadComplete());
  }
}

export function* getUniformsAdmin(action) {
  try {
    const response = yield call(getUniformsAdminAPI, action.payload);
    yield put(getUniformsAdminSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(getUniformsAdminFailure());
    yield put(failure({ error: { error: true, message: error }, statusCode }));
    yield put(loadComplete());
  }
}

export function* getUniformsPermissions(action) {
  try {
    const response = yield call(getUniformsPermissionsAPI, action.payload);
    yield put(getUniformsPermissionsSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(getUniformsPermissionsFailure());
    yield put(failure({ error: { error: true, message: error }, statusCode }));
    yield put(loadComplete());
  }
}

export function* deleteUniformsPermissions(action) {
  try {
    yield put(loading());
    const data = action.payload;
    const resp = yield call(deleteUniformsPermissionsAPI, data);
    yield put(deleteUniformsPermissionsSuccess(resp));
    yield put(loadComplete());
  } catch (err) {
    const data = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(deleteUniformsPermissionsFailure());
    yield put(failure({ error: { error: true, message: data }, statusCode }));
    yield put(loadComplete());
  }
}

export function* updateConventionGroup(action) {
  try {
    yield put(loading());
    const data = action.payload;
    const resp = yield call(updateConventionGroupAPI, data);
    if (resp.status === 500 || resp.status === 400) {
      yield put(updateConventionGroupFailure(resp));
    } else {
      yield put(updateConventionGroupSuccess(resp));
    }
    yield put(loadComplete());
  } catch (err) {
    const data = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error: { error: true, message: data }, statusCode }));
    yield put(loadComplete());
  }
}

export function* getUniformsByRutReport(action) {
  try {
    const response = yield call(getUniformsByRutReportAPI, action.codDivision);
    yield put(getUniformsByRutReportSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data') || err.message;
    yield put(getUniformsByRutReportError(error));
  }
}

export function* getUniformsByGarmentReport(action) {
  try {
    const response = yield call(getUniformsByGarmentReportAPI, action.codDivision);
    yield put(getUniformsByGarmentReportSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data') || err.message;
    yield put(getUniformsByGarmentReportError(error));
  }
}

// Solicitudes por Temporada
export function* getUniformsDeliveries(action) {
  try {
    const data = action.payload;
    const response = yield call(getUniformsDeliveriesAPI, data);
    yield put(getUniformsDeliveriesSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(getUniformsDeliveriesFailure());
    yield put(failure({ error: { error: true, message: error }, statusCode }));
    yield put(loadComplete());
  }
}

export function* setUniformsDeliveries(action) {
  try {
    const data = action.payload;
    const response = yield call(setUniformsDeliveriesAPI, data);
    yield put(setUniformsDeliveriesSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(setUniformsDeliveriesFailure());
    yield put(failure({ error: { error: true, message: error }, statusCode }));
    yield put(loadComplete());
  }
}

function* uniformsSagas() {
  yield all([
    takeLatest(actionTypes.GET_UNIFORMS_BY_RUT_REPORT, getUniformsByRutReport),
    takeLatest(actionTypes.GET_UNIFORMS_BY_GARMENT_REPORT, getUniformsByGarmentReport),
    takeLatest(actionTypes.UPDATE_UNIFORMS, updateUniforms),
    takeLatest(actionTypes.GET_UNIFORMS, getUniforms),
    takeLatest(actionTypes.GET_UNIFORMS_ADMIN, getUniformsAdmin),
    takeLatest(actionTypes.GET_UNIFORMS_PERMISSIONS, getUniformsPermissions),
    takeLatest(actionTypes.DELETE_UNIFORMS_PERMISSIONS, deleteUniformsPermissions),
    takeLatest(actionTypes.UPDATE_UNIFORMS_CONVENTION_GROUP, updateConventionGroup),
    takeLatest(actionTypes.GET_UNIFORMS_DELIVERIES, getUniformsDeliveries),
    takeLatest(actionTypes.SET_UNIFORMS_DELIVERIES, setUniformsDeliveries)
  ]);
}

export default uniformsSagas;

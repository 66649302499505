/* eslint-disable no-loop-func */
import { onError } from '@apollo/client/link/error';
import { fromPromise } from '@apollo/client/link/utils';
import axios from 'axios';
import adLogout from '../../helpers/adLogout';
import { getEnvVar } from '../../helpers/commons';
import { getItem, setItem } from '../../helpers/cookiesUtil';
// import { checkUserTryingToByPassLogin } from './helpers/loginByPass';

// const isLoginByPass = getEnvVar('REACT_APP_LOGIN_BY_PASS');

let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const renewToken = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${getItem('token')}`
    }
  };
  return axios.post(
    `${getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT')}/oauth2/v2/token/refresh`,
    {},
    config
  );
};

const errorLink = onError(({
  graphQLErrors, operation, forward
}) => {
  if (graphQLErrors && graphQLErrors.filter((e) => e).length > 0) {
    /* const cookieToken = storageUtil.getItem('token');
    if  (isLoginByPass === 'true') {
      const userNotFoundOnUsersForDebug = await checkUserTryingToByPassLogin(cookieToken);
      // If user is not present on database, don't refresh the token
      if (userNotFoundOnUsersForDebug) return null;
    } */
    // eslint-disable-next-line no-restricted-syntax
    for (const err of graphQLErrors) {
      if (err.code === 'UNAUTHENTICATED' && /SESSION_EXPIRED/.test(err.message)) {
        let forwardObservable;
        if (!isRefreshing) {
          isRefreshing = true;
          forwardObservable = fromPromise(
            renewToken()
              .then(({ data }) => {
              // Store the new tokens for your auth link
                const access_token = data.refreshedToken !== undefined
                  ? data.refreshedToken
                  : data.access_token;
                setItem('token', access_token);
                resolvePendingRequests();
                return access_token;
              })
              .catch(() => {
              // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                pendingRequests = [];
                adLogout();
              })
              .finally(() => {
                isRefreshing = false;
              })
          ).filter((value) => Boolean(value));
        } else {
          // Will only emit once the Promise is resolved
          forwardObservable = fromPromise(
            new Promise((resolve) => {
              pendingRequests.push(() => resolve());
            })
          );
        }
        return forwardObservable.flatMap(() => forward(operation));
      }
    }
  }
  return null;
});

export default errorLink;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  osName, browserName
} from 'react-device-detect';
import { formatDateHelp } from '../../helpers/strings';
import ModalComponent from '../shared/ModalMessage';

const EXTENSIONS_NO_WORK_IN_ANDROID = ['DOCX', 'DOC', 'PPT', 'PPTX', 'XLS', 'XLSX'];
const BROWSER_ANDROID_PWA = 'Chrome WebView';

const CardTicketAndComment = ({
  comment, position, key, record
}) => {
  const [modalAndroidError, setModalAndroidError] = useState({});
  const showComment = comment.comment.HtmlBody
    ? (<div dangerouslySetInnerHTML={{ __html: (comment.comment?.HtmlBody) }}></div>)
    : comment.comment.TextBody || 'Sin comentario';

  const closeModalAndroidErrorMessage = () => {
    setModalAndroidError({ visible: false });
  };
  const showAndroidErrorMessage = () => {
    setModalAndroidError({
      icon: 'icon-ml-warning',
      visible: true,
      title: 'No puedes abrir este archivo',
      message: 'Para ver este archivo necesitas hacerlo desde una computadora',
      buttons: [
        {
          text: 'Cerrar',
          type: 'primary',
          onClickButton: () => closeModalAndroidErrorMessage()
        }
      ]
    });
  };
  const openFile = (e) => {
    e.preventDefault();
    const [{ BlobPath, FileType }] = comment.file || [];

    if (osName === 'Android' && BROWSER_ANDROID_PWA === browserName && EXTENSIONS_NO_WORK_IN_ANDROID.includes(FileType)) {
      showAndroidErrorMessage();
    } else {
      window.open(BlobPath, '_blank');
    }
  };

  return (
    <div
      className={`card help-card ${position + 1 === record.threads.length && 'last-comment'}`}
      key={key}
    >
      {modalAndroidError.visible && (
        <ModalComponent
          {...modalAndroidError} closeModal={closeModalAndroidErrorMessage}
        />
      )}

      <div className="card-header">
        <div>
          <span className="help-ticket-comment-label">
            {comment.comment.FromName}
          </span>
          <span className="help-ticket-comment-date">
            {formatDateHelp(comment.createdDate, 'DD/MM/YYYY (HH:mm)')}
          </span>
        </div>
      </div>
      <div className="card-body">
        <p className="help-ticket-comment">
          {showComment}
        </p>
        {comment.file[0]?.FileType && (<>
          <p className="help-ticket-comment-label">
            Archivo Adjunto
          </p>
          <p className="help-ticket-comment-file-label-link">
            <a
              onClick={(e) => openFile(e, comment)}
              rel="noopener noreferrer"
            >
              {comment.file[0]?.Title}.{comment.file[0]?.FileType}
            </a>
          </p>
        </>)}
      </div>
    </div>);
};

CardTicketAndComment.propTypes = {
  comment: PropTypes.object.isRequired,
  position: PropTypes.number,
  key: PropTypes.string,
  record: PropTypes.object.isRequired
};

CardTicketAndComment.defaultProps = {
  comment: null,
  position: null,
  key: null,
  record: null
};

export default CardTicketAndComment;

import React from 'react';
import { useDispatch } from 'react-redux';
import {
  getFeedback
} from '../../../../../redux/actions/admin';
import useReport, { REPORT_NAMES } from '../../../../../hooks/reports/useReport';
import useSocket from '../../../../../hooks/sockets/useSocket';
import AdminFileListReportsSockets from '../../../../shared/list/AdminFileListReportsSockets';

const FeedbackReports = () => {
  const dispatch = useDispatch();
  const ws = useSocket();
  const feedbackReport = useReport(REPORT_NAMES.FEEDBACK);

  return (
    <>
      <AdminFileListReportsSockets
        title='Descarga de Reportes'
        report={feedbackReport}
        startFetch={() => dispatch(getFeedback(ws.socket.id))}
      />
    </>
  );
};

export default FeedbackReports;

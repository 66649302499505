export const actionTypes = {
  FAILURE: 'FAILURE',
  LOADING: 'LOADING',
  LOAD_COMPLETE: 'LOAD_COMPLETE',
  DISMISS_ERROR: 'DISMISS_ERROR',
  LOADING_API: 'LOADING_API',
  LOADING_API_COMPLETE: 'LOADING_API_COMPLETE',
  CLEAN_MESSAGE: 'CLEAN_MESSAGE',

  GET_REGIONS: 'GET_REGIONS',
  GET_REGIONS_SUCCESS: 'GET_REGIONS_SUCCESS',
  GET_REGIONS_ERROR: 'GET_REGIONS_ERROR',

  GET_COMMUNES: 'GET_COMMUNES',
  GET_COMMUNES_SUCCESS: 'GET_COMMUNES_SUCCESS',
  GET_COMMUNES_ERROR: 'GET_COMMUNES_ERROR',

  GET_COUNTRIES: 'GET_COUNTRIES',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_ERROR: 'GET_COUNTRIES_ERROR',

  SET_MODAL_LEFT_HEADER: 'SET_MODAL_LEFT_HEADER',
  RESET_MODAL_LEFT_HEADER: 'RESET_MODAL_LEFT_HEADER',

  SET_ML_HISTORICAL_PROPS: 'SET_ML_HISTORICAL_PROPS',

  SET_REF_ML_HISTORICAL: 'SET_REF_ML_HISTORICAL'
};

export function failure(data) {
  return {
    type: actionTypes.FAILURE,
    payload: data
  };
}

export function loading() {
  return {
    type: actionTypes.LOADING
  };
}

export function loadingAPI(loader) {
  return {
    type: actionTypes.LOADING_API,
    loader
  };
}

export function loadComplete() {
  return {
    type: actionTypes.LOAD_COMPLETE
  };
}

export function loadingAPIComplete(loader) {
  return {
    type: actionTypes.LOADING_API_COMPLETE,
    loader
  };
}

export function dismissError() {
  return {
    type: actionTypes.DISMISS_ERROR
  };
}

export function getRegions() {
  return {
    type: actionTypes.GET_REGIONS
  };
}

export function getRegionsSuccess(data) {
  return {
    type: actionTypes.GET_REGIONS_SUCCESS,
    data
  };
}

export function getRegionsError(error) {
  return {
    type: actionTypes.GET_REGIONS_ERROR,
    error
  };
}

export function getCommunes() {
  return {
    type: actionTypes.GET_COMMUNES
  };
}

export function getCommunesSuccess(data) {
  return {
    type: actionTypes.GET_COMMUNES_SUCCESS,
    data
  };
}

export function getCommunesError(error) {
  return {
    type: actionTypes.GET_COMMUNES_ERROR,
    error
  };
}

export function getCountries() {
  return {
    type: actionTypes.GET_COUNTRIES
  };
}

export function getCountriesSuccess(data) {
  return {
    type: actionTypes.GET_COUNTRIES_SUCCESS,
    data
  };
}

export function getCountriesError(error) {
  return {
    type: actionTypes.GET_COUNTRIES_ERROR,
    error
  };
}

export function setModalLeftIconHeader(data) {
  return {
    type: actionTypes.SET_MODAL_LEFT_HEADER,
    payload: data
  };
}

export function resetModalLeftIconHeader() {
  return {
    type: actionTypes.RESET_MODAL_LEFT_HEADER
  };
}

export function setMLHistoricalProps(data) {
  return {
    type: actionTypes.SET_ML_HISTORICAL_PROPS,
    payload: data
  };
}

export function setRefMLHistorical(data) {
  return {
    type: actionTypes.SET_REF_ML_HISTORICAL,
    payload: data
  };
}

import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';

const YesNoInput = (props) => {
  const {
    question, onChange, yesValue, noValue
  } = props;
  return (

    <div className='box-radio'>
      <p className='text-question'>{question.name}</p>
      <Radio.Group
        onChange={onChange}
        className='ml-radio-button d-flex justify-content-center align-items-center'
      >
        <Radio value={yesValue}>Si</Radio>
        <Radio value={noValue}>No </Radio>
      </Radio.Group>
    </div>
  );
};
YesNoInput.propTypes = {
  question: PropTypes.object,
  onChange: PropTypes.func,
  yesValue: PropTypes.any,
  noValue: PropTypes.any
};

YesNoInput.defaultProps = {
  questions: {},
  onChange: () => {},
  noValue: 'no',
  yesValue: 'si'
};
export default YesNoInput;

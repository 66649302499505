import { useState, useEffect } from 'react';

// Window size Hook
const useWindowWidthSize = () => {
  const getSize = () => window.innerWidth;

  const [windowWidthSize, setWindowWidthSize] = useState(getSize);

  useEffect(() => {
    const handleResize = () => setWindowWidthSize(getSize());

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowWidthSize;
};

export default useWindowWidthSize;

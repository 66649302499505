import React from 'react';
import PropTypes from 'prop-types';
import '../../../static/styles/shared/messages/grey-message.less';

const GreyMessage = ({ className, message }) => (
  <div className={`grey-message ${className}`.trim()}>
    {message}
  </div>
);

GreyMessage.protoTypes = {
  className: PropTypes.string,
  message: PropTypes.string
};

GreyMessage.defaultProps = {
  className: '',
  message: ''
};

export default GreyMessage;

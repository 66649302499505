import { Col, Row } from 'antd';
import React from 'react';
import { completePath } from '../../../helpers/strings';
import '../../../static/styles/login/login-help/ml-outside-access.less';

const OutsideAccess = () => (
  <div className='ml-outside-access'>
    <span>Para resguardar tu información personal,</span>{' '}

    <span>solo puedes acceder a la aplicación Mi Lugar</span>{' '}

    <span>cuando estés</span>{' '}

    <span>conectado a la red de Walmart o hayas realizado la siguiente configuración.</span>

    <b>
      Configuración para ingresar a una aplicación Walmart desde tu computador
    </b>

    <span>Para</span>{' '}
    <span>enrolar tu teléfono</span>{' '}
    <span>puedes contactarte con la</span>{' '}
    <span>Mesa de Ayuda</span>{' '}
    <span>llamando al</span>{' '}
    <span>+56222005115</span>{' '}
    <span>o realizarlo</span>{' '}
    <span>por tu cuenta de la siguiente forma:</span>

    <br/>

    <span>1. Debes ingresar a la siguiente url</span>{' '}
    <a href='https://svipreg.wal-mart.com/' target='_blank' rel='noopener noopener noreferrer'>https://svipreg.wal-mart.com/</a>{' '}
    <span>en un computador modo incógnito con tu nombre de usuario y contraseña.</span>

    <Row gutter={16} className='first-row'>
      <Col xl={8} lg={8} md={8}>
        <a target='_blank' href={completePath('web/login/init-session.jpg')} rel='noopener noreferrer'>
          <img src={completePath('web/login/init-session.jpg')}/>
        </a>
      </Col>

      <Col xl={8} lg={8} md={8}>
        <a target='_blank' href={completePath('web/login/region-select.jpg')} rel='noopener noreferrer'>
          <img src={completePath('web/login/region-select.jpg')}/>
        </a>
      </Col>

      <Col xl={8} lg={8} md={8}>
        <a target='_blank' href={completePath('web/login/credentials-set.jpg')} rel='noopener noreferrer'>
          <img src={completePath('web/login/credentials-set.jpg')}/>
        </a>
      </Col>
    </Row>

    <p>
      2. Una vez dentro se mostrará la pantalla para enrolamiento donde podremos ver 3 opciones
      {' '}disponibles para configurar {'(Mensaje de texto, Llamada de voz y Aplicación Symantec):'}
    </p>

    <p>{'a) Configurar para recibir un mensaje de texto'}</p>

    <p>Ingresa a “Configurar mensaje de texto”</p>

    <Row>
      <Col xl={12} lg={12} md={12}>
        <a target='_blank' href={completePath('web/login/text-message-config.jpg')} rel='noopener noreferrer'>
          <img src={completePath('web/login/text-message-config.jpg')}/>
        </a>
      </Col>
    </Row>

    <p>Selecciona el código de Chile e introduce tu número de celular.</p>

    <Row>
      <Col xl={12} lg={12} md={12}>
        <a target='_blank' href={completePath('web/login/phone-number-set.jpg')} rel='noopener noreferrer'>
          <img src={completePath('web/login/phone-number-set.jpg')}/>
        </a>
      </Col>
    </Row>

    <p>Introduce el código de seguridad que te llegó a tu celular.</p>

    <Row>
      <Col xl={12} lg={12} md={12}>
        <a target='_blank' href={completePath('web/login/security-code-set.jpg')} rel='noopener noreferrer'>
          <img src={completePath('web/login/security-code-set.jpg')}/>
        </a>
      </Col>
    </Row>

    <p>
      Finalmente te aparecerá un mensaje de éxito, indicando que desde ahora puedes acceder a
      {' '}la información de Walmart desde tu dispositivo personal.
    </p>

    <Row>
      <Col xl={12} lg={12} md={12}>
        <a target='_blank' href={completePath('web/login/success-message.jpg')} rel='noopener noreferrer'>
          <img src={completePath('web/login/success-message.jpg')}/>
        </a>
      </Col>
    </Row>

    <p>
      {'b) Configurar para recibir una llamada de voz: Se necesita ingresar y mantener actualizado'}
      {' '}el número de teléfono del colaborador.
    </p>

    <p>
      {'c) Configurar aplicación Symantec: Requiere la instalación y enrolamiento de una aplicación'}
      {' '}adicional en el celular llamada Vip Access.
    </p>

    <b>Configuración para ingresar a una aplicación Walmart desde tu celular</b>

    <p>1. Ingresa la url en el navegador e inmediatamente va a aparecer la pantalla de login.</p>

    <Row>
      <Col xl={5} lg={5} md={5} xs={12}>
        <a target='_blank' href={completePath('web/login/mobile-config-1.jpg')} rel='noopener noreferrer'>
          <img src={completePath('web/login/mobile-config-1.jpg')}/>
        </a>
      </Col>

      <Col xl={5} lg={5} md={5} xs={12}>
        <a target='_blank' href={completePath('web/login/mobile-config-2.jpg')} rel='noopener noreferrer'>
          <img src={completePath('web/login/mobile-config-2.jpg')}/>
        </a>
      </Col>
    </Row>

    <p>2. Se debe seleccionar la opción “Receive a text message”.</p>

    <p>
      Para los que tienen descargado el VIP Access tiene la opción
      {' '}de enviar una notificación push al dispositivo o ingresar el código que le entrega la aplicación.
    </p>

    <Row>
      <Col xl={5} lg={5} md={5} sm={12} xs={16}>
        <a target='_blank' href={completePath('web/login/push-notification.jpg')} rel='noopener noreferrer'>
          <img src={completePath('web/login/push-notification.jpg')}/>
        </a>
      </Col>
    </Row>

    <p>3. Introduce el código de seguridad que te llegó a tu celular.</p>
    <p>4. Introduce la contraseña de tu cuenta.</p>

    <Row>
      <Col xl={5} lg={5} md={5} sm={12} xs={16}>
        <a target='_blank' href={completePath('web/login/password.jpg')} rel='noopener noreferrer'>
          <img src={completePath('web/login/password.jpg')}/>
        </a>
      </Col>
    </Row>

    <p>5. Finalmente será redirigo a Mi Lugar.</p>

    <Row>
      <Col xl={5} lg={5} md={5} sm={12} xs={16}>
        <a target='_blank' href={completePath('web/login/home.jpg')} rel='noopener noreferrer'>
          <img src={completePath('web/login/home.jpg')}/>
        </a>
      </Col>
    </Row>
  </div>
);

export default OutsideAccess;

import { completePath } from '../../../../../helpers/strings';

export const IDP_TEMPLATE = (data) => `

<!DOCTYPE html>
<html lang="es">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>HTML Structure Example</title>
  <style>
    p {
    margin: 0;
    padding: 0;
}

.ft10 {
    font-size: 55px;
    font-family: BogleWeb-Regular;
    color: #ffffff;
}

.ft11 {
    font-size: 18px;
    font-family: BogleWeb-Regular;
    color: #000000;
}

.ft12 {
    font-size: 17px;
    font-family: BogleWeb-Regular;
    color: #000000;
}

.ft13 {
    font-size: 12px;
    font-family: BogleWeb-Regular;
    color: #000000;
}

.ft14 {
    font-size: 11px;
    font-family: BogleWeb-Regular;
    color: #000000;
}

.ft15 {
    font-size: 12px;
    font-family: BogleWeb-Regular;
    color: #954f72;
}

.ft16 {
    font-size: 10px;
    font-family: BogleWeb-Regular;
    color: #000000;
}

.ft17 {
    font-size: 14px;
    font-family: BogleWeb-Regular;
    color: #954f72;
}

.ft18 {
    font-size: 17px;
    font-family: BogleWeb-Regular;
    color: #000000;
}

.ft19 {
    font-size: 16px;
    font-family: BogleWeb-Regular;
    color: #954f72;
}

.ft110 {
    font-size: 13px;
    font-family: BogleWeb-Regular;
    color: #000000;
}

.ft111 {
    font-size: 17px;
    line-height: 23px;
    font-family: BogleWeb-Regular;
    color: #000000;
}

.ft112 {
    font-size: 12px;
    line-height: 16px;
    font-family: BogleWeb-Regular;
    color: #000000;
}

.ft113 {
    font-size: 10px;
    line-height: 14px;
    font-family: BogleWeb-Regular;
    color: #000000;
}

.ft114 { 
    font-size: 11px;
    line-height: 15px;
    font-family: BogleWeb-Regular;
    color: #000000;
}

.page-container {
    background-image: url('../../img/target001.png');
    background-size: cover;
    background-repeat: no-repeat;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html, body {
    width: 100%;
    height: 100%;
  }
  body {
    font-family: BogleWeb-Regular;
    font-size: 0.8em;
    margin: 0;
    min-height: 100vh;
  }
  body.with-padding {
    padding: 40px 50px;
  }

      .row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1px;
          width: 100%;
      }
      .c-15{
          flex: 15;
      }
      .c-100{
          flex: 100;
          
      }
      .column {
        flex: 1;
        padding: 10px;
        overflow-wrap: break-word;
    }
    .column_ins {
        flex: 1;
        padding: 0px;
        overflow-wrap: break-word;
    }

      .column-centered {
          flex: 3;
          padding: 10px;
          margin-top:10px;
          overflow-wrap: break-word;
      }

      .container {
          display: flex;
          margin-bottom: 1px;
      }

      .container .col {
          flex: 1;
          padding: 0px 10px 0px 10px;
          min-height: 110px;
          max-height: 111px;
      }
      .col {
          min-height: 110px;
          max-height: 111px;
      }
      .centered-row {
          display: flex;
          justify-content: center;
      }

      .centered-col {
          padding: 10px;
      }

      ul{
        padding-left: 30px;
        padding-top: 4px;
      }
      li{
        padding-top: 2px;
      }

      .titleBgBlack {
        color: white;
        background: black;
        padding: 4px 10px;
        font-size: 16px;
        font-family: BogleWeb-Bold;
        display: inline-block;
        width: 100%;
    }     
     .titleBgBlackAsp {
        color: white;
        margin-top: -10px;
        background: black;
        padding: 4px 10px;
        font-size: 16px;
        font-family: BogleWeb-Bold;
        display: inline-block;
        width: 100%;
    }

      .squads{
        border: solid 1px #ccc;
        border-style: dotted;
      padding: 10px;
  }

  .msquad-manager{
      padding: 1px;
      margin-top: 7px;
      margin-bottom: 6px;
      font-size: 16px;
      font-weight: bold;

  }   
  
  .msquad-mentor{
      padding: 1px;
      margin-top: 35px;
      margin-bottom: 6px;
      font-size: 16px;
      font-weight: bold;

  }

  .leader-profile{
    border :solid thin gray ;
    height: 52px;
    border-style: dotted;

}

.format-font{
    font-family: BogleWeb-Regular;
    font-size: 13px;
}
.liWithOutStyle{
    list-style:none
}

  </style>
</head>
<body>
    <div class="row">
        <div class="column" style=" marging-top:4px;marging-left:4px;">
        <div class="" style=" border: solid 3px; width:230px; height:250px; ">
            <img src="data:image/png;base64,${data.imgProfile}" style="margin-top: 2px;margin-left: 2px;width: 222px;height: 242px;">
        </div>
        </div>
        <div class="column">
            <div class="column" style="max-width:450px;  overflow-wrap: break-word;">
                <span class="titleBgBlack">Leader Profile</span>
                <div class="leader-profile">
                    <ul>
                        <li class="liWithOutStyle format-font">${data.leader_profile}</li>
                        <li class="liWithOutStyle format-font">${data.user.position}</li>
                    </ul>
                </div>
            </div>
            <div class="column">
                <span class="titleBgBlackAsp">Aspirations</span>
                <ul>
                    ${data.aspirations[0] ? `<li class="format-font">${data.aspirations[0] || ''}</li>` : ''}
                    ${data.aspirations[1] ? `<li class="format-font">${data.aspirations[1] || ''}</li>` : ''}
                    ${data.aspirations[2] ? `<li class="format-font">${data.aspirations[2] || ''}</li>` : ''}
                </ul>
            </div>
        </div>
        <div class="column">
            <div class="column">
                <span class="titleBgBlack">Assessment History</span>
                <ul>
                    ${data.assessment_history[0] ? `<li class="format-font">${data.assessment_history[0] || ''}</li>` : ''}
                    ${data.assessment_history[1] ? `<li class="format-font">${data.assessment_history[1] || ''}</li>` : ''}
                    ${data.assessment_history[2] ? `<li class="format-font">${data.assessment_history[2] || ''}</li>` : ''}
                </ul>
            </div>
            <div class="column_ins" style="max-width:400px;  overflow-wrap: break-word;">
                <span class="titleBgBlack">Assessment Insights</span>
                <ul>
                ${data.assessment_insights[0] ? `<li class="format-font">${data.assessment_insights[0] || ''}</li>` : ''}
                ${data.assessment_insights[1] ? `<li class="format-font">${data.assessment_insights[1] || ''}</li>` : ''}
                ${data.assessment_insights[2] ? `<li class="format-font">${data.assessment_insights[2] || ''}</li>` : ''}
            </ul>
            </div>
        </div>
        <div class="column">
            <div class="column" style=" min-width:400px:max-width:500px ">
                <span class="titleBgBlack">Development Partners</span>
                <div class="msquad-manager">Manager: </div>
                <div class="squads">${data.manager} </div>
            <div  class="msquad-mentor">Mentor: </div>
                <div class="squads">${data.mentor_optional} </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="col">
            <span class="titleBgBlack">Key Strengths</span>
            <ul>
                ${data.key_strenghts[0] ? `<li class="format-font">${data.key_strenghts[0] || ''}</li>` : ''}
                ${data.key_strenghts[1] ? `<li class="format-font">${data.key_strenghts[1] || ''}</li>` : ''}
                ${data.key_strenghts[2] ? `<li class="format-font">${data.key_strenghts[2] || ''}</li>` : ''}
            </ul>
        </div>
        <div class="col" style="width: 100%;">
            <span class="titleBgBlack">Areas for Development</span>

            <ul>
                ${data.areas_for_development[0] ? `<li class="format-font">${data.areas_for_development[0] || ''}</li>` : ''}
                ${data.areas_for_development[1] ? `<li class="format-font">${data.areas_for_development[1] || ''}</li>` : ''}
                ${data.areas_for_development[2] ? `<li class="format-font">${data.areas_for_development[2] || ''}</li>` : ''}
            </ul>
        </div>
    </div>
    <div class="container">
        <div class="col">
            <span class="titleBgBlack">Development Priorities</span>
            <ul>
                ${data.development_priorities[0] ? `<li class="format-font">${data.development_priorities[0] || ''}</li>` : ''}
                ${data.development_priorities[1] ? `<li class="format-font">${data.development_priorities[1] || ''}</li>` : ''}
                ${data.development_priorities[2] ? `<li class="format-font">${data.development_priorities[2] || ''}</li>` : ''}
            </ul>
        </div>
        <div class="col">
            <span class="titleBgBlack">Measures of Success</span>
            <ul>
                ${data.measures_of_success[0] ? `<li class="format-font">${data.measures_of_success[0] || ''}</li>` : ''}
                ${data.measures_of_success[1] ? `<li class="format-font">${data.measures_of_success[1] || ''}</li>` : ''}
                ${data.measures_of_success[2] ? `<li class="format-font">${data.measures_of_success[2] || ''}</li>` : ''}
            </ul>
        </div>
    </div>

    <div class="row">
        <div class="column">
            <div class="col">
                <span class="titleBgBlack">Development: Experience</span>
                <ul>
                    ${data.development_experience[0] ? `<li class="format-font">${data.development_experience[0] || ''}</li>` : ''}
                    ${data.development_experience[1] ? `<li class="format-font">${data.development_experience[1] || ''}</li>` : ''}
                    ${data.development_experience[2] ? `<li class="format-font">${data.development_experience[2] || ''}</li>` : ''}
                </ul>
            </div>
        </div>
        <div class="column">
            <div class="col">
                <span class="titleBgBlack">Development: Exposure</span>
                <ul>
                    ${data.development_exposure[0] ? `<li class="format-font">${data.development_exposure[0] || ''}</li>` : ''}
                    ${data.development_exposure[1] ? `<li class="format-font">${data.development_exposure[1] || ''}</li>` : ''}
                    ${data.development_exposure[2] ? `<li class="format-font">${data.development_exposure[2] || ''}</li>` : ''}
                </ul>
            </div>
        </div>
        <div class="column">
            <div class="col">
                <span class="titleBgBlack">Development: Education</span>
                <ul>
                    ${data.development_education[0] ? `<li class="format-font">${data.development_education[0] || ''}</li>` : ''}
                    ${data.development_education[1] ? `<li class="format-font">${data.development_education[1] || ''}</li>` : ''}
                    ${data.development_education[2] ? `<li class="format-font">${data.development_education[2] || ''}</li>` : ''}
                </ul>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="c-15"> 
 
  <img src="${completePath('IDP/hand.png')}" style=" margin-top:10px; width: 100px; height: 100px;">
  </div>
        <div class="c-100"> 
            <div class="column-centered">
                <span class="titleBgBlack">Aditional Remarks</span>
    
                <ul>
                    <li class="format-font">${data.additional_remarks}</li>
                    
                </ul>
            </div> </div>
        <div class="c-15"> 
  </div>
           
    </div>
</body>

</html>
`;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import {
  findFileAPI
} from '../api/digitalFolder';

import {
  actionTypes,
  getFileFailure,
  getFileSuccess
} from '../actions/digitalFolder';

import { failure as commonsFailure } from '../actions/commons';

es6promise.polyfill();

export function* getFile(action) {
  try {
    const response = yield call(findFileAPI, action.input);
    yield put(getFileSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getFileFailure(error));

    const statusCode = get(err, 'response.status');
    yield put(commonsFailure({ error, statusCode }));
  }
}

function* digitalFolderSagas() {
  yield all([
    takeLatest(actionTypes.FIND_FILE, getFile)
  ]);
}

export default digitalFolderSagas;

import React, { useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import FileCard from '../cards/FileCard';
import MLLoading from '../MLLoading';
import ErrorMessage from '../ErrorMessage';
import { GET_PERMISSION_FILE } from '../../../graphql/permissions/queries';

const minutesInterval = 4 * 60 * 1000;

const renderCards = (list, dataFile) => list.map((item, i) => (
  <Col key={`file-card-${i}`} xs={24} md={12} lg={8} className="file-card-container">
    <FileCard {...item} dataFile = {dataFile} />
  </Col>
));

const AdminFileList = ({
  title, list, loading, error, onRefetch, demoFile
}) => {
  const [
    getPermissions,
    {
      data
    }
  ] = useLazyQuery(GET_PERMISSION_FILE, {
    context: { clientName: 'myEvolution' },
    fetchPolicy: 'no-cache'
  }, []);

  const dataFile = data?.getPermissionsFile;

  useEffect(() => {
    let interval = 0;

    if (demoFile) {
      getPermissions();
      interval = setInterval(() => {
        getPermissions();
      }, minutesInterval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [demoFile]);
  return <>
    {
      loading && <MLLoading />
    }
    {
      !loading
      && (
        <>
          {
            title && (
              <Row>
                <Col span={24}>
                  <h4 className="ml-3" style={{ fontSize: '20px !important' }}>{title}</h4>
                </Col>
              </Row>
            )
          }
          <Row>
            {
              error
                ? <>
                  <ErrorMessage />
                  {onRefetch && <Row className="mb-4">
                    <Button type="primary" onClick={() => onRefetch()}>
                      Intentar de nuevo
                    </Button>
                  </Row>}
                </>
                : renderCards(list, dataFile)
            }
          </Row>
        </>
      )
    }
  </>;
};

AdminFileList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  demoFile: PropTypes.bool
};

AdminFileList.defaultProps = {
  title: '',
  list: [],
  loading: false,
  error: false,
  demoFile: false

};

export default AdminFileList;

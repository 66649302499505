import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { capitalizeText } from '../../../../helpers/commons';

const UsersSelected = (props) => {
  const {
    usersSelected, selectUser, type, userAttribute
  } = props;

  const data = [];
  usersSelected.forEach((user) => {
    data.push(user);
  });

  if (usersSelected.size === 0) return null;

  return (
    <div className='container-users-selected'>
      <div className="d-flex flex-wrap">
        {map(data, (user, i) => (
          <div
            className={`user-selected ${type}`}
            key={i} onClick={ (e) => {
              e.preventDefault();
              selectUser(user);
            }}>
            <div className='d-flex align-items-center' >
              <p> { capitalizeText(`${user.name} ${user.fatherLastName} ${user.motherLastName} \
              ${userAttribute && `- ${user[userAttribute]}`}`)}</p>
              <i className='icon-me-people-remove'/>
            </div>

          </div>
        ))}
      </div>
    </div>
  );
};

UsersSelected.propTypes = {
  usersSelected: PropTypes.instanceOf(Map),
  selectUser: PropTypes.func,
  type: PropTypes.string,
  userAttribute: PropTypes.string
};

UsersSelected.defaultProps = {
  usersSelected: {},
  selectUser: () => {},
  type: '',
  userAttribute: ''
};

export default UsersSelected;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Link
} from 'react-router-dom';

import '../../../static/styles/shared/MLHistorical/arrowBtnMobile.less';

const ArrowButtonsMobile = (props) => (
  <Link
    data-testid="link"
    to={props.to}
    className={`arrow-btn-mobile d-flex justify-content-space ${props.customStyle}`}
    onClick={() => props.onClick(true)}
  >
    <span>{props.label}</span>
    <i className={`${props.icon}`} />
  </Link>
);

ArrowButtonsMobile.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  label: PropTypes.string,
  to: PropTypes.string,
  customStyle: PropTypes.string
};

ArrowButtonsMobile.defaultProps = {
  onClick: () => {},
  icon: '',
  label: '',
  to: '',
  customStyle: ''
};

export default ArrowButtonsMobile;

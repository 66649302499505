import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Row, Form, Checkbox, Select
} from 'antd';
import {
  map, trimStart, filter, find
} from 'lodash';
import Avatar from '../../../../../shared/Avatar';
import MLSimpleCollapse from '../../../../../shared/MLSimpleCollapse';
import SectionContainer from './SectionContainer';
import '../../../../../../static/styles/mievolucion/myPerformance/feedback/feedbackDetails.less';

const maxLengthTextAreaFeedback = 4000;
const maxLengthTextAreaNotFeedbackReason = 600;

const { TextArea } = Input;
const { Option } = Select;
const ItemDetailsCard = (props) => {
  const {
    title,
    subject,
    isGroup,
    subTitle,
    date,
    status,
    statusClasses,
    comments,
    setComments,
    CONDITION_BUTTON_SEND_FEEDBACK,
    feedback: {
      situation,
      notFeedbackReason: reason
    },
    from,
    feedbackToMe,
    getFieldDecorator,
    listDefaultFeedbackAnswers,
    dontRespondFeedback,
    setDontRespondFeedback,
    notFeedbackReason,
    setNotFeedbackReason
  } = props;

  const listNotFeedbackReason = filter(listDefaultFeedbackAnswers, (item) => item.type === 'NOT_FEEDBACK_REASON');

  const handleDontRespondFeedback = (e) => {
    setDontRespondFeedback(e.target.checked);
  };
  const handleNotFeedbackReason = (value) => {
    setNotFeedbackReason(find(listNotFeedbackReason, ({ _id }) => value === _id));
  };
  const isPending = status === 'Pendiente';

  return (
    <div>
      <div>
        {
        // Ocultado ya que haran test si hace falta o No
        // !isPending && !feedbackToMyTeam
        //   && <div className='container-title'>
        //     <h3 className=''> {getTitleFeedbackDetail({ status, feedbackToMe })}</h3>
        //   </div>
        }

        <div className='container-info-feedback'>
          <div className="container-left">
            {isGroup ? (
              <span className="ant-avatar ant-avatar-icon">
                <i className="icon-me-feedback-to-group" />
              </span>
            ) : <Avatar />}
          </div>
          <div className='container-right'>
            <div className='d-flex'>
              <p><span className="bold">{feedbackToMe ? 'De: ' : 'Para: '}</span></p>
              <p><span className="bold">{title}</span> | {subTitle}</p>
            </div>
            <div className='d-flex'>
              <p><span className="bold">Asunto: </span></p>
              <p>{subject}</p>
            </div>
          </div>

        </div>
        <div className='container-date-and-status '>
          <div>
            <i className="icon-ml-calendar-2 primary-icon-color" />
            <p>{date}</p>
          </div>
          <div>
            <i className={statusClasses} />
            <p>{status}</p>
          </div>
        </div>
      </div>

      {
        isPending && from === 'others'
      && <div className='container-dont-give-feedback'><Checkbox
        checked={dontRespondFeedback}
        onChange={handleDontRespondFeedback}>
          Prefiero no dar feedback
      </Checkbox>
      </div>
      }

      { isPending && dontRespondFeedback
        ? <div className='container-reason-dont-give-feedback'>
          <Form.Item
            label='Motivo'
          >
            {getFieldDecorator('reason', {
              value: notFeedbackReason?._id,
              rules: [{ required: dontRespondFeedback, message: 'Debes ingresar un motivo' }]
            })(
              <Select
                placeholder='Selecciona una opción'
                onChange={ (value) => handleNotFeedbackReason(value)}
              >
                {
                  listNotFeedbackReason.map((item, j) => (
                    <Option key={j} value={item._id}>{item.name}</Option>
                  ))
                }
              </Select>
            )}
          </Form.Item>
          {
            notFeedbackReason?.hasComment
          && <Form.Item
            label='Comentario'
            className='box-text-area'>
            {getFieldDecorator('comment', {
              rules: [{ required: notFeedbackReason?.hasComment || false, message: 'Debes ingresar un comentario' }]
            })(
              <div>
                <TextArea
                  maxLength={maxLengthTextAreaNotFeedbackReason}
                  placeholder='Describe el motivo.'
                  onChange={(e) => {
                    setNotFeedbackReason({
                      ...notFeedbackReason,
                      comment: e.target.value
                    });
                  }}
                />
                <p
                  className='text-info-input mb-0'>
                  {`${notFeedbackReason?.comment?.length || 0}/${maxLengthTextAreaNotFeedbackReason}`}
                </p>
              </div>
            )}
          </Form.Item>
          }
        </div>
        : <div>

          {
            reason
            && <div className='container-not-feedback-reason'>
              <p className='text-prefer'> {from === 'others' ? 'Has preferido no dar el feedback solicitado.' : `${title} ha preferido no dar el feedback solicitado.`}</p>
              <p className='text-reason'>Motivo</p>
              <p className='text-explain-reason'>{reason?.comment || reason?.defaultAnswer}</p>
            </div>
          }
          {
            situation
        && <SectionContainer label={'Situación o contexto'} description={situation} className='border-0'/>
          }

          { map(comments, (comment, i) => (
            <div key ={i}
              className={`${!comment.skill && from !== 'others' && !comment?.content ? 'd-none ' : ''} section-container container-comments ${i + 1 === comments.length ? 'border-0' : ''}`}>
              {comment.skill?.name
             && <p className='text-title-container'>{comment.skill?.name}</p>
              }
              { comment.skill?.description
               && <p className='text-description'>{comment.skill?.description}</p>
              }

              { comment.skill?.behaviors

            && <MLSimpleCollapse
              baseClasses='container-behaviors'
              title='conductas de esta competencia'>
              <div>
                {
                  map(comment.skill?.behaviors, (item, j) => (
                    <div key={j} className='behavior'>
                      <p className='text-title'>{item.title}</p>
                      <p className='text-description'>{item.description}</p>
                    </div>
                  ))
                }
              </div>
            </MLSimpleCollapse>
              }
              { CONDITION_BUTTON_SEND_FEEDBACK || comment?.content?.length > 0
                ? <div className={`container-feedback ${comment.skill?.myBehaviors ? 'has-behaviors' : ''}`}>
                  { comment?.content?.length > 0 || (isPending && from === 'others')
                    ? <h3>Feedback</h3> : null
                  }
                  <Row type="flex">
                    {CONDITION_BUTTON_SEND_FEEDBACK ? (
                      <Form.Item className='w-100'>
                        {getFieldDecorator(`skill-${comment.skill?.name}`, {
                          rules: [{ required: true, message: 'Debes ingresar el feedback.' }]
                        })(

                          <div>
                            <TextArea
                              maxLength={maxLengthTextAreaFeedback}
                              placeholder='Escribe el feedback.'
                              value={comment?.content}
                              onChange={(e) => {
                                const newComments = [...comments];
                                newComments[i] = {
                                  ...newComments[i],
                                  content: trimStart(e.target.value)
                                };
                                setComments(newComments);
                              }}
                            />
                            <p
                              className='text-info-input mb-0'>
                              {`${comment?.content?.length || 0}/${maxLengthTextAreaFeedback}`}
                            </p>
                          </div>

                        )}
                      </Form.Item>
                    ) : (
                      <p className='text-feedback'>
                        {comment.content}
                      </p>
                    )}
                  </Row>
                </div>
                : null
              }

            </div>
          ))}
        </div>}

    </div>
  );
};

ItemDetailsCard.propTypes = {
  title: PropTypes.string,
  subject: PropTypes.string,
  isGroup: PropTypes.bool,
  subTitle: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
  statusClasses: PropTypes.string,
  comments: PropTypes.array,
  setComments: PropTypes.func,
  CONDITION_BUTTON_SEND_FEEDBACK: PropTypes.bool,
  answer: PropTypes.object,
  feedback: PropTypes.object,
  from: PropTypes.string,
  feedbackToMe: PropTypes.bool,
  getFieldDecorator: PropTypes.func,
  listDefaultFeedbackAnswers: PropTypes.array,
  dontRespondFeedback: PropTypes.bool,
  setDontRespondFeedback: PropTypes.func,
  notFeedbackReason: PropTypes.object,
  setNotFeedbackReason: PropTypes.func
};

ItemDetailsCard.defaultProps = {
  title: '',
  subject: null,
  isGroup: false,
  subTitle: '',
  date: '',
  status: '',
  statusClasses: '',
  comments: [],
  setComments: () => {},
  CONDITION_BUTTON_SEND_FEEDBACK: false,
  answer: null,
  feedback: null,
  from: '',
  feedbackToMe: false,
  getFieldDecorator: () => {},
  listDefaultFeedbackAnswers: [],
  dontRespondFeedback: false,
  setDontRespondFeedback: () => {},
  notFeedbackReason: null,
  setNotFeedbackReason: () => {}
};

export default ItemDetailsCard;

import React from 'react';
import PropTypes from 'prop-types';
import '../../../../../static/styles/mievolucion/myPerformance/objective-pulse-explain.less';

const PulseExplain = ({ title, description }) => (
  <div className='objective-pulse-explain'>
    <h6>{title}</h6>
    <p>{description}</p>
  </div>
);

PulseExplain.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

PulseExplain.defaultProps = {
  title: '',
  description: ''
};

export default PulseExplain;

import optionsTypeRequest from './optionsTypeRequest';
import errorKeyCase from './errorKeyCase';

const { BANK_ACCOUNT, BUSES } = optionsTypeRequest;
const {
  HOME_HELP,
  FILE_TICKET,
  FILE_SIZE,
  INCOMPLETE_BANK_DETAILS,
  INCOMPLETE_BUSES_DETAILS,
  INCOMPATIBLE_FILE
} = errorKeyCase;
const getModalError = (
  {
    setModalMessage,
    modalMessage,
    type,
    fileSize
  }
) => {
  let mssg;
  switch (type) {
  case HOME_HELP:
    mssg = 'Hubo un problema al cargar los tickets de ayuda, favor intente mas tarde.';
    break;
  case FILE_TICKET:
    mssg = 'Hubo un problema al descargar el archivo, favor intente mas tarde.';
    break;
  case INCOMPLETE_BANK_DETAILS:
    mssg = `Para ${BANK_ACCOUNT.category} es necesario que llenes la información del banco y la ${BANK_ACCOUNT.fileDescription }.`;
    break;
  case INCOMPLETE_BUSES_DETAILS:
    mssg = `Para ${BUSES.category} es necesario que llenes todos los campos.`;
    break;
  case FILE_SIZE:
    mssg = `El tamaño del archivo es demasiado grande, cargue una imagen de tamaño inferior a 20MB.\n
    Tamaño del archivo seleccionado es: ${Math.ceil(fileSize / 1000000)}MB :(`;
    break;
  case INCOMPATIBLE_FILE:
    mssg = 'Tipo de archivo no compatible.';
    break;

  default:
    mssg = `Hubo un problema al enviar el ${type}, favor intente mas tarde.`;
    break;
  }
  return (setModalMessage({
    ...modalMessage,
    visible: true,
    title: mssg,
    icon: 'icon-ml-warning pending-icon-color',
    buttons: [
      {
        text: 'Cerrar',
        type: 'primary',
        onClickButton: () => {
          setModalMessage({
            ...modalMessage,
            buttons: [],
            icon: 'icon-ml-warning pending-icon-color',
            visible: false
          });
        }
      }
    ]
  }));
};

export default getModalError;

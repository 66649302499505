import { lazy } from 'react';

const MyEvolutionTeamEvaluationsDevelopmentPlans = lazy(() => import('../../../../components/myTeam/myEvolution/developmentPlans'));

export default [
  {
    exact: true,
    path: '/mi-equipo/mi-evolucion/planes-de-desarrollo',
    section: 'Mi Equipo - Mi Evolución - Planes de desarrollo',
    component: MyEvolutionTeamEvaluationsDevelopmentPlans,
    auth: 'MI_EQUIPO_MI_EVOLUCION_PLAN_DE_DESARROLLO'
  }
];

import { actionTypes } from '../actions/wali';

export const initialState = {
  userInfo: {
    loading: false,
    finished: false,
    error: false
  },
  pendingUser: {
    loading: false,
    finished: false,
    error: false
  },
  survey: {
    loading: false,
    finished: false,
    error: false
  },
  validateStatus: {
    loading: false,
    finished: false,
    error: false
  },
  surveyResponse: {
    loading: false,
    finished: false,
    error: null
  },
  userInfoCol: {
    loading: false,
    finished: false,
    error: null
  },
  controlAccess: {
    loading: false,
    finished: false,
    error: null
  },
  assignStatus: {
    loading: false,
    finished: false,
    error: null
  },
  closingCase: {
    loading: false,
    finished: false,
    error: null
  },
  externalUser: {
    loading: false,
    finished: false,
    error: null
  },
  config: {
    loading: false,
    finished: false,
    error: null
  },
  gamaReport: {
    loading: false,
    finished: false,
    error: null
  },
  locationsOnCharge: {
    loading: false,
    finished: false,
    error: null
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_USER_INFO:
    return {
      ...state,
      userInfo: {
        loading: true,
        error: false
      }
    };
  case actionTypes.GET_USER_INFO_SUCCESS: {
    return {
      ...state,
      userInfo: {
        loading: false,
        finished: true,
        data: action.data
      }
    };
  }
  case actionTypes.GET_PENDING_USER:
    return {
      ...state,
      pendingUser: {
        loading: true,
        error: false
      }
    };
  case actionTypes.GET_PENDING_USER_SUCCESS: {
    return {
      ...state,
      pendingUser: {
        loading: false,
        finished: true,
        data: action.data
      }
    };
  }
  case actionTypes.GET_PENDING_USER_RESET_DATA: {
    return {
      ...state,
      pendingUser: {
        loading: false,
        finished: false,
        error: false
      }
    };
  }
  case actionTypes.GET_PENDING_USER_ERROR: {
    return {
      ...state,
      pendingUser: {
        loading: false,
        finished: true,
        error: true,
        data: action.error
      }
    };
  }
  case actionTypes.VALIDATE_STATUS:
    return {
      ...state,
      validateStatus: {
        loading: true,
        error: false
      }
    };
  case actionTypes.VALIDATE_STATUS_SUCCESS: {
    return {
      ...state,
      validateStatus: {
        loading: false,
        finished: true,
        data: action.data
      }
    };
  }
  case actionTypes.VALIDATE_STATUS_RESET_DATA: {
    return {
      ...state,
      validateStatus: {
        loading: false,
        finished: false,
        error: false
      }
    };
  }
  case actionTypes.VALIDATE_STATUS_ERROR: {
    return {
      ...state,
      validateStatus: {
        loading: false,
        finished: true,
        error: true,
        data: action.error
      }
    };
  }
  case actionTypes.WALIFAILURE:
    return {
      ...state,
      ...action.payload
    };
  case actionTypes.GET_SURVEY:
    return {
      ...state,
      survey: {
        loading: true,
        error: false
      }
    };
  case actionTypes.GET_SURVEY_SUCCESS:
    return {
      ...state,
      survey: {
        loading: false,
        finished: true,
        data: action.data
      }
    };
  case actionTypes.SEND_SURVEY_RESPONSE:
    return {
      ...state,
      surveyResponse: {
        loading: true,
        error: false
      }
    };
  case actionTypes.SEND_SURVEY_RESPONSE_SUCCESS:
    return {
      ...state,
      surveyResponse: {
        loading: false,
        finished: true,
        data: action.data
      }
    };
  case actionTypes.GET_USER_INFO_COL:
    return {
      ...state,
      userInfoCol: {
        loading: true,
        error: null
      }
    };
  case actionTypes.GET_USER_INFO_COL_SUCCESS:
    return {
      ...state,
      userInfoCol: {
        loading: false,
        finished: true,
        data: action.data
      }
    };
  case actionTypes.CONTROL_ACCESS: {
    return {
      ...state,
      controlAccess: {
        loading: true,
        error: null
      }
    };
  }
  case actionTypes.CONTROL_ACCESS_SUCCESS: {
    return {
      ...state,
      controlAccess: {
        loading: false,
        finished: true,
        data: action.data
      }
    };
  }
  case actionTypes.CONTROL_ACCESS_RESET_DATA: {
    return {
      ...state,
      controlAccess: {
        loading: false,
        finished: false,
        error: false
      }
    };
  }
  case actionTypes.SEND_SURVEY_RESPONSE_RESET: {
    return {
      ...state,
      surveyResponse: {
        loading: false,
        finished: false,
        error: false
      }
    };
  }
  case actionTypes.GET_USER_INFO_COL_RESET: {
    return {
      ...state,
      userInfoCol: {
        loading: false,
        finished: false,
        error: false
      }
    };
  }
  case actionTypes.GET_SURVEY_RESET: {
    return {
      ...state,
      survey: {
        loading: false,
        finished: false,
        error: false
      }
    };
  }
  case actionTypes.GET_USER_INFO_COL_ERROR: {
    return {
      ...state,
      userInfoCol: {
        loading: false,
        finished: true,
        error: action.error
      }
    };
  }
  case actionTypes.SEND_SURVEY_RESPONSE_ERROR: {
    return {
      ...state,
      surveyResponse: {
        loading: false,
        finished: true,
        error: action.error
      }
    };
  }
  case actionTypes.CONTROL_ACCESS_ERROR: {
    return {
      ...state,
      controlAccess: {
        loading: false,
        finished: true,
        error: action.error
      }
    };
  }
  case actionTypes.SEND_ASSIGN_STATUS: {
    return {
      ...state,
      assignStatus: {
        loading: true,
        error: null
      }
    };
  }
  case actionTypes.SEND_ASSIGN_STATUS_SUCCESS: {
    return {
      ...state,
      assignStatus: {
        loading: false,
        finished: true,
        data: action.data
      }
    };
  }
  case actionTypes.SEND_ASSIGN_STATUS_RESET_DATA: {
    return {
      ...state,
      assignStatus: {
        loading: false,
        finished: false,
        error: false
      }
    };
  }
  case actionTypes.SEND_ASSIGN_STATUS_ERROR: {
    return {
      ...state,
      assignStatus: {
        loading: false,
        finished: true,
        error: action.error
      }
    };
  }
  case actionTypes.SEND_CLOSING_CASE:
    return {
      ...state,
      closingCase: {
        loading: true
      }
    };
  case actionTypes.SEND_CLOSING_CASE_SUCCESS:
    return {
      ...state,
      closingCase: {
        loading: false,
        finished: true
      }
    };
  case actionTypes.SEND_CLOSING_CASE_RESET:
    return {
      ...state,
      closingCase: {
        loading: false,
        finished: false,
        error: null
      }
    };
  case actionTypes.SEND_CLOSING_CASE_ERROR:
    return {
      ...state,
      closingCase: {
        loading: false,
        finished: true,
        error: action.error
      }
    };
  case actionTypes.SEND_EXTERNAL_USER:
    return {
      ...state,
      closingCase: {
        loading: true
      }
    };
  case actionTypes.SEND_EXTERNAL_USER_ERROR:
    return {
      ...state,
      externalUser: {
        loading: false,
        finished: true,
        error: action.error
      }
    };
  case actionTypes.SEND_EXTERNAL_USER_RESET:
    return {
      ...state,
      externalUser: {
        loading: false,
        finished: false,
        error: false
      }
    };

  case actionTypes.GET_CONFIG: {
    return {
      ...state,
      config: {
        loading: true,
        error: null
      }
    };
  }
  case actionTypes.GET_CONFIG_SUCCESS: {
    return {
      ...state,
      config: {
        loading: false,
        finished: true,
        data: action.data
      }
    };
  }
  case actionTypes.GET_CONFIG_RESET_DATA: {
    return {
      ...state,
      config: {
        loading: false,
        finished: false,
        error: false
      }
    };
  }

  case actionTypes.GET_CONFIG_ERROR: {
    return {
      ...state,
      config: {
        loading: false,
        finished: true,
        error: action.error
      }
    };
  }
  case actionTypes.SEND_EXTERNAL_USER_SUCCESS:
    return {
      ...state,
      externalUser: {
        loading: false,
        finished: true
      }
    };

  case actionTypes.GET_GAMA_REPORT:
    return {
      ...state,
      gamaReport: {
        loading: true
      }
    };
  case actionTypes.GET_GAMA_REPORT_SUCCESS:
    return {
      ...state,
      gamaReport: {
        loading: false,
        finished: true
      }
    };
  case actionTypes.GET_GAMA_REPORT_ERROR:
    return {
      ...state,
      gamaReport: {
        loading: false,
        finished: true,
        error: action.error
      }
    };
  case actionTypes.GET_GAMA_REPORT_RESET:
    return {
      ...state,
      gamaReport: {
        loading: false,
        finished: false,
        error: false
      }
    };

  case actionTypes.GET_LOCATIONS_ON_CHARGE:
    return {
      ...state,
      locationsOnCharge: {
        loading: true
      }
    };
  case actionTypes.GET_LOCATIONS_ON_CHARGE_SUCCESS:
    return {
      ...state,
      locationsOnCharge: {
        loading: false,
        finished: true,
        data: action.data
      }
    };
  case actionTypes.GET_LOCATIONS_ON_CHARGE_ERROR:
    return {
      ...state,
      locationsOnCharge: {
        loading: false,
        finished: true,
        error: action.error
      }
    };
  case actionTypes.GET_LOCATIONS_ON_CHARGE_RESET:
    return {
      ...state,
      locationsOnCharge: {
        loading: false,
        finished: false,
        error: false
      }
    };
  default:
    return state;
  }
}

export default reducer;

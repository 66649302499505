import React, {
  Fragment, useRef, useCallback, useState, useEffect
} from 'react';
import { isEmpty } from 'lodash';

import Dropable from './drop/TableDropable';
import Dragable from './drag/TableDragable';
import { modifyTablePositions } from '../../../helpers/myEvolution/calibracion';

import '../../../static/styles/shared/draging/simpleTable.less';

const RowBeginingComponent = ({
  label, IconComponent, iconClassName, noIcon
}) => {
  const getIcon = () => {
    if (noIcon) return <Fragment/>;

    return IconComponent ? (
      <IconComponent {...{ ...(iconClassName ? { className: iconClassName } : null) }}/>
    ) : (
      <i {...{ ...(iconClassName ? { className: iconClassName } : null) }}/>
    );
  };

  return (
    <div className="mb-0 text-center">
      {getIcon()}
      <span className={'ml-2'}>{label}</span>
    </div>
  );
};

const SimpleTable = ({ leftColumn, rightColumn, setOutsideTable = null }) => {
  const leftRowsRefs = useRef([]);
  const [tableData, setTableData] = useState(rightColumn);
  const [initialRenderDone, setInitialRenderDone] = useState(true);

  const handleDrop = ({ newX, newY, item }) => {
    const { currentX, currentY } = item;
    const newTable = modifyTablePositions({
      table: [...tableData], currentX, currentY, newX, newY, item
    });
    if (setOutsideTable) {
      setOutsideTable(newTable);
    } else {
      setTableData(newTable);
    }
  };

  const renderItems = (x, y) => tableData[x][y].filter((v) => !isEmpty(v)).map((v, i) => (
    <Dragable key={`dragable-${i}`} {...v} handleDrop={handleDrop}/>
  ));

  const trackHeight = useCallback((node) => {
    if (node !== null) {
      const rowToSet = parseInt(node.id.split(/-/g)[2], 0);
      const leftRowsRef = leftRowsRefs.current[rowToSet];
      if (leftRowsRefs) leftRowsRef.style.height = `${node.getBoundingClientRect().height}px`;
    }
  });

  const buildLeftColumn = () => {
    const resp = leftColumn.map(({
      label, noIcon, IconComponent, iconClassName
    }, i) => (
      <li
        key={`left-column-${i}`}
        ref={(el) => { leftRowsRefs.current[i] = el; }}
        className="d-flex flex-column justify-content-center"
      >
        <RowBeginingComponent
          label={label}
          noIcon={noIcon}
          IconComponent={IconComponent}
          iconClassName={!IconComponent && !iconClassName ? 'icon-ml-location pending-icon-color' : iconClassName}
        />
      </li>
    ));
    return resp;
  };

  const buildTableHead = (skills) => (
    <tr id="right-row-0" ref={trackHeight}>
      {skills.map((item, i) => (
        <th key={`thead-${i}`}>
          <span className="text-center">{item}</span>
        </th>
      ))}
    </tr>
  );

  const buildTableBody = () => {
    const resp = rightColumn.map((firstLoop, x) => (
      <tr id={`right-row-${x + 1}`} ref={trackHeight} key={`x-${x}`}>
        {rightColumn[x].map((secondLoop, y) => (
          <Dropable key={`y-${y}`} x={x} y={y} handleDrop={handleDrop} accept="potencial">
            {renderItems(x, y)}
          </Dropable>
        ))}
      </tr>
    ));
    return resp;
  };

  // Ensure right part of table is in the dom before create left part
  useEffect(() => {
    if (!initialRenderDone) setInitialRenderDone(true);
    else setInitialRenderDone(false);
  }, [document.getElementById('right-row-2')]);

  return (
    <div className="dragging-simple-table">
      <div className="general-wrapper d-flex">
        <ul className="options-wrapper list-unstyled">
          {buildLeftColumn()}
        </ul>
        <div className="table-wrapper flex-grow-1">
          <div className="overflow-wrapper">
            <table>
              <thead>
                {buildTableHead(['Colaboradores'])}
              </thead>
              <tbody>
                {buildTableBody()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleTable;

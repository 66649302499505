import axiosOriginal from 'axios';
import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';
import { axiosFileUpload } from '../../helpers/axios/axiosFileUpload';
import { getItem } from '../../helpers/cookiesUtil';
import { getErrorDetails, getSuccessDetails } from '../../helpers/axios/getStatusDetails';

const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

export const createTicketAPI = async ({
  file, ticket, collab, initComment
}) => {
  try {
    return await axiosFileUpload({
      file,
      payload: { ticket, collab, initComment },
      url: `${BFF_ENDPOINT}/help/ticket`
    });
  } catch (e) {
    return e;
  }
};

export const addCommentAPI = async ({ body, file }) => {
  try {
    return await axiosFileUpload({
      file,
      payload: { body },
      url: `${BFF_ENDPOINT}/help/ticket/addComment`
    });
  } catch (e) {
    return e;
  }
};

export const homeHelpAPI = ({ rut }) => axios()
  .get(`${BFF_ENDPOINT}/help/home/${rut}`)
  .then((resp) => getSuccessDetails(resp))
  .catch((e) => getErrorDetails(e));

export const getFileAPI = ({ id, title, type }) => {
  const token = getItem('token');
  return axiosOriginal({
    method: 'get',
    url: `${BFF_ENDPOINT}/help/ticket/binaryUrl/${id}`,
    responseType: 'arraybuffer',
    maxBodyLength: Infinity,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(async (response) => {
      const { data, headers } = response;
      const datablob = await new Blob([data], { type: headers['content-type'] });
      const link = document.createElement('a');
      link.style.display = 'none';
      link.download = `${title}.${type}`;
      link.href = window.URL.createObjectURL(datablob);
      response.data = link;
      return getSuccessDetails(response);
    })
    .catch((err) => getErrorDetails(err));
};

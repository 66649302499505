/* eslint-disable import/prefer-default-export */

import gql from 'graphql-tag';

export const GET_LIST_QUESTIONS = gql`
    query listQuestions(
        $type: String!
    ) {
        listQuestions(
            type: $type
        )   
        {
            _id
            name
            inputType
            options
            maxLength
            placeHolder
            lowerOptionText
            higherOptionText
            metadata{
                tag
            }
        }
    }
`;

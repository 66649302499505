/* eslint-disable no-case-declarations */
import React, { useState, useReducer, useEffect } from 'react';
// import {
//   useLocation
// } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { DatePicker, Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';

import {
  shapeData,
  handleFilter,
  buildFilters,
  getFieldsToFilter,
  buildCheckboxes
} from '../../../../helpers/compensations/filters/salaryMyTeam';
import {
  filtersInitialState, filtersReducer, checkboxInitialState, checkboxReducer
} from '../../../../helpers/compensations/componentReducers/salaryMyTeamFilters';
import InfoMessage from '../../../../components/shared/InfoMessage';
import ScrollableTable from '../../../../components/shared/tables/ScrollableTable';
import IconList from '../../../../components/shared/bars/IconList';
// import MobileUserList from '../../../../components/shared/list/MobileUserList';
import { useWindowWidthSize } from '../../../../helpers/browser';
import FiltersModal from '../../../../components/shared/filters/FiltersModal';
import FiltersModalSection from '../../../../components/shared/filters/FiltersModalSection';
import Input from '../../../../components/shared/forms/Input';
import CheckboxGroup from '../../../../components/shared/forms/CheckBoxGroup';
import { columns } from '../../../../helpers/compensations/tableHandlers/salaryMyTeamColumns';
import MLLoading from '../../../../components/shared/MLLoading';
import { GET_TEAM_COMPENSATION } from '../../../../graphql/compensations/queries';
import ErrorMessage from '../../../../components/shared/Error';
import { downloadXLSX } from '../../../../helpers/workSheetHandler';
import { shapeSalaryMyTeam } from '../../../../helpers/compensations/dictionaries';

import '../../../../static/styles/compensaciones/mi-equipo/mi-equipo.less';

const { RangePicker } = DatePicker;

const DATE_FORMAT = 'ddd DD MMM YYYY';
// TODO refactor mobile view that was broken and commented in this file

const MyTeam = () => {
  // console.log(children);
  // const { props: { routes: [{ component: childrenComponent }] } } = children;
  const width = useWindowWidthSize();
  // const { pathname } = useLocation();
  const [showFilters, setShowFilters] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(filtersInitialState);
  const [tableData, setTableData] = useState([]);
  const [filtersState, filtersDispatch] = useReducer(filtersReducer, filtersInitialState);
  const [checkboxState, checkboxDispatch] = useReducer(checkboxReducer, checkboxInitialState);

  const [getTeamCompensation, {
    data: { myTeamCompensation } = {},
    loading: getTeamCompensationLoading,
    error: getTeamCompensationError
  }] = useLazyQuery(GET_TEAM_COMPENSATION, {
    context: { clientName: 'compensations' },
    fetchPolicy: 'network-only',
    onCompleted: ({ myTeamCompensation: initial }) => {
      const shaped = shapeData(initial);
      checkboxDispatch({
        type: 'set',
        data: buildCheckboxes(['band', 'talentCategory', 'performanceCategory'], shaped)
      });
      setTableData(shaped);
    }
  });

  useEffect(() => {
    if (!myTeamCompensation) {
      getTeamCompensation();
    }
  }, []);

  const resetFilters = () => {
    filtersDispatch({ field: 'reset' });
    setFiltersApplied(filtersInitialState);
    setTableData(shapeData(myTeamCompensation));
  };

  const openModal = (value) => {
    setShowFilters(value);
    filtersDispatch({ field: 'reset', value: filtersApplied });
  };

  const applyFilters = () => {
    const fields = getFieldsToFilter(filtersState);
    const filters = buildFilters(fields, filtersState);
    const filteredData = handleFilter(filters, myTeamCompensation);

    setFiltersApplied({ ...filtersState, totalApplied: fields.length });
    openModal(false, fields.length);
    setTableData(filteredData);
    filtersDispatch({ field: 'totalApplied', value: fields.length });
  };

  const getIconList = () => ([
    {
      label: 'FILTRAR',
      onClick: () => openModal(true),
      icon: 'icon-ml-filtrar',
      badge: filtersApplied.totalApplied
    },
    {
      label: 'DESCARGAR',
      onClick: async () => {
        (await downloadXLSX(shapeSalaryMyTeam, tableData))(`compensaciones-mi-equipo_${moment().format('DD-MM-YYYY')}`);
      },
      icon: 'icon-ml-excel'
    }
  ]);

  return (
    <div className='compensations-my-team'>
      {
        width > 575
          && (<>
            {
              getTeamCompensationLoading && <MLLoading title={'Los datos se están cargando'}
                message={'Por favor espera, ya que el proceso puede tomar algunos segundos.'}/>
            }
            {
              !getTeamCompensationError
              && !getTeamCompensationLoading
              && (<>
                <div>
                  <FiltersModal
                    show={showFilters}
                    hide={() => openModal(false)}
                    resetFilters={() => resetFilters()}
                    applyFilters={applyFilters}
                  >
                    <FiltersModalSection label='Cargo del colaborador' badge={filtersState.position ? 1 : 0}>
                      <Input
                        value={filtersState.position}
                        onChange={(e) => filtersDispatch({ field: 'position', value: e.target.value })}
                      />
                    </FiltersModalSection>
                    <FiltersModalSection label='Ingreso' badge={filtersState.dateOld.length ? 1 : 0}>
                      <RangePicker
                        value={filtersState.dateOld}
                        onChange={(value) => filtersDispatch({ field: 'dateOld', value })}
                        className='date-input'
                        format={DATE_FORMAT}
                        separator='a'
                      />
                    </FiltersModalSection>
                    <FiltersModalSection label='Antigüedad último cargo' badge={filtersState.positionOld.length ? 1 : 0}>
                      <RangePicker
                        value={filtersState.positionOld}
                        onChange={(value) => filtersDispatch({ field: 'positionOld', value })}
                        className='date-input'
                        format={DATE_FORMAT}
                        separator='a'
                      />
                    </FiltersModalSection>
                    {
                      !isEmpty(checkboxState.band) && (
                        <FiltersModalSection label='Banda' badge={filtersState.band.length}>
                          <CheckboxGroup
                            options={checkboxState.band}
                            value={filtersState.band}
                            onChange={(value) => filtersDispatch({ field: 'band', value })}
                          />
                        </FiltersModalSection>
                      )
                    }
                    <FiltersModalSection label='Posicionamiento' badge={filtersState.positioning.length}>
                      <CheckboxGroup
                        options={checkboxState.positioning}
                        value={filtersState.positioning}
                        onChange={(value) => filtersDispatch({ field: 'positioning', value })}
                      />
                    </FiltersModalSection>
                    <FiltersModalSection
                      label='Sueldo base actual'
                      badge={filtersState.salaryFrom && filtersState.salaryTo ? 1 : 0 } >
                      <Row>
                        <Col span={8}>
                          <Input
                            value={filtersState.salaryFrom}
                            onChange={(e) => filtersDispatch({ field: 'salaryFrom', value: e.target.value })}
                          />
                        </Col>
                        <Col span={2}><p className='text-center'>a</p></Col>
                        <Col span={8}>
                          <Input
                            value={filtersState.salaryTo}
                            onChange={(e) => filtersDispatch({ field: 'salaryTo', value: e.target.value })}
                          />
                        </Col>
                        <Col span={4} />
                      </Row>
                    </FiltersModalSection>
                    {
                      !isEmpty(checkboxState.performanceCategory) && (
                        <FiltersModalSection label='Desempeño' badge={filtersState.performanceCategory.length}>
                          <CheckboxGroup
                            options={checkboxState.performanceCategory}
                            value={filtersState.performanceCategory}
                            onChange={(value) => filtersDispatch({ field: 'performanceCategory', value })}
                          />
                        </FiltersModalSection>
                      )
                    }
                    {
                      !isEmpty(checkboxState.talentCategory) && (
                        <FiltersModalSection label='Talento' badge={filtersState.talentCategory.length}>
                          <CheckboxGroup
                            options={checkboxState.talentCategory}
                            value={filtersState.talentCategory}
                            onChange={(value) => filtersDispatch({ field: 'talentCategory', value })}
                          />
                        </FiltersModalSection>
                      )
                    }
                  </FiltersModal>
                </div>
                <InfoMessage classes='mt-3 mb-1' message='Los cambios de compensación y estructura organizacional se verán reflejados a más tardar la última semana del mes en el que se hacen efectivos.' />
                <IconList classes={'mt-3'} options={getIconList()} />
                <div className='ant-table-wrapper-div'>
                  <ScrollableTable classes='mt-4' columns={columns} dataSource={tableData} scroll={{ x: 'max-content' }} />
                </div>
              </>)
            }
            {
              !getTeamCompensationLoading
              && getTeamCompensationError && (
                <ErrorMessage message='Disculpe las molestias' />
              )
            }
          </>
          )
      }
      {/* {
        width <= 575
        && (
          <MobileUserList
            loading={getTeamCompensationLoading}
            ErrorComponent={getTeamCompensationError ?
              <ErrorMessage message="Disculpe las molestias"/> : false}
            LoadingComponent={<MLLoading
              title={'Los datos se están cargando'}
              message={'Por favor espera, ya que el proceso puede tomar algunos segundos.'}
            />}
            data={tableData.map((v) => ({ ...v, title: v.fullName }))}
            InnerComponent={pathname === '/compensaciones/mi-equipo' ? false : childrenComponent}
          />
        )
      } */}
    </div>
  );
};
export default MyTeam;

/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const COMPETENCE_FIELDS = gql`
  fragment CompetenceFields on Competencie {
    _id
    comment {
      body
    }
    skillId{
      _id
      name
      description
    }
    myLevelBehaviors{
      _id
      order
      name
    }
    nextLevelBehaviors{
      _id
      order
      name
    }
  }

  fragment CompetenciesFields on Performance {
    competencies {
      q1 {
        ...CompetenceFields
      }
      q2 {
        ...CompetenceFields
      }
      q3 {
        ...CompetenceFields
      }
      q4 {
        ...CompetenceFields
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import '../../../static/styles/shared/buttons/final-buttons.less';

const FinalButtons = ({
  approveText,
  rejectText,
  approveFunc,
  rejectFunc,
  ApproveButton,
  RejectButton
}) => (
  <div className='ml-final-buttons'>
    {RejectButton ? (
      <RejectButton/>
    ) : rejectText && (
      <Button onClick={rejectFunc} className='ml-reject-button'>
        {rejectText}
      </Button>
    )}

    {ApproveButton ? (
      <ApproveButton/>
    ) : (
      <Button type="primary" onClick={approveFunc}>
        {approveText}
      </Button>
    )}
  </div>
);

FinalButtons.propTypes = {
  approveText: PropTypes.string,
  rejectText: PropTypes.string,
  approveFunc: PropTypes.func,
  rejectFunc: PropTypes.func,
  ApproveButton: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  RejectButton: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object])
};

FinalButtons.defaultProps = {
  approveText: 'Aprobar',
  rejectText: 'Rechazar',
  approveFunc: () => null,
  rejectFunc: () => null,
  ApproveButton: null,
  RejectButton: null
};

export default FinalButtons;

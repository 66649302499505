import React from 'react';
import '../../static/styles/shared/contiguousBoxes.less';
import PropTypes from 'prop-types';

const ContiguousBoxes = ({ data, isMobile }) => (<div className="contiguous-boxes">
  {data.map((d, index) => (<div
    className={`contiguous-box ${isMobile ? 'box-mobile' : 'box-desktop'} ${index === 0 && 'left'} ${index === data.length - 1 && 'right'}`}
    key={d.title}
  >
    <p className="box-title">{d.title}</p>
    <p className="box-body">{d.body}</p>
  </div>))}
</div>);

export default ContiguousBoxes;

ContiguousBoxes.propTypes = {
  data: PropTypes.array,
  isMobile: PropTypes.bool
};

ContiguousBoxes.defaultProps = {
  data: [],
  isMobile: false
};

import React from 'react';
import { useSelector } from 'react-redux';
import getStepsData from '../../../../../helpers/myEvolution/myPerformance/getStepsData';
import Status from '../../../../shared/MLStep';
import TransparentCard from '../../../../shared/cards/TransparentCard';
import '../../../../../static/styles/mievolucion/myPerformance/my-performance-pulses.less';
import { PERFORMANCE_YEAR } from '../../../../../helpers/myEvolution/myPerformance';

const PulsesComponent = ({ loading }) => {
  const {
    permissions: {
      MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_DESEMPEÑO_PULSO_MANUAL: hasPulse,
      MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_DESEMPEÑO_PULSO_CENTRALIZADOS: hasPulseButFixed
    }
  } = useSelector((state) => state.userReducer.data);

  return hasPulse || hasPulseButFixed ? (
    <TransparentCard className='ml-my-evolution-my-performance-pulses' loading={loading}>
      <Status {...{
        finishedMark: false,
        closedMark: false,
        title: (
          <>
            <span>Desempeño {PERFORMANCE_YEAR}</span>
            {' '}|{' '}
            <span>Estos son los Pulsos que se vivirán durante el año:</span>
          </>
        ),
        ...getStepsData()
      }}/>
    </TransparentCard>
  ) : (
    <></>
  );
};

export default PulsesComponent;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import '../../../static/styles/shared/buttons/link-button.less';

const MLLinkButton = ({
  text, onClick, className, iconClassName
}) => {
  const getClassName = () => {
    let classNameResult = 'ml-link-button';
    if (className) classNameResult += ` ${className}`;
    return classNameResult;
  };

  return (
    <Button type='link' className={getClassName()} onClick={onClick}>
      {iconClassName ? (
        <i className={iconClassName}/>
      ) : null}
      {text}
    </Button>
  );
};

MLLinkButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  onClick: PropTypes.func
};

MLLinkButton.defaultProps = {
  text: '',
  className: '',
  iconClassName: '',
  onClick: () => null
};

export default MLLinkButton;

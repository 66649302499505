import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import '../../static/styles/shared/error.less';

const MLError = (props) => {
  const { title, message, reload } = props;

  return (
    <div className="row">
      <div className="error text-center">
        <h6>
          {title}
        </h6>
        { message
      && (
        <p>
          {message}
        </p>
      )
        }
        { reload && (
          <Button type="primary" onClick={reload}>
            Intentar de nuevo
          </Button>
        )}
      </div>
    </div>
  );
};
MLError.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  reload: PropTypes.func
};

MLError.defaultProps = {
  title: 'Se ha producido un error de conexión. Por favor inténtalo de nuevo más tarde',
  message: '',
  reload: null
};
export default MLError;

import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import { map } from 'lodash';
import '../../../static/styles/shared/forms/styles.less';

const RadioComponent = (props) => {
  const {
    type,
    options,
    value,
    setValue
  } = props;

  return (
    <Radio.Group
      {...{
        className: `ml-${type ? `${type}-` : ''}radio-group`,
        ...(typeof value === 'number' ? { value } : null)
      }}
      onChange={(e) => {
        e.preventDefault();
        if (setValue) {
          setValue(e.target.value);
        }
      }}
      {...props}
    >
      {map(options, (option, i) => <Radio key={i} value={option.value}>{option.label}</Radio>)}
    </Radio.Group>

  );
};

RadioComponent.propTypes = {
  type: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  setValue: PropTypes.func
};

RadioComponent.defaultProps = {
  type: '',
  options: [],
  value: null,
  setValue: () => null
};

export default RadioComponent;

export const actionTypes = {
  ALL_SEARCH: 'ALL_SEARCH',
  ALL_SEARCH_SUCCESS: 'ALL_SEARCH_SUCCESS',
  ALL_SEARCH_ERROR: 'ALL_SEARCH_ERROR',
  CLEAN_SEARCH: 'CLEAN_SEARCH',
  ALL_SEARCH_SELECT: 'ALL_SEARCH_SELECT',
  ALL_SEARCH_SUCCESS_SELECT: 'ALL_SEARCH_SUCCESS_SELECT',
  ALL_SEARCH_ERROR_SELECT: 'ALL_SEARCH_ERROR_SELECT'
};

export const addSearch = (word) => ({
  type: actionTypes.ALL_SEARCH,
  payload: word
});

export const addSearchExito = (res) => ({
  type: actionTypes.ALL_SEARCH_SUCCESS,
  payload: res
});

export const addSearchError = (error) => ({
  type: actionTypes.ALL_SEARCH_ERROR,
  payload: error
});
export const clearSearch = () => ({ type: actionTypes.CLEAN_SEARCH });

export const addSearchSelect = () => ({
  type: actionTypes.ALL_SEARCH_SELECT,
  payload: true
});

export const addSearchExitoSelect = (search) => ({
  type: actionTypes.ALL_SEARCH_SUCCESS_SELECT,
  payload: search
});

export const addSearchErrorSelect = (state) => ({
  type: actionTypes.ALL_SEARCH_ERROR_SELECT,
  payload: state
});

import { actionTypes } from '../actions/mibono';

export const initialState = {
  loaded: false,
  loading: false,
  error: false,
  loadedHistorial: false,
  loadingHistorial: false,
  errorHistorial: false,
  miBonoHistorial: {},
  loadedCarga: false,
  activeYear: 0
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.SIMULATE_BONO:
    return {
      ...state,
      loading: true,
      error: false,
      loaded: false
    };
  case actionTypes.SIMULATE_BONO_SUCCESS:
    return {
      ...state,
      ...{ simulate: action.data },
      loading: false,
      loaded: true,
      error: false
    };

  case actionTypes.SIMULATE_BONO_ERROR:
    return {
      ...state,
      ...{ simulate: action.error.error },
      loading: false,
      loaded: false,
      error: true
    };

  case actionTypes.GET_BONO:
    return {
      ...state,
      loading: true,
      error: false,
      loaded: false
    };

  case actionTypes.GET_BONO_HISTORIAL:
    return {
      ...state,
      loadingHistorial: true,
      errorHistorial: false
    };

  case actionTypes.GET_BONO_SUCCESS:
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      miBono: action.data
    };

  case actionTypes.GET_BONO_ERROR:
    return {
      ...state,
      error: true
    };

  case actionTypes.GET_BONO_HISTORIAL_SUCCESS:
    return {
      ...state,
      loadingHistorial: false,
      loadedHistorial: true,
      errorHistorial: false,
      miBonoHistorial: action.data
    };

  case actionTypes.GET_BONO_HISTORIAL_ERROR:
    return {
      ...state,
      errorHistorial: true
    };

  case actionTypes.GET_PDF_SUCCESS:
    return {
      ...state,
      ...{ miPDF: action.data }
    };

  case actionTypes.GET_HISTORY:
    return {
      ...state,
      loading: true,
      error: false,
      loadedCarga: false
    };

  case actionTypes.GET_HISTORY_SUCCESS:
    return {
      ...state,
      loading: false,
      loadedCarga: true,
      error: false,
      ...{ History: action.data }
    };

  case actionTypes.GET_ALL_METRICS_SUCCESS:
    return {
      ...state,
      ...{ allMetrics: action.data }
    };

  case actionTypes.GET_ALL_DESCRIPTIONS_SUCCESS:
    return {
      ...state,
      ...{ allDescriptions: action.data }
    };

  case actionTypes.CLEAN_MI_BONO:
    return initialState;

  case actionTypes.GET_ACTIVE_YEAR:
    return {
      ...state
      // loading: true,
      // error: false,
    };

  case actionTypes.GET_ACTIVE_YEAR_SUCCESS:
    return {
      ...state,
      // loading: false,
      // error: false,
      ...{ activeYear: action.data }
    };

  default:
    return state;
  }
}

export default reducer;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  notification
} from 'antd';
import { ReportDetails } from '../../neo-shared/esm';
import { resetRequestByCode, getDetailsByCodeReportsHistory, setRequestByCode } from '../../redux/actions/reports';

const ReportByCode = (props) => {
  const { jobCode, reportDetails } = props;

  const dispatch = useDispatch();

  const resetReportRequestByCode = () => {
    dispatch(resetRequestByCode());
  };
  const getJobs = () => {
    dispatch(getDetailsByCodeReportsHistory(jobCode));
  };

  const {
    requestByCode,
    loadingRequestByCode,
    errorRequestByCode,
    detailsByCode,
    loadingDetailsByCodeReportsHistory,
    errorDetailsByCodeReportsHistory

  } = useSelector((state) => state.reportsReducer);

  useEffect(() => {
    resetReportRequestByCode();
    getJobs();
  }, []);

  const defaultNotificationOptions = {
    message: reportDetails.title,
    onClose: resetReportRequestByCode,
    onClick: resetReportRequestByCode,
    duration: 8

  };

  useEffect(() => {
    if (!loadingRequestByCode && requestByCode?.message) {
      getJobs();
      notification.success({
        ...defaultNotificationOptions,
        description: requestByCode.message

      });
    }
  }, [requestByCode?.message, loadingRequestByCode]);

  useEffect(() => {
    if (!loadingRequestByCode && errorRequestByCode) {
      notification.error({
        ...defaultNotificationOptions,
        description: 'Ocurrió un error al generar reporte'

      });
    }
  }, [errorRequestByCode, loadingRequestByCode]);

  return (
    <div>
      <div className='neo-shared'>
        <ReportDetails
          {...{
            details: {
              title: reportDetails.title,
              description: reportDetails.description,
              jobCode
            },
            error: !!errorDetailsByCodeReportsHistory,
            reportJobs: detailsByCode?.jobs || [],
            loading: loadingDetailsByCodeReportsHistory,
            requestLoading: loadingRequestByCode,
            onRequest: () => dispatch(setRequestByCode(jobCode)),
            onRefetch: () => getJobs(),
            config: {
              canQueue: false,
              singleRun: true,
              jobCode
            }
          }}
        />
      </div>
    </div>
  );
};

export default ReportByCode;

import React from 'react';
import '../../../../../static/styles/mievolucion/objectives2022/border-div.less';
import { objectiveRowHeights, pillarColor } from '../../../../../helpers/myEvolution/myPerformance';

const borderDiv = ({
  dimensions, type, rowsQuantity, index
}) => {
  const isLastRow = rowsQuantity === index + 1;
  const isTwoRows = dimensions?.length > 0
    && dimensions[index]?.height === objectiveRowHeights['2'];
  const isThreeRows = dimensions?.length > 0
  && dimensions[index]?.height === objectiveRowHeights['3'];
  const isFourRows = dimensions?.length > 0
    && dimensions[index]?.height === objectiveRowHeights['4'];
  const isMoreThanFourRows = dimensions?.length > 0
    && dimensions[index]?.height > objectiveRowHeights['4'];

  let className = `border-div border-div-${pillarColor[type]}`;

  if (isTwoRows) {
    className += ' two-rows-height';
  } else if (isThreeRows) {
    className += ' three-rows-height';
  } else if (isFourRows) {
    className += ' four-rows-height';
  } else if (isMoreThanFourRows) {
    className += ' more-than-four-height';
  }

  if (isLastRow) {
    className += ' last-row';
  }

  return (<div className={className} />);
};

export default borderDiv;

import AdminMyEvolution from '../../../containers/admin/MiEvolucion/index';
// import PerformanceEvaluation from '../../../containers/admin/MiEvolucion/PerformanceEvaluation';
import AdminMyEvolutionReportsInterestAndSkills from '../../../containers/admin/MiEvolucion/ReportInterestAndSkills';
import AdminMyEvolutionPerformance from '../../../containers/admin/MiEvolucion/Desempeno';
import AdminMyEvolutionRETRO from '../../../containers/admin/MiEvolucion/Retro';

import UploadsMyPerformance from '../../../components/admin/mievolucion/desempeno/Uploads';
import SearchMyPerformance from '../../../components/admin/mievolucion/desempeno/Search';
import ReportsMyPerformance from '../../../components/admin/mievolucion/desempeno/Reports';

import MyPerformanceFeedback from '../../../components/admin/mievolucion/desempeno/Reports/Feedback';
import MyPerformancePerformanceEvaluation from '../../../components/admin/mievolucion/desempeno/Reports/PerformanceEvaluation';
import MyPerformanceEDP from '../../../components/admin/mievolucion/desempeno/Reports/EDP';
import MyPerformancePerformanceWalmart from '../../../components/admin/mievolucion/desempeno/Reports/PerformanceWalmart';
import MyPerformancePerformance from '../../../components/admin/mievolucion/desempeno/Reports/Performance';

import AdminMyEvolutionMiDesarrolloTabs from '../../../containers/admin/MiEvolucion/MiDesarrolloTabs';
import VisualizarIDP from '../../../containers/admin/MiEvolucion/MiDesarrollo/VisualizeIDP';
import ReporteIDP from '../../../containers/admin/MiEvolucion/MiDesarrollo/Reports';

export default [
  {
    exact: true,
    path: '/administrador/mi-evolucion',
    section: 'Administración -> Mi Evolución',
    component: AdminMyEvolution,
    auth: 'ADMIN_MI_EVOLUCION'
  },

  {
    path: '/administrador/mi-evolucion/desempeño',
    section: 'Administración -> Mi Evolución -> Desempeño',
    component: AdminMyEvolutionPerformance,
    auth: 'ADMIN_MI_EVOLUCION_PERFORMANCE',
    subRoutes: [
      {
        exact: true,
        headerTitle: null,
        tabTitle: 'Cargar Archivo',
        path: '/administrador/mi-evolucion/desempeño/cargar-archivos',
        section: 'Administración -> Mi Evolución -> Desempeño -> Cargar Archivos',
        component: UploadsMyPerformance,
        auth: 'ADMIN_MI_EVOLUCION_PERFORMANCE_UPLOAD_FILES'
      },
      {
        exact: true,
        headerTitle: null,
        tabTitle: 'Visualizar',
        path: '/administrador/mi-evolucion/desempeño/visualizar',
        section: 'Administración -> Mi Evolución -> Desempeño -> Visualizar',
        component: SearchMyPerformance,
        auth: 'ADMIN_MI_EVOLUCION_PERFORMANCE_VISUALIZE'
      },
      {
        headerTitle: null,
        tabTitle: 'Reportes',
        path: '/administrador/mi-evolucion/desempeño/reportes',
        section: 'Administración -> Mi Evolución -> Desempeño -> Reportes',
        component: ReportsMyPerformance,
        auth: 'ADMIN_MI_EVOLUCION_PERFORMANCE_REPORTS',
        subRoutes: [
          {
            exact: true,
            headerTitle: null,
            tabTitle: 'Feedback',
            path: '/administrador/mi-evolucion/desempeño/reportes/feedback',
            section: 'Administración -> Mi Evolución -> Desempeño -> Reportes -> Feedback',
            component: MyPerformanceFeedback
          },
          {
            exact: true,
            headerTitle: null,
            tabTitle: 'Pulsos 2022',
            path: '/administrador/mi-evolucion/desempeño/reportes/desempeño-2022',
            section: 'Administración -> Mi Evolución -> Desempeño -> Reportes -> Pulsos 2022',
            component: MyPerformancePerformanceWalmart
          },
          {
            exact: true,
            headerTitle: null,
            tabTitle: 'Pulsos 2021',
            path: '/administrador/mi-evolucion/desempeño/reportes/desempeño-2021',
            section: 'Administración -> Mi Evolución -> Desempeño -> Reportes -> Desempeño 2021',
            component: MyPerformancePerformance
          },
          {
            exact: true,
            headerTitle: null,
            tabTitle: 'Pulsos 2021',
            path: '/administrador/mi-evolucion/desempeño/reportes/evaluación-de-desempeño',
            section: 'Administración -> Mi Evolución -> Desempeño -> Reportes -> Evaluación de desempeño',
            component: MyPerformancePerformanceEvaluation
          },
          {
            exact: true,
            headerTitle: null,
            tabTitle: 'Pulsos 2021',
            path: '/administrador/mi-evolucion/desempeño/reportes/evaluación-de-potencial',
            section: 'Administración -> Mi Evolución -> Desempeño -> Reportes ->  Evaluación de Potencial',
            component: MyPerformanceEDP
          }
        ]
      }
    ]
  },
  {
    exact: true,
    path: '/administrador/mi-evolucion/retro',
    section: 'Administración -> Mi Evolución -> Retroalimentación',
    component: AdminMyEvolutionRETRO,
    auth: 'ADMIN_MI_EVOLUCION_RETRO'
  },
  {
    exact: true,
    path: '/administrador/mi-evolucion/intereses-y-habilidades',
    section: 'Administración -> Mi Evolución -> Reportes intereses y habilidades',
    component: AdminMyEvolutionReportsInterestAndSkills,
    auth: 'ADMIN_MI_EVOLUCION_INTERESES_Y_HABILIDADES'
  },

  {
    exact: false,
    tabs: true,
    firstTab: true,
    path: '/administrador/mi-evolucion/mi-desarrollo',
    section: 'Administración -> Mi Evolución -> Mi Desarrollo',
    component: AdminMyEvolutionMiDesarrolloTabs,
    auth: 'ADMIN_MI_EVOLUCION_MI_DESARROLLO_REPORTS',
    subRoutes: [

      {
        exact: true,
        path: '/administrador/mi-evolucion/mi-desarrollo/visualizar-idp',
        section: 'Administración -> Mi Evolución -> Mi Desarrollo -> Visualizar IDP',
        component: VisualizarIDP,
        auth: 'ADMIN_MI_EVOLUCION_MI_DESARROLLO_REPORTS'

      },
      {
        exact: true,
        path: '/administrador/mi-evolucion/mi-desarrollo/reportes',
        section: 'Administración -> Mi Evolución -> Mi Desarrollo -> Reportes',
        component: ReporteIDP,
        auth: 'ADMIN_MI_EVOLUCION_MI_DESARROLLO_REPORTS'
      }
    ]
  }

];

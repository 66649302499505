export const actionTypes = {
  FAILURE: 'FAILURE',
  GET_MAIN_BENEFITS: 'GET_MAIN_BENEFITS',
  GET_MAIN_BENEFITS_SUCCESS: 'GET_MAIN_BENEFITS_SUCCESS',
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_BENEFIT_STATUS: 'GET_BENEFIT_STATUS',
  GET_BENEFIT_STATUS_SUCCESS: 'GET_BENEFIT_STATUS_SUCCESS',
  GET_BENEFIT_STATUS_ERROR: 'GET_BENEFIT_STATUS_ERROR',
  POST_BENEFIT_REQUEST: 'POST_BENEFIT_REQUEST',
  POST_BENEFIT_REQUEST_SUCCESS: 'POST_BENEFIT_REQUEST_SUCCESS',
  POST_BENEFIT_REQUEST_ERROR: 'POST_BENEFIT_REQUEST_ERROR',
  GET_BENEFIT_DETAILS: 'GET_BENEFIT_DETAILS',
  GET_BENEFIT_DETAILS_SUCCESS: 'GET_BENEFIT_DETAILS_SUCCESS',
  GET_HOME_CATEGORIES: 'GET_HOME_CATEGORIES',
  GET_HOME_CATEGORIES_SUCCESS: 'GET_HOME_CATEGORIES_SUCCESS',
  GET_TEAM_BENEFITS: 'GET_TEAM_BENEFITS',
  GET_TEAM_BENEFITS_SUCCESS: 'GET_TEAM_BENEFITS_SUCCESS',
  GET_TEAM_BENEFITS_ERROR: 'GET_TEAM_BENEFITS_ERROR',
  POST_REQUEST_STATUS: 'POST_REQUEST_STATUS',
  POST_REQUEST_STATUS_SUCCESS: 'POST_REQUEST_STATUS_SUCCESS',
  POST_REQUEST_STATUS_ERROR: 'POST_REQUEST_STATUS_ERROR',
  DISMISS_MESSAGE: 'DISMISS_MESSAGE',
  GET_FLICKR_ALBUM: 'GET_FLICKR_ALBUM',
  GET_FLICKR_ALBUM_SUCCESS: 'GET_FLICKR_ALBUM_SUCCESS',
  GET_MY_BENEFITS: 'GET_MY_BENEFITS',
  GET_MY_BENEFITS_SUCCESS: 'GET_MY_BENEFITS_SUCCESS',
  GET_MY_BENEFITS_ERROR: 'GET_MY_BENEFITS_ERROR',
  SET_CATEGORY: 'SET_CATEGORY',
  SET_CATEGORY_SUCCESS: 'SET_CATEGORY_SUCCESS',
  GET_MONTHLY_STATUS: 'GET_MONTHLY_STATUS',
  GET_MONTHLY_STATUS_SUCCESS: 'GET_MONTHLY_STATUS_SUCCESS',
  GET_GIFT_DAY_WEEKS: 'GET_GIFT_DAY_WEEKS',
  GET_GIFT_DAY_WEEKS_SUCCESS: 'GET_GIFT_DAY_WEEKS_SUCCESS',
  GET_SCHOOL_WINNERS: 'GET_SCHOOL_WINNERS',
  GET_SCHOOL_WINNERS_SUCCESS: 'GET_SCHOOL_WINNERS_SUCCESS',
  CLEAN_BENEFIT_REQUEST: 'CLEAN_BENEFIT_REQUEST',
  POST_BENEFIT_DISCONNECTION: 'POST_BENEFIT_DISCONNECTION',
  POST_BENEFIT_DISCONNECTION_SUCCESS: 'POST_BENEFIT_DISCONNECTION',
  POST_BENEFIT_DISCONNECTION_ERROR: 'POST_BENEFIT_DISCONNECTION',
  POST_REQUEST_FILES: 'POST_REQUEST_FILES',
  POST_REQUEST_FILE_STATUS_SUCCESS: 'POST_REQUEST_FILE_STATUS_SUCCESS',
  POST_REQUEST_FILE_STATUS_ERROR: 'POST_REQUEST_FILE_STATUS_ERROR'
};

export function failure(error) {
  return {
    type: actionTypes.FAILURE,
    error
  };
}
export function cleanBenefitRequest() {
  return {
    type: actionTypes.CLEAN_BENEFIT_REQUEST
  };
}
export function getSchoolWinners() {
  return {
    type: actionTypes.GET_SCHOOL_WINNERS
  };
}

export function getSchoolWinnersSuccess(data) {
  return {
    type: actionTypes.GET_SCHOOL_WINNERS_SUCCESS,
    data
  };
}

export function getGiftDayWeeks(benefitId) {
  return {
    type: actionTypes.GET_GIFT_DAY_WEEKS,
    data: benefitId
  };
}

export function getGiftDayWeeksSuccess(data) {
  return {
    type: actionTypes.GET_GIFT_DAY_WEEKS_SUCCESS,
    data
  };
}

export function getMainBenefits() {
  return { type: actionTypes.GET_MAIN_BENEFITS };
}

export function getCategories() {
  return { type: actionTypes.GET_CATEGORIES };
}

export function getBenefitStatus(payload) {
  return { type: actionTypes.GET_BENEFIT_STATUS, payload };
}

export function getBenefitDetails(payload) {
  return { type: actionTypes.GET_BENEFIT_DETAILS, payload };
}

export function postBenefitRequest(payload) {
  return { type: actionTypes.POST_BENEFIT_REQUEST, payload };
}

export function getHomeCategories(payload) {
  return {
    type: actionTypes.GET_HOME_CATEGORIES,
    payload
  };
}

export function getMainBenefitsSuccess(data) {
  return {
    type: actionTypes.GET_MAIN_BENEFITS_SUCCESS,
    data
  };
}

export function getCategoriesSuccess(data) {
  return {
    type: actionTypes.GET_CATEGORIES_SUCCESS,
    data
  };
}

export function getBenefitStatusSuccess(data) {
  return {
    type: actionTypes.GET_BENEFIT_STATUS_SUCCESS,
    data
  };
}

export function getBenefitStatusError(data) {
  return {
    type: actionTypes.GET_BENEFIT_STATUS_ERROR,
    data
  };
}

export function getBenefitDetailsSuccess(data) {
  return {
    type: actionTypes.GET_BENEFIT_DETAILS_SUCCESS,
    data
  };
}

export function postBenefitRequestSuccess(data) {
  return {
    type: actionTypes.POST_BENEFIT_REQUEST_SUCCESS,
    data
  };
}

export function postBenefitRequestFile(data) {
  return { type: actionTypes.POST_REQUEST_FILES, data };
}

export function postBenefitRequestFileSuccess(data) {
  return {
    type: actionTypes.POST_REQUEST_FILE_STATUS_SUCCESS,
    data
  };
}

export function postBenefitRequestFileError(data) {
  return {
    type: actionTypes.POST_REQUEST_FILE_STATUS_ERROR,
    data
  };
}

export function postBenefitRequestError(data) {
  return {
    type: actionTypes.POST_BENEFIT_REQUEST_ERROR,
    data
  };
}

export function getHomeCategoriesSuccess(data) {
  return {
    type: actionTypes.GET_HOME_CATEGORIES_SUCCESS,
    data
  };
}

export function getTeamBenefits(payload) {
  return {
    type: actionTypes.GET_TEAM_BENEFITS,
    payload
  };
}

export function getTeamBenefitsSuccess(data) {
  return {
    type: actionTypes.GET_TEAM_BENEFITS_SUCCESS,
    data
  };
}

export function getTeamBenefitsError(error) {
  return {
    type: actionTypes.GET_TEAM_BENEFITS_ERROR,
    error
  };
}

export function postRequestStatus(payload) {
  return { type: actionTypes.POST_REQUEST_STATUS, payload };
}

export function postRequestStatusSuccess(data) {
  return {
    type: actionTypes.POST_REQUEST_STATUS_SUCCESS,
    data
  };
}

export function postRequestStatusError(error) {
  return {
    type: actionTypes.POST_REQUEST_STATUS_ERROR,
    error
  };
}

export function dismissMessage() {
  return {
    type: actionTypes.DISMISS_MESSAGE
  };
}

export function getFlickrAlbum(payload) {
  return {
    type: actionTypes.GET_FLICKR_ALBUM,
    payload
  };
}

export function getFlickrAlbumSuccess(data) {
  return {
    type: actionTypes.GET_FLICKR_ALBUM_SUCCESS,
    data
  };
}

export function getMyRequests(payload) {
  return {
    type: actionTypes.GET_MY_BENEFITS,
    payload
  };
}

export function getMyRequestsSuccess(data) {
  return {
    type: actionTypes.GET_MY_BENEFITS_SUCCESS,
    data
  };
}

export function getMyRequestsError(error) {
  return {
    type: actionTypes.GET_MY_BENEFITS_ERROR,
    error
  };
}

export function getMonthlyStatus(payload) {
  return {
    type: actionTypes.GET_MONTHLY_STATUS,
    payload
  };
}

export function getMonthlyStatusSuccess(data) {
  return {
    type: actionTypes.GET_MONTHLY_STATUS_SUCCESS,
    data
  };
}

export function postBenefitsDisconection(data) {
  return {
    type: actionTypes.POST_BENEFIT_DISCONNECTION,
    data
  };
}
export function postBenefitsDisconectionSuccess(data) {
  return {
    type: actionTypes.POST_BENEFIT_DISCONNECTION,
    data
  };
}
export function postBenefitsDisconectionError(data) {
  return {
    type: actionTypes.POST_BENEFIT_DISCONNECTION,
    error: data
  };
}

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import {
  addPostCommet
} from '../api/comments';
import {
  actionTypes,
  addPostCommentSuccess,
  addPostCommentError
} from '../actions/comments';

es6promise.polyfill();

function* addNewPostCommet({ variables }) {
  try {
    const response = yield call(addPostCommet, variables);
    yield put(addPostCommentSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(addPostCommentError({ error, statusCode }));
  }
}

function* commentsSagas() {
  yield all([takeLatest(actionTypes.ADD_MURAL_COMMENT_POST, addNewPostCommet)]);
}
export default commentsSagas;

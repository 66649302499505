import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import { saveAs } from 'file-saver';
import { getLeadersPeopleStoresAPI, getLastFileUploadedPeopleStoresAPI } from '../api/leadersPeopleStores';

import {
  actionTypes,
  getLeadersPeopleStoresError,
  getLeadersPeopleStoresSuccess,
  getLastFileUploadedPeopleStoresError,
  getLastFileUploadedPeopleStoresSuccess
} from '../actions/leadersPeopleStore';

es6promise.polyfill();

export function* getLeadersPeopleStoreSaga(rut) {
  try {
    const response = yield call(getLeadersPeopleStoresAPI, rut);
    yield put(getLeadersPeopleStoresSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getLeadersPeopleStoresError(error));
  }
}

export function* getLastFileUploadedPeopleStoreSaga() {
  try {
    const file = yield call(getLastFileUploadedPeopleStoresAPI);
    const fileName = 'lpt-simple.xlsx';
    saveAs(file, fileName);
    yield put(getLastFileUploadedPeopleStoresSuccess());
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getLastFileUploadedPeopleStoresError(error));
  }
}

function* leadersPeopleStoresSagas() {
  yield all([
    takeLatest(actionTypes.GET_LEADERS_PEOPLE_STORES, getLeadersPeopleStoreSaga),
    takeLatest(actionTypes.GET_LAST_FILE_UPLOADED_PEOPLE_STORES, getLastFileUploadedPeopleStoreSaga)
  ]);
}

export default leadersPeopleStoresSagas;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import * as storageUtil from '../../helpers/cookiesUtil';

import {
  simulateBonoAPI,
  getBonoAPI,
  uploadMetricsAPI,
  uploadDescriptionsAPI,
  getHistoryAPI,
  getMetricsAPI,
  getDescriptionsAPI,
  getPDFAPI,
  getBonoHistorialAPI,
  getActiveYearAPI
} from '../api/mibono';
import {
  actionTypes,
  simulateBonoSuccess,
  simulateBonoError,
  getBonoSuccess,
  uploadMetricsSuccess,
  uploadDescriptionSuccess,
  getHistorySuccess,
  getAllMetricsSuccess,
  getAllDescriptionsSuccess,
  getPdfSuccess,
  getBonoHistorialSuccess,
  getActiveYearSuccess
} from '../actions/mibono';
import { failure, loading, loadComplete } from '../actions/commons';

es6promise.polyfill();

function* getHistory(action) {
  try {
    yield put(loading());
    const code = action.code || 'MIP';
    const response = yield call(getHistoryAPI, code);
    yield put(getHistorySuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* getAllMetrics() {
  try {
    yield put(loading());
    const response = yield call(getMetricsAPI);
    yield put(getAllMetricsSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* getAllDescriptions() {
  try {
    yield put(loading());
    const response = yield call(getDescriptionsAPI);
    yield put(getAllDescriptionsSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* simulateBono(actions) {
  try {
    yield put(loading());
    const response = yield call(simulateBonoAPI, actions.item);
    yield put(simulateBonoSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(simulateBonoError({ error, statusCode }));
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* getBono() {
  try {
    yield put(loading());
    const token = storageUtil.getItem('token');
    const response = yield call(getBonoAPI, { token });
    yield put(getBonoSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* getBonoHistorial() {
  try {
    const token = storageUtil.getItem('token');
    const response = yield call(getBonoHistorialAPI, { token });
    yield put(getBonoHistorialSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* getPDF(actions) {
  try {
    yield put(loading());
    const token = storageUtil.getItem('token');
    let response;
    if (typeof actions.data !== 'undefined') {
      response = yield call(getPDFAPI, { token, rut: actions.data.rut });
    } else {
      response = yield call(getPDFAPI, { token });
    }
    yield put(getPdfSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* uploadMetrics(actions) {
  try {
    yield put(loading());
    const token = storageUtil.getItem('token');
    const params = { token, file: actions.params, name: actions.params.name };
    const response = yield call(uploadMetricsAPI, params);
    yield put(uploadMetricsSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* uploadDescriptions(actions) {
  try {
    yield put(loading());
    const token = storageUtil.getItem('token');
    const params = { token, file: actions.params, name: actions.params.name };
    const response = yield call(uploadDescriptionsAPI, params);
    yield put(uploadDescriptionSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* getActiveYear() {
  try {
    yield put(loading());
    const response = yield call(getActiveYearAPI);
    yield put(getActiveYearSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* miBonoSagas() {
  yield all([
    takeLatest(actionTypes.SIMULATE_BONO, simulateBono),
    takeLatest(actionTypes.GET_BONO, getBono),
    takeLatest(actionTypes.UPLOAD_METRICS, uploadMetrics),
    takeLatest(actionTypes.UPLOAD_DESCRIPTION, uploadDescriptions),
    takeLatest(actionTypes.GET_HISTORY, getHistory),
    takeLatest(actionTypes.GET_ALL_METRICS, getAllMetrics),
    takeLatest(actionTypes.GET_ALL_DESCRIPTIONS, getAllDescriptions),
    takeLatest(actionTypes.GET_PDF, getPDF),
    takeLatest(actionTypes.GET_BONO_HISTORIAL, getBonoHistorial),
    takeLatest(actionTypes.GET_ACTIVE_YEAR, getActiveYear)
  ]);
}

export default miBonoSagas;

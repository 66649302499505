export const filtersInitialState = {
  totalApplied: 0,
  performanceCategory: [],
  society: [],
  market: []
};

export const filtersReducer = (state, action) => {
  switch (action.field) {
  case 'reset':
    return action.value ? action.value : filtersInitialState;

  case 'totalApplied':
    return {
      ...state,
      totalApplied: action.value
    };
  default:
    return {
      ...state,
      [action.field]: action.value
    };
  }
};

export const checkboxInitialState = {
  performanceCategory: [],
  society: [],
  market: []
};

export const buildFilters = ({ calibrations }) => {
  calibrations.forEach((calibration) => {
    const { society, market } = calibration.evaluationId.evaluated;
    const performanceCategory = calibration.finalCategories.evaluation.name;
    const existsSociety = checkboxInitialState.society.includes(society);
    const existsMarket = checkboxInitialState.market.includes(market);
    const existsPerformanceCategory = checkboxInitialState.performanceCategory.includes(
      performanceCategory
    );

    if (!existsSociety) {
      checkboxInitialState.society.push(society);
    }
    if (!existsMarket) {
      checkboxInitialState.market.push(market);
    }
    if (!existsPerformanceCategory) {
      checkboxInitialState.performanceCategory.push(performanceCategory);
    }
  });
};

export const checkboxReducer = (state, action) => {
  switch (action.type) {
  case 'set':
    return {
      ...state,
      ...action.data
    };
  case 'reset':
    return {
      ...checkboxInitialState,
      ...action.data
    };
  default:
    return state;
  }
};

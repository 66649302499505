import React from 'react';
import { isEmpty, map } from 'lodash';
import PendingCircle from '../../../components/solicitudes/shared/PendingCircle';
import { COMMUNICATED, PENDING } from '../evaluations';
import { EVALUATION_TYPE_ENABLED } from '../../constants/myEvolution';

export const isUserEnabled = (evaluationType) => evaluationType === EVALUATION_TYPE_ENABLED;

export const getStatusEvaluation = (statusEvaluation) => (
  statusEvaluation !== COMMUNICATED ? PENDING : COMMUNICATED);

export const parsedPerformanceEvaluation = (performance, activePerformanceYear) => {
  if (!performance) return [];

  const { evaluation, autoEvaluation, year } = performance || {};

  if (evaluation && autoEvaluation) {
    const {
      opinion,
      status: statusEvaluation, _id
    } = evaluation || {};
    const status = getStatusEvaluation(statusEvaluation);
    const evaluationCommunicated = statusEvaluation === COMMUNICATED;
    const isActive = activePerformanceYear === year;
    const showButtonOpinion = isActive && evaluationCommunicated && isEmpty(opinion?.answers);

    return {
      onlyShowStatus: true,
      showAvatar: false,
      key: _id,
      title: 'Evaluación de Desempeño',
      year,
      evaluation: { ...evaluation, status },
      autoEvaluation,
      subTitle: year,
      status,
      isActive,
      showButtonOpinion,
      leftTitle: showButtonOpinion ? <PendingCircle /> : null
    };
  }
  return {};
};

export const parsePerformanceEvaluationHistorical = (historical) => {
  if (!historical) return [];

  const historicalParsed = [];

  map(historical, (item) => {
    const { evaluation, autoEvaluation, year } = item;
    if (evaluation && autoEvaluation) {
      const {
        _id,
        status
      } = evaluation || {};

      historicalParsed.push(
        {
          onlyShowStatus: true,
          showAvatar: false,
          key: _id,
          title: 'Evaluación de Desempeño',
          year,
          evaluation,
          autoEvaluation,
          subTitle: year,
          status,
          isActive: false
        }
      );
    }
  });

  return historicalParsed;
};

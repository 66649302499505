import React from 'react';
import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import getClassName from '../../../helpers/strings/getClassName';
import '../../../static/styles/shared/collapses/infoCollapse.less';

const { Panel } = Collapse;

export const MlArrow = () => (
  <i data-testid='ml-arrow' className="icon-ml-arrow" />
);

const MLAccordion = ({
  data, defaultActiveKey, bordered, onChange, className
}) => (
  <Collapse
    defaultActiveKey={defaultActiveKey}
    onChange={onChange}
    className={getClassName('info-collapse', className)}
    bordered={bordered}
  >
    {data.map(({
      key, title, extra, children, showArrow = false, forceRender = true
    }) => (
      <Panel
        header={<div className="info-collapse-title">{title}</div>}
        key={key}
        extra={extra || <MlArrow />}
        showArrow={showArrow}
        forceRender={forceRender}
      >
        {children}
      </Panel>
    ))}
  </Collapse>
);

MLAccordion.propTypes = {
  data: PropTypes.array,
  defaultActiveKey: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
  bordered: PropTypes.bool
};

MLAccordion.defaultProps = {
  data: [
    { key: '1', title: 'Sección 1', clildren: <p>Contenido 1</p> },
    { key: '2', title: 'Sección 2', clildren: <p>Contenido 2</p> },
    { key: '3', title: 'Sección 3', clildren: <p>Contenido 3</p> }
  ],
  defaultActiveKey: [],
  onChange: () => {},
  className: '',
  bordered: true
};

export default MLAccordion;

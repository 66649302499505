export const actionTypes = {
  GET_RANKING_RECO: 'GET_RANKING_RECO',
  GET_RANKING_RECO_SUCCESS: 'GET_RANKING_RECO_SUCCESS',
  GET_RANKING_RECO_ERROR: 'GET_RANKING_RECO_ERROR',
  RESET_RANKING_RECO: 'RESET_RANKING_RECO'
};

export function getRankingReco(variables) {
  return {
    type: actionTypes.GET_RANKING_RECO,
    variables: { mgmt: 'TODAS LAS GERENCIAS', ...variables }
  };
}

export function getRankingRecoSuccess(payload) {
  return {
    type: actionTypes.GET_RANKING_RECO_SUCCESS,
    payload
  };
}

export function getRankingRecoError(error) {
  return {
    type: actionTypes.GET_RANKING_RECO_ERROR,
    error
  };
}

export function resetRankingReco() {
  return {
    type: actionTypes.RESET_RANKING_RECO
  };
}

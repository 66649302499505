export const actionTypes = {
  GET_COMPANY_CHART: 'GET_COMPANY_CHART',
  GET_COMPANY_CHART_SUCCESS: 'GET_COMPANY_CHART_SUCCESS',
  GET_COMPANY_CHART_ERROR: 'GET_COMPANY_CHART_ERROR'
};

export function getCompanyChart() {
  return {
    type: actionTypes.GET_COMPANY_CHART
  };
}

export function getCompanyChartSuccess(payload) {
  return {
    type: actionTypes.GET_COMPANY_CHART_SUCCESS,
    payload
  };
}

export function getCompanyChartError(error) {
  return {
    type: actionTypes.GET_COMPANY_CHART_ERROR,
    error
  };
}

import { useSelector } from 'react-redux';

export const REPORT_NAMES = {
  PERFORMANCE_WALMART: 'performanceWalmart',
  PERFORMANCE_PROGRESS_WALMART: 'performanceWalmartProgress',
  FEEDBACK: 'feedback',
  PERFORMANCE: 'performance',
  FEEDBACK_NETWORKS: 'feedbackNetworks',
  COMPETENCIES: 'competencies',
  STATUS_FEEDBACK_NETWORK: 'status_feedback_network',
  EDD_EVALUATION: 'edd_evaluaciones',
  EDD_AUTOEVALUATION: 'edd_autoevaluaciones',
  EDD_OPINION: 'edd_opinion',
  EDP_EVALUATIONS: 'edp_evaluaciones',
  INTERESTS_AND_SKILLS: 'interestsAndSkills',
  PERFORMANCE_WALMART_TEMPLATE: 'performanceWalmartTemplate',
  SURVEY_PERFORMANCE: 'SURVEY_PERFORMANCE',
  IDP: 'IDP'
};

export const REPORT_STATUSES = {
  ON_CREATION: 'ON_CREATION',
  CREATED: 'CREATED',
  FAILED: 'FAILED'
};

export default (reportName) => {
  const report = useSelector((state) => state.adminReducer.reports[reportName]);

  return report;
};

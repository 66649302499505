import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import MLIntro from '../../shared/intro/MLIntro';
import Loading from '../../../containers/loading';
import { SET_SHOW_INTRODUCTION } from '../../../graphql/userSettings/mutations';
import { evolucionaTestIntroductionKey } from '../../../helpers/introductions';
import { MY_EVOLUTION_CONTEXT } from '../../../helpers/apollo';

const dataIntroduction = [
  {
    title: '¿Quieres saber qué tan cerca estás de Evolucionar?',
    child: <p>Testea tu Liderazgo Colaborativo y activa tu <span className='text-bold'>Plan de Evolución.</span></p>
  },
  {
    title: 'Te presentamos el EvolucionaTEST',
    child: <>
      <p className="text-justify"><span className='text-bold'>Tu autoconocimiento es clave para Evolucionar</span></p>
      <ul className="pl-0 pr-0 mt-2 text-justify">
        <p className="text-left"><span className='number-introduction'>1</span>Responde el <span className='text-bold'>TEST.</span></p>
        <p className="text-left"><span className='number-introduction'>2</span>EvolucionaTEST te sugerirá  <span className='text-bold'>acciones</span> clave para vivir dentro de un mes.</p>
        <p className="text-left"><span className='number-introduction'>3</span>Selecciona hasta <span className='text-bold'>4 acciones</span> que serán parte de tu <span className='text-bold'>Plan de Evolución.</span></p>
        <p className="text-left"><span className='number-introduction'>4</span>Luego de completarlas, actualiza tu <span className='text-bold'>TEST.</span></p>
      </ul>
    </>
  },
  {
    title: 'Claves de Uso',
    child: <span>
      <p>Te sugerimos <span className='text-bold'>Pedir Feedback</span> antes de responder y/o actualizar el TEST</p>
      <p>Según tu respuestas, <span className='text-bold'>EvolucionaTEST</span> te recomendará las conductas en las que enfocar tu <span className='text-bold'>Plan de Evolución.</span></p>
      <p>En caso que no completes una acción dentro de la fecha definida,
        esta quedará en estado No Finalizada y se guardará automáticamente en el historial.</p>
    </span>
  }
];

const Introduction = (props) => {
  const { endIntroduction, refetchQueries } = props;

  const [saveShowIntroduction, { loading }] = useMutation(
    SET_SHOW_INTRODUCTION,
    {
      variables: {
        introduction:
         {
           introductionName: evolucionaTestIntroductionKey,
           status: true
         }
      },
      context: MY_EVOLUTION_CONTEXT,
      onCompleted: () => {
        endIntroduction();
      }
    }
  );

  const finish = () => {
    saveShowIntroduction({
      refetchQueries
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <MLIntro data={dataIntroduction}
      labelFinish='Comenzar'
      finish={finish}
    />
  );
};

Introduction.propTypes = {
  endIntroduction: PropTypes.func,
  refetchQueries: PropTypes.array
};

Introduction.defaultProps = {
  endIntroduction: () => {},
  refetchQueries: []
};

export default Introduction;

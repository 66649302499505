import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { ConfigProvider, List } from 'antd';
import esES from 'antd/es/locale/es_ES';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Router from './router';
import createStore from './redux/store';
import apolloClient from './graphql/apollo';
import { initGA } from './helpers/googleAnalytics';
import { getEnvVar } from './helpers/commons';
import WebSocketProvider from './components/context/socket';

const store = createStore();

const App = () => {
  useEffect(() => {
    if (getEnvVar('REACT_APP_GA_TRACKING_CODE') && getEnvVar('REACT_APP_GA4_TRACKING_CODE')) initGA();
  }, []);

  return (
    <React.Fragment>
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <React.Fragment>
            <WebSocketProvider>
              <ConfigProvider locale={esES}>
                <DndProvider backend={HTML5Backend}>
                  <div className="App">
                    <Router />
                  </div>
                </DndProvider>
              </ConfigProvider>
            </WebSocketProvider>
          </React.Fragment>
        </Provider>
      </ApolloProvider>
    </React.Fragment>
  );
};

List.defaultProps = { split: false };

export default App;

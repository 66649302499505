import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';
import getCurrentYear from '../../helpers/myTeam/myEvolution/evaluations/getCurrentYear';

const BFF_ENDPOINT = getEnvVar('REACT_APP_API_REPORTS');

export const getCalibrationReportAPI = () => {
  const queryParams = `year=${getCurrentYear()}`;

  return axios().get(
    `${BFF_ENDPOINT}/calibration/registry?${queryParams}`,
    {
      timeout: 600000
    }
  ).then((resp) => resp.data);
};

import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import { find } from 'lodash';
import '../../../../../static/styles/shared/MLStep/styles.less';
import EvaluationStep from './EvaluationStep';
import {
  COMMUNICATED, EVALUATED, PENDING, REJECTED, VALIDATED
} from '../../../../../helpers/myEvolution/evaluations';

export const stepLabels = [
  { label: 'Evaluación', numberStatus: 1, status: [PENDING, REJECTED] },
  { label: 'Validación', numberStatus: 2, status: [EVALUATED] },
  { label: 'Comunicación', numberStatus: 3, status: [VALIDATED, COMMUNICATED] }
];

const EvaluationSteps = ({
  classes,
  status,
  isCompleted
}) => {
  const selectedStep = find(stepLabels, (item) => item.status.includes(status.toUpperCase()));
  return (
    <div className={`box-container-status ${classes}`}>

      <div className='ml-status-steps-container'>
        <div className='ml-status-steps'>
          <div className='separator'/>
          <div className='ml-status-labels d-flex justify-content-between align-items-center'>
            { stepLabels.map((data, i) => {
              const showIconCheck = (isCompleted && selectedStep.numberStatus === 3);
              return (
                <Row key={i} {...{ type: 'flex' }}>
                  <EvaluationStep {...{
                    i, selectedStep, status, data, showIconCheck, isCompleted
                  }}/>
                </Row>
              );
            })}
          </div>
        </div>

      </div>
    </div>
  );
};

EvaluationSteps.propTypes = {
  status: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool,
  classes: PropTypes.string
};

EvaluationSteps.defaultProps = {
  status: '',
  isCompleted: false,
  classes: ''
};
export default EvaluationSteps;

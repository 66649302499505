import React from 'react';
import PropTypes from 'prop-types';
import SearchUsersResults from './SearchUsersResults';
import useSearchUser from '../../../../hooks/shared/forms/SearchUser/useSearchUser';
import SearchBar from '../SearchBar';
import '../../../../static/styles/mievolucion/myPerformance/feedback/createFeedback/searchUsers.less';

const SearchUser = (props) => {
  const searchUser = useSearchUser(props);
  return (
    <div {...{ className: searchUser.containerClassName }}>
      <SearchBar {...searchUser.searchBarProps}/>

      { searchUser.showResultsUsers && (
        <SearchUsersResults {...searchUser.resultsProps} />
      )}
    </div>

  );
};

SearchUser.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  userAttribute: PropTypes.string,
  type: PropTypes.string
};

SearchUser.defaultProps = {
  className: '',
  disabled: false,
  label: '',
  onSelect: () => {},
  userAttribute: '',
  type: 'inverse-default'
};

export default SearchUser;

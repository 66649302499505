import { actionTypes } from '../actions/visible';

export const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_VISIBLE_SUCCESS:
    return {
      ...state,
      ...{ home: action.data }
    };

  default:
    return state;
  }
}

export default reducer;

/* eslint-disable no-return-assign */
import React, { forwardRef, useEffect } from 'react';
import {
  Carousel, Row, Col
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
import '../../../../static/styles/mievolucion/newSkills/model.less';
import { useWindowWidthSize } from '../../../../helpers/browser';
import { smallDevice } from '../../../../helpers/deviceVariables';
import ModalMessage from '../../../shared/ModalMessage';

const SkillSlider = forwardRef((props, ref) => {
  const {
    modalOptions, closeModal,
    data, takeFullSpace, carouselState, setCarouselState
  } = props;
  const width = useWindowWidthSize();

  const carouselSettings = {
    dots: false,
    autoplay: false,
    swipeToSlide: false,
    swipe: false,
    afterChange: (current) => {
      setCarouselState({ activeSlide: current });
    }
  };

  useEffect(() => {
    if (width <= smallDevice || carouselState.activeSlide === 2) {
      window.scrollTo(0, 0);
    }
  }, [carouselState.activeSlide]);

  const previous = () => {
    ref.current.prev();
  };
  const renderStep = () => data.map((item) => (
    <div key={uuidv4()} className="separator">
      {item.child}
    </div>
  ));
  const { title } = data[carouselState.activeSlide];

  return (
    <Row className="skills-slider container-test ">
      {modalOptions.visible
      && <ModalMessage
        {...modalOptions}
        closeModal={closeModal}
      />
      }
      {
        !takeFullSpace && <Col sm={0} md={0} lg={2} xl={4} />
      }
      <Col sm={24} md={16} lg={16} xl={12}>
        <div className=" upper-controls d-flex justify-content-between justify-content-sm-center">
          <button className={`button control-btn container-icon-ml-arrow  ${carouselState.activeSlide !== 0 ? 'visible' : 'invisible'}`} onClick={previous}>
            <i className="icon-ml-arrow prev" />
          </button>
          { width > smallDevice
            && <div className='container-title-slider'>
              <span>{title}</span>
              <p>{`${carouselState.activeSlide + 1} de ${data.length}`}</p>

            </div>
          }

          {width <= smallDevice
          && <span>{title}</span>
          }
          {width <= smallDevice
          && <span>{`${carouselState.activeSlide + 1} de ${data.length}`}</span>
          }
        </div>
        <Carousel {...carouselSettings} ref={ref}>
          {
            data
            && (renderStep())
          }
        </Carousel>
      </Col>
      {
        !takeFullSpace && <Col sm={0} md={0} lg={2} xl={4} />
      }
    </Row>
  );
});

export default SkillSlider;

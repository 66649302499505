import { lazy } from 'react';

const Agreements = lazy(() => import('../../containers/mio/convenios'));
const LifeHealthy = lazy(() => import('../../containers/mio/vivo-saludable'));
const MioLayout = lazy(() => import('../../layouts/mio'));
const Mio = lazy(() => import('../../containers/mio'));
const MioFaq = lazy(() => import('../../containers/mio/faq'));
const MioProfile = lazy(() => import('../../containers/mio/perfil'));
const MioBenefit = lazy(() => import('../../containers/mio/benefit'));
const SocialAssistant = lazy(() => import('../../containers/mio/asistente-social'));

export default [
  {
    path: '/mio',
    component: MioLayout,
    subRoutes: [
      {
        exact: true, path: '/mio', section: 'Mio', component: Mio
      },
      {
        exact: true,
        path: '/mio/faq',
        section: 'Mio -> Faq',
        component: MioFaq
      },
      {
        exact: true,
        path: '/mio/perfil/:slug',
        section: 'Mio -> Perfil',
        component: MioProfile
      },
      {
        exact: true,
        path: '/mio/benefit/:id',
        section: 'Mio -> Beneficios',
        component: MioBenefit
      },
      {
        exact: true,
        path: '/mio/vivo-saludable/:name',
        section: 'Mio -> Vivo Saludable',
        component: LifeHealthy
      },
      {
        exact: true,
        path: '/mio/vivo-saludable/:name/:slug',
        section: 'Mio -> Vivo Saludable',
        component: LifeHealthy
      },
      {
        exact: true,
        path: '/mio/convenios/:slug',
        section: 'Mio -> Convenios',
        component: Agreements
      },
      {
        exact: true,
        path: '/mio/asistente-social/:slug',
        section: 'Mio -> Asistente Social',
        component: SocialAssistant
      }
    ]
  }
];

import React from 'react';
import MLModalWithFixedLayout from '../../shared/MLModal/WithFixedLayout';
import LoginHelpOptions from './LoginHelpOptions';
import '../../../static/styles/login/login-help/login-help.less';

const LoginHelp = () => (
  <div className='login-help'>
    <span>¿Tienes problemas para ingresar?</span>
    {' '}

    <MLModalWithFixedLayout {...{
      fullScreen: true,
      title: 'Ayuda',
      OpenButton: (props) => (
        <span {...props}>Ver ayuda</span>
      ),
      footerComponents: null
    }}>
      <LoginHelpOptions/>
    </MLModalWithFixedLayout>
  </div>
);

export default LoginHelp;

import React from 'react';
import { useDispatch } from 'react-redux';
import AdminFileListReportsSockets from '../../../components/shared/list/AdminFileListReportsSockets';
import { NoConnectionMessage } from '../../../components/AdminReports/Reports';

import {
  getInterestAndSkillsReport
} from '../../../redux/actions/admin';
import useReport, { REPORT_NAMES } from '../../../hooks/reports/useReport';
import useSocket from '../../../hooks/sockets/useSocket';

const Reports = () => {
  const { socket, connected } = useSocket();
  const dispatch = useDispatch();

  const interestAndSkillsReport = useReport(REPORT_NAMES.INTERESTS_AND_SKILLS);

  const adminFileListProps = {
    title: 'Reporte de Intereses y Habilidades',
    report: interestAndSkillsReport,
    startFetch: () => dispatch(getInterestAndSkillsReport(socket.id))
  };

  return connected
    ? <AdminFileListReportsSockets
      {...adminFileListProps}
    />
    : <NoConnectionMessage />;
};

export default Reports;

/* eslint-disable react/jsx-key */

import React, { useState, useEffect } from 'react';
import {
  useLocation, useHistory
} from 'react-router-dom';
import { useQuery } from '@apollo/client';
import '../../../../static/styles/shared/MLHistorical/historical.less';
import {
  map, find, isEmpty, capitalize, groupBy, forOwn
} from 'lodash';
import HistoricalList from '../../../shared/MLHistorical/list/index';
import Historical from '../../../shared/MLHistorical';
import getURLParams from '../../../../helpers/routing/getURLParams';
import Error from '../../../shared/Error';
import CategoryButtons from '../../../shared/MLHistorical/categoryButtons';
import { statusClasses } from '../../../../helpers/requests';
import { formatDateCard } from '../../../../helpers/dates';
import NotFound from '../../../shared/MLNotFound';
import { GET_EVALUATION_PLAN_HISTORICAL } from '../../../../graphql/mievolucion/evolucionaTEST/queries';
import { GET_ME } from '../../../../graphql/user/queries';

const StatusActions = ({ actions }) => (
  <div className='d-flex flex-row icon-actions-historial'>
    {
      actions.map((action, indexAction) => (
        <i key={indexAction} className={statusClasses(capitalize(action.status))} />
      ))
    }
  </div>
);
const tabs = [
  {
    key: 1,
    title: 'En Proceso',
    childrenURL: '/mi-evolucion/evolucionaTEST/plan-de-evolucion/en-proceso'
  },
  {
    key: 2,
    title: 'Cerrado',
    childrenURL: '/mi-evolucion/evolucionaTEST/plan-de-evolucion/cerrado'
  }
];

const EvolutionPlan = (props) => {
  const { children, me: { user: { rut } } } = props;

  const { pathname } = useLocation();
  const [planSelected, setPlanSelected] = useState(null);
  const defaultTab = find(tabs, ({ childrenURL }) => childrenURL === pathname) || tabs[0];
  const [listTab, selectList] = useState(defaultTab || tabs[0]);
  const history = useHistory();
  const query = getURLParams();

  const id = query.get('id');

  const { props: { routes } } = children;
  const [defaultComponent] = routes;

  const {
    loading, data, error, refetch
  } = useQuery(
    GET_EVALUATION_PLAN_HISTORICAL, {
      context: {
        clientName: 'myEvolution'
      },
      variables:
      {
        dni: rut,
        status:
          listTab.key === 1 ? ['PENDING'] : ['CLOSED', 'FINISHED']
      }
    }
  );
  const { listSkillLevelBehaviorActionPlan } = data || {};

  const {
    loading: loadingME,
    data: dataMe
  } = useQuery(
    GET_ME, {
      context: {
        clientName: 'myEvolution'
      }
    }
  );
  const { me } = dataMe || {};
  const { profileUCM } = me || {};

  const getBehaviors = (item) => {
    const behaviors = [];
    const filterBehaviors = groupBy(item.actions, (action) => (
      JSON.stringify(
        {
          _id: action.behaviorId,
          name: action.behaviorName,
          choice: action.choice
        }
      )));

    forOwn(filterBehaviors, (value, key) => {
      const { _id, name, choice } = JSON.parse(key);

      behaviors.push({
        _id, name, choice, actions: value
      });
    });
    return behaviors;
  };
  const parsedData = (() => {
    const res = [];

    if (!listSkillLevelBehaviorActionPlan) return res;

    map(listSkillLevelBehaviorActionPlan, (item) => {
      res.push({
        ...item,
        _id: item._id,
        title: item.skill.name,
        subTitle: `${item.actions.length} acciones`,
        showAvatar: false,
        date: formatDateCard(item.createdAt),
        status: capitalize(item.status),
        behaviors: getBehaviors(item),
        customStatus: (<StatusActions actions={item.actions} />)
      });
    });

    return res;
  })();

  const noFoundProps = (() => {
    const img = 'web/mi-evolucion/servicios/inicio/evolucionatest-emptystate.png';
    switch (listTab.key) {
    case 1:
      return {
        img,
        title: 'No tiene un Plan de Evolución en proceso',
        description: 'Responde el TEST para definir las acciones y construir tu Plan.',
        button: {
          text: 'Responder TEST',
          route: '/mi-evolucion/evolucionaTEST/TEST'
        }
      };
    default:
      return {
        img,
        title: 'No has realizado un Plan de Evolución',
        description: '¡Anímate y construye tu camino!'
      };
    }
  })();

  const onListChange = (listKey, childrenURL) => {
    selectList(tabs[listKey - 1]);

    const variables = {
      dni: rut,
      status:
      listKey === 1 ? ['PENDING'] : ['CLOSED', 'FINISHED']
    };
    refetch(variables);
    history.replace(childrenURL);
  };
  const findLevel = () => (profileUCM ? profileUCM.name : null);

  const onSelectItem = () => {
    if (!id) {
      setPlanSelected(null);
    } else {
      const newItem = find(parsedData, (plan) => plan._id === id);
      if (newItem) {
        setPlanSelected(newItem);
      }
    }
  };

  useEffect(() => {
    if (!loading && id) {
      onSelectItem(id);
    } else {
      onSelectItem(null);
    }
  }, [id, loading]);

  const leftScrollComponents = [
    <CategoryButtons
      className='mt-0'
      key={0} {...{ tabs, listTab: listTab.key, onListChange }} />,
    // eslint-disable-next-line no-nested-ternary
    error
      ? <Error key={1} />
      : !loading && isEmpty(parsedData) ? (
        <NotFound key={1}{...noFoundProps} />
      ) : (
        <HistoricalList
          key={2}
          loading={loading || loadingME}
          data={parsedData}
          getActiveItem={(listItem) => planSelected && listItem._id === planSelected._id}
          getItemURL={(listItem) => `${listTab.childrenURL}?id=${listItem._id}`}
        />
      )
  ];

  return (
    <div
      className='container-historial-evaluation-plan'
    >
      <Historical
        loading={loadingME}
        leftScrollComponents={leftScrollComponents}
        containerClasses='container-detail-colaborator'
      >
        {
          id && planSelected && planSelected._id === id
              && <defaultComponent.component
                level={findLevel()}
                getBehaviors={getBehaviors}
                rut={rut}
                data={planSelected} />
        }
      </Historical>
    </div>
  );
};

export default EvolutionPlan;

import React from 'react';
import { map, reverse } from 'lodash';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import InputSkill from './InputSkill';

const { TextArea } = Input;

const SkillsSelected = (props) => {
  const {
    skillsSelected, feedbackType, selectSkills, setSkillsSelected
  } = props;

  const skills = [];
  skillsSelected.forEach((skill) => {
    skills.push(skill);
  });

  return (
    <div className="row ">
      <div className="d-flex align-content-around flex-wrap container-skills-selected">
        {map(reverse(skills), (skill, i) => (
          <div key={i} className="col-12 col-sm-6 col-md-6">
            <div className="container-description-skill">
              <div className="d-flex justify-content-between align-items-start">
                <p className="title">
                  {skill.name}
                </p>
                <div
                  onClick={ (e) => {
                    e.preventDefault();
                    selectSkills(skill);
                  }}
                  className="circle-delete rounded-circle">
                  <i
                    className='icon-me-competency-remove'
                  />
                </div>
              </div>
              {
                skill.behaviors && map(skill.behaviors, (item, j) => (
                  <div key={j}>
                    <p className='label-title '>{item.title}</p>
                    <p className="description">{item.description}</p>
                  </div>
                ))
              }
              { skill.name === 'Otras'
              && <div>
                <p className='label-title'>Habilidad, conducta y/o comportamiento</p>
                <TextArea
                  className='input-other-skill'
                  placeholder={'Ejemplo: Compromiso'}
                  maxLength={200}
                  onChange={ (e) => {
                    const mySkillSelected = new Map();
                    skillsSelected.forEach((item) => {
                      mySkillSelected.set(item._id,
                        {
                          ...item,
                          otherSkillName: skill._id === item._id ? e.target.value
                            : item.otherSkillName
                        });
                    });
                    setSkillsSelected(
                      mySkillSelected
                    );
                  }}
                />
              </div>
              }

              {feedbackType === 'Given' ? (
                <div>
                  <p className='label-title'>Feedback</p>
                  <InputSkill
                    content={skill.content}
                    changeContent={(value) => {
                      const mySkillSelected = new Map();
                      skillsSelected.forEach((item) => {
                        mySkillSelected.set(item._id,
                          {
                            ...item,
                            content: skill._id === item._id ? value : item.content
                          });
                      });
                      setSkillsSelected(
                        mySkillSelected
                      );
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

SkillsSelected.propTypes = {
  feedbackType: PropTypes.oneOf(['Given', 'Requested']),
  listSkills: PropTypes.array,
  skillsSelected: PropTypes.object,
  setSkillsSelected: PropTypes.func,
  selectSkills: PropTypes.func
};

SkillsSelected.defaultProps = {
  feedbackType: 'Given',
  listSkills: [],
  skillsSelected: {},
  setSkillsSelected: () => {},
  selectSkills: () => {}
};
export default SkillsSelected;

export const actionTypes = {
  FAILURE: 'FAILURE',
  GET_VISIBLE: 'GET_VISIBLE',
  GET_VISIBLE_SUCCESS: 'GET_VISIBLE_SUCCESS'
};

export function failure(error) {
  return {
    type: actionTypes.FAILURE,
    error
  };
}

export function getVisible(screen) {
  return {
    type: actionTypes.GET_VISIBLE,
    screen
  };
}

export function getVisibleSuccess(data) {
  return {
    type: actionTypes.GET_VISIBLE_SUCCESS,
    data
  };
}

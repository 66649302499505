import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { completePath } from '../../helpers/strings';
import { getEnvVar } from '../../helpers/commons';
import * as storageUtil from '../../helpers/cookiesUtil';
import InfoMessage from '../shared/InfoMessage';
import Version from '../Version';

const PINGFED_AUTH_URL = getEnvVar('REACT_APP_PINGFED_AUTH_URL');
const PINGFED_AUTH_REDIRECT_URI = getEnvVar(
  'REACT_APP_PINGFED_AUTH_REDIRECT_URI'
);
const codeChallenge = getEnvVar('REACT_APP_PINGFED_CODE_CHALLENGE');
const codeChallengeMethod = getEnvVar(
  'REACT_APP_PINGFED_CODE_CHALLENGE_METHOD'
);

const LoginForm = () => {
  const { commonsReducer = {} } = useSelector((state) => ({
    commonsReducer: state.commonsReducer
  }));

  const onSubmit = (e) => {
    e.preventDefault();
    storageUtil.clearAll();
    const scope = 'openid%20full';
    const redirectUrl = `${PINGFED_AUTH_URL}/as/authorization.oauth2?client_id=cl-milugar&redirect_uri=${PINGFED_AUTH_REDIRECT_URI}&response_type=code&scope=${scope}&code_challenge=${codeChallenge}&code_challenge_method=${codeChallengeMethod}`;
    window.location.replace(redirectUrl);
  };

  return (
    <form className='container-form' onSubmit={onSubmit}>
      <div className='text-center'>
        <img
          className='img-fluid'
          src={completePath('web/milugar/logos/logo-mi-lugar.svg')}
          alt=''
        />
      </div>

      <InfoMessage
        {...{
          suave: true,
          message:
            'La manera de ingresar a Mi Lugar ha cambiado. Desde ahora tendrás que ingresar con tu usuario Walmart. Si no lo conoces consúltalo con tu jefatura.'
        }}
      />

      <Button loading={commonsReducer.loading} type='primary' htmlType='submit'>
        Iniciar sesión
      </Button>
      <Version />
    </form>
  );
};

export default LoginForm;

import React from 'react';
import { Button } from 'antd';
import MLDot from '../Dot';
import '../../../static/styles/shared/MLStep/slider.less';

const MLStepSlider = ({
  active,
  minReached,
  maxReached,
  handleRightSlide,
  handleLeftSlide,
  handleByIndex,
  preparingLeft,
  preparingRight,
  length,
  arrows = false,
  dots = false
}) => {
  const disabled = preparingLeft || preparingRight;

  const getClassName = () => {
    const classNameResult = 'ml-step-slider';

    if (arrows) return `${classNameResult} ml-step-slider-arrows`;
    if (dots) return `${classNameResult} ml-step-slider-dots`;

    return classNameResult;
  };

  const getDots = () => {
    const dotsButtons = [];

    for (let i = 0; i < length; i += 1) {
      dotsButtons.push(
        <Button key={i} type='link' onClick={() => handleByIndex(i)}>
          <MLDot active={active === i}/>
        </Button>
      );
    }

    return dotsButtons;
  };

  return (
    <div className={getClassName()}>
      {arrows && (
        <>
          <Button type='link' onClick={() => handleLeftSlide()} disabled={disabled || minReached}>{'<'}</Button>
          <Button type='link' onClick={() => handleRightSlide()} disabled={disabled || maxReached}>{'>'}</Button>
        </>
      )}

      {dots && (getDots())}
    </div>
  );
};

export default MLStepSlider;

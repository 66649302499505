export const actionTypes = {
  GET_NEWS: 'GET_NEWS',
  GET_NEWS_SUCCESS: 'GET_NEWS_SUCCESS',
  GET_NEWS_ERROR: 'GET_NEWS_ERROR',
  GET_FREQUENT_APPS: 'GET_FREQUENT_APPS',
  GET_FREQUENT_APPS_SUCCESS: 'GET_FREQUENT_APPS_SUCCESS',
  GET_FREQUENT_APPS_ERROR: 'GET_FREQUENT_APPS_ERROR',
  GET_CAMPAIGN_BY_USER: 'GET_CAMPAIGN_BY_USER',
  GET_CAMPAIGN_BY_USER_SUCCESS: 'GET_CAMPAIGN_BY_USER_SUCCESS',
  GET_CAMPAIGN_BY_USER_ERROR: 'GET_CAMPAIGN_BY_USER_ERROR',
  UPDATE_CAMPAIGN_BY_USER: 'UPDATE_CAMPAIGN_BY_USER',
  UPDATE_CAMPAIGN_BY_USER_SUCCESS: 'UPDATE_CAMPAIGN_BY_USER_SUCCESS',
  UPDATE_CAMPAIGN_BY_USER_ERROR: 'UPDATE_CAMPAIGN_BY_USER_ERROR',
  GET_FLAG_INFORMATIVE_BY_USER: 'GET_FLAG_INFORMATIVE_BY_USER',
  GET_FLAG_INFORMATIVE_BY_USER_SUCCESS: 'GET_FLAG_INFORMATIVE_BY_USER_SUCCESS',
  GET_FLAG_INFORMATIVE_BY_USER_ERROR: 'GET_FLAG_INFORMATIVE_BY_USER_ERROR',
  SET_VERSION: 'SET_VERSION'
};

export function getFlagInformativeByUser() {
  return {
    type: actionTypes.GET_FLAG_INFORMATIVE_BY_USER
  };
}
export function getFlagInformativeByUserSuccess(data) {
  return {
    type: actionTypes.GET_FLAG_INFORMATIVE_BY_USER_SUCCESS,
    data
  };
}
export function getFlagInformativeByUserError(error) {
  return {
    type: actionTypes.GET_FLAG_INFORMATIVE_BY_USER_ERROR,
    error
  };
}

export function updateCampaignByUser(data) {
  return {
    type: actionTypes.UPDATE_CAMPAIGN_BY_USER,
    payload: data
  };
}

export function updateCampaignByUserError(error) {
  return {
    type: actionTypes.UPDATE_CAMPAIGN_BY_USER_ERROR,
    error
  };
}

export function getCampaignByUser() {
  return {
    type: actionTypes.GET_CAMPAIGN_BY_USER
  };
}
export function getCampaignByUserSuccess(data) {
  return {
    type: actionTypes.GET_CAMPAIGN_BY_USER_SUCCESS,
    data
  };
}
export function getCampaignByUserError(error) {
  return {
    type: actionTypes.GET_CAMPAIGN_BY_USER_ERROR,
    error
  };
}
export function getNews() {
  return {
    type: actionTypes.GET_NEWS
  };
}
export function getNewsSuccess(data) {
  return {
    type: actionTypes.GET_NEWS_SUCCESS,
    data
  };
}

export function getNewsError(error) {
  return {
    type: actionTypes.GET_NEWS_ERROR,
    error
  };
}
export function getFrequentApps(month) {
  return {
    type: actionTypes.GET_FREQUENT_APPS,
    month
  };
}
export function getFrequentAppsSuccess(data) {
  return {
    type: actionTypes.GET_FREQUENT_APPS_SUCCESS,
    data
  };
}

export function getFrequentAppsError(error) {
  return {
    type: actionTypes.GET_FREQUENT_APPS_ERROR,
    error
  };
}

export function setVersion(data) {
  return {
    type: actionTypes.SET_VERSION,
    data
  };
}

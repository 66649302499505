import { map, find } from 'lodash';

const authorizatePermissionsByCredential = (
  permissions,
  credentialPermissions,
  showCredential
) => {
  const results = { ...permissions };

  map(credentialPermissions.children, (_, permission) => {
    if (showCredential) {
      results[permission] = true;
    } else {
      delete results[permission];
    }
  });

  map(credentialPermissions.parents, (parentPermission) => {
    const parentHasPermissions = !!find(results, (_, permission) => {
      const parentPrefix = `${parentPermission}_`;
      const isChildrenPermission = permission.slice(0, parentPrefix.length) === parentPrefix;

      return isChildrenPermission && results[permission];
    });

    if (!parentHasPermissions) delete results[parentPermission];
  });

  return results;
};

export default authorizatePermissionsByCredential;

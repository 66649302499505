import { actionTypes } from '../actions/benefits';

export const initialState = {
  data: null,
  error: null,
  loading: false,
  loadingStatus: false,
  loadingRequest: false,
  successRequest: false,
  successRequestFile: null
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.CLEAN_BENEFIT_REQUEST:
    return {
      ...state,
      error: null,
      loading: false,
      loadingRequest: false,
      successRequest: false
    };
  case actionTypes.GET_GIFT_DAY_WEEKS_SUCCESS:
    return {
      ...state,
      giftDayWeeks: action.data
    };

  case actionTypes.GET_MAIN_BENEFITS_SUCCESS:
    return {
      ...state,
      data: action.data
    };

  case actionTypes.GET_CATEGORIES_SUCCESS:
    return {
      ...state,
      categories: action.data
    };

  case actionTypes.GET_BENEFIT_STATUS:
    return {
      ...state,
      error: null,
      loading: true,
      loadingStatus: true
    };

  case actionTypes.GET_BENEFIT_STATUS_SUCCESS:
    return {
      ...state,
      status: action.data,
      loading: false,
      loadingStatus: false
    };

  case actionTypes.GET_BENEFIT_STATUS_ERROR:
    return {
      ...state,
      error: action.data,
      loading: false,
      loadingStatus: false
    };

  case actionTypes.GET_BENEFIT_DETAILS_SUCCESS:
    return {
      ...state,
      details: action.data
    };

  case actionTypes.POST_REQUEST_FILES:
    return {
      ...state,
      error: null,
      loadingRequest: true,
      successRequestFile: null,
      loading: true
    };
  case actionTypes.POST_REQUEST_FILE_STATUS_SUCCESS:
    return {
      ...state,
      status: action.data,
      loadingRequest: false,
      loading: false,
      successRequestFile: action.data
    };
  case actionTypes.POST_REQUEST_FILE_STATUS_ERROR:
    return {
      ...state,
      error: action.error,
      loadingRequest: false,
      loading: false
    };
  case actionTypes.POST_BENEFIT_REQUEST:
    return {
      ...state,
      error: null,
      loadingRequest: true,
      successRequest: false,
      loading: true
    };

  case actionTypes.POST_BENEFIT_REQUEST_SUCCESS:
    return {
      ...state,
      status: action.data,
      requestStatus: 'Ha cambiado exitosamente el estado de la solicitud',
      loadingRequest: false,
      loading: false,
      successRequest: true
    };

  case actionTypes.POST_BENEFIT_REQUEST_ERROR:
    return {
      ...state,
      error: action.data || 'Ha ocurrido un error inesperado. Por favor, vuelva a intentarlo más tarde.',
      loadingRequest: false,
      loading: false,
      successRequest: false
    };

  case actionTypes.GET_HOME_CATEGORIES_SUCCESS:
    return {
      ...state,
      homeSections: action.data
    };

  case actionTypes.GET_TEAM_BENEFITS:
    return {
      ...state,
      loading: true,
      teamRequestsError: false,
      teamRequestsLoading: true
    };

  case actionTypes.GET_TEAM_BENEFITS_SUCCESS:
    return {
      ...state,
      teamRequests: action.data,
      loading: false,
      teamRequestsError: false,
      teamRequestsLoading: false
    };

  case actionTypes.GET_TEAM_BENEFITS_ERROR:
    return {
      ...state,
      error: action.error,
      loading: true,
      teamRequestsError: action.error,
      teamRequestsLoading: false
    };

  case actionTypes.POST_REQUEST_STATUS:
    return {
      ...state,
      pushing: true,
      requestStatus: action.data
    };

  case actionTypes.POST_REQUEST_STATUS_SUCCESS:
    return {
      ...state,
      pushing: false,
      requestStatus: action.data
    };

  case actionTypes.POST_REQUEST_STATUS_ERROR:
    return {
      ...state,
      pushing: false,
      error: action.error
    };

  case actionTypes.DISMISS_MESSAGE:
    return {
      ...state,
      requestStatus: null
    };

  case actionTypes.GET_FLICKR_ALBUM_SUCCESS:
    return {
      ...state,
      flickrAlbum: action.data
    };

  case actionTypes.GET_MY_BENEFITS:
    return {
      ...state,
      loading: true,
      myRequestsLoading: true,
      myRequestsError: false
    };

  case actionTypes.GET_MY_BENEFITS_SUCCESS:
    return {
      ...state,
      myRequests: action.data,
      loading: false,
      myRequestsLoading: false,
      myRequestsError: false
    };

  case actionTypes.GET_MY_BENEFITS_ERROR:
    return {
      ...state,
      error: action.error,
      loading: false,
      myRequestsLoading: false,
      myRequestsError: true
    };

  case actionTypes.SET_CATEGORY_SUCCESS:
    return {
      ...state
    };

  case actionTypes.GET_MONTHLY_STATUS_SUCCESS:
    return {
      ...state,
      monthlyStatus: action.data
    };
  case actionTypes.GET_SCHOOL_WINNERS:
    return {
      ...state,
      loading: true
    };
  case actionTypes.GET_SCHOOL_WINNERS_SUCCESS:
    return {
      ...state,
      schoolWinners: action.data,
      loading: false
    };
  case actionTypes.POST_BENEFIT_DISCONNECTION:
    return {
      ...state,
      loading: true,
      error: null,
      disconnectionRequestResponse: null
    };
  case actionTypes.POST_BENEFIT_DISCONNECTION_SUCCESS:
    return {
      ...state,
      loading: false,
      disconnectionRequestResponse: action.data,
      showDisconnectionModal: true
    };
  case actionTypes.POST_BENEFIT_DISCONNECTION_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error,
      showDisconnectionModal: true
    };
  case actionTypes.BENEFIT_DISCONNECTION_MODAL_DISMISS:
    return {
      ...state,
      showDisconnectionModal: false
    };
  default:
    return state;
  }
}

export default reducer;

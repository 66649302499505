import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import Avatar from '../components/shared/Avatar';
import { capitalizeText } from '../helpers/commons';
import { useWindowWidthSize } from '../helpers/browser';
import { smallDevice } from '../helpers/deviceVariables';
import '../static/styles/banner/style.less';

const Banner = (props) => {
  const {
    children,
    loading,
    imageBanner,
    imageAvatar,
    title,
    subtitle,
    className,
    childrenClassName,
    titleAndSubTitleFormat,
    hideSmallDevice,
    showBanner,
    showMobileInfo,
    historical
  } = props;

  const width = useWindowWidthSize();

  const style = {
    backgroundImage: `url(${imageBanner})`
  };

  const getClassName = () => {
    let res = 'banner-layout';

    if (className) res += ` ${className}`;
    if (hideSmallDevice) res += ' hide-small-device';
    if (historical) res += ' historical-banner-layout';

    return res;
  };

  const getChildrenClassName = () => {
    let res = 'banner-layout-children';

    if (childrenClassName) res += ` ${childrenClassName}`;
    if (historical) res += ' historical-banner-layout-children';

    return res;
  };

  const getPrettier = (value) => {
    switch (value) {
    case 'mi-evolucion':
      return 'Mi Evolución';
    case 'certificado-de-relacion-laboral':
      return 'Certificado de Relación Laboral';
    default:
      return value;
    }
  };

  const selectFormat = (text) => {
    switch (titleAndSubTitleFormat) {
    case 'prettier':
      return getPrettier(text);
    case 'upper-case-keep-format':
      return text.toUpperCase();
    case 'keep-format':
      return text;
    default:
      // eslint-disable-next-line no-useless-escape
      return capitalizeText(text.replace(/\-/g, ' '));
    }
  };
  const showCardInfo = () => {
    if (width <= smallDevice && showMobileInfo) {
      return true;
    }
    if (width > smallDevice && (imageAvatar || title || subtitle)) return true;
    return false;
  };

  return (
    <div className={getClassName()}>
      { showBanner
      && <Card className='banner-layout-card' loading={loading}>
        <div className='image-banner' style={style} />
        {
          showCardInfo()
          && (
            <div className='container-info'>
              <div className='info align-items-center'>
                <Avatar size={56} src={imageAvatar} />
                <div>
                  { title
                  && (
                    <p className='title'>
                      {selectFormat(title)}
                    </p>
                  )
                  }
                  { subtitle
                  && (
                    <p className='subtitle'>
                      {selectFormat(subtitle)}
                    </p>
                  )
                  }
                </div>
              </div>
            </div>
          )
        }
      </Card>
      }
      <div className={getChildrenClassName()}>
        {children}
      </div>
    </div>
  );
};

Banner.propTypes = {
  loading: PropTypes.bool,
  imageBanner: PropTypes.string,
  imageAvatar: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  childrenClassName: PropTypes.string,
  titleAndSubTitleFormat: PropTypes.string,
  hideSmallDevice: PropTypes.bool,
  showBanner: PropTypes.bool,
  showMobileInfo: PropTypes.bool,
  historical: PropTypes.bool
};

Banner.defaultProps = {
  loading: false,
  imageBanner: '',
  imageAvatar: '',
  title: '',
  subtitle: '',
  className: '',
  childrenClassName: '',
  titleAndSubTitleFormat: '',
  hideSmallDevice: false,
  showBanner: true,
  showMobileInfo: false,
  historical: false
};

export default Banner;

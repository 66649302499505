/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const OBJECTIVE_FIELDS = gql`
  fragment ObjectiveFields on Performance {
    objectives {
      _id
      weight
      kpiObjective
      tribu
      objective {
        _id
        year
        description
        name
        pillar {
          _id
          name
          year
          description
        }
      }
      initiatives {
        _id
        description
        kpi
        dateLimit
        updates {
          q1 {
            comment
            initiativeStatus
          }
          q2 {
            comment
            initiativeStatus
          }
          q3 {
            comment
            initiativeStatus
          }
          q4 {
            comment
            initiativeStatus
          }
        }
      }
    }
  }
`;

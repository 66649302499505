const getModalSuccess = (
  setModalCreatedSuccess,
  modalCreatedSuccess,
  type
) => (setModalCreatedSuccess({
  ...modalCreatedSuccess,
  visible: true,
  title: `El ${type} se ha enviado con éxito`,
  icon: 'icon-me-check',
  buttons: [
    {
      text: 'Cerrar',
      type: 'primary',
      onClickButton: () => {
        setModalCreatedSuccess({
          ...modalCreatedSuccess,
          buttons: [],
          icon: 'icon-me-check',
          visible: false
        });
      }
    }
  ]
}));

export default getModalSuccess;

import React from 'react';
import { Col, Row } from 'antd';
import { isMobile } from 'react-device-detect';
import getPillarType from '../../../../../../../helpers/myEvolution/myPerformance/getPillarType';
import {
  pillarType2022 as pillarType, pillarName, TRANSVERSAL_OBJECTIVE_KEY, SPECIFIC_OBJECTIVE_KEY
} from '../../../../../../../helpers/myEvolution/myPerformance';
import '../../../../../../../static/styles/mievolucion/objectives2022/myPerformanceTwoGroupsTable.less';
import ListOfSuccessMeasures from '../../../../../MyPerformance/Performance/TableComponent/ListOfSuccessMeasures';

const ObjectiveModal = (props) => {
  const {
    objectiveInfo
  } = props;

  const {
    macro,
    pillar,
    objective,
    strategicPriority,
    weight,
    successMeasures
  } = objectiveInfo;

  const getSuccessMeasureFields = () => successMeasures?.map(
    ({ description, dateMeasure, updates }) => ({ description, dateMeasure, updates })
  );

  const ObjectiveInfo = () => {
    try {
      const pillarTypeInfo = getPillarType({
        macro, pillar
      });
      const dataLength = successMeasures?.length;
      const isSuccessMeasures = dataLength > 0;
      const isMacro = pillarType === pillarTypeInfo.PECM;
      const isNotSpecificObjective = pillarType !== pillarTypeInfo[SPECIFIC_OBJECTIVE_KEY];

      return (<div key={'expanded-row'} className='expanded-row'>
        <Col>
          <p className='objective-body'>{objective?.name})</p>
        </Col>
        <Col span={isMobile ? 24 : 15} className='objective-col left'>
          <p className='objective-title'>Porcentaje:</p>
          <p className='objective-body'>{weight} %</p>
        </Col>
        {isNotSpecificObjective && <><Row gutter={[32, 0]}>
          <Col span={isMobile ? 24 : 15} className='objective-col left'>
            <p className='objective-title'>Descripción del objetivo:</p>
            <p className='objective-body'>{objective?.description || 'Sin descripción'}</p>
          </Col>
          {isMacro && <Col span={isMobile ? 24 : 9} className='objective-col right'>
            <p className='objective-title'>Objetivo Macro:</p>
            <p className='objective-body'>{ macro?.name || 'Sin objetivos'}</p>
          </Col>}
        </Row>
        <div className='expanded-separator'/></>}
        {pillar?.name?.toLowerCase()
        === pillarName[TRANSVERSAL_OBJECTIVE_KEY]?.toLowerCase() && <Row>
            <Col span={isMobile ? 24 : 15} className='objective-col left'>
              <p className='objective-title'>Prioridad estratégica:</p>
              <p className='objective-body'>{strategicPriority?.name || 'Sin prioridad estratégica'}</p>
            </Col>
          </Row>}
        {(isNotSpecificObjective && !isMobile) && <div className='expanded-separator'/>}

        {isSuccessMeasures && (
          <ListOfSuccessMeasures
            showAdvanceModal={false}
            dataSuccessMeasures={getSuccessMeasureFields()}
            isMobile={isMobile}
            currentQuarter={'q4'}
          />
        )}

      </div>);
    } catch (error) {
      return null;
    }
  };

  return (
    <div className='ml-my-evolution-my-performance-objectives-table' >
      <ObjectiveInfo />
    </div>
  );
};

export default ObjectiveModal;

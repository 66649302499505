import React from 'react';
import moment from 'moment';
import columnRender from './columnRender';

const renderAnchor = ({ generateLetter, variables }) => (
  <a
    href='#'
    onClick={(e) => { e.preventDefault(); generateLetter({ variables }); }}
    type='primary' target='_blank' rel='noopener noreferrer'>Ver Carta</a>
);

export const getCommunicationTableSrcData = (userLetterList, letterGeneration) => {
  const respTable = userLetterList.map((item) => ({
    type: item.letter.title,
    increment: `${item.data.incrementPercentage}%`,
    file: item.file,
    view: 'Ver carta',
    key: item._id
  }));
  return {
    columns: [
      {
        className: 'table-td',
        title: 'Tipo de carta',
        dataIndex: 'type',
        render: columnRender
      },
      {
        className: 'table-td',
        title: 'Incremento',
        dataIndex: 'increment',
        render: columnRender
      },
      {
        className: 'table-td',
        title: 'Visualizar',
        dataIndex: 'view',
        render: () => renderAnchor(letterGeneration)
      }
    ],
    srcData: respTable
  };
};

export const getCommunicationListSrcData = (userLetterList, { generateLetter, variables }) => {
  const resp = userLetterList.map((item) => ({
    title: item.letter.title,
    fields: [
      {
        field: 'Porcentaje',
        value: `${item.data.incrementPercentage}%`
      },
      {
        field: 'Visualizar',
        value: 'Ver carta',
        method: () => generateLetter({ variables })
      }
    ]
  }));
  return resp;
};
export const ENABLE_MERITS_LETTERS = 'ENABLE_MERITS_LETTERS';
export const DISABLE_MERIT_MESSAGE = `El proceso de mérito del año  ${moment().year()} aún no se encuentra habilitado.`;

import { lazy } from 'react';
import { PATH_RECONOCIMIENTOS } from '../helpers/paths';

const Recognitions = lazy(() => import('../../containers/reconocimientos'));

export default [
  {
    exact: true,
    path: PATH_RECONOCIMIENTOS,
    section: 'Reconocimientos',
    component: Recognitions
  }
];

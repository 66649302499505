import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import '../../../static/styles/shared/buttons/ml-button.less';

const MLButton = ({ primary, text, ...rest }) => {
  const getClassName = () => {
    const { className } = rest;
    let defaultClassName = 'ml-button';

    if (className) defaultClassName += ` ${className}`;

    return defaultClassName;
  };

  const getTypeProps = () => (primary ? { type: 'primary' } : null);

  const getButtonProps = () => ({
    ...rest,
    ...getTypeProps(),
    className: getClassName()
  });

  return (
    <Button {...getButtonProps()}>
      {text}
    </Button>
  );
};

MLButton.propTypes = {
  className: PropTypes.string,
  primary: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string
};

MLButton.defaultProps = {
  className: '',
  onClick: () => null,
  primary: false,
  text: ''
};

export default MLButton;

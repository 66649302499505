import { fullCapitalizeFormat } from './strings';

export const getFullName = (user) => {
  try {
    if (!user) return '';
    const { name, fatherLastName, motherLastName } = user;
    const fullName = [name, fatherLastName, motherLastName]
      .filter((chunck) => Boolean(chunck))
      .join(' ');

    return fullCapitalizeFormat(fullName);
  } catch (error) {
    return '';
  }
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import '../../../static/styles/shared/MLTabs.less';
import { logEvent } from '../../../helpers/googleAnalytics';

const { TabPane } = Tabs;

export const setUrl = (value) => {
  if (typeof value === 'string') {
    return value.replace(/\s/g, '-').toLowerCase();
  }
  return '';
};

const MLTabs = ({
  className,
  hideTabs,
  options,
  defaultActiveKey,
  titleAnalyticsEvent,
  unique,
  onChange,
  onTabClick,
  withKeys
}) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  const logEventTab = (value) => {
    const option = options.find(({ key }) => key === value);

    if (option?.title) {
      logEvent(titleAnalyticsEvent, 'Click', setUrl(option.title));
    }
  };

  const onChangeTab = (value) => {
    const change = onChange || setActiveKey;
    const valueToChange = change(value);
    logEventTab(value);
    if (valueToChange) setActiveKey(value);
  };

  const getClassName = () => {
    let res = 'tabs';

    if (className) res += ` ${className}`;
    if (unique) res += ' unique-tab';
    if (hideTabs) res += ' tabs-tab-hidden';

    return res;
  };

  const canRenderChildren = (option, index) => {
    if (withKeys) return option.key === activeKey;
    return activeKey === `${index}`;
  };

  useEffect(() => {
    logEventTab(0);
  }, []);

  return (
    <Tabs
      onChange={onChangeTab}
      onTabClick={onTabClick}
      defaultActiveKey={defaultActiveKey}
      activeKey={activeKey}
      className={getClassName()}
      type='line'
    >
      { options?.map((option, index) => (
        <TabPane
          tab={option.title}
          key={withKeys ? option.key : index}
          className="content-tab"

        >
          {canRenderChildren(option, index) ? option.children : null}
        </TabPane>
      ))}
    </Tabs>
  );
};

MLTabs.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  defaultActiveKey: PropTypes.string,
  titleAnalyticsEvent: PropTypes.string,
  hideTabs: PropTypes.bool,
  unique: PropTypes.bool,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onTabClick: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  withKeys: PropTypes.bool
};

MLTabs.defaultProps = {
  className: '',
  options: [],
  defaultActiveKey: '0',
  titleAnalyticsEvent: 'Tab',
  hideTabs: false,
  unique: false,
  onChange: null,
  onTabClick: () => {},
  withKeys: false
};

export default MLTabs;

import { actionTypes } from '../actions/settings';

export const initialState = {
  data: false,
  error: null,
  loading: false,
  variables: {}
};

function reducer(state = initialState, action) {
  const { variables, payload } = action;
  switch (action.type) {
  case actionTypes.GET_SETTINGS:
    return {
      ...state,
      variables,
      loading: true
    };
  case actionTypes.GET_SETTINGS_SUCCESS:
    return {
      ...state,
      data: payload.value,
      error: null,
      loading: false,
      variables: { key: payload.key }
    };
  case actionTypes.GET_SETTINGS_ERROR:
    return {
      ...state,
      data: false,
      error: payload.error,
      loading: false,
      variables: { key: payload.key }
    };
  case actionTypes.CLEAN_SETTINGS:
    return {
      ...initialState
    };
  default:
    return state;
  }
}
export default reducer;

import { map } from 'lodash';
import { DEFAULT_SUCCESS_MEASURES, WITHOUTINFO } from './index';

const getSuccessMeasures = (
  successMeasures
) => map(successMeasures, (successMeasure) => {
  const {
    updates: updateByPulse = DEFAULT_SUCCESS_MEASURES
  } = successMeasure;
  const updates = {};

  Object.keys(updateByPulse).forEach((updatesQ) => {
    const { status } = updateByPulse[updatesQ];
    updates[updatesQ] = { status: status || WITHOUTINFO };
  });

  return { ...successMeasure, updates };
});

export default getSuccessMeasures;

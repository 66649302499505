import FileDownload from 'js-file-download';
import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

export const uploadMetricsAPI = ({ file, fileName }) => {
  const data = new FormData();
  data.append('file', file, fileName);
  return axios().post(`${BFF_ENDPOINT}/miBono/metrics/upload`,
    data).then((resp) => resp.data);
};

export const uploadDescriptionsAPI = ({ file, fileName }) => {
  const data = new FormData();
  data.append('file', file, fileName);
  return axios().post(`${BFF_ENDPOINT}/miBono/description/upload`,
    data).then((resp) => resp.data);
};

export const simulateBonoAPI = ({ rut }) => axios().post(`${BFF_ENDPOINT}/miBono/simulate`, { rut }).then((resp) => resp.data);

export const getBonoAPI = () => axios().get(`${BFF_ENDPOINT}/miBono/bono-anual`).then((resp) => resp.data);
export const getBonoHistorialAPI = () => axios().get(`${BFF_ENDPOINT}/miBono/bono-anual-history`).then((resp) => resp.data);

export const getPDFAPI = ({ rut }) => axios().get(`${BFF_ENDPOINT}/miBono/generatePDF`,
  { params: { rutsimulate: rut }, responseType: 'blob' })
  .then((resp) => { FileDownload(resp.data, 'bono.pdf'); });

export const getMetricsAPI = () => axios().get(`${BFF_ENDPOINT}/miBono/metrics/list`).then((resp) => resp.data);

export const getDescriptionsAPI = () => axios().get(`${BFF_ENDPOINT}/miBono/descriptions/list`).then((resp) => resp.data);

export const getHistoryAPI = (code) => axios().get(`${BFF_ENDPOINT}/miBono/history/list`, { params: { code } }).then((resp) => resp.data);

export const getActiveYearAPI = () => axios().get(`${BFF_ENDPOINT}/miBono/active-year`).then((resp) => resp.data);

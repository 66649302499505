import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import InfoMessage from '../../../shared/InfoMessage';
import {
  VIEW_AS_EVALUATED, VIEW_AS_EVALUATOR, VIEW_AS_VALIDATOR, REJECTED
} from '../../../../helpers/myEvolution/evaluations';
import '../../../../static/styles/mievolucion/shared/evaluations/evaluations-messages.less';

const EvaluationsMessages = (props) => {
  const {
    messages,
    viewAs
  } = props;

  const ContentMessage = (data) => map(data, ({ content, isBold, link }, index) => (link
    ? (
      <Link to={link}>
        {content}
      </Link>
    )
    : (
      <span key={index} className= { isBold ? 'text-bold' : ''}> { content}</span>
    )));
  const showMessage = (message) => {
    if (!message?.to?.includes(viewAs)) return null;
    if (message?.status === REJECTED) {
      return (
        (<div className='message-evaluation-rejected '>
          <p className='title'>{message.title}</p>
          <ContentMessage {...message.message} />
          <div className='footer'>
            <ContentMessage {...message.footer} />
          </div>
        </div>)
      );
    }
    return (<InfoMessage
      classes='w-100 mb-3 mt-3'
      color='warning'
    >
      <span>
        <ContentMessage {...message.message} />
      </span>
    </InfoMessage>);
  };
  return (
    <div className='evaluation-messages'>
      {
        map(messages, (infoData, index) => (
          <div key={index}>
            {showMessage(infoData)}
          </div>
        ))
      }
    </div>
  );
};

EvaluationsMessages.propTypes = {
  messages: PropTypes.array,
  viewAs: PropTypes.oneOf([VIEW_AS_EVALUATED, VIEW_AS_EVALUATOR, VIEW_AS_VALIDATOR])
};

EvaluationsMessages.defaultProps = {
  messages: [],
  viewAs: VIEW_AS_EVALUATOR
};
export default EvaluationsMessages;

import { map } from 'lodash';
import { actionTypes } from '../actions/milugar';

export const initialState = {
  version: '',
  loadingNews: false,
  finishedNews: false,
  news: [],
  errorNews: false,

  loadingFrequentApps: false,
  finishedFrequentApps: false,
  errorFrequentApps: false,
  frequentApps: [],

  loadingCampaigns: false,
  finishedCampaigns: false,
  campaigns: [],
  errorCampaigns: false,
  loadingFlagInformative: false,
  finishedFlagInformative: false,
  flagInformative: [],
  errorFlagInformative: false

};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.SET_VERSION:
    return {
      ...state,
      version: action.data.version
    };
  case actionTypes.GET_FLAG_INFORMATIVE_BY_USER:
    return {
      ...state,
      ...{
        flagInformative: [],
        loadingFlagInformative: true,
        finishedFlagInformative: false,
        errorFlagInformative: false
      }
    };
  case actionTypes.GET_FLAG_INFORMATIVE_BY_USER_SUCCESS:

    return {
      ...state,
      ...{
        loadingFlagInformative: false,
        finishedFlagInformative: true,
        errorFlagInformative: false,
        flagInformative: action.data
      }
    };

  case actionTypes.GET_FLAG_INFORMATIVE_BY_USER_ERROR:
    return {
      ...state,
      ...{
        flagInformative: [],
        loadingFlagInformative: false,
        errorFlagInformative: true
      }
    };
  case actionTypes.GET_CAMPAIGN_BY_USER:
    return {
      ...state,
      ...{
        campaigns: [],
        loadingCampaigns: true,
        finishedCampaigns: false,
        errorCampaigns: false
      }
    };
  case actionTypes.GET_CAMPAIGN_BY_USER_SUCCESS:

    return {
      ...state,
      ...{
        loadingCampaigns: false,
        finishedCampaigns: true,
        errorCampaigns: false,
        campaigns: action.data
      }
    };

  case actionTypes.GET_CAMPAIGN_BY_USER_ERROR:
    return {
      ...state,
      ...{
        campaigns: [],
        loadingCampaigns: false,
        errorCampaigns: true
      }
    };
  case actionTypes.GET_NEWS:
    return {
      ...state,
      ...{
        news: [],
        loadingNews: true,
        finishedNews: false,
        errorNews: false
      }
    };
  case actionTypes.GET_NEWS_SUCCESS:

    return {
      ...state,
      ...{
        loadingNews: false,
        finishedNews: true,
        errorNews: false,
        news: action.data
      }
    };

  case actionTypes.GET_NEWS_ERROR:
    return {
      ...state,
      ...{
        news: [],
        loadingNews: false,
        errorNews: true
      }
    };

  case actionTypes.GET_FREQUENT_APPS:
    return {
      ...state,
      frequentApps: [],
      loadingFrequentApps: true,
      finishedFrequentApps: false,
      errorFrequentApps: false
    };
  case actionTypes.GET_FREQUENT_APPS_SUCCESS:
    return {
      ...state,
      loadingFrequentApps: false,
      finishedFrequentApps: true,
      errorFrequentApps: false,
      frequentApps: map(
        action.data, (data) => ({ ...data, image: data.mobileSetup.image || data.webSetup.image })
      )
    };
  case actionTypes.GET_FREQUENT_APPS_ERROR:
    return {
      ...state,
      frequentApps: [],
      loadingFrequentApps: false,
      errorFrequentApps: true
    };

  default:
    return state;
  }
}

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import MainBtn from '../buttons/MainBtn';

import '../../../static/styles/shared/filters/filtersModal.less';

const FiltersModal = (props) => {
  const {
    show, hide, children, resetFilters, applyFilters, maskClosable = true
  } = props;
  return (
    <Modal
      className="filters-modal"
      title="Filtrar"
      visible={show}
      onOk={hide}
      onCancel={hide}
      maskClosable={maskClosable}
      footer={[
        <div key="modal-footer" className="d-flex  btn-container  justify-content-center mt-3 mb-5">
          <MainBtn
            mlType="inverse-main"
            text="Limpiar Filtros"
            onClick={() => resetFilters()}
          />
          <MainBtn
            mlType="main"
            className="ant-btn-primary"
            text="Aplicar Filtros"
            onClick={() => applyFilters() }
          />
        </div>
      ]}
    >
      {children}
    </Modal>
  );
};

FiltersModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array
  ]),
  resetFilters: PropTypes.func,
  applyFilters: PropTypes.func
};

export default FiltersModal;

import { lazy } from 'react';
import publics from './publics';
import mlHome from './mlHome';

const MiLugar = lazy(() => import('../../containers/milugar'));
const MiLugarLayout = lazy(() => import('../../layouts/milugar'));

export default [
  ...publics,
  {
    path: '/',
    component: MiLugarLayout,
    subRoutes: [
      ...mlHome,
      {
        path: '/*',
        component: MiLugar
      }
    ]
  }
];

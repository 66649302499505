import React, { Fragment } from 'react';
import { isEmpty } from 'lodash';
import useAuthentication from '../../hooks/useAuthentication';
import useAuthorization from '../../hooks/useAuthorization';
import useAuthReducers from '../../hooks/useAuthReducers';
import useLogin from '../../hooks/useLogin';
import Login from '../../../containers';
import Expiry from './expiry';
import Loader from '../../../components/Loader';
import Error from '../../../components/shared/Error';

const AuthComponent = ({
  auth,
  validateCredentials,
  children,
  history,
  location,
  userValidator,
  canLoadPathComponent
}) => {
  const isAuthenticated = useAuthentication({ history, location });
  const isAuthorized = useAuthorization({
    auth,
    validateCredentials,
    userValidator
  });
  const renderLogin = useLogin({ history, location, isAuthenticated });
  const reducers = useAuthReducers();
  const { pathname } = location;
  const { userReducer } = reducers;
  const { me } = userReducer;

  if (renderLogin) return <Login />;

  // Redirect to home view.
  if (me && (pathname === '/' || !isAuthorized)) {
    history.push('/milugar');
    return <Fragment />;
  }

  // Waiting for session data.
  if (!isEmpty(userReducer.auth) && !me) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <Expiry />
      {canLoadPathComponent ? (
        children || (
          <Error
            reload={(e) => {
              e.preventDefault();
              window.location.reload();
            }}
          />
        )
      ) : (
        <Loader />
      )}
    </Fragment>
  );
};

export default AuthComponent;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Collapse, Icon
} from 'antd';

import Bullet from './Bullet';

import '../../../static/styles/shared/filters/filtersModalSection.less';

const { Panel } = Collapse;

const CustomArrow = ({ state }) => (<i className={`icon-ml-back ${state}`}/>);

const FiltersModalSection = ({ children, label, badge }) => (
  <Collapse
    className="filters-modal-section"
    // defaultActiveKey={['1']}
    onChange={() => {}}
    expandIcon={({ isActive }) => <Icon component={CustomArrow} className={isActive ? 'up' : 'down'} />}
    expandIconPosition='right'
  >
    <Panel header={label} key="1" extra={ badge && badge > 0 ? <Bullet number={badge} /> : null}>
      {children}
    </Panel>
  </Collapse>
);

FiltersModalSection.propTypes = {
  children: PropTypes.element,
  label: PropTypes.string,
  badge: PropTypes.number
};

export default FiltersModalSection;

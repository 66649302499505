import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import '../../../static/styles/shared/inputs/ml-textarea.less';

const { TextArea } = Input;

const MLTextArea = ({
  value, autoSize, placeholder, maxLength, onChange, inputClassName, disabled
}) => {
  const getInputClassName = () => {
    let inputClassNameResult = 'ml-textarea';
    if (inputClassName) inputClassNameResult += ` ${inputClassName}`;
    return inputClassNameResult;
  };

  return (
    <div className='ml-box-text-area box-text-area'>
      <TextArea
        className={getInputClassName()}
        autoSize={autoSize}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
      />
      <div className='d-flex justify-content-end'>
        <p className='text-written-characters'> {value ? value.length : '0'} / {maxLength} </p>
      </div>
    </div>
  );
};

MLTextArea.propTypes = {
  value: PropTypes.string,
  autoSize: PropTypes.object,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  inputClassName: PropTypes.string,
  disabled: PropTypes.bool
};

MLTextArea.defaultProps = {
  value: '',
  autoSize: { minRows: 3, maxRows: 3 },
  placeholder: '',
  maxLength: 200,
  onChange: () => null,
  inputClassName: '',
  disabled: false
};
export default MLTextArea;

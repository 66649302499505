import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import InfoCollapse from '../../shared/collapses/InfoCollapse';

const questions = [
  {
    title: '¿Por qué competencias?',
    child: <p>
      Porque son las habilidades y conductas que impulsamos como Compañía, desde las que cada uno
      {' '}aporta para alcanzar nuestras metas en común.
    </p>
  },
  {
    title: '¿Para qué un nuevo modelo?',
    child: <p>
      En Walmart Chile buscamos desarrollar nuevas competencias en todos nuestros colaboradores
      {' '}para que sean la mejor versión de sí mismos y acompañar la transformación de nuestro negocio.
    </p>
  },
  {
    title: '¿Cómo es este nuevo modelo?',
    child: <p>
      Este modelo tiene 4 competencias y cada una según nuestro nivel de responsabilidad
      {' '}o perfil tiene conductas asociadas.
    </p>
  },
  {
    title: '¿Qué debo hacer si encuentro algún error en la información entregada?',
    child: <p>
      En caso de encontrar un error en la información, por favor contactar a Servicio de Personas.
    </p>
  }
];

const FrequentQuestions = () => (
  <div className="pt-4 pt-sm-3 px-3 px-sm-0">
    {
      questions.map((question) => (
        <InfoCollapse key={uuidv4()} data={question} paneKey={uuidv4()} />
      ))
    }
  </div>
);

export default FrequentQuestions;

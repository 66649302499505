/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const UPLOAD_MASSIVE_OBJECTIVES = gql`
  mutation uploadMassiveObjectivesPerformance($input: MassiveObjectivesInput) {
    uploadMassiveObjectivesPerformance(input: $input)
  }
`;

export const UPLOAD_MASSIVE_PERMISSIONS = gql`
  mutation uploadPermissions($input: MassivePermissions) {
    uploadPermissions(input: $input)
  }
`;

export const UPLOAD_MASSIVE_OBJECTIVES_CURRENT = gql`
  mutation uploadMassiveObjectivesPerformanceCurrent($input: MassiveObjectivesInput) {
    uploadMassiveObjectivesPerformanceCurrent(input: $input)
  }
`;

export const UPLOAD_CATEGORIES_IN_OBJECTIVES = gql`
  mutation uploadCategoriesInObjectives($input: UploadCategoriesInObjectives) {
    uploadCategoriesInObjectives(input: $input){
      message
    }
  }
`;

export const UPLOAD_MASSIVE_OBJECTIVES_TRIBU = gql`
  mutation uploadMassiveObjectivesTribu($input: MassiveObjectivesInput) {
    uploadMassiveObjectivesTribu(input: $input)
  }
`;

export const SET_OPINION_BY_QUARTER = gql`
  mutation saveFeedbackByQuarter( 
    $performanceID: String!,
    $opinion: [QuestionInput]
  ) {
      saveFeedbackByQuarter( 
        performanceID: $performanceID,
        opinion: $opinion
      )
    }
`;

export const UPDATE_OBJECTIVE_WALMART = gql`
  mutation updateObjectiveWalmart($input: ObjectiveWalmartUpdateInput!) {
    updateObjectiveWalmart(input: $input) {
      _id
      objective
      weight
      successMeasures {
        _id
        description
        dateMeasure
        updates {
          q1 {
           status
          }
          q2 {
           status
          }
          q3 {
           status
          }
          q4 {
           status
          }
        }
      }
    }
  }
`;

export const UPDATE_STATUS_PERFORMANCE_WALMART = gql`
  mutation updateStatusPerformanceWalmart($rut: String, $input: DataPerformanceWalmart!) {
    updateStatusPerformanceWalmart(rut: $rut, input: $input) 
  }
`;

export const CREATE_OBJECTIVE_WALMART = gql`
  mutation createObjectiveWalmart($rut: String, $input: ObjectiveWalmartInput!){
    createObjectiveWalmart(rut: $rut, input: $input) {
      _id
      objective
      weight
      visible
      successMeasures {
        _id
        description
        dateMeasure
      }
    }
  }
`;

export const SAVE_PULSE_SURVEY = gql`
  mutation savePulseSurveyByQuarter( 
    $performanceID: String!,
    $userHadConversationWithBoss: Boolean!
    $questions: [PulseSurveyInput]
  ) {
    savePulseSurveyByQuarter( 
        performanceID: $performanceID,
        userHadConversationWithBoss: $userHadConversationWithBoss,
        questions: $questions
      )
    }
`;

/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const ADMIN_GET_USER_PERFORMANCE = gql`
  query listPerformanceAdmin($rut: String) {
    listPerformanceAdmin( rut: $rut) {
      performance {
        status {
          current
        }
        objectiveEdit {
          status
        }
      }
      user {
        rut
        name
        fatherLastName
        motherLastName
      }
      linealBoss {
        name
        fatherLastName
        motherLastName
      }
      permission
    }
  }
`;

/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import { completePath } from '../../../../../helpers/strings';

import Action from './Action';

const Behavior = (props) => {
  const {
    endAt,
    handleEndAction,
    skill,
    level,
    behaviorChoice: {
      _id, name, actions, choice
    },
    indexBehavior
  } = props;

  const renderEmoticons = () => {
    const emoticons = [];
    for (let index = 0; index < 5; index++) {
      emoticons.push(<img
        key={index}
        className="img-fluid"
        src={completePath(
          `web/mi-evolucion/servicios/inicio/emoticones-evolucionaTEST/${index + 1}${choice !== index + 1 ? '_gris' : ''}.png`
        )} alt=""
      />);
    }

    return (
      <div className='container-emoticons'>
        {emoticons}
      </div>);
  };

  return (
    <div key={indexBehavior} className='container-behavior'>
      <div className='box-behavior' >
        <h6>Conducta Nº {_id}</h6>
        <p>
          {name}
        </p>
        <div className='container-info-behavior '>
          <div>
            <h6>Posición inicial TEST</h6>

            {
              renderEmoticons()
            }

          </div>
          <div>
            <h6>Mi Nivel</h6>
            <p>{level}</p>
          </div>
          <div>
            <h6 className='mt-0'>Competencia</h6>
            <p>{skill}</p>
          </div>
        </div>
      </div>

      {
        actions.map((action, indexAction) => (
          <Action
            endAt={endAt}
            key={indexAction}
            indexAction={indexAction}
            handleEndAction={handleEndAction}
            action={action} />
        ))
      }
    </div>
  );
};

Behavior.propTypes = {
  behaviorChoice: PropTypes.object,
  indexBehavior: PropTypes.number,
  handleEndAction: PropTypes.func

};

Behavior.defaultProps = {
  behaviorChoice: {
    id: '1', name: 'Sin nombre', actions: [], choice: 0
  },
  indexBehavior: 0,
  handleEndAction: () => {}
};
export default Behavior;

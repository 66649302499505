import React from 'react';

export const preguntasGenerales = [
  {
    title: '¿Qué es la Gestión de Desempeño?',
    child: <p>
      Conjunto de procesos utilizados para identificar, medir, desarrollar y
      {' '}reconocer el desempeño y talento de los colaboradores en pos de
      {' '}alcanzar nuestra mejor versión y trabajar hacia una cultura de Alto Desempeño.
    </p>
  },
  {
    title: '¿Cómo viviremos la Gestión del Desempeño a partir del 2021?',
    child: <div>
      <p>
        A partir del 2021, viviremos la Gestión del Desempeño de manera flexible y con
        {' '}un foco en el desarrollo continuo de los colaboradores. Esto quiere decir
        {' '}que el desempeño se trabajará durante todo el año, con 1 etapa de Fijación, luego 2 etapas de revisión de avance y finalmente 1 etapa de cierre, de los siguientes focos:
      </p>

      <ul className="question-list">
        <li className="question-list-item">Objetivos</li>
        <li className="question-list-item">Competencias</li>
        <li className="question-list-item">Red de feedback</li>
      </ul>
    </div>
  },
  {
    title: '¿Cuáles son las diferencias entre el proceso de años anteriores con este nuevo Ciclo?',
    child: <div>
      <p>
        El proceso de años anteriores contaba con un único hito a fin de año de revisión
        {' '}formal del desempeño que incluía una categoría final. A partir de este nuevo
        {' '}Ciclo, habrán 4 etapas durante el año (Abril a Junio – Julio a Septiembre -
        {' '}Octubre a Diciembre – Enero a Marzo), donde se podrá revisar formalmente el
        {' '}desempeño. Y esta revisión durante el año no incluirá categoría, ya que se enfoca
        {' '}únicamente en el desarrollo y la mejora. Solo se entregará categoría en la última etapa del año (Enero a Marzo).
      </p>
      <p>
        En consecuencia, a diferencia de los procesos de años anteriores, este nuevo ciclo permite
        {' '}gestionar a corto plazo el desempeño, pudiendo hacer ajustes a tiempo para modificarlos para la siguiente etapa.
      </p>
    </div>
  },
  {
    title: '¿Cuáles son los roles de este nuevo Ciclo?',
    child: <div>
      <p>
        <b>Colaborador</b>: Protagonista de este Ciclo de
        {' '}Gestión del Desempeño. Es el responsable de:
      </p>
      <ul className="question-list">
        <li className="question-list-item">Ingresar sus propuestas de objetivos, competencias y red de feedback al inicio de cada etapa en Mi Lugar.</li>
        <li className="question-list-item">Entre etapas, trabajar en lo acordado.</li>
        <li className="question-list-item">Antes de que finalice una etapa, solicitar feedback sobre lo trabajado.</li>
        <li className="question-list-item">Preparar y realizar presentación de avances a Jefatura y nueva propuesta para etapa siguiente.</li>
      </ul>
      <br/>
      <p>
        <b>Jefatura</b>: Acompañar el desarrollo del colaborador y asegurar el correcto calce entre
        {' '}lo propuesto por este con lo requerido para su rol y la estrategia. Es el responsable de:
      </p>
      <ul className="question-list">
        <li className="question-list-item">Entregar lineamientos claros de cómo la estrategia impacta en los objetivos del colaborador.</li>
        <li className="question-list-item">Cerrar propuesta de objetivos, competencias y red de feedback en Mi Lugar, realizando modificaciones en caso de ser necesario.</li>
        <li className="question-list-item">Entregar feedback constante del desempeño del colaborador.</li>
      </ul>
    </div>
  },
  {
    title: '¿Cuáles son las etapas?',
    child: <div>
      <ul className="question-list">
        <li className="question-list-item"><b>Etapa 1: Abril a Junio</b>.</li>
      </ul>
      <p>
        Apertura del nuevo ciclo de desempeño para el año 2021, co-construir los objetivos,
        {' '}seleccionar las competencias a desarrollar en esta etapa y definir la red de feedback que entregará información sobre los avances de estos.
      </p>
      <ul className="question-list">
        <li className="question-list-item"><b>Etapa 2: Junio a Septiembre</b>.</li>
      </ul>
      <p>
        Periodo para revisar y ajustar los avances en objetivos, Red de feedback y competencias del
        {' '}periodo anterior y encaminar una nueva etapa de desarrollo de estos 3 focos.
      </p>
      <ul className="question-list">
        <li className="question-list-item"><b>Etapa 3: Octubre a Diciembre</b>.</li>
      </ul>
      <p>
        Periodo para revisar y ajustar los avances en objetivos, Red de feedback y competencias del
        {' '}periodo anterior y encaminar una nueva etapa de desarrollo de estos 3 focos.
      </p>
      <ul className="question-list">
        <li className="question-list-item"><b>Etapa 4: Enero a Marzo</b>.</li>
      </ul>
      <p>
        Período de cierre del Ciclo de Desempeño 2021, donde se toma lo trabajado en cada Etapa
        {' '}finalizando con una evaluación y categoría final. Además, permite la apertura de un nuevo ciclo.
      </p>
    </div>
  }
];

export const fijacionObjetivos = [
  {
    title: '¿Qué es la fijación de objetivos?',
    child: <p>
      Es la definición de las metas del colaborador, por las que trabajará durante el año.
    </p>
  },
  {
    title: '¿Qué elementos componen los Objetivos?',
    child: <div>
      <ul className="question-list">
        <li className="question-list-item">Pilares</li>
        <li className="question-list-item">Objetivos</li>
        <li className="question-list-item">Ponderación</li>
        <li className="question-list-item">Iniciativas</li>
        <li className="question-list-item">Fecha Límite cuplimiento iniciativa</li>
        <li className="question-list-item">KPI o indicador iniciativa</li>
      </ul>
    </div>
  },
  {
    title: '¿Qué son los Pilares Estratégicos?',
    child: <div>
      <p>
        Los pilares estratégicos son las prioridades de negocio y transformacionales que se
        {' '}han establecido con el objetivo de alcanzar nuestra visión hacia el 2025. Estos son la base para nuestros objetivos anuales.
      </p>
      <p>Son 8:</p>
      <br />
      <ul className="question-list">
        <li className="question-list-item">Explotar el valor de los datos.</li>
        <li className="question-list-item">Agilidad Organizacional</li>
        <li className="question-list-item">Atraer y Desarrollar Talento</li>
        <li className="question-list-item">Ecosistema Walmart</li>
        <li className="question-list-item">El poder de la Tecnología</li>
        <li className="question-list-item">Liderar en Omnicanalidad</li>
        <li className="question-list-item">Operar Tiendas de Excelencia</li>
        <li className="question-list-item">Continuidad Operacional.</li>
      </ul>
    </div>
  },
  {
    title: '¿Cómo se fijan los objetivos?',
    child: <div>
      <p>
        Los objetivos deben fijarse de acuerdo con la estrategia de la compañía y cómo
        {' '}esta impacta en las funciones del colaborador. Ya teniendo claro la estrategia y los pilares estratégicos que se relacionan con sus funciones, el colaborador debe fijar un mínimo de 3 y máximo de 7 objetivos para el año.
      </p>
      <br />
      <p>Cada objetivo tendrá:</p>
      <br />
      <ul className="question-list">
        <li className="question-list-item">Pilar: Seleccionable dentro de una lista, pueden estar directamente relacionados con la estrategia de la compañía.</li>
        <li className="question-list-item">Objetivo: Descripción de la meta del colaborador, seleccionable de una lista dependiendo del pilar anteriormente seleccionado.</li>
        <li className="question-list-item">Ponderación: Porcentaje asignado al objetivo a desarrollar.</li>
        <li className="question-list-item">Iniciativa: Foco de trabajo para desarrollar el objetivo seleccionado. Es de texto libre y su fecha límite puede ser dentro del período de trabajo hasta el 31 de Diciembre del año actual.</li>
      </ul>
      <br />
      <p>¿Qué son las iniciativas?</p>
      <br />
      <p>
        Las iniciativas son las acciones concretas que nos permitirán alcanzar nuestros objetivos
        {' '}definidos, en un tiempo determinado. Al ingresarlas se debe incluir la fecha límite en que finalizará esa iniciativa y el KPI o indicador para medir su éxito. Todo objetivo debe tener asociado al menos 1 iniciativa
      </p>
      <br />
      <p>
        Si bien los objetivos son fijos a lo largo del año, las iniciativas son flexibles, es decir,
        {' '}en cada inicio de etapa se puede agregar, eliminar y/o modificar las iniciativas para conseguir de mejor manera las metas, iniciativa, además de incluir el % de avance de esta iniciativa a la fecha.
      </p>
    </div>
  },
  {
    title: '¿Cómo puedo modificar mis objetivos durante el año?',
    child: <p>
      En caso que se requiera modificar las iniciativas, debe enviar un correo a
      {' '}gestiondeldesempeno@walmart.com, con copia a su Jefatura, solicitando la apertura de este módulo y los motivos de esta. El Módulo será abierto luego de que su jefatura lo apruebe.
    </p>
  },
  {
    title: '¿Cómo puedo modificar mis iniciativas durante el año?',
    child: <p>
      En caso que se requiera modificar las iniciativas, debe apretar el botón “Editar” que se
      {' '}encuentra al extremo inferior del cuadro de Objetivos Al momento de Editar, la Jefatura recibirá la solicitud de apertura de esta sección junto con los motivos de esto. Al aprobar la apertura, se notificará al colaborador que ya puede realizar modificaciones.
    </p>
  }
];

export const seleccionCompetenciasTrabajar = [
  {
    title: '¿Qué es la selección de competencias a trabajar?',
    child: <p>
      Formalización de las competencias que el colaborador se enfocará en desarrollar
      {' '}durante el periodo correspondiente. Estas competencias pueden ser aquellas
      {' '}competencias que se encuentran con mayor brecha de desarrollo, como también aquellas identificadas como fortaleza pero que son relevantes para los desafíos a los que se enfrentará el colaborador.
    </p>
  },
  {
    title: '¿Cómo se seleccionan estas competencias?',
    child: <div>
      <p>
        La selección de estas competencias deben estar asociadas a los distintos feedback
        {' '}recabados, ya sea a través de a Conversación de Cierre de la EDD del año anterior y de la red de feedback.
      </p>
      <br />
      <p>
        Se podrá seleccionar entre 2 y 3 competencias y al menos una conducta de cada una de
        {' '}ellas por periodo. Se sugiere, que durante el año se haya trabajado en cada competencia, al menos un periodo.
      </p>
    </div>
  },
  {
    title: '¿Cómo puedo modificar mis competencias a trabajar?',
    child: <p>
      En caso que se requiera modificar sus competencias a trabajar, debe enviar un correo a
      {' '}gestiondeldesempeno@walmart.com, con copia a su Jefatura, solicitando la apertura de este módulo y los motivos de esta. El Módulo será abierto luego de que su jefatura lo apruebe.
    </p>
  },
  {
    title: '¿Cómo puedo desarrollar estas competencias durante el periodo?',
    child: <p>
      Luego de seleccionar las competencias y las conductas específicas en las que se trabajará,
      {' '}se sugiere construir un plan con acciones concretas que impulsen el desarrollo de estas conductas. Es importante asegurarse que estas acciones sean medibles y desarrollables a corto plazo, para lograr hacer un seguimiento adecuado. Luego, a partir del feedback recabado al finalizar la etapa, se podrá identificar el impacto que tuvieron estas acciones en el propio desarrollo.
    </p>
  }
];

export const redFeedback = [
  {
    title: '¿Qué es la red de feedback?',
    child: <p>
      Selección de actores claves que se relacionan con el colaborador en diferentes ámbitos de
      {' '}su trabajo, permitiendo obtener una visión integral de su Desempeño y Desarrollo y a quiénes se solicitará feedback sobre sus competencias.
    </p>
  },
  {
    title: '¿Cómo se define la red de feedback?',
    child: <div>
      <p>
        Para definir la red de feedback hay que incorporar una mirada integral, seleccionando
        {' '}mínimo 2 y máximo 4 colaboradores por periodo. Además, se sugiere que la red sea conformada por un líder, un par, un cliente interno/externo y un reporte (si corresponde).
      </p>
      <br />
      <p>
        Cada colaborador podrá ser seleccionado en máximo 6 redes de feedback para el mismo periodo
        {' '}y estos serán notificados de que forman parte de cierta red de feedback.
      </p>
    </div>
  },
  {
    title: '¿Cómo puedo modificar mi red de feedback?',
    child: <p>
      En caso que se requiera modificar su red de feedback, debe enviar un correo a
      {' '}gestiondeldesempeno@walmart.com, con copia a su Jefatura, solicitando la apertura de este módulo y los motivos de esta. El Módulo será abierto luego de que su jefatura lo apruebe.
    </p>
  },
  {
    title: '¿Puedo repetir a alguien de mi red de feedback para el siguiente periodo?',
    child: <p>
      No, cada periodo se debe seleccionar nuevos colaboradores para su red de feedback,
      {' '}diferentes a quienes formaron parte de la red del periodo anterior. Esto permitirá tener una visión amplia y diversa sobre su desempeño.
    </p>
  },
  {
    title: 'Luego de definir la red de feedback, ¿cuándo y cómo lo solicito a los colaboradores que forman parte de esta?',
    child: <div>
      <p>
        Previo al término del periodo se habilitará el botón de “Solicitar Feedback a colaborador”
        {' '}dentro de Mi Desempeño/Red de Feedback. Al solicitar este feedback el colaborador recibirá solicitud desde la APP mi Lugar, de las 5 competencias junto con una propuesta de categoría para cada una de ellas.
      </p>
      <br />
      <p>A partir de este feedback podrás preparar tu propuesta de Desempeño
        {' '}para el siguiente periodo.
      </p>
    </div>

  }
];

export default {
  preguntasGenerales, fijacionObjetivos, seleccionCompetenciasTrabajar, redFeedback
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  Form
} from 'antd';
import { map } from 'lodash';
import Area from './Area';

const Areas = (props) => {
  const {
    getFieldDecorator,
    interestAreas,
    setInterestAreas,
    listOptionsinterestAreasDetail
  } = props;

  const updateArea = (value, index) => {
    const newAreas = interestAreas.map(
      (item, i) => (i === index ? { ...value } : item)
    );
    setInterestAreas(newAreas);
  };

  return (
    <>
      <Form.Item
        label='¿Cuáles son tus áreas de interés laboral?'
      >
        {map(interestAreas, ({ area }, i) => (
          <Area
            key={i}
            index={i}
            updateArea={updateArea}
            getFieldDecorator={getFieldDecorator}
            area={area}
            interestAreas={interestAreas}
            setInterestAreas={setInterestAreas}
            listOptionsinterestAreasDetail={listOptionsinterestAreasDetail}
          />
        ))}
      </Form.Item>
    </>
  );
};
Areas.propTypes = {
  getFieldDecorator: PropTypes.func,
  interestAreas: PropTypes.array,
  setInterestAreas: PropTypes.func,
  listOptionsinterestAreasDetail: PropTypes.array
};

Areas.defaultProps = {
  getFieldDecorator: () => {},
  interestAreas: [],
  setInterestAreas: () => {},
  listOptionsinterestAreasDetail: []

};
export default Areas;

import { actionTypes } from '../actions/operationsAcademy';

export const initialState = {
  sections: null,
  loading: false,
  error: ''
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_OPERATIONS_ACADEMY:
    return {
      ...state,
      loading: true
    };
  case actionTypes.GET_OPERATIONS_ACADEMY_SUCCESS:
    return {
      ...state,
      sections: action.data,
      loading: false,
      error: false
    };
  case actionTypes.GET_OPERATIONS_ACADEMY_FAILURE:
    return {
      ...state,
      error: action.error,
      loading: false
    };
  default:
    return state;
  }
}

export default reducer;

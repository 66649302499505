import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import Button from './categoryButton';
import '../../../static/styles/shared/MLHistorical/category-buttons.less';

const CategoryButtons = ({
  tabs, listTab, onListChange, className
}) => {
  if (!tabs) return null;
  return (
    <div className={`mievolucion-historical-list-category-buttons ${className}`}>
      {map(tabs, ({ key, title, childrenURL }) => (
        <div key={key}>
          <Button
            text={title}
            active={listTab === key}
            onClick={() => onListChange(key, childrenURL)}
          />
        </div>
      ))}
    </div>
  );
};

CategoryButtons.propTypes = {
  tabs: PropTypes.array,
  listTab: PropTypes.number,
  onListChange: PropTypes.func,
  className: PropTypes.string

};

CategoryButtons.defaultProps = {
  tabs: [],
  listTab: 0,
  onListChange: () => {},
  className: ''
};

export default CategoryButtons;

import React from 'react';
import PropTypes from 'prop-types';
import Card from './Card';
import MLLink from '../MLLink';
import { logEvent } from '../../../helpers/googleAnalytics';

const ListCol = ({
  category,
  subCategory,
  isListHorizontal = false,
  index, item, item: { link = '#', target = '_self', title }, openCard, getTextSpace, textSpace,
  item: { visible = true, onlyDesktop = false }
}) => {
  if (!visible) return null;

  const handleItem = () => {
    const label = subCategory ? `${subCategory} - ${title}` : title;
    logEvent(category, 'click', label);
    if (typeof openCard === 'function') {
      openCard(item);
    }
  };
  return (
    <div className={`${!isListHorizontal ? 'col-6 col-sm-12 col-lg-6 col-xl-4 pr-0 pl-0' : ''} ${onlyDesktop && 'd-none d-lg-block'}`}>
      <MLLink
        to={link}
        target={target}
        onClick={handleItem}>
        <Card index={index} getTextSpace={getTextSpace} item={item} textSpace={textSpace} />
      </MLLink>
    </div>
  );
};

ListCol.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  openCard: PropTypes.func,
  getTextSpace: PropTypes.func,
  textSpace: PropTypes.number,
  subCategory: PropTypes.string
};

ListCol.defaultProps = {
  index: null,
  item: {},
  openCard: null,
  getTextSpace: null,
  textSpace: 0,
  subCategory: ''
};

export default ListCol;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import {
  getNewsAPI,
  getFrequentAppsAPI,
  getCampaignByUserAPI,
  updateCampaignsAPI,
  getFlagInformativeByUserAPI
} from '../api/milugar';

import {
  actionTypes,
  getNewsSuccess,
  getNewsError,
  getFrequentAppsSuccess,
  getFrequentAppsError,
  getCampaignByUserSuccess,
  getCampaignByUserError,
  updateCampaignByUserError,
  getFlagInformativeByUserSuccess,
  getFlagInformativeByUserError
} from '../actions/milugar';

es6promise.polyfill();

function* getNews() {
  try {
    const response = yield call(getNewsAPI);
    yield put(getNewsSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getNewsError(error));
  }
}
function* getFrequentApps() {
  try {
    const response = yield call(getFrequentAppsAPI);
    yield put(getFrequentAppsSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getFrequentAppsError(error));
  }
}

function* getCampaignByUser() {
  try {
    const response = yield call(getCampaignByUserAPI);
    yield put(getCampaignByUserSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getCampaignByUserError(error));
  }
}

function* getFlagInformativeByUser() {
  try {
    const response = yield call(getFlagInformativeByUserAPI);
    yield put(getFlagInformativeByUserSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getFlagInformativeByUserError(error));
  }
}

function* updateCampaignByUser(action) {
  try {
    yield call(updateCampaignsAPI, {
      body: action.payload.data
    });
  } catch (err) {
    yield put(updateCampaignByUserError(err));
  }
}

function* milugarsSagas() {
  yield all([takeLatest(actionTypes.GET_NEWS, getNews)]);
  yield all([takeLatest(actionTypes.GET_FREQUENT_APPS, getFrequentApps)]);
  yield all([takeLatest(actionTypes.GET_CAMPAIGN_BY_USER, getCampaignByUser)]);
  yield all([takeLatest(actionTypes.GET_FLAG_INFORMATIVE_BY_USER, getFlagInformativeByUser)]);
  yield all([takeLatest(actionTypes.UPDATE_CAMPAIGN_BY_USER, updateCampaignByUser)]);
}

export default milugarsSagas;

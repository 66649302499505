import { lazy } from 'react';

const Profile = lazy(() => import('../../containers/perfil'));
const PersonalData = lazy(() => import('../../components/profile/personal-data'));
const EmergencyData = lazy(() => import('../../components/profile/emergency-data'));

export default [
  {
    path: '/perfil',
    section: 'Mi Perfil',
    component: Profile,
    subRoutes: [
      {
        exact: true,
        headerTitle: null,
        tabTitle: 'Datos personales',
        path: '/perfil',
        section: 'Perfil -> Datos Personales',
        component: PersonalData
      },
      {
        exact: true,
        headerTitle: null,
        tabTitle: 'Datos de emergencia',
        path: '/perfil/datos-de-emergencia',
        section: 'Perfil -> Datos de emergencia',
        component: EmergencyData
      }
    ]
  }
];

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './registerServiceWorker';

// Global styles loads first.
import 'antd/dist/antd.css';
import 'bootstrap-less-port/less/bootstrap.less';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/less/fontawesome.less';
import '@fortawesome/fontawesome-free/less/regular.less';
import '@fortawesome/fontawesome-free/less/solid.less';
import '@fortawesome/fontawesome-free/less/brands.less';
import './static/styles/estilos.less';
import './static/styles/_error.less';
import './static/styles/styles.less';
import './static/styles/index.less';
import './static/styles/header.less';
import './static/styles/fonts.less';
import './static/styles/login.less';
import './static/styles/colors.less';
import './static/styles/devices-variables.less';
import './static/styles/forgotPassword.less';

import './static/styles/iosInstall.css';
import './static/styles/loading.less';
import './static/styles/menu.less';
import './static/styles/milugar-layout/menu/colors.less';
import './static/styles/milugar-layout/menu/content-menu.less';
import './static/styles/milugar-layout/menu/mobile-menu.less';

import './static/styles/milugar-layout/breadcrumb.less';
import './static/styles/milugar-layout/colors.less';
import './static/styles/milugar-layout/dimensions.less';
import './static/styles/milugar/colors.css';
import './static/styles/mio/benefitHeader.less';
import '@walmart-web/livingdesign-components/index.esm.css';

// TO DO Implementar validación del token; si no existe, redireccionar a una página de error...
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

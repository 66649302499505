import { actionTypes } from '../actions/nuestraCompania';

export const initialState = {
  data: null,
  loading: false,
  error: ''
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_NUESTRA_COMPANIA_CONTENT:
    return {
      ...state,
      loading: true
    };
  case actionTypes.GET_NUESTRA_COMPANIA_CONTENT_SUCCESS:
    return {
      ...state,
      data: action.data,
      loading: false,
      error: false
    };
  case actionTypes.GET_NUESTRA_COMPANIA_CONTENT_FAILURE:
    return {
      ...state,
      error: action.error,
      loading: false
    };
  default:
    return state;
  }
}

export default reducer;

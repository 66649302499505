import React, { useState } from 'react';
import { Table } from 'antd-v4';
import PropTypes from 'prop-types';

import '../../../../static/styles/shared/tables/CollapsableRowTable.less';

const CollapsableRowTable = ({
  data,
  expandableRender,
  withColorStrap = false,
  size
}) => {
  const columns = withColorStrap
    ? [
      {
        title: 'col',
        dataIndex: 'colorBar',
        render: (text = '#1976BD') => (
          <div
            className='h-100 w-2 color-strap'
            style={{ backgroundColor: text }}
          ></div>
        )
      },
      Table.EXPAND_COLUMN,
      ...data.columns
    ]
    : data.columns;

  const [expandedRowKey, setExpandedRowKey] = useState('');

  const onExpand = (_, record) => {
    if (expandedRowKey !== record.key) { setExpandedRowKey(record.key); } else (setExpandedRowKey(''));
  };

  return (
    <div className='collapsable-table'>
      <Table
        columns={columns}
        dataSource={data.rowsData.map((row) => ({ ...row, onExpand }))}
        expandable={{
          expandedRowRender: expandableRender,
          expandRowByClick: true,
          onExpand,
          expandedRowKeys: [expandedRowKey]
        }}
        pagination={false}
        size={size}
      />
    </div>
  );
};

CollapsableRowTable.propTypes = {
  data: PropTypes.object,
  expandableRender: PropTypes.func,
  withColorStrap: PropTypes.bool,
  size: PropTypes.string
};

CollapsableRowTable.defaultProps = {
  data: {},
  expandableRender: () => <div>Default expandable render</div>,
  withColorStrap: false,
  size: undefined
};

export default CollapsableRowTable;

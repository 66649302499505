import React from 'react';
import PropTypes from 'prop-types';
import {
  Select, Input
} from 'antd';
import { map } from 'lodash';
import { Item } from '../../Form';
import {
  WITHOUTINFO
} from '../../../../../helpers/myEvolution/interestsAndSkills';

const { Option } = Select;

const CollegeCarrer = (props) => {
  const {
    updateStatusAndCollegeCarrer,
    getFieldDecorator,
    collegeCarrer,
    statusOfStudies,
    listStatusOfStudies,
    index,
    listLevelsOfStudies,
    levelsOfStudies,
    setLevelsOfStudies,
    postgraduateName,
    setPostgraduateName,
    initialLevelsOfStudies,
    listOptionsCollegeCarrer
  } = props;

  const onChangeCollegeCarrer = (value) => {
    updateStatusAndCollegeCarrer({
      statusOfStudies,
      collegeCarrer: {
        _id: value
      }
    }, index);
  };

  const onChangeStatusOfStudies = (value) => {
    updateStatusAndCollegeCarrer({
      statusOfStudies: value,
      collegeCarrer
    }, index);
  };

  return (
    <>

      <div className='row'>
        <Item
          className='col-12-col-md-6 col-lg-6'
          label='¿Cuál es tu nivel de estudios?'
        >
          <Select placeholder='Selecciona una opción'
            { ...(initialLevelsOfStudies !== WITHOUTINFO
              ? { defaultValue: initialLevelsOfStudies } : null)}
            onChange={setLevelsOfStudies}
          >
            {map(listLevelsOfStudies, (item, i) => (
              <Option key={i} value={item.codeValue}>{item.name}</Option>
            ))}
          </Select>
        </Item>

        <Item
          className='col-12-col-md-6 col-lg-6'
          label='Estado'
        >
          {getFieldDecorator(`statusOfStudies-${index}`,
            {
              ...(statusOfStudies ? { initialValue: statusOfStudies } : null)
            })(
            <Select placeholder='Selecciona una opción'
              onChange={onChangeStatusOfStudies}>
              {map(listStatusOfStudies, (item, i) => (
                <Option key={i} value={item.codeValue}>{item.name}</Option>
              ))}

            </Select>
          )}
        </Item>
      </div>

      <div className='row'>

        <Item
          className='col-12-col-md-6 col-lg-6'
          label='Cuéntanos cuál es tu carrera'
        >
          {getFieldDecorator(`collegeCarrer-${index}`,
            {
              initialValue: collegeCarrer?._id
            })(
            <Select
              showSearch
              allowClear
              placeholder="Selecciona una opción"
              filterOption={ (input, option) => (
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
              }
              onChange={onChangeCollegeCarrer}
            >
              {map(listOptionsCollegeCarrer, (item) => (
                <Option key={item._id} value={item._id}>{item.name}</Option>
              ))}
            </Select>
          )}
        </Item>
        {levelsOfStudies === 'POST_DEGREES'
        && <Item
          className='col-12-col-md-6 col-lg-6'
          label='Nombre del postgrado'
        >
          {getFieldDecorator(`postgraduateName-${index}`,
            {
              ...(postgraduateName !== WITHOUTINFO
                ? { initialValue: postgraduateName } : null)
            })(
            <Input
              onChange={({ target }) => {
                setPostgraduateName(target.value);
              }}
              placeholder='Ingresa el nombre del postgrado' />
          )}
        </Item>
        }
      </div>
    </>

  );
};
CollegeCarrer.propTypes = {
  updateStatusAndCollegeCarrer: PropTypes.func,
  getFieldDecorator: PropTypes.func,
  collegeCarrer: PropTypes.object,
  statusOfStudies: PropTypes.string,
  listStatusOfStudies: PropTypes.array,
  index: PropTypes.number,
  listLevelsOfStudies: PropTypes.array,
  levelsOfStudies: PropTypes.string,
  setLevelsOfStudies: PropTypes.func,
  postgraduateName: PropTypes.string,
  setPostgraduateName: PropTypes.func,
  initialLevelsOfStudies: PropTypes.string,
  listOptionsCollegeCarrer: PropTypes.array

};

CollegeCarrer.defaultProps = {
  getFieldDecorator: () => {},
  updateStatusAndCollegeCarrer: () => {},
  collegeCarrer: {},
  statusOfStudies: '',
  listStatusOfStudies: [],
  index: 0,
  listLevelsOfStudies: [],
  levelsOfStudies: '',
  setLevelsOfStudies: () => {},
  postgraduateName: '',
  setPostgraduateName: () => {},
  loadingUpdate: false,
  initialLevelsOfStudies: '',
  listOptionsCollegeCarrer: []
};

export default CollegeCarrer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MLModalFullScreen from '../../../../../../shared/MLModalFullScreen';
import ObjectiveModal from './ObjectiveModal';

const Objective = (props) => {
  const {
    objectiveInfo,
    indexToShowInLabel
  } = props;
  const { weight, objective, categorySelected } = objectiveInfo;

  const { description, name: objectiveName } = objective || {};
  const {
    name,
    objetivesDescription
  } = categorySelected || {};
  const [open, setOpen] = useState(false);

  const [visibleBigModal, setVisibleBigModal] = useState(false);

  const handleSkill = (e) => {
    e.preventDefault();
    setOpen(!open);
  };
  const handleModalObjetive = (e) => {
    e.preventDefault();
    setVisibleBigModal(!visibleBigModal);
  };
  return (
    <div className='body-summary'>
      <div className='column-evaluation-summary left'>
        <div className='container-title-skill'>
          <div
            className='container-title-icon'
            onClick={handleSkill} >
            <div className='container-icon'>
              <span className={`fa fa-${open ? 'minus' : 'plus'}`} />
            </div>
            <p>{`Objetivo ${indexToShowInLabel + 1} | Ponderación ${Math.trunc((weight))}%` }</p>
          </div>
        </div>
        {
          open
        && <div>
          <p className='description left'>{description || objectiveName}</p>
          <p onClick={handleModalObjetive} className='see-more-information'>Ver más información</p>
        </div>
        }
      </div>
      <div className='column-evaluation-summary right'>
        <div className='container-title-category' >
          <p className='text-category'>{name}</p>
        </div>
        {
          open
        && <div>
          <p className='description'>{objetivesDescription}</p>
        </div>
        }
      </div>
      {
        visibleBigModal
          && <MLModalFullScreen
            zIndex={2000}
            visible={visibleBigModal}
            closeModal= {handleModalObjetive}
            title={ `Objetivo ${indexToShowInLabel + 1}`}
          >
            <div className='container-fluid'>
              <div className='row p-4 justify-content-md-center'>
                <div className='col-sm-12 col-md-6 col-lg-6'>
                  <ObjectiveModal
                    objectiveInfo={objectiveInfo}
                    numberObjective={indexToShowInLabel}
                    openAllCollapse />
                </div>
              </div>
            </div>
          </MLModalFullScreen>
      }
    </div>
  );
};

Objective.propTypes = {
  indexToShowInLabel: PropTypes.number,
  objectiveInfo: PropTypes.object
};

Objective.defaultProps = {
  indexToShowInLabel: 0,
  objectiveInfo: {}
};

export default Objective;

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import Label from './Label';
import getClassName from '../../../../helpers/strings/getClassName';
import { SEARCH_BAR_CLASS } from '../../../../helpers/searchBar/classNames';

const SearchBar = ({
  className, inputProps, formItemProps, label
}) => (
  <div className={getClassName(SEARCH_BAR_CLASS, className)}>
    <Label label={label}/>

    <div className="row">
      <Form.Item {...formItemProps[0]}>
        <i className='icon-ml-search'/>
        <Input {...inputProps[0]}/>
      </Form.Item>
    </div>
  </div>
);

SearchBar.propTypes = {
  className: PropTypes.string,
  inputProps: PropTypes.array,
  formItemProps: PropTypes.array,
  SearchBar: PropTypes.string
};

SearchBar.defaultProps = {
  className: '',
  inputProps: [],
  formItemProps: [],
  label: ''
};

export default SearchBar;

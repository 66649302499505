import React from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty } from 'lodash';

const DetailsRow = ({ label, value, className }) => (
  <div data-testid='details-row' className={`item ${className}`}>
    <h5>{`${label}: `}</h5>
    {value && <h6>{value}</h6>}
  </div>
);

const Dad = (props) => {
  const { contentData } = props;
  if (isEmpty(contentData)) return null;

  return (
    <div className='container-info-separater-by-vertical-bar'>
      {map(contentData, (data, i) => (
        <DetailsRow key={i} {...data } />
      ))}
    </div>

  );
};
DetailsRow.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element
  ]),
  value: PropTypes.string,
  className: PropTypes.string
};

DetailsRow.defaultProps = {
  label: '',
  value: '',
  className: ''
};

export default Dad;

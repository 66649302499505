const interceptor = (error) => {
  if (error?.response?.status === 503
    && error?.response?.data?.message.toUpperCase() === 'MAINTENANCE'
  ) {
    const maintenanceURL = `${process.env.PUBLIC_URL}/maintenance-freeze`;
    localStorage.setItem('token', '');
    if (localStorage.getItem('token') === '' && window.location.pathname !== maintenanceURL) {
      window.location.href = maintenanceURL;
    }
  }
  return Promise.reject(error);
};

export default interceptor;

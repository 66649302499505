import { actionTypes } from '../actions/reports';

export const initialState = {

  requestByCode: null,
  loadingRequestByCode: false,
  errorRequestByCode: null,

  detailsByCode: [],
  loadingDetailsByCodeReportsHistory: false,
  errorDetailsByCodeReportsHistory: null
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.SET_REQUEST_BY_CODE:
    return {
      ...state,
      loadingRequestByCode: true,
      errorRequestByCode: null,
      requestByCode: null
    };
  case actionTypes.SET_REQUEST_BY_CODE_SUCCESS:
    return {
      ...state,
      requestByCode: action.payload,
      errorRequestByCode: null,
      loadingRequestByCode: false
    };
  case actionTypes.SET_REQUEST_BY_CODE_ERROR:
    return {
      ...state,
      errorRequestByCode: action.error,
      loadingRequestByCode: false,
      requestByCode: null
    };

  case actionTypes.GET_DETAILS_BY_CODE_REPORTS_HISTORY:
    return {
      ...state,
      loadingDetailsByCodeReportsHistory: true,
      errorDetailsByCodeReportsHistory: null,
      detailsByCode: []
    };
  case actionTypes.GET_DETAILS_BY_CODE_REPORTS_HISTORY_SUCCESS:
    return {
      ...state,
      detailsByCode: action.payload,
      errorDetailsByCodeReportsHistory: null,
      loadingDetailsByCodeReportsHistory: false
    };
  case actionTypes.GET_DETAILS_BY_CODE_REPORTS_HISTORY_ERROR:
    return {
      ...state,
      errorDetailsByCodeReportsHistory: action.error,
      loadingDetailsByCodeReportsHistory: false,
      detailsByCode: []
    };

  case actionTypes.RESET_REQUEST_BY_CODE:
    return {
      ...state,
      requestByCode: null,
      loadingRequestByCode: false,
      errorRequestByCode: null
    };
  default:
    return state;
  }
}

export default reducer;

import gql from 'graphql-tag';
import { OBJECTIVE_FIELDS } from '../objectives/fragments';
import { COMPETENCE_FIELDS } from '../competencies/fragments';
import { FEEDBACK_NET_FIELDS } from '../feedbackNet/fragments';

const MY_PERFORMANCE_QUERY = `
  query {
    listPerformance {
      _id
      year
      status
      objectiveEdit
      quarterCurrent
      statusByQuarter {
        q1
        q2
        q3
        q4
        q5
      }
      user {
        name
        fatherLastName
        position
        _id
      }
      ...ObjectiveFields
      ...CompetenciesFields
      ...FeedbackNetFields
      isQuarterFinished {
        q1
        q2
        q3
        q4
        q5
      }
      statusByQuarter {
        q1
        q2
        q3
        q4
        q5
      }
    }
  }
`;

const MY_PERFORMANCE_WALMART_QUERY_FIELDS = `
_id
typeUserRoot {
  is
}
user{
  livePulse
  objectiveType
}
rut
year
quarterCurrent
atLeastOnceClosedStatus
userType
boss {
  rut
  name
}
objectiveEdit {
  status
}
status {
  current
  commentByChangedStatus
}
objectives {
  data {
    _id
    objective {
      name
      description
      pillar {
        name
      }
    }
    weight
    weightBoss
    visible
    tribu
    successMeasures {
      _id
      description
      dateMeasure
      updates {
        q1 {
          status
        }
        q2 {
          status
        }
        q3 {
          status
        }
        q4 {
          status
        }
      }
    }
    successMeasuresBoss {
      _id
      description
      dateMeasure
      updates {
      q1 {
        status
      }
      q2 {
        status
      }
      q3 {
        status
      }
      q4 {
        status
      }
      }
    }
  }
}
statusByQuarter {
  q1 {
    status
  }
  q2 {
    status
  }
  q3 {
    status
  }
  q4 {
    status
  }
}
massiveCharge {
  status
  nameCharge
}
comments{
  q1 {
    commentColaborate
    dateCommentColaborate
    commentBoss
    dateCommentBoss
    commentWrittenBoss{
      rut
      name
    }
  }
  q2 {
    commentColaborate
    dateCommentColaborate
    commentBoss
    dateCommentBoss
    commentWrittenBoss{
      rut
      name
    }
  }
  q3 {
    commentColaborate
    dateCommentColaborate
    commentBoss
    dateCommentBoss
    commentWrittenBoss{
      rut
      name
    }
  }
  q4 {
    commentColaborate
    dateCommentColaborate
    commentBoss
    dateCommentBoss
    commentWrittenBoss{
      rut
      name
    }
  }
}`;

const MY_PERFORMANCE_WALMART_QUERY = `
  query ($rut: String) {
    getPerformanceWalmart(rut: $rut) {
      ${MY_PERFORMANCE_WALMART_QUERY_FIELDS}
    }
  }
`;

const PERFORMANCE_WALMART_CACHED_QUERY = `
query ($rut: String) {
  getCachedPerformanceWalmart(rut: $rut) {
    ${MY_PERFORMANCE_WALMART_QUERY_FIELDS}
  }
}
`;

const BOSS_PERFORMANCE_WALMART_CACHED_QUERY = `
query ($rut: String) {
  getCachedBossPerformanceWalmart(rut: $rut) {
    ${MY_PERFORMANCE_WALMART_QUERY_FIELDS}
  }
}
`;

export const GET_MY_PERFORMANCE = gql`
  ${OBJECTIVE_FIELDS}
  ${COMPETENCE_FIELDS}
  ${FEEDBACK_NET_FIELDS}
  ${MY_PERFORMANCE_QUERY}
`;

export const GET_MY_TEAM_PERFORMANCE = gql`
  ${OBJECTIVE_FIELDS}
  ${COMPETENCE_FIELDS}
  ${FEEDBACK_NET_FIELDS}
  ${MY_PERFORMANCE_QUERY
  .replace('query', 'query ($rut: String)')
  .replace('listPerformance', 'listPerformance (rut: $rut)')}
`;

export const GET_MY_PERFORMANCE_OBJECTIVES = gql`
  ${OBJECTIVE_FIELDS}
  ${MY_PERFORMANCE_QUERY
  .replace('...CompetenciesFields', '')
  .replace('...FeedbackNetFields', '')}
`;

export const GET_MY_TEAM_PERFORMANCE_USERS = gql`
  query {
    listPerformanceTeam {
      user {
        _id
        rut
        name
        fatherLastName
        position
        area3
      }
      status
      fixedObjective
    }
  }
`;

export const LIST_PERFORMANCE_WALMART_TEAM = gql`
  query {
    listPerformanceWalmartTeam {
      user {
        _id
        rut
        name
        fatherLastName
        position
        area3
      }
      status {
        current
      }
      fixedObjective
    }
  }
`;

export const HAVE_PERFORMANCE_TEAM = gql`
  query {
    havePerformanceTeam
  }
`;
export const GET_FEEDBACK_NETWORK = gql`
    query feedbackNetworkByQuarter(
        $performanceID: String!
        $year: Int!
        $quarter: QuarterKey!
    ) {
      feedbackNetworkByQuarter(
          performanceID: $performanceID
          year: $year
          quarter: $quarter
        ) {
          totalReceivedFeedbacks
          totalRequestedFeedbacks
          feedbacks {
            user
            {
              rut
            }
            status
          }
          skills {
            name
            average
            finalCategory {
              name
              range {
                from
                to
              }
            }
            comments {
              content
                user {
                  _id
                  name
                  secondName
                  fatherLastName
                  motherLastName
              }
            }
          }
        }
    }
`;

export const GET_PILLARS_WALMART = gql`
  query getPillarWalmart {
    getPillarWalmart {
      _id
      pillarWalmart {
        _id
        name
        description
        year
        strategicPriority {
          _id
          name
          macros {
            _id
            name
            description
            objectivesByMacro {
              _id
              name
              description
            }
          }
          objectivesStrategicPriority {
            _id
            name
            description
          }
        }
      }
    }
  }
`;

export const GET_PERFORMANCE_WALMART = gql`
  ${MY_PERFORMANCE_WALMART_QUERY}
`;

export const GET_CACHED_PERFORMANCE_WALMART = gql`
  ${PERFORMANCE_WALMART_CACHED_QUERY}
`;

export const GET_CACHED_BOSS_PERFORMANCE_WALMART = gql`
  ${BOSS_PERFORMANCE_WALMART_CACHED_QUERY}
`;

export const GET_PILLARS = gql`
  query {
    getPillarWalmart{
      _id
      data{
        _id
        name
        description
        pillar{
          name
        }
      }
    }
}
`;

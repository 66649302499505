import React from 'react';
import InfoMessage from '../shared/InfoMessage';
import '../../static/styles/pwa-install/style.less';
import { completePath } from '../../helpers/strings';

const steps = [
  {
    message: 'Haz clic en el ícono de una flecha que se encuentra en la parte inferior de la pantalla.'
  },
  {
    message: 'Selecciona "Agregar a Inicio".'
  },
  {
    message: 'Espera unos segundos mientras carga el logo de Mi Lugar y luego haz clic en "Agregar".'
  }
];

const InstallationSteps = () => (
  <div className="container-pwa ">
    <div className="d-flex flex-row justify-content-sm-center ">
      <InfoMessage classes='mb-sm-5' message="Desde esta misma página, sigue los siguientes pasos."/>
    </div>
    <div className='row d-flex justify-content-center'>
      {
        steps.map((step, i) => (
          <div key={i} className="col-12 col-sm-6 col-md-3 step">
            <div className="d-flex flex-row align-items-start ">
              <div
                className="rounded-circle number"
              >{(i + 1)}
              </div>
              <div className="d-flex flex-column justify-content-start align-items-start">
                <p>{step.message}</p>
                <img src={completePath(`web/pwa-install/safari-${(i + 1)}.jpg`)} alt="Safari"/>
              </div>
            </div>
          </div>
        ))
      }
    </div>
    <div className="d-flex flex-column justify-content-center align-items-center">
      <p className="text-ready">¡Listo!</p>
      <p className="text-new-app">
        <span>Ahora solo espera unos segundos y ya encontrarás la
          <span className="text-bold"> Nueva App de Mi Lugar </span>
              entre tus aplicaciones. Podrás reconocerla por nuestro nuevo
          <span className="text-bold"> ícono blanco</span>.
        </span>
      </p>
      <img
        className="logo-pwa"
        src={completePath('web/pwa-install/logo.png')}
        alt="Logo PWA"
      />
    </div>
  </div>
);

export default InstallationSteps;

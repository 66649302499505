import React from 'react';

export const ResCompetenciesExpand = ({
  behaviors, categorySelected
}) => (<div className='pl-5 d-flex resume-competencies-expand'>
  <div className='py-4 ml-flex-1'>
    <div className='d-flex flex-column mb-4'>
      {behaviors.map((beh, i) => (
        <div className={`pr-4 ${i === 0 && 'mb-3'}`} key={i}>
          <p className='mb-2'><b>{beh.title}</b></p>
          <p>{beh.description}</p>
        </div>
      ))}
    </div>
  </div>
  <div className='py-4 ml-flex-1'>
    <div className='pl-3 pr-5 bd-between'>
      <p className='mb-2 color-text-secondary'>{categorySelected?.name}</p>
      <p className='mb-2 color-text-secondary'>{categorySelected?.skillDescription}</p>
    </div>
  </div>
</div>);

export const ResObjetivesExpand = ({
  objective, categorySelected

}) => (<div className='pl-5 d-flex resume-objectives-expand'>
  <div className='py-4 ml-flex-6'>
    <div className='d-flex flex-column mb-4 descriptions-container'>
      <div className='pr-4' >
        <p className='mb-2'><b>Descripción del objetivo</b></p>
        <p>{objective.description}</p>
      </div>
    </div>
  </div>
  <div className='py-4 ml-flex-4'>
    <div className='pl-3 pr-5 bd-between'>
      <p className='mb-2 color-text-secondary'>{categorySelected?.name}</p>
      <p className='mb-2 color-text-secondary'>{categorySelected?.objetivesDescription}</p>
    </div>
  </div>
</div>);

import React from 'react';
import PropTypes from 'prop-types';

import '../../../static/styles/shared/filters/bullet.less';

const Bullet = ({ number, classes }) => (
  <div
    data-testid='bullet'
    className={`filters-bullet d-flex justify-content-center align-items-center ${classes}`}
  >
    <span>{number}</span>
  </div>
);

Bullet.propTypes = {
  number: PropTypes.number.isRequired,
  classes: PropTypes.string
};

Bullet.propDefault = {
  number: 0,
  classes: ''
};

export default Bullet;

import { gql } from '@apollo/client';
import { IDP } from './queries';

export const SAVE_IDP = gql`
  mutation saveIDP(
    $input: IDPInput
  ) {
    saveIDP
      (    
        input: $input
      ) {
          success
          message
          ${IDP}
      }
  }
`;

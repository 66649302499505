import React from 'react';
import PropTypes from 'prop-types';
import FinalButtons from '../../../../shared/buttons/FinalButtons';
import MLModalWithFixedLayout from '../../../../shared/MLModal/WithFixedLayout';
import MLModalMessage from '../../../../shared/MLModal/Message';
import RejectForm from './forms/RejectForm';
import RejectButton from '../../../../shared/buttons/RejectButton';
import useOpenModal from '../../../../../hooks/shared/mlModal/useOpenModal';
import usePerformance from '../../../../../hooks/myEvolution/myPerformance/usePerformance';
import useUpdatePerformanceStatus from '../../../../../hooks/myTeam/myEvolution/performance/updatePerformanceStatus';
import { GET_NOTIFICATIONS } from '../../../../../graphql/notifications/queries';
import { CLOSED, FIRST_PULSE } from '../../../../../helpers/myEvolution/myPerformance';

const SucessMessageModal = MLModalMessage;
const ApproveConfirmModal = MLModalMessage;
const ErrorModal = MLModalMessage;
const IS_PULSE_CLOSED = false;
const TITLE_PULSE_CLOSE = 'No se puede cerrar el pulso';

const MESSAGE_PULSE_CLOSE = `El proceso de Pulso finalizó, por lo que ya no puedes cerrar el pulso ya que se reiniciaran las evaluaciones y autoevaluaciones.
Si quieres hacerlo debes contactarte con el equipo de Gestión del Desempeño al correo gestiondeldesempeno@walmart.com`;

const BossButtons = ({ bossParams, commentFormRef, refetch }) => {
  const { data: performance = {} } = usePerformance();
  const { atLeastOnceClosedStatus } = performance;
  const { selectedUser } = bossParams;
  const { open: openSuccessModal, refOpen: refSuccessModal } = useOpenModal();
  const { open: openConfirmModal, refOpen: refConfirmModal } = useOpenModal();
  const { open: openErrorModal, refOpen: refErrorModal } = useOpenModal();
  const { callApi: updatePerformanceStatus } = useUpdatePerformanceStatus({
    refetchQueries: [
      {
        query: GET_NOTIFICATIONS
      }
    ]
  });
  const handleSuccess = async ({ closeModal }) => {
    await closeModal();
    refetch();
  };

  const getPropsAlertBoss = () => {
    const commonProps = {
      type: 'warning',
      icon: 'icon-ml-warning pending-icon-color'
    };
    if (IS_PULSE_CLOSED) {
      return {
        ...commonProps,
        title: TITLE_PULSE_CLOSE,
        message: MESSAGE_PULSE_CLOSE,
        buttons: [
          {
            text: 'Cerrar',
            type: 'primary'
          }
        ]
      };
    }
    if (atLeastOnceClosedStatus) {
      return {
        ...commonProps,
        title: `¿Quieres enviar comentario a ${selectedUser?.name}?`
      };
    }
    return {
      ...commonProps,
      title: `¿Quieres aprobar la Propuesta de ${selectedUser?.name}?`,
      message: 'Estos serán los objetivos en los que trabajará durante este año.'
    };
  };

  const approveFunc = () => openConfirmModal(
    getPropsAlertBoss()
  );

  const showModalPerformanceClosed = (events) => {
    openSuccessModal({
      type: 'success',
      title: atLeastOnceClosedStatus ? `¡El comentario fue enviado a ${selectedUser?.name} con éxito!`
        : `¡La propuesta de ${selectedUser?.name} fue aprobada con éxito!`
    });
    events.closeModal();
  };

  const handleConfirmOk = async (events) => {
    events.startLoading();
    try {
      if (performance.quarterCurrent === FIRST_PULSE) {
        await updatePerformanceStatus({
          variables: {
            rut: performance.rut,
            input: {
              status: CLOSED
            }
          }
        });
        refetch();
        showModalPerformanceClosed(events);
      } else {
        commentFormRef.current.props.onSubmit(
          events,
          () => {
            showModalPerformanceClosed(events);
          }
        );
      }
    } catch (err) {
      events.endLoading();

      openErrorModal({
        title: 'No pudimos aprobar la Propuesta',
        message: 'Estamos trabajando en solucionarlo, por favor intenta de nuevo más tarde.'
      });
    }
  };

  const submitReject = async (events) => {
    events.closeModal();
    openSuccessModal({
      title: `La Propuesta fue devuelta a ${bossParams?.selectedUser?.name}. Podrá editarla y volver a enviártela.`
    });
  };

  return (
    <>
      <FinalButtons {...{
        approveText: atLeastOnceClosedStatus ? 'Enviar comentario' : 'Aprobar Propuesta',
        approveFunc,
        ...(atLeastOnceClosedStatus ? {
          rejectText: null
        } : {
          RejectButton: () => (
            <MLModalWithFixedLayout {...{
              FormComponent: (props) => (
                <RejectForm {...{ ...props, selectedUser }}/>
              ),
              onFormSubmit: submitReject,
              OpenButton: RejectButton,
              title: `Devolver Propuesta a ${selectedUser?.name}`,
              openButtonText: 'Devolver Propuesta',
              okButtonText: 'Devolver Propuesta',
              cancelConfirm: {
                title: '¿Quieres salir sin devolver la propuesta?'
              }
            }}/>
          )
        })
      }}/>

      <SucessMessageModal {...{
        type: 'success', refOpen: refSuccessModal, handleOk: handleSuccess
      }}/>

      <ApproveConfirmModal {...{
        refOpen: refConfirmModal,
        handleOk: handleConfirmOk,
        okButtonText: atLeastOnceClosedStatus ? 'Sí, enviar' : 'Sí, aprobar propuesta',
        defaultCancel: true
      }}/>

      <ErrorModal {...{ refOpen: refErrorModal }}/>
    </>
  );
};

BossButtons.propTypes = {
  bossParams: PropTypes.object,
  refetch: PropTypes.func,
  commentFormRef: PropTypes.object
};

BossButtons.defaultProps = {
  bossParams: {},
  refetch: () => null,
  commentFormRef: {}
};

export default BossButtons;

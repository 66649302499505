import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyCredentialProfile } from '../../redux/actions/micredencial';
import useSectionName from './useSectionName';
import shouldFetchRequest from '../helpers/shouldFetchRequest';

const useCredentialsLoads = (leafPath) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.userReducer.data);
  const myCredentialProfile = useSelector((state) => state.miCredencialReducer.myCredentialProfile);
  const section = useSectionName();

  useEffect(() => {
    if (shouldFetchRequest(me, section, leafPath, myCredentialProfile)) {
      dispatch(getMyCredentialProfile(me.npres));
    }
  }, []);

  return myCredentialProfile;
};

export default useCredentialsLoads;

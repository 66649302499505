import React, {
  createContext,
  createRef,
  useRef
} from 'react';
import useMiLugarEnabled from '../hooks/useMiLugarEnabled';
import { useRootLayout } from '../hooks/useRootLayout';

export const AppRouterContext = createContext();

const AppRouterContextProvider = ({ children }) => {
  const milugarEnabled = useMiLugarEnabled();
  const changeAppLayoutProps = useRootLayout();
  const routesContainerReef = useRef(createRef());
  const value = { changeAppLayoutProps, milugarEnabled, routesContainerReef };

  return (
    <AppRouterContext.Provider value={value}>
      {children}
    </AppRouterContext.Provider>
  );
};

export default AppRouterContextProvider;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { GET_MY_RETROS } from '../../../graphql/mievolucion/retro/queries';
import Loader from '../../Loader';
import { getInfoEvaluation } from '../../../helpers/myEvolution/retro';
import Error from '../../shared/Error';
import ModalMessage from '../../shared/ModalMessage';
import MyEvaluations from './MyEvaluations';
import { optionsWithNetworkOnly } from '../../../helpers/myEvolution';

const Retro = (props) => {
  const { banner } = props;
  const {
    evaluationType,
    additionalField7
  } = useSelector((state) => state.userReducer.data);
  const { typeEvaluation, isEnabled } = getInfoEvaluation(evaluationType);

  const [modalMessage, setModalMessageInfo] = useState({
    visible: false,
    title: '',
    message: '',
    icon: '',
    type: ''
  });

  const closeModal = () => {
    setModalMessageInfo({
      visible: false,
      message: '',
      title: '',
      type: 'success'
    });
  };

  const {
    dateOld, position
  } = useSelector((state) => state.userReducer.data);

  const {
    loading: loadingMyRetros,
    data: dataMyRetros,
    error: errorMyRetros,
    refetch: refetchMyRetros
  } = useQuery(GET_MY_RETROS,
    {
      ...optionsWithNetworkOnly
    });

  const { myRetros = [] } = dataMyRetros || {};
  if (loadingMyRetros) return <Loader/>;

  if (errorMyRetros) {
    return <Error reload={ () => refetchMyRetros()

    }/>;
  }

  return (
    <div className='container-my-evaluations'>
      {modalMessage.visible
      && <ModalMessage
        {...modalMessage}
        closeModal={closeModal}
      />
      }
      <MyEvaluations {
        ...{
          position,
          isEnabled,
          dateOld,
          myRetros,
          props,
          typeEvaluation,
          banner,
          additionalField7
        }
      }
      />
    </div>
  );
};

Retro.propTypes = {
  banner: PropTypes.object
};

Retro.defaultProps = {
  banner: {}
};
export default Retro;

import React from 'react';
import '../../../../static/styles/mievolucion/myPerformance/frequent-questions.less';
import { v4 as uuidv4 } from 'uuid';
import CollapseInfo from '../../../shared/collapses/InfoCollapse';
import {
  preguntasGenerales, fijacionObjetivos, seleccionCompetenciasTrabajar, redFeedback
} from './questions';

const MyPerformance = () => (
  <div className="ml-mievolucion-migestion-frequent-questions">
    <div className="preguntas-content">
      <p className="preguntas-title">Preguntas Generales</p>
      {
        preguntasGenerales.map((question) => (
          <CollapseInfo data={question} key={uuidv4()}/>
        ))
      }
    </div>
    <div className="preguntas-content">
      <p className="preguntas-title">Fijación Objetivos</p>
      {
        fijacionObjetivos.map((question) => (
          <CollapseInfo data={question} key={uuidv4()}/>
        ))
      }
    </div>
    <div className="preguntas-content">
      <p className="preguntas-title">Selección  de Competencias a trabajar</p>
      {
        seleccionCompetenciasTrabajar.map((question) => (
          <CollapseInfo data={question} key={uuidv4()}/>
        ))
      }
    </div>
    <div className="preguntas-content">
      <p className="preguntas-title">Red de Feedback</p>
      {
        redFeedback.map((question) => (
          <CollapseInfo data={question} key={uuidv4()}/>
        ))
      }
    </div>
  </div>
);

export default MyPerformance;

import React from 'react';
import PropTypes from 'prop-types';

const GenerateReport = ({
  loading,
  onRefetch,
  label,
  iconName,
  setName,
  title
}) => (<>
  <div className="gen-rep">
    <span
      className="d-flex justify-content-between spanGenerateReport"
      onClick={() => {
        setName(title);
        return (loading ? null : onRefetch());
      }
      }
    >
      {label}
      <i className={iconName} />
    </span>
  </div>
</>);

GenerateReport.propTypes = {
  loading: PropTypes.bool,
  onRefetch: PropTypes.func,
  label: PropTypes.string,
  iconName: PropTypes.string
};

GenerateReport.defaultProps = {
  loading: true,
  onRefetch: () => { },
  label: 'Generar Reporte',
  iconName: 'icon-ml-file-download'
};

export default GenerateReport;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import { getRegionsAPI, getCommunesAPI, getCountriesAPI } from '../api/commons';
// eslint-disable-next-line import/named
import {
  actionTypes,
  getRegionsSuccess,
  getRegionsError,
  getCommunesSuccess,
  getCommunesError,
  getCountriesSuccess,
  getCountriesError
} from '../actions/commons';

es6promise.polyfill();

function* getRegions() {
  try {
    const response = yield call(getRegionsAPI);
    yield put(getRegionsSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getRegionsError(error));
  }
}
function* getCommunes() {
  try {
    const response = yield call(getCommunesAPI);
    yield put(getCommunesSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getCommunesError(error));
  }
}

function* getCountries() {
  try {
    const response = yield call(getCountriesAPI);
    yield put(getCountriesSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getCountriesError(error));
  }
}

function* commonsSagas() {
  yield all([takeLatest(actionTypes.GET_REGIONS, getRegions)]);
  yield all([takeLatest(actionTypes.GET_COMMUNES, getCommunes)]);
  yield all([takeLatest(actionTypes.GET_COUNTRIES, getCountries)]);
}

export default commonsSagas;

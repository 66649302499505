import { gql } from 'graphql-tag';

export const SEARCH_AUDITS = gql`
query searchAudits($input: AuditQuery) {
  searchAudits (input:$input) {
    __typename
    userRut
    user {
      name
      secondName
      fatherLastName
      motherLastName
    }
    date
    module
    operation
    ...on UploadAudit {
      data {
        title
        department
        type
        fileName
        registries
      }
    }
  }
}
`;

export const GET_TEAM = gql`
query getDashboardEvaluationsUsers ($input: DashboardEvaluationInput!) {
  getDashboardEvaluationsUsers (input: $input) {
   userList{name,position,autoevaluationState,performanceState,potentialState}
   userListEdd{code,cant,children{user}}
  }
}
`;

export const GET_PERFORMANCE_CATEGORIES = gql`
query getDashboardEvaluationsPerformanceCategories ($input: PerformaceCategoriesInput!) {
  getDashboardEvaluationsPerformanceCategories (input: $input) {
    type
    name
    year
    score
    percent
    description
    objectivesDescription
  }
}
`;

export const GET_TEAM_VALIDATOR = gql`
query getDashboardValidationsUsers ($input: DashboardValidationInput!) {
  getDashboardValidationsUsers (input: $input) {
   userListValidation{
    evaluator
    name
    position
    performanceState
    potentialState
  }
  userListEddValidation{
    code
    cant
    children{
      user
    }
  }
  }
}
`;

export const GET_DASHBOARD_EVALUATION_FILTER = gql`
query getDashboardEvaluationsFilter ($input: DashboardEvaluationFilterInput!) {
  getDashboardEvaluationsFilter (input: $input) {
    name
    rut
    npres
    id
  }
}
`;

export const GET_DASHBOARD_VALIDATION_FILTER = gql`
query getDashboardValidationsFilter ($input: DashboardValidationFilterInput!) {
  getDashboardValidationsFilter (input: $input) {
    name
    rut
    npres
    id
  }
}
`;

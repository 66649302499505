import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import '../../static/styles/shared/MLNotFound.less';
import { completePath } from '../../helpers/strings';

const MLNotFound = ({
  title, description, button, img, className
}) => (
  <div className={`not-found ${className}`}>
    {img && <img src={`${completePath(img)}`} alt="Element not found."/>}
    <b>{title}</b>
    <p dangerouslySetInnerHTML={{ __html: description }} />
    {button && (
      <Link to={button.route}>
        <Button type="primary">
          {button.text}
        </Button>
      </Link>
    )}
  </div>
);

MLNotFound.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.object,
  img: PropTypes.string,
  className: PropTypes.string
};

MLNotFound.defaultProps = {
  title: '',
  description: '',
  button: null,
  img: '',
  className: ''
};

export default MLNotFound;

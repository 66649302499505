import React from 'react';
import { Input, Tooltip } from 'antd-v4';
import CollapsablePanel from '../../../../../../shared/CollapsablePanel';

const ResumeForm = ({
  strengths, opportunities, onChange, canEdit, isAutoevaluation, autoevaluation, isMobile
}) => (<div className='py-4 rounded resume-form'>
  <div className='title'>
    <p className='mb-2'><b>Fortalezas</b>{' '}
      <Tooltip
        placement='right'
        title='Escribe una fortaleza e indica por qué la consideras así, aportando cómo podría potenciarla.'
      >
        <i className="icon-me-info-regular primary-icon-color size-icon-info" />
      </Tooltip>
    </p>
  </div>
  <Input.TextArea
    className={isMobile && 'mb-4'}
    bordered
    maxLength={600}
    showCount={!isMobile}
    value={strengths}
    onChange={(e) => onChange({ strengths: e.target.value })}
    placeholder='Ej: "destaco tu capacidad de influir positivamente en otros, logrando acuerdos entr..."'
    disabled={!canEdit}
  />
  {!isAutoevaluation && <CollapsablePanel text='fortalezas de autoevaluación' className='mt-4' openCollapsed={true}>
    <Input.TextArea
      bordered
      disabled
      value={autoevaluation?.strengths || 'El colaborador no se ha autoevaluado'}
    />
  </CollapsablePanel>}
  <div className='title'>
    <p className='mb-2 mt-4'><b>Oportunidades de mejora</b>{' '}
      <Tooltip
        placement='right'
        title='Escribe los aspectos que consideras que podría mejorar según sus características y sugiérele cómo podría hacerlo.'
      >
        <i className="icon-me-info-regular primary-icon-color size-icon-info"/>
      </Tooltip>
    </p>
  </div>
  <Input.TextArea
    bordered
    maxLength={600}
    showCount={!isMobile}
    value={opportunities}
    onChange={(e) => onChange({ opportunities: e.target.value })}
    placeholder='Ej: "tienes dificultades a la hora de priorizar y poner foco, lo que hace que dediques tiempo a tar..."'
    disabled={!canEdit}
  />
  {!isAutoevaluation && <CollapsablePanel text='oportunidades de autoevaluación' className='mt-4' openCollapsed={true}>
    <Input.TextArea
      bordered
      disabled
      value={autoevaluation?.opportunities || 'El colaborador no se ha autoevaluado'}
    />
  </CollapsablePanel>}
</div>);

export default ResumeForm;

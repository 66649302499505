export const actionTypes = {
  LOGIN: 'LOGIN',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_BLOCKED: 'LOGIN_BLOCKED',
  CLEAR_LOGIN_BLOCKED: 'CLEAR_LOGIN_BLOCKED',
  PROFILE_UPDATE: 'PROFILE_UPDATE',
  PROFILE_UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
  GET_USER: 'GET_USER',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILED: 'GET_USER_FAILED',
  DISMISS_MESSAGE: 'DISMISS_MESSAGE',
  UPDATE_MIS_DATOS: 'UPDATE_MIS_DATOS',
  UPDATE_MIS_DATOS_SUCCESS: 'UPDATE_MIS_DATOS_SUCCESS',
  CLEAN_MESSAGE: 'CLEAN_MESSAGE',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_BENEFITS_RESUME: 'GET_BENEFITS_RESUME',
  GET_BENEFITS_RESUME_SUCCESS: 'GET_BENEFITS_RESUME_SUCCESS',
  MIS_DATOS_LOADING: 'MIS_DATOS_LOADING',
  MIS_DATOS_LOADING_DISMISS: 'MIS_DATOS_LOADING_DISMISS',
  GET_MY_CREDENTIAL_PROFILE: 'GET_MY_CREDENTIAL_PROFILE',
  GET_MY_CREDENTIAL_PROFILE_SUCCESS: 'GET_MY_CREDENTIAL_PROFILE_SUCCESS',
  GET_MY_CREDENTIAL_PROFILE_ERROR: 'GET_MY_CREDENTIAL_PROFILE_ERROR',
  GET_USER_CREDENTIAL_ADMIN: 'GET_USER_CREDENTIAL_ADMIN',
  GET_USER_CREDENTIAL_ADMIN_SUCCESS: 'GET_USER_CREDENTIAL_ADMIN_SUCCESS',
  GET_USER_CREDENTIAL_ADMIN_ERROR: 'GET_USER_CREDENTIAL_ADMIN_ERROR'
};

export function login(payload) {
  return { type: actionTypes.LOGIN, payload };
}

export function updateMisDatos(data) {
  return {
    type: actionTypes.UPDATE_MIS_DATOS,
    payload: data
  };
}

export function updateMisDatosSuccess(data) {
  return {
    type: actionTypes.UPDATE_MIS_DATOS_SUCCESS,
    payload: data
  };
}

// Login
export function loginSuccess(data) {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    data
  };
}
export function loginFailed(error) {
  return {
    type: actionTypes.LOGIN_FAILED,
    error
  };
}

export function getUserFailed() {
  return {
    type: actionTypes.GET_USER_FAILED
  };
}

export function logout() {
  return {
    type: actionTypes.LOGOUT
  };
}

export function profileUpdate(payload) {
  return {
    type: actionTypes.PROFILE_UPDATE,
    payload
  };
}

export function profileUpdateSuccess(data) {
  return {
    type: actionTypes.PROFILE_UPDATE_SUCCESS,
    data
  };
}

export function getUser() {
  return {
    type: actionTypes.GET_USER
  };
}

export function getUserSuccess(data) {
  return {
    type: actionTypes.GET_USER_SUCCESS,
    loading: false,
    data
  };
}

export function dismissMessage() {
  return {
    type: actionTypes.DISMISS_MESSAGE
  };
}

export function cleanMessage() {
  return {
    type: actionTypes.CLEAN_MESSAGE
  };
}

export function getNotifications(payload) {
  return {
    type: actionTypes.GET_NOTIFICATIONS,
    payload
  };
}

export function getNotificationsSuccess(data) {
  return {
    type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
    data
  };
}

export function getBenefitsResume() {
  return {
    type: actionTypes.GET_BENEFITS_RESUME
  };
}

export function getBenefitsResumeSuccess(data) {
  return {
    type: actionTypes.GET_BENEFITS_RESUME_SUCCESS,
    payload: data
  };
}

export function getUserAdminCredential() {
  return {
    type: actionTypes.GET_USER_CREDENTIAL_ADMIN
  };
}

export function getUserAdminCredentialSuccess(response) {
  return {
    type: actionTypes.GET_USER_CREDENTIAL_ADMIN_SUCCESS,
    payload: response
  };
}

export function getUserAdminCredentialError() {
  return {
    type: actionTypes.GET_USER_CREDENTIAL_ADMIN_ERROR
  };
}

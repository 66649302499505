import React from 'react';
import '../../../../../static/styles/mievolucion/objectives2022/advanceModalInfo.less';
import PropTypes from 'prop-types';

const AdvanceModalInfo = ({ onClick }) => (<div className='advance-modal'>
  <span onClick={onClick}>
    <span className='advance-modal-icon-title'>Avance</span>
    <i className='icon-me-info-regular primary-icon-color size-icon-info' />
  </span>
</div>);

AdvanceModalInfo.propTypes = {
  onClick: PropTypes.func
};

AdvanceModalInfo.defaultProps = {
  onClick: () => null
};

export default AdvanceModalInfo;

import { actionTypes } from '../actions/comments';
import { actionTypes as muralActionTypes } from '../actions/mural';

export const initialState = {
  comments: {}
};

export default function commentsReducer(state = initialState, action) {
  const { payload, variables } = action;
  switch (action.type) {
  case muralActionTypes.ALL_MURAL_SUCCESS: {
    const mural = action.mural.result;
    const comments = mural.reduce((accum, post) => Object.assign(accum, {
      [post._id]: {
        adding: false,
        data: post.reply
      }
    }), {
      ...state.comments
    });

    return {
      ...state,
      comments
    };
  }
  case actionTypes.ADD_MURAL_COMMENT_POST: {
    const { postId } = variables;

    return {
      ...state,
      comments: {
        ...state.comments,
        [postId]: {
          ...state.comments?.[postId],
          adding: true,
          error: null
        }
      }
    };
  }
  case actionTypes.ADD_MURAL_COMMENT_SUCCESS: {
    const { result } = payload;

    return {
      ...state,
      comments: {
        ...state.comments,
        [result._id]: {
          ...state.comments?.[result._id],
          adding: false,
          data: result.reply
        }
      }
    };
  }
  case actionTypes.ADD_MURAL_COMMENT_ERROR: {
    const { code, result } = payload;

    return {
      ...state,
      comments: {
        ...state.comments,
        [result._id]: {
          adding: false,
          error: { code }
        }
      }
    };
  }
  case actionTypes.RESET_COMMENTS:
    return {
      ...initialState
    };
  default:
    return state;
  }
}

import React, { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { find } from 'lodash';
import Loading from '../../../../../containers/loading';
import Message from '../../../../shared/InfoMessage';
import MessageCard from '../../../../shared/MessageCard';
import MLNotFound from '../../../../shared/MLNotFound';
import MLModalWithFixedLayout from '../../../../shared/MLModal/WithFixedLayout';
import AddObjective from './forms/AddObjective';
import MyPerformanceTwoGroupsTable from './MyPerformanceTwoGroupsTable';
import SendToBoss from './SendToBoss';
import BossButtons from './BossButtons';
import { DescriptionObjectives } from './info-messages';
import WeightsTotalAndByPillar from './WeightsTotalAndByPillar';
import '../../../../../static/styles/mievolucion/objectives2022/styles.less';
import getStepsData from '../../../../../helpers/myEvolution/myPerformance/getStepsData';
import ErrorMessage from '../../../../shared/ErrorMessage';

const TableComponent = ({
  tableProps, isMobile, refetch
}) => {
  const commentFormRef = useRef(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const {
    can,
    bossParams,
    myPerformance,
    performancePillars,
    bossName,
    pillarsWeights,
    totalWeights,
    objectivesError,
    emptyStateProps
  } = tableProps;

  const {
    callApi: performanceWalmart,
    data: dataPerformanceWalmart,
    loading: loadingPerformanceWalmart,
    error
  } = myPerformance;

  const dataPillarsWalmart = performancePillars.data;

  const handleExpand = (expanded, user) => {
    const keys = [];
    if (expanded) {
      keys.push(user.key);
      setExpandedRowKeys([...keys]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const onAddObjectiveSubmit = ({ events }) => {
    events.closeModal();
    performanceWalmart();
  };
  if (error) {
    return <ErrorMessage message={'Ocurrió un error al cargar el performance del colaborador'}/>;
  }

  if (!can.showContainer) return <Fragment/>;
  const { stepLabels, step } = getStepsData();
  const titlePerformance = find(stepLabels, ({ value }) => value === step)?.label || 'Pulso';
  return (
    <div className='container-performance'>
      <div className='ml-my-evolution-my-performance-mo'>
        <div className='mo-title'>
          {can.showWithPulses ? titlePerformance : 'Mis Objetivos'}
        </div>

        <div className='mo-separator'/>

        {can.showInfoMessage && <Message
          classes='full-width'
          color='warning'
          message={<DescriptionObjectives {...{
            can, bossName, isMobile, bossParams
          }}/>}
          suave={true}
        />}

        {can.showReturnedInfoMessage && (
          <MessageCard {...{
            className: 'rejected-status-message-card',
            name: 'Motivo de devolución',
            description: dataPerformanceWalmart?.status?.commentByChangedStatus,
            type: 'error',
            backgroundColorLevel: 2
          }}/>
        )}

        {can.showPerformanceTable && (
          <WeightsTotalAndByPillar
            isMobile={isMobile}
            pillarsWeights={pillarsWeights}
            totalWeights={totalWeights}
          />
        )}

        <Row>
          <Col>
            {loadingPerformanceWalmart ? (
              <Loading/>
            ) : (
              <>
                {can.showPerformanceTable ? (
                  <MyPerformanceTwoGroupsTable
                    livePulse={can.livePulse}
                    showBossInputComment={can.showBossInputComment}
                    withPulseAndManualObjective={can.withPulseAndManualObjective}
                    actionsVisible={(!bossParams && can.actionsVisible)}
                    editAdvance={can.editAdvance}
                    showComments={can.showComments}
                    closePerformance={can.closePerformance}
                    disabled={!can.editObjectives}
                    objectiveClosedButAdvanceNotSent={can.objectiveClosedButAdvanceNotSent}
                    bossName={bossName}
                    dataPerformanceWalmart={dataPerformanceWalmart}
                    expandedRowKeys={expandedRowKeys}
                    handleExpand={handleExpand}
                    isMobile={isMobile}
                    refetch={performanceWalmart}
                    visibleReport={can.showVisibleReport}
                    commentFormRef={commentFormRef}
                    bossParams={bossParams}
                    addButton={isMobile
                      ? <></>
                      : (
                        <>
                          {can.addObjectives && (
                            <div className='text-center add-objective-button-wrapper'>
                              <MLModalWithFixedLayout {...{
                                FormComponent: (props) => (
                                  <AddObjective {
                                    ...{
                                      ...props,
                                      currentQuarter: dataPerformanceWalmart?.quarterCurrent,
                                      srcData: {
                                        dataPerformanceWalmart,
                                        dataPillarsWalmart,
                                        currentQuarter: dataPerformanceWalmart.quarterCurrent
                                      }
                                    }
                                  }/>
                                ),
                                okButtonText: 'Guardar y salir',
                                onFormSubmit: onAddObjectiveSubmit,
                                openButtonText: 'Agregar nuevo objetivo',
                                title: 'Agregar nuevo objetivo',
                                cancelConfirm: {
                                  title: '¿Quieres salir sin guardar los cambios?',
                                  message: 'Perderás la información que hayas ingresado.'
                                }
                              }}/>
                            </div>
                          )}
                        </>
                      )
                    }
                  />
                ) : (
                  <MLNotFound {...emptyStateProps}/>
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
      <div className='text-center'>
        { (isMobile && !can.notStartPerformance) || (can.sendToBoss && (
          <SendToBoss {...{
            bossName,
            error: objectivesError?.data,
            refetch: performanceWalmart,
            commentFormRef,
            performance: dataPerformanceWalmart

          }}/>)
        )}
      </div>

      {can.closePerformance && (
        <BossButtons {...{
          bossParams,
          commentFormRef,
          refetch: () => {
            performanceWalmart();
            refetch();
          }
        }}/>
      )}
    </ div>
  );
};

TableComponent.propTypes = {
  tableProps: PropTypes.object,
  isMobile: PropTypes.bool,
  refetch: PropTypes.func
};

TableComponent.defaultProps = {
  tableProps: null,
  isMobile: false,
  refetch: () => null
};

export default TableComponent;

export const actionTypes = {
  FAILURE: 'FAILURE',
  GET_AGREEMENTS: 'GET_AGREEMENTS',
  GET_AGREEMENT: 'GET_AGREEMENT',
  GET_AGREEMENTS_SUCCESS: 'GET_AGREEMENTS_SUCCESS',
  GET_AGREEMENT_SUCCESS: 'GET_AGREEMENT_SUCCESS'
};

export function failure(error) {
  return {
    type: actionTypes.FAILURE,
    error
  };
}

export function getAgreement(item) {
  return {
    type: actionTypes.GET_AGREEMENT,
    item
  };
}

export function getAgreements() {
  return { type: actionTypes.GET_AGREEMENTS };
}

export function getAgreementSuccess(data) {
  return {
    type: actionTypes.GET_AGREEMENT_SUCCESS,
    data
  };
}

export function getAgreementsSuccess(data) {
  return {
    type: actionTypes.GET_AGREEMENTS_SUCCESS,
    data
  };
}

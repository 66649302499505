import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import 'isomorphic-unfetch';
import { get } from 'lodash';
import adLogout from '../../helpers/adLogout';
import * as storageUtil from '../../helpers/cookiesUtil';

import { getMyBenefitsRequested, getTeamRequests } from '../api/benefits';

import {
  getBenefitsResumeAPI,
  getUserAPI,
  loginAPIToken,
  updateMisDatosAPI,
  updateProfileAPI,
  userAdminCredentialsAPI,
  logOut
} from '../api/user';

import {
  actionTypes,
  getBenefitsResumeSuccess,
  getNotificationsSuccess,
  getUserFailed,
  getUserSuccess,
  loginFailed,
  loginSuccess,
  profileUpdateSuccess,
  updateMisDatosSuccess,
  getUserAdminCredentialSuccess,
  getUserAdminCredentialError
} from '../actions/user';

import { failure, loading, loadComplete } from '../actions/commons';
import { getEnvVar } from '../../helpers/commons';
import { logEvent, initGA } from '../../helpers/googleAnalytics';

const REACT_APP_GA_TRACKING_CODE = getEnvVar('REACT_APP_GA_TRACKING_CODE');

es6promise.polyfill();

const setAuthItems = (data) => {
  storageUtil.setItem('token', data.access_token);
  storageUtil.setItem('user', data.username);
  storageUtil.setItem('idBff', data.idBff || '9999999999999999');
  storageUtil.setItem('push_public_key', data.pushPublickKey); // fix this data
  storageUtil.setItem('id_token', data.id_token);
  storageUtil.setItem('scope', data.scope);
  storageUtil.setItem('expires_in', data.expires_in);
  storageUtil.setItem('token_type', data.token_type);

  if (data.redirectToCredProduct) {
    storageUtil.setItem('redirectToCredProduct', data.redirectToCredProduct);
  }

  if (data.redirectToMio) {
    storageUtil.setItem('redirectToMio', data.redirectToMio);
  }
};

function* login(actions) {
  try {
    const { deviceInfo, code } = actions.payload;
    yield put(loading());
    const dataLoginAPIToken = yield call(loginAPIToken, { code, deviceInfo });
    const data = get(dataLoginAPIToken, 'data', {});

    data.username = data.rut;

    setAuthItems(data);

    yield put(loginSuccess(data));
    yield put(loadComplete());
    initGA(REACT_APP_GA_TRACKING_CODE);
    logEvent('Home Mi Lugar', 'Login', 'Login Exitoso');
  } catch (err) {
    const error = get(err, 'response.data', 'Ha ocurrido un error');
    if (/intentos fallidos/gi.test(error)) {
      yield put(loginFailed({ info: null }));
    } else {
      const data = get(err, 'response.data', 'Ha ocurrido un error');
      yield put(loginFailed({ info: data }));
      const statusCode = get(err, 'response.status') || 401;
      yield put(failure({ error: { error: true, info: data }, statusCode }));
    }
  }
}

function* logout() {
  try {
    yield call(logOut);
    adLogout();
  } catch (err) {
    yield put(failure());
  }
}

function* updateProfile(actions) {
  try {
    yield put(loading());
    const response = yield call(updateProfileAPI, actions.payload);
    yield put(profileUpdateSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* getUser() {
  try {
    const response = yield call(getUserAPI);
    response.username = response.rut;
    if (!storageUtil.getItem('token')) setAuthItems(response);
    yield put(getUserSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status') || 401;
    yield put(getUserFailed());
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* updateMisDatos(action) {
  try {
    yield put(loading());
    const data = action.payload;
    const resp = yield call(updateMisDatosAPI, { data });
    yield put(updateMisDatosSuccess(resp));
    yield put(loadComplete());
  } catch (err) {
    const data = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error: { error: true, message: data }, statusCode }));
    yield put(loadComplete());
  }
}

function* getNotifications(actions) {
  try {
    yield put(loading());
    const user = storageUtil.getItem('user');
    const mioParams = { ...actions.payload, rut: user };
    const teamRequests = yield call(getTeamRequests, mioParams);
    const myRequests = yield call(getMyBenefitsRequested, mioParams);
    const notifications = {
      mio: {
        teamRequests,
        myRequests
      }
    };
    yield put(getNotificationsSuccess(notifications));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* getBenefitsResume() {
  try {
    yield put(loading());
    const { data } = yield call(getBenefitsResumeAPI);
    yield put(getBenefitsResumeSuccess(data));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* getUserAdminCredentialSagas() {
  try {
    const response = yield call(userAdminCredentialsAPI);
    yield put(getUserAdminCredentialSuccess(response));
  } catch (err) {
    yield put(getUserAdminCredentialError(err));
  }
}

function* userSagas() {
  yield all([
    takeLatest(actionTypes.LOGIN, login),
    takeLatest(actionTypes.LOGOUT, logout),
    takeLatest(actionTypes.PROFILE_UPDATE, updateProfile),
    takeLatest(actionTypes.GET_USER, getUser),
    takeLatest(actionTypes.UPDATE_MIS_DATOS, updateMisDatos),
    takeLatest(actionTypes.GET_NOTIFICATIONS, getNotifications),
    takeLatest(actionTypes.GET_BENEFITS_RESUME, getBenefitsResume),
    takeLatest(
      actionTypes.GET_USER_CREDENTIAL_ADMIN,
      getUserAdminCredentialSagas
    )
  ]);
}

export default userSagas;

import { actionTypes } from '../actions/searchUser';

export const initialState = {
  data: [],
  error: false,
  loading: false
};

function searchUserReducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
  case actionTypes.SEARCH_USER:
    return {
      ...state,
      loading: true
    };
  case actionTypes.SEARCH_USER_SUCCESS:
    return {
      ...state,
      data: payload.result,
      error: false,
      loading: false
    };
  case actionTypes.SEARCH_USER_ERROR:
    return {
      ...state,
      error: { code: payload.code },
      loading: false
    };
  case actionTypes.SEARCH_USER_RESET:
    return { ...initialState };
  default:
    return state;
  }
}

export default searchUserReducer;

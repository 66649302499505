import React from 'react';
import '../../../static/styles/login/login-help/ml-first-login.less';

const FirstLogin = () => (
  <div className='ml-first-login'>
    <span>
      Para poder ingresar a Mi Lugar debe haber
    </span>

    <span>
      {' '}transcurrido al menos 1
    </span>

    <span>
      {' '}día desde tu fecha de ingreso a la compañía.
    </span>

    <p>
      Debes ingresar con tu usuario Walmart, previamente enrolado en un computador dentro de
      {' '}la red de internet de Walmart.
    </p>

    <p>
      Si nunca has cambiado tu contraseña, primero debes solicitar a tu jefatura que te comparta
      {' '}la contraseña y luego debes cambiarla en un computador conectado a la red de internet Walmart.
    </p>

    <p>
      Si tienes algún problema con el inicio de sesión, solicita ayuda a tu jefatura o Lider de
      {' '}Personas.
    </p>

    <span>
      Si transcurrieron más de 3 meses desde que ingresaste a la compañía y no has cambiado tu
      {' '}contraseña, ésta expiró. Para solucionarlo, ponte en contacto con la
    </span>

    <span>
      {' '}Mesa de Ayuda
    </span>

    <span>
      {' '}llamando al
    </span>

    <span>
      {' '}+56222005115.
    </span>
  </div>
);

export default FirstLogin;

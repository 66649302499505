import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Form, Col, Row } from 'antd';
import InfoMessage from '../../../../../shared/InfoMessage';
import ObjectiveWeight from './shared/ObjectiveWeight';
import HideBossObjectives from './shared/HideBossObjectives';
import RenderField from './shared/RenderField';
import SuccessMeasures from './shared/SuccessMeasures';
import ModalMessage from '../../../../../shared/ModalMessage';
import { UPDATE_OBJECTIVE_WALMART } from '../../../../../../graphql/mievolucion/myPerformance/mutations';
import { GET_NOTIFICATIONS } from '../../../../../../graphql/notifications/queries';
import '../../../../../../static/styles/mievolucion/myPerformance/objective-form.less';
import setObjectiveFormDefaults from '../../../../../../helpers/myEvolution/myPerformance/setObjectiveFormDefaults';
import getInputForEditObjective from '../../../../../../helpers/myEvolution/myPerformance/getInputForEditObjective';
import {
  ERROR_MESSAGE_MAX_SUCCESS_MEASURES,
  MAX_SUCCESS_MEASURES,
  TITLE_ERROR_MAX_SUCCESS_MEASURES,
  getNumberOfActivesSuccessMeasures
} from '../../../../../../helpers/myEvolution/myPerformance';

const FormItem = Form.Item;

const EditObjective = ({
  form, formRef, srcData, onSubmit, currentQuarter
}) => {
  const [modalMessage, setModalMessage] = useState({ visible: false });
  const [updateObjective] = useMutation(
    UPDATE_OBJECTIVE_WALMART,
    {
      context: { clientName: 'myEvolution' },
      refetchQueries: [
        {
          query: GET_NOTIFICATIONS
        }
      ]
    }
  );
  const { validateFieldsAndScroll, getFieldDecorator } = form;

  const {
    objectiveName,
    objectiveDescription,
    successMeasuresBoss,
    boss: { name: bossName },
    successMeasuresOnly
  } = srcData;

  const handleSubmit = (events) => {
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          const { successMeasures } = values;
          const activesQty = getNumberOfActivesSuccessMeasures(currentQuarter, successMeasures);

          if (activesQty > MAX_SUCCESS_MEASURES) {
            setModalMessage({
              visible: true,
              title: TITLE_ERROR_MAX_SUCCESS_MEASURES,
              message: ERROR_MESSAGE_MAX_SUCCESS_MEASURES,
              type: 'error'
            });
          } else {
            const input = getInputForEditObjective(values);
            events.startLoading(values);
            await updateObjective({ variables: { input } });

            onSubmit({ values, events });
          }
        } catch (error) {
          events.endLoading();
          setModalMessage({
            visible: true,
            title: 'Ha ocurrido un error',
            message: error?.message || 'Por favor, vuelva a intentar mas tarde.',
            type: 'error'
          });
        }
      }
    });
  };

  return (
    <Form
      ref={formRef}
      className='ml-performance-objective-form'
      onSubmit={handleSubmit}
    >
      <FormItem className='hidden'>{getFieldDecorator('objectiveDataID')}</FormItem>

      <HideBossObjectives {...{ form, FormItem, successMeasuresOnly }}/>

      <Row type='flex'>
        <i className='icon-ml-objetivo color-objective'/>
        <RenderField {...{ label: 'Objetivo', value: objectiveName }}/>
      </Row>

      {objectiveDescription && <RenderField {...{ label: 'Descripción', value: objectiveDescription }}/>}

      <div className='objectives-edit-form-fields'>
        <Row type='flex'>
          <Col span={12}>
            <ObjectiveWeight {...{ form, FormItem, successMeasuresOnly }}/>
          </Col>

          {srcData.weightBoss && <Col span={12}>
            <RenderField {...{
              label: `Ponderación de ${bossName}`, value: srcData.weightBoss, direction: 'column', className: 'boss-weight-render-field'
            }}/>
          </Col>}
        </Row>
      </div>

      <div className='objectives-edit-form-fields'>
        <Row type='flex'>
          <i className='icon-ml-indicador color-indicator'/>
          <label>Medidas de éxito{!successMeasuresOnly && ' (máximo 5)'}:</label>
        </Row>

        {!successMeasuresOnly && !!successMeasuresBoss.length && (
          <InfoMessage
            suave
            classes='full-width'
            color='warning'
            message={`Puedes ver las que definió ${bossName} para guiarte. También crear nuevas`}
          />
        )}

        {successMeasuresOnly && (
          <InfoMessage
            suave
            classes='full-width'
            color='info'
            message={`Puedes tener hasta ${MAX_SUCCESS_MEASURES} medidas de éxito activas en cada Pulso. Agrega su avance para tener un mejor seguimiento de ellas.`}
          />
        )}

        <SuccessMeasures {...{
          form,
          FormItem,
          successMeasuresBoss,
          defaultData: srcData.successMeasures,
          bossName,
          successMeasuresOnly
        }}/>
      </div>

      <ModalMessage {...{
        ...modalMessage,
        closeModal: () => setModalMessage({ ...modalMessage, visible: false })
      }}/>
    </Form>
  );
};

EditObjective.propTypes = {
  form: PropTypes.object,
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
  srcData: PropTypes.object,
  values: PropTypes.object,
  currentQuarter: PropTypes.string
};

EditObjective.defaultProps = {
  form: {},
  formRef: null,
  onSubmit: () => null,
  srcData: {},
  values: null,
  currentQuarter: null
};

export default Form.create({
  mapPropsToFields(props) {
    const currentQuarter = props.srcData?.currentQuarter;
    const srcData = { ...(props.values || props.srcData), currentQuarter };

    return setObjectiveFormDefaults({ srcData, Form });
  }
})(EditObjective);

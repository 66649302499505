import React from 'react';
import '../../static/styles/shared/dot.less';

const MLDot = ({ active }) => {
  const getClassName = () => {
    let classNameResult = 'ml-dot';
    if (active) classNameResult += ` ${classNameResult}-active`;
    return classNameResult;
  };

  return <div className={getClassName()}/>;
};

export default MLDot;

import { lazy } from 'react';

const MyEvolutionTeamEvaluationsPerformance = lazy(() => import('../../../../../containers/myTeam/myEvolution/Evaluations/Performance'));
const HistoricalMyTeamPerformanceEvaluation = lazy(() => import('../../../../../components/myTeam/myEvolution/evaluations/Performance/index'));
const SubordinateSelected = lazy(() => import('../../../../../components/myTeam/myEvolution/evaluations/Performance/Historical/SubordinateSelected'));

export default [
  {
    path: '/mi-equipo/mi-evolucion/evaluaciones/desempeño',
    section: 'Mi Equipo - Mi Evolución - Evaluaciones - Desempeño',
    component: MyEvolutionTeamEvaluationsPerformance,
    subRoutes: [
      {
        tabTitle: 'Evaluaciones',
        path: '/mi-equipo/mi-evolucion/evaluaciones/desempeño/evaluaciones',
        component: (props) => <HistoricalMyTeamPerformanceEvaluation {...props} />,
        subRoutes: [
          {
            exact: true,
            headerTitle: null,
            tabTitle: 'Colaborador Individual',
            path: '/mi-equipo/mi-evolucion/evaluaciones/desempeño/evaluaciones',
            component: (props) => <SubordinateSelected {...props} />
          }
        ]
      },
      {
        tabTitle: 'Validaciones',
        auth: 'MY_TEAM_MY_EVOLUTION_EVALUATIONS_VALIDATOR',
        path: '/mi-equipo/mi-evolucion/evaluaciones/desempeño/validaciones',
        component: (props) => <HistoricalMyTeamPerformanceEvaluation {...props} />,
        subRoutes: [
          {
            exact: true,
            headerTitle: null,
            auth: 'MY_TEAM_MY_EVOLUTION_EVALUATIONS_VALIDATOR',
            tabTitle: 'Colaborador Individual',
            path: '/mi-equipo/mi-evolucion/evaluaciones/desempeño/validaciones',
            component: (props) => <SubordinateSelected {...props} />
          }
        ]
      }
    ]
  }
];

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import sendInfoAPI from '../api/contact';
import {
  actionTypes,
  sendContactInfoSuccess,
  getContactDataSuccess,
  getContactDataFailure,
  updateContactDataSuccess,
  updateContactDataFailure
} from '../actions/contact';
import { failure, loading, loadComplete } from '../actions/commons';

import {
  getContactDataAPI,
  updateContactDataAPI
} from '../api/profile';

es6promise.polyfill();

function* getContactData() {
  try {
    const response = yield call(getContactDataAPI);
    yield put(getContactDataSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(getContactDataFailure());
    yield put(failure({ error: { error: true, message: error }, statusCode }));
    yield put(loadComplete());
  }
}

function* updateContactData(action) {
  try {
    yield put(loading());
    const data = action.payload;
    const resp = yield call(updateContactDataAPI, data);
    yield put(updateContactDataSuccess(resp));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(updateContactDataFailure(error));
    yield put(failure({ error: { error: true, message: error }, statusCode }));
    yield put(loadComplete());
  }
}

export function* sendContactInfo(action) {
  try {
    yield put(loading());
    yield call(sendInfoAPI, action.payload);
    yield put(sendContactInfoSuccess());
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));

    yield put(loadComplete());
  }
}

function* contactSagas() {
  yield all([
    takeLatest(actionTypes.GET_CONTACT_DATA, getContactData),
    takeLatest(actionTypes.UPDATE_CONTACT_DATA, updateContactData),
    takeLatest(actionTypes.SEND_CONTACT_INFO, sendContactInfo)
  ]);
}

export default contactSagas;

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  isMobile,
  isBrowser,
  isSafari,
  isAndroid
} from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import '../../static/styles/pwa-install/style.less';
import '../../static/styles/milugar-layout/header.less';
import Header from '../../components/milugar-layout/header';
import NotSafari from '../../components/pwa-install/NotSafari';
import Android from '../../components/pwa-install/Android';
import InstallationSteps from '../../components/pwa-install/InstallationSteps';
import getURLParams from '../../helpers/routing/getURLParams';

const PWInstall = ({ goBack }) => {
  const query = getURLParams();
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const from = query.get('from');
  useEffect(() => {
    if (isBrowser) {
      history.push('/');
    }
  });

  return (
    <div id="milugar">
      <div className="pwa-install">
        <Header
          customGoBack={goBack}
          showBack={from !== 'expo'}
          title={'Instalar Nueva Versión de Mi Lugar'}/>
        { isMobile && isAndroid
          ? <Android/>

          : isMobile && !isSafari
            ? <NotSafari
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
            />
            : <InstallationSteps />
        }
      </div>
    </div>
  );
};

export default PWInstall;

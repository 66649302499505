import React from 'react';
import PropTypes from 'prop-types';
import {
  map, sumBy
} from 'lodash';
import Objective from './Objective';

const ObjectivesTable = (props) => {
  const {
    title,
    objectives,
    className
  } = props;
  if (objectives?.length === 0) return null;
  const getObjectivesPonderation = () => {
    const listObjectives = map(objectives, ({ weight = 0 }) => weight);
    return Math.round(sumBy(
      listObjectives, (weight) => weight
    ));
  };

  return (
    <div className={`container-table ${className}`}>
      <div className={'container-header'}>
        <div className='header-summary left'>
          <span>{ `${title} | ${getObjectivesPonderation(objectives)} %`}</span>
        </div>
        <div className='header-summary right'>
          <span>Categorías</span>
        </div>
      </div>
      {map(objectives, (item, i) => (
        <Objective key={i}
          objectiveInfo={item}
          indexToShowInLabel={i}
        />
      ))}
    </div>
  );
};

ObjectivesTable.propTypes = {
  title: PropTypes.string,
  objectives: PropTypes.array,
  className: PropTypes.string
};

ObjectivesTable.defaultProps = {
  title: '',
  objectives: [],
  className: ''
};

export default ObjectivesTable;

import { gql } from '@apollo/client';

export const SAVE_OBJECTIVE = gql`
    mutation saveObjective(
        $rut: String!
        $description: String!
        $indicator: String!
        $weight: Float!
        $dateLimit: Date!
        $pillar: String!
        $_id: ID
    ) {
        saveObjective
        (
            objective: {
                rut: $rut
                description: $description
                indicator: $indicator
                weight: $weight
                dateLimit: $dateLimit
                pillar: $pillar
                _id: $_id
            }
        ) {
            _id
        }
    }
`;

export const PUBLISH_OBJECTIVES = gql`
    mutation publishObjectives(
        $rut: String!
    ) {
        publishObjectives
        (
            rut: $rut
        )
    }
`;

export const DELETE_OBJECTIVE = gql`
    mutation deleteObjective(
        $ID: ID!
    ) {
        deleteObjective
        (
            ID: $ID
        )
    }
`;

export const CREATE_OBJECTIVE = gql`
mutation createObjective($rut: String, $input: ObjectiveInput!) {
  createObjective(rut: $rut, input: $input) {
    objective
  }
}
`;

export const DELETE_OBJECTIVE_NEW = gql`
mutation deleteObjectiveById($rut: String, $input: ObjectiveDeleteInput!) {
  deleteObjectiveById(rut: $rut, input: $input) {
    objective
  }
}
`;

export const DELETE_OBJECTIVE_WALMART = gql`
mutation deleteObjectiveWalmartById($rut: String, $input: ObjectiveDeleteInput!) {
  deleteObjectiveWalmartById(rut: $rut, input: $input) {
   objective
  }
}
`;

export const UPDATE_OBJETIVE = gql`
mutation updateObjective($rut: String, $input: ObjectiveChanceInput!) {
  updateObjective(rut: $rut, input: $input) {
    objective
  }
}
`;

export const UPDATE_INITIATIVES = gql`
mutation updateInitiative($input: InitiativeModifieInput!) {
  updateInitiative(input: $input) {
    description
  }
}
`;

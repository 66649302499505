/* eslint-disable no-console */
import React, { useReducer, useEffect } from 'react';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { orderBy } from 'lodash';

import { reducer, initialState, capitalizeFieldValuesForTable } from '../../../helpers/myEvolution/calibracion';
import { buildTable } from '../../../helpers/myEvolution/calibracion/historicalTable';
import { completePath } from '../../../helpers/strings';
import Loader from '../../../components/shared/MLLoading';
import Error from '../../../components/shared/Error';
import BannerLayout from '../../../layouts/banner';
import { GET_CALIBRATION_HISTORY } from '../../../graphql/mievolucion/calibracion/queries';
import { DELETE_CALIBRATION } from '../../../graphql/mievolucion/calibracion/mutations';

import InfoMessage from '../../../components/shared/InfoMessage';
import MainBtn from '../../../components/shared/buttons/MainBtn';
import MLAntdModalFullScreen from '../../../components/shared/MLAntdModalFullScreen';
import MLSteps from '../../../components/shared/steps/MLSteps';

import MLTable from '../../../components/shared/tables/MLTable';
import StepperOne from '../../../components/mievolucion/calibracion/StepperOne';
import StepperTwo from '../../../components/mievolucion/calibracion/StepperTwo';
import StepperThree from '../../../components/mievolucion/calibracion/StepperThree';
import ReviewTable from '../../../components/mievolucion/calibracion/ReviewTable';
import Reports from '../../../components/mievolucion/calibracion/Reports';

import '../../../static/styles/mievolucion/calibracion/styles.less';
import { GET_LIST_PERFORMANCE_CATEGORIES } from '../../../graphql/mievolucion/performanceCategories/queries';

const context = { clientName: 'myEvolution' };
const fetchPolicy = { fetchPolicy: 'network-only' };

const Main = () => {
  const [calibrationReducer, calibrationDispatch] = useReducer(reducer, initialState);
  const { step, mainModal, editing } = calibrationReducer;
  const banner = {
    imageBanner: `${completePath('web/mi-evolucion/banner/calibracion-banner.jpg')}`,
    imageAvatar: `${completePath('web/mi-evolucion/servicios/inicio/calibracion-card.jpg')}`,
    title: 'Calibración'
  };

  const [deleteCalibration, {
    loading: deleteLoading,
    error: deleteError
  }] = useMutation(DELETE_CALIBRATION, {
    context,
    onCompleted: () => {
      console.log('deleted!');
    },
    onError: () => {
      console.log('error on delete');
    }
  });

  const {
    data: { getEvaluationCalibrationsHistory: history } = {},
    loading: historyLoading,
    error: historyError,
    refetch: refetchHistory
  } = useQuery(GET_CALIBRATION_HISTORY, {
    variables: { input: { year: 2021 } },
    context,
    fetchPolicy: 'network-only'
  });

  const [getPerformanceCategoryList, {
    data: categoryListData,
    loading: categoryListDataLoading,
    error: categoryListDataError
  }] = useLazyQuery(GET_LIST_PERFORMANCE_CATEGORIES, {
    context,
    fetchPolicy,
    variables: { type: 'FOUR_C' }
  });

  const editUsers = () => {
    calibrationDispatch({ type: 'step', payload: 0 });
    calibrationDispatch({ type: 'clear-editingUsersAddDrop' });
  };

  const getProps = () => ([
    {
      title: 'Selección de Colaboradores',
      content: <StepperOne
        calibrationDispatch={calibrationDispatch}
        calibrationReducer={calibrationReducer}
      />
    },
    {
      title: 'Calibración de Desempeño',
      content: <StepperTwo
        calibrationDispatch={calibrationDispatch}
        calibrationReducer={calibrationReducer}
        editUsers={editUsers}
      />
    },
    {
      title: 'Calibración de Potencial',
      content: <StepperThree
        calibrationDispatch={calibrationDispatch}
        calibrationReducer={calibrationReducer}
        editUsers={editUsers}
        refetchHistory={refetchHistory}
      />
    }
  ]);

  const tableActions = async (calibrationId, action) => {
    switch (action) {
    case 'a-edit':
      calibrationDispatch({
        type: 'set-editing',
        payload: { editing: true, calibrationId }
      });
      calibrationDispatch({
        type: 'set-mainModal',
        payload: { visible: true, details: '' }
      });
      break;
    case 'a-delete':
      await deleteCalibration({ variables: { _id: calibrationId } });
      refetchHistory();
      break;
    default: // a-view
      calibrationDispatch({
        type: 'set-mainModal',
        payload: { visible: true, details: calibrationId }
      });
    }
  };

  const getModalTitle = () => {
    if (mainModal.details) return 'Detalle Calibración Jefes';
    return 'Editar Grilla';
  };

  const getRender = () => {
    if (historyLoading || categoryListDataLoading || deleteLoading) return <Loader />;
    if (categoryListDataError || historyError || deleteError) return <Error />;
    return (
      <div className='wrapper'>
        <InfoMessage color='warning' classes='mt-3 mb-4'>
          <p>En este módulo podrás realizar el proceso de calibración, generar las distintas
        grillas, realizar modificaciones en las evaluaciones y descargar el detalle
        calibrado. <b>Recuerda que para poder calibrar a un colaborador, éste debe estar
        evaluado tanto por desempeño como por potencial.</b></p>
        </InfoMessage>
        <Reports />
        {history && (
          <MLTable
            columnAndData={buildTable({
              data: capitalizeFieldValuesForTable(['createdBy'], history),
              actions: tableActions
            })}
          />
        )}
        <div className='d-flex justify-content-center'>
          <div className='mt-5 mr-2'>
            <MainBtn
              text='Agregar Grilla'
              width='160px'
              onClick={() => {
                calibrationDispatch({
                  type: 'set-mainModal',
                  payload: { details: '', visible: true }
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!categoryListData) {
      getPerformanceCategoryList();
    }
    if (categoryListData) {
      const performanceCategories = orderBy(categoryListData.listPerformanceCategories, ['score'], ['desc'])
        .map((v) => ({ _id: v._id, name: v.name, score: v.score }));
      calibrationDispatch({ type: 'set-performanceCategoryList', payload: performanceCategories });
    }
  }, [categoryListData]);

  return (
    <div className='d-none d-lg-block main-calibration-component'>
      <BannerLayout {...banner} childrenClassName='p-0' hideSmallDevice={true} />
      {getRender()}
      <MLAntdModalFullScreen
        title={getModalTitle()}
        visible={mainModal.visible}
        closeModal={() => {
          if (!editing) {
            refetchHistory();
            calibrationDispatch({
              type: 'set-editing',
              payload: { editing: false, calibrationId: '' }
            });
          }
          calibrationDispatch({
            type: 'set-mainModal',
            payload: { visible: false }
          });
          calibrationDispatch({ type: 'reset' });
        }}
      >
        <div className='container main-calibration-component-modal'>
          {!mainModal.details
            && (
              <MLSteps
                steps={getProps()}
                current={step}
                // the onChange event most be comment when update calibration service its done
                // onChange={(index) => calibrationDispatch({ type: 'step', payload: index })}
              />
            )
          }
          {mainModal.details
            && (
              <ReviewTable
                calibrationId={mainModal.details}
              />
            )
          }
        </div>
      </MLAntdModalFullScreen>
    </div>
  );
};

export default Main;

import { ApolloLink } from '@apollo/client';
import shouldEncode from './helpers/shouldEncode';
import encodeBase64Object from '../../helpers/encodeBase64Object';

const encodeVariablesLink = new ApolloLink((operation, forward) => {
  const context = operation.getContext();

  if (shouldEncode(context)) operation.variables = encodeBase64Object(operation.variables);

  return forward(operation);
});

export default encodeVariablesLink;

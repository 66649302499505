export const actionTypes = {
  GET_BANK_LIST: 'GET_BANK_LIST',
  GET_BANK_LIST_SUCCESS: 'GET_BANK_LIST_SUCCESS',
  GET_BANK_LIST_ERROR: 'GET_BANK_LIST_ERROR'
};

export const getBankList = () => ({
  type: actionTypes.GET_BANK_LIST
});

export const getBankListSuccess = (data) => ({
  type: actionTypes.GET_BANK_LIST_SUCCESS,
  data
});

export const getBankListError = () => ({
  type: actionTypes.GET_BANK_LIST_ERROR
});

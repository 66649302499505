import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import MLTable from '../../shared/tables/MLTable';
import { buildTable, handleTableSearch } from '../../../helpers/myEvolution/calibracion/detailTable';
import { capitalizeFieldValuesForTable } from '../../../helpers/myEvolution/calibracion';
import SearchInTable from '../../admin/uniforms/SearchInTable';
import Loader from '../../shared/MLLoading';
import Error from '../../shared/Error';

import { GET_CALIBRATION_DETAIL } from '../../../graphql/mievolucion/calibracion/queries';

import '../../../static/styles/mievolucion/calibracion/reviewTable.less';

const context = { clientName: 'myEvolution' };
const fetchPolicy = { fetchPolicy: 'network-only' };

const ReviewTable = ({ calibrationId }) => {
  const [tableData, setTableData] = useState([]);
  const {
    data: { getEvaluationCalibrationsDetails: details } = {},
    loading: detailsLoading
  } = useQuery(GET_CALIBRATION_DETAIL, {
    variables: { input: { calibrationId } },
    context,
    ...fetchPolicy
  });

  useEffect(() => {
    if (details) {
      setTableData(details);
    }
  }, [details]);

  if (detailsLoading) return <Loader />;
  if (details) {
    return (
      <div className='review-table-component'>
        <div style={{ clear: 'both', overflow: 'hidden' }}>
          <SearchInTable
            handleSearch={handleTableSearch({
              fieldsToCheck: ['fullName', 'rut'],
              data: details,
              callback: setTableData
            })}
            placeholder='Buscar a un colaborador por RUT o Nombre'
            isMobile={true}
          />
        </div>
        <MLTable
          columnAndData={buildTable(capitalizeFieldValuesForTable(['fullName', 'position'], tableData))}
          pagination={{ defaultPageSize: 7 }}
        />
      </div>
    );
  }
  return <Error />;
};

ReviewTable.propTypes = {
  calibrationId: PropTypes.string
};

ReviewTable.defaultProps = {
  calibrationId: ''
};

export default ReviewTable;

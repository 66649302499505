/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_SKILL_LEVEL_BEHAVIOR = gql`
    query listSkillLevelBehavior(
        $levelId: Int
    ) {
      listSkillLevelBehavior(
            levelId: $levelId
        ) {
            _id
            skillId
            name
            id
        }
    }
`;

export const GET_EVALUATION_PLAN_HISTORICAL = gql`
    query listSkillLevelBehaviorActionPlan(
        $status:  [PlanStates]
        $dni: String
    ) {
        listSkillLevelBehaviorActionPlan(
            status: $status
            dni: $dni
        )     {
            _id
            dni
            skill {
                _id
                name
            }
            createdAt
            updatedAt
            endAt
            status
            actions {
                _id
                actionId
                actionName
                behaviorId
                behaviorName
                status
                choice
                createdAt
                closeAt
            }
        }
    }
`;

import React, { Suspense, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { map, isUndefined, capitalize } from 'lodash';
import { Route, Switch } from 'react-router-dom';
import { checkVersions } from '../../helpers/version';
import isLeafPath from '../helpers/isLeafPath';
import authorized from '../helpers/authorized';
import traceUserVisit from '../../helpers/traceability/traceUserVisit';
import useCredentialsLoads from '../hooks/useCredentialsLoad';
import useCredentialsAdminLoads from '../hooks/useCredentialsAdminLoad';
import useSectionName from '../hooks/useSectionName';
import AuthComponent from './auth';
import MLLoading from '../../components/shared/MLLoading';
import useRouteLayout from '../hooks/useRouteLayout';
import { AppRouterContext } from '../context/AppRouterContext';

export const CreateRoutes = ({ parent, routes }) => (
  <Switch>
    {map(routes, (route) => (
      <AppRoute
        key={route.path}
        {...{
          ...route,
          parent
        }}
      />
    ))}
  </Switch>
);

const AppRoute = (propsAppRoute) => {
  const { path, component: Component, ...rest } = propsAppRoute;

  const { hideBanner, isPublic, parent } = rest;

  const routeSection = rest.section;
  // VERIFICAR HIJOS
  const headerTitle = rest.headerTitle
    || (isUndefined(rest.headerTitle) ? (parent || {}).headerTitle : null);
  const headerDescription = rest.headerDescription
    || (isUndefined(rest.headerDescription) ? (parent || {}).headerDescription : null);
  const leafPath = isLeafPath(path);

  const {
    show: showCredential = false,
    called: credencialProfileCalled,
    loading: loadigCredential
  } = useCredentialsLoads(leafPath, path);

  const {
    show: showCredentialAdmin = false,
    called: credencialAdminAccessCalled,
    loading: loadigCredentialAdmin
  } = useCredentialsAdminLoads(leafPath, path);

  const me = useSelector((state) => state.userReducer.data);
  const section = useSectionName();
  const refreshingPermissions = useSelector(
    (state) => state.userReducer.refreshingPermissions
  );
  // Mientras no se nombran los heads de mi lugar
  const setRouteLayout = useRouteLayout({
    headerDescription,
    headerTitle,
    hideBanner,
    isPublic,
    routeSection
  });

  const {
    changeAppLayoutProps,
    milugarEnabled,
    routesContainerReef
  } = useContext(AppRouterContext);

  useEffect(() => {
    if (!!me && !milugarEnabled.called) milugarEnabled.fetch();
  }, [me]);

  return (
    <Route
      {...rest}
      render={(props) => {
        const { permissions = {} } = me || {};
        const {
          auth,
          className,
          validateCredentials,
          banner,
          userValidator,
          trace,
          tabs,
          firstTab
        } = rest;

        const childrenContainerClassName = `main-layout-${rest.location.pathname
          .replace('/', '')
          .replace(/\//g, '-')}`;

        const canLoadPathComponent = section === 'mio'
          || !leafPath
          || (credencialProfileCalled
            && !loadigCredential
            && !refreshingPermissions)
          || (credencialAdminAccessCalled
            && !loadigCredentialAdmin
            && !refreshingPermissions);

        const requiredProps = {
          auth,
          className,
          permissions,
          childrenContainerClassName,
          changeAppLayoutProps,
          routesContainerReef,
          banner,
          headerDescription: rest.headerDescription,
          headerTitle: rest.headerTitle,
          parent: { headerTitle, section, headerDescription },
          userValidator,
          ...(tabs && {
            tabs: rest.subRoutes?.map((subRoute) => ({
              key: subRoute.path,
              title: capitalize(
                subRoute.path
                  .split('/')
                  .pop()
                  .replaceAll('-', ' ')
              ),
              visible: authorized({
                auth: subRoute.auth,
                me,
                showCredential,
                validateCredentials,
                userValidator
              })
            })),
            firstTab: firstTab && path === rest.location.pathname
          })
        };

        setRouteLayout();
        checkVersions();

        if (me && trace && routeSection) traceUserVisit(routeSection);

        const loadingPrivateComponent = !isPublic && milugarEnabled.loading;

        const routeComponent = loadingPrivateComponent ? (
          <MLLoading />
        ) : (
          <Component
            {...{
              ...props,
              me: { user: me, permissions },
              showCredential,
              showCredentialAdmin,
              ...requiredProps
            }}
          >
            {rest.subRoutes ? (
              <CreateRoutes
                {...{
                  routes: rest.subRoutes,
                  ...requiredProps
                }}
              />
            ) : null}
          </Component>
        );

        return (
          <Suspense fallback={<MLLoading />}>
            {isPublic ? (
              routeComponent
            ) : (
              <AuthComponent
                {...{
                  ...props,
                  auth,
                  validateCredentials,
                  userValidator,
                  permissions,
                  canLoadPathComponent
                }}
              >
                {routeComponent}
              </AuthComponent>
            )}
          </Suspense>
        );
      }}
    />
  );
};

export default AppRoute;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import * as storageUtil from '../../helpers/cookiesUtil';

import {
  getUserInfo, getSurvey, sendSurveyRes, addMedicalRecord, sendAssignStatus,
  getPendingUser, validateStatus, closingCase, getConfig, externalUser, getGamaReport,
  getLocationsOnCharge
} from '../api/wali';
import {
  actionTypes, getUserInfoSuccess, getPendingUserSuccess, getPendingUserError,
  validateStatusSuccess, validateStatusError, getSurveySuccess, sendSurveyResponseSuccess,
  getUserInfoColSuccess, controlAccessSuccess, waliFailure, getUserInfoColError,
  sendSurveyResponseError, controlAccessError, sendAssignStatusSuccess, sendAssignStatusError,
  sendClosingCaseSuccess, sendClosingCaseError, getConfigSuccess, getConfigError,
  sendExternalUserError, sendExternalUserSuccess, getGamaReportError, getGamaReportSuccess,
  getLocationsOnChargeSuccess, getLocationsOnChargeError
} from '../actions/wali';

import { loadComplete } from '../actions/commons';

es6promise.polyfill();

function* waliUserInfo() {
  try {
    const user = storageUtil.getItem('user');
    const params = { dni: user };
    const response = yield call(getUserInfo, params);
    yield put(getUserInfoSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(waliFailure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* waliPendingUser() {
  try {
    const user = storageUtil.getItem('user');
    const params = { dni: user };
    const response = yield call(getPendingUser, params);
    yield put(getPendingUserSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(loadComplete());
    yield put(getPendingUserError({ error, statusCode }));
  }
}

function* waliValidateStatus(actions) {
  try {
    const response = yield call(validateStatus, actions.payload.dni, actions.payload);
    yield put(validateStatusSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(loadComplete());
    yield put(validateStatusError({ error, statusCode }));
  }
}
function* waliGetSurvey(actions) {
  try {
    const params = { ...actions.payload };
    const response = yield call(getSurvey, params);
    yield put(getSurveySuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(waliFailure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* waliSendSurveyResponse(actions) {
  try {
    const response = yield call(sendSurveyRes, actions.payload);
    yield put(sendSurveyResponseSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(sendSurveyResponseError({ error, statusCode }));
  }
}

function* waliUserInfoCol(actions) {
  try {
    const user = actions.payload;
    const params = { dni: user };
    const response = yield call(getUserInfo, params);
    yield put(getUserInfoColSuccess(response));
  } catch (err) {
    const statusCode = get(err, 'response.status');
    const error = get(err, 'response.data');
    yield put(getUserInfoColError({ error, statusCode }));
  }
}

function* waliControlAccess(actions) {
  try {
    const response = yield call(addMedicalRecord, actions.payload);
    yield put(controlAccessSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(controlAccessError({ error, statusCode }));
  }
}

function* waliSendAssignStatus(actions) {
  try {
    const response = yield call(sendAssignStatus, actions.payload.dni, actions.payload);
    yield put(sendAssignStatusSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(sendAssignStatusError({ error, statusCode }));
  }
}
function* waliClosingCase(actions) {
  try {
    const response = yield call(closingCase, actions.payload);
    yield put(sendClosingCaseSuccess(response));
  } catch (err) {
    const statusCode = get(err, 'response.status');
    const error = get(err, 'response.data');
    yield put(sendClosingCaseError({ error, statusCode }));
  }
}

function* waliSendExternalUser(actions) {
  try {
    const response = yield call(externalUser, actions.payload);
    yield put(sendExternalUserSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(sendExternalUserError({ error, statusCode }));
  }
}

function* waliGetConfig() {
  try {
    const response = yield call(getConfig);
    yield put(getConfigSuccess(response));
  } catch (err) {
    const statusCode = get(err, 'response.status');
    const error = get(err, 'response.data');
    yield put(getConfigError({ error, statusCode }));
  }
}

function* waliGetGamaReport(actions) {
  try {
    const response = yield call(getGamaReport, actions.payload);
    yield put(getGamaReportSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(getGamaReportError({ error, statusCode }));
  }
}

function* waliGetLocationsOnCharge(actions) {
  try {
    const response = yield call(getLocationsOnCharge, actions.payload);
    yield put(getLocationsOnChargeSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(getLocationsOnChargeError({ error, statusCode }));
  }
}

function* waliSagas() {
  yield all([
    takeLatest(actionTypes.GET_USER_INFO, waliUserInfo),
    takeLatest(actionTypes.GET_SURVEY, waliGetSurvey),
    takeLatest(actionTypes.SEND_SURVEY_RESPONSE, waliSendSurveyResponse),
    takeLatest(actionTypes.GET_USER_INFO_COL, waliUserInfoCol),
    takeLatest(actionTypes.CONTROL_ACCESS, waliControlAccess),
    takeLatest(actionTypes.SEND_ASSIGN_STATUS, waliSendAssignStatus),
    takeLatest(actionTypes.SEND_CLOSING_CASE, waliClosingCase),
    takeLatest(actionTypes.GET_PENDING_USER, waliPendingUser),
    takeLatest(actionTypes.VALIDATE_STATUS, waliValidateStatus),
    takeLatest(actionTypes.GET_CONFIG, waliGetConfig),
    takeLatest(actionTypes.SEND_EXTERNAL_USER, waliSendExternalUser),
    takeLatest(actionTypes.GET_GAMA_REPORT, waliGetGamaReport),
    takeLatest(actionTypes.GET_LOCATIONS_ON_CHARGE, waliGetLocationsOnCharge)
  ]);
}

export default waliSagas;

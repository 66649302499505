import React from 'react';
import PropTypes from 'prop-types';
import {
  Select, DatePicker, Input
} from 'antd';
import { map } from 'lodash';
import moment from 'moment';
import {
  FORMAT_DATE
} from '../../../../helpers/myEvolution/interestsAndSkills';
import { Item } from '../Form';

moment.locale('es');

const { Option } = Select;

const Position = (props) => {
  const {
    updatePosition,
    position,
    listYearExperiences,
    index,
    lastPositions,
    setLastPositions,
    getFieldDecorator,
    listOptionsWorkExperienceArea
  } = props;

  const {
    area, company, positionName, period, duration
  } = position;

  const onChangeArea = (value) => {
    updatePosition({ area: { _id: value } }, index);
  };

  const removePosition = (e) => {
    e.preventDefault();

    const newLastPositions = Object.assign([], lastPositions);
    newLastPositions.splice(index, 1);

    setLastPositions(newLastPositions);
  };
  const onChangeDuration = (value) => {
    updatePosition({ duration: value }, index);
  };

  const onChangeDate = (value, key) => {
    updatePosition({ period: { ...period, [key]: value.format() } }, index);
  };
  return (
    <>
      <p className='text-label-primary'>Cargo {index + 1}</p>
      <div className='row'>
        <Item
          label='Área'
          className='col-12 col-md-12 col-lg-6'
        >
          {getFieldDecorator(`area-${index}`,
            {
              ...(area?._id ? { initialValue: area._id } : null)
            })(
            <Select
              showSearch
              allowClear
              placeholder="Elige una plataforma"
              onChange={(value) => onChangeArea(value, index)}
              filterOption={ (input, option) => (
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
              }
            >
              {map(listOptionsWorkExperienceArea, (item) => (
                <Option key={item._id} value={item._id}>{item.name}</Option>
              ))}
            </Select>
          )}
        </Item>

        <Item
          label='Duración en el cargo'
          className='col-12 col-md-12 col-lg-6'
        >
          {getFieldDecorator(`duration-${index}`,
            {
              ...(duration !== 'WITHOUTINFO' ? { initialValue: duration } : null)
            })(

            <Select placeholder='Elige un nivel'

              onChange={onChangeDuration}
            >
              {map(listYearExperiences, (item, i) => (
                <Option key={i} value={item.codeValue}>{item.name}</Option>
              ))}
            </Select>
          )}
        </Item>
      </div>
      <div className='row'>
        <Item
          label='Periodo'
          className='col-12 pb-0'
        />
        <Item
          className='col-12 col-md-12 col-lg-6'
        >
          {getFieldDecorator(`dateInit-${index}`,
            {
              ...(period?.dateInit ? { initialValue: moment(period.dateInit) } : null)
            })(

            <DatePicker
              format={FORMAT_DATE}
              size={'default'}
              placeholder='Fecha de inicio'
              onChange={(value) => {
                onChangeDate(value, 'dateInit');
              }}
            />
          )}
        </Item>
        <Item
          className='col-12 col-md-12 col-lg-6'
        >
          {getFieldDecorator(`dateFinish${index}`,
            {
              ...(period?.dateFinish ? { initialValue: moment(period.dateFinish) } : null)
            })(
            <DatePicker

              format={FORMAT_DATE}
              size={'default'}
              placeholder='Fecha de finalización'
              onChange={(value) => {
                onChangeDate(value, 'dateFinish');
              }}
            />
          )}
        </Item>
      </div>

      <div className='row'>
        <Item
          label='Nombre del cargo'
          className='col-12 col-md-12 col-lg-6'
        >
          {getFieldDecorator(`positionName-${index}`,
            {
              initialValue: positionName
            })(
            <Input
              onChange={({ target }) => {
                updatePosition({ positionName: target.value }, index);
              }}
              placeholder='Ej: Jefe de Transporte' />
          )}

        </Item>
        <Item
          label='Empresa'
          className='col-12 col-md-12 col-lg-6'
        >
          {getFieldDecorator(`company-${index}`,
            {
              initialValue: company
            })(
            <Input
              onChange={({ target }) => {
                updatePosition({ company: target.value }, index);
              }}
              placeholder='Ej: Walmart' />
          )}
          { lastPositions.length > 1
            && <p className='text-delete' onClick={removePosition}>Eliminar</p>
          }
        </Item>
      </div>
    </>

  );
};

Position.propTypes = {
  updatePosition: PropTypes.func,
  listYearExperiences: PropTypes.array,
  index: PropTypes.number,
  lastPositions: PropTypes.array,
  setLastPositions: PropTypes.func,
  getFieldDecorator: PropTypes.func

};

Position.defaultProps = {
  updatePosition: () => {},
  listYearExperiences: [],
  index: 0,
  lastPositions: [],
  setLastPositions: () => {},
  getFieldDecorator: () => {}
};
export default Position;

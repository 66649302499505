import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Form } from 'antd';
import InfoMessage from '../../../../../shared/InfoMessage';
import MLTextArea from '../../../../../shared/MLTextArea';
import MLErrorModal from '../../../../../shared/MLModal/Message';
import useOpenModal from '../../../../../../hooks/shared/mlModal/useOpenModal';
import { UPDATE_STATUS_PERFORMANCE_WALMART } from '../../../../../../graphql/mievolucion/myPerformance/mutations';
import '../../../../../../static/styles/my-team/my-evolution/performance/reject-form.less';
import { GET_NOTIFICATIONS } from '../../../../../../graphql/notifications/queries';

const RejectForm = ({
  form, formRef, onSubmit, selectedUser
}) => {
  const [updatePerformanceStatus] = useMutation(
    UPDATE_STATUS_PERFORMANCE_WALMART,
    {
      context: { clientName: 'myEvolution' },
      refetchQueries: [
        {
          query: GET_NOTIFICATIONS
        }
      ]
    }
  );
  const { getFieldDecorator, validateFields } = form;
  const { open: showError, refOpen: refShowError } = useOpenModal();

  const handleSubmit = (events) => {
    validateFields(async (err, values) => {
      if (!err) {
        events.startLoading();

        try {
          const variables = {
            rut: selectedUser.rut,
            input: {
              status: 'RETURNED',
              commentReturned: values.commentReturned
            }
          };

          await updatePerformanceStatus({ variables });
          onSubmit(events);
        } catch (_) {
          events.endLoading();
          showError();
        }
      }
    });
  };

  return (
    <div className='ml-performance-reject-form'>
      <InfoMessage {...{
        suave: true,
        color: 'warning',
        message: `Indica a ${selectedUser.name} el motivo por el que quieres que edite su propuesta de objetivos.`
      }}/>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Form.Item label='Motivo'>{getFieldDecorator('commentReturned', {
          rules: [{ required: true, message: 'Campo requerido' }]
        })(<MLTextArea maxLength={400} placeholder='Describe el motivo'/>)}
        </Form.Item>
      </Form>

      <MLErrorModal {...{
        refOpen: refShowError,
        title: 'No pudimos devolver la Propuesta',
        message: 'Estamos trabajando en solucionarlo, por favor intenta de nuevo más tarde.'
      }}/>
    </div>
  );
};

RejectForm.propTypes = {
  form: PropTypes.object,
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
  selectedUser: PropTypes.object
};

RejectForm.defaultProps = {
  form: {},
  formRef: null,
  onSubmit: () => null,
  selectedUser: {}
};

export default Form.create()(RejectForm);

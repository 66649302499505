import React from 'react';
import PropTypes from 'prop-types';
import { COMMUNICATED } from '../../../../../helpers/myEvolution/evaluations';

const EvaluationStep = ({
  isCompleted, selectedStep, status, showIconCheck, data, i
}) => {
  const getClassNameBorderCircle = () => {
    if (selectedStep.numberStatus - 1 === i && status.toUpperCase() !== COMMUNICATED) {
      return 'active';
    }
    return '';
  };

  const getClassNameIcon = () => {
    if (showIconCheck && status.toUpperCase() !== COMMUNICATED) {
      return 'icon-done';
    }
    if (selectedStep.numberStatus - 1 > i || status.toUpperCase() === COMMUNICATED) {
      return 'icon-done';
    }
    return `container-number ${selectedStep.numberStatus - 1 === i ? 'active' : ''}`;
  };

  return (
    <div
      className='container-status'
    >
      <div className='box-container-number'>
        <div className={`border-circle ${getClassNameBorderCircle()}`}>
          <div
            data-testid="icon"
            className={getClassNameIcon()}
          >
            {
              selectedStep.numberStatus - 1 > i || isCompleted
                ? <i className='icon-me-check ' />
                : <span>{i + 1}</span>
            }
          </div>
        </div>
      </div>
      <p className={` ${selectedStep.numberStatus - 1 >= i ? 'text-active' : ''}`}>{data.label}</p>
      <p className='ml-steps-status-description'>{data.description}</p>
    </div>
  );
};

EvaluationStep.propTypes = {
  isCompleted: PropTypes.bool,
  selectedStep: PropTypes.object,
  status: PropTypes.string,
  showIconCheck: PropTypes.bool,
  data: PropTypes.object,
  i: PropTypes.number
};

EvaluationStep.defaultProps = {
  isCompleted: false,
  selectedStep: {},
  status: '',
  showIconCheck: false,
  data: {},
  i: 0
};

export default EvaluationStep;

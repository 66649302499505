import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const REACT_APP_API_BFF_CREDENTIALS = getEnvVar('REACT_APP_API_BFF_CREDENTIALS');

export const getListStoresAPI = (npres) => axios().get(`${REACT_APP_API_BFF_CREDENTIALS}/Credentials/GetFiltersByAssociated/${npres}`);

export const getMyCredentialProfileAPI = (npers) => axios().get(`${REACT_APP_API_BFF_CREDENTIALS}/UserIdentity/GetUserData/${npers}`);

export const getMyCredentialPictureAPI = (npers) => axios().get(`${REACT_APP_API_BFF_CREDENTIALS}/ImageStorage/DownloadAssociatedImage/${npers}/${npers}`);

export const renewCredentialAPI = (data) => axios().post(`${REACT_APP_API_BFF_CREDENTIALS}/Request/AddRequest`, data);

import gql from 'graphql-tag';
import { retro } from './queries';

export const SAVE_OPINION = gql`
    mutation saveRetroOpinion(
        $_id: String!
        $opinion: [OpinionRetroInput]!
    ) {
        saveRetroOpinion
        (
            _id: $_id
            opinion: $opinion
        ) 
        {
            ${retro}
        }
    }
`;

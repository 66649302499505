import { useSelector } from 'react-redux';

const useAuthReducers = () => useSelector((state) => {
  const {
    commonsReducer, userReducer: {
      data, loading, usersToRedirect
    },
    miCredencialReducer: {
      myCredentialProfile: {
        show: showCredential = false
      }
    }
  } = state;

  return {
    commonsReducer,
    userReducer: {
      auth: state.userReducer.auth,
      loading,
      me: data,
      usersToRedirect
    },
    showCredential
  };
});

export default useAuthReducers;

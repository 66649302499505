import optionsTypeRequest from './optionsTypeRequest';

const { BANK_ACCOUNT, OTHERS } = optionsTypeRequest;
const setValueOptionsTypeRequest = ({ category = '', priority = '' }) => ({ value: `${category}--${priority}`, name: category });

const listOptionsTypeRequest = [
  { value: 'Alumnos en práctica--Baja', name: 'Alumnos en práctica' },
  { value: 'Accesos y Permisos (GDI)--Baja', name: 'Accesos y Permisos (GDI)' },
  { value: 'Contratos y Anexos--Media', name: 'Contratos y Anexos' },
  { value: 'Gestión Equipo Personas--Alta', name: 'Gestión Equipo Personas' },
  { value: 'Licencias médicas--Media', name: 'Licencias médicas' },
  { value: 'Liquidación de sueldo--Media', name: 'Liquidación de sueldo' },
  { value: 'Nuevos colaboradores--Media', name: 'Nuevos colaboradores' },
  { value: 'Beneficios y Convenios--Media', name: 'Beneficios y Convenios' },
  { value: 'Servicio de Buses--Media', name: 'Servicio de Buses' },
  { value: 'Servicio de Casino--Media', name: 'Servicio de Casino' },
  { value: 'Vacaciones y Permisos Legales--Media', name: 'Vacaciones y Permisos Legales' },
  setValueOptionsTypeRequest(BANK_ACCOUNT),
  setValueOptionsTypeRequest(OTHERS)
];

export default listOptionsTypeRequest;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { filter, find } from 'lodash';
import InfoMessage from '../../shared/InfoMessage';
import '../../../static/styles/shared/MLOpinion/styles.less';
import RadioInput from '../../shared/MLOpinion/RadioInput';
import TextAreaInput from '../../shared/MLOpinion/TextAreaInput';
import YesNoInput from '../../shared/MLOpinion/YesNoInput';
import ButtonSave from '../../shared/MLOpinion/ButtonSave';
import { fullCapitalizeFormat } from '../../../helpers/strings';

const MLOpinion = (props) => {
  const {
    closeOpinion,
    questions,
    sendOpinion,
    loading,
    evaluator
  } = props;

  const setInitialData = () => questions.map((question) => (
    {
      ...question,
      value: null,
      visible: !!question.questionBlocksOthers
    }
  ));

  const [questionsAndAnswers, setAnswers] = useState(setInitialData());

  const validAnswers = filter(questionsAndAnswers,
    (item) => item.value !== null && item.value.trim().length > 0);

  const disabledButton = () => {
    let disabled = true;

    if (validAnswers?.length > 0) {
      const hasQuestionBlocker = find(questions,
        ({ questionBlocksOthers }) => questionBlocksOthers);

      disabled = hasQuestionBlocker ? !(validAnswers?.length > 1) : false;
    }
    return disabled;
  };

  const setVisibleWhenInputIsTextArea = ({
    questionBlocksOthers, questionToUpdate, inputValue, indexQuestion, indexQuestionToUpdate
  }) => {
    if (!questionBlocksOthers) return questionToUpdate.visible;

    return inputValue.trim().length > 0 && !questionToUpdate.initiallyHidden ? true
      : !!(
        indexQuestion === indexQuestionToUpdate
        || inputValue.trim().length > 0);
  };
  const setVisibleWhenInputIsYesNo = ({
    questionBlocksOthers, questionToUpdate, inputValue, indexQuestion, indexQuestionToUpdate
  }) => {
    if (!questionBlocksOthers) return questionToUpdate.visible;

    return inputValue === 'no' && !questionToUpdate.initiallyHidden ? true
      : !!(indexQuestion === indexQuestionToUpdate || inputValue === 'si');
  };

  const setValueInQuestion = ({
    questionBlocksOthers,
    conditionToClearOtherAnswers,
    indexQuestion,
    indexQuestionToUpdate,
    inputValue,
    questionToUpdate
  }) => {
    if (indexQuestion === indexQuestionToUpdate) return inputValue;
    if (questionBlocksOthers && conditionToClearOtherAnswers) return null;
    return questionToUpdate.value;
  };
  return (
    <div className='d-flex justify-content-center'>

      <div className='col-12 col-sm-12 col-md-8 col-lg-6 box-evaluation-opinion'>
        <InfoMessage
          classes='w-100'
          color='warning'
        >
          <span className='text-bold'>Danos tu opinión sobre la conversación acerca de la evaluación que tuviste con
            {fullCapitalizeFormat(` ${evaluator.name} ${evaluator.fatherLastName} `) }</span>
        </InfoMessage>

        {questionsAndAnswers.map((question, indexQuestion) => question.visible && (
          <div key={indexQuestion} className='box-questions'>

            {question.inputType === 'radio'
            && <RadioInput {...{
              question,
              onClick: (indexEmoticon) => {
                const newAnswersAndQuestions = questionsAndAnswers.map(
                  (item, indexQuestionToUpdate) => (
                    {
                      ...item,
                      value: indexQuestion === indexQuestionToUpdate
                        ? (indexEmoticon + 1).toString()
                        : item.value,
                      visible: true
                    }
                  )
                );
                setAnswers(newAnswersAndQuestions);
              }
            }}/>

            }
            { question.inputType === 'textarea'
            && <TextAreaInput
              {...{
                inputClassName: 'ml-textarea',
                question,
                onChange: (e) => {
                  const newAnswersAndQuestions = questionsAndAnswers.map(
                    (item, indexQuestionToUpdate) => (
                      {
                        ...item,
                        value: setValueInQuestion({
                          questionBlocksOthers: question.questionBlocksOthers,
                          conditionToClearOtherAnswers: e.target.value.trim().length <= 0,
                          indexQuestion,
                          indexQuestionToUpdate,
                          inputValue: e.target.value,
                          questionToUpdate: item
                        }),
                        visible: setVisibleWhenInputIsTextArea({
                          questionBlocksOthers: question.questionBlocksOthers,
                          questionToUpdate: item,
                          inputValue: e.target.value,
                          indexQuestion,
                          indexQuestionToUpdate
                        })
                      }
                    )
                  );
                  setAnswers(newAnswersAndQuestions);
                }
              }}/>
            }
            { question.inputType === 'yes-no'
               && (
                 <YesNoInput

                   {...{
                     question,
                     onChange: (e) => {
                       const newAnswersAndQuestions = questionsAndAnswers.map(
                         (item, indexQuestionToUpdate) => (
                           {
                             ...item,
                             value: setValueInQuestion({
                               questionBlocksOthers: question.questionBlocksOthers,
                               conditionToClearOtherAnswers: e.target.value === 'no',
                               indexQuestion,
                               indexQuestionToUpdate,
                               inputValue: e.target.value,
                               questionToUpdate: item
                             }),
                             visible: setVisibleWhenInputIsYesNo({
                               questionBlocksOthers: question.questionBlocksOthers,
                               questionToUpdate: item,
                               inputValue: e.target.value,
                               indexQuestion,
                               indexQuestionToUpdate
                             })
                           }
                         )
                       );
                       setAnswers(newAnswersAndQuestions);
                     }
                   }}/>
               )
            }

          </div>
        ))}
        <ButtonSave {
          ...{
            loading,
            disabledButton: disabledButton(),
            saveOpinion: () => sendOpinion(questionsAndAnswers),
            closeOpinion,
            labelButton: 'Enviar mi opinión'
          }
        } />
      </div>
    </div>
  );
};

MLOpinion.propTypes = {
  questions: PropTypes.array,
  sendOpinion: PropTypes.func,
  loading: PropTypes.bool,
  closeOpinion: PropTypes.func,
  evaluator: PropTypes.object
};

MLOpinion.defaultProps = {
  questions: [],
  sendOpinion: () => {},
  loading: false,
  closeOpinion: () => {},
  evaluator: {}
};
export default MLOpinion;

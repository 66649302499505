import { actionTypes } from '../actions/misReconocimientos';

export const initialState = {
  toMe: {
    called: false,
    data: [],
    error: '',
    loading: false
  },
  toOthers: {
    called: false,
    data: [],
    error: '',
    loading: false
  }
};

function misReconocimientosReducer(state = initialState, action) {
  const { error, payload } = action;

  switch (action.type) {
  case actionTypes.GET_VALORATIONS_TO_ME:
    return {
      ...state,
      toMe: {
        ...state.toMe,
        called: true,
        loading: true
      }
    };
  case actionTypes.GET_VALORATIONS_TO_ME_SUCCESS:
    return {
      ...state,
      toMe: {
        ...state.toMe,
        data: payload.result,
        error: false,
        loading: false
      }
    };
  case actionTypes.GET_VALORATIONS_TO_ME_ERROR:
    return {
      ...state,
      toMe: {
        ...state.toMe,
        error,
        loading: false
      }
    };
  case actionTypes.GET_VALORATIONS_TO_OTHERS:
    return {
      ...state,
      toOthers: {
        ...state.toOthers,
        called: true,
        loading: true
      }
    };
  case actionTypes.GET_VALORATIONS_TO_OTHERS_SUCCESS:
    return {
      ...state,
      toOthers: {
        ...state.toOthers,
        data: payload.result,
        error: false,
        loading: false
      }
    };
  case actionTypes.GET_VALORATIONS_TO_OTHERS_ERROR:
    return {
      ...state,
      toOthers: {
        ...state.toOthers,
        error,
        loading: false
      }
    };
  case actionTypes.RESET_MIS_RECONOCIMIENTOS:
    return {
      ...initialState
    };
  default:
    return state;
  }
}

export default misReconocimientosReducer;

export const actionTypes = {
  POST_ANALYTIC_REQUEST: 'POST_ANALYTIC_REQUEST',
  POST_ANALYTIC_REQUEST_SUCCESS: 'POST_ANALYTIC_REQUEST_SUCCESS'
};

export function postAnalyticRequest(user, screen) {
  return { type: actionTypes.POST_ANALYTIC_REQUEST, user, screen };
}

export function postAnalyticRequestSuccess(data) {
  return {
    type: actionTypes.POST_ANALYTIC_REQUEST_SUCCESS,
    data
  };
}

/* eslint-disable no-case-declarations */
import moment from 'moment';
import {
  isEmpty, isArray, isFunction, isString
} from 'lodash';
import { fullCapitalizeFormat, humanize } from '../../strings';

export const unmaskCurrency = (v) => parseInt(v.replace(/\./g, '').replace(/^\$\s/, ''), 0);

export const maskCurrency = (v) => (v.replace(/\D/g, '')
  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  .replace(/^/, '$ '));

const emptyHistory = {
  band: '',
  positioning: 0,
  salary: '0',
  valid: '',
  reason: '',
  performanceCategory: '',
  talentCategory: ''
};

export const shapeData = (array) => array.map((item, i) => {
  const {
    fullName,
    position,
    dateOld,
    positionOld,
    performanceCategory,
    talentCategory,
    history: [history = emptyHistory]
  } = item;
  return {
    key: i,
    fullName,
    position: fullCapitalizeFormat(position),
    dateOld: moment(dateOld).utc().format('DD/MM/YYYY'),
    positionOld: moment(positionOld).utc().format('DD/MM/YYYY'),
    performanceCategory,
    talentCategory,
    band: history.band,
    positioning: history.positioning,
    salary: maskCurrency(history.salary.toString()),
    validDueDate: history.validDueDate ? moment(history.validDueDate).utc().format('DD/MM/YYYY') : moment(),
    reason: history.reason
  };
});

export const getFieldsToFilter = (filtersState) => (
  Object.keys(filtersState).reduce((acum, value) => {
    if (/From|To/.test(value)) {
      const property = value.split(/From|To/)[0];
      if (
        !isEmpty(filtersState[`${property}From`])
        && !isEmpty(filtersState[`${property}To`])
        && !acum.some((v) => v === `${property}|${filtersState[`${property}From`]}|${filtersState[`${property}To`]}`)
      ) {
        acum.push(`${property}|${filtersState[`${property}From`]}|${filtersState[`${property}To`]}`);
      }
    } else if (!isEmpty(filtersState[value])) {
      acum.push(value);
    }
    return acum;
  }, [])
);

export const buildFilters = (fields, filtersState) => {
  const filters = {};
  fields.forEach((item) => {
    filters[item] = (() => {
      if (item === 'positioning') {
        const conditions = filtersState[item]
          .map((i) => i.split(' a ')
            .map((v) => v.replace('%', '')
              .trim()))
          .map((v) => {
            if (/Menor/.test(v[0])) return (value) => value < v[1];
            if (/Mayor/.test(v[0])) return (value) => value > v[1];
            return (value) => value > v[0] && value < v[1];
          });
        return conditions;
      }
      return filtersState[item];
    })();
  });
  return filters;
};

export const handleFilter = (filters, dataSource) => {
  const tableSource = shapeData(dataSource);
  const result = tableSource.filter((dataItem) => Object.entries(filters)
    .every(([filterProperty, filterValues]) => {
      switch (true) {
      case /\|/.test(filterProperty): // for input composition
      // eslint-disable-next-line prefer-const
        let [property, from, to] = filterProperty.split('|');
        from = unmaskCurrency(from);
        to = unmaskCurrency(to);
        const dataValue = unmaskCurrency(dataItem[property]);
        return dataValue >= from && dataValue <= to;

      case isArray(filterValues) && moment.isMoment(filterValues[0]): // for date ranges
        const date = moment(dataItem[filterProperty], 'DD/MM/YYYY');
        return date.isBetween(filterValues[0], filterValues[1], 'days', true);

      case isArray(filterValues) && isFunction(filterValues[0]): // for checkboxes with ranges
        return filterValues.some((condition) => condition(parseInt(dataItem[filterProperty], 0)));

      case isArray(filterValues): // for checkboxes
        return filterValues.some(
          (v) => v.toLowerCase().includes(
            dataItem[filterProperty] ? dataItem[filterProperty].toLowerCase() : false
          )
        );

      case isString(filterValues): // for strings
        const stringRegex = new RegExp(`${filterValues.toLowerCase()}`);
        return dataItem[filterProperty].toLowerCase().match(stringRegex);
      default:
        return null;
      }
    }));
  return result;
};

export const buildCheckboxes = (fields, data) => {
  const getData = (field) => data
    .filter((v) => v[field])
    .map((item) => humanize(item[field]));
  const result = fields.reduce((acum, current) => {
    acum[current] = [...new Set(getData(current))];
    return acum;
  }, {});
  return result;
};

import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import '../../static/styles/shared/MLFilterSelect.less';

const { Option } = Select;

const MLFilterSelect = ({
  defaultValue, /* Se está ignorando por completo esta propiedad. */
  loading,
  onSelect,
  options,
  value,
  onBlur,
  onFocus,
  suffixIcon
}) => (
  <div className='ml-container-filter-select'>
    <i className='icon-ml-filtrar'/>
    <Select
      loading={loading}
      value={value}
      defaultValue={defaultValue}
      onSelect={onSelect}
      onBlur={onBlur}
      onFocus={onFocus}
      suffixIcon={suffixIcon}
    >
      {options.map((option, i) => <Option key={i} value={option.type}>{option.label}</Option>)}
    </Select>
  </div>
);

MLFilterSelect.propTypes = {
  defaultValue: PropTypes.string,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.string,
  suffixIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.object])
};

MLFilterSelect.defaultProps = {
  defaultValue: '',
  loading: false,
  onSelect: () => {},
  onBlur: () => {},
  onFocus: () => {},
  options: [],
  value: '',
  suffixIcon: null
};

export default MLFilterSelect;

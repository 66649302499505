import React, {
  useEffect, Fragment, useState, useRef
} from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import SectionTitle from '../../../../components/compensaciones/SectionTitle';
import { getMyTeamTableSrcData, getMyTeamListSrcData } from '../../../../helpers/compensations/myTeam';
import {
  ENABLE_MERITS_LETTERS, DISABLE_MERIT_MESSAGE
} from '../../../../helpers/compensations/communication';

import MLTable from '../../../../components/shared/tables/MLTable';
import MLCard from '../../../../components/shared/cards/MLCard';
import InfoMessage from '../../../../components/shared/InfoMessage';
import ModalMessage from '../../../../components/shared/ModalMessage';
import MLModalMessage from '../../../../components/shared/MLModal/Message';
import MLError from '../../../../components/shared/Error';
import { GET_BOSS_LETTER_LIST } from '../../../../graphql/compensations/queries';
import { GENERATE_LETTER, SEND_LETTER } from '../../../../graphql/compensations/mutations';
import useOpenModal from '../../../../hooks/shared/mlModal/useOpenModal';
import { GET_SETTING } from '../../../../graphql/settings/queries';
import Loading from '../../../loading';
import { getSettingValue } from '../../../../helpers/settings';

const context = { clientName: 'compensations' };
const SuccessModal = MLModalMessage;

const CartasDeMerito = () => {
  const anchorRef = useRef();
  const { open: openSuccessModal, refOpen: refSuccessModal } = useOpenModal();
  const [selected, setSelected] = useState({
    id: '',
    name: ''
  });
  const [modalOptions, setModalOptions] = useState({
    show: false,
    title: '',
    message: '',
    icon: '',
    type: ''
  });
  const {
    loading: settingMeritsLettersLoading,
    data: settingMeritsLetters,
    error: settingMeritsLettersError
  } = useQuery(GET_SETTING,
    {
      context,
      variables: { key: ENABLE_MERITS_LETTERS },
      fetchPolicy: 'network-only'
    });
  const { getSetting: setting = {} } = settingMeritsLetters || {};

  const isEnableMeritsLetters = getSettingValue(setting?.value);
  const [getBossList, {
    data,
    loading: bossListLoading,
    error: bossListError
  }] = useLazyQuery(GET_BOSS_LETTER_LIST, {
    context,
    variables: { type: 'MERIT' },
    fetchPolicy: 'network-only'
  });
  const [sendLetterMutation, { loading: sendMutationLoader }] = useMutation(
    SEND_LETTER,
    {
      context,
      onCompleted: async () => {
        openSuccessModal({
          title: `La carta a ${selected.name} se ha enviado con éxito`,
          message: ''
        });
      },
      onError: () => {
        setModalOptions({
          show: true,
          title: 'Ha habido un error de conexión',
          message:
            'Se ha producido un error al intentar enviar la carta. Por favor inténtalo de nuevo más tarde.',
          type: 'error'
        });
      }
    }
  );
  const [generateLetterMutation, { loading: generateLetterLoading }] = useMutation(
    GENERATE_LETTER,
    {
      context,
      fetchPolicy: 'no-cache',
      onCompleted: (resp) => {
        const { generateLetter: { file } } = resp;
        setTimeout(() => {
          anchorRef.current.href = file;
          anchorRef.current.click();
        }, 0);
      },
      onError: () => {
        setModalOptions({
          show: true,
          title: 'Ha habido un error de conexión',
          message:
            'Se ha producido un error al intentar enviar la carta. Por favor inténtalo de nuevo más tarde.',
          type: 'error'
        });
      }
    }
  );

  const sendLetter = (id, name) => (
    selected.id === id ? (
      sendLetterMutation({ variables: { letterID: selected.id } })
    ) : (
      setSelected({ id, name, action: 'send' })
    )
  );

  const generateLetter = (id, name) => (
    selected.id === id ? (
      generateLetterMutation({ variables: { letterID: selected.id } })
    ) : (
      setSelected({ id, name, action: 'generate' })
    )
  );

  const closeModal = () => {
    setModalOptions({
      ...modalOptions,
      show: false
    });
  };

  const openModal = (options) => {
    setModalOptions({
      show: true,
      icon: 'icon-ml-information primary',
      title: 'No es elegible',
      type: 'success',
      ...options
    });
  };

  const handleSuccessOk = async (events) => {
    await events.closeModal();

    try {
      await getBossList();
    } catch {
      setModalOptions({
        show: true,
        title: 'Ha habido un error de conexión',
        message:
          'Se ha producido un error al intentar obtener el listado de colaboradores. Por favor inténtalo de nuevo más tarde.',
        type: 'error'
      });
    }
  };

  useEffect(() => {
    if (!data) {
      getBossList();
    }
  }, [selected]);

  useEffect(() => {
    switch (selected.action) {
    case 'generate':
      generateLetterMutation({ variables: { letterID: selected.id } });
      break;
    case 'send':
      sendLetterMutation({ variables: { letterID: selected.id } });
      break;
    default:
    }
  }, [selected.id, selected.action]);

  if (bossListLoading
    || generateLetterLoading
    || settingMeritsLettersLoading) {
    return <Loading />;
  }

  if (!isEnableMeritsLetters) {
    return (
      <InfoMessage
        color='warning'
        message={
          <div className='infoMessage'>
            <p>{DISABLE_MERIT_MESSAGE}</p>
          </div>
        }
        classes='info-message info-message-suave'
      />
    );
  }
  if (bossListError || settingMeritsLettersError) {
    return <MLError />;
  }
  return (
    <div className='section-page compensations-my-team'>
      <a href='#' download target='_blank' ref={anchorRef} />
      <SuccessModal {...{ type: 'success', refOpen: refSuccessModal, handleOk: handleSuccessOk }}/>
      <ModalMessage
        visible={modalOptions.show}
        title={modalOptions.title}
        message={modalOptions.message}
        icon={modalOptions.icon}
        type={modalOptions.type}
        closeModal={closeModal}
      />
      <SectionTitle title='Revisión Cartas de Mérito' className='show-always' divider={false}/>
      {
        data
          && data.bossLetterList
          && data.bossLetterList.letters.length === 0
          && !bossListLoading
          ? (<InfoMessage message='No tienes comunicaciones pendientes por revisar.' />)

          : data
          && (!bossListLoading && !generateLetterLoading)
          && (
            <Fragment>
              <MLTable
                columnAndData={
                  getMyTeamTableSrcData(data, { sendLetter, openModal, generateLetter },
                    { selected, sendMutationLoader })
                }
              />
              <div className='row'>
                {
                  getMyTeamListSrcData(data, { sendLetter, generateLetter },
                    { selected, sendMutationLoader })
                    .map((item, i) => (
                      <div className='col-sm-12 card-standart' key={`cardWrapper${i}`}>
                        <MLCard
                          avatar
                          infoMessage={item.infoMessage?.message}
                          infoMessageProps={item.infoMessage?.props}
                          name={item.name}
                          itemList={item.fields}
                          btnList={item.buttons}
                        />
                      </div>
                    ))
                }
              </div>
            </Fragment>
          )
      }
    </div>
  );
};

export default CartasDeMerito;

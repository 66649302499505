import React from 'react';
import { map, filter, capitalize } from 'lodash';
import PendingCircle from '../../../../components/solicitudes/shared/PendingCircle';
import { fullCapitalizeFormat } from '../../../strings';
import { createURLSepateByDash } from '../../../url';
import { getValueKeySetting } from '../../../settings';
import {
  COMMUNICATED,
  VALIDATED,
  VIEW_AS_EVALUATOR,
  VIEW_AS_VALIDATOR,
  REJECTED, EVALUATED,
  VIEW_AS_EVALUATED
} from '../../../myEvolution/evaluations';

export const MY_TEAM_URL = '/mi-equipo/mi-evolucion/evaluaciones/desempeño';

export const MY_TEAM_URL_VALIDATOR = `${MY_TEAM_URL}/validaciones`;
export const MY_TEAM_URL_EVALUATOR = `${MY_TEAM_URL}/evaluaciones`;

export const ENABLE_EDD_COMMUNICATION = 'ENABLE_EDD_COMMUNICATION';
export const ENABLE_EDD_VALIDATION = 'ENABLE_EDD_VALIDATION';
export const ENABLE_EDD_EVALUATION = 'ENABLE_EDD_EVALUATION';

export const TAB_VALIDATOR_TITLE = 'Validaciones';
export const TAB_EVALUATOR_TITLE = 'Evaluaciones';

export const getMyTeamTabs = (isEvaluator, isValidator, isEnabledValidate) => {
  const myTeamTabs = [];

  if (isEvaluator) {
    myTeamTabs.push({
      key: 1,
      title: TAB_EVALUATOR_TITLE,
      childrenURL: `${MY_TEAM_URL}/evaluaciones`,
      viewAs: VIEW_AS_EVALUATOR
    });
  }
  if (isValidator && isEnabledValidate) {
    myTeamTabs.push({
      key: 2,
      title: TAB_VALIDATOR_TITLE,
      childrenURL: MY_TEAM_URL_VALIDATOR,
      viewAs: VIEW_AS_VALIDATOR
    });
  }

  return myTeamTabs;
};

export const getUsersSubordinatesEDD = (list) => map(list, (item) => {
  const {
    rut,
    name,
    fatherLastName,
    fullName,
    position,
    hasNotification,
    statusEDD,
    enabledEDD
  } = item;
  return {
    rut,
    title: fullCapitalizeFormat(fullName),
    subTitle: position,
    leftTitle: hasNotification ? <PendingCircle /> : null,
    fullName: fullCapitalizeFormat(fullName),
    firstName: fullCapitalizeFormat(name),
    fatherLastName: fullCapitalizeFormat(fatherLastName),
    statusEDD,
    enabledEDD
  };
});
export const filterSubordinatesByStatus = (list, status) => {
  if (status === '') return list;
  return filter(list, ({ statusEDD }) => statusEDD === status);
};

export const getListSubordinates = (mySubordinatesEDD, viewAs, filterByStatusSelected) => {
  if (viewAs === VIEW_AS_VALIDATOR) return getUsersSubordinatesEDD(mySubordinatesEDD.toValidate);
  const listToEvaluate = getUsersSubordinatesEDD(mySubordinatesEDD.toEvaluate);
  return filterSubordinatesByStatus(listToEvaluate, filterByStatusSelected);
};

export const getUserSelectedURL = (user, tabSelected, filterByStatusSelected, viewAs) => {
  const type = tabSelected ? tabSelected.title.toLowerCase() : '';
  const statusSelected = viewAs === VIEW_AS_VALIDATOR ? '' : `&status=${filterByStatusSelected}`;
  return `${MY_TEAM_URL}/${type}/${createURLSepateByDash(user.fullName)}?rut=${user.rut}${statusSelected}`;
};

export const getEDDSettings = (settings) => {
  const { listSettings = [] } = settings || {};

  const isCommunicationEnabled = getValueKeySetting(listSettings, ENABLE_EDD_COMMUNICATION);
  const isValidationEnabled = getValueKeySetting(listSettings, ENABLE_EDD_VALIDATION);
  const isEvaluationEnabled = getValueKeySetting(listSettings, ENABLE_EDD_EVALUATION);

  return [isCommunicationEnabled, isValidationEnabled, isEvaluationEnabled];
};

export const getPropsEmptyListSubordinates = (listTab) => {
  const img = 'web/mi-evolucion/servicios/inicio/sin-evaluacion-de-desempeño.png';
  const key = listTab ? listTab.key : '';

  switch (key) {
  case 1:
    return {
      img,
      title: 'Sin evaluaciones',
      description: 'Sin evaluaciones'
    };
  case 2:
    return {
      img,
      title: 'Sin evaluaciones pendientes por validar'
    };
  default:
    return {
      img
    };
  }
};

export const isEvaluationStarted = (lastStepEvaluation) => {
  if (!lastStepEvaluation) return false;
  if (lastStepEvaluation.component === 'SKILLS' && lastStepEvaluation.step === 1) {
    return false;
  }
  return true;
};

const getButtonsAlertMessagesByStatus = (
  primaryButtonText, primaryButtonOnClick, status
) => ([
  {
    text: 'Cancelar',
    className: 'button-border-primary'
  },
  {
    text: primaryButtonText,
    type: 'primary',
    onClickButton: () => primaryButtonOnClick(status)
  }
]);
export const getAlertMessagesByStatus = (
  status, fullNameEvaluated, primaryButtonOnClick
) => {
  switch (status) {
  case COMMUNICATED:
    return ({
      visible: true,
      icon: 'icon-ml-warning',
      message: '',
      title: `¿Quieres comunicar la evaluación a ${fullNameEvaluated}?`,
      type: 'success',
      buttons: getButtonsAlertMessagesByStatus('Sí, comunicar', primaryButtonOnClick, status)
    });
  case VALIDATED:
    return {
      visible: true,
      icon: 'icon-ml-warning',
      message: 'Recuerda que una vez aprobada la evaluación, ésta no podrá ser modificada posteriormente.',
      title: '¿Estás seguro que deseas aprobar la evaluación?',
      type: 'success',
      buttons: getButtonsAlertMessagesByStatus('Aprobar Evaluación', primaryButtonOnClick, status)
    };
  default:
    return null;
  }
};

export const getSuccessTitleByStatus = (status, fullNameEvaluated, evaluatorNameAndSurname) => {
  switch (status) {
  case COMMUNICATED:
    return `¡Se comunicó con éxito la evaluación a ${capitalize(fullNameEvaluated)}`;
  case VALIDATED:
    return 'Se aprobó la evaluación con éxito.';
  case REJECTED:
    return `Se envió la evaluación rechazada a ${capitalize(evaluatorNameAndSurname)}`;
  default:
    return '';
  }
};

export const statusUpdatedSuccessfully = (edd, setModalMessage) => {
  if (!edd) return false;
  const { data } = edd;
  const { updateStatusEDD = {} } = data || {};
  setModalMessage({
    visible: true,
    title: getSuccessTitleByStatus(
      updateStatusEDD.status,
      updateStatusEDD.evaluated.name,
      updateStatusEDD.evaluator.name
    ),
    type: 'success',
    buttons: [
      {
        text: 'Cerrar',
        type: 'primary',
        onClickButton: () => {
          setModalMessage({ visible: false });
        }
      }
    ]
  });
  return true;
};

export const getLoadingTitleByStatus = (status) => ` ${status === VALIDATED ? 'Aprobando' : 'Comunicando'} evaluación`;

export const MESSAGE_PERFORMANCE_EVALUATION_WITH_ERRORS = 'Por favor comunicate con el equipo de Mi Lugar, la actual evaluación de desempeño de este coloborador presenta problemas';

export const showEvaluation = (viewAs, evaluation) => {
  if (viewAs === VIEW_AS_EVALUATED) {
    return evaluation?.status === COMMUNICATED;
  }
  if ([VIEW_AS_EVALUATOR, VIEW_AS_VALIDATOR].includes(viewAs)) {
    return [EVALUATED, VALIDATED, COMMUNICATED].includes(evaluation?.status);
  }
  return false;
};

export const showAutoEvaluation = (autoEvaluation) => autoEvaluation?.status === COMMUNICATED;

export const getMessageUserDidAutoEvaluation = (autoEvaluation) => {
  if (!autoEvaluation) return null;
  return <span>
    <span className='text-bold'>{capitalize(autoEvaluation.evaluated?.name)} ya realizó su autoevaluación. </span>
  Podrás ver cómo percibe su desempeño a medida que avances en su evaluación.
  </span>;
};
export const getMessagesUserEnableds = (evaluation) => (
  <span>
    <span className='text-bold'>
      {capitalize(evaluation.evaluated?.name)}
      {' '}no esta habilitado al proceso de Evaluación de Desempeño porque no cumple con los requisitos.
    </span>
    <span>{' '}Para más información, comunícate por mail con el equipo de <span className='text-bold'>Gestión del Desempeño {'<gestiondeldesempeno@walmart.com>'}</span>.</span>
  </span>
);

import { PERFORMANCE_YEAR } from '.';

const getDescriptionObjectivesData = ({
  can, bossName, isMobile, bossParams
}) => {
  const { selectedUser, bossBoss } = bossParams || {};
  const selectedUserName = selectedUser && selectedUser.name;
  const isBoss = !!bossParams;
  const closedObjectivesMsg = isBoss ? `Estos son los objetivos de ${selectedUser.name} fijados para el ${PERFORMANCE_YEAR}` : 'Estos son los objetivos fijados para el año.';

  if (isBoss) {
    if (can.showMessagePendingByBoss) {
      return {
        description: `${selectedUser.name} no puede fijar sus objetivos dado que no has cerrado los tuyos.`
      };
    }
    if (can.showMessageFirstPulsePendingByColaborate) {
      return {
        description: `${selectedUser.name} aún no ha fijado sus objetivos`
      };
    }
    if (can.showMessageFirstPulsePendingByUploadObjectives) {
      return {
        description: `No se le han cargaron a ${selectedUser.name} sus objetivos de forma centralizada.`
      };
    }
    if (can.objectiveClosedButAdvanceNotSent && !can.notPassPerformance) {
      return {
        description: `${selectedUser.name} no ha enviado el avance de su desempeño para este pulso.`
      };
    }
  }

  if (can.showObjetivesError) {
    if (isBoss) {
      return {
        title: `${selectedUser.name} no tiene acceso a desempeño`
      };
    }
    return {
      title: 'No tienes acceso a este modulo'
    };
  }
  if (!isBoss && bossName === null) {
    return {
      title: 'Tu jefatura no está cargada en nuestro sistema',
      description: 'y esto es necesario para que puedas comenzar tu proceso de Desempeño. Consulta con tu People Business Partner o Jefe de Recursos Humanos para regularizar esta situación'
    };
  }
  if (can.showWithFixedObjectives) {
    if (isBoss && can.showErrorPerformanceWithFixedObjective) {
      return {
        title: `${selectedUser.name} tiene objetivos centralizados`,
        description: ' su desempeño debió quedar cerrado cuando subieron sus objetivos'
      };
    }
    if (!isBoss && can.showErrorPerformanceWithFixedObjective) {
      return {
        title: 'Comunícate con tu jefe ya que tus objetivos debieron quedar cerrados',
        description: ' cuando te cargaron los objetivos de forma centralizada'
      };
    }
    if (!can.showPerformanceTable || can.userCentralizadoWithoutObjectives) {
      return {
        title:
        bossParams ? `Los objetivos de ${selectedUser.name} aún no han sido cargados de forma centralizada.`

          : 'Tus objetivos se cargaran de forma centralizada.'
      };
    }
    return {
      title: closedObjectivesMsg
    };
  }

  if (can.editAdvanceAfterRejectedByBoss) {
    return {
      description: `Tus objetivos fueron devueltos por ${bossName}. Debes enviar nuevamente la propuesta, agrega el avance del pulso anterior.`
    };
  }

  if (can.showReturnedMessage) {
    return {
      title: `Devolviste a ${selectedUserName} su propuesta de Desempeño.`,
      description: 'Podrá editarla y una vez que finalice con las correcciones, debe volver a enviártela para tu validación.'
    };
  }

  if (can.showReturnedInfoMessage) {
    return {
      description: `${bossName} te devolvió la propuesta para que la edites. Modifica los objetivos y agrega el avance a las medidas de éxito.`
    };
  }

  if (can.sendToBoss || can.waitByBossApprove) {
    if (can.showTouchedMessages) {
      return {
        description: can.addObjectives ? (
          `Finaliza tu propuesta de objetivos${isMobile ? ' desde el computador ' : ' '}y envíasela a ${bossName} para su validación.`
        ) : (
          `Agrega avances ${isMobile ? 'desde tu computador' : ''} a las medidas de éxito de tus objetivos y luego conversa con ${bossName}.`
        )
      };
    }

    return {
      description: `Estos son los objetivos de ${bossName}, úsalos como base para armar y definir los tuyos${isMobile ? ' desde el computador' : '. Edítalos para agregar ponderación y medidas de éxito. Elimina objetivos en los que no trabajes y agrega otros nuevos. Cuando finalices, envíaselos para su validación'}.`
    };
  }

  if (bossParams && !can.showPerformanceTable) {
    if (!can.showWithoutPulses) {
      return {
        title: `Invita a ${selectedUserName} para que cree su desempeño correspondiente al ${PERFORMANCE_YEAR}.`
      };
    }

    return can.addObjectivesAsBoss ? {
      title: `${selectedUserName} aún no realizó la propuesta de Desempeño para trabajar durante este año.`,
      description: 'Ofrécele ayuda para definir juntos sus objetivos.'
    } : {
      title: `${selectedUserName} no puede comenzar con su propuesta de Desempeño hasta que la tuya no esté aprobada.`,
      description: `Define tus objetivos y pide a ${bossBoss?.name} que los apruebe para que pueda comenzar con su proceso.`
    };
  }

  if (can.addObjectives) {
    if (isMobile) {
      return {
        title: `Estos son los objetivos de ${bossName}`,
        description: ', úsalos como base para armar y definir los tuyos desde el computador.'
      };
    }
    return {
      title: `Estos son los objetivos de ${bossName}, úsalos como base para armar los tuyos.`,
      description: 'Edítalos para agregar ponderación y medidas de éxito. Elimina objetivos en los que no trabajes y agrega otros nuevos. Cuando finalices, envíaselos para su validación.'
    };
  }

  if (can.showSentToBossaAdvanceMessage) {
    return {
      description: 'El  avance de tu desempeño fue enviado a tu jefatura.'
    };
  }
  if (can.showSentToBossMessage) {
    return {
      description: 'Tus objetivos fueron enviados a tu jefatura para su revisión.'
    };
  }

  if (can.closePerformance) {
    return can.returnPerformance ? {
      title: `Esta es la propuesta de objetivos de ${selectedUserName} para trabajar este año.`,
      description: 'Agrega un comentario de su desempeño para aprobarla o devuelve la propuesta para que edite sus objetivos.'
    } : {
      description: `${selectedUserName} actualizó el avance de sus objetivos y su comentario de desempeño.`
    };
  }

  if (can.notStartPerformance) {
    return {
      title: 'Tu jefatura aún no ha fijado sus objetivos, y eso es necesario para que puedas definir los tuyos.',
      description: `Invita a ${bossName} a hacerlo para que puedas comenzar con tu proceso.`
    };
  }

  if (can.showClosedMessage) {
    return {
      description: closedObjectivesMsg
    };
  }

  return { title: '', description: '' };
};

export default getDescriptionObjectivesData;

/* eslint-disable no-useless-escape */
import React from 'react';
import PropTypes from 'prop-types';
import '../../static/styles/shared/bannerMobile.less';

const Banner = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    children,
    imageBanner
  } = props;

  const style = {
    backgroundImage: `url(${imageBanner})`
  };

  return (
    <div className="banner-mobile-layout">
      <div className="image-banner" style={style} />
      {children}
    </div>
  );
};

Banner.propTypes = {
  imageBanner: PropTypes.string,
  imageAvatar: PropTypes.string
};

Banner.defaultProps = {
  imageBanner: '',
  imageAvatar: ''
};

export default Banner;

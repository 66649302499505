export const actionTypes = {
  GET_OPERATIONS_ACADEMY: 'GET_OPERATIONS_ACADEMY',
  GET_OPERATIONS_ACADEMY_SUCCESS: 'GET_OPERATIONS_ACADEMY_SUCCESS',
  GET_OPERATIONS_ACADEMY_FAILURE: 'GET_OPERATIONS_ACADEMY_FAILURE'
};

export function getOperationsAcademy(error) {
  return {
    type: actionTypes.GET_OPERATIONS_ACADEMY,
    error
  };
}

export function getOperationsAcademySuccess(data) {
  return {
    type: actionTypes.GET_OPERATIONS_ACADEMY_SUCCESS,
    data
  };
}

export function getOperationsAcademyFailure(error) {
  return {
    type: actionTypes.GET_OPERATIONS_ACADEMY_FAILURE,
    error
  };
}

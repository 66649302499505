import React from 'react';
import PropTypes from 'prop-types';

const Edit = ({ onClick }) => (
  <div onClick={onClick} className='container-edit-evaluation'>
    <span>Editar</span>
    <i className='icon-perfil-editar' />
  </div>
);

Edit.propTypes = {
  onClick: PropTypes.func
};

Edit.defaultProps = {
  onClick: () => {}
};

export default Edit;

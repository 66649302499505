import React, { useState } from 'react';
import '../../../../static/styles/shared/MLHistorical/historical.less';
import Historical from '../../../shared/MLHistorical';
import getURLParams from '../../../../helpers/routing/getURLParams';
import DetailProfile from './DetailProfile';
import LeftComponents from './HistoricalLeftComponents';

const HistoricalLeftComponents = () => {
  const query = getURLParams();
  const ucmSelected = query.get('ucm');
  const [profileSelected, setProfileSelected] = useState(null);
  const leftScrollComponents = [
    <LeftComponents
      key={1}
      {...{
        ucmSelected,
        profileSelected,
        setProfileSelected
      }} />
  ];

  return (
    <div>
      {
        <Historical
          leftScrollComponents={leftScrollComponents}
        >
          {
            ucmSelected && profileSelected && profileSelected.ucm.toString() === ucmSelected
            && <DetailProfile skills={profileSelected.skills} />
          }
        </Historical>
      }
    </div>
  );
};

export default HistoricalLeftComponents;

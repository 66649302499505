export const actionTypes = {
  SET_REQUEST_BY_CODE: 'SET_REQUEST_BY_CODE',
  SET_REQUEST_BY_CODE_SUCCESS: 'SET_REQUEST_BY_CODE_SUCCESS',
  SET_REQUEST_BY_CODE_ERROR: 'SET_REQUEST_BY_CODE_ERROR',

  RESET_REQUEST_BY_CODE: 'RESET_REQUEST_BY_CODE',

  GET_DETAILS_BY_CODE_REPORTS_HISTORY: 'GET_DETAILS_BY_CODE_REPORTS_HISTORY',
  GET_DETAILS_BY_CODE_REPORTS_HISTORY_SUCCESS: 'GET_DETAILS_BY_CODE_REPORTS_HISTORY_SUCCESS',
  GET_DETAILS_BY_CODE_REPORTS_HISTORY_ERROR: 'GET_DETAILS_BY_CODE_REPORTS_HISTORY_ERROR'
};

export function setRequestByCode(code) {
  return {
    type: actionTypes.SET_REQUEST_BY_CODE,
    code
  };
}

export function setRequestByCodeError(error) {
  return {
    type: actionTypes.SET_REQUEST_BY_CODE_ERROR,
    error
  };
}

export function setRequestByCodeSuccess(payload) {
  return {
    type: actionTypes.SET_REQUEST_BY_CODE_SUCCESS,
    payload
  };
}

export function getDetailsByCodeReportsHistory(code) {
  return {
    type: actionTypes.GET_DETAILS_BY_CODE_REPORTS_HISTORY,
    code
  };
}

export function getDetailsByCodeReportsHistoryError(error) {
  return {
    type: actionTypes.GET_DETAILS_BY_CODE_REPORTS_HISTORY_ERROR,
    error
  };
}

export function getDetailsByCodeReportsHistorySuccess(payload) {
  return {
    type: actionTypes.GET_DETAILS_BY_CODE_REPORTS_HISTORY_SUCCESS,
    payload
  };
}

export function resetRequestByCode() {
  return {
    type: actionTypes.RESET_REQUEST_BY_CODE
  };
}

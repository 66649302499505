import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import '../../../static/styles/shared/cards/transparent-card.less';

const TransparentCard = ({ children, className, loading }) => {
  const getClassName = () => {
    let finalClassName = 'transparent-card';
    if (className) finalClassName += ` ${className}`;
    return finalClassName;
  };

  return (
    <Card className={getClassName()} loading={loading}>
      {children}
    </Card>
  );
};

TransparentCard.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  loading: PropTypes.bool
};

TransparentCard.defaultProps = {
  children: <></>,
  className: '',
  loading: false
};

export default TransparentCard;

import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import '../../../static/styles/shared/tables/mlTable.less';

const MLTable = ({ columnAndData: { columns, srcData }, ...rest }) => (
  <div className='ml-table'>
    <Table
      columns={columns}
      dataSource={srcData}
      pagination={false}
      {...rest}
    />
  </div>
);

MLTable.propTypes = {
  columnAndData: PropTypes.object
};

MLTable.defaultProps = {
  columnAndData: {}
};

export default MLTable;

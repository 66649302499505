import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import Edit from '../Edit';

const Skill = (props) => {
  const {
    handelEditEvaluation,
    showEdit,
    index,
    skill
  } = props;
  if (!skill) return skill;

  const { categorySelected = {} } = skill || {};

  const [open, setOpen] = useState(false);
  const openModal = (e) => {
    e.preventDefault();
    handelEditEvaluation(index, 'skill');
  };

  const handleSkill = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <div className='body-summary'>
      <div className='column-evaluation-summary left'>
        <div className='container-title-skill'>

          <div
            className='container-title-icon'
            onClick={handleSkill} >

            <div className='container-icon'>
              <span className={`fa fa-${open ? 'minus' : 'plus'}`} />
            </div>
            <p>{skill.name} </p>
          </div>

          { showEdit
          && <Edit onClick={openModal} />
          }
        </div>
        {
          open
        && <div>
          <div className='description left'>{
            (
              <>
                {skill.description
              && <p className='description'>{skill.description}</p>
                }

                {skill.behaviors
                && map(skill.behaviors, (behavior, indexBehavior) => (
                  <div key={indexBehavior}>
                    <h6 className='description'>{behavior.title}</h6>
                    <p className='description'>{behavior.description}</p>
                  </div>))}
              </>
            )
          }</div>
        </div>
        }
      </div>
      <div className='column-evaluation-summary right'>
        <div className='container-title-category' >
          <p className='text-category'>{categorySelected?.name}</p>
          { showEdit
          && <Edit onClick={openModal} />
          }
        </div>
        {
          open
        && <div>
          <p className='description'>{categorySelected?.skillDescription}</p>
        </div>
        }
      </div>
    </div>
  );
};

Skill.propTypes = {
  columnsNames: PropTypes.array,
  name: PropTypes.string,
  nameDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  value: PropTypes.any,
  valueDescription: PropTypes.string,
  showEdit: PropTypes.bool,
  handelEditEvaluation: PropTypes.func,
  skill: PropTypes.object
};

Skill.defaultProps = {
  columnsNames: [],
  name: '',
  nameDescription: '',
  value: 0,
  valueDescription: '',
  showEdit: false,
  handelEditEvaluation: () => {},
  skill: null
};

export default Skill;

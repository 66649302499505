import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { first } from 'lodash';
import '../../../static/styles/shared/MLTabs.less';

const { TabPane } = Tabs;

export const setUrl = (value) => {
  if (typeof value === 'string') {
    return value.replace(/\s/g, '-').toLowerCase();
  }
  return '';
};

const MLTabsByRoute = (props) => {
  const history = useHistory();
  const {
    options, defaultActiveKey, hideTabs, className,
    tabsOnly
  } = props;

  const activeTab = props.activeTab || first(options).key || 0;

  const onTabClick = (i) => {
    history.replace(i);
  };

  const tabClassName = `${activeTab.replace('/', '').replace(/\//g, '-')}-tab`;

  return (

    <Tabs
      type='line'
      onTabClick={onTabClick}
      activeKey={activeTab}
      defaultActiveKey={defaultActiveKey}
      className={`${className} ${tabClassName} tabs${hideTabs ? ' hide-tabs' : ''}`}
    >
      { options.map((option, index) => (
        <TabPane
          tab={option.title}
          key={option.key || index}
          className={`content-tab ${option.className}`}
        >
          {
            !tabsOnly && option.children
          }
        </TabPane>
      ))}
    </Tabs>
  );
};
MLTabsByRoute.propTypes = {
  options: PropTypes.array,
  defaultActiveKey: PropTypes.string,
  className: PropTypes.string
};

MLTabsByRoute.defaultProps = {
  options: [],
  defaultActiveKey: '0',
  className: ''
};

export default MLTabsByRoute;

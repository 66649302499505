import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import { getOperationsAcademyAPI } from '../api/operationsAcademy';
import { actionTypes, getOperationsAcademySuccess, getOperationsAcademyFailure } from '../actions/operationsAcademy';
import {
  failure
} from '../actions/commons';

es6promise.polyfill();

export function* getContent() {
  try {
    const response = yield call(getOperationsAcademyAPI);
    yield put(getOperationsAcademySuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(getOperationsAcademyFailure(error));
    yield put(failure({ error, statusCode }));
  }
}

function* operationsAcademySagas() {
  yield all([
    takeLatest(actionTypes.GET_OPERATIONS_ACADEMY, getContent)
  ]);
}

export default operationsAcademySagas;

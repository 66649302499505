import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import BannerLayout from '../../../layouts/banner';
import Uploads from '../../../components/admin/mievolucion/desempeno/Uploads';
import Search from '../../../components/admin/mievolucion/desempeno/Search';
import Reports from '../../../components/admin/mievolucion/desempeno/Reports';
import { completePath } from '../../../helpers/strings';
import MLTabs from '../../../components/shared/MLTabs/MLTabsByRoute';

const Performance = (props) => {
  const { permissions } = props;
  const { pathname } = useLocation();
  const banner = {
    imageBanner: completePath('web/mi-evolucion/banner/midesempeno-banner.jpg'),
    imageAvatar: completePath('web/mi-evolucion/avatars/midesempeno-avatar.jpg'),
    title: 'Mi Desempeño',
    subtitle: 'Mi Evolución',
    showBanner: !isMobile
  };
  const {
    ADMIN_MI_EVOLUCION_PERFORMANCE_UPLOAD_FILES = false,
    ADMIN_MI_EVOLUCION_PERFORMANCE_VISUALIZE = false,
    ADMIN_MI_EVOLUCION_PERFORMANCE_REPORTS = false
  } = permissions;

  const primaryTabs = [
    ...(ADMIN_MI_EVOLUCION_PERFORMANCE_UPLOAD_FILES ? [
      {
        title: 'Cargar Archivos',
        key: '/administrador/mi-evolucion/desempeño/cargar-archivos',
        children: <Uploads />
      }
    ] : []),
    ...(ADMIN_MI_EVOLUCION_PERFORMANCE_VISUALIZE ? [
      {
        title: 'Visualizar',
        key: '/administrador/mi-evolucion/desempeño/visualizar',
        children: <Search />
      }
    ] : []),
    ...(ADMIN_MI_EVOLUCION_PERFORMANCE_REPORTS ? [
      {
        title: 'Reportes',
        key: '/administrador/mi-evolucion/desempeño/reportes',
        children: <Reports />
      }
    ] : [])
  ];

  const getActiveKey = () => {
    if (pathname === '/administrador/mi-evolucion/desempeño') {
      const { key } = [primaryTabs];
      return key;
    }
    return pathname;
  };
  return (
    <BannerLayout {...banner}>
      <MLTabs
        options={primaryTabs}
        defaultActiveKey={getActiveKey()}
        activeTab={getActiveKey()}
      />
    </BannerLayout>
  );
};

Performance.propTypes = {
  permissions: PropTypes.object
};

Performance.defaultProps = {
  permissions: {}
};
export default Performance;

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GET_PERFORMANCE_WALMART, GET_CACHED_PERFORMANCE_WALMART } from '../../../graphql/mievolucion/myPerformance/queries';

const usePerformance = () => {
  const [loading, setLoading] = useState(false);
  const {
    linealBoss: linealBossSrc
  } = useSelector((state) => state.userReducer.data);
  const { rut, name, fatherLastName } = linealBossSrc || {};
  const linealBoss = { rut, name: `${name} ${fatherLastName}` };
  const context = { clientName: 'myEvolution' };
  const query = GET_PERFORMANCE_WALMART;
  const cachedQuery = GET_CACHED_PERFORMANCE_WALMART;
  const apolloClient = useApolloClient();

  const [
    callApi,
    {
      data,
      error
    }
  ] = useLazyQuery(
    query,
    {
      context,
      fetchPolicy: 'no-cache'
    }
  );

  const cached = apolloClient.readQuery({ query: cachedQuery });

  const updateCached = (newData) => apolloClient.writeQuery({
    query: cachedQuery, data: { getCachedPerformanceWalmart: newData }
  });

  const callApiSave = async (params) => {
    setLoading(true);
    let response = {};

    try {
      response = await callApi(params);
      updateCached(response.data.getPerformanceWalmart);
    } catch (err) {
      updateCached(null);
    } finally {
      setLoading(false);
    }

    return response;
  };

  const resData = data?.getPerformanceWalmart || cached?.getCachedPerformanceWalmart;

  useEffect(() => {
    if (data) updateCached(data.getPerformanceWalmart);
  }, [data]);

  return {
    callApi: callApiSave,
    data: resData && {
      ...resData,
      performanceBoss: resData.boss,
      boss: linealBoss
    },
    loading,
    error
  };
};
export default usePerformance;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import {
  getPillarsAPI, getPillarAPI, getFlickrAlbumAPI, getSocialWorkersApi, postRecipeApi
} from '../api/liveHealthy';
import {
  actionTypes,
  getPillarSuccess,
  getPillarsSuccess,
  getFlickrAlbumLiveSuccess,
  getSocialWorkersSuccess,
  postRecipeSuccess
} from '../actions/LiveHealthy';
import {
  failure, loading, loadComplete
} from '../actions/commons';

es6promise.polyfill();

function* formRecipe(data) {
  try {
    yield put(loading());
    const response = yield call(postRecipeApi, data.data);
    yield put(postRecipeSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
    if (error === 'Item no encontrado') {
      window.location.push('/error');
    }
  }
}

function* pillar(item) {
  try {
    yield put(loading());
    const response = yield call(getPillarAPI, item);
    yield put(getPillarSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
    if (error === 'Item no encontrado') {
      window.location.push('/error');
    }
  }
}

function* pillars() {
  try {
    yield put(loading());
    const response = yield call(getPillarsAPI);
    yield put(getPillarsSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
    if (error === 'Item no encontrado') {
      window.location.push('/error');
    }
  }
}

function* flickrAlbumLive(actions) {
  try {
    yield put(loading());
    const response = yield call(getFlickrAlbumAPI, actions.payload);
    yield put(getFlickrAlbumLiveSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* getSocialWorkers(actions) {
  try {
    yield put(loading());
    const response = yield call(getSocialWorkersApi, actions);
    yield put(getSocialWorkersSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* liveHealthySagas() {
  yield all([
    takeLatest(actionTypes.GET_PILLAR, pillar),
    takeLatest(actionTypes.GET_PILLARS, pillars),
    takeLatest(actionTypes.POST_RECIPE, formRecipe),
    takeLatest(actionTypes.GET_FLICKR_ALBUM_LIVE, flickrAlbumLive),
    takeLatest(actionTypes.GET_SOCIAL_WORKERS, getSocialWorkers)
  ]);
}

export default liveHealthySagas;

import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from './LinkButton';
import '../../../static/styles/shared/buttons/add-button.less';

const MLAddButton = ({
  text, onClick, className
}) => {
  const getClassName = () => {
    let classNameResult = 'ml-add-button';
    if (className) classNameResult += ` ${className}`;
    return classNameResult;
  };

  return (
    <LinkButton {...{
      text, onClick, className: getClassName(), iconClassName: 'fa fa-plus-circle'
    }}/>
  );
};

MLAddButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

MLAddButton.defaultProps = {
  text: 'Agregar',
  className: '',
  onClick: () => null
};

export default MLAddButton;

import React, { useState, useEffect } from 'react';
import socketio from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { getEnvVar } from '../../helpers/commons';
import { updateReportStatus } from '../../redux/actions/admin';

export const SocketContext = React.createContext();

export default ({ children }) => {
  let ws = {};
  const userData = useSelector((state) => state.userReducer.data);
  const [connected, setConnected] = useState(false);
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!socket && userData) {
      setSocket(socketio.connect(
        getEnvVar('REACT_APP_API_REPORTS_SOCKET'),
        { path: getEnvVar('REACT_APP_API_REPORTS_SOCKET_PATH') }
      ));
    }
    if (socket && !userData) {
      socketio.disconnect();
      setSocket(null);
    }
  }, [userData]);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => setConnected(true));
      socket.on('disconnect', () => setConnected(false));

      socket.on('reports', (msg) => dispatch(updateReportStatus(msg)));
    }
  }, [socket]);

  ws = {
    socket,
    connected
  };

  return (
    <SocketContext.Provider value={ws}>
      {children}
    </SocketContext.Provider>
  );
};

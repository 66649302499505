import { maskCurrency } from '../filters/salaryMyTeam';

export const filtersInitialState = {
  position: '',
  dateOld: [],
  positionOld: [],
  band: [],
  salaryFrom: '',
  salaryTo: '',
  positioning: [],
  performanceCategory: [],
  talentCategory: [],
  totalApplied: 0
};

export function filtersReducer(state, action) {
  switch (action.field) {
  case 'salaryFrom':
  case 'salaryTo':
    return {
      ...state,
      [action.field]: /\d/.test(action.value) ? maskCurrency(action.value)
        : ''
    };
  case 'totalApplied':
    return {
      ...state,
      totalApplied: action.value
    };
  case 'reset':
    return action.value ? action.value : filtersInitialState;
  default:
    return {
      ...state,
      [action.field]: action.value
    };
  }
}

export const checkboxInitialState = {
  band: [],
  positioning: ['Menor a 80%', '80% a 99%', '100% a 120%', 'Mayor a 120%'],
  performanceCategory: [],
  talentCategory: []
};

export function checkboxReducer(state, action) {
  switch (action.type) {
  case 'set':
    return {
      ...state,
      ...action.data
    };
  default:
    return state;
  }
}

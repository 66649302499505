/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { logEvent } from '../../helpers/googleAnalytics';

const MLLink = (props) => {
  const regexURL = /((http|ftp|https):\/\/)/;
  const {
    children, target, to, className, analyticEvent
  } = props;

  const onClick = () => {
    if (analyticEvent && analyticEvent.length) logEvent(...analyticEvent);
    if (props.onClick && typeof props.onClick === 'function') props.onClick();
  };

  if (regexURL.test(to)) {
    return (
      <a data-testid='external-anchor'
        href={to}
        target={target || '_blank'}
        className={className}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }
  return (
    <Link
      to={to}
      target={target}
      className={className}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

MLLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array
  ]),
  target: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
  analyticEvent: PropTypes.array
};

MLLink.defaultProps = {
  children: [<></>],
  target: '_self',
  to: '#',
  className: '',
  analyticEvent: []
};

export default MLLink;

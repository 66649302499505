import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useRouteMatch } from 'react-router-dom';
import MLSecondaryTabs from '../../../../shared/MLTabs/MLSecondaryTabs';
import { smallDevice } from '../../../../../helpers/deviceVariables';
import MobileList from '../../../../shared/list/MobileList';
import Historical from './Historical';
import { useWindowWidthSize } from '../../../../../helpers/browser';
import { EDD_URL } from '../../../../../helpers/myEvolution/myPerformance';
import InfoEvaluations from '../InfoEvaluations';
import InfoMessage from '../../../../shared/InfoMessage';

const Evaluations = (props) => {
  const {
    GetProfile
  } = props;

  const width = useWindowWidthSize();
  const isMobile = width <= smallDevice;
  const [hidden, setHidden] = useState(isMobile);
  const [messageEDD, setMessageEDD] = useState('');
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  useEffect(() => {
    if (pathname === path) {
      setHidden(false);
    }
  }, [pathname, path]);

  const { children } = props;
  const buildSecondaryTabs = () => ([
    {
      title: 'Historial',
      key: `${EDD_URL}/historial`,
      children: <Historical />
    }
  ]);

  const renderDefaultSecondaryTab = () => {
    if (pathname === path
      && children) {
      const { props: { routes } } = children;
      const [defaultComponent] = routes;
      return <defaultComponent.component />;
    }
    return null;
  };

  const listOptions = [
    {
      label: 'Historial',
      to: `${EDD_URL}/historial`,
      onClick: () => setHidden(true)
    }
  ];
  const activeSecundaryTab = `${EDD_URL}/historial`;

  return (
    <div>
      { width <= smallDevice && !hidden
      && <div>
        <GetProfile />
        <InfoEvaluations userHasEvaluations />
        <MobileList listOptions={listOptions} />
      </div>
      }

      {!hidden
        && <div className='d-none d-sm-block'>
          <GetProfile />
          { messageEDD
          && <div>
            <InfoMessage
              color='warning'
              classes={'container-message-evaluations'}
              message = { messageEDD }
            />
          </div>
          }

          <MLSecondaryTabs
            options={buildSecondaryTabs()}
            defaultActiveKey={activeSecundaryTab}
            activeTab={activeSecundaryTab}
          />
        </div>
      }
      {
        (hidden && isMobile) || (!isMobile
        ) ? <Historical setMessageEDD={setMessageEDD} /> : null
      }
      <div >
        {children}
      </div>

      <div>
        { hidden && renderDefaultSecondaryTab()}
      </div>

    </div>
  );
};

Evaluations.propTypes = {
  GetProfile: PropTypes.element
};

Evaluations.defaultProps = {
  GetProfile: <></>
};
export default Evaluations;

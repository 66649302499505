import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';
import { getErrorDetails } from '../../helpers/axios/getStatusDetails';

const REPORTS_BFF_ENDPOINT = getEnvVar('REACT_APP_API_REPORTS');
const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

// Admin
export const getUniformsByRutReportAPI = (data) => axios().get(`${REPORTS_BFF_ENDPOINT}/uniforms/generate-report-by-store/${data}`).then((resp) => resp.data);

export const getUniformsByGarmentReportAPI = (data) => axios().get(`${REPORTS_BFF_ENDPOINT}/uniforms/generate-report-by-solicitude/${data}`).then((resp) => resp.data);

// Profile
export const getUniformsAPI = (data = null) => {
  if (data) {
    const { codDivision, rut } = data;
    return axios().get(`${BFF_ENDPOINT}/user/uniforms?rut=${rut}&codDivision=${codDivision}`).then((resp) => resp.data);
  }
  return axios().get(`${BFF_ENDPOINT}/user/uniforms`).then((resp) => resp.data);
};
export const updateUniformsAPI = (data) => {
  const { codDivision, rut } = data;
  if (codDivision && rut) {
    return axios().post(`${BFF_ENDPOINT}/user/uniforms?rut=${rut}&codDivision=${codDivision}`, data).then((resp) => resp.data);
  }
  return axios().post(`${BFF_ENDPOINT}/user/uniforms`, data).then((resp) => resp.data);
};

export const getUniformsAdminAPI = (codDivision) => axios().get(`${BFF_ENDPOINT}/admin/uniforms/${codDivision}`).then((resp) => resp.data);

export const getUniformsPermissionsAPI = (codDivision) => axios().get(`${BFF_ENDPOINT}/admin/uniforms/${codDivision}/permissions`).then((resp) => resp.data);

export const deleteUniformsPermissionsAPI = ({
  idConventionGroup: id,
  selectedStore: codDivision
}) => axios().delete(`${BFF_ENDPOINT}/admin/uniforms/${codDivision}/permissions/${id}`, { codDivision, id }).then((resp) => resp.data);

export const updateConventionGroupAPI = ({
  permissionsToSave,
  selectedStore: codDivision,
  _id
}) => axios()
  .post(`${BFF_ENDPOINT}/admin/uniforms/${codDivision}/permissions${_id ? `/${_id}` : ''}`, permissionsToSave)
  .then((resp) => resp.data)
  .catch((err) => getErrorDetails(err));

export const getUniformsDeliveriesAPI = ({ codDivision, rut }) => axios().get(`${BFF_ENDPOINT}/admin/uniforms/${codDivision}/deliveries/${rut}`).then((resp) => resp.data);

export const setUniformsDeliveriesAPI = ({ codDivision, deliveryId, items }) => axios().post(`${BFF_ENDPOINT}/admin/uniforms/${codDivision}/deliveries/${deliveryId}`, { items }).then((resp) => resp.data);

import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Select, Input
} from 'antd';
import InfoMessage from '../../components/shared/InfoMessage';
import MLError from '../../components/shared/Error';
import { bankAccountMessage } from '../../components/help/messages';
import MLLoading from '../../components/shared/MLLoading';

const { Option } = Select;
const ACCOUNT_TYPES = ['Corriente', 'Vista', 'Ahorro', 'Chequera electrónica'];

const BankAccountForm = (props) => {
  const {
    bankList,
    loading,
    error,
    setBankAccountData,
    bankAccountData,
    getBanks
  } = props;
  if (loading) {
    return <MLLoading title={'Cargando listado de bancos'}/>;
  }
  if (error) {
    return <MLError
      reload={getBanks}
      title='Ocurrió un error al cargar el listado de bancos, por favor intenta más tarde.' />;
  }

  const handleBankList = (bankID) => {
    setBankAccountData({ ...bankAccountData, bankID });
  };
  const handleAccountType = (accountType) => {
    setBankAccountData({ ...bankAccountData, accountType });
  };
  const onChangeAccountNumber = (event) => {
    setBankAccountData({ ...bankAccountData, accountNumber: event.target.value });
  };

  return (
    <div>
      <Form.Item>
        <div className="help-info-message">
          <InfoMessage
            color='info-message-suave'
            suave={true}
            classes="mt-3"
            message={bankAccountMessage} />
        </div>
      </Form.Item>
      <Form.Item label="Banco">
        <Select
          placeholder="Seleccionar un banco"
          onChange={(bankID) => { handleBankList(bankID); }}
        >
          {bankList.map((item) => (
            <Option key={item._id} value={item._id}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Tipo de cuenta">
        <Select
          placeholder="Seleccionar tipo de cuenta"
          onChange={(accountType) => { handleAccountType(accountType); }}
        >
          {ACCOUNT_TYPES.map((accountType) => (
            <Option key={accountType} value={accountType}>{accountType}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Número de cuenta">
        <Input
          onChange={ (event) => onChangeAccountNumber(event)}
          placeholder='Ingresar Número de cuenta' />
      </Form.Item>
    </div>
  );
};

BankAccountForm.propTypes = {
  bankList: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  setBankAccountData: PropTypes.func,
  bankAccountData: PropTypes.object,
  getBanks: PropTypes.func
};

BankAccountForm.defaultProps = {
  bankList: [],
  loading: false,
  error: false,
  setBankAccountData: () => {},
  bankAccountData: {},
  getBanks: () => {}
};

export default BankAccountForm;

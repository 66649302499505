import { actionTypes } from '../actions/compensations';

export const initialState = {
  salary: {
    loading: false,
    finished: false,
    error: false
  },
  historicalBonus: {
    resume: {
      loading: false,
      finished: false,
      error: false
    },
    details: {
      loading: false,
      finished: false,
      error: false
    }
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_SALARY:
    return {
      ...state,
      salary: {
        loading: true,
        error: false
      }
    };
  case actionTypes.GET_SALARY_SUCCESS: {
    return {
      ...state,
      salary: {
        loading: false,
        finished: true,
        data: action.data
      }
    };
  }
  case actionTypes.GET_HISTORICAL_BONUS:
    return {
      ...state,
      historicalBonus: {
        ...state.historicalBonus,
        loading: true,
        error: false
      }
    };
  case actionTypes.GET_HISTORICAL_BONUS_SUCCESS: {
    return {
      ...state,
      historicalBonus: {
        ...state.historicalBonus,
        loading: false,
        finished: true,
        data: action.data
      }
    };
  }
  case actionTypes.COMPENSATIONS_ERROR:
    return {
      ...state,
      error: true,
      loading: false,
      finished: true
    };
  case actionTypes.COMPENSATIONS_RESET_DATA:
    return initialState;
  default:
    return state;
  }
}

export default reducer;

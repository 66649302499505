/* eslint-disable react/prop-types */

import React from 'react';
import { Input } from 'antd';
import '../../../static/styles/shared/forms/styles.less';

const InputComponent = (props) => (

  <Input {...props} />

);
export default InputComponent;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Loader from 'react-loaders';

import 'loaders.css/loaders.css';

export const Loading = <Loader type="ball-pulse" color="#007bc4" />;
class LoaderComponent extends Component {
  render() {
    const { height, actReport } = this.props;
    return (
      <Row>
        <Col>
          <div className={`d-flex align-items-center justify-content-center ${actReport || ''}`} style={{ height }}>
            {Loading}
          </div>
        </Col>
      </Row>
    );
  }
}

LoaderComponent.propTypes = {
  height: PropTypes.string
};

LoaderComponent.defaultProps = {
  height: '35vh'
};

export default LoaderComponent;

/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { message } from 'antd';
import * as storeUtil from './cookiesUtil';
import { getEnvVar } from './commons';

const getVersionNumber = (versionString) => parseInt(versionString.split('.').join(''), 0);

const isPWA = () => window.matchMedia('(display-mode: standalone)').matches;

const getMessage = () => (isPWA()
  ? '¡Tu app ha sido actualizada con éxito!'
  : '¡Contenido actualizado!');

export const getManifestVersion = async () => {
  const { data: manifest } = await axios.get(
    `${getEnvVar('PUBLIC_URL')}/manifest.json`
  );
  return manifest.version || '';
};

export const checkVersions = async () => {
  const { data: manifest } = await axios.get(
    `${getEnvVar('PUBLIC_URL')}/manifest.json`
  );

  if (storeUtil.getItem('reloaded') === 'yes') message.success(getMessage(), 5);
  if (!storeUtil.getItem('client-version')) {
    storeUtil.setItem('client-version', manifest.version);
    storeUtil.setItem('reloaded', 'no');
  } else {
    const storageVersion = getVersionNumber(
      storeUtil.getItem('client-version')
    );
    const manifestVersion = getVersionNumber(manifest.version);
    if (storageVersion < manifestVersion) {
      storeUtil.setItem('client-version', manifest.version);
      storeUtil.setItem('reloaded', 'yes');
      window.location.reload();
    } else if (storageVersion > manifestVersion) {
      storeUtil.setItem('client-version', manifest.version);
      storeUtil.setItem('reloaded', 'no');
    } else storeUtil.setItem('reloaded', 'no');
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import DotColor from '../DotColor';
import '../../../../../../../static/styles/shared/MLSpanListVertical/MLSpanListVertical.less';

const MLSpanListVertical = ({ items: { totalWeights, pillarsWeights } }) => (
  <div className="ml-span-list-vertical">
    <div
      className="ml-span-list-vertical-item-bold">
      <span className="title-bold">Ponderación total de objetivos: </span>
      <span>{totalWeights[0].value}</span>
    </div>
    <div className="expanded-separator"/>
    <div
      className="ml-span-list-vertical-item">
      <DotColor color='purple'/>
      <span>Objetivos Transversales: </span>
      <span>{pillarsWeights[0].value}</span>
    </div>
    <div
      className="ml-span-list-vertical-item">
      <DotColor color='green'/>
      <span>Otros: </span>
      <span>{pillarsWeights[1].value}</span>
    </div>
  </div>
);

MLSpanListVertical.propTypes = {
  pillarsWeights: PropTypes.array.isRequired,
  totalWeights: PropTypes.array.isRequired
};

MLSpanListVertical.defaultProps = {
  pillarsWeights: [],
  totalWeights: []
};
export default MLSpanListVertical;

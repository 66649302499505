import axios from 'axios';
import { getEnvVar } from '../commons';
import { getItem } from '../cookiesUtil';

const traceUserVisit = (section) => {
  const token = getItem('token');

  if (section && token) {
    axios({
      method: 'POST',
      url: `${getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT')}/traceability/trace-user-visit`,
      data: {
        section
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
};

export default traceUserVisit;

import React from 'react';
import BannerLayout from '../../../layouts/banner';
import SectionList from '../../../components/shared/sectionList';
import { completePath } from '../../../helpers/strings';

const MyEvolutionAdmin = ({ permissions = {} }) => {
  const {
    ADMIN_MI_EVOLUCION_INTERESES_Y_HABILIDADES = false,
    ADMIN_MI_EVOLUCION_PERFORMANCE = false,
    ADMIN_MI_EVOLUCION_RETRO = false,
    ADMIN_MI_EVOLUCION_MI_DESARROLLO_REPORTS = false
  } = permissions;

  const banner = {
    imageBanner: completePath('web/admin/banner/mi-evolucion-banner.jpg'),
    imageAvatar: completePath('web/admin/avatar/mi-evolucion-avatar.jpg'),
    title: 'Mi Evolución',
    subtitle: 'Mi Evolución'
  };

  const srcList = [
    {
      title: 'Mi Desempeño',
      image: completePath('web/mi-evolucion/servicios/inicio/evaluacion-de-desempeno-EDD-card.jpg'),
      link: '/administrador/mi-evolucion/desempeño',
      visible: ADMIN_MI_EVOLUCION_PERFORMANCE
    },
    {
      title: 'Intereses y habilidades',
      image: completePath('web/mi-evolucion/servicios/inicio/intereses-y-habilidades-card.jpg'),
      link: '/administrador/mi-evolucion/intereses-y-habilidades',
      visible: ADMIN_MI_EVOLUCION_INTERESES_Y_HABILIDADES
    },
    {
      title: 'Retroalimentación',
      image: completePath('web/mi-evolucion/servicios/inicio/evaluacion-de-desempeno-RETRO-card.jpg'),
      link: '/administrador/mi-evolucion/retro',
      visible: ADMIN_MI_EVOLUCION_RETRO
    },
    {
      title: 'Mi Desarrollo',
      image: completePath('web/mi-evolucion/servicios/inicio/evaluacion-de-desempeno-RETRO-card.jpg'),
      link: '/administrador/mi-evolucion/mi-desarrollo',
      visible: ADMIN_MI_EVOLUCION_MI_DESARROLLO_REPORTS
    }
  ];

  return (
    <BannerLayout {...banner}>
      <SectionList category='Documentos' {...{ srcList }} />
    </BannerLayout>
  );
};

export default MyEvolutionAdmin;

export const actionTypes = {
  FAILURE: 'FAILURE',
  GET_PILLARS: 'GET_PILLARS',
  GET_PILLAR: 'GET_PILLAR',
  POST_RECIPE: 'POST_RECIPE',
  POST_RECIPE_SUCCESS: 'POST_RECIPE_SUCCESS',
  GET_PILLARS_SUCCESS: 'GET_PILLARS_SUCCESS',
  GET_PILLAR_SUCCESS: 'GET_PILLAR_SUCCESS',
  GET_FLICKR_ALBUM_LIVE: 'GET_FLICKR_ALBUM_LIVE',
  GET_FLICKR_ALBUM_LIVE_SUCCESS: 'GET_FLICKR_ALBUM_LIVE_SUCCESS',
  GET_SOCIAL_WORKERS: 'GET_SOCIAL_WORKERS',
  GET_SOCIAL_WORKERS_SUCCESS: 'GET_SOCIAL_WORKERS_SUCCESS'
};

export function failure(error) {
  return {
    type: actionTypes.FAILURE,
    error
  };
}

export function postRecipe(data) {
  return {
    type: actionTypes.POST_RECIPE,
    data
  };
}

export function postRecipeSuccess(data) {
  return {
    type: actionTypes.POST_RECIPE_SUCCESS,
    data
  };
}

export function getPillar(item) {
  return {
    type: actionTypes.GET_PILLAR,
    item
  };
}

export function getPillars() {
  return { type: actionTypes.GET_PILLARS };
}

export function getPillarSuccess(data) {
  return {
    type: actionTypes.GET_PILLAR_SUCCESS,
    data
  };
}

export function getPillarsSuccess(data) {
  return {
    type: actionTypes.GET_PILLARS_SUCCESS,
    data
  };
}

export function getFlickrAlbumLive(payload) {
  return {
    type: actionTypes.GET_FLICKR_ALBUM_LIVE,
    payload
  };
}

export function getFlickrAlbumLiveSuccess(data) {
  return {
    type: actionTypes.GET_FLICKR_ALBUM_LIVE_SUCCESS,
    data
  };
}

export function getSocialWorkers() {
  return {
    type: actionTypes.GET_SOCIAL_WORKERS
  };
}

export function getSocialWorkersSuccess(data) {
  return {
    type: actionTypes.GET_SOCIAL_WORKERS_SUCCESS,
    data
  };
}

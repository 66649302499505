import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import '../../../static/styles/shared/buttons/mainBtn.less';

const MainBtn = (props) => {
  const {
    text, mlType, className, width, ...restProps
  } = props;
  return (
    <div style={{ width: width !== 0 && width }}>
      <Button
        data-testid='antd-btn'
        className={`ml-main-btn ${mlType} ${className}`}
        {...restProps}
      >
        {text}
      </Button>
    </div>
  );
};

MainBtn.propTypes = {
  text: PropTypes.string,
  mlType: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string
};

MainBtn.defaultProps = {
  text: '',
  mlType: '',
  className: '',
  width: ''
};

export default MainBtn;

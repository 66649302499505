/* eslint-disable import/prefer-default-export */

import gql from 'graphql-tag';

export const UPDATE_ACADEMIC_TRAINING = gql`
    mutation updateAcademicTraining($input: AcademicTraining!) {
        updateAcademicTraining(input: $input){
            success
        }
    }
`;
export const UPDATE_WORK_EXPERIENCE = gql`
    mutation updateWorkExperience($input: WorkExperience!) {
        updateWorkExperience(input: $input){
            success
        }
    }
`;
export const UPDATE_AREAS = gql`
    mutation updateInterestAreas($input: InteresAreas!) {
        updateInterestAreas(input: $input){
            success
        }
    }
`;

import React from 'react';
import { useDrag } from 'react-dnd';

import '../../../../static/styles/shared/draging/drag/tableDragable.less';

const Dragable = ({
  label, currentX, currentY, handleDrop, id, className, cantDrag, type
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: {
      type, currentX, currentY, id, label, className
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: () => !cantDrag,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        handleDrop({
          item, newX: dropResult.x, newY: dropResult.y
        });
      }
    }
  });

  return (
    <div
      ref={drag}
      className={`table-dragable ${isDragging && 'isDragging'} align-items-center ${className}`}
    >
      <i className="icon-ml-drag-and-drop mr-1"/>
      <span className="d-flex justify-content-center flex-grow-1">{label}</span>
    </div>
  );
};

export default Dragable;

import PropTypes from 'prop-types';
import React from 'react';
import ContiguousBoxes from '../../../../shared/ContiguousBoxes';
import getAdvanceName from '../../../../../helpers/myEvolution/myPerformance/getAdvanceName';
import usePerformance from '../../../../../hooks/myEvolution/myPerformance/usePerformance';
import { MONTH_ADVANCE_COMMENTS_BY_Q } from '../../../../../helpers/myEvolution/myPerformance';

const months = [
  MONTH_ADVANCE_COMMENTS_BY_Q[2]
  // MONTH_ADVANCE_COMMENTS_BY_Q[3] se comentó ya que sólo habrán dos pulsos en el 2023
];

const AdvanceMonthsBoxes = ({ data, isMobile }) => {
  const { data: performance = {} } = usePerformance();
  const { quarterCurrent: currentQuarter } = performance;

  const currentQuarterIndex = Number(currentQuarter?.split('q')?.pop());

  const dataToShow = months.map((month, index) => {
    const qIndex = index + 2;
    const qKey = `q${qIndex}`;

    const advanceBody = (() => {
      const hyphen = qIndex > currentQuarterIndex;
      const advanceBodyResult = !hyphen && getAdvanceName(data[qKey]?.status);
      const withoutInfoMssg = advanceBodyResult === '-' && qKey === currentQuarter && 'Sin Información';

      return (hyphen && '-') || withoutInfoMssg || advanceBodyResult;
    })();

    return { title: month, body: advanceBody };
  });

  return (<ContiguousBoxes data={dataToShow} isMobile={isMobile} />);
};

AdvanceMonthsBoxes.propTypes = {
  data: PropTypes.object,
  isMobile: PropTypes.bool
};

AdvanceMonthsBoxes.defaultProps = {
  data: {
    title: 'Sin Información',
    body: '-'
  },
  isMobile: false
};

export default AdvanceMonthsBoxes;

/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const FEEDBACK_NET_FIELDS = gql`
  fragment FeedbackNetQFields on User {
    area3
    HOOPP
    area1
    area3
    division
    fatherLastName
    motherLastName
    name
    position
    rut
    secondName
    _id
  }

  fragment FeedbackNetFields on Performance {
    feedbackNetwork {
      q1 { ...FeedbackNetQFields }
      q2 { ...FeedbackNetQFields }
      q3 { ...FeedbackNetQFields }
      q4 { ...FeedbackNetQFields }
    }
  }
`;

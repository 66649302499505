import { useMutation } from '@apollo/client';
import { UPDATE_STATUS_PERFORMANCE_WALMART } from '../../../../graphql/mievolucion/myPerformance/mutations';

const updatePerformanceStatus = (options = {}) => {
  const [callApi, performanceStatus] = useMutation(
    UPDATE_STATUS_PERFORMANCE_WALMART,
    { context: { clientName: 'myEvolution' }, ...options }
  );

  return { ...performanceStatus, callApi };
};

export default updatePerformanceStatus;

export const actionTypes = {
  GET_SECTION_FORM: 'GET_SECTION_FORM',
  GET_SECTION_FORM_SUCCESS: 'GET_SECTION_FORM_SUCCESS',
  GET_SECTION_FORM_FAILURE: 'GET_SECTION_FORM_FAILURE',
  POST_SECTION_FEEDBACK: 'POST_SECTION_FEEDBACK',
  POST_SECTION_FEEDBACK_SUCCESS: 'POST_SECTION_FEEDBACK_SUCCESS',
  POST_SECTION_FEEDBACK_FAILURE: 'POST_SECTION_FEEDBACK_FAILURE',
  RESET_SECTION_POST: 'RESET_SECTION_POST',
  SET_SECTION_BUTTON_OFFSET_TOP: 'SET_SECTION_BUTTON_OFFSET_TOP'
};

export function getSectionForm(error) {
  return {
    type: actionTypes.GET_SECTION_FORM,
    error
  };
}

export function getSectionFormSuccess(data) {
  return {
    type: actionTypes.GET_SECTION_FORM_SUCCESS,
    data
  };
}

export function getSectionFormFailure(error) {
  return {
    type: actionTypes.GET_SECTION_FORM_FAILURE,
    error
  };
}

export function postSectionFeedback(data) {
  return {
    type: actionTypes.POST_SECTION_FEEDBACK,
    data
  };
}

export function postSectionFeedbackSuccess(data) {
  return {
    type: actionTypes.POST_SECTION_FEEDBACK_SUCCESS,
    data
  };
}

export function postSectionFeedbackFailure(error) {
  return {
    type: actionTypes.POST_SECTION_FEEDBACK_FAILURE,
    error
  };
}

export function setButtonSectionOffsetTop(data) {
  return {
    type: actionTypes.SET_SECTION_BUTTON_OFFSET_TOP,
    data
  };
}

export function resetSectionPost() {
  return {
    type: actionTypes.RESET_SECTION_POST
  };
}

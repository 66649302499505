import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';

const { TextArea } = Input;
const InputSkill = ({ changeContent, content }) => (
  <TextArea
    placeholder={'Escribe acá tu feedback'}
    maxLength={1000}
    onChange={ (e) => {
      if (changeContent) {
        changeContent(e.target.value);
      }
    }}
    value={content}
  />
);

InputSkill.propTypes = {
  changeContent: PropTypes.func,
  content: PropTypes.string
};

InputSkill.defaultProps = {
  changeContent: () => {},
  content: ''
};

export default InputSkill;

import gql from 'graphql-tag';

const CommunicationPage = {};

CommunicationPage.fragments = {
  letterItem: gql`
  fragment CommunicationPageLetterItem on LetterItem {
      _id
      rut

      sent
      status
      file
      createdAt
      updatedAt
      fullName
      error
    }
  `,
  letterItemData: gql`
  fragment CommunicationPageLetterItemData on LetterItemData {
      managerSignature
      managerPosition
      letterCode
      names
      talentCategory
      evaluationCategory
      band
      currentBaseCompensation
      currentPosition
      incrementPercentage
      newBaseCompensation
      newPosition
      notElegibleSubject
      notElegiblePhrase
      uniqueBonusAmount
  }
  `
};

export default CommunicationPage;

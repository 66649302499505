import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import Modal from '../ModalMessage';
import InfoCard from './InfoCard';
import GenerateReport from '../buttons/GenerateReport';

import '../../../static/styles/shared/cards/fileCard.less';
import * as storageUtil from '../../../helpers/cookiesUtil';

const UPLOAD_VARIABLE_BONUS = 'UPLOAD_VARIABLE_BONUS';
const ERROR_BAD_USER_INPUT = 'BAD_USER_INPUT';

let detailError = {};
let detailSuccess = {};

const cardSetting = {
  download: {
    icon: 'icon-ml-file-download',
    label: 'Descargar'
  },
  upload: {
    icon: 'icon-ml-file-upload',
    label: 'Cargar'
  },
  antiquityCertificate: {
    icon: 'icon-ml-file-send',
    label: 'Enviar a mi correo electrónico'
  },
  generate_report: {
    icon: 'icon-me-arrow-right',
    label: 'Generar Reporte'
  }
};

export const beforeUpload = ({
  file, handleResult
}) => {
  const isExcelFile = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  if (!isExcelFile) {
    handleResult({
      visible: true,
      type: 'error',
      errors: [{
        label: 'No se ha podido cargar el archivo',
        value: ['El formato del archivo que intentas cargar no es válido. Debe estar en formato Excel (xls, xlsx).']
      }]
    });
    return false;
  }
  return true;
};

export const onChange = ({
  info, handleResult
}) => {
  if (info.file.status === 'done') {
    handleResult({
      visible: true,
      success: [{
        label: detailSuccess?.data?.singleUpload?.message || 'El archivo se ha cargado correctamente',
        value: [],
        type: 'success'
      }]
    });
    return true;
  } if (info.file.status === 'error') {
    let generalError;
    const { code = '', message = '', messages } = detailError.message && detailError.graphQLErrors[0];
    if (ERROR_BAD_USER_INPUT === code) {
      generalError = [{
        label: 'No se ha podido cargar el archivo',
        value: [message],
        errors: messages
      }];
    } else if (detailError.message && detailError.graphQLErrors[0]) {
      const detail = `${detailError.message ? 'El archivo tiene inconsistencias' : 'Se excedió el tiempo límite de carga. Por favor intente de nuevo o más tarde'}`;
      generalError = [{
        label: 'No se ha podido cargar el archivo',
        value: [detail],
        errors: detailError.graphQLErrors[0]?.messages
      }];
    } else if (info?.file?.response[0]?.error) {
      generalError = [
        {
          label: 'No se ha podido cargar el archivo',
          value: [info.file.response[0].error]
        }
      ];
    } else if (info?.file?.response[0]?.field) {
      generalError = [
        {
          label: 'No se ha podido cargar el archivo',
          value: ['El archivo que has tratado de cargar contiene la siguiente información que no es válida:']
        },
        ...info.file.response
      ];
    } else if (detailError?.messageErrors && detailError?.code === UPLOAD_VARIABLE_BONUS) {
      generalError = [
        {
          label: 'No se ha podido cargar el archivo de Bono Variable',
          value: ['El archivo que has tratado de cargar contiene la siguiente información que no es válida:']
        },
        ...(detailError.messageErrors ? detailError.messageErrors : [])
      ];
    }
    handleResult({ visible: true, errors: generalError, type: 'error' });
  }
  return false;
};

const handleMutation = async ({
  options, graphqlMutation, graphqlParams
}) => {
  const {
    onSuccess, onError, file
  } = options;
  const input = { ...graphqlParams, file };
  try {
    detailSuccess = await graphqlMutation({ variables: { input } });
    onSuccess('ok');
  } catch (e) {
    detailError = JSON.parse(JSON.stringify(e));
    onError('error');
  }
};

const Wrapper = ({
  type, endpoint, children, handleResult, graphqlMutation,
  graphqlParams
}) => {
  switch (type) {
  case 'upload':
    return (
      <Upload
        {...{
          name: 'file',
          action: endpoint,
          headers: {
            authorization: `Bearer ${storageUtil.getItem('token')}`
          }
        }}
        data-testid='upload-component'
        className='upload-wrapper'
        {...(graphqlMutation && {
          customRequest: (options) => handleMutation({
            options, graphqlMutation, graphqlParams
          })
        })}
        onChange={(info) => onChange({
          info, handleResult
        })}
        beforeUpload={(file) => beforeUpload({
          file, handleResult
        })}
      >
        {children}
      </Upload>
    );
  case 'download':
  default:
    return <>{children}</>;
  }
};

const printResult = (errors) => errors.map((item, i) => (
  <div key={i}>
    <p className='primary-text'>{item.label}</p>
    <p className='secondary-text'>{item.value.join(' - ')}</p>
    {item.errors && item.errors.length > 0 && (
      <ul className='errors'>
        {item.errors.map((err, index) => (
          <li key={index}>{err}</li>
        ))}
      </ul>
    )}
  </div>
));

const FileCard = ({
  setName,
  actReport,
  onRefetch,
  loading,
  title,
  type,
  url,
  onClick,
  className,
  children,
  endpoint,
  graphqlMutation,
  error,
  graphqlParams,
  dataFile
}) => {
  const [modalState, setModalState] = useState({
    visible: false,
    type: '',
    errors: [],
    success: []
  });
  if (error) {
    return (<InfoCard title={title} className={`file-card ${className}`}>
      <p>Ocurrió un error al cargar el archivo <b>{error}</b></p>
    </InfoCard>);
  }
  const propsAnchor = { ...(url !== '#' ? { href: url } : null) };
  return (<>
    <InfoCard title={title} dataFile={dataFile} className={`file-card ${className}`}>
      <Modal
        data-testid='modal'
        type={modalState.type}
        visible={modalState.visible}
        closeModal={() => setModalState({ visible: false })}
      >
        {modalState.errors && printResult(modalState.errors)}
        {modalState.success && printResult(modalState.success)}
      </Modal>

      <Wrapper
        type={type}
        endpoint={endpoint}
        handleResult={setModalState}
        graphqlMutation={graphqlMutation}
        graphqlParams={graphqlParams}
      >

        {!actReport && (<a className='d-flex justify-content-between'
          data-testid='anchor'
          {...propsAnchor}
          onClick={(parameters, e) => onClick(parameters, e)}
        >
          <span>{cardSetting[type].label}</span>
          <i className={cardSetting[type].icon} />
        </a>)}

        {actReport && (
          <GenerateReport
            title={title}
            setName={setName}
            loading={loading}
            onRefetch={() => onRefetch()}
            label={cardSetting[type].label}
            iconName={cardSetting[type].icon}
          />
        )}
      </Wrapper>
      {children}
    </InfoCard>

  </>
  );
};

FileCard.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  url: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.element,
  endpoint: PropTypes.string,
  graphqlMutation: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(null)
  ]),
  graphqlParams: PropTypes.object,
  error: PropTypes.string
};

FileCard.defaultProps = {
  title: '',
  type: 'download',
  onClick: () => { },
  url: '#',
  className: '',
  children: null,
  endpoint: '',
  graphqlMutation: null,
  graphqlParams: {},
  error: ''
};

export default FileCard;

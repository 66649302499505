import React from 'react';
import PropTypes from 'prop-types';
import { Steps, Popover } from 'antd';
import '../../../static/styles/shared/steps/ml-steps.less';

const { Step } = Steps;

const MLSteps = ({ steps, current, onChange }) => {
  const customDot = (dot, { index }) => (
    <Popover
      content={
        <span>
          Ir a {steps[index].title}
        </span>
      }
    >
      {{ ...dot, props: { ...dot.props, className: `${dot.props.className}${index !== current ? ' ml-step-icon-dot-disabled' : ''}` } }}
    </Popover>
  );

  return (
    <div className="ml-steps-wrapper">
      <Steps className="ml-steps" current={current} onChange={onChange} progressDot={customDot}>
        {steps.map(({ title, description }, key) => (
          <Step key={key} {...{ title, description }}/>
        ))}
      </Steps>

      <div className="steps-content">{steps[current].content}</div>
    </div>
  );
};

MLSteps.propTypes = {
  steps: PropTypes.array,
  current: PropTypes.number,
  onChange: PropTypes.func
};

MLSteps.defaultProps = {
  steps: [],
  current: 0,
  onChange: () => null
};

export default MLSteps;

import { lazy } from 'react';
import compensations from './compensations';
import myEvolution from './myEvolution';

const MyTeamItemDetail = lazy(() => import('../../../components/solicitudes/MyTeamItemDetail'));
const MyTeam = lazy(() => import('../../../containers/myTeam'));
const MyTeamVacaciones = lazy(() => import('../../../containers/solicitudes/MyTeam'));

export default [
  {
    exact: true,
    auth: 'MY_TEAM',
    path: '/mi-equipo',
    section: 'Mi Equipo',
    component: MyTeam
  },
  {
    exact: false,
    tabTitle: 'Vacaciones',
    isTab: true,
    path: '/mi-equipo/vacaciones',
    section: 'Mi Equipo -> Vacaciones',
    component: MyTeamVacaciones,
    subRoutes: [
      {
        exact: true,
        tabTitle: 'Colaborador Individual',
        path: '/mi-equipo/vacaciones',
        section: 'Mi Equipo -> Vacaciones',
        component: (props) => <MyTeamItemDetail {...props} />
      }
    ]
  },
  ...compensations,
  ...myEvolution
];

export function checkRut(rut) {
  // Despejar Puntos
  let valor = rut.replace(/\./g, '');

  // Despejar Guión
  valor = valor.replace('-', '');

  // Aislar Cuerpo y Dígito Verificador
  const cuerpo = valor.slice(0, -1);
  let dv = valor.slice(-1).toUpperCase();

  // Formatear RUN
  rut = `${cuerpo}-${dv}`;  // eslint-disable-line

  // Si no cumple con el mínimo ej. (n.nnn.nnn)
  if (cuerpo.length < 7) {
    return {
      invalid: true,
      message: 'Rut incompleto'
    };
  }

  // Calcular Dígito Verificador
  let suma = 0;
  let multiplo = 2;

  // Para cada dígito del Cuerpo
  for (let i = 1; i <= cuerpo.length; i += 1) {
    // Obtener su Producto con el Múltiplo Correspondiente
    const index = multiplo * valor.charAt(cuerpo.length - i);
    // Sumar al Contador General
    suma += index;
    // Consolidar Múltiplo dentro del rango [2,7]
    if (multiplo < 7) { multiplo += 1; } else { multiplo = 2; }
  }

  // Calcular Dígito Verificador en base al Módulo 11
  const dvEsperado = 11 - (suma % 11);

  // Casos Especiales (0 y K)
  dv = (dv === 'K') ? 10 : dv;
  dv = (parseInt(dv, 0) === 0) ? 11 : dv;

  // Validar que el Cuerpo coincide con su Dígito Verificador
  if (parseInt(dvEsperado, 0) !== parseInt(dv, 0)) {
    return {
      invalid: true,
      message: 'Rut inválido'
    };
  }
  if (dv === 10) {
    dv = 'K';
  } else if (dv === 11) {
    dv = '0';
  }
  // Todo bien
  return {
    invalid: false,
    message: '',
    rut: cuerpo + dv
  };
}

export function clean(rut) {
  return typeof rut === 'string'
    ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
    : '';
}

export function format(rut) {
  rut = clean(rut);

  let result = `${rut.slice(-4, -1)}-${rut.substr(rut.length - 1)}`;
  for (let i = 4; i < rut.length; i += 3) {
    result = `${rut.slice(-3 - i, -i)}.${result}`;
  }

  return result;
}

export function removeDots(text) {
  return text.replace(/\./g, '');
}

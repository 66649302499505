import { useState } from 'react';
import showSearchUserResults from '../../../../helpers/searchUser/showResults';
import useRefetchTimer from '../../timers/useRefetchTimer';
import useIsSmallDevice from '../../responsive/useIsSmallDevice';

export default function useSearchUserValidator({ blockedFirstName, firstName, focused }) {
  const isSmallDevice = useIsSmallDevice();
  const [canRefetchQuery, setRefetchTrigger] = useState(false);
  const validateShowResults = () => showSearchUserResults(setRefetchTrigger, firstName);
  const showResultsUsers = isSmallDevice ? focused : canRefetchQuery;
  const refetchDisabled = !focused || (blockedFirstName.length < 2 && !!blockedFirstName);

  useRefetchTimer(firstName, 1000, validateShowResults, refetchDisabled);

  return { canRefetchQuery, setRefetchTrigger, showResultsUsers };
}

import { completePath } from '../../../../helpers/strings';
import '../../../../static/styles/my-team/compensations/main.less';

import MyTeamTabs from '../../../../containers/myTeam/MyTeamTabs';
import MeritLetters from '../../../../containers/myTeam/compensations/CartasDeMerito';
import Salaries from '../../../../containers/myTeam/compensations/Salaries';
import UserSalary from '../../../../containers/myTeam/compensations/Salaries/UserSalary';

export default [
  {
    exact: false,
    tabs: true,
    firstTab: true,
    auth: 'MY_TEAM_COMPENSATIONS',
    className: 'my-compensations-team',
    path: '/mi-equipo/compensaciones',
    section: 'Mi Equipo - Compensaciones',
    banner: {
      imageBanner: completePath('web/compensaciones/banner/compensaciones-banner.jpg'),
      imageAvatar: completePath('web/compensaciones/servicios/inicio/compensaciones-card.png'),
      title: 'Compensaciones'
    },
    component: MyTeamTabs,
    subRoutes: [
      {
        path: '/mi-equipo/compensaciones/sueldos',
        auth: 'MY_TEAM_COMPENSATIONS_SALARIES',
        section: 'Mi Equipo -> -> Compensaciones -> Sueldos',
        component: Salaries,
        subRoutes: [
          {
            exact: true,
            path: '/mi-equipo/compensaciones/sueldos/:name',
            section: 'Mi Equipo -> Compensaciones -> Sueldos',
            component: UserSalary
          }
        ]
      },
      {
        tabs: true,
        firstTab: true,
        path: '/mi-equipo/compensaciones/cartas-de-mérito',
        section: 'Mi Equipo -> Compensaciones -> Cartas de Mérito',
        component: MeritLetters,
        auth: ['MY_TEAM_COMPENSATIONS_MERIT']
      }
    ]
  }
];

export const actionTypes = {
  GET_LIST_TICKET: 'GET_LIST_TICKET',
  GET_LIST_TICKET_SUCCESS: 'GET_LIST_TICKET_SUCCESS',
  GET_LIST_TICKET_ERROR: 'GET_LIST_TICKET_ERROR',
  CREATE_TICKET: 'CREATE_TICKET',
  CREATE_TICKET_SUCCESS: 'CREATE_TICKET_SUCCESS',
  CREATE_TICKET_ERROR: 'CREATE_TICKET_ERROR',
  ADD_COMMENT: 'ADD_COMMENT',
  ADD_COMMENT_SUCCESS: 'ADD_COMMENT_SUCCESS',
  ADD_COMMENT_ERROR: 'ADD_COMMENT_ERROR',
  GET_USER_SF: 'GET_USER_SF',
  GET_USER_SF_SUCCESS: 'GET_USER_SF_SUCCESS',
  GET_USER_SF_ERROR: 'GET_USER_SF_ERROR',
  HOME_HELP: 'HOME_HELP',
  HOME_HELP_SUCCESS: 'HOME_HELP_SUCCESS',
  HOME_HELP_ERROR: 'HOME_HELP_ERROR',
  GET_FILE: 'GET_FILE',
  GET_FILE_SUCCESS: 'GET_FILE_SUCCESS',
  GET_FILE_ERROR: 'GET_FILE_ERROR'
};

// List Ticket
export function getListTicket() {
  return {
    type: actionTypes.GET_LIST_TICKET
  };
}
export function getListTicketSuccess(data) {
  return {
    type: actionTypes.GET_LIST_TICKET_SUCCESS,
    data
  };
}
export function getListTicketError() {
  return {
    type: actionTypes.GET_LIST_TICKET_ERROR
  };
}

// Create Ticket
export function createTicket(payload) {
  return {
    type: actionTypes.CREATE_TICKET,
    payload
  };
}
export function createTicketSuccess(resTicket) {
  return {
    type: actionTypes.CREATE_TICKET_SUCCESS,
    resTicket
  };
}
export function createTicketError(resTicket) {
  return {
    type: actionTypes.CREATE_TICKET_ERROR,
    resTicket
  };
}

// Add Comment
export function addComment(payload) {
  return {
    type: actionTypes.ADD_COMMENT,
    payload
  };
}
export function addCommentSuccess(resComment) {
  return {
    type: actionTypes.ADD_COMMENT_SUCCESS,
    resComment
  };
}
export function addCommentError(resComment) {
  return {
    type: actionTypes.ADD_COMMENT_ERROR,
    resComment
  };
}

// Home Help (USER DATA SF + LIST HISTORY TICKET)
export function homeHelpDetail(rut) {
  return {
    type: actionTypes.HOME_HELP,
    rut
  };
}
export function homeHelpSuccess(resHomeHelp) {
  return {
    type: actionTypes.HOME_HELP_SUCCESS,
    resHomeHelp
  };
}
export function homeHelpError(error) {
  return {
    type: actionTypes.HOME_HELP_ERROR,
    error
  };
}

// Get files
export function getFile(id, title, type) {
  return {
    type: actionTypes.GET_FILE,
    payload: { id, title, type }
  };
}
export function getFileSuccess(resFileHelp) {
  return {
    type: actionTypes.GET_FILE_SUCCESS,
    resFileHelp
  };
}
export function getFileError(resFileHelp) {
  return {
    type: actionTypes.GET_FILE_ERROR,
    resFileHelp
  };
}

import { actionTypes } from '../actions/banks';

export const initialState = {
  bankListLoading: false,
  bankListError: false,
  bankList: []
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_BANK_LIST:
    return {
      ...state,
      bankListLoading: true,
      bankListError: false
    };

  case actionTypes.GET_BANK_LIST_SUCCESS:
    return {
      ...state,
      bankListLoading: false,
      bankListError: false,
      bankList: action.data
    };

  case actionTypes.GET_BANK_LIST_ERROR:
    return {
      ...state,
      bankListLoading: true,
      bankListError: true
    };

  default:
    return state;
  }
}
export default reducer;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import { getSettingsAPI } from '../api/settings';
import { actionTypes, getSettingsSuccess, getSettingsError } from '../actions/settings';

es6promise.polyfill();

function* getSettings({ variables }) {
  try {
    const response = yield call(getSettingsAPI, variables);
    yield put(getSettingsSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(getSettingsError({ error, statusCode }));
  }
}

function* searchSagas() {
  yield all([takeLatest(actionTypes.GET_SETTINGS, getSettings)]);
}
export default searchSagas;

import { lazy } from 'react';
import performance from './performance';
import retro from './retro';
import feedback from './feedback';
import skills4C from './skills4C';
import evaluations from './evaluations';
import improvementPlan from './improvementPlan';
import developmentPlans from './developmentPlans';

const MyTeamTabs = lazy(() => import('../../../../containers/myTeam/MyTeamTabs'));

export default [
  {
    exact: false,
    tabs: true,
    firstTab: true,
    auth: 'MY_TEAM_MY_EVOLUTION',
    className: 'my-evolution-team',
    path: '/mi-equipo/mi-evolucion',
    section: 'Mi Equipo - Mi Evolución',
    component: MyTeamTabs,
    subRoutes: [
      ...performance,
      ...evaluations,
      ...retro,
      ...feedback,
      ...skills4C,
      ...improvementPlan,
      ...developmentPlans
    ]
  }
];

export const actionTypes = {
  GET_RETURNING_PLAN_FORM_DATA: 'GET_RETURNING_PLAN_FORM_DATA',
  GET_RETURNING_PLAN_FORM_DATA_SUCCESS: 'GET_RETURNING_PLAN_FORM_DATA_SUCCESS',
  GET_RETURNING_PLAN_FORM_DATA_ERROR: 'GET_RETURNING_PLAN_FORM_DATA_ERROR',
  POST_RETURNING_PLAN_DATA: 'POST_RETURNING_PLAN_DATA',
  POST_RETURNING_PLAN_DATA_SUCCESS: 'POST_RETURNING_PLAN_DATA_SUCCESS',
  POST_RETURNING_PLAN_DATA_ERROR: 'POST_RETURNING_PLAN_DATA_ERROR',
  CLEAN_ERRORS: 'CLEAN_ERRORS'
};

export function getReturningFormData(data) {
  return {
    type: actionTypes.GET_RETURNING_PLAN_FORM_DATA,
    data
  };
}

export function getReturningFormDataSuccess(data) {
  return {
    type: actionTypes.GET_RETURNING_PLAN_FORM_DATA_SUCCESS,
    data
  };
}

export function getReturningFormDataError(error) {
  return {
    type: actionTypes.GET_RETURNING_PLAN_FORM_DATA_ERROR,
    error
  };
}

export function postReturningPlanData(data) {
  return {
    type: actionTypes.POST_RETURNING_PLAN_DATA,
    data
  };
}

export function postReturningPlanDataSuccess(data) {
  return {
    type: actionTypes.POST_RETURNING_PLAN_DATA_SUCCESS,
    data
  };
}

export function postReturningPlanDataError(error) {
  return {
    type: actionTypes.POST_RETURNING_PLAN_DATA_ERROR,
    error
  };
}

export function cleanReturningPlanErrors(error) {
  return {
    type: actionTypes.CLEAN_ERRORS,
    error
  };
}

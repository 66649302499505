import { actionTypes } from '../actions/myTime';

export const initialState = {
  loading: null,
  data: null
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.SELECTING_DATE:
    return {
      ...state,
      loading: true,
      data: action.data
    };
  case actionTypes.DATE_SELECTED:
    return {
      ...state,
      loading: false,
      data: null
    };
  default:
    return state;
  }
}

export default reducer;

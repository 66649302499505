import React from 'react';
import ReportByCode from '../../../reports/ReportByCode';

export default () => (
  <ReportByCode
    jobCode='RETRO_REPORT'
    reportDetails= {{
      title: 'Reporte de Retroalimentación',
      description:
      'Detalle de todas las retroalimentaciones creadas.'
    }}
  />
);

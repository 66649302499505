import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';

const { TextArea } = Input;

const TextAreaInput = (props) => {
  const { question, onChange, inputClassName } = props;
  return (

    <div className='box-text-area'>
      <p className='text-label'>{question.name}</p>
      <TextArea
        className={inputClassName}
        rows={4}
        value={question.value}
        onChange = {onChange}
        placeholder={question.placeholder}
        maxLength={question.maxLength}
      />
      <div className='d-flex justify-content-end'>
        <p className='text-written-characters'> {question.value ? question.value.length : '0'} / {question.maxLength} </p>
      </div>
    </div>
  );
};
TextAreaInput.propTypes = {
  question: PropTypes.object,
  onChange: PropTypes.func,
  inputClassName: PropTypes.string
};

TextAreaInput.defaultProps = {
  questions: {},
  onChange: () => {},
  inputClassName: ''
};
export default TextAreaInput;

import React from 'react';
import PropTypes from 'prop-types';

import '../../static/styles/shared/message-card.less';

const MessageCard = ({
  name, description, type, className, border, backgroundColorLevel
}) => {
  const typeClassName = (() => {
    const getTypeClassNameLevel = (typeClassNameRestult) => (
      backgroundColorLevel === 1 ? '' : ` ${typeClassNameRestult}-${backgroundColorLevel}`
    );

    switch (type) {
    case 'info': {
      const typeClassNameRestult = 'skill-wrapper';
      return `${typeClassNameRestult}${getTypeClassNameLevel(typeClassNameRestult)} `;
    }
    case 'warning': {
      const typeClassNameRestult = 'behavior-wrapper';
      return `${typeClassNameRestult}${getTypeClassNameLevel(typeClassNameRestult)} `;
    }
    case 'success': {
      const typeClassNameRestult = 'success-wrapper';
      return `${typeClassNameRestult}${getTypeClassNameLevel(typeClassNameRestult)} `;
    }
    case 'error': {
      const typeClassNameRestult = 'error-wrapper';
      return `${typeClassNameRestult}${getTypeClassNameLevel(typeClassNameRestult)} `;
    }
    default:
      return '';
    }
  })();

  const getClassName = () => {
    let classNameResult = 'message-card';
    if (className) classNameResult += ` ${className}`;
    if (type) classNameResult += ` message-card-${type}`;
    if (border) classNameResult += ' message-card-no-bordered';
    return classNameResult;
  };

  return (
    <div className={getClassName()}>
      <div className={`${typeClassName}py-3 px-2 px-xl-5 w-100`}>
        <div className="title-container">
          <h6 className="text-center mt-1">{name}</h6>
        </div>
        {description}
      </div>
    </div>

  );
};

MessageCard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  className: PropTypes.string,
  border: PropTypes.bool,
  backgroundColorLevel: PropTypes.number
};

MessageCard.defaultProps = {
  name: '',
  description: '',
  type: '',
  className: '',
  border: false,
  backgroundColorLevel: 3
};

export default MessageCard;

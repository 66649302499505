import React, { Fragment, useState, useEffect } from 'react';
import {
  useRouteMatch,
  useLocation
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useQuery } from '@apollo/client';
import { useWindowWidthSize } from '../../../helpers/browser';
import Loading from '../../loading';
import Introduction from '../../../components/mievolucion/EvolucionaTEST/Introduction';
import Error from '../../../components/shared/Error';
import { completePath } from '../../../helpers/strings';

import { EvolucionaTESTMobile, EvolucionaTESTDesktop } from '../../../components/mievolucion/EvolucionaTEST';
import { GET_SHOW_INTRODUCTION } from '../../../graphql/userSettings/queries';
import { smallDevice } from '../../../helpers/deviceVariables';
import '../../../static/styles/mievolucion/evolucionaTEST/styles.less';
import { evolucionaTestIntroductionKey } from '../../../helpers/introductions';
import { MY_EVOLUTION_CONTEXT } from '../../../helpers/apollo';

const NewSkills = ({ children }) => {
  const [hidden, setHidden] = useState(true);
  const [showIntroduction, setShowIntroduction] = useState(true);
  const {
    isOfficer,
    isLinealBoss,
    additionalField4: userLevel
  } = useSelector((state) => state.userReducer.data);
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const width = useWindowWidthSize();

  const banner = {
    imageBanner: `${completePath('web/mi-evolucion/banner/evolucionatest-banner.jpg')}`,
    imageAvatar: `${completePath('web/mi-evolucion/avatars/evolucionatest-avatar.jpg')}`,
    title: 'EvolucionaTEST',
    subtitle: 'Mi evolución',
    titleAndSubTitleFormat: 'keep-format'
  };

  const buildSecondaryTabs = () => {
    if (children) {
      const { props: { routes } } = children;
      return routes
        .map((item) => ({
          title: item.tabTitle,
          key: item.path,
          children: <item.component />
        }));
    }
    return [];
  };

  const variablesIntroduction = { introductionName: evolucionaTestIntroductionKey };
  const {
    data: introductionData,
    loading: introductionLoading,
    error: introductionError,
    refetch: introductionRefetch
  } = useQuery(
    GET_SHOW_INTRODUCTION,
    {
      variables: variablesIntroduction,
      context: MY_EVOLUTION_CONTEXT,
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (introductionData) {
      const { getIntroduction: showIntroductionToUser } = introductionData;

      if (showIntroductionToUser) {
        setShowIntroduction(false);
      }
    }
  }, [introductionLoading, introductionData]);

  useEffect(() => {
    if (pathname === path) setHidden(false);
  });

  const Intro = () => <Introduction

    refetchQueries={ [
      {
        context: MY_EVOLUTION_CONTEXT,
        query: GET_SHOW_INTRODUCTION,
        variables: variablesIntroduction
      }
    ]}

    endIntroduction={ () => setShowIntroduction(false)} />;

  const renderDefaultSecondaryTab = () => {
    if (pathname === path && width > smallDevice && children) {
      const { props: { routes } } = children;
      const [defaultComponent] = routes;
      return <defaultComponent.component />;
    }
    return null;
  };
  return (
    <Fragment>
      { introductionLoading && !introductionError
        ? <Loading />
        : <>
          {

            <EvolucionaTESTDesktop
              banner={banner}
              userLevel={userLevel}
              showIntroduction={showIntroduction}
              isLinealBoss={isLinealBoss}
              isOfficer={isOfficer}
              buildSecondaryTabs={buildSecondaryTabs}
              Intro={Intro}
              error={introductionError}
            />

          }
          {
            <EvolucionaTESTMobile
              banner={banner}
              userLevel={userLevel}
              hidden={hidden}
              setHidden={setHidden}
              width={width}
              showIntroduction={showIntroduction}
              error={introductionError}
              isLinealBoss={isLinealBoss}
              Intro={Intro}
            />

          }

          { !showIntroduction && (
            <>
              {children}
              {renderDefaultSecondaryTab()}
            </>
          )
          }
        </>
      }
      {
        introductionError && <Error reload={() => introductionRefetch()} />
      }
    </Fragment>
  );
};

export default NewSkills;

import { successMeasuresStatusOptions } from './index';

const getAdvanceName = (statusValue) => {
  const statusName = successMeasuresStatusOptions.find(
    (option) => option.value === statusValue
  );

  return statusName?.label || '-';
};

export default getAdvanceName;

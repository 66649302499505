import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import { searchUserAPI } from '../api/searchUser';
import { actionTypes, searchUserSuccess, searchUserError } from '../actions/searchUser';

es6promise.polyfill();

export function* searchUser({ params }) {
  try {
    const response = yield call(searchUserAPI, params);
    yield put(searchUserSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(searchUserError({ ...error, statusCode }));
  }
}

function* searchUserSagas() {
  yield all([
    takeLatest(actionTypes.SEARCH_USER, searchUser)
  ]);
}

export default searchUserSagas;

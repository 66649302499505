import { SEARCH_BAR_CLASS } from '../searchBar/classNames';
import { SEARCH_USER_CONTAINER_CLASS } from './classNames';
import getClassName from '../strings/getClassName';

const getSearchUserClassNames = (className, focused) => {
  const defaultContainerClassName = SEARCH_USER_CONTAINER_CLASS;
  const defaultSearchBarClassName = SEARCH_BAR_CLASS;
  const fullNameContainerClasName = `${defaultContainerClassName}-fullname`;
  const searchBarClassName = getClassName(defaultSearchBarClassName, `${defaultSearchBarClassName}-fullname`);
  let containerClassName = getClassName(defaultContainerClassName, `${fullNameContainerClasName} ${className}`);
  if (focused) containerClassName += ` ${fullNameContainerClasName}-focused`;

  return {
    containerClassName,
    searchBarClassName
  };
};

export default getSearchUserClassNames;

import React from 'react';
import PropTypes from 'prop-types';
import '../../../static/styles/shared/cards/infoCard.less';

const Card = (props) => {
  const {
    title, children, className, footer, classNameInfoCard, dataFile
  } = props;

  return (
    <div data-testid='info-card' className={`col-12 ${className}`}>
      <div className={`info-card ${classNameInfoCard}`}>
        {
          title && (
            <div className='container-title container-title d-flex justify-content-between'>
              <p>{title}</p>
              <p> { dataFile ? <a href={dataFile?.url} >Archivo permisos</a> : null }</p>
            </div>
          )
        }
        {children}

        { footer !== null
        && <div className='footer'>
          {footer}
        </div>
        }

      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  className: PropTypes.string,
  footer: PropTypes.element,
  classNameInfoCard: PropTypes.string
};

Card.defaultProps = {
  title: '',
  children: [<></>],
  className: '',
  footer: null,
  classNameInfoCard: ''
};

export default Card;

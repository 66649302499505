import { useState, useEffect } from 'react';

const useWindowHeightSize = () => {
  const getSize = () => window.innerHeight;

  const [windowHeightSize, setWindowHeightSize] = useState(getSize);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeightSize(getSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowHeightSize;
};

export default useWindowHeightSize;

import React from 'react';
import PropTypes from 'prop-types';
import ActiveEvaluation from './ActiveEvaluation';
import PastEvaluation from './PastEvaluation';

const EvaluationSelected = ({ evaluationSelected, refetchQueries, reload }) => {
  if (evaluationSelected.isActive) {
    return <ActiveEvaluation
      refetchQueries={refetchQueries}
      evaluationSelected={evaluationSelected}
      reload={reload}
    />;
  }
  return <PastEvaluation evaluationSelected={evaluationSelected} />;
};

EvaluationSelected.propTypes = {
  evaluationSelected: PropTypes.object,
  refetchQueries: PropTypes.array,
  reload: PropTypes.func
};

EvaluationSelected.defaultProps = {
  evaluationSelected: {},
  refetchQueries: [],
  reload: () => {}
};
export default EvaluationSelected;

import gql from 'graphql-tag';

export const GENERATE_LETTER = gql`
  mutation generateLetter($letterID: ID!, $seenByOwner: Boolean){
    generateLetter(letterID: $letterID, seenByOwner: $seenByOwner) {
      file,
      data {
        letterCode
      }
    }
}`;

export const SEND_LETTER = gql`
  mutation sendLetter($letterID: String!){
    sendLetter(letterID: $letterID)
}
`;

export const UPDATE_LETTER = gql`
  mutation changeLetterStatus($letterID: String!, $status: LetterStatus){
    changeLetterStatus(letterID: $letterID, status: $status)
}
`;

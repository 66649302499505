import qs from 'qs';
import FileDownload from 'js-file-download';
import moment from 'moment';
import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

export const getUserInfo = (params) => axios().get(`${BFF_ENDPOINT}/wal-i/userInfo?${qs.stringify(params)}`).then((resp) => resp.data);

export const getPendingUser = (params) => axios().get(`${BFF_ENDPOINT}/wal-i/pendingUser?${qs.stringify(params)}`).then((resp) => resp.data);

export const validateStatus = (params, data) => axios().put(`${BFF_ENDPOINT}/wal-i/validateStatus?dni=${(params)}`, data).then((resp) => resp.data);

export const getSurvey = (params) => axios().get(`${BFF_ENDPOINT}/wal-i/survey?${qs.stringify(params)}`).then((resp) => resp.data);

export const sendSurveyRes = (params) => axios().post(`${BFF_ENDPOINT}/wal-i/surveyResponse`, params).then((resp) => resp.data);

export const addMedicalRecord = (params) => axios().post(`${BFF_ENDPOINT}/wal-i/medicalRecord`, params).then((resp) => resp.data);

export const sendAssignStatus = (params, data) => axios().put(`${BFF_ENDPOINT}/wal-i/changeStatus?dni=${(params)}`, data).then((resp) => resp.data);

export const closingCase = (params) => axios().post(`${BFF_ENDPOINT}/wal-i/closeCase`, params).then((resp) => resp.data);

export const externalUser = (params) => axios().post(`${BFF_ENDPOINT}/wal-i/externalUser`, params).then((resp) => resp.data);

export const getConfig = () => axios().get(`${BFF_ENDPOINT}/wal-i/config`).then((resp) => resp.data);

export const getGamaReport = (data) => axios().get(`${BFF_ENDPOINT}/wal-i/gamaReport`,
  { params: data, responseType: 'blob' })
  .then((resp) => {
    const fileName = `Reporte Gama_${data.local}_${moment().format('DD-MM-YYYY')}.xlsx`;
    FileDownload(resp.data, fileName);
  });

export const getLocationsOnCharge = (params) => axios().get(`${BFF_ENDPOINT}/wal-i/locationsOnCharge`, { params }).then((resp) => resp.data);

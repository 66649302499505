import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GET_PILLARS } from '../../../graphql/mievolucion/myPerformance/queries';

const usePerformancePillars = (bossParams) => {
  const [
    callApi,
    {
      data,
      loading,
      error
    }
  ] = useLazyQuery(
    GET_PILLARS,
    {
      context: { clientName: 'myEvolution' },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (!bossParams) callApi();
  }, [bossParams]);

  return {
    callApi,
    data,
    loading,
    error
  };
};

export default usePerformancePillars;

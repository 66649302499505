import React, { useState, useEffect } from 'react';
import {
  Row,
  Col
} from 'antd';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import FileCard from '../cards/FileCard';
import ErrorMessage from '../ErrorMessage';
import DownloadReport from '../buttons/DownloadReport';
import LoaderAndDesc from '../Loaders/LoaderAndDesc';
import '../../../static/styles/shared/Reports/admin-file-list-reports.less';
import { REPORT_STATUSES } from '../../../hooks/reports/useReport';
import ModalMessage from '../ModalMessage';

const renderCards = (item, loading, startFetch, setName) => (
  <Col xs={24} md={12} lg={8} className='file-card-container'>
    <FileCard {...item}
      loading={loading} onClick={ startFetch } onRefetch={startFetch} setName={setName}/>
  </Col>
);

const AdminFileListReportsSockets = ({
  title, report, startFetch, isAsync
}) => {
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ visible: false, type: 'error' });
  const onCreation = report?.status === REPORT_STATUSES.ON_CREATION;
  const error = report?.status === REPORT_STATUSES.FAILED;
  const showLoader = (loading || onCreation) && !isAsync;

  const { emailOffice } = useSelector((state) => state.userReducer.data);

  const onClick = () => {
    setLoading(true);
    startFetch();
    if (isAsync) {
      setModalMessage({
        visible: true,
        type: 'info',
        title: `Se está generando el reporte: ${title}`,
        message: `El reporte será enviado a tu correo electronico ${emailOffice.toLowerCase()} cuando se encuentre listo. Puede que tarde unos minutos.`
      });
    }
  };

  useEffect(() => {
    if (report?.status) setLoading(false);
  }, [report]);
  return (<>
    <div className='admin-file-list-reports'>
      {
        title && (
          <Row>
            <Col span={24}>
              <h4 className='ml-3' style={{ fontSize: '20px !important' }}>{title}</h4>
            </Col>
          </Row>
        )
      }
      {
        (
          <>
            {<Row>
              {renderCards(report, showLoader, onClick, () => {})}
            </Row>}
            {showLoader && !error && (<LoaderAndDesc title={title} textTime='un minuto' />)}
            {
              <Row>
                {!showLoader && !error && report?.info?.files && !isAsync
                   && report.info.files.map((file, i) => (
                     <div key={i}>
                       {
                         (<a className='d-flex justify-content-between'
                           data-testid='anchor'
                           href={file?.url}
                           target='_blank' rel='noopener noreferrer'
                         >
                           <DownloadReport
                             nameFile={file?.title}
                             title='Descargar Reporte '
                             classIconNameLeft='icon-ml-excel'
                             classIconNameRight='icon-ml-file-download' />

                         </a>)}
                     </div>))
                }
              </Row>
            }
          </>
        )
      }
      <Row>
        {error
        && <ErrorMessage
          message={`Se ha producido un error al intentar generar el reporte 
          (${title}). Por favor inténtalo de nuevo más tarde.`} />}
      </Row>
    </div>
    {modalMessage.visible && isAsync && <ModalMessage {...{
      ...modalMessage,
      closeModal: () => setModalMessage({ ...modalMessage, visible: false })
    }}/>}
  </>
  );
};

AdminFileListReportsSockets.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onClick: PropTypes.func,
  listAzure: PropTypes.array,
  isAsync: PropTypes.bool
};

AdminFileListReportsSockets.defaultProps = {
  title: '',
  list: [],
  loading: false,
  error: false,
  onClick: () => { },
  url: '#',
  className: '',
  listAzure: [],
  isAsync: false
};

export default AdminFileListReportsSockets;

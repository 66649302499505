import React from 'react';
import PropTypes from 'prop-types';

const Question = ({
  title, description, children, descriptionClassName
}) => (
  <div className='container-question'>
    <p className='title'>{title}</p>
    <p className={`description ${descriptionClassName}`}>{description}</p>
    {children}
  </div>
);

Question.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any,
  descriptionClassName: PropTypes.string
};

Question.defaultProps = {
  title: '',
  description: '',
  children: <></>,
  descriptionClassName: ''
};
export default Question;

export const actionTypes = {
  LOADING: 'LOADING',
  LOAD_COMPLETE: 'LOAD_COMPLETE',

  LOADING_HISTORIAL: 'LOADING_HISTORIAL',
  LOAD_HISTORIAL_COMPLETE: 'LOAD_HISTORIAL_COMPLETE',

  FAILURE: 'FAILURE',
  SHOW_MESSAGE: 'SHOW_MESSAGE',
  GET_VALIDATION: 'GET_VALIDATION',
  GET_VALIDATION_SUCCESS: 'GET_VALIDATION_SUCCESS',
  GET_VALIDATION_ERROR: 'GET_VALIDATION_ERROR',

  GET_SAP_ANTICIPO: 'GET_SAP_ANTICIPO',
  GET_SAP_ANTICIPO_SUCCESS: 'GET_SAP_ANTICIPO_SUCCESS',
  GET_SAP_ANTICIPO_ERROR: 'GET_SAP_ANTICIPO_ERROR',

  GET_ANTICIPO_HISTORIAL: 'GET_ANTICIPO_HISTORIAL',
  GET_ANTICIPO_HISTORIAL_SUCCESS: 'GET_ANTICIPO_HISTORIAL_SUCCESS',
  GET_ANTICIPO_HISTORIAL_ERROR: 'GET_ANTICIPO_HISTORIAL_ERROR',

  SEND_ANTICIPO_REQUEST: 'SEND_ANTICIPO_REQUEST',

  POST_ANTICIPO_SUELDO: 'POST_ANTICIPO_SUELDO',
  PUT_ANTICIPO_SUELDO: 'PUT_ANTICIPO_SUELDO',
  DELETE_ANTICIPO_SUELDO: 'DELETE_ANTICIPO_SUELDO',

  POST_ANTICIPO_SUELDO_SUCCESS: 'POST_ANTICIPO_SUELDO_SUCCESS',
  PUT_ANTICIPO_SUELDO_SUCCESS: 'PUT_ANTICIPO_SUELDO_SUCCESS',
  DELETE_ANTICIPO_SUELDO_SUCCESS: 'DELETE_ANTICIPO_SUELDO_SUCCESS',
  ANTICIPO_REQUEST_FAILURE: 'ANTICIPO_REQUEST_FAILURE',

  SHOW_MODAL_ANTICIPO_STATUS: 'SHOW_MODAL_ANTICIPO_STATUS',
  CHANGE_MODAL_ANTICIPO_STATUS: 'CHANGE_MODAL_ANTICIPO_STATUS'
};

export const loading = () => ({
  type: actionTypes.LOADING
});

export const loadComplete = () => ({
  type: actionTypes.LOAD_COMPLETE
});

export const getValidation = () => ({
  type: actionTypes.GET_VALIDATION
});

export const getValidationSuccess = (data) => ({
  type: actionTypes.GET_VALIDATION_SUCCESS,
  data
});

export const getValidationError = (data) => ({
  type: actionTypes.GET_VALIDATION_ERROR,
  data
});

export const getAnticipoSueldo = () => ({
  type: actionTypes.GET_SAP_ANTICIPO
});

export const getAnticipoSueldoError = (error) => ({
  type: actionTypes.GET_SAP_ANTICIPO_ERROR,
  data: error
});

export const getAnticipoSuccess = (data) => ({
  type: actionTypes.GET_SAP_ANTICIPO_SUCCESS,
  data
});

export const getAnticipoHistorialSuccess = (data) => ({
  type: actionTypes.GET_ANTICIPO_HISTORIAL_SUCCESS,
  data
});

export const getAnticipoHistorialError = () => ({
  type: actionTypes.GET_ANTICIPO_HISTORIAL_ERROR
});

export const loadingHistorial = () => ({
  type: actionTypes.LOADING_HISTORIAL
});

export const loadHistorialComplete = () => ({
  type: actionTypes.LOAD_HISTORIAL_COMPLETE
});

export const getAnticipoHistorial = () => ({
  type: actionTypes.GET_ANTICIPO_HISTORIAL
});

export const postAnticipoSueldo = (anticipo) => ({
  type: actionTypes.POST_ANTICIPO_SUELDO,
  data: anticipo
});

export const postAnticipoSuccess = (amount) => ({
  type: actionTypes.POST_ANTICIPO_SUELDO_SUCCESS,
  data: amount
});

export const putAnticipoSuccess = () => ({
  type: actionTypes.PUT_ANTICIPO_SUELDO_SUCCESS
});

export const deleteAnticipoSuccess = () => ({
  type: actionTypes.DELETE_ANTICIPO_SUELDO_SUCCESS
});

export const requestError = (error) => ({
  type: actionTypes.ANTICIPO_REQUEST_FAILURE,
  data: error
});

export const putAnticipoSueldo = (anticipo) => ({
  type: actionTypes.PUT_ANTICIPO_SUELDO,
  data: anticipo
});

export const deleteAnticipoSueldo = () => ({
  type: actionTypes.DELETE_ANTICIPO_SUELDO
});

export const showModalAnticipo = (flag) => ({
  type: actionTypes.SHOW_MODAL_ANTICIPO_STATUS,
  data: flag
});
export const changeModalAnticipo = (flag) => ({
  type: actionTypes.CHANGE_MODAL_ANTICIPO_STATUS,
  data: flag
});

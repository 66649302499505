import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

// eslint-disable-next-line import/prefer-default-export
export const getFromUserAPI = (data) => axios().post(`${BFF_ENDPOINT}/social-attention/get-profile`, data).then((resp) => resp.data);

export const addIndividualAPI = (rut, data) => axios().post(`${BFF_ENDPOINT}/social-attention/change`, { ...data, rut }).then((resp) => resp.data);

export const getSACategoriesAPI = (category) => axios().get(`${BFF_ENDPOINT}/social-attention-categories/${category}`).then((resp) => resp.data);

export const addRegisterAPI = (id, data) => axios().post(`${BFF_ENDPOINT}/social-attention/${id}/simple/records`, data).then((resp) => resp.data);

export const addAdvisoryAPI = (id, data) => axios().post(`${BFF_ENDPOINT}/social-attention/${id}/simple/advices`, data).then((resp) => resp.data);

export const addReviewAPI = (data) => axios().post(`${BFF_ENDPOINT}/social-attention/${data.socialAttentionId}/simple/records/${data.recordId}/reviews`, { text: data.text }).then((resp) => resp.data);

export const closeCaseAPI = (data) => axios().post(`${BFF_ENDPOINT}/social-attention/${data.socialAttentionId}/simple/records/${data.recordId}/close`).then((resp) => resp.data);

export const getLocalInfoAPI = (local) => axios().get(`${BFF_ENDPOINT}/locals/${local}`).then((resp) => resp.data);

export const addGroupRegisterAPI = (data) => axios().post(`${BFF_ENDPOINT}/social-attention-group`, data).then((resp) => resp.data);

export const deleteUserGroupAttentionAPI = (data) => axios().post(`${BFF_ENDPOINT}/social-attention-group/remove-user/${data.activityId}/${data.rut}`);

export const editGroupRegisterAPI = (data) => axios().post(`${BFF_ENDPOINT}/social-attention-group/edit/${data.activityId}`, { ...data.body });

import React, { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { map } from 'lodash';
import { isMobile } from 'react-device-detect';
import MLHistorical from '../../../shared/MLHistorical';
import HistoricalList from '../../../shared/MLHistorical/list';
import MessageModal from '../../../shared/ModalMessage';
import MLError from '../../../shared/Error';
import '../../../../static/styles/mievolucion/miDesarrollo/manage.less';
import useGetIDP from '../../../../hooks/myEvolution/miDesarrollo/useGetIDP';
import { getIconStatusIDP, getLabelStatusIDP, isIDPFormSelected } from '../../../../helpers/myEvolution/miDesarrollo';
import MainMessage from '../MainMessage';
import IDPProfile from '../IDPProfile';

const ManageIDP = ({ children }) => {
  const [modal, setModal] = useState({ visible: false });
  const { pathname } = useLocation();

  const {
    IDP,
    loading: loadingIDP,
    error: errorIDP
  } = useGetIDP();

  const renderInfo = (status = '') => (
    <div className='IDP-status'>
      <i className={getIconStatusIDP(status)} />
      <p className='mb-0'>{ getLabelStatusIDP(status)}</p>
    </div>
  );

  const getHistoricalIDP = () => {
    const { props } = children || { };
    const { routes = [] } = props || {};

    const historicalIDP = map(routes, (route) => ({
      showAvatar: false,
      title: route.headerTitle,
      slug: route.path,
      customDescription: renderInfo(IDP[route?.statusKey]),
      titleConnectors: ['y', 'del', 'de']
    }));

    return historicalIDP;
  };

  const leftScrollComponents = [
    errorIDP

      ? <MLError key={0}/>
      : <HistoricalList
        key={1}
        loading={loadingIDP}
        data={getHistoricalIDP()}
        getItemURL={(listItem) => listItem.slug}
        getActiveItem={(listItem) => !!pathname?.match(listItem.slug)}
      />
  ];

  return (
    <div className='container-manage-ipd'>
      { isMobile && !isIDPFormSelected(pathname)
      && <div>
        <IDPProfile />
        <MainMessage />
      </div>

      }
      <Fragment>
        { modal.visible
      && <MessageModal {...{
        ...modal,
        closeModal: () => setModal({ visible: false })
      }}/>

        }
        <MLHistorical
          classes=''
          leftScrollComponents={leftScrollComponents}
        >
          {children}
        </MLHistorical>
      </Fragment>
    </div>

  );
};

export default ManageIDP;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  VIEW_AS_EVALUATED,
  VIEW_AS_EVALUATOR
} from '../../../../../helpers/myEvolution/evaluations';
import { GET_LIST_PERFORMANCE_CATEGORIES } from '../../../../../graphql/mievolucion/performanceCategories/queries';
import { GET_EDD } from '../../../../../graphql/mievolucion/performanceEvaluation/queries';
import { MY_EVOLUTION_CONTEXT } from '../../../../../helpers/apollo';
import NewEvaluation from './NewEvaluation';

const EvaluationModal = ({
  viewAs, rut, refetchQueries, currentStep, onModalClose, startModalClose, reload
}) => {
  const isAutoevaluation = viewAs === VIEW_AS_EVALUATED;
  const { loading: EDDLoading, data: EDDData, refetch: EDDRefetch } = useQuery(
    GET_EDD,
    {
      context: MY_EVOLUTION_CONTEXT,
      variables: { rutSubordinate: rut }
    }
  );

  const { loading: categoriesLoading, data: categoriesData } = useQuery(
    GET_LIST_PERFORMANCE_CATEGORIES,
    {
      variables: { type: 'FOUR_C' },
      context: MY_EVOLUTION_CONTEXT
    }
  );

  useEffect(() => {
    EDDRefetch();
  }, []);

  const { getEDD } = EDDData || {};
  const { listPerformanceCategories: categories } = categoriesData || {};

  const evaluation = getEDD && (isAutoevaluation
    ? getEDD.autoEvaluation
    : getEDD.evaluation);

  const loading = EDDLoading || categoriesLoading;

  const finalCategories = categories
    ? categories.map(
      ({
        _id,
        name,
        type,
        description,
        skillDescription,
        finalSkillDescription,
        objetivesDescription,
        finalObjetivesDescription,
        score
      }) => ({
        _id,
        name,
        type,
        description,
        skillDescription,
        finalSkillDescription,
        objetivesDescription,
        finalObjetivesDescription,
        score
      })
    )
    : [];

  return (
    <div>
      <NewEvaluation
        evaluation={evaluation}
        categories={finalCategories}
        loading={loading}
        viewAs={viewAs}
        onModalClose={onModalClose}
        startModalClose={startModalClose}
        isAutoevaluation={isAutoevaluation}
        refetchQueries={refetchQueries}
        currentStep={currentStep}
        autoevaluation={getEDD?.autoEvaluation}
        reload={reload}
      />
    </div>
  );
};

EvaluationModal.propTypes = {
  viewAs: PropTypes.oneOf([VIEW_AS_EVALUATED, VIEW_AS_EVALUATOR]),
  rut: PropTypes.string,
  currentStep: PropTypes.number,
  onModalClose: PropTypes.func,
  refetchQueries: PropTypes.array,
  startModalClose: PropTypes.bool,
  reload: PropTypes.func
};

EvaluationModal.defaultProps = {
  viewAs: VIEW_AS_EVALUATOR,
  rut: undefined,
  currentStep: 1,
  onModalClose: () => {},
  refetchQueries: [],
  startModalClosed: false,
  reload: () => {}
};
export default EvaluationModal;

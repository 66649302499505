import { lazy } from 'react';
import { isProduction } from '../../helpers/commons';

const MyTime = lazy(() => import('../../containers/mi-tiempo'));
const MyShiftsAndMarkings = lazy(() => import('../../containers/mi-tiempo/mis-turnos-y-marcaciones'));
const FrequentQuestions = lazy(() => import('../../components/mi-tiempo/mis-turnos-y-marcaciones/FrequentQuestions'));
const MyTeamDate = lazy(() => import('../../components/mi-tiempo/MyTeam/SelectedDate'));

export default [
  {
    exact: true,
    trace: true,
    path: '/mi-tiempo',
    section: 'Mi Tiempo',
    auth: isProduction() ? 'MY_TIME_MY_SHIFTS_AND_MARKINGS' : 'MY_TIME',
    component: MyTime
  },
  {
    path: '/mi-tiempo/mis-turnos-y-marcaciones',
    section: 'Mi Tiempo -> Mis Turnos y Marcaciones',
    trace: true,
    auth: [
      'MY_TIME_MY_SHIFTS_AND_MARKINGS',
      ...(isProduction() ? [] : ['MY_TIME_MY_TEAM_SHIFTS_AND_MARKINGS'])
    ],
    component: MyShiftsAndMarkings,
    subRoutes: [
      {
        exact: true,
        tabTitle: 'Preguntas frecuentes',
        path: '/mi-tiempo/mis-turnos-y-marcaciones/preguntas-frecuentes',
        section: 'Mi Tiempo -> Mis Turnos y Marcaciones -> Preguntas Frecuentes',
        trace: true,
        component: FrequentQuestions
      },
      {
        exact: true,
        tabTitle: 'Planificaciones y anomalías',
        path: '/mi-tiempo/mis-turnos-y-marcaciones/:date',
        section: 'Mi Tiempo -> Mis Turnos y Marcaciones -> Planificaciones y Anomalías',
        component: MyTeamDate
      }
    ]
  }
];

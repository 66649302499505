import { useEffect, useState } from 'react';

const useUnreactiveState = (state, stateDispatch) => {
  const [blockedState, setStateWithoutReaction] = useState(state);

  useEffect(() => {
    if (blockedState) stateDispatch(blockedState);
  }, [blockedState]);

  return [blockedState, setStateWithoutReaction];
};

export default useUnreactiveState;

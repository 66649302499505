import axios from 'axios';
import { getItem } from '../cookiesUtil';
import { getErrorDetails, getSuccessDetails } from './getStatusDetails';

export const axiosFileUpload = ({ file, payload, url }) => {
  const token = getItem('token');
  const data = new FormData();
  data.append('file', file);
  data.append('body', JSON.stringify(payload));

  return axios({
    method: 'post',
    url,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  })
    .then((resp) => getSuccessDetails(resp))
    .catch((err) => getErrorDetails(err));
};

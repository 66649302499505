import React from 'react';
import DotColor from '../mievolucion/MyPerformance/Performance/TableComponent/shared/DotColor';

const PENDING = 'Pendiente';

const renderStatus = (status) => {
  if (status === PENDING) {
    return (
      <span>
        <DotColor color='red' size='small' /> {status}
      </span>
    );
  }
  return <span>{status}</span>;
};

export default renderStatus;

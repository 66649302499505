import { actionTypes } from '../actions/user';
import {
  authorizatePermissionsByCredential, getCredentialsPermissions
} from '../../router/helpers/credentials';

export const initialState = {
  auth: null,
  loadingLogin: false,
  errorLogin: null,
  challenge: null,
  data: null,
  message: '',
  redirect: false,

  questions: {
    streetNumber: '',
    currentStep: ''
  },

  notifications: {},
  finishedNotification: false,
  solicitudesByDay: [],
  benefitsResume: [],
  forgotStep: 1,
  error: false,
  isLogged: false,
  loading: false,
  fetchingCredentialProfile: false,
  fetchingUserAdminCredential: false,
  refreshingPermissions: false,

  usersToRedirect: {
    333333333: '/covid-19/wali/control-acceso'
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.LOGIN:
    return {
      ...state,
      isLogged: false,
      loadingLogin: true,
      errorLogin: null

    };
  case actionTypes.LOGIN_FAILED:
    return {
      ...state,
      isLogged: false,
      loadingLogin: false,
      errorLogin: action.error
    };
  case actionTypes.LOGIN_SUCCESS:
    return {
      ...state,
      auth: action.data,
      isLogged: true,
      loadingLogin: false,
      errorLogin: null
    };
  case actionTypes.PROFILE_UPDATE_SUCCESS:
    return {
      ...state,
      ...{ data: action.data, message: '¡Guardado exitoso!' }
    };

  case actionTypes.UPDATE_MIS_DATOS_SUCCESS:
    return {
      ...state,
      ...{ misDatos: action.payload, message: '¡Tus datos se guardaron con éxito!' }
    };

  case actionTypes.CLEAN_MESSAGE:
    return {
      ...state,
      ...{ message: '' }
    };

  case actionTypes.GET_USER:
    return {
      ...state,
      loading: true,
      error: false
    };

  case actionTypes.GET_USER_SUCCESS:
    return {
      ...state,
      data: {
        ...action.data,
        credentialPermissions: getCredentialsPermissions(action.data.permissions)
      },
      loading: false
    };

  case actionTypes.GET_USER_FAILED:
    return {
      ...state,
      loading: false,
      error: true
    };

  case actionTypes.DISMISS_MESSAGE:
    return {
      ...state,
      ...{ message: '' }
    };

  case actionTypes.GET_USER_STREET_SUCCESS:
    return {
      ...state,
      questions: { ...state.questions, streetNumber: action.payload.resetdata, currentStep: '2' }
    };

  case actionTypes.GET_NOTIFICATIONS:
    return {
      ...state,
      finishedNotification: true
    };
  case actionTypes.GET_NOTIFICATIONS_SUCCESS:
    return {
      ...state,
      notifications: action.data,
      finishedNotification: true
    };
  case actionTypes.GET_BENEFITS_RESUME_SUCCESS:
    return {
      ...state,
      benefitsResume: action.payload
    };
  case actionTypes.GET_MY_CREDENTIAL_PROFILE:
    return {
      ...state,
      fetchingCredentialProfile: true,
      refreshingPermissions: true
    };
  case actionTypes.GET_MY_CREDENTIAL_PROFILE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        permissions: authorizatePermissionsByCredential(
          state.data.permissions,
          state.data.credentialPermissions,
          action.data.show
        )
      },
      fetchingCredentialProfile: false,
      refreshingPermissions: state.fetchingUserAdminCredential || false
    };
  case actionTypes.GET_MY_CREDENTIAL_PROFILE_ERROR:
    return {
      ...state,
      fetchingCredentialProfile: false,
      refreshingPermissions: state.fetchingUserAdminCredential || false
    };

  case actionTypes.GET_USER_CREDENTIAL_ADMIN:
    return {
      ...state,
      fetchingUserAdminCredential: true,
      refreshingPermissions: true
    };
  case actionTypes.GET_USER_CREDENTIAL_ADMIN_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        permissions: {
          ...state.data.permissions,
          ADMIN_CREDENTIAL: action.payload
        }
      },
      fetchingUserAdminCredential: false,
      refreshingPermissions: state.fetchingCredentialProfile || false
    };
  case actionTypes.GET_USER_CREDENTIAL_ADMIN_ERROR:
    return {
      ...state,
      fetchingUserAdminCredential: false,
      refreshingPermissions: state.fetchingCredentialProfile || false
    };

  default:
    return state;
  }
}

export default reducer;

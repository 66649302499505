import { compact } from 'lodash';
import { getEnvVar } from '../../helpers/commons';

const isLeafPath = (path) => {
  if (path === window.location.pathname.replace(getEnvVar('PUBLIC_URL'), '')) return true;

  // Split and clean current path and pathname by levels.
  const pathLevels = compact(path.split('/'));
  const pathnameLevels = compact(window.location.pathname.split('/'));

  if (pathLevels.length !== pathnameLevels.length) return false;

  /* If current path lvl is not a param and it is not equal than pathname lvl
  then current path route is differente than pathname. */
  return !pathLevels.find(
    (pathLvl, i) => pathLvl[0] !== ':' && pathnameLevels[i] !== pathLvl
  );
};

export default isLeafPath;

/* eslint-disable camelcase */
import axios from 'axios';
import adLogout from '../adLogout';
import { getEnvVar } from '../commons';
import * as storageUtil from '../cookiesUtil';

// import { checkUserTryingToByPassLogin } from '../loginByPass';

const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');
// const isLoginByPass = getEnvVar('REACT_APP_LOGIN_BY_PASS');

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((promess) => {
    if (error) {
      promess.reject(error);
    } else {
      promess.resolve(token);
    }
  });

  failedQueue = [];
};

const interceptor = (axiosInstance) => (error) => {
  const originalRequest = error.config;
  if (error.response?.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axiosInstance.request(originalRequest);
        })
        .catch((err) => Promise.reject(err));
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise((resolve, reject) => {
      // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      axios
        .post(
          `${BFF_ENDPOINT}/oauth2/v2/token/refresh`,
          {},
          {
            headers: {
              Authorization: `Bearer ${storageUtil.getItem('token')}`
            }
          }
        )
        .then(({ data }) => {
          const { access_token } = data;
          const sanityToken = decodeURIComponent(
            encodeURIComponent(access_token)
          );
          storageUtil.setItem('token', sanityToken); // direct set, need sanitization ...
          originalRequest.headers.Authorization = `Bearer ${sanityToken}`;
          processQueue(null, sanityToken);
          resolve(axiosInstance(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          if (
            err.response.status === 403
            && err.response.data === 'invalid_grant'
          ) {
            adLogout();
          }
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }

  return Promise.reject(error);
};

export default interceptor;

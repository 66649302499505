import { actionTypes } from '../actions/contact';

export const initialState = {
  showResume: false,
  loading: false,
  error: false,
  data: {},
  saving: false,
  saved: false,
  errorSaving: false,
  loadingContactData: false,
  loadedContactData: false,
  updateUserErrorMessage: ''
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_CONTACT_DATA:
    return {
      ...state,
      loadedContactData: false,
      loading: true,
      error: false
    };

  case actionTypes.GET_CONTACT_DATA_SUCCESS:
    return {
      ...state,
      loadedContactData: true,
      loading: false,
      error: false,
      data: {
        ...state.data,
        ...action.payload,
        emailPersonal: action.payload.email,
        mobile: action.payload.cellphone
      }
    };
  case actionTypes.GET_CONTACT_DATA_FAILURE:
    return {
      ...state,
      loading: false,
      error: true,
      loadedContactData: false
    };
  case actionTypes.SEND_CONTACT_INFO_SUCCESS:
    return {
      ...state,
      ...{ showResume: true }
    };

  case actionTypes.RESET_CONTACT_VIEW:
    return {
      ...state,
      ...{ showResume: false }
    };
  case actionTypes.UPDATE_CONTACT_DATA:
    return {
      ...state,
      errorSaving: false,
      saving: true,
      saved: false,
      updateUserErrorMessage: ''
    };

  case actionTypes.UPDATE_CONTACT_DATA_SUCCESS:
    return {
      ...state,
      errorSaving: false,
      saving: false,
      saved: true,
      data: {
        ...state.data,
        ...action.payload,
        emailPersonal: action.payload.email,
        mobile: action.payload.cellphone
      },
      updateUserErrorMessage: ''
    };

  case actionTypes.UPDATE_CONTACT_DATA_FAILURE:
    return {
      ...state,
      errorSaving: true,
      saving: false,
      saved: false,
      updateUserErrorMessage: action.error
    };
  case actionTypes.HANDLE_MODAL_SUCCESS:
    return {
      ...state,
      errorSaving: false,
      saving: false,
      saved: false,
      updateUserErrorMessage: ''
    };

  default:
    return state;
  }
}

export default reducer;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import { getSalaryAPI, getHistoricalBonusAPI } from '../api/compensations';
// eslint-disable-next-line import/named
import {
  actionTypes,
  getSalarySuccess,
  getHistoricalBonusSuccess,
  compensationsError
} from '../actions/compensations';

import { failure as commonsFailure } from '../actions/commons';

es6promise.polyfill();

function* getSalary() {
  try {
    const { data } = yield call(getSalaryAPI);
    yield put(getSalarySuccess(data));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(statusCode === 401 ? commonsFailure({ error, statusCode }) : compensationsError());
  }
}

function* getHistoricalBonus() {
  try {
    const data = yield call(getHistoricalBonusAPI);
    yield put(getHistoricalBonusSuccess(data));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(statusCode === 401 ? commonsFailure({ error, statusCode }) : compensationsError());
  }
}

function* compensationsSagas() {
  yield all([takeLatest(actionTypes.GET_SALARY, getSalary)]);
  yield all([takeLatest(actionTypes.GET_HISTORICAL_BONUS, getHistoricalBonus)]);
}

export default compensationsSagas;

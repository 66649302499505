import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SeveralOptions from '../Form/SeveralInputOptions';
import {
  STRENGTHS_SETTINGS,
  DEVELOPMENT_AREA_SETTINGS,
  DEVELOPMENT_PRIORITY_SETTINGS,
  SUCCESSS_MEASURES_SETTINGS,
  getInitialSeveralOptionsData,
  getStrengthsFieldsToUpdate,
  showAlertBeforeSaveIDP,
  getStatusIDP,
  STATUS_IDP
} from '../../../../../helpers/myEvolution/miDesarrollo';
import ModalMessage from '../../../../shared/ModalMessage';
import Form from '../Form';
import useGetIDP from '../../../../../hooks/myEvolution/miDesarrollo/useGetIDP';
import useSaveIDP from '../../../../../hooks/myEvolution/miDesarrollo/useSaveIDP';

const Strengths = (props) => {
  const { headerDescription, headerTitle } = props;

  const [strengths, setStrengths] = useState({});
  const [modalMessage, setModalMessage] = useState({ visible: false });

  const {
    keyStrengths,
    areasForDevelopment,
    developementPriorities,
    measuresOfSuccess
  } = strengths;

  const {
    success: successIDP,
    loading: loadingIDP,
    IDP,
    error: errorIDP
  } = useGetIDP();

  const { saveIDP, loading: loadingSavingIDP } = useSaveIDP({ setModalMessage });

  const setInitialData = () => {
    const {
      key_strenghts = [],
      areas_for_development = [],
      development_priorities = [],
      measures_of_success = ''
    } = IDP;

    setStrengths({
      keyStrengths: getInitialSeveralOptionsData(key_strenghts),
      areasForDevelopment: getInitialSeveralOptionsData(areas_for_development),
      developementPriorities: getInitialSeveralOptionsData(development_priorities),
      measuresOfSuccess: getInitialSeveralOptionsData(measures_of_success)
    });
  };
  useEffect(() => {
    if (!loadingIDP && successIDP) {
      setInitialData();
    }
  }, [IDP, loadingIDP, successIDP]);

  const handleSaveIDP = ({ status = STATUS_IDP.pending }) => {
    const newIDP = getStrengthsFieldsToUpdate({
      IDP,
      keyStrengths,
      areasForDevelopment,
      developementPriorities,
      measuresOfSuccess,
      status
    });

    showAlertBeforeSaveIDP({
      status,
      setModalMessage,
      loading: loadingSavingIDP,
      updateIDP: () => { saveIDP(newIDP); }
    });
  };

  return (
    <Form {...{
      description: headerDescription,
      title: headerTitle,
      handleSaveIDP,
      loadingSavingIDP,
      loadingIDP,
      errorIDP,
      ...getStatusIDP(IDP),
      filePath: IDP.filePath,
      IDP: {
        ...IDP,
        key_strenghts: keyStrengths,
        areas_for_development: areasForDevelopment,
        development_priorities: developementPriorities,
        measures_of_success: measuresOfSuccess
      }
    }}
    > { modalMessage.visible
      && <ModalMessage {...{
        ...modalMessage,
        closeModal: () => setModalMessage({ visible: false })
      }}/>}

      <SeveralOptions {...{
        title: STRENGTHS_SETTINGS.title,
        description: STRENGTHS_SETTINGS.description,
        placeholder: STRENGTHS_SETTINGS.placeholder,
        maxOptions: STRENGTHS_SETTINGS.maxOptions,
        maxLength: STRENGTHS_SETTINGS.maxLength,
        listToShow: keyStrengths,
        updateValueQuestion: (value) => {
          setStrengths({
            ...strengths,
            keyStrengths: value
          });
        }
      }} />
      <SeveralOptions {...{
        title: DEVELOPMENT_AREA_SETTINGS.title,
        description: DEVELOPMENT_AREA_SETTINGS.description,
        placeholder: DEVELOPMENT_AREA_SETTINGS.placeholder,
        maxOptions: DEVELOPMENT_AREA_SETTINGS.maxOptions,
        maxLength: DEVELOPMENT_AREA_SETTINGS.maxLength,
        listToShow: areasForDevelopment,
        updateValueQuestion: (value) => {
          setStrengths({
            ...strengths,
            areasForDevelopment: value

          });
        }
      }} />
      <SeveralOptions {...{
        title: DEVELOPMENT_PRIORITY_SETTINGS.title,
        description: DEVELOPMENT_PRIORITY_SETTINGS.description,
        placeholder: DEVELOPMENT_PRIORITY_SETTINGS.placeholder,
        maxOptions: DEVELOPMENT_PRIORITY_SETTINGS.maxOptions,
        maxLength: DEVELOPMENT_PRIORITY_SETTINGS.maxLength,
        listToShow: developementPriorities,
        updateValueQuestion: (value) => {
          setStrengths({
            ...strengths,
            developementPriorities: value
          });
        }
      }} />

      <SeveralOptions {...{
        title: SUCCESSS_MEASURES_SETTINGS.title,
        description: SUCCESSS_MEASURES_SETTINGS.description,
        placeholder: SUCCESSS_MEASURES_SETTINGS.placeholder,
        maxOptions: SUCCESSS_MEASURES_SETTINGS.maxOptions,
        maxLength: SUCCESSS_MEASURES_SETTINGS.maxLength,
        listToShow: measuresOfSuccess,
        updateValueQuestion: (value) => {
          setStrengths({
            ...strengths,
            measuresOfSuccess: value
          });
        }
      }} />

    </Form>
  );
};

Strengths.propTypes = {
  headerTitle: PropTypes.string
};

Strengths.defaultProps = {
  headerTitle: ''
};

export default Strengths;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import InfoCardField from '../../../../shared/cards/InfoCardField';
import { formatDateCard } from '../../../../../helpers/dates';
import { statusClasses } from '../../../../../helpers/requests';

const Action = (props) => {
  const {
    indexAction, action, handleEndAction, endAt
  } = props;
  const {
    actionName, status, closeAt
  } = action;

  const getStatus = () => {
    switch (status) {
    case 'PENDING':
      return { labelStatus: 'En proceso', statusIcon: 'Pending' };
    case 'FINISHED':
      return { labelStatus: 'Finalizada', statusIcon: 'Finished' };
    case 'CLOSED':
      return { labelStatus: 'No Finalizada', statusIcon: 'Closed' };
    default:
      return { labelStatus: '', statusIcon: '' };
    }
  };

  const { labelStatus, statusIcon } = getStatus();

  return (
    <div className='box-action'>
      <div className='container-title-action'>
        <h6>Acción Nº{indexAction + 1}</h6>
      </div>
      <div>
        <InfoCardField
          classNameIcon={'icon-ml-objetivo color-objective'}
          title='Acción a desarrollar'>
          <p className='description'>{actionName}</p>
        </InfoCardField>
        <div className='container-info-actions'>
          <div className='d-flex justify-content-start align-items-lg-start'>
            <InfoCardField
              classNameIcon={'icon-ml-calendar-2 color-date'}
              title='Fecha límite'>
              <p className='description'>{formatDateCard(endAt)}</p>
            </InfoCardField>
            <InfoCardField
              classNameIcon={statusClasses(statusIcon)}
              title='Estado'>
              <p className='description'>{labelStatus}</p>
            </InfoCardField>
          </div>
          { status === 'FINISHED'
            && <InfoCardField
              classNameIcon={'icon-ml-fecha-cierre color-date'}
              title='Cierre'>
              <p className='description'>{formatDateCard(closeAt)}</p>
            </InfoCardField>
          }
        </div>
        { status === 'PENDING'
            && (
              <div className='container-button-end-action '>
                <Button
                  onClick={() => handleEndAction(action)}
                  className='button-border-primary'>
                  Finalizar Acción
                </Button>
              </div>
            )
        }

      </div>
    </div>
  );
};
Action.propTypes = {
  action: PropTypes.object,
  indexAction: PropTypes.number,
  handleEndAction: PropTypes.func

};

Action.defaultProps = {
  action: { },
  indexAction: 0,
  handleEndAction: () => {}
};

export default Action;

import { lazy } from 'react';

const MyEvolutionTeamEvaluationsPerformance = lazy(() => import('../../../../containers/myTeam/myEvolution/Retro'));
const HistoricalMyTeamPerformanceEvaluation = lazy(() => import('../../../../components/myTeam/myEvolution/retro'));
const DetailColaborator = lazy(() => import('../../../../components/myTeam/myEvolution/retro/Historical/DetailColaborator'));

export default [
  {
    path: '/mi-equipo/mi-evolucion/retroalimentación',
    section: 'Mi Equipo - Mi Evolución - Retroalimentación',
    component: MyEvolutionTeamEvaluationsPerformance,
    auth: 'MY_TEAM_MY_EVOLUTION_RETRO',
    subRoutes: [
      {
        tabTitle: 'Evaluaciones',
        section: 'Mi Equipo - Mi Evolución - Retroalimentación - Evaluaciones',
        path: '/mi-equipo/mi-evolucion/retroalimentación/evaluaciones',
        component: HistoricalMyTeamPerformanceEvaluation,
        subRoutes: [
          {
            exact: true,
            headerTitle: null,
            tabTitle: 'Colaborador Individual',
            path: '/mi-equipo/mi-evolucion/retroalimentación/evaluaciones',
            component: DetailColaborator
          }
        ]
      },
      {
        tabTitle: 'Validaciones',
        auth: 'MI_EQUIPO_MI_EVOLUCION_RETROALIMENTACION_VALIDACIONES',
        section: 'Mi Equipo - Mi Evolución - Retroalimentación - Validaciones',
        path: '/mi-equipo/mi-evolucion/retroalimentación/validaciones',
        component: HistoricalMyTeamPerformanceEvaluation,
        subRoutes: [
          {
            exact: true,
            headerTitle: null,
            auth: 'MI_EQUIPO_MI_EVOLUCION_RETROALIMENTACION_VALIDACIONES',
            tabTitle: 'Colaborador Individual',
            path: '/mi-equipo/mi-evolucion/retroalimentación/validaciones',
            component: DetailColaborator
          }
        ]
      }
    ]
  }
];

import axios from '../../helpers/axios';
import getCurrentYear from '../../helpers/myTeam/myEvolution/evaluations/getCurrentYear';
import { getEnvVar } from '../../helpers/commons';

const BFF_ENDPOINT = getEnvVar('REACT_APP_API_REPORTS');

export const getContentAPI = () => {
  const queryParams = `year=${getCurrentYear()}`;

  return axios().get(
    `${BFF_ENDPOINT}/evaluations/calibrations?${queryParams}`,
    {
      timeout: 600000
    }
  ).then((resp) => resp);
};

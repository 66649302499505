import gql from 'graphql-tag';
import { retro } from '../../../mievolucion/retro/queries';

export const GET_LAST_RETRO_COMMUNICATED = gql`
    query lastRetroCommunicated(
        $rut: String
    ) {
        lastRetroCommunicated(
            rut: $rut
        ) 
        {
            ${retro}
        }
    }
`;

export const MY_TEAM_SUBORDINATES_AS_EVALUATOR = gql`
    query mySubordinatesRetroAsEvaluator(
        $filter: FiltersRetro

    ) {
        mySubordinatesRetroAsEvaluator(
            filter: $filter
        )   
        {
            rut
            fullName
            firstIncomeDate
            position
            hasNotification
            dateOld {
                fecha
            }
            evaluationType
            name
            fatherLastName
        }
    }
`;

export const GET_LIST_RETRO_SUBORDINATE = gql`
    query listRetroSubordinate(
        $rut: String!
    ) {
        listRetroSubordinate(
            rut: $rut
        ) 
        {
            ${retro}
        }
    }
`;

export const GET_LIST_USERS_TO_VALIDATE = gql`
    query {
        listUsersRetroToValidate{
            user {
                rut
                name
                fatherLastName
                fullName
                position
                dateOld {
                    fecha
                }
                evaluationType   
            }
            toValidate
        }
    }
`;

export const MY_TEAM_SUBORDINATES_AS_EVALUATOR_FILTERS = gql`
    query {
        mySubordinatesRetroAsEvaluatorFilters{
            label      
            type
        }
    }
`;

export const GET_LIST_JUSTIFY_OPTIONS = gql`
    query {
      listJustifyRetroOptions{
        _id
        name
      }
    }
`;

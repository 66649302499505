import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { AppRouterContext } from '../context/AppRouterContext';
import useSectionName from './useSectionName';

export default function useRouteLayout(routeProps) {
  const {
    isPublic, headerTitle, hideBanner, routeSection
  } = routeProps;

  const {
    changeAppLayoutProps, milugarEnabled, routesContainerReef
  } = useContext(AppRouterContext);

  const isEnabledMiLugar = !milugarEnabled.error && milugarEnabled.data;
  const section = useSectionName();
  const me = useSelector((state) => state.userReducer.data);

  const setRouteLayout = () => {
    if (Object.isExtensible(routesContainerReef.current)) {
      if (isPublic || !me) {
        routesContainerReef.current.id = null;
      } else if (section === 'mio') {
        routesContainerReef.current.id = isEnabledMiLugar ? 'mio' : 'milugar';
      } else {
        document.title = 'Mi Lugar';
        routesContainerReef.current.id = 'milugar';
      }

      const opts = {
        headerTitle: headerTitle || null,
        hideBanner: hideBanner || false,
        section: routeSection || null
      };

      changeAppLayoutProps(opts);
    }
  };

  return setRouteLayout;
}

import { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import useWindowWidthSize from '../../../helpers/browser/useWindowWidthSize';
import { smallDevice, mediumDevice } from '../../../helpers/deviceVariables';

const useStatusSlider = ({ data = [], duration = 0.5 }) => {
  const [slider, changeSlider] = useState({ currentXPositionPercent: 0, slide: 0 });
  const {
    slide, preparingLeft, preparingRight, style
  } = slider;
  const witdh = useWindowWidthSize();
  const isMediumDevice = witdh < mediumDevice;
  const isSmallDevice = witdh < smallDevice;
  const dataLength = data?.length;
  const animation = style?.animation;
  const animations = {
    left: `${duration}s slide-ml-status-step-left forwards`,
    right: `${duration}s slide-ml-status-step-right forwards`
  };

  const lengthDivicer = (() => {
    if (isSmallDevice) return 2;
    if (isMediumDevice) return 3;
    return 0;
  })();

  const length = lengthDivicer ? dataLength / lengthDivicer : 0;

  const getValueByIndex = (index) => (index > -1 ? index * 100 : -1);

  const getDirectionByIndex = (valueByIndex) => {
    const valueByPreviousIndex = slider.currentXPositionPercent * -1;

    return (
      valueByIndex !== valueByPreviousIndex && (valueByIndex < valueByPreviousIndex ? 'Left' : 'Right')
    );
  };

  const handleLeftSlide = (index = -1) => {
    if (!preparingRight) {
      changeSlider({
        ...slider, slide: index !== -1 ? index : slide - 1, preparingLeft: true, style: { ...slider?.style, animation: 'none' }
      });
    }
  };

  const handleRightSlide = (index = -1) => {
    if (!preparingLeft) {
      changeSlider({
        ...slider, slide: index !== -1 ? index : slide + 1, preparingRight: true, style: { ...slider?.style, animation: 'none' }
      });
    }
  };

  const handleByIndex = (index = -1) => {
    const valueByIndex = getValueByIndex(index);
    const slideKey = getDirectionByIndex(valueByIndex);

    if (slideKey === 'Left') handleLeftSlide(index);
    if (slideKey === 'Right') handleRightSlide(index);
  };

  const handleSlide = (direction = '', index = -1) => {
    if (!direction && index === -1) return null;

    const sumValue = (() => {
      switch (direction) {
      case 'left':
        return 100;
      case 'right':
        return -100;
      default:
        return 0;
      }
    })();

    const valueByIndex = getValueByIndex(index);

    if (!sumValue && valueByIndex === -1) return null;

    const slideKey = capitalize(direction) || getDirectionByIndex(valueByIndex);

    const currentXPositionPercent = sumValue ? (
      slider.currentXPositionPercent + sumValue
    ) : (
      valueByIndex * (slideKey === 'Right' ? -1 : 1)
    );

    return slideKey && changeSlider({
      ...slider,
      currentXPositionPercent,
      style: {
        '--current-x-position': `${currentXPositionPercent}%`,
        animation: animations[slideKey.toLocaleLowerCase()]
      }
    });
  };

  const endAnimation = (direction = '') => {
    if (!direction) return null;

    return setTimeout(() => {
      changeSlider({
        ...slider,
        [`preparing${capitalize(direction)}`]: false,
        style: { ...style, transform: 'translate(var(--current-x-position), 0)' }
      });
    }, duration * 1000);
  };

  useEffect(() => {
    if (animation === 'none') {
      if (preparingLeft) handleSlide('left');
      if (preparingRight) handleSlide('right');
    } else if (animation) {
      if (animation === animations.left) endAnimation('left');
      if (animation === animations.right) endAnimation('right');
    }
  }, [animation]);

  return {
    props: {
      active: slide,
      minReached: !slide,
      maxReached: slide + 1 === length,
      length,
      preparingLeft,
      preparingRight,
      handleLeftSlide,
      handleRightSlide,
      handleByIndex
    },
    style
  };
};

export default useStatusSlider;

export const actionTypes = {
  FIND_FILE: 'FIND_FILE',
  FIND_FILE_SUCCESS: 'FIND_FILE_SUCCESS',
  FIND_FILE_FAILURE: 'FIND_FILE_FAILURE',
  CLEAN_SEARCH: 'CLEAN_SEARCH'
};

export function cleanSearch(name) {
  return {
    type: actionTypes.CLEAN_SEARCH,
    name
  };
}

export function getFile(input) {
  return {
    type: actionTypes.FIND_FILE,
    input
  };
}

export function getFileSuccess(data) {
  return {
    type: actionTypes.FIND_FILE_SUCCESS,
    data
  };
}

export function getFileFailure(error) {
  return {
    type: actionTypes.FIND_FILE_FAILURE,
    error
  };
}

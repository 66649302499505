import { delay } from 'redux-saga';
import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get, omit, has } from 'lodash';
import * as storageUtil from '../../helpers/cookiesUtil';

import {
  getMainBenefits,
  getCategories,
  getBenefitStatus,
  getBenefitDetails,
  postBenefitRequest,
  getHomeCategories,
  getTeamRequests,
  postRequestStatus,
  getFlickrAlbum,
  getMyBenefitsRequested,
  getMonthlyStatus,
  getGiftDayWeeksAPI,
  getSchoolWinnersAPI,
  postBenefitRequestFile
} from '../api/benefits';
import {
  actionTypes,
  getMainBenefitsSuccess,
  getCategoriesSuccess,
  getBenefitStatusSuccess,
  getBenefitStatusError,
  postBenefitRequestSuccess,
  postBenefitRequestError,
  getBenefitDetailsSuccess,
  getHomeCategoriesSuccess,
  getTeamBenefitsSuccess,
  getTeamBenefitsError,
  postRequestStatusSuccess,
  postRequestStatusError,
  getFlickrAlbumSuccess,
  getMyRequestsSuccess,
  getMyRequestsError,
  getMonthlyStatusSuccess,
  getGiftDayWeeksSuccess,
  getSchoolWinnersSuccess,
  postBenefitsDisconectionError,
  postBenefitsDisconectionSuccess,
  postBenefitRequestFileSuccess,
  postBenefitRequestFileError
} from '../actions/benefits';
import {
  failure, loading, loadComplete, loadingAPI, loadingAPIComplete
} from '../actions/commons';

es6promise.polyfill();

function* mainBenefits() {
  try {
    yield put(loading());
    yield call(delay, 3000);
    const response = yield call(getMainBenefits);
    yield put(getMainBenefitsSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* categories() {
  try {
    yield put(loading());
    const response = yield call(getCategories);
    yield put(getCategoriesSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* benefitStatus(actions) {
  try {
    const response = yield call(getBenefitStatus, actions.payload);
    yield put(getBenefitStatusSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getBenefitStatusError(error));
  }
}

function* benefit(actions) {
  try {
    yield put(loadingAPI(actions.payload.loader));
    const { category } = actions.payload;
    const params = { category };
    const apiParams = Object.assign(actions.payload, { params });
    const response = yield call(getBenefitDetails, apiParams);
    yield put(getBenefitDetailsSuccess(response));
    yield put(loadingAPIComplete(actions.payload.loader));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadingAPIComplete(actions.payload.loader));
    if (error === 'El id de beneficio no es válido') {
      window.location.push('/error');
    }
  }
}

function* benefitRequest(actions) {
  try {
    const response = yield call(postBenefitRequest, actions.payload);
    yield put(postBenefitRequestSuccess(response));
    if (has(actions.payload, 'to')) {
      const to = actions.payload.to.toLowerCase();
      const params = {
        limit: 10,
        offset: 0,
        direction: 'desc',
        position: to === 'coordinator' ? 'ASISTENTE SOCIAL' : 'COORDINADOR PILAR SOCIAL'
      };
      const responseTeam = yield call(getTeamRequests, params);
      yield put(getTeamBenefitsSuccess(responseTeam));
    }
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(postBenefitRequestError(error));
  }
}

function* postRequestFile({ data }) {
  try {
    const response = yield call(postBenefitRequestFile, data);
    yield put(postBenefitRequestFileSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(postBenefitRequestFileError(error));
  }
}

function* homeCategories(actions) {
  try {
    yield put(loadingAPI(actions.payload.loader));
    const response = yield call(getHomeCategories);
    yield put(getHomeCategoriesSuccess(response));
    yield put(loadingAPIComplete(actions.payload.loader));
  } catch (e) {
    const error = get(e, 'response.data');
    const statusCode = get(e, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadingAPIComplete(actions.payload.loader));
  }
}

function* teamRequests(actions) {
  try {
    yield put(loadingAPI(actions.payload.loader));
    const user = storageUtil.getItem('user');
    const response = yield call(getTeamRequests, { ...omit(actions.payload, 'loader'), rut: user });
    yield put(getTeamBenefitsSuccess(response));
    yield put(loadingAPIComplete(actions.payload.loader));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(getTeamBenefitsError(error));
    yield put(loadingAPIComplete(actions.payload.loader));
  }
}

function* myRequests(actions) {
  try {
    yield put(loadingAPI(actions.payload.loader));
    const user = storageUtil.getItem('user');
    const params = { ...actions.payload, rut: user };
    const response = yield call(getMyBenefitsRequested, params);
    yield put(getMyRequestsSuccess(response));
    yield put(loadingAPIComplete(actions.payload.loader));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(getMyRequestsError(error));
    yield put(loadingAPIComplete(actions.payload.loader));
  }
}

function* monthlyStatus(actions) {
  try {
    yield put(loadingAPI(actions.payload.loader));
    const apiParams = actions.payload;
    const response = yield call(getMonthlyStatus, apiParams);
    yield put(getMonthlyStatusSuccess(response));
    yield put(loadingAPIComplete(actions.payload.loader));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadingAPIComplete(actions.payload.loader));
  }
}

function* requestStatus(actions) { // ejemplo
  try {
    yield put(loading());
    const updateParams = actions.payload;
    const updateResponse = yield call(postRequestStatus, updateParams);
    const params = get(actions, 'payload.params', {});
    const filters = get(actions, 'payload.filters', {});
    const month = get(actions, 'payload.month', -1);
    const loader = get(actions, 'payload.loader', null);
    let responseParams;
    yield put(postRequestStatusSuccess(updateResponse));
    if (month < 0) {
      responseParams = { ...params, ...filters };
      const responseTeam = yield call(getTeamRequests, responseParams);
      const responseMy = yield call(getMyBenefitsRequested, responseParams);
      yield put(getTeamBenefitsSuccess(responseTeam));
      yield put(getMyRequestsSuccess(responseMy));
    } else {
      responseParams = { payload: { month, loader } };
      yield call(monthlyStatus, responseParams);
    }
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(postRequestStatusError(error));
    yield put(loadComplete());
  }
}

function* flickrAlbum(actions) {
  try {
    yield put(loading());
    const response = yield call(getFlickrAlbum, actions.payload);
    yield put(getFlickrAlbumSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* getGiftDayWeeks(action) {
  try {
    const response = yield call(getGiftDayWeeksAPI, action.data);
    yield put(getGiftDayWeeksSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getBenefitStatusError(error));
  }
}

function* getSchoolWinners() {
  try {
    const response = yield call(getSchoolWinnersAPI);
    yield put(getSchoolWinnersSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getBenefitStatusError(error));
  }
}

function* postDisconnectionRequest(params) {
  try {
    const response = yield call(postBenefitRequest(params));
    yield put(postBenefitsDisconectionSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(postBenefitsDisconectionError(error));
  }
}

function* benefitsSagas() {
  yield all([
    takeLatest(actionTypes.GET_MAIN_BENEFITS, mainBenefits),
    takeLatest(actionTypes.GET_CATEGORIES, categories),
    takeLatest(actionTypes.GET_BENEFIT_STATUS, benefitStatus),
    takeLatest(actionTypes.GET_BENEFIT_DETAILS, benefit),
    takeLatest(actionTypes.POST_BENEFIT_REQUEST, benefitRequest),
    takeLatest(actionTypes.GET_HOME_CATEGORIES, homeCategories),
    takeLatest(actionTypes.GET_TEAM_BENEFITS, teamRequests),
    takeLatest(actionTypes.POST_REQUEST_STATUS, requestStatus),
    takeLatest(actionTypes.GET_FLICKR_ALBUM, flickrAlbum),
    takeLatest(actionTypes.GET_MY_BENEFITS, myRequests),
    takeLatest(actionTypes.GET_MONTHLY_STATUS, monthlyStatus),
    takeLatest(actionTypes.GET_GIFT_DAY_WEEKS, getGiftDayWeeks),
    takeLatest(actionTypes.GET_SCHOOL_WINNERS, getSchoolWinners),
    takeLatest(actionTypes.POST_BENEFIT_DISCONNECTION, postDisconnectionRequest),
    takeLatest(actionTypes.POST_REQUEST_FILES, postRequestFile)
  ]);
}

export default benefitsSagas;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import { getRankingRecoApi } from '../api/rankingReco';
import { actionTypes, getRankingRecoError, getRankingRecoSuccess } from '../actions/rankingReco';

es6promise.polyfill();

export function* getRankingReco({ variables = {} }) {
  try {
    const response = yield call(getRankingRecoApi, variables);
    yield put(getRankingRecoSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(getRankingRecoError({ ...error, statusCode }));
  }
}

function* rankingRecoSagas() {
  yield all([
    takeLatest(actionTypes.GET_RANKING_RECO, getRankingReco)
  ]);
}

export default rankingRecoSagas;

import gql from 'graphql-tag';
import { gqlMessages } from '../../../helpers/myEvolution/evaluations';

export const categoryFields = `
    {
        _id
        name
        description
        finalSkillDescription
        skillDescription
        score
    }
`;

const user = `{
    rut
    name
    secondName
    fatherLastName
    motherLastName
    position
    dateOld {
        fecha
    }
    additionalField4
    evaluationType
    fullName

}`;
const evaluated = `
    evaluated ${user}
`;
const evaluator = `
    evaluator  ${user}
`;
const validator = `
    validator  ${user}
`;

const skills = `
    skills {
        _id
        name
        description
        shortName
        behaviors {
            title
            description
        }
        categorySelected ${categoryFields}
        SAPCounter
    }
`;

export const retro = `
    _id
    year
    updatedAt
    createdAt
    status
    strengths
    opportunities
    updatedAt
    lastStepEvaluation{
        component
        step
    }
    ${evaluated}
    ${evaluator}
    ${validator}
    ${skills}
    finalCategory ${categoryFields}
    finalSkillCategory ${categoryFields}
    skillType
    comment {
        comment
        status
    }
    hasOpinion
    UCM
    justification {
        _id
        name
    }
    dateChangedStatus{
        communicated
    }
    messages ${gqlMessages}
`;

export const GET_MY_RETROS = gql`
    query {
        myRetros{
            ${retro}
        }
    }
`;

export const GET_LIST_OPINION = gql`
    query {
        listQuestionsRetroOpinion{
            _id
            name
            inputType
            options
            maxLength
            placeHolder
            lowerOptionText
            higherOptionText
            type
            questionBlocksOthers
            initiallyHidden
        }
    }
`;

import React from 'react';
import {
  Checkbox
} from 'antd';

const Action = (props) => {
  const { indexAction, action, handleAction } = props;

  return (

    <Checkbox
      className='container-action'
      checked={action.checked}
      onChange={({ target: { checked } }) => {
        handleAction(checked, action);
      }}>
      <div className='ml-3'>
        <h6>Acción Nº{indexAction + 1}</h6>
        <p>{action.name}</p>
      </div>
    </Checkbox>

  );
};
export default Action;

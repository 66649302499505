import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useQuery } from '@apollo/client';
import MLModalMessage from '../../../../shared/MLModal/Message';
import useOpenModal from '../../../../../hooks/shared/mlModal/useOpenModal';
import useUpdatePerformanceStatus from '../../../../../hooks/myTeam/myEvolution/performance/updatePerformanceStatus';
import { GET_NOTIFICATIONS } from '../../../../../graphql/notifications/queries';
import PulseSurvey from '../PulseSurvey';
import { FIRST_PULSE } from '../../../../../helpers/myEvolution/myPerformance';
import { LIST_SETTINGS } from '../../../../../graphql/settings/queries';
import LoaderComponent from '../../../../Loader';
import { getValueKeySetting } from '../../../../../helpers/settings';

const RequestPreviewModal = MLModalMessage;
const SuccessModal = MLModalMessage;
const ErrorModal = MLModalMessage;

const SendToBoss = ({
  bossName,
  error,
  refetch,
  commentFormRef,
  performance
}) => {
  const {
    loading: loadingSettings,
    data: settings
  } = useQuery(LIST_SETTINGS,
    {
      context: { clientName: 'myEvolution' },
      variables: { keys: ['ENABLED_SURVEY_PULSE', 'TYPE_SURVEY_PULSE'] },
      fetchPolicy: 'network-only'
    });

  const { listSettings = [] } = settings || {};

  const surveyType = getValueKeySetting(listSettings, 'TYPE_SURVEY_PULSE');
  const showSurvey = getValueKeySetting(listSettings, 'ENABLED_SURVEY_PULSE');

  const { quarterCurrent, _id: performanceID, atLeastOnceClosedStatus } = performance;

  const { callApi: updatePerformanceStatus } = useUpdatePerformanceStatus({
    refetchQueries: [
      {
        query: GET_NOTIFICATIONS
      }
    ]
  });
  const { open: openSucessModal, refOpen: refSuccessModal } = useOpenModal();
  const { open: openErrorModal, refOpen: refErrorMpdal } = useOpenModal();
  const [isOpenModalOpinion, setIsOpenModalOpinion] = useState(false);
  const firstQ = quarterCurrent === FIRST_PULSE;

  const sendAdvanceSubmit = (events) => {
    try {
      events.closeModal();
      openSucessModal({
        title: atLeastOnceClosedStatus
          ? `¡El avance de tu desempeño fue enviado con con éxito a ${bossName}!`
          : `¡La propuesta fue enviada con éxito a ${bossName}!`,
        closeModal: () => {
          events.closeModal();
          if (showSurvey) {
            setIsOpenModalOpinion(true);
          } else {
            refetch();
          }
        }
      });
    } catch (_) {
      events.endLoading();
      openErrorModal();
    }
  };

  const handleOk = async (events) => {
    events.startLoading();

    try {
      if (firstQ) {
        await updatePerformanceStatus({
          variables: {
            input: {
              status: 'SENT'
            }
          }
        });

        sendAdvanceSubmit(events);
      } else {
        commentFormRef.current.props.onSubmit(events, () => sendAdvanceSubmit(events));
      }
    } catch (err) {
      events.endLoading();
      openErrorModal();
    }
  };

  const handleSuccessOk = async (events) => {
    await events.closeModal();
    if (showSurvey) {
      setIsOpenModalOpinion(true);
    } else {
      refetch();
    }
  };

  const closeModalOpinion = () => {
    setIsOpenModalOpinion(false);
    refetch();
  };
  if (loadingSettings) {
    return <LoaderComponent />;
  }

  return (
    <>
      <RequestPreviewModal {...{
        OpenButton: (openButtonProps) => (
          <Button {...openButtonProps}
            onClick={() => {
              openButtonProps.onClick();
            }} type='primary' className='send-to-boss-button'>
            Enviar a jefatura
          </Button>
        ),
        ...(error || {
          type: 'warning',
          icon: 'icon-ml-warning pending-icon-color',
          title: atLeastOnceClosedStatus
            ? `¿Quieres enviar el avance de tu desempeño a ${bossName}?`
            : `¿Quieres enviar la propuesta a ${bossName}?`,
          okButtonText: `Sí, enviar${firstQ ? ' propuesta' : ''}`,
          cancelButtonText: 'No, cancelar',
          defaultCancel: true,
          handleOk
        })
      }}

      />

      <SuccessModal {...{
        refOpen: refSuccessModal,
        type: 'success',
        handleOk: handleSuccessOk,
        closeModal: handleSuccessOk
      }}/>

      <ErrorModal {...{
        title: `No pudimos enviar ${atLeastOnceClosedStatus ? 'el avance de tu desempeño' : 'la propuesta'}`,
        message: 'Estamos trabajando en solucionarlo, por favor intenta de nuevo más tarde.',
        refOpen: refErrorMpdal
      }}/>

      <PulseSurvey
        {...{
          isOpenModalOpinion,
          performanceID,
          quarterCurrent,
          closeModalOpinion,
          surveyType
        }
        }
      />

    </>
  );
};
SendToBoss.propTypes = {
  bossName: PropTypes.string,
  error: PropTypes.object,
  refetch: PropTypes.func,
  performance: PropTypes.object
};

SendToBoss.defaultProps = {
  bossName: '<JEFE>',
  error: null,
  refetch: () => {},
  performance: {}
};

export default SendToBoss;

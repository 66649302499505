import React from 'react';
import PropTypes from 'prop-types';
import {
  Radio, Input
} from 'antd';
import { map } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Item } from '../Form';
import { WITHOUTINFO } from '../../../../helpers/myEvolution/interestsAndSkills';

const CoursesOrTraining = (props) => {
  const {
    haveCoursesOrTraining, coursesOrTraining, setHaveCoursesOrTraining, setCoursesOrTraining
  } = props;

  const onChangeHaveCourses = ({ target }) => {
    setHaveCoursesOrTraining(target.value);
  };
  const addOtherCoursesOrTraining = (e) => {
    e.preventDefault();
    const newCoursesOrTraining = Object.assign([], coursesOrTraining);
    newCoursesOrTraining.push({ name: '', _id: uuidv4() });
    setCoursesOrTraining(newCoursesOrTraining);
  };
  const onChangeCourseOrTraining = (value, _id) => {
    const newCoursesOrTraining = coursesOrTraining.map(
      (item) => (item._id === _id ? { ...item, name: value } : item)
    );
    setCoursesOrTraining(newCoursesOrTraining);
  };

  const removeCourseOrTraining = (i) => {
    const newCoursesOrTraining = Object.assign([], coursesOrTraining);
    newCoursesOrTraining.splice(i, 1);
    setCoursesOrTraining(newCoursesOrTraining);
  };

  return (
    <>
      <div className='row'>
        <Item
          className='col-12 col-md-12 col-lg-6'
          label='¿Has realizado cursos, diplomados o capacitaciones específicas?'
        >
          <Radio.Group
            className='ml-radio-button'
            defaultValue={haveCoursesOrTraining}
            onChange={onChangeHaveCourses} >
            <Radio value={'YES'}>
              Si
            </Radio>
            <Radio value={'NO'}>
              No
            </Radio>
          </Radio.Group>
        </Item>
      </div>

      { haveCoursesOrTraining === 'YES'
        ? <div>
          {
            map(coursesOrTraining, (item, i) => (
              <div
                key={i}
                className='row'>
                <Item
                  className='col-12 col-md-12 col-lg-6'
                  label='¿Cuál?'
                >

                  <Input
                    key={item._id}
                    value={item.name}
                    onChange={({ target }) => {
                      onChangeCourseOrTraining(target.value, item._id);
                    }}
                    placeholder='Nombre de curso, diplomado, etc.' />
                  { coursesOrTraining.length > 1
            && <p className='text-delete' onClick={(e) => {
              e.preventDefault();
              removeCourseOrTraining(i);
            }
            }
            >Eliminar</p>
                  }

                </Item>
              </div>
            ))
          }
          { coursesOrTraining.length < 3
            ? <p className='text-add-other' onClick={addOtherCoursesOrTraining}>Agregar otro</p> : null
          }
        </div>
        : null
      }

    </>

  );
};
CoursesOrTraining.propTypes = {
  haveCoursesOrTraining: PropTypes.string,
  coursesOrTraining: PropTypes.array,
  setHaveCoursesOrTraining: PropTypes.func,
  setCoursesOrTraining: PropTypes.func
};

CoursesOrTraining.defaultProps = {
  haveCoursesOrTraining: WITHOUTINFO,
  coursesOrTraining: [],
  setHaveCoursesOrTraining: () => {},
  setCoursesOrTraining: () => {}
};

export default CoursesOrTraining;

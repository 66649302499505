/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

const FOUR_C_SKILLS_PART = gql`
  fragment fourCSkillShared on Skills4CResponse {
        _id
        behaviors {
            title
            description
        }
        name
        shortName
    }
`;

export const GET_ALL_4C_SKILLS = gql`
    ${FOUR_C_SKILLS_PART}
    
    query {
        listAll4CSkills {
            ...fourCSkillShared
            description
        }
    }
`;

export const GET_SKILLS = gql`

    query listSkills(
        $type: SkillType
    ) {
        listSkills(
            type: $type
        ) {
            _id
            name
            description
            type 
        }
    }
`;

export const GET_GENERAL_MODEL_SKILLS_4C = gql`
    ${FOUR_C_SKILLS_PART}

    query generalModel4C(
        $filter: Filter4C!
    ) {
        generalModel4C(
                filter: $filter
        ) {
            name
            ucm
            skills {
                ...fourCSkillShared
            }
        }
    }
`;
export const GET_LIST_FILTER_GENERAL_MODEL4C = gql`
    query {
        listFilterGeneralModel4C{
            label
            type
        }
    }
`;
export const GET_MY_TEAM_SKILLS_4C = gql`
    query {
        skill4CSubordinates {
            UCM
            UCMName
            subordinates {
                rut
                name
                fatherLastName
                position
            }
        }
    }
`;
export const GET_MY_SKILLS_4C = gql`
    ${FOUR_C_SKILLS_PART}

    query {
        listMySkillsBehaviors4C {
            ...fourCSkillShared            
        }
    }
`;

export const GET_SKILLS_BEHAVIORS_4C_BY_UCM = gql`
    ${FOUR_C_SKILLS_PART}

    query listSkillsBehaviors4CByUCM(
        $UCM: Int!
    ) {
        listSkillsBehaviors4CByUCM(
            UCM: $UCM
        ) { ...fourCSkillShared }
    }
`;

import { actionTypes } from '../actions/agreements';

export const initialState = {
  agreement: null
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_AGREEMENT:
    return {
      ...state,
      ...{ agreement: action.data }
    };

  case actionTypes.GET_AGREEMENTS:
    return {
      ...state,
      ...{ agreements: action.data }
    };

  case actionTypes.GET_AGREEMENTS_SUCCESS:
    return {
      ...state,
      ...{ agreements: action.data }
    };

  case actionTypes.GET_AGREEMENT_SUCCESS:
    return {
      ...state,
      ...{ agreement: action.data }
    };
  default:
    return state;
  }
}

export default reducer;

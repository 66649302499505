const getObjectivesValidateValues = () => ({
  minWeightInput: 5,
  maxWeightInput: 50,
  expectedWeight: 100,
  minObjectives: 3,
  maxObjectives: 10,
  minSuccessMeasures: 1,
  maxSuccessMeasures: 7
});

export default getObjectivesValidateValues;

import React from 'react';
import PropTypes from 'prop-types';
import { Card, List } from 'antd';
import { capitalizeText } from '../../../../helpers/commons';

const Result = (props) => {
  const {
    user: {
      fullName,
      position
    }
  } = props;
  return (
    <Card>
      <p>
        {capitalizeText(fullName)}
      </p>

      <p className='text-position'>
        {capitalizeText(position)}
      </p>
    </Card>

  );
};
const SearchUsersResults = (props) => {
  const {
    hideResults, searchUser, selectUser, onClose, onPick
  } = props;

  const { data, loading } = searchUser;

  return (
    <div className='container-results-search-users'>
      <div className='container-header-results d-flex justify-content-between align-items-center'>
        <p>Resultados:</p>
        <i onClick={onClose} className='icon-me-people-remove'/>
      </div>
      <List
        locale={{ emptyText: 'No se han encontrado resultados' }}
        bordered
        loading={loading}
        dataSource={!hideResults ? data : []}
        renderItem={(item) => (
          <List.Item className='user-card' onClick={(e) => onPick(e, item)}>
            <Result user={item} selectUser={selectUser}/>
          </List.Item>
        )}
      />
    </div>
  );
};

SearchUsersResults.propTypes = {
  selectUser: PropTypes.func,
  setRefetchTrigger: PropTypes.func
};

SearchUsersResults.defaultProps = {
  selectUser: () => {},
  setRefetchTrigger: () => {}
};

export default SearchUsersResults;

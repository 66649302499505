import React from 'react';
import PropTypes from 'prop-types';
import {
  Input
} from 'antd';
import {
  map
} from 'lodash';
import Question from './Question';
import { DEFAULT_FORM_VALUE } from '../../../../../helpers/myEvolution/miDesarrollo';
import WrittenCharacters from './WrittenCharacters';

const SeveralInputOptions = ({
  showAdd,
  showDelete,
  title,
  description,
  listToShow,
  updateValueQuestion,
  placeholder,
  maxOptions,
  maxLength
}) => (

  <Question
    title={title}
    description= {description}
  >
    {
      map(listToShow, (value, index) => (
        <div key={index}>
          <Input
            value={value}
            maxLength={maxLength}
            onChange={({ target }) => {
              listToShow[index] = target.value;
              updateValueQuestion(listToShow);
            }}
            placeholder={placeholder}
          />
          <div className={`container-delete-and-number-of-characters ${ showDelete && listToShow?.length > 1 ? 'justify-content-between' : 'justify-content-end'}  `}>
            { showDelete && listToShow?.length > 1
              && <div className='container-delete' onClick={() => {
                listToShow.splice(index, 1);
                updateValueQuestion(listToShow);
              }}>
                <p>Eliminar</p>
              </div>
            }
            <WrittenCharacters
              value={value}
              maxLength= {maxLength}
            />
          </div>
        </div>
      ))
    }
    { showAdd && listToShow.length < maxOptions
      && <div
        onClick={() => {
          listToShow[listToShow.length] = DEFAULT_FORM_VALUE;
          updateValueQuestion(listToShow);
        }}>
        <div className='container-add-other'>
          <div className='container-plus'>
            +
          </div>
          <p>Agregar otro</p>
        </div>

      </div>
    }
  </Question>
);

SeveralInputOptions.propTypes = {
  showAdd: PropTypes.bool,
  showDelete: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  listToShow: PropTypes.array,
  updateValueQuestion: PropTypes.func,
  placeholder: PropTypes.string,
  maxOptions: PropTypes.number,
  maxLength: PropTypes.number
};

SeveralInputOptions.defaultProps = {
  showAdd: true,
  showDelete: true,
  title: '',
  description: '',
  listToShow: [],
  updateValueQuestion: () => {},
  placeholder: '',
  maxOptions: 0,
  maxLength: 0
};
export default SeveralInputOptions;

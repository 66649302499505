import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import { getContentAPI } from '../api/evaluationsReport';
import { actionTypes, getEvaluationsReportSuccess, getEvaluationsReportError } from '../actions/evaluationsReport';
import { failure as commonsFailure } from '../actions/commons';

es6promise.polyfill();

export function* getContent() {
  try {
    const response = yield call(getContentAPI);
    if (response.status === 200) {
      yield put(getEvaluationsReportSuccess(response.status));
    } else {
      const error = get(response, 'data');
      const statusCode = get(response, 'status');
      yield put(statusCode === 401 ? commonsFailure(
        { error, statusCode }
      ) : getEvaluationsReportError({ error, statusCode }));
    }
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(statusCode === 401 ? commonsFailure(
      { error, statusCode }
    ) : getEvaluationsReportError({ error, statusCode }));
  }
}

function* evaluationsReportSagas() {
  yield all([
    takeLatest(actionTypes.GET_EVALUATIONS_REPORT, getContent)
  ]);
}

export default evaluationsReportSagas;

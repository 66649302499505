import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import '../../../static/styles/shared/MLTabs.less';

const { TabPane } = Tabs;

export const setUrl = (value) => {
  if (typeof value === 'string') {
    return value.replace(/\s/g, '-').toLowerCase();
  }
  return '';
};

const MLTabsByRouteSimple = (props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    children,
    options,
    hideTabs,
    firstTab,
    className
  } = props;

  const onTabClick = (i) => {
    history.replace(i);
  };

  const activeKey = options.find(({ key }) => !pathname.indexOf(key))?.key;
  const tabClassName = `${activeKey?.replace('/', '')?.replace(/\//g, '-')}-tab`;

  if (firstTab) history.replace(options?.find(({ visible }) => visible)?.key);

  return (
    <Tabs
      type='line'
      animated={false}
      activeKey={activeKey}
      onTabClick={onTabClick}
      className={`${className} ${tabClassName} tabs${hideTabs ? ' hide-tabs' : ''}`}
    >
      { options.map((option, index) => option.visible && (
        <TabPane
          tab={option.title}
          key={option.key || index}
          className={`content-tab ${option.className}`}
        >
          {children}
        </TabPane>
      ))}
    </Tabs>
  );
};
MLTabsByRouteSimple.propTypes = {
  children: PropTypes.element,
  options: PropTypes.array,
  firstTab: PropTypes.bool,
  defaultActiveKey: PropTypes.string,
  className: PropTypes.string
};

MLTabsByRouteSimple.defaultProps = {
  children: <></>,
  options: [],
  firstTab: false,
  defaultActiveKey: '0',
  className: ''
};

export default MLTabsByRouteSimple;

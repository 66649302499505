import { actionTypes } from '../actions/admin';
import { REPORT_NAMES } from '../../hooks/reports/useReport';

export const initialState = {
  objectives: [],
  loadingObjectives: false,
  errorObjectives: null,

  evolucionaTEST: [],
  loadingEvolucionaTEST: false,
  errorEvolucionaTEST: null,

  loadingSocialAssistant: false,
  socialAssistant: [],
  errorSocialAssistant: null,

  section: [],
  loadingSection: false,
  errorSection: null,

  traceability: [],
  loadingTraceability: false,
  errorTraceability: null,

  EDD: [],
  loadingEDD: false,
  errorEDD: null,

  merit: [],
  loadingMerit: false,
  errorMerit: null,

  meritsTraceability: [],
  loadingMeritsTraceability: false,
  errorMeritsTraceability: null,

  reconocimiento: [],
  loadingReconocimiento: false,
  errorReconocimiento: null,

  reconocimientoComentarios: [],
  loadingReconocimientoComentarios: false,
  errorReconocimientoComentarios: null,

  reports: {}
};

const emptyReport = (state, reportName) => ({
  ...state,
  reports: {
    ...state.reports,
    [reportName]: null
  }
});

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_INTERESTANDSKILLS_REPORTS:
    return emptyReport(state, REPORT_NAMES.INTERESTS_AND_SKILLS);
  case actionTypes.GET_COMPETENCIES_REPORTS:
    return emptyReport(state, REPORT_NAMES.COMPETENCIES);
  case actionTypes.GET_FEEDBACKNETWORK_REPORTS:
    return emptyReport(state, REPORT_NAMES.FEEDBACK_NETWORKS);
  case actionTypes.GET_PERFORMANCE_WALMART_REPORTS:
    return emptyReport(state, REPORT_NAMES.PERFORMANCE_WALMART);
  case actionTypes.GET_SURVEY_PERFORMANCE_WALMART_REPORTS:
    return emptyReport(state, REPORT_NAMES.SURVEY_PERFORMANCE);
  case actionTypes.GET_PERFORMANCE_WALMART_PROGRESS_REPORTS:
    return emptyReport(state, REPORT_NAMES.PERFORMANCE_PROGRESS_WALMART);
  case actionTypes.GET_PERFORMANCE_WALMART_TEMPLATE_REPORTS:
    return emptyReport(state, REPORT_NAMES.PERFORMANCE_WALMART_TEMPLATE);
  case actionTypes.GET_PERFORMANCE_REPORTS:
    return emptyReport(state, REPORT_NAMES.PERFORMANCE);
  case actionTypes.GET_FEEDBACK:
    return emptyReport(state, REPORT_NAMES.FEEDBACK);
  case actionTypes.GET_EVOLUCIONATEST:
    return {
      ...state,
      loadingEvolucionaTEST: true,
      errorEvolucionaTEST: null
    };
  case actionTypes.GET_EVOLUCIONATEST_REPORTS_SUCCESS:
    return {
      ...state,
      evolucionaTEST: action.payload,
      errorEvolucionaTEST: null,
      loadingEvolucionaTEST: false
    };
  case actionTypes.GET_EVOLUCIONATEST_REPORTS_ERROR:
    return {
      ...state,
      errorEvolucionaTEST: action.error,
      loadingEvolucionaTEST: false
    };

  case actionTypes.GET_OBJECTIVES:
    return {
      ...state,
      loadingObjectives: true,
      errorObjectives: null
    };

  case actionTypes.GET_OBJECTIVES_REPORTS_SUCCESS:
    return {
      ...state,
      objectives: action.payload,
      errorObjectives: null,
      loadingObjectives: false
    };
  case actionTypes.GET_OBJECTIVES_REPORTS_ERROR:
    return {
      ...state,
      errorObjectives: action.error,
      loadingObjectives: false
    };
  case actionTypes.GET_SOCIAL_ASSISTANT_REPORT:
    return {
      ...state,
      loadingSocialAssistant: true,
      errorSocialAssistant: null
    };
  case actionTypes.GET_SOCIAL_ASSISTANT_REPORT_SUCCESS:
    return {
      ...state,
      socialAssistant: action.payload,
      errorSocialAssistant: null,
      loadingSocialAssistant: false
    };
  case actionTypes.GET_SOCIAL_ASSISTANT_REPORT_ERROR:
    return {
      ...state,
      errorSocialAssistant: action.error,
      loadingSocialAssistant: false
    };
  case actionTypes.GET_SECTION_REPORT:
    return {
      ...state,
      loadingSection: true,
      errorSection: null
    };
  case actionTypes.GET_SECTION_REPORT_SUCCESS:
    return {
      ...state,
      loadingSection: false,
      section: action.data,
      errorSection: null
    };
  case actionTypes.GET_SECTION_REPORT_FAILURE:
    return {
      ...state,
      loadingSection: false,
      errorSection: action.error
    };
  case actionTypes.GET_TRACEABILITY_REPORT:
    return {
      ...state,
      loadingTraceability: true,
      errorTraceability: null
    };
  case actionTypes.GET_TRACEABILITY_REPORT_SUCCESS:
    return {
      ...state,
      loadingTraceability: false,
      traceability: [{ ...action.data }],
      errorTraceability: null
    };
  case actionTypes.GET_TRACEABILITY_REPORT_ERROR:
    return {
      ...state,
      loadingTraceability: false,
      errorTraceability: action.error
    };
  case actionTypes.GET_EDD_REPORTS:
    return {
      ...state,
      loadingEDD: true,
      errorEDD: null
    };
  case actionTypes.GET_EDD_REPORTS_SUCCESS:
    return {
      ...state,
      EDD: action.payload,
      errorEDD: null,
      loadingEDD: false
    };
  case actionTypes.GET_EDD_REPORTS_ERROR:
    return {
      ...state,
      errorEDD: action.error,
      loadingEDD: false
    };

  case actionTypes.GET_MERIT_LETTERS_REPORT:
    return {
      ...state,
      loadingMerit: true,
      errorMerit: null
    };
  case actionTypes.GET_MERIT_LETTERS_REPORT_SUCCESS:
    return {
      ...state,
      merit: action.payload,
      loadingMerit: false,
      errorMerit: null
    };
  case actionTypes.GET_MERIT_LETTERS_REPORT_ERROR:
    return {
      ...state,
      loadingMerit: false,
      errorMerit: true
    };
  case actionTypes.GET_RECONOCIMIENTO_REPORT:
    return {
      ...state,
      loadingReconocimiento: true,
      errorReconocimiento: null
    };
  case actionTypes.GET_RECONOCIMIENTO_REPORT_SUCCESS:
    return {
      ...state,
      reconocimiento: action.payload,
      errorReconocimiento: null,
      loadingReconocimiento: false
    };
  case actionTypes.GET_RECONOCIMIENTO_REPORT_ERROR:
    return {
      ...state,
      errorReconocimiento: action.error,
      loadingReconocimiento: false
    };
  case actionTypes.GET_RECONOCIMIENTO_COMMENTS_REPORT:
    return {
      ...state,
      loadingReconocimientoComentarios: true,
      errorReconocimientoComentarios: null
    };
  case actionTypes.GET_RECONOCIMIENTO_COMMENTS_REPORT_SUCCESS:
    return {
      ...state,
      reconocimientoComentarios: action.payload,
      errorReconocimientoComentarios: null,
      loadingReconocimientoComentarios: false
    };
  case actionTypes.GET_RECONOCIMIENTO_COMMENTS_REPORT_ERROR:
    return {
      ...state,
      errorReconocimientoComentarios: action.error,
      loadingReconocimientoComentarios: false
    };

  case actionTypes.GET_STATUS_FEEDBACK_NETWORK:
    return emptyReport(state, REPORT_NAMES.STATUS_FEEDBACK_NETWORK);
  case actionTypes.GET_EDD_AUTOEVALUATIONS_REPORTS:
    return emptyReport(state, REPORT_NAMES.EDD_AUTOEVALUATION);
  case actionTypes.GET_EDD_EVALUATIONS_REPORTS:
    return emptyReport(state, REPORT_NAMES.EDD_EVALUATION);
  case actionTypes.GET_EDP_REPORTS:
    return emptyReport(state, REPORT_NAMES.EDP_EVALUATIONS);
  case actionTypes.GET_EDD_OPINION_EVALUATION_REPORT:
    return emptyReport(state, REPORT_NAMES.EDD_OPINIONS_FEEDBACK);
  case actionTypes.GET_IDP_REPORT:
    return emptyReport(state, REPORT_NAMES.IDP);
  case actionTypes.GET_MERITS_TRACEABILITY:
    return {
      ...initialState,
      loadingMeritsTraceability: true,
      errorMeritsTraceability: null
    };
  case actionTypes.GET_MERITS_TRACEABILITY_SUCCESS:
    return {
      ...state,
      loadingMeritsTraceability: false,
      meritsTraceability: action.data,
      errorMeritsTraceability: null
    };
  case actionTypes.GET_MERITS_TRACEABILITY_ERROR:
    return {
      ...state,
      loadingMeritsTraceability: false,
      errorMeritsTraceability: action.error
    };
  case actionTypes.UPDATE_REPORT_STATUS:
    // eslint-disable-next-line no-case-declarations
    const { name, status, info } = action.reportUpdate;

    return {
      ...state,
      reports: {
        ...state.reports,
        [name]: {
          status,
          info
        }
      }
    };

  default:
    return state;
  }
}

export default reducer;

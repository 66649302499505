import axios from 'axios';
import authInterceptor from './authInterceptor';
import maintenanceInterceptor from './maintenanceInterceptor';
import { getItem } from '../cookiesUtil';

let instance;

export default () => {
  const token = getItem('token') || '';
  if (!instance) {
    instance = axios.create({ withCredentials: true });
    instance.defaults.timeout = 700000;
    instance.interceptors.response.use(undefined, authInterceptor(instance));
    instance.interceptors.response.use(undefined, maintenanceInterceptor);
  }
  if (token !== '') {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
  return instance;
};

import { lazy } from 'react';

const Home = lazy(() => import('../../../../containers/myTeam/myEvolution/Feedback'));

export default [
  {
    path: '/mi-equipo/mi-evolucion/feedback',
    section: 'Mi Equipo - Mi Evolución - Feedback',
    component: Home,
    auth: 'MY_TEAM_MY_EVOLUTION_FEEDBACK',
    subRoutes: [
      {
        exact: true,
        path: '/mi-equipo/mi-evolucion/feedback',
        section: 'Mi Equipo - Mi Evolución - Feedback - Colaborador'
      }
    ]

  }
];

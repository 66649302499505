/* eslint-disable react/prop-types */

import React from 'react';
import { Select } from 'antd';
import '../../../static/styles/shared/forms/styles.less';

const { Option } = Select;

const SelectComponent = (props) => {
  const {
    options,
    optionFilter
  } = props;

  return (
    <Select
      {...props}
      {
        ...(optionFilter === 'children' ? {
          filterOption: (input, option) => (
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
        } : null)
      }
    >
      {options.map((option, i) => (
        <Option key={i} value={option.value} className={option.className}>{option.label}</Option>
      ))}
    </Select>
  );
};
export default SelectComponent;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useHistory
} from 'react-router-dom';
import '../../../../static/styles/shared/MLHistorical/historical.less';
import { find } from 'lodash';
import { useLazyQuery, useQuery } from '@apollo/client';
import HistoricalList from '../../../shared/MLHistorical/list/index';
import getURLParams from '../../../../helpers/routing/getURLParams';
import {
  GET_GENERAL_MODEL_SKILLS_4C, GET_LIST_FILTER_GENERAL_MODEL4C
} from '../../../../graphql/mievolucion/skills/queries';
import MLError from '../../../shared/Error';
import MLFilterSelect from '../../../shared/MLFilterSelect';
import { optionsWithNetworkOnly } from '../../../../helpers/myEvolution';
import { parsedDataGeneralModel } from '../../../../helpers/myEvolution/skills4C';
import LoaderComponent from '../../../Loader';

const LeftComponents = (props) => {
  const {
    ucmSelected,
    profileSelected,
    setProfileSelected
  } = props;
  const query = getURLParams();

  const ucmFilter = query.get('filter');

  const [filter, setFilter] = useState(ucmFilter || 'ALL');

  const history = useHistory();

  const variablesListGeneralModel = {
    filter
  };

  const [
    getListGeneralModel,
    {
      loading: loadingGeneralModel,
      data,
      error: errorGeneralModel,
      refetch: refetchGeneralModel
    }
  ] = useLazyQuery(GET_GENERAL_MODEL_SKILLS_4C, {
    ...optionsWithNetworkOnly,
    variables: variablesListGeneralModel
  });

  const {
    loading: loadingFilters,
    data: dataFilters,
    error: errorFilters,
    refetch: refetchFilters
  } = useQuery(GET_LIST_FILTER_GENERAL_MODEL4C,
    optionsWithNetworkOnly);

  const { generalModel4C = [] } = data || {};
  const { listFilterGeneralModel4C: listfilter = [] } = dataFilters || {};

  const parsedData = parsedDataGeneralModel(generalModel4C);

  const getURL = (item) => `modelo-general?ucm=${item.ucm}&filter=${filter}`;

  const onSelectItem = () => {
    if (!ucmSelected) {
      setProfileSelected(null);
    } else {
      const newItem = find(parsedData, (user) => user.ucm.toString() === ucmSelected);

      if (newItem) {
        setProfileSelected(newItem);
      }
    }
  };
  useEffect(() => {
    if (dataFilters) {
      getListGeneralModel();
    }
  }, [dataFilters]);

  useEffect(() => {
    if (
      !loadingGeneralModel
     && ucmSelected) {
      onSelectItem(ucmSelected);
    } else {
      onSelectItem(null);
    }
  }, [ucmSelected, loadingGeneralModel, data]);

  if (errorFilters || errorGeneralModel) {
    return (
      <MLError reload={() => {
        if (errorFilters) {
          refetchFilters();
        } else {
          refetchGeneralModel(
            { variables: variablesListGeneralModel }
          );
        }
      }}/>
    );
  }
  if (loadingGeneralModel || loadingFilters) {
    return <LoaderComponent />;
  }
  return (

    <div>
      <MLFilterSelect
        {...{
          value: filter,
          defaultValue: 'ALL',
          options: listfilter,
          onSelect: (value) => {
            setFilter(value);
            getListGeneralModel({ variables: { filter: value } });
            history.replace(`modelo-general?filter=${value}`);
          }
        }}
      />
      <HistoricalList
        loading={loadingGeneralModel}
        data={parsedData}
        getActiveItem={(listItem) => profileSelected && listItem.ucm === profileSelected.ucm}
        getItemURL={(listItem) => getURL(listItem)
        }
      />

    </div>
  );
};

LeftComponents.propTypes = {
  ucmSelected: PropTypes.string,
  profileSelected: PropTypes.object,
  setProfileSelected: PropTypes.func
};

LeftComponents.defaultProps = {
  ucmSelected: '',
  profileSelected: {},
  setProfileSelected: () => {}
};

export default LeftComponents;

import { actionTypes } from '../actions/search';

export const initialState = {
  search: [],
  searchSelect: [],
  filterSearch: [],
  result: [],
  error: null,
  loading: false
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.ALL_SEARCH:
  case actionTypes.ALL_SEARCH_SELECT:
    return {
      ...state,
      loading: true
    };
  case actionTypes.ALL_SEARCH_SUCCESS:
    return {
      ...state,
      loading: false,
      error: null,
      search: action.payload
    };
  case actionTypes.ALL_SEARCH_SUCCESS_SELECT:
    return {
      ...state,
      loading: false,
      error: null,
      searchSelect: action.payload
    };
  case actionTypes.ALL_SEARCH_ERROR:
  case actionTypes.ALL_SEARCH_ERROR_SELECT:
    return {
      ...state,
      loading: false,
      error: action.payload
    };
  case actionTypes.CLEAN_SEARCH:
    return {
      search: []
    };
  default:
    return state;
  }
}
export default reducer;

/* eslint-disable import/prefer-default-export */

import gql from 'graphql-tag';

export const SAVE_SKILL_LEVEL_BEHAVIOR_CHOICE = gql`
    mutation saveSkillLevelBehaviorChoice(
        $choices: [SkillLevelBehaviorChoiceRequest]!
    ) {
        saveSkillLevelBehaviorChoice(
            choices: $choices 
        ) {
            choice {
                _id
                behaviorId
                choice
                dni
                createdAt
                updatedAt

            }
            behavior {
                _id
                id
                skillId
                levelId
                name
                actions {
                    id
                    name
                }
            }
        }
    }
`;

export const SAVE_SKILL_LEVEL_BEHAVIOR_ACTION_CHOICE = gql`
    mutation saveSkillLevelBehaviorActionChoice(
        $choices: [SkillLevelBehaviorActionChoiceRequest]!
    ) {
        saveSkillLevelBehaviorActionChoice(
            choices: $choices 
        ) {
            _id
        }
    }
`;

export const FINISH_SKILL_LEVEL_BEHAVIOR_ACTION = gql`
    mutation finishSkillLevelBehaviorAction(
        $id: String
    ) {
        finishSkillLevelBehaviorAction(
            id: $id 
        ) {
            _id
            dni
            skill {
                _id
                name
            }
            createdAt
            updatedAt
            endAt
            status
            actions {
                _id
                actionId
                actionName
                behaviorId
                behaviorName
                status
                choice
                createdAt
                closeAt
            }
        }
    }
`;

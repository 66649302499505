import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _, { last } from 'lodash';
import BannerLayout from './banner';

import { completePath } from '../helpers/strings';

const sectionData = {
  miEvolucion: {
    ext: 'jpg'
  }
};

const MyEvolutionLayout = (props) => {
  const { children } = props;
  const { pathname } = useLocation();
  const { data: user } = useSelector((state) => state.userReducer);

  const getBannerConfig = () => {
    const sectionName = last(encodeURI(pathname).split('/'));
    const imgNames = sectionName;
    const camelSectionName = _.camelCase(sectionName);
    const { [camelSectionName]: { ext, subtitle } } = sectionData || {};

    return {
      imageBanner: `${completePath(`web/mi-evolucion/banner/${imgNames}-banner.jpg`)}`,
      imageAvatar: `${completePath(`web/mi-evolucion/servicios/inicio/${imgNames}-card.${ext}`)}`,
      title: sectionName,
      subtitle,
      titleAndSubTitleFormat: 'prettier',
      loading: user === null
    };
  };

  const banner = getBannerConfig();

  return (
    <div id="my-evolution-layout">
      <BannerLayout {...banner}>
        {user && children}
      </BannerLayout>
    </div>
  );
};

MyEvolutionLayout.propTypes = {
  children: PropTypes.any
};

MyEvolutionLayout.defaultProps = {
  children: PropTypes.object
};

export default MyEvolutionLayout;

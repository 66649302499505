import React, { Fragment, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import BannerLayout from '../../../layouts/banner';
import MLTabs from '../../../components/shared/MLTabs/MLTabsByRoute';
import { completePath } from '../../../helpers/strings';
import { useWindowWidthSize } from '../../../helpers/browser';
import { smallDevice } from '../../../helpers/deviceVariables';
import UserProfile from '../../../components/mievolucion/Skills4C/Profile';
import { GET_SHOW_INTRODUCTION } from '../../../graphql/userSettings/queries';
import Introduction from '../../../components/mievolucion/Skills4C/Introduction';
import Loading from '../../loading';
import { MY_EVOLUTION_CONTEXT } from '../../../helpers/apollo';
import { skills4CIntroductionKey } from '../../../helpers/introductions';
import Error from '../../../components/shared/Error';

const Skills = (props) => {
  const { pathname } = useLocation();
  const [showIntroduction, setShowIntroduction] = useState(true);

  const width = useWindowWidthSize();
  const banner = {
    imageBanner: `${completePath('web/mi-evolucion/banner/nuevas-competencias-banner.jpg')}`,
    imageAvatar: `${completePath('web/mi-evolucion/avatars/nuevas-competencias-avatar.jpg')}`,
    title: 'Mis Competencias 4C'
  };

  const variablesIntroduction = { introductionName: skills4CIntroductionKey };

  const {
    data: introductionData,
    loading: introductionLoading,
    error: introductionError,
    refetch: introductionRefetch
  } = useQuery(
    GET_SHOW_INTRODUCTION,
    {
      variables: variablesIntroduction,
      context: MY_EVOLUTION_CONTEXT,
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (introductionData) {
      const { getIntroduction: showIntroductionToUser } = introductionData;

      if (showIntroductionToUser) {
        setShowIntroduction(false);
      }
    }
  }, [introductionLoading, introductionData]);

  const isMobile = width <= smallDevice;

  const {
    children
  } = props;

  const primaryTabs = [
    {
      title: 'Mis Conductas',
      key: '/mi-evolucion/mis-competencias-4C'
    },
    {
      title: 'Modelo General',
      key: '/mi-evolucion/mis-competencias-4C/modelo-general'
    },
    {
      title: 'Preguntas frecuentes',
      key: '/mi-evolucion/mis-competencias-4C/preguntas-frecuentes'
    }
  ];

  if (introductionError) {
    return (
      <Error reload={() => introductionRefetch()} />
    );
  }

  const child = (
    <Fragment>
      {showIntroduction
        ? <Introduction
          refetchQueries={[
            {
              context: MY_EVOLUTION_CONTEXT,
              query: GET_SHOW_INTRODUCTION,
              variables: variablesIntroduction
            }

          ]}
          endIntroduction={() => setShowIntroduction(false)} />
        : <Fragment>
          {!isMobile
            && <UserProfile className='mt-4 mb-2 d-block' title='Mi Rol' />
          }
          <MLTabs
            options={primaryTabs}
            defaultActiveKey={pathname}
            activeTab={pathname}
            className='no-padding-margin'
            tabsOnly
          />
          {
            <>
              {children}
            </>
          }
        </Fragment>
      }
    </Fragment>
  );
  if (introductionLoading) {
    return <Loading />;
  }

  return (
    <div>
      {!isMobile ? (
        <BannerLayout {...{
          ...banner
        }}>
          {child}
        </BannerLayout>
      ) : child}
    </div>
  );
};

export default Skills;

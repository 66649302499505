/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_ME = gql`
    query {
        me {
            rut
            typeOfDay
            profileUCM {
                name
                UCM
            }
            nextProfileUCM {
                name
                UCM
            }
            linealBoss{
                rut
                name
                secondName
                fatherLastName
                motherLastName
            }
        }
    }
`;
export const SEARCH_USERS = gql`
    query searchUsers(
        $name: String!
        $fatherLastName: String
        $offset: Int
        $limit: Int
        $onCollection: String
    ) {
        searchUsers(
            name: $name
            fatherLastName: $fatherLastName
            offset: $offset
            limit: $limit
            onCollection: $onCollection
        ) {
            _id
            rut
            name
            secondName
            fatherLastName
            motherLastName
            HOOPP
            position
            area1
            area2
            area3
            division
            additionalField4
            sexo
            emailOffice
            emailPersonal
        }
    }
`;

export const actionTypes = {
  SELECTING_DATE: 'SELECTING_DATE',
  DATE_SELECTED: 'DATE_SELECTED'
};

export function selectingDate(data) {
  return {
    type: actionTypes.SELECTING_DATE,
    data
  };
}

export function dateSelected() {
  return {
    type: actionTypes.DATE_SELECTED
  };
}

import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useLocation, useRouteMatch } from 'react-router-dom';
import {
  queryValidation, getParsedData, setItemFromData, setItemFromQueryParams, onSelectItem
} from '../../../../../helpers/myEvolution/feedback';
import Historical from '../../../../shared/MLHistorical';
import NotFoundFeedbacks from '../../../../shared/MLNotFound';
import HistoricalList from '../../../../shared/MLHistorical/list';
import MLError from '../../../../shared/Error';
import '../../../../../static/styles/shared/MLHistorical/historical.less';
import 'moment/locale/es';

moment.locale('es');

const FeedbackHistorical = (props) => {
  const {
    children,
    listTab
  } = props;

  /*
    mi-evolucion/mi-desempeño/feedback/hacia-mí
    tiene el hijo:
    mi-evolucion/mi-desempeño/feedback/hacia-mí/detalle-de-feedback
    que hace referencia a
    components/mievolucion/MyPerformance/Feedback/Historical/FeedbackDetails

    Lo mismo pasa con mi-evolucion/mi-desempeño/feedback/hacia-otros
  */
  const { path } = useRouteMatch();

  const { search } = useLocation();

  const [item, setItem] = useState({});

  const { loading, data, error } = useQuery(queryValidation(listTab), {
    context: {
      clientName: 'myEvolution'
    },
    fetchPolicy: 'network-only'
  });

  const grouped = listTab === 3;
  const parsedData = getParsedData(data, listTab, grouped);

  const noFoundProps = (() => {
    const img = 'web/mi-evolucion/servicios/inicio/sin-feedback.jpg';
    switch (listTab) {
    case 1:
      return {
        img,
        title: 'No te han dado feedback todavía',
        description: 'Aún no has interactuado con nadie.<br/>Anímate a Pedirle Feedback a un compañero.'
      };
    case 2:
      return {
        img,
        title: 'No has dado feedback todavía',
        description: 'Aún no has interactuado con nadie.<br/>Anímate a Darle Feedback a un compañero.'
      };
    default:
      return {
        img,
        title: 'No te han compartido feedback todavía',
        description: 'Nadie de tu equipo te ha compartido sus feedback.'
      };
    }
  })();

  const leftScrollComponents = [
    !loading && isEmpty(parsedData) ? (
      <NotFoundFeedbacks key={1} {...noFoundProps} />
    ) : (
      <HistoricalList
        key={2}
        loading={loading}
        data={parsedData}
        grouped={grouped}
        getActiveItem={(listItem) => item
            && listItem._id === item._id && listItem.rut === item.rut}
        getItemURL={(listItem) => `${path}/detalle-de-feedback?id=${listItem._id}&rut=${listItem.rut}`}
        {...{
          ...(grouped ? {
            getItem: (itemData) => {
              const res = { ...itemData };
              delete res.status;
              delete res.date;
              return res;
            }
          } : null)
        }}
      />
    )
  ];

  useEffect(() => setItemFromData(parsedData, item, setItem), [data]);

  useEffect(() => {
    if (!loading) {
      setItemFromQueryParams(search, setItem, parsedData);
    } else {
      onSelectItem(setItem);
    }
  }, [search, loading, data]);
  if (error) {
    return (<MLError title={error?.message} />);
  }
  return (
    <Fragment>
      <Historical leftScrollComponents={leftScrollComponents}>
        {isEmpty(item) ? <Fragment />
          : children
        }
      </Historical>
    </Fragment>
  );
};

FeedbackHistorical.propTypes = {
  listTab: PropTypes.number,
  children: PropTypes.element
};

FeedbackHistorical.defaultProps = {
  listTab: 1,
  children: null
};
export default FeedbackHistorical;

import getSearchUserFormItemClassName from './getFormItemClassName';

const createSearchUserInputData = ({
  input: {
    disabled, name, placeholder, onBlur, onChange, onFocus, value
  }
}) => ({
  formItemProps: {
    name,
    className: getSearchUserFormItemClassName(true)
  },
  inputProps: {
    disabled,
    value,
    placeholder,
    onChange,
    onFocus,
    onBlur
  }
});

export default createSearchUserInputData;

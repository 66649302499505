import React, { useRef } from 'react';
import { Select, Button } from 'antd';
import '../../../../../static/styles/admin/compensaciones/merito/status-selector.less';

const { Option } = Select;

const MLStatusSelector = ({
  loading, isActive, handleButton, data, widthOptional
}) => {
  const selectRef = useRef(null);
  const { defaultValue, options } = data;

  const getSelectedObject = (id) => options.find(({ value }) => value === id) || {};
  const withParam = widthOptional ? { width: '320px' } : {};
  return (
    <div className="ml-status-selector">
      <Select
        style={withParam}
        ref={selectRef}
        defaultValue={defaultValue}
        disabled={!isActive}
        suffixIcon={<i className="icon-ml-select-dd primary-icon-color"/>}>
        {options.map(({ name, value }, i) => (
          <Option key={i} value={value}>
            {name}
          </Option>
        ))}
      </Select>

      <Button
        type="primary"
        onClick={() => {
          const selectedObject = getSelectedObject(selectRef.current.rcSelect.state.value[0]);
          handleButton(selectedObject);
        }}
        loading={loading}
      >
        {isActive ? 'Guardar Estado' : 'Cambiar Estado'}
      </Button>
    </div>
  );
};

export default MLStatusSelector;

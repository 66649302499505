export function getErrorDetails(error) {
  if (!error?.response) {
    const { message } = error;
    return { data: message, status: 500 };
  }
  const {
    response: {
      data,
      status
    }
  } = error;
  return { data, status };
}

export function getSuccessDetails(resp) {
  const {
    data,
    status
  } = resp;
  return { data, status };
}

import React from 'react';
import Message from '../../../../../../shared/InfoMessage';
import CollapsablePanel from '../../../../../../shared/CollapsablePanel';
import {
  initiativesDictionary,
  quarterDictionaryAux
} from '../../../../../../../helpers/myEvolution/objetives/commons';
import { smallDevice } from '../../../../../../../helpers/deviceVariables';
import { useWindowWidthSize } from '../../../../../../../helpers/browser';

const ObjetivesForm = ({
  _id,
  userName,
  status,
  categories,
  objective,
  macro,
  strategicPriority,
  successMeasures,
  categorySelected,
  onObjectiveChange,
  isAutoevaluation,
  canEdit,
  canEditObjetives
}) => {
  const width = useWindowWidthSize();
  const isMobile = width <= smallDevice;

  const SuccessMeasuresRender = () => (
    <div>
      {successMeasures
        && successMeasures.map((measure, i) => {
          const date = new Date(parseInt(measure.dateMeasure));
          return (
            <div className='d-flex mb-4 success-measures-container' key={i}>
              <div className='ml-flex-3'>
                <p className='mb-3'>
                  <b>{i + 1}.</b> {measure.description}
                </p>
                {isMobile && (
                  <>
                    <p className='mb-4 due-date'>
                      <b>Fecha límite:</b>
                    </p>
                    <p className='mb-4'>{date.toLocaleDateString()}</p>
                  </>
                )}
                {measure.updates && (
                  <>
                    <p className='mb-2 color-primary'>
                      <b>Avance</b>
                    </p>
                    <div className='d-flex success-measures'>
                      {Object.keys(measure.updates)
                        .filter((key) => ['q2'].includes(key))
                        .map((key) => (
                          <div
                            key={key}
                            className='px-4 py-2 border text-center'
                          >
                            <p>
                              <b>{quarterDictionaryAux[key]}</b>
                            </p>
                            <p>
                              {
                                initiativesDictionary[
                                  measure.updates[key].status
                                ]
                              }
                            </p>
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>
              {!isMobile && (
                <div className='ml-flex-1'>
                  <p className='mb-4 due-date'>
                    <b>Fecha límite:</b>
                  </p>
                  <p className='mb-4'>{date.toLocaleDateString()}</p>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );

  return (
    <div className='px-5 py-4 objectives-expand'>
      <div className='d-flex mb-4 pb-4 border-bottom objectives-form-descs'>
        <div className='pr-5'>
          <p className='mb-2'>
            <b>Descripción del objetivo:</b>
          </p>
          <p>
            {objective.description
              || 'No se ha ingresado una descripción del objetivo.'}
          </p>
        </div>
        {macro?.name && macro?.name !== 'undefined' && (
          <div className='pr-5'>
            <p className='mb-2'>
              <b>Objetivo macro:</b>
            </p>
            <p>{macro?.name}</p>
          </div>
        )}
        {strategicPriority?.name && strategicPriority?.name !== 'undefined' && (
          <div className='pr-5'>
            <p className='mb-2'>
              <b>Prioridad estratégica:</b>
            </p>
            <p>{strategicPriority?.name}</p>
          </div>
        )}
      </div>
      {!isMobile && (
        <div className='d-flex'>
          <div className='ml-flex-3'>
            <p className='mb-4'>
              <b>Medidas de éxito:</b>
            </p>
          </div>
          <div className='ml-flex-1'>
            <p className='mb-4'>
              <b>Fecha límite:</b>
            </p>
          </div>
        </div>
      )}
      {isMobile ? (
        <div className='mobile-container'>
          <div className='ml-flex-3'>
            <CollapsablePanel
              text='avances'
              className='mb-4'
              openCollapsed={true}
            >
              <>
                <p className='mb-4'>
                  <b>Medidas de éxito:</b>
                </p>
                <SuccessMeasuresRender />
              </>
            </CollapsablePanel>
          </div>
        </div>
      ) : (
        <SuccessMeasuresRender />
      )}
      <p className='mb-4 ml-4 with-dot red'>
        {!isAutoevaluation ? (
          <b>Evalúa el desempeño de {userName} de este objetivo:</b>
        ) : (
          <b>Evalúa tu cumplimiento de este objetivo:</b>
        )}
      </p>
      {!isAutoevaluation && (
        <Message classes='mb-3' suave>
          {status ? (
            <p>
              El resultado de su autoevaluación es <b>{status}</b>
            </p>
          ) : (
            <p>{userName} aún no realizado su autoevaluación.</p>
          )}
        </Message>
      )}
      { canEditObjetives
      && <div className='d-flex rounded mb-4 ml-border-primary options-container'>
        {categories.map((category) => (
          <div
            key={category._id}
            className='bd-between ml-flex-1 evaluation-option d-flex flex-column'
          >
            <div
              className='px-5 py-1 text-center rounded-top option-header'
              onClick={() => {
                if (canEdit) {
                  onObjectiveChange(_id, category._id);
                }
              }}
            >
              <input
                type='radio'
                value={category._id}
                checked={categorySelected?._id === category._id}
                onChange={(e) => onObjectiveChange(_id, e.target.value)}
                disabled={!canEdit}
              />
              <p className='color-primary'>
                <b>{category.name}</b>
              </p>
            </div>
            <div className='p-3 text-center rounded-bottom option-body h-100'>
              <p>{category.objetivesDescription}</p>
            </div>
          </div>
        ))}
      </div>
      }
    </div>
  );
};

export default ObjetivesForm;

import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import '../../../static/styles/shared/filters/filter-list-by.less';

const FilterListBy = ({
  children,
  colorButton,
  buttonState,
  handleInputChange,
  enviarDatos,
  getEddFn
}) => {
  const ref = useRef(null);

  const onSubmit = (e) => {
    e.preventDefault();
    enviarDatos(ref.current.value);
    if (getEddFn) getEddFn(ref.current.value);
  };

  return (
    <div className='busqueda-colaborador-content'>
      <Fragment>
        <form onSubmit={onSubmit}>
          <div>
            <label className='titulo-accion'>Buscar colaborador</label>
          </div>
          <div className='contentInputButton'>
            <input
              className='input-rut'
              type='text'
              placeholder='Ingresar RUT del colaborador. Ej: 112223334'
              ref={ref}
              onChange={handleInputChange}
              name='rut'
            ></input>
            <button
              className={colorButton ? 'buscar-rut-on' : 'buscar-rut-off'}
              disabled={buttonState}
              type='submit'
            >
              Buscar RUT
            </button>
          </div>
        </form>
      </Fragment>

      {children}
    </div>
  );
};
FilterListBy.propTypes = {
  colorButton: PropTypes.bool,
  buttonState: PropTypes.bool,
  handleInputChange: PropTypes.func,
  enviarDatos: PropTypes.func
};

FilterListBy.defaultProps = {
  colorButton: false,
  buttonState: false,
  handleInputChange: () => {},
  enviarDatos: () => {}
};
export default FilterListBy;

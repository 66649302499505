import { actionTypes } from '../actions/fourChart';

export const initialProps = {
  titleChart: [],
  error: null,
  loading: false
};

function reducer(state = initialProps, action) {
  switch (action.type) {
  case actionTypes.ALL_TITLES_CHART:
    return {
      ...state,
      loading: true
    };
  case actionTypes.ALL_TITLES_CHART_SUCCESS:
    return {
      ...state,
      loading: false,
      titleChart: action.payload
    };
  case actionTypes.ALL_TITLES_CHART_ERROR:
    return {
      ...state,
      loading: false,
      error: action.payload
    };
  default:
    return state;
  }
}
export default reducer;

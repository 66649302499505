import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { UPLOAD_USERS_TO_CALIBRATE } from '../../../../graphql/mievolucion/calibracion/mutations';
import ModalMessage from '../../../shared/ModalMessage';
import MLUpload from '../../../shared/MLUpload';

const CalibrationsUploader = ({
  onSuccess, onError, className, shouldCreate
}) => {
  const [uploadUsersToCalibrate] = useMutation(
    UPLOAD_USERS_TO_CALIBRATE, {
      context: { clientName: 'myEvolution' },
      fetchPolicy: 'network-only'
    }
  );

  const [modalMessage, setModalMessage] = useState({ visible: false, icon: 'icon-ml-warning' });

  const getClassName = () => (className ? { className } : null);
  const callMutation = ({ file }) => uploadUsersToCalibrate({ variables: { somefile: file } });
  const onUploadSuccess = ({ responseData }) => onSuccess(responseData);
  const onUploadError = (error) => onError(error);
  const onUploadDisabled = () => setModalMessage({
    ...modalMessage,
    visible: true,
    title: 'No puedes volver a subir un archivo',
    message: 'Se puede subir un archivo solamente una vez por grilla. Si deseas agregar o quitar colaboradores de la grilla, lo debes hacer en la opción “Buscar colaboradores uno a uno.”'
  });
  const onCloseDisableModal = () => setModalMessage({ ...modalMessage, visible: false });

  return (
    <div {...getClassName()}>
      <MLUpload {...{
        label: 'Subir archivo con colaboradores',
        ...(!shouldCreate ? {
          disabled: !shouldCreate,
          onDisabled: onUploadDisabled
        } : null),
        callMutation,
        onSuccess: onUploadSuccess,
        onError: onUploadError
      }}/>

      {modalMessage?.visible
      && <ModalMessage {...{
        ...modalMessage,
        buttons: [{
          type: 'primary',
          text: 'Entendido',
          onClickButton: onCloseDisableModal
        }],
        closeModal: onCloseDisableModal
      }}/>
      }
    </div>
  );
};

CalibrationsUploader.propTypes = {
  className: PropTypes.string,
  shouldCreate: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onUploadError: PropTypes.func
};

CalibrationsUploader.defaultProps = {
  className: '',
  shouldCreate: false,
  onSuccess: () => null,
  onError: () => null,
  onUploadError: () => null
};

export default CalibrationsUploader;

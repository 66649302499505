import getSuccessMeasures from './getSuccessMeasures';
import { SPECIFIC_OBJECTIVE_KEY, pillarName } from './index';

const getInputForCreate = ({
  dataPerformanceWalmart, values, formItems
}) => {
  try {
    const {
      successMeasures, visible, weight, otherObjective: otherObjectiveText
    } = values;
    const {
      pillarSelected
    } = formItems;
    const { objectives = [] } = pillarSelected;
    const [otherObjective] = objectives;

    const isOtherObjective = pillarSelected._id === pillarName[SPECIFIC_OBJECTIVE_KEY];

    const objective = isOtherObjective ? otherObjective?._id : values.objective;

    const input = {
      visible: !visible,
      performanceId: dataPerformanceWalmart._id,
      weight: Number(weight.replace(/[^\d.]/g, '')),
      successMeasures: getSuccessMeasures(successMeasures),
      objective,
      ...(pillarSelected._id === pillarName[SPECIFIC_OBJECTIVE_KEY] ? {
        objectiveText: {
          text: otherObjectiveText
        }
      } : { })
    };

    return input;
  } catch {
    return null;
  }
};

export default getInputForCreate;

import React from 'react';
import PropTypes from 'prop-types';
import usePerformance from '../../../../../hooks/myEvolution/myPerformance/usePerformance';
import { fullCapitalizeFormat } from '../../../../../helpers/strings';
import InfoMessage from '../../../../shared/InfoMessage';
import GreyMessage from '../../../../shared/messages/GreyMessage';
import Separator from '../../../../shared/Separator';
import '../../../../../static/styles/mievolucion/objectives2022/advance-comments-description.less';

const AdvanceCommentsDescription = ({
  comments, bossParams, messages, quarter
}) => {
  const { data: performance = {} } = usePerformance();
  const { quarterCurrent, boss } = performance;
  const qIndex = quarter?.split('q').pop();
  const emptyMessage = `No ${quarter === quarterCurrent ? 'han habido aún' : 'hubo'} comentarios en este Pulso ${qIndex}`;
  const bossName = fullCapitalizeFormat(comments?.commentWrittenBoss?.name || boss.name);
  const bossHasCommented = !!comments?.commentBoss;
  const colaboradorHasCommented = !!comments?.commentColaborate;

  return (
    <div className='advance-comments-description'>
      <div className='my-advance-comment'>
        {colaboradorHasCommented && <label>{
          bossParams ? `Comentario de ${bossParams.selectedUser.name}` : 'Mi comentario'
        }</label>}

        {comments?.commentColaborate ? (
          <p>{comments?.commentColaborate}</p>
        ) : (
          <GreyMessage message={emptyMessage}/>
        )}
      </div>

      {colaboradorHasCommented && (
        <>
          <Separator/>

          <div className='my-boss-advance-comment'>
            <label>
              {bossParams ? (
                bossParams.commentMessage || 'Tu comentario'
              ) : (
                `Comentario de ${bossName}`
              )}
            </label>
            <p>{comments?.commentBoss}</p>
          </div>

          { colaboradorHasCommented && !bossHasCommented && messages?.bossHasNoCommented && (
            <div className='my-boss-advance-comment'>
              <InfoMessage {...{
                suave: true,
                color: messages.bossHasNoCommented?.color,
                message: messages.bossHasNoCommented?.message
              }} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

AdvanceCommentsDescription.typeProps = {
  comments: PropTypes.object,
  bossParams: PropTypes.object,
  messages: PropTypes.object,
  quarter: PropTypes.string
};

AdvanceCommentsDescription.defaultProps = {
  comments: null,
  bossParams: null,
  messages: null,
  quarter: undefined
};

export default AdvanceCommentsDescription;

/* eslint-disable import/prefer-default-export */
import * as FileSaver from 'file-saver';

const XLSX = import('xlsx');

export const downloadXLSX = async (shapingFunction, data) => {
  const jsonData = shapingFunction(data);
  const { utils: xlsxUtils, write: xlsxWrite } = await XLSX;

  return async (fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = xlsxUtils.json_to_sheet(jsonData);
    const wscols = jsonData.map(() => ({ wch: 20 }));
    ws['!cols'] = wscols;
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = xlsxWrite(wb, { bookType: 'xlsx', type: 'array' });
    const data2 = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data2, fileName + fileExtension);
  };
};

export const downloadExcelBlobFromRest = (file, fileName) => {
  const byteCharacters = atob(file);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  FileSaver.saveAs(blob, fileName);
};

import { actionTypes } from '../actions/help';

export const initialState = {
  data: null,
  loading: false,
  error: null
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_LIST_TICKET:
    return {
      ...state,
      loading: true,
      error: null
    };
  case actionTypes.GET_LIST_TICKET_SUCCESS:
    return {
      ...state,
      data: action.data,
      loading: false
    };
  case actionTypes.GET_LIST_TICKET_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error
    };
  case actionTypes.CREATE_TICKET:
    return {
      ...state,
      payload: action.payload,
      resTicket: null,
      loading: true,
      error: null
    };
  case actionTypes.CREATE_TICKET_SUCCESS:
    return {
      ...state,
      resTicket: action.resTicket,
      loading: false
    };
  case actionTypes.CREATE_TICKET_ERROR:
    return {
      ...state,
      loading: false,
      resTicket: action.resTicket,
      error: action.error
    };
  case actionTypes.ADD_COMMENT:
    return {
      ...state,
      payload: action.payload,
      loading: true,
      error: null
    };
  case actionTypes.ADD_COMMENT_SUCCESS:
    return {
      ...state,
      resComment: action.resComment,
      loading: false
    };
  case actionTypes.ADD_COMMENT_ERROR:
    return {
      ...state,
      loading: false,
      resComment: action.resComment,
      error: action.error
    };
  case actionTypes.GET_USER_SF:
    return {
      ...state,
      reqUserSF: action.rut,
      loading: true,
      error: null
    };
  case actionTypes.GET_USER_SF_SUCCESS:
    return {
      ...state,
      resUserSF: action.resUserSF,
      loading: false
    };
  case actionTypes.GET_USER_SF_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error
    };
  case actionTypes.HOME_HELP:
    return {
      ...state,
      reqHomeHelp: action.rut,
      loading: true,
      error: null
    };
  case actionTypes.HOME_HELP_SUCCESS:
    return {
      ...state,
      resHomeHelp: action.resHomeHelp,
      loading: false
    };
  case actionTypes.HOME_HELP_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error
    };
  case actionTypes.GET_FILE:
    return {
      ...state,
      loading: true,
      error: null
    };
  case actionTypes.GET_FILE_SUCCESS:
    return {
      ...state,
      resFileHelp: action.resFileHelp,
      loading: false
    };
  case actionTypes.GET_FILE_ERROR:
    return {
      ...state,
      loading: false,
      resFileHelp: action.resFileHelp,
      error: action.error
    };
  default:
    return state;
  }
}

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import '../../../../static/styles/mievolucion/myPerformance/feedback/styles.less';

const ButtonCreateFeedback = ({ type, className, onClick }) => (
  <div className='container-button-create-feedback'>
    <Button
      type='primary'
      className={className}
      onClick={onClick}
    >
      {`${type === 'Requested' ? 'Pedir' : 'Dar'} Feedback`}
    </Button>
  </div>
);

ButtonCreateFeedback.propTypes = {
  type: PropTypes.oneOf(['Given', 'Requested']),
  onClick: PropTypes.func,
  className: PropTypes.string
};

ButtonCreateFeedback.defaultProps = {
  type: 'Given',
  onClick: () => {},
  className: ''
};

export default ButtonCreateFeedback;

import { lazy } from 'react';
import mlHome from './mlHome';
import profile from './profile';
import admin from './admin';
import compensations from './compensations';
import miBono from './myBonus';
import documents from './documents';
import solicitudes from './solicitudes';
import nuestraCompania from './nuestraCompania';
import notifications from './notifications';
import myEvolution from './myEvolution';
import myRecognition from './recognition';
import maintenance from './maintenance';
import internalOpportunities from './opportunities';
import operationsAcademy from './operationsAcademy';
import returningPlan from './returningPlan';
import MyTime from './myTime';
import Wali from './wali';
import MyTeam from './myTeam';
import help from './help';

const MiLugarLayout = lazy(() => import('../../layouts/milugar'));

export default [
  {
    path: '/',
    component: MiLugarLayout,
    subRoutes: [
      ...mlHome,
      ...profile,
      ...nuestraCompania,
      ...compensations,
      ...admin,
      ...documents,
      ...maintenance,
      ...miBono,
      ...notifications,
      ...help,
      ...myEvolution,
      ...myRecognition,
      ...internalOpportunities,
      ...operationsAcademy,
      ...returningPlan,
      ...solicitudes,
      ...MyTime,
      ...Wali,
      ...MyTeam
    ]
  }
];

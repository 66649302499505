import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import WrittenCharacters from './WrittenCharacters';

const { TextArea: InputTextArea } = Input;

const TextArea = ({
  title, description, maxLength, placeholder, value, onChange
}) => (
  <div className='container-question'>
    <p className='title'>{title}</p>
    <p className='description'>{description}</p>
    <InputTextArea
      maxLength={maxLength}
      value={value}
      onChange={({ target }) => {
        onChange(target.value);
      }}
      placeholder={placeholder}
    />
    <WrittenCharacters
      value={value}
      maxLength= {maxLength}
    />
  </div>
);

TextArea.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

TextArea.defaultProps = {
  title: '',
  description: '',
  maxLength: 0,
  placeholder: '',
  value: '',
  onChange: () => {}
};
export default TextArea;

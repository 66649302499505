import React from 'react';
import { Button } from 'antd';
import '../../../static/styles/shared/MLHistorical/category-button.less';

const CategoryButton = ({ text, active, onClick }) => (
  <Button
    block
    className={`mievolucion-historical-list-category-button${active ? ' active-btn' : ''}`}
    onClick={onClick}
  >
    {text}
  </Button>
);

export default CategoryButton;

import React from 'react';
import PropTypes from 'prop-types';
import InfoMessage from '../../../shared/InfoMessage';

const InfoEvaluations = ({ classes, userHasEvaluations }) => (
  <div>
    <InfoMessage
      color='warning'
      classes={`container-message-evaluations ${classes}`}
      message ={userHasEvaluations ? <span>
        Si necesitas información de evaluaciones antes del 2020 debes comunicarte con el equipo de gestión del desempeño al correo <span className='text-bold'>gestiondeldesempeno@walmart.com</span>
      </span>
        : <span>
        No tienes Evaluaciones de Desempeño registradas en Mi Lugar. Si necesitas información de tus evaluaciones antes del 2020 debes comunicarte con el equipo de gestión del desempeño al correo <span className='text-bold'>gestiondeldesempeno@walmart.com</span>
        </span>
      }

    />
  </div>
);
InfoEvaluations.propTypes = {
  userHasEvaluations: PropTypes.bool,
  classes: PropTypes.string
};

InfoEvaluations.defaultProps = {
  userHasEvaluations: false,
  classes: ''
};

export default InfoEvaluations;

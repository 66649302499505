import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Behavior from './Behavior';
import ModalMessage from '../../../../shared/ModalMessage';
import '../../../../../static/styles/mievolucion/evolucionaTEST/planDetail.less';
import { FINISH_SKILL_LEVEL_BEHAVIOR_ACTION } from '../../../../../graphql/mievolucion/evolucionaTEST/mutations';
import { GET_EVALUATION_PLAN_HISTORICAL } from '../../../../../graphql/mievolucion/evolucionaTEST/queries';

const PlanDetail = (props) => {
  const {
    data, rut, getBehaviors, level
  } = props;
  const { skill: { name: skill } } = data;

  const [planDetail, setPlanDetail] = useState(data);
  const [modalOptions, setModalOptions] = useState({
    visible: false,
    title: '',
    message: '',
    icon: '',
    type: ''
  });

  const clientName = 'myEvolution';
  const closeModal = () => {
    setModalOptions({
      visible: false,
      message: '',
      title: '',
      type: 'success'
    });
  };
  const [finishSkillLevelBehaviorAction] = useMutation(
    FINISH_SKILL_LEVEL_BEHAVIOR_ACTION,
    {
      context: { clientName },
      onCompleted: ({ finishSkillLevelBehaviorAction: planDetailData }) => {
        const newData = { ...planDetailData, behaviors: getBehaviors(planDetailData) };
        setPlanDetail(newData);
        setModalOptions({
          visible: true,
          title: 'Has finalizado esta acción con éxito',
          message: '',
          type: 'success',
          buttons: [
            {
              type: 'primary',
              text: 'Cerrar'
            }
          ]
        });
      },
      onError: () => {
        setModalOptions({
          visible: true,
          title: 'No se ha podido finalizar la acción',
          message: 'Se ha producido un error al intentar finalizar la acción. Por favor inténtalo de nuevo más tarde.',
          type: 'error'
        });
      }
    }
  );

  const endAction = (action) => {
    finishSkillLevelBehaviorAction({
      variables: {
        id: action._id
      },
      refetchQueries: [
        {
          context: { clientName },
          query: GET_EVALUATION_PLAN_HISTORICAL,
          variables: { dni: rut, status: ['PENDING'] }
        }
      ]
    });
  };
  const openModalFinishAction = (action, loadingFinish = false) => {
    setModalOptions({
      icon: 'icon-ml-warning',
      visible: true,
      title: '¿Estás seguro que deseas finalizar esta acción?',
      buttons: [
        {
          text: 'Volver',
          className: 'button-border-primary'
        },
        {
          text: 'Finalizar Acción',
          type: 'primary',
          loadingButton: loadingFinish,
          onClickButton: () => {
            endAction(action);
            openModalFinishAction(action, true);
          }
        }
      ]
    });
  };

  const handleEndAction = (action) => {
    openModalFinishAction(action);
  };

  return (
    <div className='container-plan-detail'>
      {modalOptions.visible
      && <ModalMessage
        {...modalOptions}
        closeModal={closeModal}
      />
      }
      {
        planDetail.behaviors.map((behaviorChoice, indexBehaviorChoice) => (
          <Behavior
            endAt={planDetail.endAt}
            level={level}
            skill={skill}
            handleEndAction={handleEndAction}
            key={indexBehaviorChoice}
            indexBehavior={indexBehaviorChoice}
            behaviorChoice={behaviorChoice}
          />
        ))
      }

    </div>
  );
};

PlanDetail.propTypes = {
  data: PropTypes.object,
  rut: PropTypes.string,
  getBehaviors: PropTypes.func

};

PlanDetail.defaultProps = {
  data: { },
  rut: '',
  getBehaviors: () => {}
};

export default PlanDetail;

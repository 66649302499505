import React, { useEffect, useState } from 'react';

import { getManifestVersion } from '../helpers/version';

const Version = () => {
  const [version, setVersion] = useState('');
  useEffect(() => {
    let isMounted = true;
    getManifestVersion().then((response) => isMounted && setVersion(response));
    return () => {
      isMounted = false;
    };
  }, []);

  if (version === '') return null;

  return (
    <div className='d-flex justify-content-center mt-5'>
      {<p className='m-0 text-version'>v {version}</p>}
    </div>
  );
};

export default Version;

import React from 'react';
import PropTypes from 'prop-types';
import MyTeamContainer from './MyTeamContainer';
import MLTabs from '../../components/shared/MLTabs/MLTabsByRouteSimple';
import '../../static/styles/my-team/my-evolution/container.less';

const MyEvolutionTeam = ({
  children, className, banner, tabs, firstTab
}) => (
  <MyTeamContainer banner={banner}>
    <div className={className}>
      <MLTabs
        options={tabs}
        firstTab={firstTab}
      >
        {children}
      </MLTabs>
    </div>
  </MyTeamContainer>
);

MyEvolutionTeam.propTypes = {
  banner: PropTypes.object,
  children: PropTypes.element,
  className: PropTypes.string
};

MyEvolutionTeam.defaultProps = {
  banner: null,
  children: <></>,
  className: ''
};

export default MyEvolutionTeam;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import { getValorationsToMeAPI, getValorationsToOthersAPI } from '../api/misReconocimientos';
import {
  actionTypes,
  getValorationsToMeSuccess,
  getValorationsToMeError,
  getValorationsToOthersSuccess,
  getValorationsToOthersError
} from '../actions/misReconocimientos';
import { failure as commonsFailure } from '../actions/commons';

es6promise.polyfill();

export function* getValorationsToMe() {
  try {
    const response = yield call(getValorationsToMeAPI);
    yield put(getValorationsToMeSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(statusCode === 401 ? commonsFailure(
      { error, statusCode }
    ) : getValorationsToMeError({ error, statusCode }));
  }
}

export function* getValorationsToOthers() {
  try {
    const response = yield call(getValorationsToOthersAPI);
    yield put(getValorationsToOthersSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(statusCode === 401 ? commonsFailure(
      { error, statusCode }
    ) : getValorationsToOthersError({ error, statusCode }));
  }
}

function* covidSagas() {
  yield all([
    takeLatest(actionTypes.GET_VALORATIONS_TO_ME, getValorationsToMe),
    takeLatest(actionTypes.GET_VALORATIONS_TO_OTHERS, getValorationsToOthers)
  ]);
}

export default covidSagas;

import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { last } from 'lodash';
import BannerLayout from '../../../layouts/banner';
import Tabs from '../../../components/shared/MLTabs';
import { completePath } from '../../../helpers/strings';
import RetroReport from '../../../components/admin/mievolucion/retro/report';

const Retro = (props) => {
  const { permissions } = props;
  const { ADMIN_MI_EVOLUCION_RETRO_REPORTS } = permissions;
  const { pathname } = useLocation();

  const tabs = [
    ...(ADMIN_MI_EVOLUCION_RETRO_REPORTS ? [
      {
        title: 'Reportes',
        children: <RetroReport />
      }
    ] : [])
  ];

  const getBannerConfig = () => {
    const sectionName = last(pathname.split('/'));
    return {
      imageBanner: completePath('web/admin/banner/mi-evolucion-banner.jpg'),
      imageAvatar: completePath('web/admin/avatar/mi-evolucion-avatar.jpg'),
      title: sectionName
    };
  };

  return (
    <BannerLayout {...getBannerConfig()} childrenClassName='px-0'>
      <Tabs options={tabs} titleAnalyticsEvent='Administrador' />
    </BannerLayout>
  );
};

Retro.propTypes = {
  permissions: PropTypes.object
};

Retro.defaultProps = {
  permissions: {}
};
export default Retro;

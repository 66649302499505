import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'antd';
import { GERENCIAS } from '../../../../helpers/admin/miEvolucion/miDesarrollo/constants';

const { Option } = Select;

const SearchIDPGerencia = ({ handleOnchange }) => <div className='row'>
  <div className='col-4'>
    <Select placeholder='Seleccionar una opción' onChange={handleOnchange}>
      {
        GERENCIAS.map((gerencia, i) => <Option
          key={i}
          value = {gerencia}>
          {gerencia}
        </Option>)
      }
    </Select>
  </div>
</div>;

SearchIDPGerencia.propTypes = {
  handleOnchange: PropTypes.func
};

SearchIDPGerencia.defaultProps = {
  handleOnchange: () => {}
};

export default SearchIDPGerencia;

import moment from 'moment';

const setObjectiveFormDefaults = ({ Form, srcData }) => {
  const { currentQuarter } = srcData;
  const previousQuarter = `q${Number(currentQuarter?.split('q')?.pop()) - 1}`;

  const fields = {
    objectiveDataID: Form.createFormField({
      value: srcData.id
    }),
    visible: Form.createFormField({
      value: !srcData.visible
    }),
    weight: Form.createFormField({
      value: srcData.weight || '0%'
    })
  };

  // eslint-disable-next-line no-unused-expressions
  srcData.successMeasures?.map((measure, i) => (
    Object.keys(measure).map((measureKey) => {
      switch (measureKey) {
      case 'dateMeasure':
      case 'description':
        Object.assign(fields, {
          [`successMeasures[${i}][${measureKey}]`]: Form.createFormField({
            value: measureKey === 'dateMeasure' ? (
              moment(Number(measure[measureKey]))
            ) : (
              measure[measureKey]
            )
          })
        });
        break;
      case 'updates': {
        const previousStatus = measure[measureKey][previousQuarter]?.status;

        for (let qi = 1; qi <= 4; qi += 1) {
          const qKey = `q${qi}`;
          const qiStatus = measure[measureKey][qKey]?.status;

          Object.assign(fields, {
            [`successMeasures[${i}][${measureKey}][q${qi}][status]`]: Form.createFormField({
              value: qiStatus || (qKey === currentQuarter && previousStatus) || 'WITHOUTINFO'
            })
          });
        }
        break;
      }
      default:
      }

      return null;
    })
  ));

  return fields;
};

export default setObjectiveFormDefaults;

/* eslint-disable prefer-const */
import { lazy } from 'react';
import { isProduction } from '../../../../helpers/commons';
import { PERFORMANCE_YEAR } from '../../../../helpers/myEvolution/myPerformance';

const Performance = lazy(() => import('../../../../containers/myTeam/myEvolution/Performance'));

let listPerformance = [
  {
    exact: true,
    auth: 'MY_TEAM_MY_EVOLUTION_PEFORMANCE',
    path: `/mi-equipo/mi-evolucion/desempeño-${PERFORMANCE_YEAR}`,
    section: `Mi Equipo - Mi Evolución - Desempeño ${PERFORMANCE_YEAR}`,
    component: Performance
  }
];

export default isProduction() ? listPerformance.splice(0, 1) : listPerformance;

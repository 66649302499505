import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useLocation, useRouteMatch } from 'react-router-dom';
import MLSecondaryTabs from '../../shared/MLTabs/MLSecondaryTabs';
import { smallDevice } from '../../../helpers/deviceVariables';
import Profile from '../../shared/Headers/Profile';
import { fullCapitalizeFormat } from '../../../helpers/strings';
import MobileList from '../../shared/list/MobileList';
import Historical from './Historical';
import { customFormatDateCard } from '../../../helpers/dates';
import InfoMessage from '../../shared/InfoMessage';
import { useWindowWidthSize } from '../../../helpers/browser';
import { RETRO_URL } from '../../../helpers/myEvolution/retro';
import { GET_LAST_RETRO_COMMUNICATED } from '../../../graphql/myTeam/myEvolution/retro/queries';
import { MY_EVOLUTION_CONTEXT } from '../../../helpers/apollo';

const MyEvaluations = (props) => {
  const {
    position,
    dateOld,
    myRetros,
    banner
  } = props;
  const width = useWindowWidthSize();
  const isMobile = width <= smallDevice;
  const [hidden, setHidden] = useState(isMobile);
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  const {
    data: lastEvaluation
  } = useQuery(GET_LAST_RETRO_COMMUNICATED,

    {
      context: MY_EVOLUTION_CONTEXT,
      fetchPolicy: 'network-only'
    });

  const { lastRetroCommunicated = null } = lastEvaluation || {};
  const { finalCategory } = lastRetroCommunicated || {};

  useEffect(() => {
    if (pathname === path) {
      setHidden(false);
    }
  }, [pathname, path]);

  if (myRetros.length === 0) {
    return (
      <div>
        <InfoMessage
          classes='m-3 m-sm-0 m-md-0 m-xl-0'
          message ={'No tienes evaluaciones de retroalimentación cargadas en Mi Lugar.'}
        />
      </div>
    );
  }

  const { children } = props;
  const buildSecondaryTabs = () => ([
    {
      title: 'Historial',
      key: `${RETRO_URL}/historial`,
      children: <Historical />
    }
  ]);

  const renderDefaultSecondaryTab = () => {
    if (pathname === path
      && children) {
      const { props: { routes } } = children;
      const [defaultComponent] = routes;
      return <defaultComponent.component />;
    }
    return null;
  };

  const GetProfile = () => {
    const listHeader = [
    ];
    if (dateOld) {
      listHeader.push({
        label: 'Ingreso',
        value: customFormatDateCard(dateOld, 'DD/MM/YYYY')
      });
    }

    listHeader.push(
      { label: 'Cargo actual', value: fullCapitalizeFormat(position) }
    );

    if (finalCategory) {
      listHeader.push(
        { label: 'Desempeño última evaluación', value: finalCategory.name }
      );
    }

    return (
      <Profile
        listHeader={listHeader}
      />
    );
  };
  const listOptions = [
    {
      label: 'Historial',
      to: `${path}/historial`,
      onClick: () => setHidden(true)
    }
  ];

  const conditionShowHistorical = (path === `${RETRO_URL}`
  || pathname === RETRO_URL);

  return (
    <div>
      { width <= smallDevice && !hidden
      && <div {...banner}>
        <GetProfile />
        <MobileList listOptions={listOptions} />
      </div>
      }

      {!hidden
        && <div className="d-none d-sm-block">
          <GetProfile />
          <MLSecondaryTabs
            options={buildSecondaryTabs()}
            defaultActiveKey={`${RETRO_URL}/historial`}
            activeTab={`${RETRO_URL}/historial`}
          />
        </div>
      }
      {
        (hidden && isMobile && conditionShowHistorical) || (
          conditionShowHistorical && !isMobile
        ) ? <Historical /> : null
      }

      <div >
        {children}
      </div>

      <div>
        { hidden && renderDefaultSecondaryTab()}
      </div>

    </div>
  );
};

MyEvaluations.propTypes = {
  position: PropTypes.string,
  handleShowEvaluation: PropTypes.func,
  isEnabled: PropTypes.bool,
  dateOld: PropTypes.string,
  myRetros: PropTypes.array
};

MyEvaluations.defaultProps = {
  position: '',
  handleShowEvaluation: () => {},
  isEnabled: true,
  dateOld: '',
  myRetros: []
};
export default MyEvaluations;

import { find, isEmpty } from 'lodash';

const setDescriptionBankAccount = ({ bankAccountData, bankList }) => {
  const { bankID, accountType, accountNumber } = bankAccountData;

  const bankData = find(bankList, (bank) => bank._id === bankID);
  if (!bankData || isEmpty(accountType) || isEmpty(accountNumber)) return null;
  return `\nNombre del banco: ${bankData.name}\nCódigo del banco: ${bankData.code}\nTipo de cuenta: ${accountType}\nNúmero de cuenta: ${accountNumber}`;
};

export default setDescriptionBankAccount;

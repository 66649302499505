import optionsTypeRequest from './optionsTypeRequest';

const { BANK_ACCOUNT, BUSES } = optionsTypeRequest;

export default {
  HOME_HELP: 'homeHelp',
  FILE_TICKET: 'fileTicket',
  INCOMPLETE_BANK_DETAILS: BANK_ACCOUNT.category,
  INCOMPLETE_BUSES_DETAILS: BUSES.category,
  FILE_SIZE: 'fileSize',
  INCOMPATIBLE_FILE: 'incompatibleFile',
  CREATE_TICKET: 'createTicket',
  COMMENT: 'comentario'
};

/* eslint-disable max-len */
import React from 'react';
import { Table } from 'antd';

import '../../../static/styles/shared/tables/scrollableTable.less';

const ScrollableTable = ({
  dataSource, columns, scroll, classes = ''
}) => <Table
  className={`scrollableTable ${classes}`}
  columns={columns}
  dataSource={dataSource}
  scroll={scroll}
  pagination={false}
/>;

export default ScrollableTable;

import { actionTypes } from '../actions/LiveHealthy';

export const initialState = {
  pillar: null,
  loadingSocialWorkers: false,
  loadedSocialWorkers: false,
  socialWorkers: null

};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_PILLAR:
    return {
      ...state,
      ...{ pillar: action.data }
    };

  case actionTypes.GET_PILLARS:
    return {
      ...state,
      ...{ pillars: action.data }
    };

  case actionTypes.GET_PILLARS_SUCCESS:
    return {
      ...state,
      ...{ pillars: action.data }
    };

  case actionTypes.POST_RECIPE_SUCCESS:
    return {
      ...state,
      ...{ message: action.data }
    };

  case actionTypes.GET_PILLAR_SUCCESS:
    return {
      ...state,
      ...{ pillar: action.data }
    };

  case actionTypes.GET_FLICKR_ALBUM_LIVE:
    return {
      ...state,
      ...{ flickrAlbum: action.data }
    };

  case actionTypes.GET_FLICKR_ALBUM_LIVE_SUCCESS:
    return {
      ...state,
      ...{ flickrAlbum: action.data }
    };

  case actionTypes.GET_SOCIAL_WORKERS:
    return {
      ...state,
      loadingSocialWorkers: true,
      loadedSocialWorkers: false
    };

  case actionTypes.GET_SOCIAL_WORKERS_SUCCESS:
    return {
      ...state,
      socialWorkers: action.data,
      loadingSocialWorkers: false,
      loadedSocialWorkers: true
    };

  default:
    return state;
  }
}

export default reducer;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  find, isEmpty, map, trimStart
} from 'lodash';
import {
  Input, Select, Form
} from 'antd';
import { withoutInformation } from '../../../../../../helpers/myEvolution/feedback';
import InfoMessage from '../../../../../shared/InfoMessage';
import MLSimpleCollapse from '../../../../../shared/MLSimpleCollapse';
import Avatar from '../../../../../shared/Avatar';

const { TextArea } = Input;
const { Option } = Select;
const maxLengthTextArea = 400;

export const CompetenciesDetails = ({ currentLevel, nextLevel }) => {
  if (isEmpty(currentLevel) || isEmpty(nextLevel)) {
    return null;
  }
  const behaviorsByLevel = (behaviors, title) => {
    if (isEmpty(behaviors)) return null;
    return (
      <Fragment>
        <h6>{title}</h6>
        {behaviors.map((conduct, i) => (
          <div key={i} className='detail-behavior'>
            <div className="dot-passion"/>
            <p>{conduct.name}</p>
          </div>
        ))}
      </Fragment>
    );
  };
  return (
    <div className='box-behaviors'>
      <MLSimpleCollapse title='Conductas' >
        <div className='behaviors'>
          {behaviorsByLevel(currentLevel.behaviors, 'Conductas de Mi Nivel')}
          <div className='behaviors-next-level'>
            {behaviorsByLevel(nextLevel.behaviors, 'Conductas del Siguiente Nivel')}
          </div>
        </div>
      </MLSimpleCollapse>
    </div>
  );
};
const FeedbackNetwork = (props) => {
  const {
    categories,
    data: {
      isGroup,
      subject,
      title,
      subTitle,
      date,
      status,
      comments,
      statusClasses,
      setComments,
      CONDITION_BUTTON_SEND_FEEDBACK
    },
    feedbackToMe,
    getFieldDecorator
  } = props;

  const setContent = (value, comment) => {
    switch (true) {
    case comment.content === withoutInformation.content && value !== withoutInformation.value:
      return '';

    case value === withoutInformation.value:
      return withoutInformation.content;
    default:
      return comment.content;
    }
  };

  const handleCategory = (value, i) => {
    const newComments = [...comments];
    const performanceCategory = find(categories, (category) => category._id === value);
    newComments[i] = {
      ...newComments[i],
      performanceCategoryID: value,
      performanceCategory,
      content: setContent(value, newComments[i])
    };
    setComments(newComments);
  };
  return (
    <Fragment>
      <div className='mievolucion-feedback-network'>
        <div className='container-info-feedback'>
          <div className="container-left">
            {isGroup ? (
              <span className="ant-avatar ant-avatar-icon">
                <i className="icon-me-feedback-to-group" />
              </span>
            ) : <Avatar />}
          </div>
          <div className='container-right'>
            <div className='d-flex'>
              <p><span className="bold">{feedbackToMe ? 'De: ' : 'Para: '}</span></p>
              <p><span className="bold">{title}</span> | {subTitle}</p>
            </div>
            <div className='d-flex'>
              <p><span className="bold">Asunto: </span></p>
              <p>{subject}</p>
            </div>
          </div>

        </div>
        <div className='container-date-and-status '>
          <div>
            <i className="icon-ml-calendar-2 primary-icon-color" />
            <p>{date}</p>
          </div>
          <div>
            <i className={statusClasses} />
            <p>{status}</p>
          </div>
        </div>

        {
          status === 'Vencido'
            ? <InfoMessage
              color='warning'
              classes='w-auto'
              message={feedbackToMe
                ? `Ha vencido el tiempo para que ${title} responda tu solicitud de feedback.`
                : `Ha vencido el tiempo para poder realizar el feedback solicitado por ${title}.`
              } />
            : map(comments, (comment, i) => (
              <div key={i} className={`box-skills ${status !== 'Pending' && 'box-feedback-sent'}`}>
                <h6>{comment.skill.name}</h6>
                <p>
                  {comment.skill.description}
                </p>
                <CompetenciesDetails {...{ ...comment.skill }} />

                { !(feedbackToMe && status === 'Pendiente')

            && (<div>

              <h5>Categoría de desempeño</h5>

              {CONDITION_BUTTON_SEND_FEEDBACK ? (
                <Form.Item
                  className='box-select-categories'>
                  {getFieldDecorator(`category-${comment.skill.name}`, {
                    rules: [{ required: true, message: 'Debes ingresar una categoria' }]
                  })(
                    <Select
                      className='select-categories'

                      placeholder='Seleccionar categoría'
                      onChange={ (value) => handleCategory(value, i)}>
                      <Option value={withoutInformation.value}>
                        {withoutInformation.category}
                      </Option>
                      {
                        categories.map((category, j) => (
                          <Option key={j} value={category._id}>{category.name}</Option>
                        ))
                      }
                    </Select>
                  )}
                </Form.Item>

              ) : (
                <p>
                  { comment.performanceCategory
                    ? comment.performanceCategory.name : withoutInformation.category}
                </p>

              )}

              <h5>Comentarios</h5>

              {CONDITION_BUTTON_SEND_FEEDBACK
              && comments[i].performanceCategoryID !== withoutInformation.value ? (

                  <Form.Item
                    className='box-text-area'>
                    {getFieldDecorator(`skill-${comment.skill.name}`, {
                      rules: [{ required: comments[i].performanceCategoryID !== withoutInformation.value, message: 'Debes ingresar un comentario' }]
                    })(
                      <div>
                        <TextArea
                          maxLength={maxLengthTextArea}
                          placeholder='Ingresar comentarios'
                          value={comment.content}
                          disabled={comments[i].performanceCategoryID === withoutInformation.value}
                          onChange={(e) => {
                            const newComments = [...comments];
                            newComments[i] = {
                              ...newComments[i], content: trimStart(e.target.value)
                            };
                            setComments(newComments);
                          }}
                        />
                        <p className='text-info-input mb-0'>{`${comment.content.length}/${maxLengthTextArea}`}</p>
                      </div>
                    )}
                  </Form.Item>

                ) : (

                  <p>
                    {comment.content}
                  </p>

                )}
            </div>)}

              </div>
            ))}
      </div>
    </Fragment>
  );
};
FeedbackNetwork.propTypes = {
  categories: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  feedbackToMe: PropTypes.bool.isRequired,
  getFieldDecorator: PropTypes.func

};

FeedbackNetwork.defaultProps = {
  categories: [],
  data: {},
  feedbackToMe: true,
  getFieldDecorator: () => {}
};
export default FeedbackNetwork;

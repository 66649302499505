import authorized from '../helpers/authorized';
import useAuthReducers from './useAuthReducers';

const useAuthorization = ({
  auth, validateCredentials, userValidator
}) => {
  const reducers = useAuthReducers();

  const { userReducer, showCredential } = reducers;
  const { me } = userReducer;

  const isAuthorizated = authorized({
    auth, me, showCredential, validateCredentials, userValidator
  });

  return isAuthorizated;
};

export default useAuthorization;

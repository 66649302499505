import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import { getCompanyChartApi } from '../api/companyChart';
import { actionTypes, getCompanyChartError, getCompanyChartSuccess } from '../actions/companyChart';

es6promise.polyfill();

export function* getCompanyChart() {
  try {
    const response = yield call(getCompanyChartApi);
    yield put(getCompanyChartSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(getCompanyChartError({ error, statusCode }));
  }
}

function* companyChartSagas() {
  yield all([
    takeLatest(actionTypes.GET_COMPANY_CHART, getCompanyChart)
  ]);
}

export default companyChartSagas;

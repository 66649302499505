import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MLBoxMessage from '../../../../shared/MLBoxMessage';
import MLSimpleCollapse from '../../../../shared/MLSimpleCollapse';
import InfoMessage from '../../../../shared/InfoMessage';

const ContainerCategory = (props) => {
  const {
    classes,
    showCategory,
    category, showEdit, children, keyDescription
  } = props;
  const ContainerBoxCategoryAndTable = showEdit ? MLSimpleCollapse : Fragment;
  if (!category) return null;

  return (
    <div className={`box-category-and-table ${classes} ${!showEdit ? 'not-edit' : ''} `}>
      {showCategory
      && <MLBoxMessage
        boxTitle={ {
          label: 'Resultado de las competencias',
          value: category.name
        }}
      >
        <p>{category[keyDescription]}</p>
      </MLBoxMessage>
      }

      <ContainerBoxCategoryAndTable
        {
          ...(showEdit ? {
            title: 'detalle',
            open: true,
            classes: 'collapse-see-more'
          } : null)
        }
      >

        { showEdit
          && <InfoMessage
            classes='box-message-edit-categories'
            color='warning'
            message={'En este resumen puedes editar las categorías que asignaste a \
            las competencias.'} />
        }
        {children}
      </ContainerBoxCategoryAndTable>

    </div>

  );
};
ContainerCategory.propTypes = {
  category: PropTypes.object,
  showEdit: PropTypes.bool,
  children: PropTypes.element,
  showCategory: PropTypes.bool,
  keyDescription: PropTypes.string,
  classes: PropTypes.string
};

ContainerCategory.defaultProps = {
  category: {},
  showEdit: false,
  children: <></>,
  showCategory: true,
  keyDescription: 'description',
  classes: ''
};

export default ContainerCategory;

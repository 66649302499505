/* eslint-disable prefer-const */
import React from 'react';
import { useDispatch } from 'react-redux';
import AdminFileListReportsSockets from '../../../../shared/list/AdminFileListReportsSockets';
import {
  getPerformanceWalmartReport,
  getPerformanceWalmartProgressReport,
  getPerformanceWalmartTemplateReport
} from '../../../../../redux/actions/admin';
import useReport, { REPORT_NAMES } from '../../../../../hooks/reports/useReport';
import useSocket from '../../../../../hooks/sockets/useSocket';
import { PERFORMANCE_YEAR } from '../../../../../helpers/myEvolution/myPerformance';
import ReportByCode from '../../../../reports/ReportByCode';

const PerformanceWalmartReports = () => {
  const ws = useSocket();
  const performanceWalmartReport = useReport(REPORT_NAMES.PERFORMANCE_WALMART);
  const performanceWalmartProgressReport = useReport(REPORT_NAMES.PERFORMANCE_PROGRESS_WALMART);
  const performanceWalmartTemplateReport = useReport(REPORT_NAMES.PERFORMANCE_WALMART_TEMPLATE);

  const dispatch = useDispatch();
  const reportsToShow = [
    {
      title: `Objetivos ${PERFORMANCE_YEAR}`,
      report: performanceWalmartReport,
      startFetch: () => dispatch(getPerformanceWalmartReport(PERFORMANCE_YEAR)),
      isAsync: true
    },
    {
      title: 'Avance de Desempeño',
      report: performanceWalmartProgressReport,
      startFetch: () => dispatch(getPerformanceWalmartProgressReport(ws.socket.id))
    },
    {
      title: 'Plantilla para cargar resultados de Objetivos en EDD ',
      report: performanceWalmartTemplateReport,
      startFetch: () => dispatch(getPerformanceWalmartTemplateReport(ws.socket.id))
    }
  ];

  return (
    <div>
      {reportsToShow.map((item) => <AdminFileListReportsSockets
        key={item.title}
        {...item}
      />)}
      <ReportByCode
        jobCode='PULSE_SURVEY_REPORT'
        reportDetails= {{
          title: 'Reporte de encuestas de pulso',
          description:
        'Detalle de todas las encuestas del pulso actual realizadas.'
        }}
      />
    </div>
  );
};

export default PerformanceWalmartReports;

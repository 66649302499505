export const actionTypes = {
  FAILURE: 'FAILURE',
  GET_BONO: 'GET_BONO',
  GET_BONO_HISTORIAL: 'GET_BONO_HISTORIAL',
  GET_PDF: 'GET_PDF',
  GET_METRICS: 'GET_METRICS',
  GET_HISTORY: 'GET_HISTORY',
  SIMULATE_BONO: 'SIMULATE_BONO',
  UPLOAD_METRICS: 'UPLOAD_METRICS',
  GET_ALL_METRICS: 'GET_ALL_METRICS',
  GET_DESCRIPTIONS: 'GET_DESCRIPTIONS',
  UPLOAD_DESCRIPTION: 'UPLOAD_DESCRIPTION',
  GET_ALL_DESCRIPTIONS: 'GET_ALL_DESCRIPTIONS',
  GET_BONO_SUCCESS: 'GET_BONO_SUCCESS',
  GET_BONO_HISTORIAL_SUCCESS: 'GET_BONO_HISTORIAL_SUCCESS',
  GET_BONO_HISTORIAL_ERROR: 'GET_BONO_HISTORIAL_ERROR',
  GET_PDF_SUCCESS: 'GET_PDF_SUCCESS',
  GET_HISTORY_SUCCESS: 'GET_HISTORY_SUCCESS',
  GET_METRICS_SUCCESS: 'GET_METRICS_SUCCESS',
  SIMULATE_BONO_ERROR: 'SIMULATE_BONO_ERROR',
  SIMULATE_BONO_SUCCESS: 'SIMULATE_BONO_SUCCESS',
  UPLOAD_METRICS_SUCCESS: 'UPLOAD_METRICS_SUCCESS',
  GET_ALL_METRICS_SUCCESS: 'GET_ALL_METRICS_SUCCESS',
  GET_ALL_DESCRIPTIONS_SUCCESS: 'GET_ALL_DESCRIPTIONS_SUCCESS',
  GET_DESCRIPTIONS_SUCCESS: 'GET_DESCRIPTIONS_SUCCESS',
  UPLOAD_DESCRIPTION_SUCCESS: 'UPLOAD_DESCRIPTION_SUCCESS',
  CLEAN_MI_BONO: 'CLEAN_MI_BONO',
  GET_ACTIVE_YEAR: 'GET_ACTIVE_YEAR',
  GET_ACTIVE_YEAR_SUCCESS: 'GET_ACTIVE_YEAR_SUCCESS'
};

export function cleanBono() {
  return {
    type: actionTypes.CLEAN_MI_BONO
  };
}

export function failure(error) {
  return {
    type: actionTypes.FAILURE,
    error
  };
}

export function getBono() {
  return {
    type: actionTypes.GET_BONO
  };
}

export function getBonoSuccess(data) {
  return {
    type: actionTypes.GET_BONO_SUCCESS,
    data
  };
}

export function getBonoError(error) {
  return {
    type: actionTypes.GET_BONO_ERROR,
    error
  };
}

export function getBonoHistorial() {
  return {
    type: actionTypes.GET_BONO_HISTORIAL
  };
}

export function getBonoHistorialSuccess(data) {
  return {
    type: actionTypes.GET_BONO_HISTORIAL_SUCCESS,
    data
  };
}

export function getBonoHistorialError(error) {
  return {
    type: actionTypes.GET_BONO_HISTORIAL_ERROR,
    error
  };
}

export function getPdf(data) {
  return {
    type: actionTypes.GET_PDF,
    data
  };
}

export function getPdfSuccess(data) {
  return {
    type: actionTypes.GET_PDF_SUCCESS,
    data
  };
}

export function simulateBono(item) {
  return {
    type: actionTypes.SIMULATE_BONO,
    item
  };
}

export function simulateBonoError(error) {
  return {
    type: actionTypes.SIMULATE_BONO_ERROR,
    error
  };
}

export function simulateBonoSuccess(data) {
  return {
    type: actionTypes.SIMULATE_BONO_SUCCESS,
    data
  };
}

export function uploadMetrics(params) {
  return {
    type: actionTypes.UPLOAD_METRICS,
    params
  };
}

export function uploadMetricsSuccess(data) {
  return {
    type: actionTypes.UPLOAD_METRICS_SUCCESS,
    data
  };
}

export function uploadDescription(params) {
  return {
    type: actionTypes.UPLOAD_DESCRIPTION,
    params
  };
}

export function uploadDescriptionSuccess(data) {
  return {
    type: actionTypes.UPLOAD_DESCRIPTION_SUCCESS,
    data
  };
}

export function getHistory(code = 'MIP') {
  return { type: actionTypes.GET_HISTORY, code };
}

export function getHistorySuccess(data) {
  return {
    type: actionTypes.GET_HISTORY_SUCCESS,
    data
  };
}

export function getAllMetrics() {
  return { type: actionTypes.GET_ALL_METRICS };
}

export function getAllMetricsSuccess(data) {
  return {
    type: actionTypes.GET_ALL_METRICS_SUCCESS,
    data
  };
}

export function getAllDescriptions() {
  return { type: actionTypes.GET_ALL_DESCRIPTIONS };
}

export function getAllDescriptionsSuccess(data) {
  return {
    type: actionTypes.GET_ALL_DESCRIPTIONS_SUCCESS,
    data
  };
}

export function getActiveYear() {
  return {
    type: actionTypes.GET_ACTIVE_YEAR
  };
}

export function getActiveYearSuccess(data) {
  return {
    type: actionTypes.GET_ACTIVE_YEAR_SUCCESS,
    data
  };
}

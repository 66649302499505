import React from 'react';
import { Button } from 'antd';
import '../../static/styles/pwa-install/style.less';
import Modal from '../shared/ModalMessage';

const NotSafari = (props) => {
  const { modalVisible, setModalVisible } = props;

  const handleCopyLink = () => {
    const copyText = document.getElementById('linkInstall');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    copyText.blur();
    setModalVisible(true);
  };

  return (
    <div>
      <div className="container-pwa ">
        <div className='container-oops'>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p className="text-ready">¡Oops!</p>
            <p className="text-new-app">
              Para poder instalar la nueva versión de Mi Lugar desde un dispositivo iOS (iPhone),
              debes hacerlo desde el navegador de Safari.
            </p>
            <p className="text-safari mb-0">
              <span className="text-bold">{' Copia el enlace ' }</span>
               a continuación y
              <span className="text-bold">{' pégalo ' }</span>
              en el navegador de
              <span className="text-bold">{' Safari.'}</span>
            </p>
            <div className='container-links'>
              <input type="text" value={window.location.href} id="linkInstall" className="container-url"/>
              <Button
                onClick={handleCopyLink}
                type="primary">
                  Copiar Enlace
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        closeModal={() => setModalVisible(false)}
        visible={modalVisible}
        title='Enlace copiado con éxito'
        message='Ahora abre el navegador de Safari, pega el enlace y sigue las instrucciones para poder instalar la nueva versión de Mi Lugar.'
      />
    </div>
  );
};

export default NotSafari;

/* eslint-disable import/prefer-default-export */
import {
  map, capitalize, lowerCase, split
} from 'lodash';
import moment from 'moment';
import { getEnvVar } from '../commons';

export const fullCapitalizeFormat = (str = '', exception = () => false, todo = (word) => word) => (
  map(str.split(' '), (word) => (exception(word) ? todo(word) : capitalize(word))).join(' ')
);

export const DEFAULT_CONNECTORS = ['de', 'a', 'y', 'en', 'DE', 'A', 'Y', 'EN', 'menos'];

export const humanize = (str, connectorWords = []) => map(split(str, ' '), (p) => {
  if (connectorWords?.length === 0) {
    return capitalize(p);
  }
  if (connectorWords.includes(p)) {
    return p.toLowerCase();
  }
  return capitalize(p);
}).join(' ');

export const parseToSlug = (str) => lowerCase(str).replace(/ /g, '-');

export const isValidEmail = (str) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);

export const completePath = (path) => {
  const REACT_APP_STATICS = getEnvVar('REACT_APP_STATICS');
  const REACT_APP_STATICS_KEY = getEnvVar('REACT_APP_STATICS_KEY');
  return !/https|http/.test(path) ? `${REACT_APP_STATICS}/${path}${REACT_APP_STATICS_KEY}` : path;
};
export const completeNeoMiLugarSitePath = (path) => `${getEnvVar('REACT_APP_NEO_MI_LUGAR_URL')}/${path}`;

export const isValidPhoneNumber = (str) => {
  if (!Number.isInteger(Number(str))) return false;
  if (str.toString().length < 9) return false;
  return true;
};
export const formatURL = (str = '') => map(str.split(' '), (word) => word.toLowerCase()).join('-');

export const unformatString = (str) => {
  if (str !== '' && str) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, ' ')
      .trim()
      .toLowerCase();
  }
  return '';
};

export const toSpanishStringList = (str) => str.replace(/,/g, ', ').replace(/,(?!.*,)/, ' y');

export const formatDateHelp = (date, format) => capitalize(moment(new Date(date)).local()
  .format(format));

export const formatPeso = (value) => new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(value);

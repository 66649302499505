export const actionTypes = {
  FAILURE: 'FAILURE',
  CLEAN_MESSAGE: 'CLEAN_MESSAGE',
  GET_PAYING: 'GET_PAYING',
  GET_PAYING_SUCCESS: 'GET_PAYING_SUCCESS',
  SEND_PAYING: 'SEND_PAYING',
  SEND_PAYING_SUCCESS: 'SEND_PAYING_SUCCESS'
};

export function failure(error) {
  return {
    type: actionTypes.FAILURE,
    error
  };
}

export function getPaying(countMonth) {
  return { type: actionTypes.GET_PAYING, countMonth };
}

export function getPayingSuccess(data) {
  return {
    type: actionTypes.GET_PAYING_SUCCESS,
    data
  };
}

export function sendPaying(payload) {
  return {
    type: actionTypes.SEND_PAYING,
    payload
  };
}

export function sendPayingSuccess(data) {
  return {
    type: actionTypes.SEND_PAYING_SUCCESS,
    data
  };
}

export function clearMessage() {
  return {
    type: actionTypes.CLEAN_MESSAGE
  };
}

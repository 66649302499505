import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MLBoxMessage from '../../../../shared/MLBoxMessage';

const ContainerCategory = (props) => {
  const {
    classes,
    showCategory,
    category,
    children,
    type,
    keyDescription
  } = props;

  if (!category) return null;
  const complementType = type === 'objective' ? 'los objetivos' : 'las competencias';

  return (
    <div className={`box-category-and-table ${classes} `}>
      {showCategory
      && <MLBoxMessage
        boxTitle={ {
          label: `Resultado de ${complementType}`,
          value: category.name
        }}
      >
        <p>{category[keyDescription]}</p>
      </MLBoxMessage>
      }

      <Fragment
      >
        {children}
      </Fragment>

    </div>

  );
};
ContainerCategory.propTypes = {
  category: PropTypes.object,
  children: PropTypes.element,
  type: PropTypes.oneOf(['objective', 'skill']),
  showCategory: PropTypes.bool,
  keyDescription: PropTypes.string,
  classes: PropTypes.string
};

ContainerCategory.defaultProps = {
  category: {},
  children: <></>,
  type: 'skill',
  showCategory: true,
  keyDescription: 'description',
  classes: ''
};

export default ContainerCategory;

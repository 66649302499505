import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import '../../../static/styles/shared/MLModal/ml-modal.less';
import getClassName from '../../../helpers/strings/getClassName';

const MLModal = forwardRef((props, ref) => {
  const {
    title,
    visible,
    closable,
    closeModal,
    closeIcon,
    className,
    fullScreen,
    footer,
    maskClosable,
    wrapClassName
  } = props;

  const [prevScrollY, setScrollY] = useState(0);

  const onCancel = (e) => (closable || e.key !== 'Escape') && closeModal();

  useEffect(() => {
    if (fullScreen) {
      if (visible) {
        setScrollY(window.scrollY);
        window.scrollTo(0, 0);
      } else if (typeof visible === 'boolean') {
        window.scrollTo(0, prevScrollY);
      }
    }
  }, [visible]);

  return (
    <Modal {...{
      ...(fullScreen ? null : { width: '45%' }),
      ...(closeIcon && { closeIcon }),
      title,
      visible,
      onCancel,
      closable,
      maskClosable,
      wrapClassName: getClassName(fullScreen ? 'ml-antd-modal-fullscreen-wrap' : 'ml-modal-wrap', wrapClassName),
      className: `${fullScreen ? 'ml-antd-modal-fullscreen' : 'ml-modal'} ${className}`,
      destroyOnClose: true,
      footer,
      ref
    }}
    >
      {props.children}
    </Modal>
  );
});

MLModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  className: PropTypes.string,
  closeIcon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool
  ]),
  closable: PropTypes.bool,
  fullScreen: PropTypes.bool,
  footer: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  maskClosable: PropTypes.bool,
  wrapClassName: PropTypes.string
};

MLModal.defaultProps = {
  title: '',
  visible: false,
  closeModal: () => {},
  className: '',
  closeIcon: <i className="icon-ml-close"/>,
  closable: true,
  fullScreen: false,
  footer: null,
  maskClosable: true,
  wrapClassName: ''
};

export default MLModal;

export const actionTypes = {
  LIKE_POST: 'LIKE_POST',
  LIKE_POST_SUCCESS: 'LIKE_POST_SUCCESS',
  LIKE_POST_ERROR: 'LIKE_POST_ERROR',
  UN_LIKE_POST: 'UN_LIKE_POST',
  UN_LIKE_POST_SUCCESS: 'UN_LIKE_POST_SUCCESS',
  UN_LIKE_POST_ERROR: 'UN_LIKE_POST_ERROR'
};

export const likesPosts = (variables) => ({
  type: actionTypes.LIKE_POST,
  variables
});

export const likePostExito = (payload) => ({
  type: actionTypes.LIKE_POST_SUCCESS,
  payload
});

export const likePostError = (error) => ({
  type: actionTypes.LIKE_POST_ERROR,
  error
});

// Delete LIKES
export const unLikepPost = (variables) => ({
  type: actionTypes.UN_LIKE_POST,
  variables
});

export const unLikepPostExito = (payload) => ({
  type: actionTypes.UN_LIKE_POST_SUCCESS,
  payload
});

export const unLikepPostError = (error) => ({
  type: actionTypes.UN_LIKE_POST_ERROR,
  error
});

import React from 'react';
import PropTypes from 'prop-types';
import { completePath } from '../../../helpers/strings';

const Question = (props) => {
  const {
    question, indexEmoticon, numberEmoticon, onClick
  } = props;
  const isAnswerSelected = question.value === (indexEmoticon + 1).toString();
  return (
    <div
      className='d-flex flex-column justify-content-center align-items-center'
    >
      <div
        className={isAnswerSelected ? 'img-active' : 'img-inactive'}
      >
        <img
          onClick={onClick}

          className="img-fluid" src={completePath(`web/mi-evolucion/servicios/inicio/emoticones-evolucionaTEST/${indexEmoticon + 1}.png`)} alt="" />
      </div>

      <div className={`opinion-number ${isAnswerSelected ? 'bg-primary' : ''} `}>
        <span className={`${isAnswerSelected ? 'text-active' : ''} `}>{numberEmoticon}</span>
      </div>

    </div>
  );
};
const RadioInput = (props) => {
  const { question, onClick } = props;
  return (

    <div className='box-radio'>
      <p className='text-question'>{question.name}</p>
      <div className="container-emoticons">

        <p className='text-lower-option'>{question.lowerOptionText}</p>
        <div className='box-emoticons'>

          <div className='line' />
          <div className='emoticons'>
            {question.options && question.options.map((numberEmoticon, indexEmoticon) => (
              <Question
                key={numberEmoticon}
                {...{
                  question,
                  indexEmoticon,
                  numberEmoticon,
                  onClick: () => {
                    onClick(indexEmoticon);
                  }
                }
                }
              />
            ))
            }

          </div>
        </div>

        <p className='text-higher-option'>{question.higherOptionText}</p>
        { question.lowerOptionText && question.higherOptionText
        && <div className='container-text-options'>
          <p>{question.lowerOptionText}</p>
          <p>{question.higherOptionText}</p>
        </div>
        }
      </div>
    </div>
  );
};

RadioInput.propTypes = {
  question: PropTypes.object,
  onClick: PropTypes.func
};

RadioInput.defaultProps = {
  questions: {},
  onClick: () => {}
};
export default RadioInput;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ModalMessage from '../../../shared/ModalMessage';
import Evaluations from './Evaluations';
import '../../../../static/styles/mievolucion/myPerformance/performanceEvaluation.less';
import { fullCapitalizeFormat } from '../../../../helpers/strings';
import { customFormatDateCard } from '../../../../helpers/dates';
import Profile from '../../../shared/Headers/Profile';

const PerformanceEvaluation = () => {
  const [modalMessage, setModalMessageInfo] = useState({
    visible: false,
    title: '',
    message: '',
    icon: '',
    type: ''
  });

  const closeModal = () => {
    setModalMessageInfo({
      visible: false,
      message: '',
      title: '',
      type: 'success'
    });
  };

  const {
    dateOld, position
  } = useSelector((state) => state.userReducer.data);

  const GetProfile = () => {
    const listHeader = [
    ];
    if (dateOld) {
      listHeader.push({
        label: 'Ingreso',
        value: customFormatDateCard(dateOld, 'DD/MM/YYYY')
      });
    }

    listHeader.push(
      { label: 'Cargo actual', value: fullCapitalizeFormat(position) }
    );

    return (
      <Profile
        listHeader={listHeader}
      />
    );
  };

  return (
    <div className='container-my-evaluations'>
      {modalMessage.visible
      && <ModalMessage
        {...modalMessage}
        closeModal={closeModal}
      />
      }
      <Evaluations {
        ...{
          GetProfile
        }
      }
      />
    </div>
  );
};

export default PerformanceEvaluation;

import { actionTypes } from '../actions/reactionsMural';

export const initialState = {
  reactions: {}
};

function reducer(state = initialState, action) {
  const { payload, variables } = action;
  switch (action.type) {
  case actionTypes.LIKE_POST:
  case actionTypes.UN_LIKE_POST:

    return {
      ...state,
      reactions: {
        ...state.reactions,
        [variables.postId]: {
          loading: true
        }
      }
    };
  case actionTypes.LIKE_POST_SUCCESS:
  case actionTypes.UN_LIKE_POST_SUCCESS: {
    const post = payload.result;

    return {
      ...state,
      reactions: {
        ...state.reactions,
        [post._id]: {
          loading: false,
          data: post
        }
      }
    };
  }
  case actionTypes.LIKE_POST_ERROR:
  case actionTypes.UN_LIKE_POST_ERROR: {
    const { result } = payload;

    return {
      ...state,
      reactions: {
        ...state.reactions,
        [result._id]: {
          loading: false,
          error: true
        }
      }
    };
  }
  default:
    return state;
  }
}

export default reducer;

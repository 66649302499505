import { lazy } from 'react';
import home from './home';
import performance from './performance';
import potential from './potential';
import faq from './faq';

const MyEvolutionTeamEvaluations = lazy(() => import('../../../../../containers/myTeam/myEvolution/Evaluations'));

export default [
  {
    auth: ['MY_TEAM_MY_EVOLUTION_EVALUATIONS', 'MY_TEAM_MY_EVOLUTION_EVALUATIONS_VALIDATOR'],
    path: '/mi-equipo/mi-evolucion/evaluaciones',
    section: 'Mi Equipo - Mi Evolución - Evaluaciones',
    component: MyEvolutionTeamEvaluations,
    subRoutes: [
      ...home,
      ...performance,
      ...potential,
      ...faq
    ]
  }
];

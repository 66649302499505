import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');
const REDIRECT_URI = getEnvVar('REACT_APP_PINGFED_AUTH_REDIRECT_URI');

export const loginAPIToken = ({ code, deviceInfo }) => axios().post(`${BFF_ENDPOINT}/pingfed/auth`, {
  authorizationCode: code,
  redirect: Buffer.from(REDIRECT_URI, 'utf-8').toString('base64'),
  deviceInfo
});

export const logOut = () => axios().post(`${BFF_ENDPOINT}/user/logout`);

/**
 * Este método no se está usando actualmente.
 * @param {*} params
 */
export const updateProfileAPI = (params) => axios()
  .post(`${BFF_ENDPOINT}/user/update`, {
    rut: params.rut,
    params: params.data
  })
  .then((resp) => resp.data.response);

export const updateMisDatosAPI = ({ data }) => axios()
  .post(`${BFF_ENDPOINT}/user/usermetadata/update`, data)
  .then((resp) => resp.data);

export const getUserAPI = () => axios()
  .get(`${BFF_ENDPOINT}/user/me`)
  .then((resp) => resp.data);

export const encodeRedirectURI = () => {
  const buff = Buffer.from(REDIRECT_URI, 'utf-8');
  return buff.toString('base64');
};

export const getBenefitsResumeAPI = () => axios().get(`${BFF_ENDPOINT}/user/own/benefits/resume`);

export const updatePushTokenAPI = ({ subscription }) => axios()
  .post(`${BFF_ENDPOINT}/user/pushToken`, { subscription })
  .then((resp) => resp.data);

export const userAdminCredentialsAPI = () => axios()
  .post(`${BFF_ENDPOINT}/oauth2/v2/token/useradmincredentials`)
  .then((resp) => resp.data);

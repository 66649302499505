import React from 'react';
import PropTypes from 'prop-types';
import '../../../static/styles/shared/MLOpinion/styles.less';
import RadioInput from './RadioInput';
import TextAreaInput from './TextAreaInput';
import YesNoInput from './YesNoInput';

const Questions = (props) => {
  const {
    questionsAndAnswers,
    setAnswers
  } = props;

  return (
    <div>

      {questionsAndAnswers.map((question, indexQuestion) => (
        <div key={indexQuestion} className='box-questions'>
          { question.inputType === 'radio'
              && <RadioInput
                {...{
                  question,
                  onClick: (indexEmoticon) => {
                    const newAnswersAndQuestions = questionsAndAnswers.map(
                      (item, indexSetValueEmoticon) => (
                        {
                          ...item,
                          value: indexQuestion === indexSetValueEmoticon
                            ? (indexEmoticon + 1).toString()
                            : item.value

                        }
                      )
                    );
                    setAnswers(newAnswersAndQuestions);
                  }
                }}
              />
          }

          { question.inputType === 'textarea'
          && <TextAreaInput
            {
              ...{
                inputClassName: 'ml-textarea',
                question,
                onChange: (e) => {
                  const newAnswersAndQuestions = questionsAndAnswers.map(
                    (item, indexSetValueEmoticon) => (
                      {
                        ...item,
                        value: indexQuestion === indexSetValueEmoticon
                          ? e.target.value
                          : item.value
                      }
                    )
                  );
                  setAnswers(newAnswersAndQuestions);
                }
              }
            }/>
          }
          { question.inputType === 'yes-no'
              && (
                <YesNoInput
                  {...{
                    question,
                    onChange: (e) => {
                      const newAnswersAndQuestions = questionsAndAnswers.map(
                        (item, indexSetValueEmoticon) => (
                          {
                            ...item,
                            value: indexQuestion === indexSetValueEmoticon
                              ? e.target.value
                              : item.value

                          }
                        )
                      );
                      setAnswers(newAnswersAndQuestions);
                    }
                  }}/>
              )
          }

        </div>
      ))}
    </div>
  );
};

Questions.propTypes = {
  questions: PropTypes.array,
  sendOpinion: PropTypes.func,
  loading: PropTypes.bool,
  labelButton: PropTypes.string,
  message: PropTypes.any,
  error: PropTypes.any
};

Questions.defaultProps = {
  questions: [],
  sendOpinion: () => {},
  loading: false,
  labelButton: 'Enviar mi opinión',
  message: null,
  error: null
};
export default Questions;

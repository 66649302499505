import {
  isEqual, isEmpty, isObject, find, keys
} from 'lodash';

const authorized = ({
  auth, me, validateCredentials, showCredential, userValidator
}) => {
  const permissions = me?.permissions;

  const hasInvalidPermissions = () => !isEmpty(auth) && (
    Array.isArray(auth) ? !auth.find((a) => permissions[a]) : !permissions[auth]
  );

  const hasInvalidCredential = () => validateCredentials && !showCredential;

  const hasInvalidUser = () => !!find(userValidator, (value, key) => {
    if (isObject(value)) {
      const isIn = find(keys(value), (key2) => key2 === '$in');
      return isIn && !find(value.$in, (val) => val === (me && me[key]));
    }

    return !isEqual(me && me[key], value);
  });

  const isAuthorizated = !(hasInvalidPermissions() || hasInvalidCredential() || hasInvalidUser());

  return isAuthorizated;
};

export default authorized;

import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const SET_SHOW_INTRODUCTION = gql`
    mutation setIntroduction(
        $introduction: introductionInput!
        ) {
        setIntroduction(
            introduction: $introduction
        )
    }
`;

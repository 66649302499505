import React from 'react';
import { Collapse } from 'antd';
import PropTypes from 'prop-types';

import '../../../static/styles/shared/collapses/infoCollapse.less';

const { Panel } = Collapse;

export const MlArrow = () => (
  <i data-testid='ml-arrow' className="icon-ml-arrow" />
);

const InfoCollapse = ({
  children, data, forceRender, paneKey, defaultPaneKey, classes, bordered, onChange, extra
}) => (
  <Collapse
    defaultActiveKey={defaultPaneKey}
    onChange={onChange}
    className={`info-collapse ${classes}`}
    bordered={bordered}
    forceRender={forceRender}
  >
    <Panel
      header={<div className="info-collapse-title">{data.title}</div>}
      key={paneKey}
      extra={extra || <MlArrow />}
      showArrow={false}
      forceRender={true}
    >
      {data.child || children}
    </Panel>
  </Collapse>
);

InfoCollapse.propTypes = {
  data: PropTypes.object,
  paneKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultPaneKey: PropTypes.array,
  onChange: PropTypes.func,
  classes: PropTypes.string,
  bordered: PropTypes.bool,
  forceRender: PropTypes.bool
};

InfoCollapse.defaultProps = {
  data: { title: '', clild: null },
  paneKey: 0,
  defaultPaneKey: [],
  onChange: () => {},
  classes: '',
  bordered: true,
  forceRender: false
};

export default InfoCollapse;

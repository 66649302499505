import React from 'react';
import { STATUS_IDP, getLabelStatusIDP, setFormatDateIDP } from '../../myEvolution/miDesarrollo';
import MainBtn from '../../../components/shared/buttons/MainBtn';
import { humanize } from '../../strings';
import { stringFilter } from '../../table';

export const columnsMyTeamIDP = [
  {
    title: 'Colaborador',
    width: 200,
    dataIndex: 'fullName',
    key: '0',
    fixed: 'left',
    sorter: (a, b) => stringFilter(a, b, 'fullName'),
    sortDirections: ['descend', 'ascend'],
    render: (fullName) => (humanize(fullName))
  },
  {
    title: 'Cargo',
    dataIndex: 'position',
    key: '1',
    sorter: (a, b) => stringFilter(a, b, 'position'),
    sortDirections: ['descend', 'ascend'],
    width: 150,
    render: (position) => (humanize(position))
  },
  {
    title: 'Antiguedad Colaborador',
    dataIndex: 'antiquity',
    width: 150,
    key: '2',
    sorter: (a, b) => stringFilter(a, b, 'antiquity'),
    sortDirections: ['descend', 'ascend']
  },
  {
    title: 'Estado IDP',
    dataIndex: 'IDPStatus',
    key: '5',
    width: 120,
    sorter: (a, b) => stringFilter(a, b, 'IDPStatus'),
    sortDirections: ['descend', 'ascend'],
    render: (status) => (getLabelStatusIDP(status))
  },
  {
    title: 'Fecha Actualización IDP',
    dataIndex: 'IDPLastUpdated',
    key: '6',
    width: 120,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: 'Ver IDP',
    dataIndex: 'IDPFilePath',
    key: '7',
    width: 120,
    render: (filePath, record) => (
      <MainBtn {
        ...{
          onClick: () => { window.open(filePath, '_blank'); },
          disabled: record.IDPStatus !== STATUS_IDP.finished,
          text: 'Ver'
        }
      }>
      </MainBtn>
    )
  }
];

export const getValuesMyTeamIDP = (listUserIDP) => listUserIDP?.map(
  (user, i) => ({
    ...user,
    IDPLastUpdated: setFormatDateIDP(user?.IDPLastUpdated),
    key: i
  })
);

export const transformValuesReportIDP = (listIDPResponse) => listIDPResponse?.map(

  ({
    user,
    status,
    last_changes,
    filePath
  }) => ({
    fullName: user?.fullName || `${user?.name} ${user?.secondName} ${user?.fatherLastName}`,
    position: user?.position,
    antiquity: user?.antiquity,
    EDDResultLastYear: '-',
    IDPStatus: status,
    IDPLastUpdated: last_changes,
    IDPFilePath: filePath
  })

);

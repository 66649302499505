/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../static/styles/shared/MLSimpleCollapse.less';

const MLSimpleCollapse = (props) => {
  const {
    textTitleClasse,
    children,
    title,
    open,
    classes,
    baseClasses,
    labelHide,
    labelOpen,
    labelSeeMore,
    showSeeMoreAfterHideCollapse,
    showSeeMore: currentShowSeeMore
  } = props;
  const [isOpen, setIsOpen] = useState(open);
  const [showSeeMore, setShowSeeMore] = useState(currentShowSeeMore);

  const seeMore = showSeeMore && isOpen;

  return (

    <div className={`${baseClasses}`}>
      <div className={`ml-simple-collapse ${classes}`}
        onClick={() => {
          if (!isOpen && currentShowSeeMore) {
            setShowSeeMore(showSeeMoreAfterHideCollapse);
          }
          setIsOpen(!isOpen);
        }}>
        <span className={`text-title ${textTitleClasse}`}>{isOpen ? labelHide : labelOpen} {title}</span>
        <i className={`icon-ml-arrow icon-arrow icon-${isOpen ? 'open' : 'closed'}`} />
      </div>
      <div>
        <div
          className={`${seeMore ? 'ml-simple-collapse-compressed' : ''}`}>
          { children && (
            <div {...(isOpen ? null : { className: 'hidden' })}>
              {children}
            </div>
          )}
          { seeMore
      && <div className='box-opacity-see-more'/>
          }
        </div>

      </div>
      { seeMore
      && <div className={'ml-simple-collapse see-more-info'}
        onClick={() => setShowSeeMore(!showSeeMore)}>
        <span className='text-title'>{labelSeeMore}</span>
        <i className={'icon-ml-arrow icon-arrow icon-open'} />
      </div>
      }

    </div>
  );
};
MLSimpleCollapse.propTypes = {
  showSeeMore: PropTypes.bool,
  title: PropTypes.string,
  open: PropTypes.bool,
  classes: PropTypes.string,
  baseClasses: PropTypes.string,
  labelHide: PropTypes.string,
  labelOpen: PropTypes.string,
  labelSeeMore: PropTypes.string,
  showSeeMoreAfterHideCollapse: PropTypes.bool,
  textTitleClasse: PropTypes.string
};

MLSimpleCollapse.defaultProps = {
  showSeeMore: false,
  title: '',
  open: false,
  classes: '',
  baseClasses: '',
  labelHide: 'Ocultar',
  labelOpen: 'Ver',
  labelSeeMore: 'Ver más',
  showSeeMoreAfterHideCollapse: true,
  textTitleClasse: ''
};
export default MLSimpleCollapse;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import {
  actionTypes,
  createTicketSuccess,
  createTicketError,
  homeHelpSuccess,
  homeHelpError,
  addCommentSuccess,
  addCommentError,
  getFileError,
  getFileSuccess
} from '../actions/help';
import {
  addCommentAPI, createTicketAPI, homeHelpAPI, getFileAPI
} from '../api/help';

function* createTicketSagas(data) {
  try {
    const response = yield call(createTicketAPI, data.payload);
    yield put(createTicketSuccess(response));
  } catch (err) {
    yield put(createTicketError(err));
  }
}

function* addCommentSagas(data) {
  try {
    const response = yield call(addCommentAPI, data.payload);
    yield put(addCommentSuccess(response));
  } catch (err) {
    yield put(addCommentError(err));
  }
}

function* homeHelpSagas(req) {
  try {
    const { rut } = req;
    const response = yield call(homeHelpAPI, { rut });
    yield put(homeHelpSuccess(response));
  } catch (err) {
    yield put(homeHelpError(err));
  }
}

function* getFileSagas({ payload }) {
  const { id, title, type } = payload;
  try {
    const response = yield call(getFileAPI, { id, title, type });
    yield put(getFileSuccess(response));
  } catch (err) {
    yield put(getFileError(err));
  }
}

function* helpSagas() {
  yield all([
    takeLatest(actionTypes.CREATE_TICKET, createTicketSagas),
    takeLatest(actionTypes.ADD_COMMENT, addCommentSagas),
    takeLatest(actionTypes.HOME_HELP, homeHelpSagas),
    takeLatest(actionTypes.GET_FILE, getFileSagas)
  ]);
}

export default helpSagas;

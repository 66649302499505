import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import {
  getSectionFormAPI,
  postSectionFeedbackAPI
} from '../api/section';

import {
  actionTypes,
  getSectionFormSuccess,
  getSectionFormFailure,
  postSectionFeedbackSuccess,
  postSectionFeedbackFailure
} from '../actions/section';

import { failure as commonsFailure } from '../actions/commons';

es6promise.polyfill();

export function* getSectionForm() {
  try {
    const response = yield call(getSectionFormAPI);
    yield put(getSectionFormSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(
      statusCode === 401 ? commonsFailure({
        error,
        statusCode
      }) : getSectionFormFailure({
        error,
        statusCode
      })
    );
  }
}

export function* postSectionFeedback(data) {
  try {
    const response = yield call(postSectionFeedbackAPI, data.data);
    yield put(postSectionFeedbackSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(
      statusCode === 401 ? commonsFailure({
        error,
        statusCode
      }) : postSectionFeedbackFailure({
        error,
        statusCode
      })
    );
  }
}

function* sectionSagas() {
  yield all([
    takeLatest(actionTypes.GET_SECTION_FORM, getSectionForm),
    takeLatest(actionTypes.POST_SECTION_FEEDBACK, postSectionFeedback)
  ]);
}

export default sectionSagas;

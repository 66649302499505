const getEmptyStateProps = (can, bossParams) => {
  const selectedUserName = bossParams?.selectedUser?.name;
  const props = { img: 'web/mi-evolucion/servicios/inicio/evolucionatest-emptystate.png' };

  if (can.userCentralizadoWithoutObjectives) {
    props.description = `${bossParams ? 'Sus' : 'Tus' } objetivos aún no han sido cargados en el sistema. Ingresa nuevamente más adelante.`;
    return props;
  }
  if (can.showWithFixedObjectives) {
    return props;
  }

  if (!can.showWithPulses) {
    if (!can.showWithoutPulses) {
      props.description = 'Tus objetivos aún no han sido cargados en el sistema.<br/>Ingresa nuevamente más adelante';
    }
    return props;
  }

  if (can.notPassPerformance) {
    props.description = 'Debes tener tu propuesta de Desempeño cerrada para que tus reportes realicen las suyas.';
    props.button = {
      text: 'Ir a Mi Desempeño',
      route: '/mi-evolucion/mi-desempeño'
    };
    return props;
  }
  if (can.showReturnedMessage) {
    props.description = `La propuesta de ${selectedUserName} fué devuelta.`;
    return props;
  }
  if (can.showUserNotSentMessage) {
    props.description = `${selectedUserName} aún no realizó su propuesta de Desempeño.`;
    return props;
  }

  if (can.showReturnedMessage) {
    props.description = `${selectedUserName} está editando su propuesta de Desempeño.`;
    return props;
  }

  if (can.notStartPerformance) {
    props.description = 'Aún no están fijados los objetivos de tu jefatura.';
    return props;
  }

  return props.description ? props : null;
};

export default getEmptyStateProps;

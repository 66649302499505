import React from 'react';
import MLAccordion from './MLAccordion';
import getClassName from '../../../helpers/strings/getClassName';
import '../../../static/styles/shared/collapses/ml-collapsible-elements.less';

const MLCollapsibleElements = (props) => (
  <MLAccordion {...{
    ...props,
    bordered: false,
    className: getClassName('ml-collapsible-elements', props.className)
  }}/>
);

export default MLCollapsibleElements;

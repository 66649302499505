import { actionTypes } from '../actions/paying';

export const initialState = {
  paying: null,
  messages: {}
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_PAYING:
    return {
      ...state,
      ...{ paying: null, messages: {} }
    };
  case actionTypes.GET_PAYING_SUCCESS:

    return {
      ...state,
      ...{ paying: action.data, messages: action.data.messages }
    };

  case actionTypes.SEND_PAYING_SUCCESS:
    return {
      ...state,
      ...{ paying: { ...state.paying, ...action.data } }
    };

  case actionTypes.CLEAN_MESSAGE:
    return {
      ...state,
      ...{
        paying: { ...state.paying, message: null },
        error: null,
        messages: {}
      }
    };

  case actionTypes.FAILURE:
    return {
      ...state,
      ...{ error: action.error }
    };

  default:
    return state;
  }
}

export default reducer;

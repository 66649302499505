import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

const saveAnalyticAPI = (params) => axios()
  .post(`${BFF_ENDPOINT}/analytic/save`, params)
  .then((resp) => resp.data[0]);

export default saveAnalyticAPI;

import React, { useState } from 'react';
import { map, max, values } from 'lodash';
import PropTypes from 'prop-types';
import Item from './Item';
import '../../../static/styles/shared/sectionList.less';
import Error from '../Error';
import { useWindowWidthSize } from '../../../helpers/browser';
import { smallDevice } from '../../../helpers/deviceVariables';
import Loading from '../../../containers/loading';

const SectionList = (props) => {
  const {
    loading, srcList, title, openCard, error, reload,
    classes = '',
    isListHorizontal = false,
    category,
    permissions
  } = props;
  const [textSpaces, setTextSpaces] = useState({});
  let textSpacesBuffer = {};

  const width = useWindowWidthSize();

  const getTextSpace = (key, height) => {
    textSpacesBuffer = { ...textSpacesBuffer, [key]: height };
    setTextSpaces(textSpacesBuffer);
  };

  return (

    <div className={`section-list ${classes}`}>
      {title ? <h4>{title}</h4> : null}
      {loading && (
        <Loading />
      )}
      {error ? <Error reload={reload} /> : (
        <div gutter={24}
          className={
            `row items-section-list ${isListHorizontal && width <= smallDevice && 'scrolling-wrapper  flex-row flex-nowrap'}`
          }
        >
          {
            map(srcList, (item, index) => {
              if (item.slug === 'bienvenida-operador-de-tienda'
                && !permissions.OPERATION_ACADEMY_WELCOME_STORE_OPERATOR) return null;
              return (
                <Item
                  category={category}
                  subCategory={title}
                  isListHorizontal={isListHorizontal && width <= smallDevice}
                  key={index}
                  {...{
                    index, item, openCard, getTextSpace, textSpace: max(values(textSpaces))
                  }}
                />
              );
            })
          }
        </div>
      )}
    </div>
  );
};

SectionList.propTypes = {
  title: PropTypes.string,
  srcList: PropTypes.array,
  loading: PropTypes.bool,
  openCard: PropTypes.func,
  error: PropTypes.bool,
  reload: PropTypes.func,
  category: PropTypes.string.isRequired
};

SectionList.defaultProps = {
  title: '',
  srcList: [],
  loading: false,
  openCard: null,
  error: false,
  reload: () => { },
  category: 'Sin categoría'
};

export default SectionList;

import { Col, Row, Carousel } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MainBtn from '../../shared/buttons/MainBtn';

const BehaviorItem = ({ behavior, columnSize }) => (
  <Col span={columnSize} className='behavior-item'>
    <div className='behavior-item-title'><span className='text-bold-secundary'>{behavior.title}</span></div>
    <div className='behavior-item-content'>
      {behavior.description}
    </div>
  </Col>
);

export const SkillDetail = ({
  skill, columns = 0, showCardIndex = true, className = ''
}) => {
  let columnSize;
  if (!columns) {
    columnSize = skill.behaviors.length > 1 ? 24 / skill.behaviors.length : 24;
  } else {
    columnSize = 24 / columns;
  }
  return (showCardIndex && <div className={`containter-skill-detail ${className}`}>
    <div className='behavior-title'>
      <span className='text-bold'>Competencia:</span>
      <span className='text-bold-secundary'>{skill.name}</span>
    </div>
    <div className='behavior-detail'>
      <span className='text-bold'>Conductas esperadas del rol:</span>
      <Row className='behavior-list'>
        {
          skill.behaviors.map((behavior, i) => (
            <BehaviorItem key={skill.name + i} behavior={behavior} columnSize={columnSize} />
          ))
        }
      </Row>
    </div>
  </div>
  );
};

export const SkillDetailMobile = ({ skill, className }) => (
  <div className='container-skills-4c px-0'>
    <div className={`containter-skill-detail ${className}`}>
      <div className='behavior-title'>
        <span className='text-bold'>Competencia:</span>
        <span className='text-bold-secundary'>{skill.name}</span>
      </div>
      <div className='behavior-detail'>
        <span className='text-bold'>Conductas esperadas del rol:</span>
        <Carousel className='behavior-list behavior-list-mobile'>
          {
            skill.behaviors.map((behavior, i) => (
              <BehaviorItem key={skill.name + i} behavior={behavior} columnSize={24} />
            ))
          }
        </Carousel>
      </div>
    </div>
  </div>
);

const BehaviorContent = ({ skills }) => {
  const buttonClass = ['first', 'second', 'thrid', 'fourth'];
  const [cardIndex, setCardINdex] = useState(0);

  const buttons = skills.map((skill, index) => (
    <Col key={index} span={6}>
      <MainBtn
        key={index}
        width="90%"
        text={skill.shortName}
        onClick={() => setCardINdex(index)}
        mlType={buttonClass[index % buttonClass.length]}
        className={index === cardIndex ? 'focus' : ''}
      />
    </Col>
  ));

  return (
    <div className='container-skills-4c'>
      <div className='text-center'> <span className='text-bold'>Mis Competencias</span></div>
      <Row className='container-buttons-skills'>
        {buttons}
      </Row>

      {
        skills.map((skill, index) => (
          <SkillDetail key={skill.name} skill={skill} showCardIndex={cardIndex === index} />
        ))
      }
    </div>
  );
};

BehaviorContent.propType = {
  skills: PropTypes.array
};

BehaviorContent.defaultProps = {
  skills: []
};

export default BehaviorContent;

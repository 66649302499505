import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { isEmpty, map } from 'lodash';
import { Form } from 'antd';
import InfoMessage from '../../../shared/InfoMessage';
import {
  DEFAULT_AREAS_DETAILS, AREA_DETAIL, MAX_AREAS_DETAILS, COMMON_VARIABLES_LIST
} from '../../../../helpers/myEvolution/interestsAndSkills';
import {
  FIND_AREAS_DETAIL
} from '../../../../graphql/mievolucion/interestsAndSkills/queries';
import { MY_EVOLUTION_CONTEXT } from '../../../../helpers/apollo';
import Areas from './Areas';
import { Button } from '../Form';
import MLError from '../../../shared/Error';
import Loader from '../../../Loader';

const context = MY_EVOLUTION_CONTEXT;

const AreasOfInterest = (props) => {
  const {
    form: {
      getFieldDecorator
    },
    updateData,
    loadingUpdate,
    interestAreas: initialInterestAreas
  } = props;

  const [interestAreas, setInterestAreas] = useState(
    isEmpty(initialInterestAreas) ? DEFAULT_AREAS_DETAILS : initialInterestAreas
  );
  const {
    loading: loadingListAreas,
    error: errorListAreas,
    data: dataListAreas,
    refetch: refetchListAreas
  } = useQuery(FIND_AREAS_DETAIL, {
    variables: COMMON_VARIABLES_LIST,
    context,
    fetchPolicy: 'network-only'
  });

  const { listOptionsinterestAreasDetail = [] } = dataListAreas || {};
  useEffect(() => {
    if (!isEmpty(initialInterestAreas)) {
      if (initialInterestAreas.length !== MAX_AREAS_DETAILS) {
        const complement = Array(
          MAX_AREAS_DETAILS - initialInterestAreas.length
        ).fill(AREA_DETAIL);
        const newInitialInterestAreas = initialInterestAreas.concat(complement);
        setInterestAreas(newInitialInterestAreas);
      } else {
        setInterestAreas(initialInterestAreas);
      }
    }
  }, [initialInterestAreas]);

  const saveData = (e) => {
    e.preventDefault();

    const areas = map(interestAreas, (item) => (item.area._id === 'OTHER' ? { newArea: item.area.name } : { area: item.area._id }));

    updateData({ variables: { input: { interestAreas: areas } } });
  };
  if (loadingListAreas) {
    return <Loader />;
  }

  if (errorListAreas) {
    return (<MLError
      reload={() => refetchListAreas({ variables: COMMON_VARIABLES_LIST })}
    />);
  }

  return (
    <>
      <Form onSubmit={saveData}>
        <InfoMessage
          message='Ingresa las áreas de Walmart que son de tu interés.'
          classes='mb-4'
        />
        <Areas
          {...{
            getFieldDecorator,
            interestAreas,
            setInterestAreas,
            listOptionsinterestAreasDetail
          } }

        />
        <Button
          loading={loadingUpdate}
          label='Guardar mis Intereses laborales'
        />
      </Form>
    </>
  );
};

AreasOfInterest.propTypes = {
  form: PropTypes.object,
  loadingUpdate: PropTypes.bool,
  updateData: PropTypes.func,
  coursesOrTraining: PropTypes.array,
  interestAreas: PropTypes.array
};

AreasOfInterest.defaultProps = {
  form: () => {},
  loadingUpdate: false,
  updateData: () => {},
  interestAreas: []
};
export default AreasOfInterest;

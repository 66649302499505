import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { getContentAPI } from '../api/nuestraCompania';
import { actionTypes, getContentSuccess, failure } from '../actions/nuestraCompania';

es6promise.polyfill();

export function* getContent() {
  const response = yield call(getContentAPI);

  if (response.status === 200) {
    yield put(getContentSuccess(response.data));
  } else {
    yield put(failure(response));
  }
}

function* nuestraCompaniaSagas() {
  yield all([
    takeLatest(actionTypes.GET_NUESTRA_COMPANIA_CONTENT, getContent)
  ]);
}

export default nuestraCompaniaSagas;

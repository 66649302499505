import {
  differenceBy, intersection, has, orderBy, isEmpty, omit
} from 'lodash';
import { fullCapitalizeFormat } from '../../strings';

export const initialState = {
  editing: false,
  calibrationId: '',
  editingUsersToAdd: [],
  editingUsersToDrop: [],
  usersSelected: new Map(),
  step: 0,
  performanceCategoryList: [],
  skills: [],
  calibration: null,
  initialUsersInCalibration: [],
  calibrationTable: [],
  skillScores: {},
  finalSkillScores: {},
  objetiveScores: {},
  finalScores: {},
  showUserLoading: false,
  mainModal: {
    details: '',
    visible: false
  },
  formFields: {
    groupName: '',
    calibrationMeeting: null
  },
  formError: {
    groupName: false,
    calibrationMeeting: false
  },
  modalState: {
    visible: false,
    title: '',
    type: '',
    message: '',
    toBegining: false
  }
};

const handleUsersEditing = ({
  oldState, newState, initialUsersInCalibration, editingUsersToAdd, editingUsersToDrop
}) => {
  const prevArray = Array.from(oldState || new Map(), ([, value]) => (value));
  const currentArray = Array.from(newState, ([, value]) => (value));
  const currentMayorThanPrev = currentArray.length >= prevArray.length;
  const dif = currentMayorThanPrev
    ? differenceBy(currentArray, prevArray, 'rut')
    : differenceBy(prevArray, currentArray, 'rut');
  const difRut = dif.map((v) => v.rut);
  const inter = intersection(initialUsersInCalibration, difRut);
  const switchCondition = `${currentMayorThanPrev}|${inter.length > 0}`;
  switch (switchCondition) {
  case 'true|false':
    return {
      editingUsersToAdd: dif[0] ? [...editingUsersToAdd, dif[0].rut] : [],
      usersSelected: newState
    };
  case 'false|false':
    return {
      editingUsersToAdd: dif[0] ? [...editingUsersToAdd.filter((v) => v !== dif[0].rut)] : [],
      usersSelected: newState
    };
  case 'false|true':
    return {
      editingUsersToDrop: dif[0] ? [...editingUsersToDrop, dif[0].rut] : [],
      initialUsersInCalibration: initialUsersInCalibration.filter((v) => v !== dif[0].rut),
      usersSelected: newState
    };
  case 'true|true':
    return {
      editingUsersToDrop: dif[0] ? [...editingUsersToDrop.filter((v) => v !== dif[0].rut)] : [],
      usersSelected: newState
    };
  default:
    break;
  }

  return null;
};

const getOrderSkills = (skillEvaluated) => skillEvaluated.sort((a, b) => {
  const firstValue = a.order;
  const secondValue = b.order;
  const secondOrder = ((firstValue > secondValue) ? 1 : 0);
  return ((firstValue < secondValue) ? -1 : secondOrder);
});

export function reducer(state, action) {
  switch (action.type) {
  case 'set-modalState':
    return {
      ...state,
      modalState: action.payload
    };
  case 'set-mainModal':
    return {
      ...state,
      mainModal: action.payload
    };
  case 'clear-editingUsersAddDrop':
    return {
      ...state,
      editingUsersToAdd: initialState.editingUsersToAdd,
      editingUsersToDrop: initialState.editingUsersToDrop
    };
  case 'set-editing':
    return {
      ...state,
      editing: action.payload.editing,
      calibrationId: action.payload.calibrationId
    };
  case 'step':
    return {
      ...state,
      step: action.payload
    };
  case 'step-forward':
    return {
      ...state,
      step: state.step + 1
    };
  case 'step-backward':
    return {
      ...state,
      step: state.step - 1
    };
  case 'set-performanceCategoryList':
    return {
      ...state,
      performanceCategoryList: action.payload
    };
  case 'set-form-fields':
    return {
      ...state,
      formFields: {
        ...state.formFields,
        [action.payload.field]: action.payload.value
      }
    };
  case 'set-form-errors':
    return {
      ...state,
      formError: action.payload
    };
  case 'set-calibration':
    return {
      ...state,
      calibration: action.payload.calibration,
      skills: getOrderSkills(action.payload.calibration.skillsEvaluated),
      initialUsersInCalibration: action.payload.calibration.calibrations.map(
        (v) => v.evaluationId.evaluated.rut
      ),
      formFields: {
        groupName: action.payload.calibration.groupName,
        calibrationMeeting: action.payload.calibration.calibrationMeeting
      },
      editingUsersToAdd: initialState.editingUsersToAdd,
      editingUsersToDrop: initialState.editingUsersToDrop,
      usersSelected: new Map(action.payload.calibration.calibrations.map((v) => {
        const { evaluated } = v.evaluationId;
        return {
          _id: evaluated._id,
          rut: evaluated.rut,
          name: evaluated.name,
          fatherLastName: evaluated.fatherLastName,
          motherLastName: evaluated.motherLastName || '',
          position: evaluated.position
        };
      }).map((v) => ([v.rut, v])))
    };
  case 'set-calibrationTable':
    return {
      ...state,
      calibrationTable: action.payload.table,
      skillScores: action.payload.skillScores,
      finalSkillScores: action.payload.finalSkillScores,
      objetiveScores: action.payload.objetiveScores,
      finalScores: action.payload.finalScores
    };
  case 'reset':
    return {
      ...initialState,
      mainModal: state.mainModal,
      step: 0,
      skills: state.skills,
      performanceCategoryList: state.performanceCategoryList
    };
  case 'set-usersSelected':
    return {
      ...state,
      usersSelected: action.payload,
      ...handleUsersEditing({
        oldState: state.usersSelected,
        newState: action.payload,
        initialUsersInCalibration: state.initialUsersInCalibration,
        editingUsersToAdd: state.editingUsersToAdd,
        editingUsersToDrop: state.editingUsersToDrop
      })
    };
  case 'set-updateByFile':
    return {
      ...state,
      usersSelected: action.payload,
      editingUsersToAdd: [...action.payload.keys()],
      editingUsersToDrop: [...state.usersSelected.keys()]
    };
  default:
    throw new Error();
  }
}

export const getAverage = (array) => {
  const skillSum = array
    .reduce((acum, current) => parseFloat(parseFloat(acum) + parseFloat(current)));
  return (skillSum / array.length).toFixed(2);
};

export const scoreConversion = {
  3: 0,
  2: 1,
  1: 2
};

const FIRST_LEVEL_PERFORMANCE_MAX_SCORE = 1.60;
const SECOND_LEVEL_PERFORMANCE_MAX_SCORE = 2.60;

/*
< 1.59    Desempeño por desarrollar
1.6 - 2.59  Desempeño exitoso
> 2.6       Desempeño exelencia
*/
export const getScoreConversion = (score) => {
  const floatScore = parseFloat(score);
  if (floatScore) {
    if (floatScore < FIRST_LEVEL_PERFORMANCE_MAX_SCORE) {
      return 2;
    } if (floatScore >= FIRST_LEVEL_PERFORMANCE_MAX_SCORE
      && floatScore < SECOND_LEVEL_PERFORMANCE_MAX_SCORE) {
      return 1;
    } if (floatScore >= SECOND_LEVEL_PERFORMANCE_MAX_SCORE) {
      return 0;
    }
  }
  return 2;
};

export const iconClassNameEDP = {
  0: 'hight-potential-donut',
  1: 'expert-donut',
  2: 'valuable-resourse-donut',
  3: 'talent-risk-donut'
};

export const getScoreByXPosition = (object, value) => parseInt(
  Object.keys(object).find((key) => object[key] === value), 0
);

const distributePositions = ({
  x, y, calibration, user
}) => {
  switch (true) {
  // Covers skill columns
  case y >= 3
  && x === scoreConversion[calibration.skills[y - 3].categorySelected?.score]:
    return { user, saveSkillScore: true };
  // Covers skill final skill category column
  case y === 2 && x === getScoreConversion(calibration.finalCategories.skills.score):
    return { user, saveFinalSkillScore: true };
  // Covers skill final object category column
  case y === 1 && x === getScoreConversion(calibration.finalCategories.objectives.score):
    return { user, saveFinalObjectCategory: true };
  // Covers final category column
  case y === 0 && x === getScoreConversion(calibration.finalCategories.evaluation.score):
    return { user, saveFinalCategory: true };
  default:
    return 'empty';
  }
};

const getSkillsOrder = (skills, skillId) => skills.findIndex((v) => v._id === skillId);

export const initializeTable = (calibrationReducer) => {
  const { calibrations } = calibrationReducer.calibration;
  const { skills, performanceCategoryList } = calibrationReducer;
  const rowsLength = performanceCategoryList.length;
  const columnsLength = skills.length + 3;
  const table = [];
  const skillScores = {};
  const finalSkillScores = {};
  const objetiveScores = {};
  const finalScores = {};
  calibrations.forEach((calibration) => {
    const { evaluated } = calibration.evaluationId;
    skillScores[evaluated.rut] = [];
    for (let x = 0; x < rowsLength; x += 1) {
      for (let y = 0; y < columnsLength; y += 1) {
        if (!table[x]) table[x] = [];
        if (!table[x][y]) table[x][y] = [];
        const user = {
          label: fullCapitalizeFormat(`${evaluated.name} ${evaluated.fatherLastName} ${evaluated.motherLastName || ''}`),
          id: evaluated.rut,
          currentX: x,
          currentY: y,
          type: 'calibration',
          ...(y === 1 && { className: 'shadowed', cantDrag: true })
        };
        if (y > 2) {
          const sk = skills[y - 3];
          const element = calibration.skills.find((v) => v._id === sk._id);
          const realX = getScoreConversion(element?.categorySelected?.score);
          if (realX === x) {
            if (!table[realX]) table[realX] = [];
            if (!table[realX][y]) table[realX][y] = [];
            table[realX][y] = [...table[realX][y], user];
          }
        }
        const toAdd = distributePositions({
          user, calibration, x, y, skills
        });
        if (toAdd !== 'empty') {
          if (has(toAdd, 'saveSkillScore')) {
            const skillScoreObj = calibration.skills[toAdd.user.currentY - 3];
            const skillScore = skillScoreObj.categorySelected.score;
            const skillOrder = getSkillsOrder(skills, skillScoreObj._id);
            skillScores[evaluated.rut][skillOrder] = skillScore;
          }
          if (has(toAdd, 'saveFinalSkillScore')) {
            finalSkillScores[evaluated.rut] = calibration.finalCategories.skills.score
            || calibration.finalScore.skills;
            table[x][y] = [...table[x][y], user];
          }
          if (has(toAdd, 'saveFinalObjectCategory')) {
            objetiveScores[evaluated.rut] = calibration.finalCategories.objectives.score
            || calibration.finalScore.objectives;
            table[x][y] = [...table[x][y], user];
          }
          if (has(toAdd, 'saveFinalCategory')) {
            finalScores[evaluated.rut] = calibration.finalCategories.evaluation.score
            || calibration.finalScore.evaluation;
            table[x][y] = [...table[x][y], user];
          }
        }
      }
    }
  });
  return {
    table, skillScores, finalSkillScores, objetiveScores, finalScores
  };
};

export const initializeTableEDP = ({ calibration }) => {
  const { calibrations, edpResultsList } = calibration;
  const list = orderBy(edpResultsList, ['order'], ['asc']);
  const table = list.map((listValue, x) => {
    const calibrationRegisters = calibrations.filter((calibrationValue) => (
      listValue._id === calibrationValue.potentialSkillResultId
    ));
    if (calibrationRegisters.length) {
      return [calibrationRegisters.map((v) => {
        const { evaluated } = v.evaluationId;
        return {
          label: fullCapitalizeFormat(`${evaluated.name} ${evaluated.fatherLastName}`),
          currentX: x,
          currentY: 0,
          id: `${evaluated.rut}`,
          type: 'potencial',
          cantDrag: false
        };
      })];
    }
    return [[]];
  });
  return table;
};

const getPerformanceByScore = (performances, score) => {
  let realScore = 1;
  const floatScore = parseFloat(score);
  if (floatScore < FIRST_LEVEL_PERFORMANCE_MAX_SCORE) {
    realScore = 1;
  } if (floatScore >= FIRST_LEVEL_PERFORMANCE_MAX_SCORE
    && score < SECOND_LEVEL_PERFORMANCE_MAX_SCORE) {
    realScore = 2;
  } if (floatScore >= SECOND_LEVEL_PERFORMANCE_MAX_SCORE) {
    realScore = 3;
  }
  const result = performances.find((performance) => performance.score === realScore);
  return result._id;
};

export const shapeDataToSend = ({
  calibration, status, finalScores, skillScores,
  skills, performanceCategoryList,
  add, drop, formFields, finalSkillScores, objetiveScores
}) => {
  const toSend = {
    _id: calibration._id,
    status,
    ...formFields,
    ...(!add && !drop && { calibrations: [] }),
    ...(add && { add }),
    ...(drop && { drop })
  };
  const findPerformanceSkill = (score) => performanceCategoryList.find((v) => v.score === score);
  Object.keys(finalScores).forEach((rut) => {
    const calibrationToEvaluate = calibration.calibrations.find(
      (v) => v.evaluationId.evaluated.rut === rut
    );
    const evolutionSkills = skillScores[rut].map((v, i) => ({
      skillId: skills[i]._id,
      performanceCategoryId: findPerformanceSkill(v)._id
    }));
    const calibrationToSend = {
      evaluationId: calibrationToEvaluate.evaluationId._id,
      skills: evolutionSkills,
      finalCategories: {
        objectivesId: getPerformanceByScore(performanceCategoryList, objetiveScores[rut]),
        skillsId: getPerformanceByScore(performanceCategoryList, finalSkillScores[rut]),
        evaluationId: getPerformanceByScore(performanceCategoryList, finalScores[rut])
      },
      finalScore: {
        evaluation: parseFloat(finalScores[rut])
          || parseFloat(calibrationToEvaluate.finalScore.evaluation),
        objectives: parseFloat(objetiveScores[rut])
          || parseFloat(calibrationToEvaluate.finalScore.objectives),
        skills: parseFloat(finalSkillScores[rut])
          || parseFloat(calibrationToEvaluate.finalScore.skills)
      },
      potentialEvaluationId: calibrationToEvaluate.potentialEvaluationId,
      potentialSkillResultId: calibrationToEvaluate.potentialSkillResultId
    };
    if (toSend.calibrations) {
      toSend.calibrations.push(calibrationToSend);
    } else {
      toSend.calibrations = [calibrationToSend];
    }
  });
  return toSend;
};

const getSkillsInput = (skills) => {
  const result = [];
  skills.forEach((skill) => result.push({
    skillId: skill._id, performanceCategoryId: skill.categorySelected._id
  }));
  return result;
};

const getFinalCategoriesInput = (finalCategories) => ({
  objectivesId: finalCategories.objectives._id,
  skillsId: finalCategories.skills._id,
  evaluationId: finalCategories.evaluation._id
});

export const shapeDataToSendEDP = ({ calibration, table }) => {
  const { edpResultsList, calibrations } = calibration;
  const orderedList = orderBy(edpResultsList, ['order'], ['asc']);
  const resp = [];
  table.forEach((el, x) => {
    const row = table[x][0];
    if (row.length) {
      row.forEach((element) => {
        const ca = calibrations.find((v) => v.evaluationId.evaluated.rut === element.id);
        const { evaluationId: { evaluated }, potentialEvaluationId } = ca;
        if (evaluated.rut === element.id) {
          delete ca.__typename;
          delete ca._id;
          resp.push({
            ...ca,
            evaluationId: ca.evaluationId._id,
            skills: getSkillsInput(ca.skills),
            finalCategories: getFinalCategoriesInput(ca.finalCategories),
            finalScore: omit(ca.finalScore, '__typename'),
            potentialEvaluationId,
            potentialSkillResultId: orderedList[x]._id
          });
        }
      });
    }
  });
  return resp;
};

export const removeObjectInArray = (array, obj) => array.filter((item) => item.id !== obj.id);

export const cleanColumn = ({ table, y, item }) => {
  for (let x = 0; x <= table.length - 1; x += 1) {
    const exists = table[x][y].some((v) => v.id === item.id);
    if (exists) { table[x][y] = removeObjectInArray(table[x][y], item); }
  }
  return table;
};

export const modifyTablePositions = ({
  table, item, currentY, currentX, newX, newY
}) => {
  const newTable = [...table];
  const leavingPosition = [...table[currentX][currentY]];
  const arraivingPosition = [...table[newX][newY]];
  const newLeavingPositionObjects = removeObjectInArray(leavingPosition, item);
  newTable[currentX][currentY] = newLeavingPositionObjects;

  const exists = arraivingPosition.some((v) => v.id === item.id);
  let newArraivingPositionObjects = newTable[newX][newY];
  if (exists) {
    newArraivingPositionObjects = removeObjectInArray(arraivingPosition, item);
  }
  newArraivingPositionObjects = [
    ...newArraivingPositionObjects,
    { ...item, currentX: newX, currentY: newY }
  ];
  newTable[newX][newY] = newArraivingPositionObjects;
  return newTable;
};

export const capitalizeFieldValuesForTable = (fieldsToCapitalize, array) => {
  const newArray = array.map((item, i) => {
    const changeIt = fieldsToCapitalize.reduce((acum, current) => ({
      ...acum,
      [current]: fullCapitalizeFormat(item[current])
    }), {});
    if (!isEmpty(changeIt)) {
      const nItem = {
        ...item,
        key: i,
        ...changeIt
      };
      return nItem;
    }
    return { ...item, key: i };
  });
  return newArray;
};

/* eslint-disable import/prefer-default-export */
import {
  capitalize
} from 'lodash';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');
export const formatDateBenefitRequest = (date) => capitalize(moment(new Date(date)).utc().format('DD MMM YYYY')
  .replace('.', ' '));

export const labelStatus = (status) => {
  status = status || '';
  switch (status.toLowerCase()) {
  case 'finished':
  case 'completed':
    return 'Aprobada';
  case 'pending':
    return 'Pendiente';
  case 'incomplete':
    return 'Incompleto';
  case 'canceled':
    return 'Anulada';
  default:
    return '';
  }
};
export const statusClasses = (status) => {
  status = status || '';
  switch (status.toLowerCase()) {
  case 'finished':
  case 'completed':
  case 'communicated':
    return 'icon-me-check success-icon-color';
  case 'pending': case 'created': case 'approved':
    return 'icon-me-feedback-pending pending-icon-color';
  case 'canceled':
    return 'icon-ml-anulada canceled-icon-color';
  case 'incomplete':
    return 'icon-ml-incompleto primary-icon-color';
  default:
    return 'icon-me-error rejected-icon-color';
  }
};

export const formatDateVacations = (date) => capitalize(moment(new Date(date)).utc().format('ddd DD MMM YYYY').replace('.', ',')
  .replace('.', ' '));

export const formatAnticipoHistoricalDate = (date) => {
  const day = '15';
  const [month, year] = moment(new Date(date)).utc().format('MMMM YYYY').split(' ');
  return [day, 'de', month, 'del', year].join(' ');
};

import React from 'react';
import PropTypes from 'prop-types';

const WrittenCharacters = ({ maxLength, value }) => (
  <p className='text-written-characters'> {value?.length} / {maxLength} </p>
);

WrittenCharacters.propTypes = {
  maxLength: PropTypes.number,
  value: PropTypes.string
};

WrittenCharacters.defaultProps = {
  maxLength: 0,
  value: ''
};
export default WrittenCharacters;

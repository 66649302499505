export const actionTypes = {
  GET_EVALUATIONS_REPORT: 'GET_EVALUATIONS_REPORT',
  GET_EVALUATIONS_REPORT_SUCCESS: 'GET_EVALUATIONS_REPORT_SUCCESS',
  GET_EVALUATIONS_REPORT_ERROR: 'GET_EVALUATIONS_REPORT_ERROR',
  CLEAN_EVALUATIONS_REPORT: 'CLEAN_EVALUATIONS_REPORT'
};

export function getEvaluationsReport() {
  return {
    type: actionTypes.GET_EVALUATIONS_REPORT
  };
}

export function getEvaluationsReportSuccess(status) {
  return {
    type: actionTypes.GET_EVALUATIONS_REPORT_SUCCESS,
    data: status
  };
}

export function getEvaluationsReportError(error) {
  return {
    type: actionTypes.GET_EVALUATIONS_REPORT_ERROR,
    error
  };
}

export function cleanEvaluationsReport(error) {
  return {
    type: actionTypes.CLEAN_EVALUATIONS_REPORT,
    error
  };
}

import React from 'react';
import moment from 'moment';
import { stringFilter } from '../../table';

moment.locale('es');

const dateFormat = 'ddd DD MMM YYYY';

const dateFilter = (field) => (itemA, itemB) => {
  const dateA = moment(itemA[field], 'YYYY-MM-DD').valueOf();
  const dateB = moment(itemB[field], 'YYYY-MM-DD').valueOf();
  return dateA - dateB;
};

const fireAction = (callback, calibrationId) => (e) => {
  e.preventDefault();
  const action = e.target.name;
  callback(calibrationId, action);
};

const renderActions = (actions) => (_, record) => {
  const { status } = record;
  switch (status) {
  case 'PENDING':
    return (
      <div>
        <a href='#'
          name='a-edit'
          className='mr-3'
          onClick={fireAction(actions, record._id)}
        >Editar</a>
        <a href='#'
          name='a-delete'
          onClick={fireAction(actions, record._id)}
        >Eliminar</a>
      </div>
    );
  default:
    return <a href='#'
      name='a-view'
      onClick={fireAction(actions, record._id)}
    >Ver detalle</a>;
  }
};

const renderStatus = (status) => {
  const isFinished = status === 'FINISHED';
  return (
    <span>
      <i className={`${isFinished ? 'icon-me-check' : 'icon-me-feedback-pending'} mr-1`}/>
      {isFinished ? 'Finalizada' : 'Pendiente'}
    </span>
  );
};

const renderDates = (field) => (date, record) => {
  const shouldRender = (field === 'updatedAt' && record.status === 'FINISHED') || field === 'calibrationMeeting';
  return shouldRender ? moment(date).format(dateFormat) : '-';
};

export const buildTable = ({ data, actions }) => ({
  columns: [
    {
      title: 'Grupo',
      dataIndex: 'groupName',
      key: 'groupName',
      className: 'table-td',
      sorter: (a, b) => stringFilter(a, b, 'groupName')
    },
    {
      title: 'Calibrador',
      dataIndex: 'createdBy',
      key: 'createdBy',
      className: 'table-td',
      sorter: (a, b) => stringFilter(a, b, 'createdBy')
    },
    {
      title: 'Reunión Calibración',
      dataIndex: 'calibrationMeeting',
      key: 'calibrationMeeting',
      className: 'table-td',
      sorter: dateFilter('calibrationMeeting'),
      render: renderDates('calibrationMeeting')
    },
    {
      title: 'Finalización',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      className: 'table-td',
      sorter: dateFilter('updatedAt'),
      render: renderDates('updatedAt')
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      className: 'table-td',
      sorter: (a, b) => stringFilter(a, b, 'status'),
      render: renderStatus
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      className: 'table-td',
      render: renderActions(actions)
    }
  ],
  srcData: data
});

const dictionary = {
  groupName: 'Grupo',
  createdBy: 'Calibrador',
  calibrationMeeting: 'Reunión Calibración',
  updatedAt: 'Finalización',
  status: 'Estado'
};

const getReadableFields = (key, value) => {
  switch (key) {
  case 'date':
  case 'finish':
    return moment().format('DD-MM-YYYY');
  case 'status':
    return value === 'FINISHED' ? 'Finalizada' : 'Pendiente';
  default:
    return value;
  }
};

export const shapeDataToXLSX = (data) => (
  data.map((obj) => Object.keys(obj).reduce((acum, item) => {
    if (dictionary[item]) {
      acum[dictionary[item]] = getReadableFields(item, obj[item]);
    }
    return acum;
  }, {}))
);

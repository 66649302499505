import React, { lazy } from 'react';
import anticipoSueldo from './solicitudes/anticipo-sueldo';

const Solicitudes = lazy(() => import('../../containers/solicitudes'));
const Vacaciones = lazy(() => import('../../containers/solicitudes/vacaciones'));
const MyTeam = lazy(() => import('../../containers/solicitudes/MyTeam'));
const VacacionesRequest = lazy(() => import('../../containers/solicitudes/vacaciones/request'));
const Historical = lazy(() => import('../../components/solicitudes/vacaciones/Historical/index'));
const RequestDetailHistorical = lazy(() => import('../../components/solicitudes/vacaciones/Historical/ItemDetails'));
const FrequentQuestions = lazy(() => import('../../components/solicitudes/vacaciones/FrequentQuestions'));
const MyTeamItemDetail = lazy(() => import('../../components/solicitudes/MyTeamItemDetail'));
const Requests = lazy(() => import('../../components/solicitudes'));

export default [
  {
    auth: 'BENEFITS_VISIBLE_VACATIONS',
    path: '/solicitudes',
    section: 'Solicitudes',
    component: Solicitudes,
    subRoutes: [
      {
        exact: true,
        tabTitle: 'Solicitudes',
        isTab: true,
        path: '/solicitudes',
        section: 'Solicitudes',
        component: Requests
      },
      {
        exact: true,
        tabTitle: 'Solicitar',
        isTab: true,
        path: '/solicitudes/solicitar',
        section: 'Solicitudes -> Solicitar',
        component: Requests
      },
      {
        exact: true,
        tabTitle: 'Mi Equipo',
        isTab: true,
        path: '/solicitudes/mi-equipo',
        section: 'Solicitudes -> Mi Equipo',
        component: MyTeam,
        subRoutes: [
          {
            exact: true,
            tabTitle: 'Colaborador Individual',
            path: '/solicitudes/mi-equipo',
            section: 'Solicitudes -> Mi Equipo',
            component: (props) => <MyTeamItemDetail {...props} />
          }
        ]
      },
      {
        path: '/solicitudes/vacaciones',
        section: 'Solicitudes -> Vacaciones',
        component: Vacaciones,
        subRoutes: [
          {
            exact: true,
            tabTitle: 'Historial',
            path: '/solicitudes/vacaciones/historial',
            section: 'Solicitudes -> Vacaciones -> Historial',
            component: Historical,
            subRoutes: [
              {
                exact: true,
                tabTitle: 'Detalle Solicitud',
                path: '/solicitudes/vacaciones/historial/solicitud-de-vacaciones',
                section: 'Solicitudes -> Vacaciones -> Historial -> Detalle de Solicitud',
                component: (props) => <RequestDetailHistorical {...props} />
              }
            ]
          },
          {
            exact: true,
            tabTitle: 'Preguntas Frecuentes',
            path: '/solicitudes/vacaciones/preguntas-frecuentes',
            section: 'Solicitudes -> Vacaciones -> Preguntas Frecuentes',
            component: FrequentQuestions
          },
          {
            auth: 'BENEFITS_REQUESTS_VACATIONS',
            tabTitle: 'Proceso de solicitud',
            path: '/solicitudes/vacaciones/proceso-de-solicitud',
            section: 'Solicitudes -> Vacaciones -> Proceso de Solicitud',
            component: VacacionesRequest
          }
        ]
      },
      ...anticipoSueldo

    ]
  }
];

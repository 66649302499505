import React from 'react';
import PropTypes from 'prop-types';
import LoaderComponent from '../../Loader';

const LoaderAndDesc = ({
  title,
  textTime
}) => (<>
  <div className="content-down">
    <LoaderComponent actReport="loadReport" height="20px" />
    <span className="textawaitbold">
        El reporte ({title}) se está generando.
      <span className="textawaitnobold">
          Por favor espera, ya que el proceso puede
          tomar {textTime} aproximadamente.</span>
    </span>
  </div>
</>);

LoaderAndDesc.propTypes = {
  title: PropTypes.string,
  textTime: PropTypes.string
};

LoaderAndDesc.defaultProps = {
  title: '',
  textTime: ''
};

export default LoaderAndDesc;

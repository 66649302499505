import { lazy } from 'react';

const WaliHome = lazy(() => import('../../../containers/wali/home'));
const EmployeeDiagnose = lazy(() => import('../../../components/wali/survey/EmployeeDiagnose'));
const Autoevaluation = lazy(() => import('../../../components/wali/survey/Autoevaluation'));
const TemperatureRegister = lazy(() => import('../../../components/wali/temperatureRegister'));
const AssignState = lazy(() => import('../../../components/wali/assignState'));
const EmployeeRecuperation = lazy(() => import('../../../components/wali/employeeRecuperation'));
const CheckStatus = lazy(() => import('../../../components/wali/checkStatus'));
const GamaReport = lazy(() => import('../../../components/wali/gamaReport'));

// Test
export default [
  {
    path: '/covid-19/wali',
    section: 'Covid-19 -> Wali',
    component: WaliHome,
    subRoutes: [
      {
        exact: true,
        tabTitle: 'Registro Caso Colaborador',
        headerTitle: 'Registro Caso Colaborador',
        path: '/covid-19/wali/diagnostico-colaborador',
        section: 'Covid-19 -> Wali -> Diagnóstico Colaborador',
        component: EmployeeDiagnose
      },
      {
        exact: true,
        tabTitle: 'Autoevaluación',
        headerTitle: 'Autoevaluación',
        path: '/covid-19/wali/autoevaluacion-covid-19',
        section: 'Covid-19 -> Wali -> Autoevaluación Covid-19',
        component: Autoevaluation
      },
      {
        exact: true,
        tabTitle: 'Control Acceso GAMA',
        headerTitle: 'Control Acceso GAMA',
        path: '/covid-19/wali/control-acceso',
        component: TemperatureRegister
      },
      {
        exact: true,
        tabTitle: 'Asignar Estado',
        headerTitle: 'Asignar Estado',
        path: '/covid-19/wali/asignar-estado',
        component: AssignState
      },
      {
        tabTitle: 'Cierre Caso Colaborador',
        headerTitle: 'Cierre Caso Colaborador',
        path: '/covid-19/wali/recuperacion-colaborador',
        component: EmployeeRecuperation
      },
      {
        tabTitle: 'Verificar Estado',
        path: '/covid-19/wali/verificar-estado',
        component: CheckStatus
      },
      {
        tabTitle: 'Reporte Gama',
        headerTitle: 'Reporte Gama',
        path: '/covid-19/wali/reporte-gama',
        component: GamaReport
      }
    ]
  }
];

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import { getFourChart } from '../api/fourChart';
import { actionTypes, getTitleChartSuccess, getTitleChartrror } from '../actions/fourChart';

es6promise.polyfill();

function* getFourCharts() {
  try {
    const response = yield call(getFourChart);
    yield put(getTitleChartSuccess(response.result));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(getTitleChartrror({ error, statusCode }));
  }
}

function* podiumSagas() {
  yield all([takeLatest(actionTypes.ALL_TITLES_CHART, getFourCharts)]);
}
export default podiumSagas;

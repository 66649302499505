import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import InfoCollapse from '../../shared/collapses/InfoCollapse';

const questions = [
  {
    title: 'Pedir feedback',
    child: <p>Te sugerimos Pedir Feedback antes de responder y/o actualizar el TEST.</p>
  },
  {
    title: 'TEST',
    child: <p>
    Según tu respuestas,
     EvolucionaTEST te recomendará las conductas en las que enfocar tu Plan de Evolución.
    </p>

  },
  {
    title: 'Acción no finalizada',
    child: <p>
      En caso que no completes una acción dentro de la fecha definida,
       esta quedará en estado No Finalizada y se guardará automáticamente en el historial.
    </p>

  }
];

const FrequentQuestions = () => (
  <div className="pt-4 pt-sm-3 px-3 px-sm-0">
    {
      questions.map((question) => (
        <InfoCollapse key={uuidv4()} data={question} paneKey={uuidv4()} />
      ))
    }
  </div>
);

export default FrequentQuestions;

export const actionTypes = {
  ADD_POSTS: 'ADD_POSTS',
  ADD_POSTS_SUCCESS: 'ADD_POSTS_SUCCESS',
  ADD_POSTS_ERROR: 'ADD_POSTS_ERROR',
  ALL_MURAL: 'ALL_MURAL',
  ALL_MURAL_SUCCESS: 'ALL_MURAL_SUCCESS',
  ALL_MURAL_ERROR: 'ALL_MURAL_ERROR',
  FILTER_OPTION_UPDATE: 'FILTER_OPTION_UPDATE',
  FILTER_OPTION_RESET: 'FILTER_OPTION_RESET',
  MORE_MURAL: 'MORE_MURAL',
  MORE_MURAL_SUCCESS: 'MORE_MURAL_SUCCESS',
  MORE_MURAL_ERROR: 'MORE_MURAL_ERROR',
  MORE_SEARCH_ERROR: 'MORE_SEARCH_ERROR',
  NO_MORE_RESULTS: 'NO_MORE_RESULTS',
  NO_RESULTS: 'NO_RESULTS',
  RESET_POSTS: 'RESET_POSTS',
  RESET_POSTS_RESULTS: 'RESET_POSTS_RESULTS',
  SEARCH_POST_MURAL: 'SEARCH_POST_MURAL',
  SEARCH_POST_SUCCESS: 'SEARCH_POST_SUCCESS',
  SEARCH_POST_ERROR: 'SEARCH_POST_ERROR',
  SEARCH_MURAL_SUCCESS: 'SEARCH_MURAL_SUCCESS'
};

export const addPost = (post) => ({
  type: actionTypes.ADD_POSTS,
  payload: post
});

export const addPostExito = (post) => ({
  type: actionTypes.ADD_POSTS_SUCCESS,
  payload: post
});

export const addPostError = (error) => ({
  type: actionTypes.ADD_POSTS_ERROR,
  error
});

export const allMural = (variables) => ({
  type: actionTypes.ALL_MURAL,
  variables: {
    mgmt: 'TODAS LAS GERENCIAS', limit: 10, page: 0, ...variables
  }
});

export const allMuralSuccess = (mural) => ({
  type: actionTypes.ALL_MURAL_SUCCESS,
  mural
});

export const allMuralError = (error) => ({
  type: actionTypes.ALL_MURAL_ERROR,
  error
});

export const filterOptionsUpdate = (payload) => ({
  type: actionTypes.FILTER_OPTION_UPDATE,
  payload
});

export const filterOptionsReset = (payload) => ({
  type: actionTypes.FILTER_OPTION_RESET,
  payload
});

export const noMoreResults = () => ({
  type: actionTypes.NO_MORE_RESULTS
});

export const resetPosts = () => ({
  type: actionTypes.RESET_POSTS
});

export const resetPostResults = () => ({
  type: actionTypes.RESET_POSTS_RESULTS
});

export const noResults = () => ({
  type: actionTypes.NO_RESULTS
});

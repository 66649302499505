import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import '../../../../../../../static/styles/mievolucion/myPerformance/hide-boss-objectives.less';
import { useSelector } from 'react-redux';

const HideBossObjectives = ({ form, FormItem, successMeasuresOnly }) => {
  const {
    permissions: {
      MI_EQUIPO_MI_EVOLUCION_DESEMPEÑO_SUBORDINADOS_CON_PULSOS:
        showVisibleReport
    }
  } = useSelector((state) => state.userReducer.data);

  const { getFieldDecorator } = form;
  return (showVisibleReport && successMeasuresOnly
    ? <div className='my-performance-boss-hide-objectives'>
      <FormItem {...{ label: 'Ocultar este objetivo a mis reportes.' }}>
        {getFieldDecorator('visible', { valuePropName: 'checked' })(<Switch/>)}
      </FormItem>

      <p>
        Activa esta opción si no quieres que tus reportes vean este
        {' '}objetivo en particular. Deja visibles todos los que quieras
        {' '}que usen como base al crear los suyos.
      </p>
    </div>
    : <></>
  );
};

HideBossObjectives.propTypes = {
  form: PropTypes.object,
  FormItem: PropTypes.elementType,
  successMeasuresOnly: PropTypes.bool
};

HideBossObjectives.defaultProps = {
  form: {},
  FormItem: Fragment,
  successMeasuresOnly: false
};

export default HideBossObjectives;

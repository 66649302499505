import React from 'react';
import {
  useRouteMatch,
  useLocation
} from 'react-router-dom';
import BannerMobile from '../../shared/BannerMobile';
import InfoMessage from '../../shared/InfoMessage';
import MobileList from '../../shared/list/MobileList';
import BannerLayout from '../../../layouts/banner';
import MLSecondaryTabs from '../../shared/MLTabs/MLSecondaryTabs';
import { smallDevice } from '../../../helpers/deviceVariables';

const EvolucionaTESTDesktop = ({
  banner,
  showIntroduction,
  buildSecondaryTabs,
  Intro,
  error
}) => {
  const { pathname } = useLocation();

  const getActiveTab = () => {
    switch (true) {
    case !!pathname.match(/plan-de-evolucion/):
      return '/mi-evolucion/evolucionaTEST/plan-de-evolucion';

    case pathname === '/mi-evolucion/evolucionaTEST':
      return '/mi-evolucion/evolucionaTEST/TEST';

    default:
      return pathname;
    }
  };

  return (
    <BannerLayout {...banner} childrenClassName="p-0" hideSmallDevice={true}>
      {
        showIntroduction && !error
          ? <Intro />
          : <div>
            {
              (
                <div className="d-none d-sm-block">
                  <MLSecondaryTabs
                    options={buildSecondaryTabs()}
                    defaultActiveKey={getActiveTab()}
                    activeTab={getActiveTab()}
                    className='mb-0'
                  />
                </div>
              )
            }
          </div>
      }
    </BannerLayout>
  );
};

const EvolucionaTESTMobile = ({
  banner, userLevel, hidden, setHidden, width, showIntroduction, error, Intro
}) => {
  const { path } = useRouteMatch();

  const listOptions = [
    {
      label: 'TEST',
      to: `${path}/TEST`,
      onClick: () => setHidden(true)
    },
    {
      label: 'Plan de Evolución',
      to: `${path}/plan-de-evolucion`,
      onClick: () => setHidden(true)
    },
    {
      label: 'Claves de Uso',
      to: `${path}/claves-de-uso`,
      onClick: () => setHidden(true)
    }
  ];

  return (
    <>
      {
        width <= smallDevice
        && !error && (
          showIntroduction
            ? <Intro />
            : !hidden && (
              <BannerMobile {...banner}>
                {
                  userLevel && userLevel !== 0 ? (
                    <MobileList className='container-mobile-list' listOptions={listOptions} />
                  )
                    : (
                      <div className="pt-4 px-3">
                        <InfoMessage message="Lo sentimos, no tenemos registrado tu nivel" />
                      </div>
                    )
                }
              </BannerMobile>
            )
        )
      }
    </>
  );
};

export { EvolucionaTESTMobile, EvolucionaTESTDesktop };

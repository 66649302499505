import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import '../../../static/styles/shared/buttons/reject-button.less';

const RejectButton = ({ onClick, text }) => (
  <Button onClick={onClick} className='ml-reject-button'>
    {text}
  </Button>
);

RejectButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func
};

RejectButton.defaultProps = {
  text: '',
  onClick: () => null
};

export default RejectButton;

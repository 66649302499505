import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import '../../../static/styles/shared/tabs/MLSecondaryTabs.less';

const { TabPane } = Tabs;

export const setUrl = (value) => {
  if (typeof value === 'string') {
    return value.replace(/\s/g, '-').toLowerCase();
  }
  return '';
};

const MLSecondaryTabs = ({
  onChange, options, defaultActiveKey, activeTab
}) => {
  const history = useHistory();

  const onTabClick = (i) => {
    history.push(i);
  };

  return (
    <Tabs
      onTabClick={onChange || onTabClick}
      activeKey={activeTab}
      defaultActiveKey={defaultActiveKey}
      className="ml-secondary-tabs"
      type="card"
    >
      { options.map((option, index) => (
        <TabPane
          tab={option.title}
          key={option.key || index}
          className="content-tab"
        >
          {/* {option.children} */}
        </TabPane>
      ))}
    </Tabs>
  );
};
MLSecondaryTabs.propTypes = {
  onChange: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  options: PropTypes.array,
  defaultActiveKey: PropTypes.string,
  className: PropTypes.string
};

MLSecondaryTabs.defaultProps = {
  onChange: null,
  options: [],
  defaultActiveKey: '0',
  className: ''
};

export default MLSecondaryTabs;

import getObjectivesValidateValues from './getObjectivesValidateValues';

const validateObjectives = (data, totalWeights) => {
  const result = { data: null, type: '' };
  const objectives = data?.objectives?.data;
  const cQuarter = data?.quarterCurrent;

  const {
    expectedWeight,
    minObjectives,
    maxObjectives,
    maxSuccessMeasures
  } = getObjectivesValidateValues();

  const [{ value: currentWeight1 }] = totalWeights;

  const errors = {
    WEIGHT: () => ({
      title: `No se puede enviar la propuesta, dado que la ponderación total de Objetivos es de ${currentWeight1} y debe sumar 100%.`
    }),
    OBJECTIVES: () => ({
      title: `No se puede enviar la propuesta, dado que no cumple con la cantidad requerida de objetivos: mínimo ${minObjectives}, máximo ${maxObjectives}.`
    }),
    EXISTENCE: () => ({
      title: 'No se puede enviar la propuesta, dado que existen objetivos sin medidas de éxito, ponderación y fechas de cumplimiento.'
    })
  };

  const getError = (type, params) => ({ type, data: errors[type](params) });

  if (!objectives?.length) return getError('EXISTENCE');
  if (objectives.length < minObjectives || objectives.length > maxObjectives) {
    return Object.assign(result, getError('OBJECTIVES'));
  }

  let currentWeight = 0;

  result.valid = !objectives.find(({ weight, successMeasures }, i) => {
    const weightExceed = (currentWeight += weight) > expectedWeight;
    const weightNotReached = i + 1 === objectives.length && currentWeight !== expectedWeight;

    if (weightExceed || weightNotReached) {
      return Object.assign(result, getError('WEIGHT'));
    }

    const sts = ['FINISHED', 'CANCELLED'];
    const qM = successMeasures.filter(({ updates }) => !sts.includes(updates[cQuarter]?.status));
    const measuresExceed = qM.length > maxSuccessMeasures;

    if (!successMeasures.length || measuresExceed) {
      return Object.assign(result, getError('EXISTENCE'));
    }

    return false;
  });

  return result.data ? result : null;
};

export default validateObjectives;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useAuthReducers from './useAuthReducers';
import { getItem } from '../../helpers/cookiesUtil';
import { loginSuccess } from '../../redux/actions/user';

const useLogin = ({ history, location, isAuthenticated }) => {
  const { pathname } = location;
  const reducers = useAuthReducers();
  const dispatch = useDispatch();
  const {
    commonsReducer: { statusCode },
    userReducer
  } = reducers;
  const { me, usersToRedirect } = userReducer;

  useEffect(() => {
    if (getItem('id_token')) {
      const data = {
        id_token: getItem('id_token'),
        redirectToCredProduct: getItem('redirectToCredProduct'),
        redirectToMio: getItem('redirectToMio')
      };

      dispatch(loginSuccess(data));
    }
  }, [userReducer?.data]);

  // Validate and redirect users - wali
  if (isAuthenticated && me && usersToRedirect && usersToRedirect[me.rut]) {
    if (pathname !== '/covid-19/wali/control-acceso') {
      history.replace(usersToRedirect[me.rut]);
      return true;
    }
  }

  return (!userReducer.loading && !me) || (!me && statusCode === 0);
};

export default useLogin;

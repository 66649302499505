import { useEffect, useState } from 'react';

const useRefetchTimer = (value, timeLimit, onTimeEllapsed, disabled) => {
  const [refetchTimout, setRefetchTimout] = useState(null);

  useEffect(() => {
    clearTimeout(refetchTimout);

    if (value) {
      if (!disabled) {
        setRefetchTimout(setTimeout(() => {
          onTimeEllapsed();
        }, timeLimit));
      }
    }
  }, [value]);
};

export default useRefetchTimer;

import { lazy } from 'react';

const MyEvolutionTeamEvaluationsImprovementPlan = lazy(() => import('../../../../components/myTeam/myEvolution/improvementPlan'));

export default [
  {
    exact: true,
    path: '/mi-equipo/mi-evolucion/plan-de-mejora',
    section: 'Mi Equipo - Mi Evolución - Plan de Mejora',
    component: MyEvolutionTeamEvaluationsImprovementPlan,
    auth: 'MI_EQUIPO_MI_EVOLUCION_PLAN_DE_MEJORA'
  }
];

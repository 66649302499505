import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const timeOutValue = 700000;

const MIO_BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');
const REPORTS_BFF_ENDPOINT = getEnvVar('REACT_APP_API_REPORTS');
const REPORTS_RECONOCIMIENTOS = getEnvVar('REACT_APP_SOLUTION_LVDC_BACKEND');

export const getFeedbackAPI = (socketId) => axios().get(`${REPORTS_BFF_ENDPOINT}/feedback?&socketId=${socketId}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getPerformanceAPI = (socketId, year) => axios().get(`${REPORTS_BFF_ENDPOINT}/performance?year=${year}&socketId=${socketId}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getPerformanceWalmartAPI = (year) => axios().get(`${REPORTS_BFF_ENDPOINT}/performanceWalmart?year=${year}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getPerformanceWalmartProgressAPI = (socketId) => axios().get(`${REPORTS_BFF_ENDPOINT}/performanceWalmartProgress?socketId=${socketId}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getSurveyPerformanceWalmartProgressAPI = (socketId) => axios().get(`${REPORTS_BFF_ENDPOINT}/surveyPerformanceWalmart?socketId=${socketId}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getPerformanceWalmartTemplateAPI = (socketId) => axios().get(`${REPORTS_BFF_ENDPOINT}/performanceWalmartTemplate?socketId=${socketId}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getFeedbackNetworkAPI = (socketId, year) => axios().get(`${REPORTS_BFF_ENDPOINT}/feedbackNetworks?year=${year}&socketId=${socketId}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getInterestAndSkillsAPI = (socketId) => axios().get(`${REPORTS_BFF_ENDPOINT}/interestAndSkills?socketId=${socketId}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getCompetenciesAPI = (socketId, year) => axios().get(`${REPORTS_BFF_ENDPOINT}/competencies?year=${year}&socketId=${socketId}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getEvolucionaTESTAPI = () => axios().get(`${REPORTS_BFF_ENDPOINT}/evolucionaTEST`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getSocialAssistantListAPI = () => axios().post(`${MIO_BFF_ENDPOINT}/admin/social-assistant/list/download`, { responseType: 'arraybuffer' }, { timeout: timeOutValue }).then((resp) => resp.data);

export const getObjectivesAPI = () => axios().get(`${REPORTS_BFF_ENDPOINT}/objectives`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getSocialAssistantAPI = () => axios().get(`${REPORTS_BFF_ENDPOINT}/socialattentions`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getSectionReportAPI = (data) => axios().get(`${REPORTS_BFF_ENDPOINT}/ratingPages`, { ...data, ...timeOutValue }).then((resp) => resp.data);

export const getEDDAPI = (year) => axios().get(`${REPORTS_BFF_ENDPOINT}/edd?year=${year}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getMeritLettersReportAPI = () => axios().get(`${REPORTS_BFF_ENDPOINT}/compensations/merit_letters`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getStatusFeedbackNetworkReportAPI = (socketId) => axios().get(`${REPORTS_BFF_ENDPOINT}/performance/status_feedback_network?socketId=${socketId}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getTraceabilityReportAPI = (data) => axios().get(`${REPORTS_BFF_ENDPOINT}/traceability/generate-report`, { ...data, timeout: timeOutValue }).then((resp) => resp.data);

export const getEDDAutoevaluationsAPI = (year) => axios().get(`${REPORTS_BFF_ENDPOINT}/edd/autoevaluations?year=${year}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getEDDEvaluationsAPI = (year) => axios().get(`${REPORTS_BFF_ENDPOINT}/edd/evaluations?year=${year}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getEDPAPI = (year) => axios().get(`${REPORTS_BFF_ENDPOINT}/edp/evaluations?year=${year}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getEDDOpinionEvaluationAPI = (socketId, year) => axios().get(`${REPORTS_BFF_ENDPOINT}/edd/opinion?year=${year}&socketId=${socketId}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getMeritsTraceabilityAPI = () => axios().get(`${REPORTS_BFF_ENDPOINT}/compensation/meritstraceability`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getIDPAPI = (socketId) => axios().get(`${REPORTS_BFF_ENDPOINT}/idp?socketId=${socketId}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getReconocimientoAPI = () => axios().get(`${REPORTS_RECONOCIMIENTOS}/report/general`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getReconocimientoComentariosAPI = () => axios().get(`${REPORTS_RECONOCIMIENTOS}/report/comments`, { timeout: timeOutValue }).then((resp) => resp.data);

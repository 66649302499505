import { isEmpty, find, map } from 'lodash';
import { fullCapitalizeFormat } from '../../strings';

export const MONTH_ADVANCE_COMMENTS_BY_Q = {
  1: 'Marzo',
  2: 'Julio',
  3: 'Diciembre'
};

export const PLACEHOLDER_COMMENTS_BY_Q = {
  q1: {
    colaborate: '',
    boss: ''
  },
  q2: {
    colaborate: '¿Cómo fue tu desempeño en el Pulso 1?',
    boss: '¿Cómo fue su desempeño en el Pulso 1?'
  },
  q3: {
    colaborate: '¿Cuáles serán los compromisos que te impulsarán a conseguir las metas y tu propósito este año?',
    boss: ''
  }
};

export const AMOUNT_OF_PULSES = 4; // porque el back espera siempre recibir comentarios de q1 a q4

export const QUARTERS = [];
for (let i = 1; i <= AMOUNT_OF_PULSES; i += 1) {
  QUARTERS.push(`q${i}`);
}

export const RETRO_URL = '/mi-evolucion/retro';
export const DESEMPENO_URL = '/mi-evolucion/mi-desempeño/desempeño';
export const EDD_URL = '/mi-evolucion/mi-desempeño/evaluación-de-desempeño';
export const FEEDBACK_URL = '/mi-evolucion/mi-desempeño/feedback';
export const FIRST_PULSE = 'q1';
export const initiativeStatusValidsInPreviousQuarter = [
  'FINISHED',
  'CANCELLED'
];

export const getStatusByQuarter = (status) => ({
  quarterPending: status === 'PENDING',
  quarterSent: status === 'SENT',
  quarterClosed: status === 'CLOSED',
  quarterReturned: status === 'RETURNED'
});

const findMissingComments = ({ quarterCurrent, competencies }) => {
  if (quarterCurrent === FIRST_PULSE) return false;

  const indexCurrentQuarter = QUARTERS.indexOf(quarterCurrent);
  if (indexCurrentQuarter !== -1) {
    const previousQuarter = QUARTERS[indexCurrentQuarter - 1];

    if (!previousQuarter || isEmpty(previousQuarter)) return false;
    const previousCompetencies = competencies[previousQuarter];

    const hasEmptyComment = find(previousCompetencies,
      (competencie) => competencie.comment.body.trim().length === 0);
    if (hasEmptyComment) {
      return true;
    }
  }
  return false;
};
const findMissingIniciatives = ({ quarterCurrent, objectives }) => {
  if (quarterCurrent === FIRST_PULSE) return false;

  let missingIniciative = false;
  const indexCurrentQuarter = QUARTERS.indexOf(quarterCurrent);
  if (indexCurrentQuarter !== -1) {
    const previousQuarter = QUARTERS[indexCurrentQuarter - 1];
    map(objectives, ({ initiatives }) => {
      map(initiatives, ({ updates }) => {
        const previousQuarterStatus = updates[previousQuarter];
        if (previousQuarterStatus) {
          const { initiativeStatus: previousStatus } = previousQuarterStatus;
          if (previousStatus === 'WITHOUTINFO') {
            missingIniciative = true;
          }
        }
      });
    });
  }
  return missingIniciative;
};

export const showButtonProgressComment = ({
  quarterKey, quarterCurrent, year, myTeam, quarterSent, quarterClosed, currentStatus
}) => {
  let showButton = false;

  if (myTeam || quarterSent || quarterClosed) return false;
  const indexCurrentQuarter = QUARTERS.indexOf(quarterCurrent);
  if (indexCurrentQuarter !== -1) {
    const previousQuarter = QUARTERS[indexCurrentQuarter === 0
      ? QUARTERS.length - 1 : indexCurrentQuarter - 1];

    const yearPreviousQuarter = indexCurrentQuarter === 0 ? year - 1 : year;
    if (previousQuarter === quarterKey && year === yearPreviousQuarter) {
      showButton = true;
    }
    showButton = false;
  }
  if (quarterKey === 'q4' && quarterCurrent === 'q5' && (currentStatus === 'PENDING' || currentStatus === 'RETURNED')) {
    showButton = true;
  }
  return showButton;
};

export const customValidate = (data, hasTribe) => {
  const { quarterCurrent } = data;
  const weights = (() => {
    try {
      return Math.round(data.objectives.reduce((acc, item) => {
        acc += item.weight;
        return acc;
      }, 0) * 100);
    } catch (error) {
      return 0;
    }
  })();

  const objectivesLengthValidted = hasTribe || (
    data.objectives.length >= 3 && data.objectives.length <= 8
  );
  const objectivesWeightingValidated = weights === 100;
  const objectivesValidted = objectivesLengthValidted && objectivesWeightingValidated;
  const canBeSend = quarterCurrent === 'q5' ? objectivesValidted : objectivesValidted
      && !!data.competencies[quarterCurrent]?.length
        && !!data.feedbackNetwork[quarterCurrent]?.length;

  return {
    weights,
    objectivesLengthValidted,
    objectivesWeightingValidated,
    canBeSend,
    missingCommentsInCompetencies: findMissingComments({
      quarterCurrent,
      competencies: data.competencies
    }),
    missingInitiatives: findMissingIniciatives({
      quarterCurrent,
      objectives: data.objectives
    })
  };
};

export const getModalMessage = ({
  data,
  competenciesPerformance,
  quarterCurrent,
  linealBoss,
  myTeam,
  feedbackNetwork,
  objectives,
  hasTribe
}) => {
  const {
    weights,
    objectivesLengthValidted,
    objectivesWeightingValidated,
    canBeSend,
    missingInitiatives,
    missingCommentsInCompetencies
  } = customValidate(data, hasTribe);

  const currentCompetencies = competenciesPerformance[quarterCurrent];
  const titleError = `No se ha podido ${myTeam ? 'cerrar el Proceso' : 'enviar la propuesta a Jefatura'}`;
  const titleSuccess = ` ¿Quieres enviar la Propuesta a ${fullCapitalizeFormat(linealBoss)}?`;
  const currentFeedbackNetwork = feedbackNetwork[quarterCurrent];

  const emptyCompetencies = isEmpty(currentCompetencies);
  const emptyFeedbackNetwork = isEmpty(currentFeedbackNetwork);
  const emptyObjectives = isEmpty(objectives);

  if (!objectivesLengthValidted || !objectivesWeightingValidated) {
    return {
      title: titleError,
      message: !objectivesLengthValidted
        ? 'Es necesario que definas un mínimo de 3 y un máximo de 8 Objetivos.'
      // eslint-disable-next-line no-template-curly-in-string
        : `Las ponderaciones de los Objetivos deben sumar 100% entre ellas y actualmente suman un total de ${weights}%. Por favor, corrígelas para continuar.`
    };
  }
  if (quarterCurrent !== 'q5' && isEmpty(currentCompetencies) && isEmpty(currentFeedbackNetwork)) {
    return {
      title: 'Para enviar la propuesta a Jefatura, primero debes definir Competencias y Red de Feedback.'
    };
  }
  if (quarterCurrent !== 'q5' && (emptyCompetencies || emptyFeedbackNetwork || emptyObjectives)) {
    if (emptyCompetencies && emptyFeedbackNetwork && emptyObjectives) {
      return {
        title: 'Para enviar la propuesta a Jefatura, primero debes definir Objetivos, Competencias y Red de Feedback.'
      };
    } if (emptyCompetencies && emptyFeedbackNetwork) {
      return {
        title: 'Para enviar la propuesta a Jefatura, primero debes definir Competencias y Red de Feedback.'
      };
    } if (emptyCompetencies) {
      return {
        title: 'Para enviar la propuesta a Jefatura, primero debes definir Competencias'
      };
    } if (emptyFeedbackNetwork) {
      return {
        title: 'Para enviar la propuesta a Jefatura, primero debes definir Red de Feedback.'
      };
    }
  }
  if (canBeSend) {
    let message = quarterCurrent !== 'q5'
      ? 'Una vez que la envíes, no podrás editarla hasta que inicie un nuevo Pulso.'
      : 'Una vez que la envies no podrás realizar modificaciones a menos que tu jefe te lo devuelva';

    if (missingInitiatives && missingCommentsInCompetencies) {
      message = 'No has registrado el progreso de algunas competencias e iniciativas de tus Objetivos.';
    } else if (missingInitiatives) {
      message = 'No has registrado el progreso de algunas iniciativas de tus Objetivos.';
    } else if (missingCommentsInCompetencies) {
      message = 'No has registrado comentarios de progreso en algunas competencias del Pulso anterior.';
    }
    return {
      title: titleSuccess,
      message
    };
  }
  return {
    title: titleError
  };
};
export const SPECIFIC_OBJECTIVE_KEY = 'SPECIFIC';
export const TRANSVERSAL_OBJECTIVE_KEY = 'TRANSVERSAL';

export const pillarName = {
  [`${TRANSVERSAL_OBJECTIVE_KEY}`]: 'Transversales',
  [`${SPECIFIC_OBJECTIVE_KEY}`]: 'Específicos'
};
export const pillarColor = {
  [`${TRANSVERSAL_OBJECTIVE_KEY}`]: 'purple',
  [`${SPECIFIC_OBJECTIVE_KEY}`]: 'green'
};

export const pillarType2022 = {
  AOP: 'AOP Plan de Negocio',
  PESM: 'Plan estratégico (sin macro)',
  PECM: 'Plan estratégico (con macro)'
};

export const objectiveRowHeights = {
  2: 72,
  3: 94.5,
  4: 117
};

export const successMeasuresStatusOptions = [
  { value: 'NOT_INIT', label: 'No Iniciado' },
  { value: 'IN_PROGRESS', label: 'En proceso' },
  { value: 'FINISHED', label: 'Finalizada' },
  { value: 'CANCELLED', label: 'Cancelada' },
  { value: 'WITHOUTINFO', label: 'Sin Información', className: 'hidden' }
];
export const WITHOUTINFO = 'WITHOUTINFO';
export const CLOSED = 'CLOSED';
export const SENT = 'SENT';
export const RETURNED = 'RETURNED';
export const PENDING_BY_COLABORATE = 'PENDING_BY_COLABORATE';
export const PENDING_BY_BOSS = 'PENDING_BY_BOSS';

export const DEFAULT_SUCCESS_MEASURES = {
  q1: {
    status: WITHOUTINFO
  },
  q2: {
    status: WITHOUTINFO
  },
  q3: {
    status: WITHOUTINFO
  },
  q4: {
    status: WITHOUTINFO
  }
};
export const PERFORMANCE_YEAR = 2024;

export const MAX_SUCCESS_MEASURES = 7;

export const getNumberOfActivesSuccessMeasures = (qKey, successMeasures) => successMeasures?.filter(
  ({ updates }) => {
    switch (updates[qKey].status) {
    case 'NOT_INIT':
    case 'IN_PROGRESS':
    case 'WITHOUTINFO':
      return true;
    default:
    }
    return false;
  }
).length || 0;

export const TITLE_ERROR_MAX_SUCCESS_MEASURES = 'Error en medidas de éxito';

export const ERROR_MESSAGE_MAX_SUCCESS_MEASURES = `No pueden haber más de ${MAX_SUCCESS_MEASURES} medidas de éxito activas(aquellas que no están en estado finalizada o cancelada).`;

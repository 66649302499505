export const actionTypes = {
  GET_LEADERS_PEOPLE_STORES: 'GET_LEADERS_PEOPLE_STORES',
  GET_LEADERS_PEOPLE_STORES_SUCCESS: 'GET_UNIFORMS_STORES_SUCCESS',
  GET_LEADERS_PEOPLE_STORES_ERROR: 'GET_UNIFORMS_STORES_ERROR',
  GET_LAST_FILE_UPLOADED_PEOPLE_STORES: 'GET_LAST_FILE_UPLOADED_PEOPLE_STORES',
  GET_LAST_FILE_UPLOADED_PEOPLE_STORES_SUCCESS: 'GET_LAST_FILE_UPLOADED_PEOPLE_STORES_SUCCESS',
  GET_LAST_FILE_UPLOADED_PEOPLE_STORES_ERROR: 'GET_LAST_FILE_UPLOADED_PEOPLE_STORES_ERROR'
};

export function getLeadersPeopleStores(rut) {
  return {
    type: actionTypes.GET_LEADERS_PEOPLE_STORES,
    rut
  };
}

export function getLeadersPeopleStoresSuccess(payload) {
  return {
    type: actionTypes.GET_LEADERS_PEOPLE_STORES_SUCCESS,
    payload
  };
}

export function getLeadersPeopleStoresError(error) {
  return {
    type: actionTypes.GET_LEADERS_PEOPLE_STORES_ERROR,
    error
  };
}

export function getLastFileUploadedPeopleStores() {
  return {
    type: actionTypes.GET_LAST_FILE_UPLOADED_PEOPLE_STORES
  };
}

export function getLastFileUploadedPeopleStoresSuccess() {
  return {
    type: actionTypes.GET_LAST_FILE_UPLOADED_PEOPLE_STORES_SUCCESS
  };
}

export function getLastFileUploadedPeopleStoresError(error) {
  return {
    type: actionTypes.GET_LAST_FILE_UPLOADED_PEOPLE_STORES_ERROR,
    error
  };
}

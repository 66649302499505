import { concat, findIndex } from 'lodash';
import { actionTypes } from '../actions/socialAssistant';

export const initialState = {
  saUser: null,
  wizardStep: 1,
  resetCase: false,
  showModal: false,
  addingReview: false,
  saGroup: null
};

const UpdateRecordReview = (array, id, data) => {
  const index = findIndex(array, { _id: id });
  const newArray = array.map((item, i) => {
    if (i !== index) {
      return item;
    }
    return {
      ...item,
      ...data
    };
  });
  return newArray;
};

function reducer(state = initialState, action) {
  let advices;
  let records;
  if (state.saUser !== null
    && state.saUser.socialAttention) {
    ({ saUser: { socialAttention: { advices, records } } } = state);
  }

  switch (action.type) {
  case actionTypes.ADD_SA_GROUP_REGISTER_SUCCESS:
    return {
      ...state,
      showModal: true
    };

  case actionTypes.GET_LOCAL_INFO_SUCCESS:
    return {
      ...state,
      saGroup: action.payload
    };

  case actionTypes.GET_USER_FROM_GRUPAL_SUCCESS:
    return {
      ...state,
      saGroup: {
        ...state.saGroup,
        userFromModal: action.payload
      }
    };

  case actionTypes.RESET_USER_FROM_MODAL:
    return {
      ...state,
      saGroup: {
        ...state.saGroup,
        userFromModal: {}
      }
    };

  case actionTypes.ADD_TO_LOCAL_LIST:
    return {
      ...state,
      saGroup: {
        ...state.saGroup,
        users: [
          ...state.saGroup.users,
          {
            rut: action.payload.rut,
            name: action.payload.name,
            fatherLastName: action.payload.fatherLastName,
            position: action.payload.position
          }
        ]
      }
    };

  case actionTypes.GET_SA_CATEGORIES_SUCCESS:
    return {
      ...state,
      ...{ [`${action.payload.type.replace(/-/g, '')}Options`]: action.payload.data }
    };

  case actionTypes.GET_SA_USER_SUCCESS:
    return {
      ...state,
      saUser: action.payload,
      resetCase: true,
      resetForm: true
    };

  case actionTypes.DISMISS_MODAL:
    return {
      ...state,
      ...{ showModal: false }
    };

  case actionTypes.ADD_INDIVIDUAL_SUCCESS:
    return {
      ...state,
      wizardStep: 4,
      showModal: true,
      saUser: {
        ...state.saUser,
        socialAttention: action.payload
      }
    };

  case actionTypes.ADD_ADVISORY_SUCCESS:
    return {
      ...state,
      showModal: true,
      saUser: {
        ...state.saUser,
        socialAttention: {
          ...state.saUser.socialAttention,
          advices: concat(action.payload, advices)
        }
      }
    };

  case actionTypes.ADD_REGISTER_SUCCESS:
    return {
      ...state,
      showModal: true,
      saUser: {
        ...state.saUser,
        socialAttention: {
          ...state.saUser.socialAttention,
          records: concat(action.payload, records)
        }
      }
    };

  case actionTypes.CLOSE_CASE_SUCCESS:
    return {
      ...state,
      saUser: {
        ...state.saUser,
        socialAttention: {
          ...state.saUser.socialAttention,
          records: UpdateRecordReview(records, action.payload._id, action.payload)
        }
      },
      closingCase: false
    };

  case actionTypes.CLOSE_CASE:
    return {
      ...state,
      closingCase: true
    };

  case actionTypes.ADD_REVIEW:
    return {
      ...state,
      addingReview: true
    };

  case actionTypes.ADD_REVIEW_SUCCESS:
    return {
      ...state,
      addingReview: false,
      saUser: {
        ...state.saUser,
        socialAttention: {
          ...state.saUser.socialAttention,
          records: UpdateRecordReview(records, action.payload._id, action.payload)
        }
      }
    };

  case actionTypes.CHANGE_WIZARD_STEP:
    return {
      ...state,
      ...{ wizardStep: action.payload }
    };

  case actionTypes.RESET_CASE:
    return {
      ...state,
      ...{ resetCase: false }
    };

  case actionTypes.RESET_FORM:
    return {
      ...state,
      ...{ resetForm: false }
    };

  default:
    return state;
  }
}

export default reducer;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import { getAgreementsAPI, getAgreementAPI } from '../api/agreements';
import { actionTypes, getAgreementSuccess, getAgreementsSuccess } from '../actions/agreements';
import {
  failure, loading, loadComplete
} from '../actions/commons';

es6promise.polyfill();

export function* agreement(item) {
  try {
    yield put(loading());
    const response = yield call(getAgreementAPI, item);
    yield put(getAgreementSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

export function* agreements() {
  try {
    yield put(loading());
    const response = yield call(getAgreementsAPI);
    yield put(getAgreementsSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* agreementsSagas() {
  yield all([
    takeLatest(actionTypes.GET_AGREEMENT, agreement),
    takeLatest(actionTypes.GET_AGREEMENTS, agreements)
  ]);
}

export default agreementsSagas;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input
} from 'antd';
import { isEmpty } from 'lodash';
import ResultsUsers from './ResultsUsers';
import UsersSelected from './UsersSelected';
import Label from './Label';
import getClassName from '../../../../helpers/strings/getClassName';

const textRequired = 'Este campo es obligatorio';

const SearchUsers = (props) => {
  const {
    className,
    disabled,
    usersSelected,
    setUsersSelected,
    showResultsUsers,
    setShowResultsUsers,
    query,
    label,
    onCollection = null,
    userAttribute,
    type
  } = props;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const validateShowResults = () => {
    if (firstName.trim().length > 0 && lastName.trim().length > 0) {
      setShowResultsUsers(true);
    } else {
      setShowResultsUsers(false);
    }
  };

  const selectUser = (value, canDelete = true) => {
    const myUserSelected = new Map();
    let action = ''; // delete or add
    usersSelected.forEach((user) => {
      myUserSelected.set(user.rut, { ...user });
    });

    if (canDelete && myUserSelected.has(value.rut)) {
      myUserSelected.delete(value.rut);
      action = 'delete';
    } else {
      myUserSelected.set(value.rut, value);
      action = 'add';
    }
    setFirstName('');
    setLastName('');
    setUsersSelected(myUserSelected, value, action);
  };

  const resultsProps = {
    query,
    setShowResultsUsers,
    selectUser,
    usersSelected,
    variables: {
      name: firstName,
      fatherLastName: lastName,
      offset: 0,
      limit: 100,
      ...(onCollection && { onCollection })
    }
  };
  const showError = (key) => {
    const _firstName = key === 'firstName' ? isEmpty(firstName.trim()) : !isEmpty(firstName.trim());
    const _lastName = key === 'lastName' ? isEmpty(lastName.trim()) : !isEmpty(lastName.trim());
    if (_firstName && _lastName) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    validateShowResults();
  }, [firstName, lastName]);

  return (
    <div {...{ className: getClassName('container-input-search-users-container', className) }}>
      <div className="container-input-search-users">
        <Label label={label}/>
        <div className="row">
          <Form.Item
            name="firstName"
            help={ showError('firstName') ? textRequired : ''}
            className={`col-12 col-sm-6 col-md-6 input-search ${showError('firstName') ? 'has-error' : ''}`}
          >
            <Input
              disabled={disabled}
              value={firstName}
              onFocus={(e) => {
                e.preventDefault();
                validateShowResults();
              }}
              placeholder='Ingresar nombre*'
              onChange={ (e) => {
                setFirstName(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            help={ showError('lastName') ? textRequired : ''}
            className={`col-12 col-sm-6 col-md-6 input-search ${showError('lastName') ? 'has-error' : ''}`}
          >
            <Input
              disabled={disabled}
              value={lastName}
              onFocus={(e) => {
                e.preventDefault();
                validateShowResults();
              }}
              placeholder='Ingresar apellido*'
              onChange={ (e) => {
                setLastName(e.target.value);
              }}
            />
          </Form.Item>
        </div>
      </div>
      <div className="row">
        <div className={`col-12  ${showResultsUsers ? 'col-sm-6 col-md-6' : ''}`}>
          <UsersSelected
            usersSelected={usersSelected}
            selectUser={selectUser}
            type={type}
            userAttribute={userAttribute}/>
        </div>
        { showResultsUsers
        && (
          <ResultsUsers {...resultsProps} />
        )}
      </div>
    </div>

  );
};

SearchUsers.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  query: PropTypes.object,
  usersSelected: PropTypes.instanceOf(Map),
  setUsersSelected: PropTypes.func,
  showResultsUsers: PropTypes.bool,
  setShowResultsUsers: PropTypes.func,
  userAttribute: PropTypes.string,
  type: PropTypes.string
};

SearchUsers.defaultProps = {
  className: '',
  disabled: false,
  label: '',
  query: {},
  usersSelected: {},
  setUsersSelected: () => {},
  showResultsUsers: false,
  setShowResultsUsers: () => {},
  userAttribute: '',
  type: 'inverse-default'
};

export default SearchUsers;

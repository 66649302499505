import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import {
  addPost,
  allMural
} from '../api/mural';
import {
  actionTypes,
  addPostExito,
  addPostError,
  allMuralSuccess,
  allMuralError
} from '../actions/mural';

es6promise.polyfill();

function* addNewPostAction(post) {
  try {
    const response = yield call(addPost, post.payload);
    yield put(addPostExito(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(addPostError({ error, statusCode }));
  }
}

function* getAllMural(action) {
  try {
    const response = yield call(allMural, action.variables);
    yield put(allMuralSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(allMuralError({ ...error, statusCode }));
  }
}

function* muralSagas() {
  yield all([takeLatest(actionTypes.ALL_MURAL, getAllMural)]);
  yield all([takeLatest(actionTypes.ADD_POSTS, addNewPostAction)]);
}
export default muralSagas;

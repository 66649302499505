import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import { getCalibrationReportAPI } from '../api/calibrationsReport';
import { actionTypes, getCalibrationsReportSuccess, getCalibrationsReportError } from '../actions/calibrationsReport';
import { failure as commonsFailure } from '../actions/commons';

es6promise.polyfill();

export function* getContent() {
  try {
    const response = yield call(getCalibrationReportAPI);
    yield put(getCalibrationsReportSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(statusCode === 401 ? commonsFailure(
      { error, statusCode }
    ) : getCalibrationsReportError({ error, statusCode }));
  }
}

function* calibrationsReportSagas() {
  yield all([
    takeLatest(actionTypes.GET_CALIBRATIONS_REPORT, getContent)
  ]);
}

export default calibrationsReportSagas;

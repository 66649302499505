import { useState } from 'react';

export default function useScrollTracker(elementRef) {
  const [scroll, setScroll] = useState({ x: null, y: null });

  const handleScroll = () => {
    const element = elementRef.current;
    if (element) {
      const { scrollLeft, scrollTop } = elementRef.current;
      if (scroll.x !== scrollLeft || scroll.y !== scrollTop) {
        setScroll({ x: scrollLeft, y: scrollTop });
      }
    }
  };

  return [scroll, handleScroll];
}

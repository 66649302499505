export const actionTypes = {
  GET_SETTINGS: 'GET_SETTINGS',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_ERROR: 'GET_SETTINGS_ERROR',
  CLEAN_SETTINGS: 'CLEAN_SETTINGS'
};

export const getSettings = ({ variables }) => ({
  type: actionTypes.GET_SETTINGS,
  variables
});

export const getSettingsSuccess = (payload) => ({
  type: actionTypes.GET_SETTINGS_SUCCESS,
  payload
});

export const getSettingsError = (error) => ({
  type: actionTypes.GET_SETTINGS_ERROR,
  payload: error
});

export const clearSearch = () => ({ type: actionTypes.CLEAN_SETTINGS });

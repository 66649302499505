import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import useModal from '../../../../hooks/shared/mlModal/useModal';
import DefaultOpenButton from './DefaultOpenButton';
import DefaultCancelConfirmModal from './DefaultCancelConfirmModal';
import ModalHeader from './ModalHeader';
import MLModal from '../index';
import MLAntdModalFullScreen from '../../MLAntdModalFullScreen';
import useOpenModal from '../../../../hooks/shared/mlModal/useOpenModal';
import TransparentCard from '../../cards/TransparentCard';
import '../../../../static/styles/shared/MLModal/ml-modal-with-fixed-layout.less';
import '../../../../static/styles/shared/MLModal/ml-modal-with-fixed-layout-defaults.less';

const MLModalWithFixedLayout = ({
  fullScreen,
  children,
  className,
  title,
  openButtonText,
  okButtonText,
  handleOpen,
  handleOk,
  handleCancel,
  Header,
  footerComponents,
  onFormSubmit,
  OpenButton,
  CancelConfirmModal,
  FormComponent,
  cancelConfirm
}) => {
  const formRef = useRef();
  const { open: openCancelConfirm, refOpen: refCancelConfirm } = useOpenModal();
  const modal = useModal();
  const { openModal, closeModal } = modal.events;

  const getClassName = () => {
    let classNameResult = 'ml-modal-with-fixed-layout ml-modal-with-fixed-layout-defaults';
    if (className) classNameResult += ` ${className}`;
    return classNameResult;
  };

  const handleSubmit = () => FormComponent && formRef.current.props.onSubmit(modal.events);
  const onOpen = () => (handleOpen || openModal)(modal.events);
  const onOk = () => {
    if (FormComponent && !handleOk) return handleSubmit(modal.events);
    return (handleOk || closeModal)(modal.events);
  };
  const onCancel = () => {
    if (cancelConfirm) return openCancelConfirm();
    return !modal.props.okHandling && (handleCancel || closeModal)(modal.events);
  };

  const getFooter = () => {
    const haveFooterComponents = footerComponents?.length || !footerComponents;

    return haveFooterComponents ? footerComponents : [
      <Button key={uuidv4()} loading={modal.props.okHandling} type="primary" onClick={onOk}>
        {okButtonText}
      </Button>
    ];
  };

  const CancelConfirm = CancelConfirmModal || DefaultCancelConfirmModal;
  const FixedModal = fullScreen ? MLAntdModalFullScreen : MLModal;

  return (
    <>
      <OpenButton {...{ text: openButtonText, onClick: onOpen }}/>

      <FixedModal
        className={getClassName()}
        closable={false}
        closeIcon={false}
        visible={modal.props.visible}
        title={Header ? (
          <Header {...{
            DefaultHeader: () => <ModalHeader {...{ title, onCancel }}/>,
            title,
            onCancel
          }}/>
        ) : (
          <ModalHeader {...{ title, onCancel }}/>
        )}
        footer={getFooter()}
      >
        {FormComponent ? (
          <TransparentCard>
            <FormComponent {...{ formRef, onSubmit: onFormSubmit, values: modal.values }} />
          </TransparentCard>
        ) : (
          children
        )}

        <CancelConfirm {...{
          refOpen: refCancelConfirm,
          ...cancelConfirm,
          handleOk: (cancelConfirmEvents) => {
            if (cancelConfirm?.handleOk) {
              return cancelConfirm.handleOk(cancelConfirmEvents, modal.events);
            }

            cancelConfirmEvents.closeModal();
            modal.events.closeModal();
            return null;
          }
        }}/>
      </FixedModal>
    </>
  );
};

MLModalWithFixedLayout.propTypes = {
  fullScreen: PropTypes.bool,
  children: PropTypes.element,
  OpenButton: PropTypes.elementType,
  FormComponent: PropTypes.elementType,
  CancelConfirmModal: PropTypes.elementType,
  cancelConfirm: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  className: PropTypes.string,
  title: PropTypes.string,
  openButtonText: PropTypes.string,
  okButtonText: PropTypes.string,
  openModal: PropTypes.func,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  Header: PropTypes.elementType,
  footerComponents: PropTypes.array
};

MLModalWithFixedLayout.defaultProps = {
  fullScreen: false,
  children: <p>Some content</p>,
  OpenButton: DefaultOpenButton,
  CancelConfirmModal: DefaultCancelConfirmModal,
  FormComponent: null,
  cancelConfirm: false,
  className: '',
  title: 'Basic Modal',
  openButtonText: 'Abrir Modal',
  okButtonText: 'Entendido',
  openModal: null,
  handleOk: null,
  handleCancel: null,
  Header: null,
  footerComponents: []
};

export default MLModalWithFixedLayout;

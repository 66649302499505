import { Fragment, lazy } from 'react';

const MyEvolutionTeamEvaluationsPotential = lazy(() => import('../../../../../containers/myTeam/myEvolution/Evaluations/Potential'));

export default [
  {
    exact: false,
    path: '/mi-equipo/mi-evolucion/evaluaciones/potencial',
    section: 'Mi Equipo - Mi Evolución - Evaluaciones - Potencial',
    component: MyEvolutionTeamEvaluationsPotential,
    subRoutes: [ /* DEBERIA CAMBIARSE EL headerTitle */
      {
        exact: true,
        path: '/mi-equipo/mi-evolucion/evaluaciones/potencial/:title',
        section: 'Mi Evolución -> Mi Gestión -> Evaluación de Potencial',
        headerTitle: 'Evaluación de potencial',
        auth: ['MY_TEAM_MY_EVOLUTION_EVALUATIONS', 'MY_TEAM_MY_EVOLUTION_EVALUATIONS_VALIDATOR'],
        component: Fragment
      }
    ]
  }
];

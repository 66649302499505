import gql from 'graphql-tag';

export const categoryFields = `
    {
        _id
        name
        description
        finalSkillDescription
        objetivesDescription
        finalObjetivesDescription
        finalPerformanceDescription
        skillDescription
        score
        range{
            from
            to
        }
        year
        createdAt
        updatedAt
        type
    }
    
`;

export const GET_LIST_PERFORMANCE_CATEGORIES = gql`
    query listPerformanceCategories(
        $type: CategoryType
    ) 
    {
        listPerformanceCategories(
            type: $type
        ) ${categoryFields}
    }
`;

import React from 'react';
import PropTypes from 'prop-types';

const SectionContainer = ({
  label, description, children, className
}) => (
  <div className={`section-container ${className}`}>
    {label
      && <p className='text-title-container'>{label}</p>
    }
    { description
        && <p className='text-description'>{description}</p>
    }
    {children}
  </div>
);

SectionContainer.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string
};

SectionContainer.defaultProps = {
  label: null,
  description: null,
  children: null,
  className: ''
};

export default SectionContainer;

import React, { useState, useEffect } from 'react';
import {
  useRouteMatch,
  useLocation
} from 'react-router-dom';

import MyEvaluations from '../../../components/mievolucion/Retro';
import '../../../static/styles/mievolucion/retro/styles.less';
import { RETRO_URL } from '../../../helpers/myEvolution/retro';

const Retro = () => {
  const [, setHidden] = useState(true);
  const [, setShowMyEvaluations] = useState(false);
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  useEffect(() => {
    if (path === pathname && pathname === RETRO_URL) {
      setShowMyEvaluations(true);
    } else {
      setShowMyEvaluations(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (pathname === path) setHidden(false);
  }, [pathname, path]);

  return (
    <div className='container-retro-evaluation'>
      <MyEvaluations />

    </div>
  );
};

export default Retro;

import React from 'react';
import '../../../../../static/styles/mievolucion/objectives2022/ModalObjectivesChildren.less';

const ModalObjectivesChildren = () => (<div
  className="modal-objectives-children">
  <p className="description">Se deben definir mínimo 3 objetivos y máximo 10,  donde el porcentaje de cada uno no debe ser menor a 5% ni mayor a 50%
Cada objetivo debe tener al menos una medida de éxito y máximo 5.
  </p>
  <p className="title">¿Qué son los objetivos?</p>
  <p className="description">Definición de las metas del colaborador, por las que trabajará durante el año.</p>

  <p className="title">¿Qué son los pilares?</p>
  <p className="description">Determinan el tipo de objetivo que tendrá durante el año cada colaborador.</p>
  <p className="title">¿Cuántos tipos de pilares hay?</p>
  <p className="description">En este el año 2023 se dividirán en 2 tipos de pilares:</p>
  <ul>
    <li><span><b>Transversales:</b> Objetivos que son focos para Walmart Chile este año.</span></li>
    <li><span><b>Específicos:</b>Objetivos de mi gerencia,
      de la estrategia y de otros temas relevantes que potencien a Walmart Chile.</span></li>
  </ul>
  <p className="title">¿Qué son las medidas de éxito?</p>
  <p className="description pre-ul">Es un resultado de generado en un plan específico. Deben tener mínimo 1 y máximo 5 por cada objetivo. (son las acciones concretas que nos permitirán alcanzar nuestros objetivos definidos, en un tiempo determinado)</p>
</div>);

export default ModalObjectivesChildren;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import { addNewSearchWord } from '../api/search';
import { actionTypes, addSearchExito, addSearchError } from '../actions/search';

es6promise.polyfill();

function* addNewSearch(word) {
  try {
    const response = yield call(addNewSearchWord, word.payload);
    yield put(addSearchExito(response.result));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(addSearchError({ error, statusCode }));
  }
}

function* searchSagas() {
  yield all([takeLatest(actionTypes.ALL_SEARCH, addNewSearch)]);
}
export default searchSagas;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { isEmpty } from 'lodash';
import PerformanceEvaluationCard from '../../../../shared/PerformanceEvaluation/PerformanceEvaluationCard';
import {
  COMMUNICATED,
  VIEW_AS_EVALUATED
} from '../../../../../../helpers/myEvolution/evaluations';
import MLModalFullScreen from '../../../../../shared/MLModalFullScreen';
import ModalMessage from '../../../../../shared/ModalMessage';
import EvaluationModal from '../../../../shared/PerformanceEvaluation/EvaluationModal';
import ModalOpinion from '../../ModalOpinion';

const elementsCompleted = (elements) => elements.every((element) => element.categorySelected?.name);
const elementsOnGoing = (elements) => elements.some((element) => element.categorySelected?.name);

const ActiveEvaluation = ({ evaluationSelected, refetchQueries, reload }) => {
  const { evaluation, autoEvaluation, showButtonOpinion } = evaluationSelected || {};
  const { objectives } = autoEvaluation;
  const { status: evaluationStatus } = evaluation;
  const { status: autoEvaluationStatus } = autoEvaluation;
  const [bigModal, setBigModal] = useState({ visible: false });
  const [startClose, setStartClose] = useState(false);

  const [modalMessage, setModalMessage] = useState({
    visible: false,
    title: '',
    message: '',
    icon: '',
    type: ''
  });

  const closeBigModal = () => {
    setBigModal({ visible: false });
    setStartClose(true);
  };

  const onModalClose = () => {
    setStartClose(false);
    closeBigModal();
  };

  const getCurrentStep = () => {
    if (elementsCompleted(autoEvaluation.objectives)) return 3;
    if (elementsCompleted(autoEvaluation.skills)) return 2;
    if (elementsOnGoing(autoEvaluation.skills)) return 1;
    return 0;
  };

  return (
    <div>
      <PerformanceEvaluationCard
        {...{
          showButtonOpinion,
          viewAs: VIEW_AS_EVALUATED,
          evaluation,
          autoEvaluation,
          handleModalOpinion: () => {
            setBigModal({ visible: true, type: 'opinion', title: 'Opinión de mi evaluación' });
          }
        }}
      >
        {![evaluationStatus, autoEvaluationStatus].includes(COMMUNICATED) && (
          <div className='d-flex justify-content-center mt-3'>
            <Button
              onClick={() => setBigModal({ visible: true, type: 'auto', title: 'Autoevaluación' })}
              disabled={isEmpty(objectives)}
              type='primary'
            >
              {getCurrentStep() > 0 ? 'Continuar' : 'Iniciar'} autoevaluación
            </Button>
          </div>
        )}
      </PerformanceEvaluationCard>

      {modalMessage.visible && (
        <ModalMessage
          {...modalMessage}
          closeModal={() => setModalMessage({ visible: false })}
        />
      )}

      {bigModal?.visible && (
        <MLModalFullScreen
          zIndex={1000}
          visible={bigModal.visible}
          closeModal={closeBigModal}
          title={bigModal.title}
          fullScreen={true}
        >
          { bigModal.type === 'auto'
            ? <EvaluationModal
              viewAs={VIEW_AS_EVALUATED}
              refetchQueries={refetchQueries}
              startModalClose={startClose}
              currentStep={getCurrentStep() || 1}
              onModalClose={onModalClose}
              reload={reload}
            />
            : <ModalOpinion
              setModalMessage={setModalMessage}
              evaluatorFullName={`${evaluation?.evaluator?.name} ${evaluation?.evaluator?.fatherLastName}`}
              evaluationID={evaluation._id}
              closeOpinion={closeBigModal} />
          }
        </MLModalFullScreen>
      )}
    </div>
  );
};

ActiveEvaluation.propTypes = {
  evaluationSelected: PropTypes.object,
  refetchQueries: PropTypes.array,
  reload: PropTypes.func
};

ActiveEvaluation.defaultProps = {
  evaluationSelected: {},
  refetchQueries: [],
  reload: () => {}
};
export default ActiveEvaluation;

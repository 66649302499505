import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import moment from 'moment';
import {
  getEvolucionaTESTAPI,
  getFeedbackAPI,
  getPerformanceAPI,
  getPerformanceWalmartAPI,
  getPerformanceWalmartProgressAPI,
  getPerformanceWalmartTemplateAPI,
  getSurveyPerformanceWalmartProgressAPI,
  getInterestAndSkillsAPI,
  getFeedbackNetworkAPI,
  getCompetenciesAPI,
  getSocialAssistantAPI,
  getSectionReportAPI,
  getObjectivesAPI,
  getSocialAssistantListAPI,
  getEDDAPI,
  getMeritLettersReportAPI,
  getStatusFeedbackNetworkReportAPI,
  getTraceabilityReportAPI,
  getEDDAutoevaluationsAPI,
  getEDDEvaluationsAPI,
  getEDPAPI,
  getEDDOpinionEvaluationAPI,
  getMeritsTraceabilityAPI,
  getReconocimientoAPI,
  getReconocimientoComentariosAPI,
  getIDPAPI
} from '../api/admin';

import {
  actionTypes,
  getSocialAssistantSuccess,
  gelSocialAssistantError,
  getObjectivesReportsSuccess,
  getObjectivesReportsError,
  getSectionReportSuccess,
  getSectionReportFailure,
  getEvolucionaTESTReportsError,
  getEvolucionaTESTReportsSuccess,
  getEDDReportError,
  getEDDReportSuccess,
  getMeritLettersReportSuccess,
  getMeritLettersReportError,
  getTraceabilityReportSuccess,
  getTraceabilityReportError,
  getMeritsTraceabilitySuccess,
  getMeritsTraceabilityError,
  getReconocimientoSuccess,
  getReconocimientoError,
  getReconocimientoComentariosSuccess,
  getReconocimientoComentariosError
} from '../actions/admin';

import { downloadExcelBlobFromRest } from '../../helpers/workSheetHandler';
import { failure as commonsFailure } from '../actions/commons';

es6promise.polyfill();

export function* getSocialAssistantSaga() {
  try {
    const response = yield call(getSocialAssistantAPI);
    yield put(getSocialAssistantSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(gelSocialAssistantError(error));
  }
}

export function* getRecomonocimientoSaga() {
  try {
    const response = yield call(getReconocimientoAPI);
    yield put(getReconocimientoSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getReconocimientoError(error));
  }
}

export function* getRecomonocimientoComentariosSaga() {
  try {
    const response = yield call(getReconocimientoComentariosAPI);
    yield put(getReconocimientoComentariosSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getReconocimientoComentariosError(error));
  }
}

export function* getSectionReport() {
  try {
    const response = yield call(getSectionReportAPI);
    yield put(getSectionReportSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(
      statusCode === 401 ? commonsFailure({
        error,
        statusCode
      }) : getSectionReportFailure({
        error,
        statusCode
      })
    );
  }
}

export function* getTraceabilityReport() {
  try {
    const response = yield call(getTraceabilityReportAPI);
    yield put(getTraceabilityReportSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(
      statusCode === 401 ? commonsFailure({
        error,
        statusCode
      }) : getTraceabilityReportError({
        error,
        statusCode
      })
    );
  }
}

export function* getPerformanceReport(action) {
  yield call(getPerformanceAPI, action.socketId, action.year);
}

export function* getPerformanceWalmartReport(action) {
  yield call(getPerformanceWalmartAPI, action.year);
}

export function* getSurveyPerformanceWalmart(action) {
  yield call(getSurveyPerformanceWalmartProgressAPI, action.socketId, action.year);
}

export function* getPerformanceWalmartProgressReport(action) {
  yield call(getPerformanceWalmartProgressAPI, action.socketId);
}

export function* getPerformanceWalmartTemplateReport(action) {
  yield call(getPerformanceWalmartTemplateAPI, action.socketId);
}

export function* getInterestAndSkillsReport(action) {
  yield call(getInterestAndSkillsAPI, action.socketId);
}

export function* getFeedbackNetworkReport(action) {
  yield call(getFeedbackNetworkAPI, action.socketId, action.year);
}

export function* getCompetenciesReport(action) {
  yield call(getCompetenciesAPI, action.socketId, action.year);
}

export function* getFeedback(action) {
  yield call(getFeedbackAPI, action.socketId);
}

export function* getEvolucionaTEST() {
  try {
    const response = yield call(getEvolucionaTESTAPI);
    yield put(getEvolucionaTESTReportsSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getEvolucionaTESTReportsError(error));
  }
}

export function* getObjectives() {
  try {
    const response = yield call(getObjectivesAPI);
    yield put(getObjectivesReportsSuccess(response));
  } catch (err) {
    const error = err?.response?.data ? get(err, 'response.data') : err;
    yield put(getObjectivesReportsError(error));
  }
}

export function* getSocialAssistantListSaga() {
  try {
    const file = yield call(getSocialAssistantListAPI);
    const fileName = `asistentes-sociales_${moment().format('DD-MM-YYYY')}.xlsx`;
    downloadExcelBlobFromRest(file, fileName);
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(gelSocialAssistantError(error));
  }
}

export function* getEDDReport(action) {
  try {
    const response = yield call(getEDDAPI, action.year);
    yield put(getEDDReportSuccess(response));
  } catch (err) {
    const error = err?.response?.data ? get(err, 'response.data') : err;
    yield put(getEDDReportError(error));
  }
}
export function* getMeritLettersReport() {
  try {
    const response = yield call(getMeritLettersReportAPI);
    yield put(getMeritLettersReportSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getMeritLettersReportError(error));
  }
}

export function* getStatusFeedbackNetwork(action) {
  yield call(getStatusFeedbackNetworkReportAPI, action.socketId);
}

export function* getEDDAutoevaluationsReport(action) {
  yield call(getEDDAutoevaluationsAPI, action.year);
}

export function* getEDDEvaluationsReport(action) {
  yield call(getEDDEvaluationsAPI, action.year);
}

export function* getEDPReport(action) {
  yield call(getEDPAPI, action.year);
}

export function* getIDPReport(action) {
  yield call(getIDPAPI, action.socketId);
}

export function* getEDDOpinionEvaluation(action) {
  yield call(getEDDOpinionEvaluationAPI, action.socketId, action.year);
}

export function* getMeritsTraceability(action) {
  try {
    const response = yield call(getMeritsTraceabilityAPI, action.year);
    yield put(getMeritsTraceabilitySuccess(response));
  } catch (err) {
    const error = err?.response?.data ? get(err, 'response.data') : err;
    yield put(getMeritsTraceabilityError(error));
  }
}

function* adminReportSagas() {
  yield all([
    takeLatest(actionTypes.GET_FEEDBACK, getFeedback),
    takeLatest(actionTypes.GET_OBJECTIVES, getObjectives),
    takeLatest(actionTypes.GET_EVOLUCIONATEST, getEvolucionaTEST),
    takeLatest(actionTypes.GET_SOCIAL_ASSISTANT_REPORT, getSocialAssistantSaga),
    takeLatest(actionTypes.GET_SOCIAL_ASSISTANT_LIST, getSocialAssistantListSaga),
    takeLatest(actionTypes.GET_SECTION_REPORT, getSectionReport),
    takeLatest(actionTypes.GET_TRACEABILITY_REPORT, getTraceabilityReport),
    takeLatest(actionTypes.GET_EDD_REPORTS, getEDDReport),
    takeLatest(actionTypes.GET_PERFORMANCE_REPORTS, getPerformanceReport),
    takeLatest(actionTypes.GET_PERFORMANCE_WALMART_REPORTS, getPerformanceWalmartReport),
    takeLatest(actionTypes.GET_FEEDBACKNETWORK_REPORTS, getFeedbackNetworkReport),
    takeLatest(actionTypes.GET_INTERESTANDSKILLS_REPORTS, getInterestAndSkillsReport),
    takeLatest(actionTypes.GET_COMPETENCIES_REPORTS, getCompetenciesReport),
    takeLatest(actionTypes.GET_MERIT_LETTERS_REPORT, getMeritLettersReport),
    takeLatest(actionTypes.GET_STATUS_FEEDBACK_NETWORK, getStatusFeedbackNetwork),
    takeLatest(actionTypes.GET_EDD_AUTOEVALUATIONS_REPORTS, getEDDAutoevaluationsReport),
    takeLatest(actionTypes.GET_EDD_EVALUATIONS_REPORTS, getEDDEvaluationsReport),
    takeLatest(actionTypes.GET_EDP_REPORTS, getEDPReport),
    takeLatest(actionTypes.GET_EDD_OPINION_EVALUATION_REPORT, getEDDOpinionEvaluation),
    takeLatest(actionTypes.GET_MERITS_TRACEABILITY, getMeritsTraceability),
    takeLatest(actionTypes.GET_PERFORMANCE_WALMART_PROGRESS_REPORTS,
      getPerformanceWalmartProgressReport),
    takeLatest(actionTypes.GET_SURVEY_PERFORMANCE_WALMART_REPORTS,
      getSurveyPerformanceWalmart),
    takeLatest(actionTypes.GET_PERFORMANCE_WALMART_TEMPLATE_REPORTS,
      getPerformanceWalmartTemplateReport),
    takeLatest(actionTypes.GET_RECONOCIMIENTO_REPORT, getRecomonocimientoSaga),
    takeLatest(actionTypes.GET_RECONOCIMIENTO_COMMENTS_REPORT,
      getRecomonocimientoComentariosSaga),

    takeLatest(actionTypes.GET_IDP_REPORT, getIDPReport)
  ]);
}

export default adminReportSagas;

import moment from 'moment';
import { capitalize } from 'lodash';
import 'moment/locale/es';

moment.locale('es');

export const formatDateCard = (date) => capitalize(moment(new Date(date)).utc().format('ddd DD MMM YYYY').replace('.', ',')
  .replace('.', ' '));

export const customFormatDateCard = (date, format) => capitalize(
  moment(new Date(date)).utc().format(format)
);

export const formatDateUniformsInfoMessage = (date) => moment(new Date(date))
  .utc()
  .format('DD [de] MMMM [de] YYYY')
  .replace('.', ',')
  .replace('.', ' ');

export const setMLFormatDate = (date) => capitalize(moment(date)?.format('ddd[,] DD MMM YYYY')?.replaceAll('.', ''));

export const actionTypes = {
  ALL_PODIUM: 'ALL_PODIUM',
  ALL_PODIUM_SUCCESS: 'ALL_PODIUM_SUCCESS',
  ALL_PODIUM_ERROR: 'ALL_PODIUM_ERROR'
};

export const getPodium = () => ({
  type: actionTypes.ALL_PODIUM,
  payload: true
});

export const getPodiumSuccess = (data) => ({
  type: actionTypes.ALL_PODIUM_SUCCESS,
  payload: data
});

export const getPodiumError = (error) => ({
  type: actionTypes.ALL_PODIUM_ERROR,
  payload: error
});

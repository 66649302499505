import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  DatePicker, Col, Row
} from 'antd';
// import moment from 'moment';
import BossSuccessMeasures from '../BossSuccessMeasures';
import MLSeparator from '../../../../../../../shared/Separator';
import MLAddButton from '../../../../../../../shared/buttons/AddButton';
import MLDeleteButton from '../../../../../../../shared/buttons/DeleteButton';
import MLTextArea from '../../../../../../../shared/MLTextArea';
import useSuccessMeasure from '../../../../../../../../hooks/myEvolution/myPerformance/useSuccessMeasure';
import getObjectivesValidateValues from '../../../../../../../../helpers/myEvolution/myPerformance/getObjectivesValidateValues';
import SuccessMeasuresUpdates from './SuccessMeasuresUpdates';
import RenderField from '../RenderField';
import '../../../../../../../../static/styles/mievolucion/myPerformance/performance-objective-success-measures.less';

const SuccessMeasures = ({
  form,
  FormItem,
  successMeasuresBoss,
  defaultData,
  bossName,
  successMeasuresOnly
}) => {
  const { getFieldsValue, getFieldDecorator, setFieldsValue } = form;
  const {
    successMeasuresLength,
    addSucessMeasure,
    copySucessMeasure,
    removeSucessMeasure,
    filterSuccessMeasuresLength
  } = useSuccessMeasure({
    defaultData, getFieldsValue, setFieldsValue
  });
  const { minSuccessMeasures, maxSuccessMeasures } = getObjectivesValidateValues();

  const renderSuccessMeasures = () => {
    const results = [];

    const getClassName = (index) => {
      let classNameResult = 'success-measure-description primary-label';
      if (successMeasuresOnly && index < defaultData.length) classNameResult += ' hidden';
      return classNameResult;
    };

    for (let i = 0; i < successMeasuresLength; i += 1) {
      const canEditOrDelete = !successMeasuresOnly || i >= defaultData.length;
      const successMeasuresDescriptionLabel = `Medida de éxito ${i + 1}`;

      results.push(
        <Fragment key={i}>
          <FormItem label={`${successMeasuresDescriptionLabel}:`} className={getClassName(i)}>
            {getFieldDecorator(`successMeasures[${i}][description]`, {
              rules: [
                {
                  required: true,
                  message: 'Este campo es necesario para continuar.'
                }
              ]
            })(
              <MLTextArea
                placeholder='Describe la medida de éxito'
                disabled={!canEditOrDelete}
              />
            )}
          </FormItem>

          {!canEditOrDelete && (
            <RenderField {...{
              className: 'success-measure-render-field',
              direction: 'column',
              label: successMeasuresDescriptionLabel,
              value: defaultData[i].description
            }}/>
          )}

          <Row>
            <Col span={12}>
              <FormItem label='Fecha límite de cumplimiento:' className='success-measure-date-measure'>
                {getFieldDecorator(`successMeasures[${i}][dateMeasure]`, {
                  // rules: [{
                  //   validator: (_, value, callback) => (
                  //     value?.year() === moment().year() ? callback() : callback(
                  //       `Ingresa una fecha dentro del año ${moment().year()}.`
                  //     )
                  //   )
                  // }]
                })(
                  <DatePicker
                    format='ddd DD MMM YYYY'
                    suffixIcon={canEditOrDelete && <i className='icon-ml-select-dd'/>}
                    disabled={!canEditOrDelete}
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <SuccessMeasuresUpdates {...{ form, index: i }}/>
            </Col>
          </Row>

          {canEditOrDelete && successMeasuresLength > minSuccessMeasures && (
            <MLDeleteButton onClick={() => removeSucessMeasure(i)}/>)
          }

          {i < successMeasuresLength - 1 ? (
            <MLSeparator/>
          ) : null}
        </Fragment>
      );
    }

    return results;
  };

  return (
    <div className='performance-objective-success-measures'>
      {!!successMeasuresBoss.length && (
        <BossSuccessMeasures {...{ successMeasuresBoss, copySucessMeasure, bossName }}/>
      )}

      {renderSuccessMeasures()}

      {filterSuccessMeasuresLength() < maxSuccessMeasures && (
        <div>
          <MLAddButton text='Agregar medidas de éxito' onClick={addSucessMeasure}/>
        </div>
      )}
    </div>
  );
};

SuccessMeasures.propTypes = {
  form: PropTypes.object,
  FormItem: PropTypes.elementType,
  defaultData: PropTypes.array,
  successMeasuresBoss: PropTypes.array,
  bossName: PropTypes.string,
  successMeasuresOnly: PropTypes.bool
};

SuccessMeasures.defaultProps = {
  form: {},
  FormItem: Fragment,
  defaultData: [{ description: '', dateMeasure: null }],
  successMeasuresBoss: [],
  bossName: 'Jefe',
  successMeasuresOnly: false
};

export default SuccessMeasures;

import getCredentialPermissionsNames from './getCredentialPermissionsNames';

const getCredentialPermissions = (permissions) => {
  const results = { children: {} };
  const credentialPermissionsNames = getCredentialPermissionsNames();

  credentialPermissionsNames.children.map((permissionName) => {
    if (permissions[permissionName]) results.children[permissionName] = true;
    return null;
  });

  results.parents = credentialPermissionsNames.parents;

  return results;
};

export default getCredentialPermissions;

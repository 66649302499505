import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { GET_IDP } from '../../../graphql/mievolucion/miDesarrollo/queries';
import { MY_EVOLUTION_CONTEXT } from '../../../helpers/apollo';

const context = MY_EVOLUTION_CONTEXT;

const useGetIDP = () => {
  const {
    rut: loggedUserRut
  } = useSelector((state) => state.userReducer.data);

  const {
    loading,
    data: dataIDP,
    error,
    refetch
  } = useQuery(
    GET_IDP,
    {
      context,
      variables: { rut: loggedUserRut },
      fetchPolicy: 'network-only'
    }
  );

  const IDP = get(dataIDP, 'getIDP.data', {});
  const message = get(dataIDP, 'getIDP.message', null);
  const success = get(dataIDP, 'getIDP.success', false);

  return {
    IDP,
    message,
    loading,
    success,
    error,
    refetch
  };
};
export default useGetIDP;

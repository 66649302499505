import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import '../../../static/styles/shared/sectionList.less';

const Card = ({
  index, item, getTextSpace, textSpace
}) => {
  const textRef = useRef({});
  const [isMobile, setIsMobile] = useState(null);
  const handleTextSpace = () => {
    if (textRef.current) {
      getTextSpace(index, textRef.current.clientHeight);
    }
  };
  const handleWidth = () => {
    setIsMobile(window.innerWidth < 576);
    handleTextSpace(textRef.current.clientHeight);
  };

  useEffect(() => {
    if (isMobile === null) handleWidth();
    window.addEventListener('resize', handleWidth);
    return () => window.removeEventListener('resize', handleWidth);
  }, []);

  useEffect(() => handleTextSpace(textRef.current.clientHeight), [textRef.current.clientHeight]);

  return (
    <div className="card">
      <div
        className="content-card"
      >
        <div
          className="img"
          style={{ backgroundImage: `url(${item.webSetup ? item.webSetup.image : item.image})` }}
        />
        <div className="d-flex align-items-center justify-content-start" style={isMobile ? { height: textSpace } : null}>

          <p ref={textRef} className="mb-0">
            {item.title}
          </p>

        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  getTextSpace: PropTypes.func,
  textSpace: PropTypes.number
};

Card.defaultProps = {
  index: null,
  item: {},
  getTextSpace: () => {},
  textSpace: PropTypes.number
};

export default Card;

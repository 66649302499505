import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const ModalHeader = ({
  title, onCancel
}) => (
  <div className='ml-modal-with-fixed-layout-header'>
    <h6>{title}</h6>

    <Button
      type='link'
      onClick={onCancel}
    >
      <i className='icon-ml-close'/>
    </Button>
  </div>
);

ModalHeader.propTypes = {
  onCancel: PropTypes.func,
  title: PropTypes.string
};

ModalHeader.defaultProps = {
  onCancel: () => null,
  title: ''
};

export default ModalHeader;

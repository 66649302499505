import { isEmpty } from 'lodash';
import {
  CLOSED, FIRST_PULSE, PENDING_BY_BOSS, PENDING_BY_COLABORATE, RETURNED, SENT, WITHOUTINFO
} from '.';

export const getAddObjective = ({
  userCouldUpdatePerformance,
  q1,
  isBoss,
  withPulseAndManualObjective,
  returned,
  pendingByColaborate,
  previuosStatus,
  objectiveEdit

}) => {
  if (!userCouldUpdatePerformance) {
    return false;
  }
  if (q1) {
    return !isBoss
   && withPulseAndManualObjective;
  }
  if (!isBoss
    && withPulseAndManualObjective
    && (
      (
        pendingByColaborate && objectiveEdit)
      || returned
       || (
         pendingByColaborate
        && [RETURNED, PENDING_BY_COLABORATE, PENDING_BY_BOSS, WITHOUTINFO]
          .includes(previuosStatus)))) {
    return true;
  }
  return false;
};
export const getEditAdvance = ({
  userCouldUpdatePerformance,
  q1,
  isBoss,
  sent,
  MANUAL,
  returned,
  pendingByColaborate,
  CENTRALIZADO,
  previuosStatus
}) => {
  if (q1 || !userCouldUpdatePerformance) return false;
  if (isBoss) {
    return sent;
  }
  return (MANUAL && pendingByColaborate || returned
  ) || (CENTRALIZADO && previuosStatus === CLOSED || returned);
};
export const getShowActionsVisible = ({
  q1, userCouldUpdatePerformance, editAdvance, withPulseAndManualObjective
}) => {
  if (!userCouldUpdatePerformance) return false;
  if (q1) {
    if (withPulseAndManualObjective) return true;
  }
  return editAdvance;
};

export const getSentToBoss = ({
  q1, isBoss, withPulseAndManualObjective, editAdvance, userCouldUpdatePerformance
}) => {
  if (isBoss || !userCouldUpdatePerformance) return false;
  if (q1) {
    if (withPulseAndManualObjective) return true;
  }
  return editAdvance;
};

export const getClosePerformance = ({
  q1, isBoss, sent, withPulseAndManualObjective, livePulse, CENTRALIZADO, previuosStatus
}) => {
  if (q1) {
    return withPulseAndManualObjective && isBoss && sent;
  }
  if (isBoss) {
    if (!livePulse && CENTRALIZADO) return false;
    if (livePulse && CENTRALIZADO && previuosStatus !== CLOSED) return false;
    return sent;
  }
  return false;
};
export const getShowPerformanceTable = ({
  withoutObjectives,
  isBoss,
  closed,
  objectiveType,
  mayShowPerformanceTable,
  noneBossCanShowPerformanceTable,
  showErrorPerformanceWithFixedObjective,
  CENTRALIZADO,
  withPulseAndManualObjective,
  q1,
  previuosStatus,
  withoutPulseAndfixedObjective,
  showMessageFirstPulsePendingByColaborate,
  showMessageFirstPulsePendingByUploadObjectives,
  notStartPerformance,
  showMessagePendingByBoss
}) => {
  if (withoutObjectives || notStartPerformance) return false;
  if (isBoss && showMessageFirstPulsePendingByColaborate) return false;
  if (showMessagePendingByBoss
     || showMessageFirstPulsePendingByUploadObjectives) {
    return false;
  }

  if (!isBoss) {
    if (withoutPulseAndfixedObjective && !closed) return false;

    if ((withPulseAndManualObjective)
     || (q1 && closed && CENTRALIZADO)
    || (!q1 && CENTRALIZADO && (previuosStatus === CLOSED || closed))) return true;
    if (CENTRALIZADO) return false;
  }
  if (!objectiveType || showErrorPerformanceWithFixedObjective) return false;
  if (isBoss && (mayShowPerformanceTable
    || noneBossCanShowPerformanceTable)) return true;

  return false;
};

export const getUserCentralizadoWithoutObjectives = ({
  q1, CENTRALIZADO, closed, previuosStatus
}) => {
  if (CENTRALIZADO) {
    if (q1) {
      return !closed;
    }
    if (previuosStatus !== CLOSED && !closed) {
      return true;
    }
    if (previuosStatus === CLOSED) {
      return false;
    }
  }
  return false;
};

const getShowBossInputComment = ({
  q1, isBoss, livePulse, sent
}) => {
  if (q1) return false;
  return isBoss && livePulse && sent;
};
const getEditObjectives = ({
  addObjectives, objectiveEdit, returned, pendingByColaborate, previuosStatus
}) => {
  if (addObjectives) return true;
  if (objectiveEdit
    && (returned
    || pendingByColaborate)
     && [RETURNED, PENDING_BY_BOSS, PENDING_BY_COLABORATE, WITHOUTINFO].includes(previuosStatus)) {
    return true;
  }
  return false;
};

const getComponentsPermissions = ({

  myPerformance,
  bossParams,
  linearBossWithObjectives
}) => {
  const livePulse = myPerformance?.data?.user?.livePulse;
  const objectiveType = myPerformance?.data?.user?.objectiveType;
  const objectiveEdit = myPerformance?.data?.objectiveEdit?.status;
  const CENTRALIZADO = objectiveType === 'CENTRALIZADO';
  const MANUAL = objectiveType === 'MANUAL';
  const withoutPulseAndfixedObjective = !livePulse && CENTRALIZADO;
  const withPulseAndManualObjective = livePulse && MANUAL;
  const withPulseAndFixedObjective = livePulse && CENTRALIZADO;

  const isBoss = !!bossParams;
  const dataExists = !!myPerformance.data;
  const currentQuarter = myPerformance.data?.quarterCurrent;
  const comments = myPerformance.data?.comments;
  const { commentBoss, commentColaborate } = (comments && comments[currentQuarter]) || {};
  const haveCommentData = !!commentBoss || !!commentColaborate;

  const q1 = currentQuarter === FIRST_PULSE;
  const previousQuarter = `q${Number(currentQuarter?.split('q')?.pop()) - 1}`;
  const previuosStatus = (myPerformance.data?.statusByQuarter[previousQuarter] || {}).status;
  const status = myPerformance.data?.status?.current;
  const atLeastOnceClosedStatus = myPerformance.data?.atLeastOnceClosedStatus;
  const showTouchedMessages = !!myPerformance.data?.objectives?.data?.find(
    ({ weight }) => weight !== 0
  );

  const withoutObjectives = isEmpty(myPerformance.data?.objectives?.data);

  const sent = status === SENT;
  const closed = status === CLOSED;
  const returned = status === RETURNED;
  const pendingByColaborate = status === PENDING_BY_COLABORATE;
  const pendingByBoss = status === PENDING_BY_BOSS;
  const sentToBossOnPreviousQ = previuosStatus === SENT;
  const rejectdByBossOnPreviousQ = previuosStatus === RETURNED;
  const userCouldUpdatePerformance = pendingByColaborate || returned;

  const showErrorPerformanceWithFixedObjective = q1 && (sent || returned)
  && withoutPulseAndfixedObjective;
  const showContainer = !isBoss || (isBoss && !!bossParams.selectedUser);
  const showInfoMessage = !myPerformance.loading && (
    isBoss || (dataExists || !!myPerformance.error)
  );

  const addObjectivesAsBoss = isBoss && !!myPerformance.error?.graphQLErrors?.find(
    (gqlError) => gqlError?.extensions?.code === 'PERFORMANCE_WITH_STATUS_DEFINED'
  );

  const withFixedObjectives = CENTRALIZADO;
  const showWithFixedObjectives = withFixedObjectives;
  const notStartPerformance = !isBoss && pendingByBoss;
  const closePerformance = getClosePerformance({
    q1,
    isBoss,
    sent,
    withPulseAndManualObjective,
    livePulse,
    CENTRALIZADO,
    notStartPerformance,
    previuosStatus
  });
  const returnPerformance = closePerformance && !atLeastOnceClosedStatus;

  const showSentToBossMessage = !isBoss && sent;
  const showSentToBossaAdvanceMessage = atLeastOnceClosedStatus && !isBoss && sent;
  const showReturnedMessage = isBoss && returned;
  const showBossCommentMessage = closePerformance || showReturnedMessage;
  const showReturnedInfoMessage = !isBoss && returned;
  const showClosedMessage = closed;
  const showWithClosedFixedObjectives = (
    withFixedObjectives && atLeastOnceClosedStatus
  );

  const showWithPulses = livePulse;
  const showWithPulsesAndFixedObjectives = withPulseAndFixedObjective;
  const showVisibleReport = q1 && linearBossWithObjectives;
  const showWithoutPulses = !showWithPulses && dataExists;
  const mayShowPerformanceTable = closePerformance || closed || atLeastOnceClosedStatus;
  const noneBossCanShowPerformanceTable = !isBoss && (showWithoutPulses || !notStartPerformance);

  const showMessageFirstPulsePendingByColaborate = currentQuarter === 'q2' && previuosStatus === PENDING_BY_COLABORATE && livePulse && MANUAL && pendingByColaborate;

  const showMessageFirstPulsePendingByUploadObjectives = currentQuarter === 'q2' && previuosStatus !== CLOSED && livePulse && CENTRALIZADO && !closed;

  const showMessagePendingByBoss = pendingByBoss && isBoss;
  const showUserNotSentMessage = isBoss && !sent && !showMessagePendingByBoss;
  const showPerformanceTable = getShowPerformanceTable({
    withoutObjectives,
    isBoss,
    closed,
    objectiveType,
    mayShowPerformanceTable,
    noneBossCanShowPerformanceTable,
    showErrorPerformanceWithFixedObjective,
    CENTRALIZADO,
    withPulseAndManualObjective,
    q1,
    previuosStatus,
    withoutPulseAndfixedObjective,
    showMessageFirstPulsePendingByColaborate,
    showMessageFirstPulsePendingByUploadObjectives,
    notStartPerformance,
    showMessagePendingByBoss
  });
  const showComments = !withoutPulseAndfixedObjective && haveCommentData;
  const waitByBossApprove = pendingByColaborate && sentToBossOnPreviousQ;
  const editAdvance = getEditAdvance({
    userCouldUpdatePerformance,
    q1,
    isBoss,
    sent,
    MANUAL,
    returned,
    pendingByColaborate,
    CENTRALIZADO,
    previuosStatus
  });
  const editAdvanceAfterRejectedByBoss = rejectdByBossOnPreviousQ;

  const addObjectives = getAddObjective({
    userCouldUpdatePerformance,
    q1,
    isBoss,
    withPulseAndManualObjective,
    returned,
    pendingByColaborate,
    previuosStatus,
    objectiveEdit
  });

  const editObjectives = getEditObjectives({
    addObjectives, objectiveEdit, returned, pendingByColaborate, previuosStatus
  });

  const sendToBoss = getSentToBoss({
    q1, isBoss, withPulseAndManualObjective, editAdvance, userCouldUpdatePerformance
  });

  const userCentralizadoWithoutObjectives = getUserCentralizadoWithoutObjectives({
    q1, CENTRALIZADO, closed, previuosStatus
  });
  const objectiveClosedButAdvanceNotSent = isBoss && pendingByColaborate && atLeastOnceClosedStatus;

  const showBossInputComment = getShowBossInputComment({
    q1, isBoss, livePulse, sent
  });

  const can = {
    notStartPerformance,
    addObjectives,
    addObjectivesAsBoss,
    editObjectives,
    editAdvance,
    editAdvanceAfterRejectedByBoss,
    closePerformance,
    returnPerformance,
    objectiveClosedButAdvanceNotSent,
    showTouchedMessages,
    showUserNotSentMessage,
    showSentToBossMessage,
    showContainer,
    showInfoMessage,
    showReturnedMessage,
    showReturnedInfoMessage,
    showPerformanceTable,
    showClosedMessage,
    showWithClosedFixedObjectives,
    showWithPulses,
    showWithPulsesAndFixedObjectives,
    showWithoutPulses,
    showComments,
    showWithFixedObjectives,
    showBossCommentMessage,
    sendToBoss,
    showVisibleReport,
    waitByBossApprove,
    actionsVisible: getShowActionsVisible({
      q1, userCouldUpdatePerformance, editAdvance, withPulseAndManualObjective
    }),
    showErrorPerformanceWithFixedObjective,
    userCentralizadoWithoutObjectives,
    showObjetivesError: !objectiveType,
    withPulseAndManualObjective,
    showBossInputComment,
    showMessageFirstPulsePendingByColaborate,
    showMessageFirstPulsePendingByUploadObjectives,
    showMessagePendingByBoss,
    showSentToBossaAdvanceMessage,
    livePulse
  };
  return can;
};

export default getComponentsPermissions;

import { lazy } from 'react';
import myEvolution from './myEvolution';

const AdminRepots = lazy(() => import('../../../containers/admin'));
const AdminCompensations = lazy(() => import('../../../containers/admin/Compensaciones'));
const AdminCompensationsMerito = lazy(() => import('../../../containers/admin/Compensaciones/Merito'));
const SocialAssistant = lazy(() => import('../../../containers/admin/AsistenteSocial'));
const MLFeedback = lazy(() => import('../../../containers/admin/CalificacionMiLugar'));
const BonoAnual = lazy(() => import('../../../containers/mibono/BonoAnual'));
const BonoVariable = lazy(() => import('../../../containers/mibono/BonoVariable'));
const AdminMiTiempo = lazy(() => import('../../../containers/admin/MiTiempo'));
const AdminUniforms = lazy(() => import('../../../containers/admin/Uniforms'));
const VacacionesAdministrador = lazy(() => import('../../../containers/admin/Vacaciones'));
const Reconocimiento = lazy(() => import('../../../containers/admin/reconocimiento'));
const PlanRetorno = lazy(() => import('../../../containers/admin/PlanRetorno'));
const LeaderPeopleStore = lazy(() => import('../../../containers/admin/LeaderPeopleStore'));
const VacacionesSearch = lazy(() => import('../../../components/admin/vacaciones/Search'));
const PendingVacationReports = lazy(() => import('../../../components/admin/vacaciones/PendingVacationReports'));

export default [
  {
    exact: true,
    path: '/administrador',
    section: 'Administración',
    component: AdminRepots
  },
  {
    exact: true,
    path: '/administrador/asistente-social',
    section: 'Administración -> Asistente Social',
    component: SocialAssistant,
    auth: 'ADMIN_ASISTENTE_SOCIAL'
  },
  {
    exact: true,
    path: '/administrador/calificación-milugar',
    section: 'Administración -> Calificación Mi Lugar',
    component: MLFeedback,
    auth: 'ADMIN_CALIFICACION_MI_LUGAR'
  },
  {
    exact: true,
    path: '/administrador/compensaciones',
    section: 'Administración -> Compensaciones',
    component: AdminCompensations,
    auth: 'ADMIN_COMPENSACIONES'
  },
  {
    exact: true,
    path: '/administrador/compensaciones/bono-anual',
    section: 'Administración -> Compensaciones -> Bono Anual',
    component: BonoAnual,
    auth: 'ADMIN_COMPENSACIONES'
  },
  {
    exact: true,
    path: '/administrador/compensaciones/bono-variable',
    section: 'Administración -> Compensaciones -> Bono Variable',
    component: BonoVariable,
    auth: 'ADMIN_COMPENSACIONES'
  },
  {
    exact: true,
    path: '/administrador/compensaciones/merito',
    section: 'Administración -> Compensaciones -> Mérito',
    component: AdminCompensationsMerito,
    auth: 'ADMIN_COMPENSACIONES'
  },
  {
    exact: true,
    path: '/administrador/mi-tiempo',
    section: 'Administración -> Mi Tiempo',
    component: AdminMiTiempo,
    auth: 'ADMIN_MY_TIME'
  },
  {
    exact: true,
    path: '/administrador/uniformes',
    section: 'Administración -> Uniformes',
    component: AdminUniforms,
    auth: 'ADMIN_UNIFORM'
  },
  {
    exact: false,
    tabs: true,
    firstTab: true,
    path: '/administrador/vacaciones',
    section: 'Administración -> Vacaciones',
    component: VacacionesAdministrador,
    auth: 'ADMIN_VACACIONES',
    subRoutes: [

      {
        exact: true,
        path: '/administrador/vacaciones/validar-estado',
        section: 'Administración -> Vacaciones -> Validar Estado',
        component: VacacionesSearch

      },
      {
        exact: true,
        path: '/administrador/vacaciones/reporte-de-vacaciones-pendientes',
        section: 'Administración -> Vacaciones -> Reporte de vacaciones pendientes',
        component: PendingVacationReports
      }
    ]
  },
  {
    exact: true,
    path: '/administrador/reconocimiento',
    section: 'Administración -> Reconocimiento',
    component: Reconocimiento,
    auth: 'ADMIN_RECONOCIMIENTO_REPORTS'
  },
  {
    exact: true,
    path: '/administrador/plan-retorno',
    section: 'Administración -> Plan de Retorno',
    component: PlanRetorno,
    auth: 'ADMIN_PLAN_RETORNO_UPLOAD'
  },
  {
    exact: true,
    path: '/administrador/lideres-persona',
    section: 'Administración -> Líderes Persona',
    component: LeaderPeopleStore,
    auth: 'ADMIN_LEADER_PEOPLE_STORE'
  },
  ...myEvolution
];

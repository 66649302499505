import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'moment/locale/es';
import { useWindowWidthSize } from '../../../helpers/browser';
import { setMLHistoricalProps } from '../../../redux/actions/commons';
import { smallDevice } from '../../../helpers/deviceVariables';
import '../../../static/styles/shared/MLHistorical/historical.less';

const MLHistorical = ({
  classes,
  children,
  containerClasses,
  leftScrollComponents,
  splitByScreensAt
}) => {
  const width = useWindowWidthSize();
  const childrenContainerEl = useRef(null);

  const [hasChildren, setHasChildren] = useState(false);
  const [listLoading, setListLoading] = useState(true);
  const dispatch = useDispatch();

  const { hideList, hideDetails } = (() => ({
    hideList: width <= splitByScreensAt && hasChildren,
    hideDetails: width <= splitByScreensAt && !hasChildren
  }))();

  const
    mlHistoricalReducer = useSelector((state) => state.commonsReducer.mlHistorical);
  const { scrollTop = 0 } = mlHistoricalReducer || {};

  const listContainerRef = useRef(null);

  useEffect(() => {
    setHasChildren(!!(childrenContainerEl.current || {}).firstChild);
  }, [children]);

  useEffect(() => {
    if (listContainerRef) {
      listContainerRef.current.scrollTop = scrollTop;
    }

    setListLoading(false);
  }, [scrollTop]);

  useEffect(() => {
    /* HTML DOM changes could be deprecated if some encapsulated css solve it */
    const mainLayoutChildrenContainer = document.getElementById('main-layout-children-container');
    const bannerLayout = document.getElementsByClassName('banner-layout')[0];
    const bannerLayoutChildren = document.getElementsByClassName('banner-layout-children')[0];
    const antTabsBar = document.getElementsByClassName('ant-tabs-bar')[0];
    const antTabsTabpane = document.getElementsByClassName('ant-tabs-tabpane-active')[0];

    if (mainLayoutChildrenContainer) {
      mainLayoutChildrenContainer.style.paddingRight = 0;
      mainLayoutChildrenContainer.style.paddingBottom = 0;
    }

    if (bannerLayoutChildren) bannerLayoutChildren.style.paddingRight = 0;
    if (bannerLayout) bannerLayout.style.paddingBottom = 0;
    if (antTabsTabpane) antTabsTabpane.style.paddingTop = 0;
    if (antTabsBar) antTabsBar.style.marginRight = '24px';

    return () => {
      if (mainLayoutChildrenContainer) {
        mainLayoutChildrenContainer.style.paddingRight = '';
        mainLayoutChildrenContainer.style.paddingBottom = '';
      }
      if (bannerLayout) bannerLayout.style.paddingBottom = '';
      if (bannerLayoutChildren) bannerLayoutChildren.style.paddingRight = '';
      if (antTabsBar) antTabsBar.style.marginRight = '';
      if (antTabsTabpane) antTabsTabpane.style.paddingTop = '';

      dispatch(setMLHistoricalProps({ scrollTop: listContainerRef.current.scrollTop }));
    };
  }, []);

  return (
    <div>
      <div className={`mievolucion-historical ${classes}`}>
        <div
          {...{
            ref: listContainerRef,
            className: `${listLoading ? 'without-scroll' : ''} mievolucion-historical-list-contaier${hideList ? ' hidden' : ''}`
          }}
        >
          {
            leftScrollComponents
          }
        </div>

        <div
          className={`mievolucion-historical-item-details-contaier ${hideDetails ? 'hidden' : ''} ${containerClasses}`}
          ref={childrenContainerEl}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

MLHistorical.propTypes = {
  classes: PropTypes.string,
  children: PropTypes.element,
  containerClasses: PropTypes.string,
  leftScrollComponents: PropTypes.array,
  splitByScreensAt: PropTypes.number
};

MLHistorical.defaultProps = {
  classes: '',
  children: <></>,
  leftScrollComponents: [],
  containerClasses: '',
  splitByScreensAt: smallDevice
};

export default MLHistorical;

import { actionTypes } from '../actions/leadersPeopleStore';

export const initialState = {
  data: [],
  error: null,
  loading: false
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_LEADERS_PEOPLE_STORES:
    return {
      ...state,
      error: false,
      loading: true
    };
  case actionTypes.GET_LEADERS_PEOPLE_STORES_SUCCESS:
    return {
      ...state,
      data: action.payload,
      error: false,
      loading: false
    };
  case actionTypes.GET_LEADERS_PEOPLE_STORES_ERROR:
    return {
      ...state,
      data: action.error,
      error: true,
      loading: false
    };
  case actionTypes.GET_LAST_FILE_UPLOADED_PEOPLE_STORES:
    return {
      ...state,
      error: false,
      loading: true
    };
  case actionTypes.GET_LAST_FILE_UPLOADED_PEOPLE_STORES_SUCCESS:
    return {
      ...state,
      error: false,
      loading: false
    };
  case actionTypes.GET_LAST_FILE_UPLOADED_PEOPLE_STORES_ERROR:
    return {
      ...state,
      data: action.error,
      error: true,
      loading: false
    };
  default:
    return state;
  }
}

export default reducer;

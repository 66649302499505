export const actionTypes = {
  SEARCH_USER: 'SEARCH_USER',
  SEARCH_USER_SUCCESS: 'SEARCH_USER_SUCCESS',
  SEARCH_USER_ERROR: 'SEARCH_USER_ERROR',
  SEARCH_USER_RESET: 'SEARCH_USER_RESET'
};

export function searchUser({ params }) {
  return {
    type: actionTypes.SEARCH_USER,
    params
  };
}

export function searchUserSuccess(payload) {
  return {
    type: actionTypes.SEARCH_USER_SUCCESS,
    payload
  };
}

export function searchUserError(error) {
  return {
    type: actionTypes.SEARCH_USER_ERROR,
    error
  };
}

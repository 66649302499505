import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';

import moment from 'moment';
import saveAnalyticAPI from '../api/analytic';
import { actionTypes, postAnalyticRequestSuccess } from '../actions/analytic';
import { loading, loadComplete } from '../actions/commons';

moment.locale('es');

es6promise.polyfill();

export function* analyticRequest(params) {
  try {
    yield put(loading());
    const {
      user: {
        rut,
        name,
        secondName,
        fatherLastName,
        motherLastName,
        birthdate,
        sexo,
        HOOPP
      },
      screen
    } = params;
    const obj = {
      rut,
      name,
      secondName,
      fatherLastName,
      motherLastName,
      birthdate,
      sexo,
      HOOPP,
      screen
    };
    const response = yield call(saveAnalyticAPI, obj);
    yield put(postAnalyticRequestSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    yield put(loadComplete());
  }
}

function* analyticSagas() {
  yield all([takeLatest(actionTypes.POST_ANALYTIC_REQUEST, analyticRequest)]);
}

export default analyticSagas;

import { lazy } from 'react';

const InternalOpportunitiesHome = lazy(() => import('../../containers/postulaciones'));
const OpportunitiesGroupList = lazy(() => import('../../components/postulaciones/OpportunityGroupList'));
const OpportunitiesGroupListItem = lazy(() => import('../../components/postulaciones/OpportunityGroupListItem'));
const OpportunityDetails = lazy(() => import('../../containers/postulaciones/details'));

export default [
  {
    exact: true,
    path: '/mi-evolucion/postulaciones-internas/:managementSlug/:opportunityTitle',
    section: 'Postulaciones Internas',
    component: OpportunityDetails
  },
  {
    path: '/mi-evolucion/postulaciones-internas', // Se carga la query desde servidor
    section: 'Postulaciones Internas',
    component: InternalOpportunitiesHome,
    subRoutes: [
      {
        exact: true,
        path: '/mi-evolucion/postulaciones-internas',
        section: 'Postulaciones Internas',
        component: OpportunitiesGroupList
      },
      {
        exact: true,
        path: '/mi-evolucion/postulaciones-internas/:managementSlug',
        section: 'Postulaciones Internas',
        component: OpportunitiesGroupListItem
      }
    ]
  }
];

import { lazy } from 'react';

const Compensations = lazy(() => import('../../containers/compensaciones'));
const Bonus = lazy(() => import('../../containers/compensaciones/bono-anual'));
const ExceptionalBonus = lazy(() => import('../../containers/compensaciones/bono-anual/bono-excepcional'));
const Salary = lazy(() => import('../../containers/compensaciones/sueldo'));

// Comunications
const HomeCommunications = lazy(() => import('../../containers/compensaciones/cartas-de-merito'));
const Communications = lazy(() => import('../../components/compensaciones/MeritLetters'));
// Bono variable
const BonoVariable = lazy(() => import('../../containers/compensaciones/bono-variable'));

const AnnualBonus = Bonus;

export default [
  {
    exact: true,
    path: '/compensaciones',
    section: 'Compensaciones',
    component: Compensations
  },
  {
    exact: true,
    path: '/compensaciones/bono',
    section: 'Compensaciones -> Bono',
    component: Bonus,
    auth: 'COMPENSACIONES_BONO_BONO'
  },
  {
    exact: true,
    path: '/compensaciones/sueldo',
    section: 'Compensaciones -> Sueldo',
    component: Salary
  },
  {
    exact: true,
    path: '/compensaciones/bono-anual',
    section: 'Compensaciones -> Bono Anual',
    component: AnnualBonus,
    auth: 'COMPENSACIONES_BONO_BONO'
  },
  {
    exact: true,
    path: '/compensaciones/bono/bono-excepcional',
    section: 'Compensaciones -> Bono Excepcional',
    component: ExceptionalBonus
  },
  {
    path: '/compensaciones/cartas-de-mérito',
    section: 'Compensaciones -> Cartas de Mérito',
    headerTitle: 'Cartas de Mérito',
    component: HomeCommunications,
    auth: 'COMPENSATIONS_MERIT_LETTERS',
    subRoutes: [
      {
        exact: true,
        tabTitle: 'Mis Cartas',
        headerTitle: 'Cartas de Mérito',
        path: '/compensaciones/cartas-de-mérito',
        section: 'Compensaciones -> Cartas de Mérito',
        component: Communications,
        auth: 'COMPENSATIONS_MERIT_LETTERS'
      }
    ]
  },
  {
    exact: true,
    path: '/compensaciones/bono-variable',
    section: 'Compensaciones -> Bono Variable',
    component: BonoVariable
  }
];

import React from 'react';
import PropTypes from 'prop-types';
import '../../../../../../static/styles/shared/MLSpanListHorizontal/dot-color.less';

const DotColor = ({ color, size }) => (<div className={`dot-color dot-color-${color} dot-color-${size}`} />);

DotColor.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.string
};

DotColor.defaultProps = {
  color: 'green',
  size: 'normal'
};
export default DotColor;

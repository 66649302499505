import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import Select from '../../../../../../shared/forms/Select';

const ObjectivePillar = ({
  form, FormItem, pillars, isSelected
}) => {
  const [options, setOptions] = useState([]);
  const { getFieldDecorator } = form;
  const handleChange = (_, name) => isSelected(name);

  useEffect(() => {
    if (pillars.length > 0) {
      const optionsParsed = map(pillars, (pillar) => ({
        label: pillar._id,
        value: pillar._id
      }));
      setOptions(optionsParsed);
    }
  }, [pillars]);

  return (
    <FormItem
      className='objective-pillar-field'
      label={(
        <>
          <i className='icon-ml-pilar color-pillar'/>
          <label>Selecciona un pilar:</label>
        </>
      )}
    >
      {getFieldDecorator('pillarWalmart', {
        rules: [{ required: true, message: 'Selecciona una opción para continuar.' }]
      })(<Select
        placeholder="Selecciona una opción"
        onSelect={handleChange}
        options={options}
        size="large"
        suffixIcon={<i className='icon-ml-select-dd'/>}
      />)}
    </FormItem>
  );
};

ObjectivePillar.propTypes = {
  form: PropTypes.object,
  FormItem: PropTypes.elementType
};

ObjectivePillar.defaultProps = {
  form: {},
  FormItem: Fragment
};

export default ObjectivePillar;

import FileDownload from 'js-file-download';
import moment from 'moment';
import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

// eslint-disable-next-line import/prefer-default-export
export const getExcelAPI = (data) => axios().get(`${BFF_ENDPOINT}/reports/build`,
  { params: data, responseType: 'blob' })
  .then((resp) => {
    const fileName = `${data.reportType}_${moment().format('DD-MM-YYYY')}.xlsx`;
    FileDownload(resp.data, fileName);
  });

export const getExcelAPIBenefits = (data) => axios().get(`${BFF_ENDPOINT}/reports/build`,
  { params: data, responseType: 'blob' });

export const getAnalyticAPI = () => axios().get(`${BFF_ENDPOINT}/reports/registered`,
  { responseType: 'blob' })
  .then((resp) => {
    const fileName = `Reporte_MIO-Registrados_${moment().format('DD-MM-YYYY')}.xlsx`;
    FileDownload(resp.data, fileName);
  });

export const setInitialDateAPI = (type) => axios().post(`${BFF_ENDPOINT}/analytic/saveInitialDate/${type}`).then((resp) => resp.data);

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Modal
} from 'reactstrap';
import '../../static/styles/shared/modalFullScreen.less';

const ModalFullScreen = forwardRef((props, ref) => {
  const {
    className,
    visible,
    children,
    title,
    closeModal,
    zIndex
  } = props;

  return (
    <Modal
      className={className}
      fade={false}
      isOpen={visible}
      toggle={closeModal}
      zIndex={zIndex}
    >
      <div className="modal-full-screen">

        <div className="header-full-screen">

          <div onClick={closeModal}
            className='container-close-left '>
            <i className='icon-ml-close'/>
          </div>
          <p>{title}</p>
          <div onClick={closeModal}
            className='container-close-right'>
            <i className='icon-ml-close'/>
          </div>

        </div>
        <div ref={ref} id='full-screen' className="content-full-screen"> {children}</div>
      </div>
    </Modal>
  );
});

ModalFullScreen.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  title: PropTypes.string,
  closeModal: PropTypes.func,
  zIndex: PropTypes.number
};

ModalFullScreen.defaultProps = {
  className: '',
  visible: false,
  title: '',
  closeModal: () => null,
  zIndex: 1000
};

export default ModalFullScreen;

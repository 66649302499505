import { useEffect, useState } from 'react';

const useScrollTop = (element, property) => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const elementToObserve = element || window;
    const onScroll = () => {
      // or use document.documentElement.scrollTop;
      const propertyToObserve = property || 'pageYOffset';
      const currentPosition = elementToObserve[propertyToObserve];
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    };
    // const elementToObserve = element || window;
    elementToObserve.addEventListener('scroll', onScroll);
    return () => elementToObserve.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  return scrollTop;
};

export default useScrollTop;

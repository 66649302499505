import React, { useState } from 'react';
import '../../../static/styles/mievolucion/miDesarrollo/introduction.less';
import { find, map } from 'lodash';
import MainBtn from '../../shared/buttons/MainBtn';
import MLModal from '../../shared/MLModal';
import { ABOUT_STEPS, MORE_INFO_ABOUT_IDP_URL } from '../../../helpers/myEvolution/miDesarrollo';

const Introduction = () => {
  const [modalStep, setModalStep] = useState({});
  const handleGoToManageIDP = () => {
    window.open(MORE_INFO_ABOUT_IDP_URL);
  };

  const openStep = (stepTitle) => {
    const { modal } = find(ABOUT_STEPS, (step) => step.title === stepTitle);
    setModalStep({
      visible: true,
      ...modal
    });
  };

  const closeStepModal = () => {
    setModalStep({ visible: false });
  };

  const Footer = <div className='d-flex justify-content-center p-2'>
    <MainBtn onClick={closeStepModal} text={'Entendido'} />
  </div>;

  return (
    <div className='mi-desarrollo-introduction'>
      {modalStep?.visible
      && <MLModal
        className='mi-desarrollo-modal-step'
        footer={Footer}
        closeModal={closeStepModal}
        {...modalStep}>
        <div >
          <p className='title'>{modalStep.subtitle}</p>
          <span className='description'>{modalStep.description}
            <a href={modalStep.link} target='_blank' rel='noopener noreferrer'>
            Accede aquí para más información.
            </a>
          </span>
        </div>
      </MLModal>
      }

      <div className='info'>
        <p className='title'>¿Qué es Mi Desarrollo?</p>
        <p className='description'><span className='text-bold'></span> ¡Este es un viaje de 4 etapas que te invita a impulsar tu plan de desarrollo profesional a tu ritmo y de manera autogestionada con diferentes herramientas que podrás encontrar a lo largo de este viaje. En Walmart Chile somos partidarios de ampliar nuestras posibilidades de desarrollo y como sabemos que ningún camino es lineal, es importante contar con distintas opciones para avanzar según nuestras motivaciones, aspiraciones e intereses.</p>
        <p className='title'>Mi Desarrollo | Te invitamos a vivir el siguiente viaje</p>
      </div>

      <div className='container-steps'>
        <div className='container-separator'>
          <div className='separator'/>
        </div>
        <div className='steps'>
          {
            map(ABOUT_STEPS, (step, index) => (
              <div onClick={ (e) => {
                e.preventDefault();
                openStep(step.title);
              }} key={step.title} className='step'>
                <div className='contaniner-step-number'>
                  <div className='step-number'>
                    {index + 1}
                  </div>
                </div>
                <p className='title'>{step.title}</p>
                <p className='description'>{step.description}</p>
              </div>
            ))
          }
        </div>
      </div>
      <div className='d-flex justify-content-center'>
        <MainBtn onClick={handleGoToManageIDP} text={'Conocer más detalles del plan'} />
      </div>
    </div>
  );
};

export default Introduction;

import React from 'react';
import PropTypes from 'prop-types';
import '../../static/styles/shared/separator.less';

const MLSeparator = ({
  className,
  vertical
}) => (
  <div className={`ml-separator ${className} ${vertical ? 'ml-vertical-separator' : ''}`.trim()} />
);

MLSeparator.propTypes = {
  className: PropTypes.string,
  vertical: PropTypes.bool
};

MLSeparator.defaultProps = {
  className: '',
  vertical: false
};

export default MLSeparator;

import { gql } from 'graphql-tag';

export const GET_NOTIFICATIONS = gql`
    query {
        getGroupedNotifications {
            count
            section
            sectionTarget
        }
    }
`;

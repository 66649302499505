/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
import React, { useState, useRef, useEffect } from 'react';
import {
  Carousel
} from 'antd';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import InfoMessage from '../../../../shared/InfoMessage';
import Behavior from './Behavior';
import { SAVE_SKILL_LEVEL_BEHAVIOR_ACTION_CHOICE } from '../../../../../graphql/mievolucion/evolucionaTEST/mutations';
import { resetModalLeftIconHeader } from '../../../../../redux/actions/commons';
import { GET_EVALUATION_PLAN_HISTORICAL } from '../../../../../graphql/mievolucion/evolucionaTEST/queries';

const CreatePlan = (props) => {
  const {
    rut,
    behaviorChoices,
    setBehaviorChoices,
    mainActiveSlide,
    setModalOptions
  } = props;
  const refSliderCreatePlan = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const clientName = 'myEvolution';
  const [carouselState, setCarouselState] = useState({
    activeSlide: 0
  });

  const goToEvaluation = () => {
    history.replace('/mi-evolucion/evolucionaTEST/plan-de-evolucion/en-proceso');
  };

  const [saveSkillLevelBehaviorActionChoice, { loading: loadingEndTest }] = useMutation(
    SAVE_SKILL_LEVEL_BEHAVIOR_ACTION_CHOICE,
    {
      context: { clientName },
      onCompleted: () => {
        dispatch(resetModalLeftIconHeader());
        setModalOptions({
          visible: true,
          title: 'Has finalizado tu TEST',
          message: 'Tienes un mes para completar las acciones que elegiste para tu Plan de Evolución.',
          type: 'success',
          buttons: [
            {
              type: 'primary',
              text: 'Cerrar',
              onClickButton: goToEvaluation
            }
          ]
        });
      },
      onError: () => {
        setModalOptions({
          visible: true,
          title: 'No se ha podido finalizar el TEST',
          message: 'Se ha producido un error al intentar finalizar el TEST. Por favor inténtalo de nuevo más tarde.',
          type: 'error'
        });
      }
    }
  );

  const slickGoToInitial = () => {
    if (refSliderCreatePlan && refSliderCreatePlan.current) {
      refSliderCreatePlan.current.slick.slickGoTo(0);
    }
  };

  useEffect(() => {
    slickGoToInitial();
    setCarouselState({ activeSlide: 0 });
  }, [mainActiveSlide]);

  if (!behaviorChoices) {
    return (
      <div className="container-evolucionaTest">
        <InfoMessage
          classes='mb-4'
          message='No tienes acciones'
        />
      </div>
    );
  }

  const carouselSettings = {
    dots: false,
    autoplay: false,
    swipeToSlide: false,
    swipe: false,
    afterChange: (current) => {
      setCarouselState({ activeSlide: current });
    }
  };
  const next = () => {
    refSliderCreatePlan.current.next();
  };

  const endTest = () => {
    const choices = [];
    behaviorChoices.map(({ behavior }) => {
      const actionsChecked = behavior.actions.filter((action) => action.checked);
      if (actionsChecked) {
        actionsChecked.map((action) => {
          choices.push({ behaviorId: behavior._id, actionId: action.id, dni: rut });
        });
      }
    });

    saveSkillLevelBehaviorActionChoice({
      variables: {
        choices
      },
      refetchQueries: [
        {
          context: { clientName },
          query: GET_EVALUATION_PLAN_HISTORICAL,
          variables: { dni: rut, status: ['PENDING'] }
        }
      ]
    });
  };

  const renderStep = () => behaviorChoices
   && behaviorChoices.map((behaviorChoice, indexBehaviorChoice) => (
     <Behavior
       key={indexBehaviorChoice}
       indexBehavior={indexBehaviorChoice}
       behaviorChoice={behaviorChoice}
       next={next}
       carouselState={carouselState}
       endTest={endTest}
       behaviorChoices ={behaviorChoices}
       setBehaviorChoices={setBehaviorChoices}
       loadingEndTest={loadingEndTest}
     />

   ));

  return (
    <div className="container-evolucionaTest">
      <InfoMessage
        classes='mb-4'
        message='Selecciona entre 1 y 4 acciones para realizar este mes y desafíate a Evolucionar.'
      />
      <Carousel {...carouselSettings} ref={refSliderCreatePlan}>
        {
          renderStep()
        }
      </Carousel>
    </div>
  );
};

export default CreatePlan;

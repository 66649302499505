import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { has, isEmpty } from 'lodash';
import {
  Card, Row, Col, Button
} from 'antd';
import Avatar from '../Avatar';
import InfoMessage from '../InfoMessage';

import '../../../static/styles/shared/cards/mlCard.less';

export const renderBtns = (btnList) => (
  <div className="btn-wrapper d-flex justify-content-center">
    {
      btnList.map((item, i) => (
        <Button
          key={`btn${i}`}
          type="primary"
          disabled={item.disabled}
          loading={item.loading}
          onClick={() => item.onClick()}
        >
          {item.text}
        </Button>
      ))
    }
  </div>
);

export const renderItemList = (itemList) => (
  <div className="item-list">
    {
      itemList.map((item, i) => (
        <Row key={`itemList${i}`} className={`${has(item, 'cssClass') && 'total-row'}`}>
          <Col span={12}>
            <p className="mb-0 mt-3 field">{item.field}</p>
          </Col>
          <Col span={12}>
            {
              has(item, 'onClick')
                ? (
                  <p className="mb-0 mt-3 value text-right">
                    {
                      isEmpty(item.file)
                        ? <a href="#" role="presentation" type="primary" rel="noopener noreferrer"
                          onClick={item.onClick}>{item.value}</a>
                        : <a href={item.file} type="primary" target="_blank" rel="noopener noreferrer">{item.value}</a>
                    }
                  </p>
                )
                : (
                  <p className={`mb-0 mt-3 value text-right ${has(item, 'sent') && item.sent}`}>
                    {item.value}
                  </p>
                )
            }
          </Col>
        </Row>
      ))
    }
  </div>
);

export const renderTitleList = (titleList) => (
  <div className="title-list">
    {titleList.map((item, i) => (
      <Fragment key={`titleList${i}`}>
        <span>{item.title}</span>
        <ul className="list">
          {
            item.fields.map((item2, index) => (
              <li className="list-item mb-0 mt-3" key={`i2${index}`}>
                <p>{item2.field}</p>
                {
                  has(item2, 'file') && <p><a href={item2.file} type="primary" target="_blank" rel="noopener noreferrer">{item2.value}</a></p>
                }
                {
                  !has(item2, 'file') && !has(item2, 'method') && <p>{item2.value}</p>
                }
                {
                  has(item2, 'method') && <p>
                    <a href="#" type="primary" onClick={(e) => { e.preventDefault(); item2.method(); }}>{item2.value}</a>
                  </p>
                }
              </li>
            ))
          }
        </ul>
      </Fragment>
    ))}
  </div>
);

const MLCard = ({
  title, avatar, name, btnList, itemList, titleList, cssClass, infoMessage, infoMessageProps
}) => (
  <Card className={`ml-card ${cssClass}`}>
    {/* -------------------- TITLE -------------------- */}
    {
      title && (
        <div className="section-title-wrapper">
          <h3 className="title">{title}</h3>
        </div>
      )
    }
    {/* -------------------- TITLE WITH AVATAR -------------------- */}
    <div className="title-wrapper">
      {avatar && (
        <div className="avatar-wrapper d-flex justify-content-start align-items-center">
          <Avatar />
          <span className="ml-2 mb-0">{name}</span>
        </div>
      )}
      <hr className="mb-0" />
    </div>
    {
      !isEmpty(infoMessage)
      && (
        <InfoMessage classes="mt-4" message={infoMessage} {...infoMessageProps} />
      )
    }
    {/* -------------------- TITLE LIST -------------------- */}
    {
      titleList.length > 0
      && (
        renderTitleList(titleList)
      )
    }
    {/* -------------------- SIMPLE LIST -------------------- */}
    {
      itemList.length > 0
      && isEmpty(infoMessage)
      && renderItemList(itemList)
    }
    {
      titleList.length === 0 && itemList.length === 0 && <hr className="mb-0" />
    }
    {
      btnList.length > 0
      && isEmpty(infoMessage)
      && renderBtns(btnList)
    }
  </Card>
);

MLCard.propTypes = {
  titleList: PropTypes.array,
  itemList: PropTypes.array,
  btnList: PropTypes.array,
  title: PropTypes.string,
  name: PropTypes.string,
  avatar: PropTypes.bool,
  cssClass: PropTypes.string,
  infoMessage: PropTypes.string,
  infoMessageProps: PropTypes.object
};

MLCard.defaultProps = {
  titleList: [],
  itemList: [],
  btnList: [],
  title: '',
  name: '',
  avatar: false,
  cssClass: '',
  infoMessage: '',
  infoMessageProps: {}
};

export default MLCard;

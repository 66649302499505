import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const timeOutValue = 700000;

const MIO_BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

export const setRequestByCodeAPI = (code) => axios().get(`${MIO_BFF_ENDPOINT}/reports/request-by-code/${code}`, { timeout: timeOutValue }).then((resp) => resp.data);

export const getDetailsByCodeReportsHistoryAPI = (code) => axios().get(`${MIO_BFF_ENDPOINT}/reports/details-by-code/${code}`, { timeout: timeOutValue }).then((resp) => resp.data);

import React, {
  useRef, useCallback, useState, useEffect, useReducer
} from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { isEmpty, orderBy } from 'lodash';

import Dragable from '../../shared/dragging/drag/TableDragable';
import Dropable from '../../shared/dragging/drop/TableDropable';
import MainBtn from '../../shared/buttons/MainBtn';
import Loader from '../../shared/MLLoading';
import ModalMessage from '../../shared/ModalMessage';
import useScrollTracker from '../../../helpers/hooks/browser/useScrollTracker';
import {
  getAverage, scoreConversion, getScoreConversion, getScoreByXPosition,
  shapeDataToSend, modifyTablePositions, cleanColumn, initializeTable
} from '../../../helpers/myEvolution/calibracion';
import { useScrollTop } from '../../../helpers/browser';
import { UPDATE_CALIBRATION } from '../../../graphql/mievolucion/calibracion/mutations';
import IconList from '../../shared/bars/IconList';
import '../../../static/styles/mievolucion/calibracion/stepperTwo.less';
import FiltersModal from '../../shared/filters/FiltersModal';
import FiltersModalSection from '../../shared/filters/FiltersModalSection';
import CheckboxGroup from '../../shared/forms/CheckBoxGroup';
import {
  filtersInitialState,
  filtersReducer,
  checkboxInitialState,
  checkboxReducer,
  buildFilters
} from '../../../helpers/myEvolution/calibracion/filterReducer';

const context = { clientName: 'myEvolution' };
const fetchPolicy = { fetchPolicy: 'network-only' };

const StepperTwo = ({
  calibrationDispatch, calibrationReducer, type, editUsers
}) => {
  const {
    calibrationTable, skills, skillScores, objetiveScores,
    finalScores, finalSkillScores, calibration, performanceCategoryList,
    modalState
  } = calibrationReducer;
  const [columnOpen, setColumnOpen] = useState([
    null, true, false, false, false, false, false, false, false
  ]);
  const leftRowsRefs = useRef([]);
  const refTableScrollable = useRef();
  const scrollTop = useScrollTop(document.getElementsByClassName('ml-antd-modal-fullscreen-wrap')[0], 'scrollTop');
  const [scroll, handleScroll] = useScrollTracker(refTableScrollable);
  const [showFilters, setShowFilters] = useState(false);
  const [filtersState, filtersDispatch] = useReducer(filtersReducer, filtersInitialState);
  const [checkboxState, checkboxDispatch] = useReducer(checkboxReducer, checkboxInitialState);

  const [rutsToHiddenState, setRutsToHiddenState] = useState([]);
  useEffect(() => {
    buildFilters(calibration);
  }, []);
  const [updateEvaluationCalibration, {
    loading: loadingUpdateCalibration
  }] = useMutation(UPDATE_CALIBRATION, {
    context,
    ...fetchPolicy,
    onCompleted: ({ updateCalibration }) => {
      calibrationDispatch({
        type: 'set-modalState',
        payload: {
          visible: true,
          type: 'success',
          title: 'La calibración se ha guardado con éxito',
          message: '',
          toBegining: modalState.toBegining || false
        }
      });
      calibrationDispatch({
        type: 'set-calibration',
        payload: {
          calibration: updateCalibration
        }
      });
    },
    onError: (err) => {
      calibrationDispatch({
        type: 'set-modalState',
        payload: {
          visible: true,
          type: 'error',
          message: err.message
        }
      });
    }
  });

  useEffect(() => {
    if (calibrationReducer.calibration) {
      calibrationDispatch({
        type: 'set-calibrationTable',
        payload: initializeTable(calibrationReducer)
      });
      // TODO:  SET FILTER
      checkboxDispatch({ type: 'set', data: {} });
    }
  }, [calibrationReducer.calibration]);

  const handleDrop = ({ newX, newY, item }) => {
    const { currentX, currentY } = item;
    let table = modifyTablePositions({
      table: [...calibrationTable], currentX, currentY, newX, newY, item
    });
    // Handle skills drag & drop
    if (newY > 2) {
      table = cleanColumn({ table, y: 2, item });
      const oldAvg = getAverage(skillScores[item.id]);
      skillScores[item.id][newY - 3] = getScoreByXPosition(scoreConversion, newX);
      const newAvg = getAverage(skillScores[item.id]);
      finalScores[item.id] = getAverage([newAvg, objetiveScores[item.id]]);
      finalSkillScores[item.id] = newAvg;
      table = modifyTablePositions({
        table,
        newX: getScoreConversion(newAvg),
        newY: 2,
        item,
        currentX: getScoreConversion(oldAvg),
        currentY: 2
      });
    }
    let skillAvg = getAverage(skillScores[item.id]);
    // Handle C.F.C changes
    if (newY === 2) {
      const conversion = getScoreByXPosition(scoreConversion, newX);
      finalSkillScores[item.id] = conversion;
      skillAvg = conversion;
      finalScores[item.id] = getAverage([skillAvg, objetiveScores[item.id]]);
    }
    // hanldes all changes but the C.F.D
    if (newY !== 0) {
      table = cleanColumn({ table, y: 0, item });
      const newFinalAvg = getAverage([skillAvg, objetiveScores[item.id]]);
      table = modifyTablePositions({
        table,
        newX: getScoreConversion(newFinalAvg),
        newY: 0,
        item,
        currentX: getScoreConversion(skillAvg),
        currentY: 0
      });
    }
    // hanldes C.F.D changes
    if (newY === 0) {
      finalScores[item.id] = getScoreByXPosition(scoreConversion, newX);
    }

    calibrationDispatch({
      type: 'set-calibrationTable',
      payload: {
        table, skillScores, objetiveScores, finalScores, finalSkillScores
      }
    });
  };

  const renderItems = (x, y) => calibrationTable[x][y].filter((v) => !isEmpty(v)).map((v, i) => (
    <Dragable key={`dragable-${i}`} {...v} handleDrop={handleDrop} className={rutsToHiddenState.includes(v.id) ? 'd-none' : 'd-flex'}/>
  ));

  const trackRowHeight = useCallback((node) => {
    if (node !== null) {
      const rowToSet = parseInt(node.id.split(/-/g)[2], 0);
      if (rowToSet < 6) {
        leftRowsRefs.current[rowToSet].style.height = `${node.getBoundingClientRect().height}px`;
      }
    }
  });

  const getFinalCategoryPercentage = (x) => {
    const total = Object.keys(calibrationReducer.skillScores).length;
    const column = 0;
    const number = calibrationReducer.calibrationTable[x][column];
    return `(${parseInt((number.length / total) * 100, 0)}%)`;
  };

  const buildLeftColumn = () => {
    const orderedList = orderBy(performanceCategoryList, ['score'], ['desc']);
    const resp = orderedList.map((item, i) => {
      const lineBreak = item.name.split('|');
      return (
        <li
          key={`left-column-${i + 1}`}
          ref={(el) => { leftRowsRefs.current[i + 1] = el; }}
          className="d-flex flex-column justify-content-center"
        >
          <div>
            <span>{lineBreak[0]}</span>
            {lineBreak[1] && (<><br /><span>{lineBreak[1]}</span></>)}<br />
            {getFinalCategoryPercentage(i)}
          </div>
        </li>
      );
    });
    return resp;
  };

  const handleColumnClick = (column) => () => {
    const newArray = [...columnOpen];
    newArray[column] = !newArray[column];
    setColumnOpen(newArray);
  };

  const chooseColumnTitle = ({ item, isOpen }) => (isOpen ? item.title : item.initials);

  const firstTableRow = document.getElementById('right-row-0');
  const tableWrapper = document.getElementsByClassName('table-wrapper')[0];

  const setHeadScrolling = () => { if (firstTableRow) firstTableRow.scrollLeft = scroll.x; };

  useEffect(() => {
    setHeadScrolling();
  }, [scroll]);

  useEffect(() => {
    const elementOffset = tableWrapper?.offsetTop;
    if (firstTableRow) {
      if (scrollTop > elementOffset) {
        setHeadScrolling();
        firstTableRow.className = 'fixed';
      } else {
        firstTableRow.classList.remove('fixed');
      }
    }
  }, [scrollTop]);

  const getTableHeadItems = () => {
    const skillColumns = calibrationReducer.skills.map((v) => (
      { title: v.name, initials: v.name[0] }));
    const extraColumns = [{
      title: 'Categoría Final de Desempeño',
      initials: 'C.F.D.',
      classes: 'larger-min-width'
    }, {
      title: 'Categoría Final de Objetivos',
      initials: 'C.F.O.',
      classes: 'larger-min-width'
    }, {
      title: 'Categoría Final de Competencias',
      initials: 'C.F.C.',
      classes: 'larger-min-width'
    }];
    return [...extraColumns, ...skillColumns];
  };

  const buildTableHead = () => {
    const thColumns = getTableHeadItems();
    return (
      <tr id="right-row-0" ref={trackRowHeight}>
        {thColumns.map((item, y) => (
          <th
            key={`thead-${y}`}
            className={`
              ${item.classes || ''}
              th-col-${y + 1} ${!columnOpen[y + 1] ? 'column-closed' : 'column-open'}
            `}
            onClick={handleColumnClick(y + 1)}
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <span className="text-center">
                {chooseColumnTitle({ item, isOpen: columnOpen[y + 1] })}
              </span>
              <i className={`icon-ml-arrow ${columnOpen[y + 1] && 'open'}`} />
            </div>
          </th>
        ))}
      </tr>
    );
  };

  const buildTableBody = () => {
    const thColumns = getTableHeadItems();
    const resp = calibrationTable.map((firstLoop, x) => (
      <tr id={`right-row-${x + 1}`} ref={trackRowHeight} key={`x-${x}`}>
        {calibrationTable[x].map((secondLoop, y) => (
          <Dropable
            key={`y-${y}`}
            x={x}
            y={y}
            handleDrop={handleDrop}
            accept="calibration"
            classes={`
            ${thColumns[y]?.classes ? 'larger-min-width' : ''}
            td-row-${x + 1} 
            td-col-${y + 1} ${!columnOpen[y + 1] ? 'column-closed' : 'column-open'}
            `}
          >
            {renderItems(x, y)}
          </Dropable>
        ))}
      </tr>
    ));
    return resp;
  };

  const submit = async (status, moveFordward) => {
    const toSend = shapeDataToSend({
      calibration,
      status,
      finalScores,
      skillScores,
      skills,
      performanceCategoryList,
      finalSkillScores,
      objetiveScores
    });
    await updateEvaluationCalibration({
      variables: toSend
    });
    if (moveFordward) {
      calibrationDispatch({ type: 'step-forward' });
    }
  };

  const openModal = (value) => {
    setShowFilters(value);
    // filtersDispatch({ field: 'reset', value: filtersApplied });
  };

  const resetFilters = () => {
    filtersDispatch({ field: 'reset' });
    setRutsToHiddenState([]);
  };

  const applyFilters = () => {
    // eslint-disable-next-line consistent-return, array-callback-return
    const leakedCalibrations = calibration.calibrations.filter((item) => {
      const iMarket = item.evaluationId.evaluated.market;
      const iSociety = item.evaluationId.evaluated.society;
      const iPerformanceCategory = item.finalCategories.evaluation.name;
      if (!(filtersState.market.includes(iMarket)
      || filtersState.society.includes(iSociety)
      || filtersState.performanceCategory.includes(iPerformanceCategory))) { return item; }
    });

    const rutsToHidden = leakedCalibrations.map((item) => item.evaluationId.evaluated.rut);
    setRutsToHiddenState(rutsToHidden);
  };

  const getIconList = () => ([
    {
      label: 'FILTRAR',
      onClick: () => setShowFilters(true),
      icon: 'icon-ml-filtrar',
      badge: filtersState.totalApplied
    }
  ]);

  return (
    <div className="stepper-two-component mt-5">
      {modalState?.visible
      && <ModalMessage
        visible={modalState.visible}
        type={modalState.type}
        title={modalState.title}
        message={modalState.message}
        buttons={[
          {
            text: 'Cerrar',
            type: 'primary',
            onClickButton: () => (modalState.toBegining ? calibrationDispatch({ type: 'reset' })
              : calibrationDispatch({ type: 'set-modalState', payload: { visible: false } }))
          }
        ]}
      />
      }
      {loadingUpdateCalibration && (<Loader />)}
      {
        !loadingUpdateCalibration && (
          <>
            <div>
              <FiltersModal
                show={showFilters}
                hide={() => openModal(false)}
                resetFilters={() => resetFilters()}
                applyFilters={applyFilters}
              >
                <FiltersModalSection
                  label='Categoría Final de Desempeño'
                >
                  <CheckboxGroup
                    options={checkboxState.performanceCategory}
                    value={filtersState.performanceCategory}
                    onChange={(value) => filtersDispatch({ field: 'performanceCategory', value })}
                  />
                </FiltersModalSection>
                <FiltersModalSection
                  label='Formatos'
                >
                  <CheckboxGroup
                    options={checkboxState.society}
                    value={filtersState.society}
                    onChange={(value) => filtersDispatch({ field: 'society', value })}
                  />
                </FiltersModalSection>
                <FiltersModalSection
                  label='Mercados'
                >
                  <CheckboxGroup
                    options={checkboxState.market}
                    value={filtersState.market}
                    onChange={(value) => filtersDispatch({ field: 'market', value })}
                  />

                </FiltersModalSection>
              </FiltersModal>
            </div>

            <div>
              <IconList classes={'mt-3'} options={getIconList()} />
            </div>

            <div className="general-wrapper d-flex">
              <ul className="options-wrapper list-unstyled">
                <li
                  key={'left-column-0'}
                  ref={(el) => { leftRowsRefs.current[0] = el; }}
                  className="d-flex flex-column justify-content-center"
                >
                  <div><span>Categoría de Desempeño</span></div>
                </li>
                {buildLeftColumn()}
              </ul>
              <div className="table-wrapper flex-grow-1">
                <div className="overflow-wrapper" onScroll={handleScroll} ref={refTableScrollable}>
                  <table className={`${type}`}>
                    <thead>
                      {buildTableHead()}
                    </thead>
                    <tbody>
                      {buildTableBody()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="btns-wrapper d-flex justify-content-center flex-column">
              <div className='d-flex justify-content-between'>
                <MainBtn
                  mlType="inverse-main"
                  width="200px"
                  text="Editar Grilla"
                  onClick={() => editUsers()}
                />
                <MainBtn
                  width="200px"
                  text="Guardar Calibración"
                  onClick={() => submit('PENDING', false)}
                />
                <MainBtn
                  mlType="main"
                  width="200px"
                  text="Calibración de Potencial"
                  onClick={() => submit('PENDING', true)}
                />
              </div>
            </div>
          </>
        )
      }
    </div>
  );
};

StepperTwo.propTypes = {
  calibrationDispatch: PropTypes.func,
  calibrationReducer: PropTypes.object,
  editUsers: PropTypes.func
};

StepperTwo.defaultProps = {
  calibrationDispatch: () => { },
  calibrationReducer: {},
  editUsers: () => { }
};

export default StepperTwo;

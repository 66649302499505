import React from 'react';
import PropTypes from 'prop-types';
import {
  Select
} from 'antd';
import { map } from 'lodash';
import { Item } from '../../Form';

const { Option } = Select;

const Platform = (props) => {
  const {
    updatePlatform,
    getFieldDecorator,
    platform,
    level,
    listPlatformLevels,
    index,
    platforms,
    setPlatforms,
    listOptionsPlatforms
  } = props;

  const onChangePlatform = (value) => {
    updatePlatform({
      level,
      platform: {
        _id: value
      }
    },
    index);
  };

  const onChangeStatusOfStudies = (value) => {
    updatePlatform({
      level: value,
      platform
    },
    index);
  };

  const removePlatform = (e) => {
    e.preventDefault();

    const newPlatforms = Object.assign([], platforms);
    newPlatforms.splice(index, 1);

    setPlatforms(newPlatforms);
  };

  return (
    <>
      <p className='text-label-primary'>Plataforma {index + 1}</p>
      <div className='row'>
        <Item
          label='Plataforma'
          className='col-12 col-md-12 col-lg-6'
        >
          {getFieldDecorator(`platform-${index}`,
            {
              ...(platform?._id ? { initialValue: platform._id } : null)
            })(
            <Select
              showSearch
              allowClear
              placeholder="Elige una plataforma"
              onChange={(value) => onChangePlatform(value)}
              filterOption={ (input, option) => (
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
              }
            >
              {map(listOptionsPlatforms, (item, i) => (
                <Option key={i} value={item._id}>{item.name}</Option>
              ))}
            </Select>
          )}
        </Item>

        <Item
          label='Nivel'
          className='col-12 col-md-12 col-lg-6'
        >
          {getFieldDecorator(`level-${index}`,
            {
              ...(level !== 'WITHOUTINFO' ? { initialValue: level } : null)
            })(
            <Select
              showSearch
              allowClear
              placeholder="Elige una nivel"
              filterOption={false}
              onChange={(value) => onChangeStatusOfStudies(value)}
              style={{ width: '100%' }}
            >
              {map(listPlatformLevels, (item, i) => (
                <Option key={i} value={item.codeValue}>{item.name}</Option>
              ))}
            </Select>
          )}
          { platforms.length > 1
            && <p className='text-delete' onClick={removePlatform}>Eliminar</p>
          }
        </Item>
      </div>
    </>
  );
};
Platform.propTypes = {
  updatePlatform: PropTypes.func,
  getFieldDecorator: PropTypes.func,
  platform: PropTypes.object,
  level: PropTypes.string,
  listPlatformLevels: PropTypes.array,
  index: PropTypes.number,
  platforms: PropTypes.array,
  setPlatforms: PropTypes.func,
  listOptionsPlatforms: PropTypes.array
};

Platform.defaultProps = {
  updatePlatform: () => {},
  getFieldDecorator: () => {},
  platform: {},
  level: 'WITHOUTINFO',
  listPlatformLevels: [],
  index: 0,
  platforms: [],
  setPlatforms: () => {},
  listOptionsPlatforms: []
};
export default Platform;

import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

export const getNewsAPI = () => axios().get(`${BFF_ENDPOINT}/newplace/2023`).then((resp) => resp.data);

export const getFrequentAppsAPI = () => axios().get(`${BFF_ENDPOINT}/myplace/frequentApps`).then((resp) => resp.data);

export const getCampaignByUserAPI = () => axios().get(`${BFF_ENDPOINT}/user/campaigns`).then((resp) => resp.data);

export const getFlagInformativeByUserAPI = () => axios().get(`${BFF_ENDPOINT}/user/flagInformative`).then((resp) => resp.data);

export const updateCampaignsAPI = (data) => axios().put(`${BFF_ENDPOINT}/user/campaigns`, { campaignId: data.body });

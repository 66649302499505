import gql from 'graphql-tag';

import CalibrationsPage from './fragments';

export const GET_LIST_EVALUATION_CALIBRATIONS_OLD = gql`
  query listEvaluationCalibrationsNew(
      $createdBy: String
      $status: [EvaluationCalibrationStatus]
      $_id: String
  ) {
    listEvaluationCalibrationsNew(
      createdBy: $createdBy
      status: $status
      _id: $_id
    ) {
      ...CalibrationsPageMain
    }
  }
  ${CalibrationsPage.fragments.main}
`;

export const GET_LIST_EVALUATION_CALIBRATIONS = gql`
  query listCalibration(
      $createdBy: String
      $_id: String
  ) {
    listCalibration(
      createdBy: $createdBy
      _id: $_id
    ) {
    _id
    createdBy # early was a object
    groupName
    calibrationMeeting
    skillsEvaluated { # skills
      _id
      order
      name
    }
    status
    calibrations {
      _id
      evaluationId {
        _id
        evaluated {
         _id
         rut
         name
         fatherLastName
         motherLastName
         position
        }
      },
      potentialEvaluationId
      potentialSkillResultId,
      finalScore {
        evaluation
        skills
        objectives
      }
      finalCategories {
        evaluation { # finalCategory
          _id
          score
        }
        skills { # finalSkillCategory
          _id
          score
        }
        objectives { # finalObjectiveCategory
          _id
          score
        }
      }
      skills { # evolutionSkills and inside has skills object
        _id
        order
        categorySelected { # performanceCategory
          _id
          score
        }
      }
    }
    edpResultsList {
      _id
      name
      order
    }
  }
  }
`;

export const GET_CALIBRATION_HISTORY = gql`
  query getEvaluationCalibrationsHistory(
      $input: evaluationCalibrationsHistoryInput
  ) {
    getEvaluationCalibrationsHistory(input: $input) {
      _id
      groupName
      createdBy
      calibrationMeeting
      updatedAt
      status
    }
  }
`;

export const GET_CALIBRATION_DETAIL = gql`
  query getEvaluationCalibrationsDetails(
      $input: evaluationCalibrationsDetailsInput
  ) {
    getEvaluationCalibrationsDetails(input: $input) {
      fullName
      rut
      position
      finalCategoryEvaluated
      finalCategoryCalibrated
      finalCategoryCalibratedDetails{
        name,
        color
      },
      finalSkillCategory
      finalObjectiveCategory
      potentialSkillResult {
        name
        color
      }
      edpResultCategory {
        name
        score
      }
    }
  }
`;

export const GET_CALIBRATION_USER_INFO = gql`
  query getUserToCalibrate(
      $rut: String!
  ) {
    getUserToCalibrate(
      rut: $rut
    ) {
      success
      data
      code
      message
    }
  }
`;

import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import {
  queryValidation, getParsedData, setItemFromData, setItemFromQueryParams
} from '../../../../../../helpers/myEvolution/feedback';
import { fullCapitalizeFormat } from '../../../../../../helpers/strings';
import ItemDetailsSingleCard from './Single';
import Modal from '../../../../../shared/ModalMessage';
import '../../../../../../static/styles/mievolucion/myPerformance/feedback/feedbackDetails.less';
import 'moment/locale/es';
import Loading from '../../../../../../containers/loading';
import { GET_LIST_PERFORMANCE_CATEGORIES } from '../../../../../../graphql/mievolucion/performanceCategories/queries';

moment.locale('es');

const ItemDetails = (props) => {
  const {
    listTab, type
  } = props;
  // Query obtenida de cache, que esta data fué calculada previamente en el contenedor padre.
  const { data } = useQuery(queryValidation(listTab), {
    context: {
      clientName: 'myEvolution'
    }
  });

  const [item, setItem] = useState({});
  const [modalProps, setModalState] = useState(null);
  const { search } = useLocation();
  const title = fullCapitalizeFormat(item?.title);
  const subTitle = item?.subTitle ? fullCapitalizeFormat(item?.subTitle) : '';
  const parsedData = getParsedData(data, listTab);

  const {
    loading: loadingCategories,
    data: dataCategories
  } = useQuery(GET_LIST_PERFORMANCE_CATEGORIES, {
    context: {
      clientName: 'myEvolution'
    },
    variables: { type: 'FOUR_C' }
  });
  const { listPerformanceCategories: categories = [] } = dataCategories || {};

  useEffect(() => setItemFromData(parsedData, item, setItem), [data]);
  useEffect(() => setItemFromQueryParams(search, setItem, parsedData), [search]);
  if (loadingCategories) {
    return <Loading />;
  }

  return isEmpty(item) ? <div /> : (
    <Fragment>
      <ItemDetailsSingleCard {...{
        data: { ...item, title, subTitle },
        type,
        setModalState,
        categories
      }}/>
      {modalProps?.visible
      && <Modal
        closeModal={() => setModalState({ ...modalProps, visible: false })}
        {...modalProps}
      />
      }
    </Fragment>
  );
};

ItemDetails.propTypes = {
  grouped: PropTypes.bool,
  listTab: PropTypes.number,
  type: PropTypes.string,
  me: PropTypes.object
};

ItemDetails.defaultProps = {
  grouped: false,
  listTab: 1,
  type: '',
  me: {}
};

export default ItemDetails;

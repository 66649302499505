import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import { useMutation } from '@apollo/client';

import MainBtn from '../../shared/buttons/MainBtn';
import SimpleTable from '../../shared/dragging/SimpleTable';
import Donut from '../../myTeam/myEvolution/evaluations/Potential/Donut';
import ModalMessage from '../../shared/ModalMessage';
import Loader from '../../shared/MLLoading';
import {
  initializeTable, iconClassNameEDP, shapeDataToSendEDP, initializeTableEDP
} from '../../../helpers/myEvolution/calibracion';

import { UPDATE_CALIBRATION } from '../../../graphql/mievolucion/calibracion/mutations';

import '../../../static/styles/mievolucion/calibracion/stepperThree.less';
import '../../../static/styles/mievolucion/potential-evaluation/colors-classname.less';

const context = { clientName: 'myEvolution' };
const fetchPolicy = { fetchPolicy: 'network-only' };

const StepperThree = ({
  calibrationDispatch, calibrationReducer, editUsers, refetchHistory
}) => {
  const { calibration, modalState } = calibrationReducer;
  const [confirmModal, setConfirmModal] = useState({
    visible: false,
    showIcon: true,
    icon: 'icon-ml-warning pending-icon-color',
    title: '¿Estás seguro que deseas finalizar la calibración?',
    message: 'Recuerda que antes debes reunirte con tu reporte de forma presencial y revisar en conjunto su evaluación y su categoría de desempeño.'
  });
  const [leftColumn, setLeftColumn] = useState();
  const [tableEDP, setTableEDP] = useState();

  const [updateCalibration, {
    loading: loadingCalibrationEDP
  }] = useMutation(UPDATE_CALIBRATION, {
    context,
    ...fetchPolicy,
    onCompleted: (data) => {
      calibrationDispatch({
        type: 'set-modalState',
        payload: {
          visible: true,
          type: 'success',
          title: 'La calibración se ha guardado con éxito',
          message: '',
          toBegining: modalState.toBegining || false
        }
      });
      const { updateCalibration: updateCalibrations } = data;
      calibrationDispatch({
        type: 'set-calibration',
        payload: {
          calibration: updateCalibrations
        }
      });
    },
    onError: (err) => {
      calibrationDispatch({
        type: 'set-modalState',
        payload: {
          visible: true,
          type: 'error',
          message: err.message
        }
      });
    }
  });

  const buildLeftColumn = () => {
    const { edpResultsList } = calibration;
    return orderBy(edpResultsList, ['order'], ['asc'])
      .map((v, i) => ({
        label: v.name,
        IconComponent: Donut,
        iconClassName: iconClassNameEDP[i],
        percent: 0
      }));
  };

  useEffect(() => {
    calibrationDispatch({
      type: 'set-calibrationTable',
      payload: initializeTable(calibrationReducer)
    });
    setTableEDP(initializeTableEDP({ calibration }));
    setLeftColumn([
      {
        label: 'Categoría de Potencial',
        noIcon: true
      },
      ...buildLeftColumn()
    ]);
  }, []);

  useEffect(() => {
    if (tableEDP) {
      const newEDPStats = {};
      const newLeftColumn = leftColumn.slice(1, leftColumn.length);
      const keys = newLeftColumn.map(({ label }) => label);
      let total = 0;
      keys.map((key) => {
        newEDPStats[key] = { percent: 0, number: 0 };
        return null;
      });
      tableEDP.map((edpTableItem, i) => {
        const itemNumber = edpTableItem[0].length;
        newEDPStats[keys[i]].number += itemNumber;
        total += itemNumber;
        return null;
      });
      newLeftColumn.map((obj, i) => {
        const newEDPStat = newEDPStats[obj.label];
        if (newEDPStat) {
          newLeftColumn[i].percent = newEDPStat.number * 100 / total;
        }
        return null;
      });
      setLeftColumn([leftColumn[0], ...newLeftColumn]);
    }
  }, [tableEDP]);

  const submit = async (status) => {
    const calibrations = shapeDataToSendEDP({ calibration, table: tableEDP });
    const toSend = {
      _id: calibration._id,
      status,
      calibrations
    };
    await updateCalibration({ variables: toSend });
  };

  return (
    <div className="stepper-three-component">
      { modalState?.visible
      && <ModalMessage
        visible={modalState.visible}
        type={modalState.type}
        title={modalState.title}
        message={modalState.message}
        buttons={[
          {
            text: 'Cerrar',
            type: 'primary',
            onClickButton: modalState.toBegining ? () => {
              refetchHistory();
              calibrationDispatch({ type: 'reset' });
              calibrationDispatch({
                type: 'set-mainModal',
                payload: { visible: false, details: '' }
              });
            }
              : () => calibrationDispatch({ type: 'set-modalState', payload: { visible: false } })
          }
        ]}
      />
      }
      { confirmModal?.visible
      && <ModalMessage
        {...confirmModal}
        buttons={[
          {
            text: 'Finalizar Calibración',
            type: 'primary',
            onClickButton: () => {
              setConfirmModal({ ...confirmModal, visible: false });
              submit('FINISHED');
            }
          },
          {
            text: 'Cancelar',
            type: 'default',
            onClickButton: () => {
              calibrationDispatch({ type: 'set-modalState', payload: { visible: false } });
              setConfirmModal({ ...confirmModal, visible: false });
            }
          }
        ]}
      />
      }
      { loadingCalibrationEDP && (<Loader />)}
      { !loadingCalibrationEDP && (
        <>
          <div className="d-flex justify-content-center">
            {
              tableEDP && tableEDP.length && (
                <SimpleTable
                  leftColumn={leftColumn.map((obj, i) => (i ? { ...obj, label: `${obj.label} (${Math.round(obj.percent)}%)` } : obj))}
                  rightColumn={tableEDP}
                  setOutsideTable={setTableEDP}
                />
              )
            }
          </div>
          <div className="btns-wrapper d-flex justify-content-center flex-column">
            <div className='d-flex justify-content-between'>
              <MainBtn
                className="pr-2"
                mlType="inverse-main"
                width="200px"
                text="Editar Grilla"
                onClick={() => editUsers()}
              />
              <MainBtn
                className="mr-2"
                width="200px"
                text="Guardar Calibración"
                onClick={() => submit('PENDING')}
              />
              <MainBtn mlType="main" width="200px" text="Finalizar Calibración" onClick={() => {
                calibrationDispatch({ type: 'set-modalState', payload: { ...modalState, toBegining: true } });
                setConfirmModal({ ...confirmModal, visible: true });
              }}/>
            </div>
            <a href="#" className="m-0 d-flex justify-content-center align-items-center mt-4"
              onClick={(e) => {
                e.preventDefault();
                calibrationDispatch({ type: 'step-backward' });
              }}>{'< Ir atrás'}
            </a>
          </div>
        </>
      )}
    </div>
  );
};

StepperThree.propTypes = {
  calibrationDispatch: PropTypes.func,
  calibrationReducer: PropTypes.object,
  editUsers: PropTypes.func,
  refetchHistory: PropTypes.func
};

StepperThree.defaultProps = {
  calibrationDispatch: () => {},
  calibrationReducer: {},
  editUsers: () => {},
  refetchHistory: () => {}
};

export default StepperThree;

import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Form } from 'antd';
import usePerformance from '../../../../../../../../hooks/myEvolution/myPerformance/usePerformance';
import MLSelect from '../../../../../../../shared/forms/Select';
import {
  AMOUNT_OF_PULSES, FIRST_PULSE,
  MONTH_ADVANCE_COMMENTS_BY_Q,
  successMeasuresStatusOptions
} from '../../../../../../../../helpers/myEvolution/myPerformance';

const SuccessMeasuresUpdates = ({ form, index }) => {
  const { data: performance = {} } = usePerformance();
  const { quarterCurrent: currentQuarter } = performance;

  if (currentQuarter === FIRST_PULSE) return null;
  const { getFieldDecorator, getFieldsValue } = form;
  const successMeasuresUpdatesQuarters = [];

  const getClassName = (qKey) => {
    const { successMeasures } = getFieldsValue(['successMeasures']);
    const updates = successMeasures[index]?.updates;
    const updateQStatus = updates && updates[qKey]?.status;
    let classNameResult = 'success-measure-description-update-status';

    if (updateQStatus) {
      classNameResult += ` success-measure-description-update-status-${updateQStatus.toLowerCase().replace(/_/g, '-')}`;
    }

    return classNameResult;
  };

  for (let qi = 1; qi <= AMOUNT_OF_PULSES; qi += 1) {
    const qKey = `q${qi}`;

    successMeasuresUpdatesQuarters.push(
      <Form.Item key={uuidv4()} {...{
        label: `Avance a ${MONTH_ADVANCE_COMMENTS_BY_Q[qi]}`,
        className: getClassName(qKey),
        ...(qKey === currentQuarter ? null : { className: 'hidden' })
      }}>
        {getFieldDecorator(`successMeasures[${index}][updates][${qKey}][status]`, {
          rules: [
            {
              validator: (_, value, callback) => {
                const { successMeasures } = getFieldsValue(['successMeasures']);

                if (qKey === currentQuarter) {
                  if (!successMeasures.length) return callback('Cada objetivo debe tener al menos una medida de exito');
                }

                return callback();
              }
            }
          ]
        })(
          <MLSelect {...{ options: successMeasuresStatusOptions, placeholder: 'Selecciona una opción', suffixIcon: <i className='icon-ml-select-dd'/> }}/>
        )}
      </Form.Item>
    );
  }

  return <>{successMeasuresUpdatesQuarters}</>;
};

SuccessMeasuresUpdates.propTypes = {
  form: PropTypes.object,
  index: PropTypes.number
};

SuccessMeasuresUpdates.defaultProps = {
  form: {},
  index: 0
};

export default SuccessMeasuresUpdates;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../static/styles/collapsable-panel.less';

const CollapsablePanel = ({
  children, text, openCollapsed = false, className
}) => {
  const [collapsed, setCollapsed] = useState(openCollapsed);

  return (<div className={`collapsable-panel ${className || ''}`}>
    <div onClick={() => setCollapsed(!collapsed)} className='collapse-button mb-3' >
      {collapsed ? 'Mostrar' : 'Ocultar'} {text}{' '}
      <i className={`icon-ml-forward ${collapsed ? 'collapsed' : ''}`} />
    </div>
    <div className='collapse-display'>
      <div className={collapsed ? 'collapsed' : ''}>
        {children}
      </div>
    </div>
  </div>);
};

CollapsablePanel.propTypes = {
  children: PropTypes.element,
  text: PropTypes.string,
  openCollapsed: PropTypes.bool,
  className: PropTypes.string
};

CollapsablePanel.defaultProps = {
  children: undefined,
  text: undefined,
  openCollapsed: false,
  className: undefined
};

export default CollapsablePanel;

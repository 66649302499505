import React, { useState } from 'react';

import { Radio } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import SearchByRUT from '../../../../components/shared/filters/FilterListBy';
import ScrollableTable from '../../../../components/shared/tables/ScrollableTable';
import { checkRut } from '../../../../helpers/rut_validator';
import { GET_IDP } from '../../../../graphql/mievolucion/miDesarrollo/queries';
import {
  columnsMyTeamIDP as columnsAdminIDP,
  getValuesMyTeamIDP as getValuesReportIDP,
  transformValuesReportIDP
} from '../../../../helpers/myTeam/myEvolution/developmentPlans';
import '../../../../static/styles/admin/mi-desarrollo.less';
import MLLoading from '../../../../components/shared/MLLoading';
import SearchIDPGerencia from './SearchIDPGerencia';
import { GET_COLLABORATORS_BY_GERENCIA_IDPS } from '../../../../graphql/mievolucion/idp/queries';

const SEARCH_OPTION = {
  RUT: 'RUT',
  GERENCIA: 'GERENCIA'
};

const VisualizarIDP = () => {
  const [option, setOption] = useState(SEARCH_OPTION.RUT);
  const [tableData, setTableData] = useState([]);
  const [colorButton, setColorButton] = useState(false);
  const [buttonState, setButtonState] = useState(true);

  const [getIDP, { loading }] = useLazyQuery(
    GET_IDP, {
      context: { clientName: 'myEvolution' },
      fetchPolicy: 'network-only',
      onCompleted: ({ getIDP: IDPResponse }) => {
        setTableData(getValuesReportIDP(transformValuesReportIDP([IDPResponse?.data])));
      }
    }
  );

  const [getIdpsByGerencia, { loading: loadingGerencias }] = useLazyQuery(
    GET_COLLABORATORS_BY_GERENCIA_IDPS, {
      context: { clientName: 'myEvolution' },
      fetchPolicy: 'network-only',
      onCompleted: ({ getIdpsByGerencia: IDPGerencia }) => {
        setTableData(getValuesReportIDP(IDPGerencia?.data));
      }
    }
  );

  const handleEnviardatos = (rut) => {
    getIDP({ variables: { rut } });
  };

  const handleInputChange = (e) => {
    const { invalid } = checkRut(e.target.value);
    if (!invalid) {
      setColorButton(true);
      setButtonState(false);
    } else {
      setColorButton(false);
      setButtonState(true);
    }
  };

  const handleChangeOption = (value) => {
    getIdpsByGerencia({ variables: { gerencia: value } });
  };

  return <div className='admin-mi-desarrollo-idp-container'>
    <div className='admin-search-by-rut-or-division'>
      <Radio.Group
        className='inputgroup'
        onChange={(e) => {
          setOption(e.target.value);
          setTableData([]);
        }}
        value={option}
        size='large'>
        <Radio value={SEARCH_OPTION.RUT} style={{ whiteSpace: 'normal' }}> Rut Colaborador </Radio>
        <Radio value={SEARCH_OPTION.GERENCIA} style={{ whiteSpace: 'normal' }}> Gerencia </Radio>
      </Radio.Group>
    </div>
    {
      option === SEARCH_OPTION.RUT
        ? <SearchByRUT
          colorButton={colorButton}
          buttonState={buttonState}
          handleInputChange={handleInputChange}
          enviarDatos={handleEnviardatos}/>
        : <SearchIDPGerencia handleOnchange={handleChangeOption}/>

    }

    {
      (loading || loadingGerencias) && <MLLoading title={'Los datos se están cargando'}
        message={'Por favor espera, ya que el proceso puede tomar algunos segundos.'}/>
    }
    {
      (!loading || !loadingGerencias) && !isEmpty(tableData) && <div>
        <div className='ant-table-wrapper-div'>
          <ScrollableTable classes='mt-4' columns={columnsAdminIDP} dataSource={tableData} scroll={{ x: 'max-content' }} />
        </div>
      </div>
    }

  </div>;
};
export default VisualizarIDP;

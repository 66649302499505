export const actionTypes = {
  GET_UNIFORMS_BY_RUT_REPORT: 'GET_UNIFORMS_BY_RUT_REPORT',
  GET_UNIFORMS_BY_RUT_REPORT_SUCCESS: 'GET_UNIFORMS_BY_RUT_REPORT_SUCCESS',
  GET_UNIFORMS_BY_RUT_REPORT_ERROR: 'GET_UNIFORMS_BY_RUT_REPORT_ERROR',
  GET_UNIFORMS_BY_GARMENT_REPORT: 'GET_UNIFORMS_BY_GARMENT_REPORT',
  GET_UNIFORMS_BY_GARMENT_REPORT_SUCCESS: 'GET_UNIFORMS_BY_GARMENT_REPORT_SUCCESS',
  GET_UNIFORMS_BY_GARMENT_REPORT_ERROR: 'GET_UNIFORMS_BY_GARMENT_REPORT_ERROR',
  GET_UNIFORMS: 'GET_UNIFORMS',
  GET_UNIFORMS_SUCCESS: 'GET_UNIFORMS_SUCCESS',
  GET_UNIFORMS_FAILURE: 'GET_UNIFORMS_FAILURE',
  UPDATE_UNIFORMS: 'UPDATE_UNIFORMS',
  UPDATE_UNIFORMS_SUCCESS: 'UPDATE_UNIFORMS_SUCCESS',
  UPDATE_UNIFORMS_FAILURE: 'UPDATE_UNIFORMS_FAILURE',
  GET_UNIFORMS_ADMIN: 'GET_UNIFORMS_ADMIN',
  GET_UNIFORMS_ADMIN_SUCCESS: 'GET_UNIFORMS_ADMIN_SUCCESS',
  GET_UNIFORMS_ADMIN_FAILURE: 'GET_UNIFORMS_ADMIN_FAILURE',
  GET_UNIFORMS_PERMISSIONS: 'GET_UNIFORMS_PERMISSIONS',
  GET_UNIFORMS_PERMISSIONS_SUCCESS: 'GET_UNIFORMS_PERMISSIONS_SUCCESS',
  GET_UNIFORMS_PERMISSIONS_FAILURE: 'GET_UNIFORMS_PERMISSIONS_FAILURE',
  UPDATE_UNIFORMS_PERMISSIONS: 'UPDATE_UNIFORMS_PERMISSIONS',
  UPDATE_UNIFORMS_PERMISSIONS_SUCCESS: 'UPDATE_UNIFORMS_PERMISSIONS_SUCCESS',
  UPDATE_UNIFORMS_PERMISSIONS_FAILURE: 'UPDATE_UNIFORMS_PERMISSIONS_FAILURE',
  DELETE_UNIFORMS_PERMISSIONS: 'DELETE_UNIFORMS_PERMISSIONS',
  DELETE_UNIFORMS_PERMISSIONS_SUCCESS: 'DELETE_UNIFORMS_PERMISSIONS_SUCCESS',
  DELETE_UNIFORMS_PERMISSIONS_FAILURE: 'DELETE_UNIFORMS_PERMISSIONS_FAILURE',
  UPDATE_UNIFORMS_CONVENTION_GROUP: 'UPDATE_UNIFORMS_CONVENTION_GROUP',
  UPDATE_UNIFORMS_CONVENTION_GROUP_SUCCESS: 'UPDATE_UNIFORMS_CONVENTION_GROUP_SUCCESS',
  UPDATE_UNIFORMS_CONVENTION_GROUP_FAILURE: 'UPDATE_UNIFORMS_CONVENTION_GROUP_FAILURE',
  GET_UNIFORMS_DELIVERIES: 'GET_UNIFORMS_DELIVERIES',
  GET_UNIFORMS_DELIVERIES_SUCCESS: 'GET_UNIFORMS_DELIVERIES_SUCCESS',
  GET_UNIFORMS_DELIVERIES_FAILURE: 'GET_UNIFORMS_DELIVERIES_FAILURE',
  SET_UNIFORMS_DELIVERIES: 'SET_UNIFORMS_DELIVERIES',
  SET_UNIFORMS_DELIVERIES_SUCCESS: 'SET_UNIFORMS_DELIVERIES_SUCCESS',
  SET_UNIFORMS_DELIVERIES_FAILURE: 'SET_UNIFORMS_DELIVERIES_FAILURE'
};
// Admin
export function getUniformsByRutReport(codDivision) {
  return {
    type: actionTypes.GET_UNIFORMS_BY_RUT_REPORT,
    codDivision
  };
}

export function getUniformsByRutReportSuccess(data) {
  return {
    type: actionTypes.GET_UNIFORMS_BY_RUT_REPORT_SUCCESS,
    data
  };
}

export function getUniformsByRutReportError(error) {
  return {
    type: actionTypes.GET_UNIFORMS_BY_RUT_REPORT_ERROR,
    error
  };
}

export function getUniformsByGarmentReport(codDivision) {
  return {
    type: actionTypes.GET_UNIFORMS_BY_GARMENT_REPORT,
    codDivision
  };
}

export function getUniformsByGarmentReportSuccess(data) {
  return {
    type: actionTypes.GET_UNIFORMS_BY_GARMENT_REPORT_SUCCESS,
    data
  };
}

export function getUniformsByGarmentReportError(error) {
  return {
    type: actionTypes.GET_UNIFORMS_BY_GARMENT_REPORT_ERROR,
    error
  };
}

// Uniforms
export function updateUniforms(data) {
  return {
    type: actionTypes.UPDATE_UNIFORMS,
    payload: data
  };
}

export function updateUniformsSuccess(data) {
  return {
    type: actionTypes.UPDATE_UNIFORMS_SUCCESS,
    payload: data
  };
}
export function updateUniformsFailure() {
  return {
    type: actionTypes.UPDATE_UNIFORMS_FAILURE
  };
}

export function getUniformsSuccess(data) {
  return {
    type: actionTypes.GET_UNIFORMS_SUCCESS,
    payload: data
  };
}

export function getUniformsFailure() {
  return {
    type: actionTypes.GET_UNIFORMS_FAILURE
  };
}

export function getUniforms(data) {
  return {
    payload: data,
    type: actionTypes.GET_UNIFORMS
  };
}

// Uniforms Admin
export function getUniformsAdminSuccess(data) {
  return {
    type: actionTypes.GET_UNIFORMS_ADMIN_SUCCESS,
    payload: data
  };
}

export function getUniformsAdminFailure() {
  return {
    type: actionTypes.GET_UNIFORMS_ADMIN_FAILURE
  };
}

export function getUniformsAdmin(data) {
  return {
    type: actionTypes.GET_UNIFORMS_ADMIN,
    payload: data
  };
}

// UniformsPermissions
export function getUniformsPermissionsSuccess(data) {
  return {
    type: actionTypes.GET_UNIFORMS_PERMISSIONS_SUCCESS,
    payload: data
  };
}

export function getUniformsPermissionsFailure() {
  return {
    type: actionTypes.GET_UNIFORMS_PERMISSIONS_FAILURE
  };
}

export function getUniformsPermissions(data) {
  return {
    type: actionTypes.GET_UNIFORMS_PERMISSIONS,
    payload: data
  };
}

export function updateUniformsPermissions(data) {
  return {
    type: actionTypes.UPDATE_UNIFORMS_PERMISSIONS,
    payload: data
  };
}

export function updateUniformsPermissionsSuccess(data) {
  return {
    type: actionTypes.UPDATE_UNIFORMS_PERMISSIONS_SUCCESS,
    payload: data
  };
}
export function updateUniformsPermissionsFailure() {
  return {
    type: actionTypes.UPDATE_UNIFORMS_PERMISSIONS_FAILURE
  };
}

export function deleteUniformsPermissions(data) {
  return {
    type: actionTypes.DELETE_UNIFORMS_PERMISSIONS,
    payload: data
  };
}

export function deleteUniformsPermissionsSuccess(data) {
  return {
    type: actionTypes.DELETE_UNIFORMS_PERMISSIONS_SUCCESS,
    payload: data
  };
}
export function deleteUniformsPermissionsFailure() {
  return {
    type: actionTypes.DELETE_UNIFORMS_PERMISSIONS_FAILURE
  };
}

export function updateConventionGroup(data) {
  return {
    type: actionTypes.UPDATE_UNIFORMS_CONVENTION_GROUP,
    payload: data
  };
}

export function updateConventionGroupSuccess(data) {
  return {
    type: actionTypes.UPDATE_UNIFORMS_CONVENTION_GROUP_SUCCESS,
    payload: data
  };
}
export function updateConventionGroupFailure(data) {
  return {
    type: actionTypes.UPDATE_UNIFORMS_CONVENTION_GROUP_FAILURE,
    payload: data
  };
}

// Solicitudes por Temporada
export function getUniformsDeliveries(data) {
  return {
    payload: data,
    type: actionTypes.GET_UNIFORMS_DELIVERIES
  };
}

export function getUniformsDeliveriesSuccess(data) {
  return {
    type: actionTypes.GET_UNIFORMS_DELIVERIES_SUCCESS,
    payload: data
  };
}

export function getUniformsDeliveriesFailure() {
  return {
    type: actionTypes.GET_UNIFORMS_DELIVERIES_FAILURE
  };
}

export function setUniformsDeliveries(data) {
  return {
    payload: data,
    type: actionTypes.SET_UNIFORMS_DELIVERIES
  };
}

export function setUniformsDeliveriesSuccess(data) {
  return {
    type: actionTypes.SET_UNIFORMS_DELIVERIES_SUCCESS,
    payload: data
  };
}

export function setUniformsDeliveriesFailure() {
  return {
    type: actionTypes.SET_UNIFORMS_DELIVERIES_FAILURE
  };
}

export const actionTypes = {
  GET_MERIT_LETTERS_REPORT: 'GET_MERIT_LETTERS_REPORT',
  GET_MERIT_LETTERS_REPORT_SUCCESS: 'GET_MERIT_LETTERS_REPORT_SUCCESS',
  GET_MERIT_LETTERS_REPORT_ERROR: 'GET_MERIT_LETTERS_REPORT_ERROR',
  GET_FEEDBACK: 'GET_FEEDBACK',
  GET_FEEDBACK_REPORTS_SUCCESS: 'GET_FEEDBACK_REPORTS_SUCCESS',
  GET_FEEDBACK_REPORTS_ERROR: 'GET_FEEDBACK_REPORTS_ERROR',
  GET_OBJECTIVES: 'GET_OBJECTIVES',
  GET_OBJECTIVES_REPORTS_SUCCESS: 'GET_OBJECTIVES_REPORTS_SUCCESS',
  GET_OBJECTIVES_REPORTS_ERROR: 'GET_OBJECTIVES_REPORTS_ERROR',
  GET_EVOLUCIONATEST: 'GET_EVOLUCIONATEST',
  GET_EVOLUCIONATEST_REPORTS_SUCCESS: 'GET_EVOLUCIONATEST_REPORTS_SUCCESS',
  GET_EVOLUCIONATEST_REPORTS_ERROR: 'GET_EVOLUCIONATEST_REPORTS_ERROR',
  GET_SOCIAL_ASSISTANT_REPORT: 'GET_SOCIAL_ASSISTANT_REPORT',
  GET_SOCIAL_ASSISTANT_REPORT_SUCCESS: 'GET_SOCIAL_ASSISTANT_REPORT_SUCCESS',
  GET_SOCIAL_ASSISTANT_REPORT_ERROR: 'GET_SOCIAL_ASSISTANT_REPORT_ERROR',
  GET_SOCIAL_ASSISTANT_LIST: 'GET_SOCIAL_ASSISTANT_LIST',
  GET_SECTION_REPORT: 'GET_SECTION_REPORT',
  GET_SECTION_REPORT_SUCCESS: 'GET_SECTION_REPORT_SUCCESS',
  GET_SECTION_REPORT_FAILURE: 'GET_SECTION_REPORT_FAILURE',

  GET_EDD_REPORTS: 'GET_EDD_REPORTS',
  GET_EDD_REPORTS_SUCCESS: 'GET_EDD_REPORTS_SUCCESS',
  GET_EDD_REPORTS_ERROR: 'GET_EDD_REPORTS_ERROR',
  GET_PERFORMANCE_REPORTS: 'GET_PERFORMANCE_REPORTS',
  GET_PERFORMANCE_WALMART_REPORTS: 'GET_PERFORMANCE_WALMART_REPORTS',
  GET_PERFORMANCE_WALMART_PROGRESS_REPORTS: 'GET_PERFORMANCE_WALMART_PROGRESS_REPORTS',
  GET_PERFORMANCE_WALMART_TEMPLATE_REPORTS: 'GET_PERFORMANCE_WALMART_TEMPLATE_REPORTS',
  GET_SURVEY_PERFORMANCE_WALMART_REPORTS: 'GET_SURVEY_PERFORMANCE_WALMART_REPORTS',
  GET_FEEDBACKNETWORK_REPORTS: 'GET_FEEDBACKNETWORK_REPORTS',
  GET_COMPETENCIES_REPORTS: 'GET_COMPETENCIES_REPORTS',
  GET_STATUS_FEEDBACK_NETWORK: 'GET_STATUS_FEEDBACK_NETWORK',
  GET_TRACEABILITY_REPORT: 'GET_TRACEABILITY_REPORT',
  GET_TRACEABILITY_REPORT_SUCCESS: 'GET_TRACEABILITY_REPORT_SUCCESS',
  GET_TRACEABILITY_REPORT_ERROR: 'GET_TRACEABILITY_REPORT_ERROR',
  GET_INTERESTANDSKILLS_REPORTS: 'GET_INTERESTANDSKILLS_REPORTS',
  GET_EDD_AUTOEVALUATIONS_REPORTS: 'GET_EDD_AUTOEVALUATIONS_REPORTS',
  GET_EDD_EVALUATIONS_REPORTS: 'GET_EDD_EVALUATIONS_REPORTS',
  GET_EDP_REPORTS: 'GET_EDP_REPORTS',
  GET_EDD_OPINION_EVALUATION_REPORT: 'GET_EDD_OPINION_EVALUATION_REPORT',
  GET_MERITS_TRACEABILITY: 'GET_MERITS_TRACEABILITY',
  GET_MERITS_TRACEABILITY_SUCCESS: 'GET_MERITS_TRACEABILITY_SUCCESS',
  GET_MERITS_TRACEABILITY_ERROR: 'GET_MERITS_TRACEABILITY_SUCCESS',
  UPDATE_REPORT_STATUS: 'UPDATE_REPORT_STATUS',
  GET_RECONOCIMIENTO_REPORT: 'GET_RECONOCIMIENTO_REPORT',
  GET_RECONOCIMIENTO_REPORT_SUCCESS: 'GET_RECONOCIMIENTO_REPORT_SUCCESS',
  GET_RECONOCIMIENTO_REPORT_ERROR: 'GET_RECONOCIMIENTO_REPORT_ERROR',
  GET_RECONOCIMIENTO_COMMENTS_REPORT: 'GET_RECONOCIMIENTO_COMMENTS_REPORT',
  GET_RECONOCIMIENTO_COMMENTS_REPORT_SUCCESS: 'GET_RECONOCIMIENTO_COMMENTS_REPORT_SUCCESS',
  GET_RECONOCIMIENTO_COMMENTS_REPORT_ERROR: 'GET_RECONOCIMIENTO_COMMENTS_REPORT_ERROR',
  GET_LEADER_PEOPLE_STORE: 'GET_LEADER_PEOPLE_STORE',
  GET_LEADER_PEOPLE_STORE_SUCCESS: 'GET_LEADER_PEOPLE_STORE_SUCCESS',
  GET_LEADER_PEOPLE_STORE_ERROR: 'GET_LEADER_PEOPLE_STORE_ERROR',

  GET_IDP_REPORT: 'GET_IDP_REPORT',
  GET_IDP_REPORT_SUCCESS: 'GET_IDP_REPORT_SUCCESS',
  GET_IDP_REPORT_ERROR: 'GET_IDP_REPORT_ERROR'
};

export function getInterestAndSkillsReport(socketId) {
  return {
    type: actionTypes.GET_INTERESTANDSKILLS_REPORTS,
    socketId
  };
}

export function getCompetenciesReport(socketId, year) {
  return {
    type: actionTypes.GET_COMPETENCIES_REPORTS,
    socketId,
    year
  };
}

export function getFeedbackNetworkReport(socketId, year) {
  return {
    type: actionTypes.GET_FEEDBACKNETWORK_REPORTS,
    socketId,
    year
  };
}

export function getPerformanceWalmartReport(year) {
  return {
    type: actionTypes.GET_PERFORMANCE_WALMART_REPORTS,
    year
  };
}

export function getSurveyPerformanceWalmartReport(socketId, year) {
  return {
    type: actionTypes.GET_SURVEY_PERFORMANCE_WALMART_REPORTS,
    socketId,
    year
  };
}

export function getPerformanceWalmartProgressReport(socketId) {
  return {
    type: actionTypes.GET_PERFORMANCE_WALMART_PROGRESS_REPORTS,
    socketId
  };
}

export function getPerformanceWalmartTemplateReport(socketId) {
  return {
    type: actionTypes.GET_PERFORMANCE_WALMART_TEMPLATE_REPORTS,
    socketId
  };
}

export function getPerformanceReport(socketId, year) {
  return {
    type: actionTypes.GET_PERFORMANCE_REPORTS,
    socketId,
    year
  };
}

export function getEDDReport(year) {
  return {
    type: actionTypes.GET_EDD_REPORTS,
    year
  };
}

export function getEDDReportError(error) {
  return {
    type: actionTypes.GET_EDD_REPORTS_ERROR,
    error
  };
}

export function getEDDReportSuccess(payload) {
  return {
    type: actionTypes.GET_EDD_REPORTS_SUCCESS,
    payload
  };
}

export function getMeritLettersReport() {
  return {
    type: actionTypes.GET_MERIT_LETTERS_REPORT
  };
}

export function getMeritLettersReportSuccess(payload) {
  return {
    type: actionTypes.GET_MERIT_LETTERS_REPORT_SUCCESS,
    payload
  };
}

export function getMeritLettersReportError() {
  return {
    type: actionTypes.GET_MERIT_LETTERS_REPORT_ERROR
  };
}

export function gelSocialAssistantList() {
  return {
    type: actionTypes.GET_SOCIAL_ASSISTANT_LIST
  };
}

export function gelSocialAssistantError(error) {
  return {
    type: actionTypes.GET_SOCIAL_ASSISTANT_REPORT_ERROR,
    error
  };
}

export function getFeedback(socketId) {
  return {
    type: actionTypes.GET_FEEDBACK,
    socketId
  };
}

export function getEvolucionaTEST() {
  return {
    type: actionTypes.GET_EVOLUCIONATEST
  };
}

export function getEvolucionaTESTReportsError(error) {
  return {
    type: actionTypes.GET_EVOLUCIONATEST_REPORTS_ERROR,
    error
  };
}

export function getEvolucionaTESTReportsSuccess(payload) {
  return {
    type: actionTypes.GET_EVOLUCIONATEST_REPORTS_SUCCESS,
    payload
  };
}

export function getObjectives() {
  return {
    type: actionTypes.GET_OBJECTIVES
  };
}

export function getObjectivesReportsError(error) {
  return {
    type: actionTypes.GET_OBJECTIVES_REPORTS_ERROR,
    error
  };
}

export function getObjectivesReportsSuccess(payload) {
  return {
    type: actionTypes.GET_OBJECTIVES_REPORTS_SUCCESS,
    payload
  };
}

export function getSocialAssistant() {
  return {
    type: actionTypes.GET_SOCIAL_ASSISTANT_REPORT
  };
}

export function getSocialAssistantSuccess(payload) {
  return {
    type: actionTypes.GET_SOCIAL_ASSISTANT_REPORT_SUCCESS,
    payload
  };
}
export function getSocialAssistantError(error) {
  return {
    type: actionTypes.get_soc,
    error
  };
}

export function getSectionReport() {
  return {
    type: actionTypes.GET_SECTION_REPORT
  };
}

export function getSectionReportSuccess(data) {
  return {
    type: actionTypes.GET_SECTION_REPORT_SUCCESS,
    data
  };
}

export function getSectionReportFailure(error) {
  return {
    type: actionTypes.GET_SECTION_REPORT_FAILURE,
    error
  };
}

export function getTraceabilityReport() {
  return {
    type: actionTypes.GET_TRACEABILITY_REPORT
  };
}

export function getTraceabilityReportSuccess(data) {
  return {
    type: actionTypes.GET_TRACEABILITY_REPORT_SUCCESS,
    data
  };
}

export function getTraceabilityReportError() {
  return {
    type: actionTypes.GET_TRACEABILITY_REPORT_ERROR
  };
}

export function checkPermits() {
  return {
    type: actionTypes.CHECK_PERMITS
  };
}

export function getStatusFeedbackNetworkReport(socketId) {
  return {
    type: actionTypes.GET_STATUS_FEEDBACK_NETWORK,
    socketId
  };
}

export function getEDDAutoevaluationsReport(year) {
  return {
    type: actionTypes.GET_EDD_AUTOEVALUATIONS_REPORTS,
    year
  };
}

export function getEDDEvaluationsReport(year) {
  return {
    type: actionTypes.GET_EDD_EVALUATIONS_REPORTS,
    year
  };
}

export function getEDPReport(year) {
  return {
    type: actionTypes.GET_EDP_REPORTS,
    year
  };
}

export function getEDDOpinionEvaluationReport(socketId, year) {
  return {
    type: actionTypes.GET_EDD_OPINION_EVALUATION_REPORT,
    socketId,
    year
  };
}

export function getMeritsTraceability(error) {
  return {
    type: actionTypes.GET_MERITS_TRACEABILITY,
    error
  };
}

export function getMeritsTraceabilitySuccess(data) {
  return {
    type: actionTypes.GET_MERITS_TRACEABILITY_SUCCESS,
    data
  };
}

export function getMeritsTraceabilityError(error) {
  return {
    type: actionTypes.GET_CALIBRATIONS_REPORT_ERROR,
    error
  };
}

export function updateReportStatus(reportUpdate) {
  return {
    type: actionTypes.UPDATE_REPORT_STATUS,
    reportUpdate
  };
}

export function getReconocimiento() {
  return {
    type: actionTypes.GET_RECONOCIMIENTO_REPORT
  };
}

export function getReconocimientoSuccess(payload) {
  return {
    type: actionTypes.GET_RECONOCIMIENTO_REPORT_SUCCESS,
    payload
  };
}

export function getReconocimientoError(error) {
  return {
    type: actionTypes.GET_RECONOCIMIENTO_REPORT_ERROR,
    error
  };
}

export function getReconocimientoComentarios() {
  return {
    type: actionTypes.GET_RECONOCIMIENTO_COMMENTS_REPORT
  };
}

export function getReconocimientoComentariosSuccess(payload) {
  return {
    type: actionTypes.GET_RECONOCIMIENTO_COMMENTS_REPORT_SUCCESS,
    payload
  };
}

export function getReconocimientoComentariosError(error) {
  return {
    type: actionTypes.GET_RECONOCIMIENTO_COMMENTS_REPORT_ERROR,
    error
  };
}

export function getLeaderPeopleStore() {
  return {
    type: actionTypes.GET_LEADER_PEOPLE_STORE
  };
}

export function getLeaderPeopleStoreSuccess(payload) {
  return {
    type: actionTypes.GET_LEADER_PEOPLE_STORE_SUCCESS,
    payload
  };
}
export function getLeaderPeopleStoreError(error) {
  return {
    type: actionTypes.GET_LEADER_PEOPLE_STORE_ERROR,
    error
  };
}

export function getIDPReport(socketId) {
  return {
    type: actionTypes.GET_IDP_REPORT,
    socketId
  };
}

export function getIDPError(error) {
  return {
    type: actionTypes.GET_IDP_REPORT_ERROR,
    error
  };
}

export function getIDPSuccess(payload) {
  return {
    type: actionTypes.GET_IDP_REPORT_SUCCESS,
    payload
  };
}

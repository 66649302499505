import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import createSearchUserInputsData from '../../../../helpers/searchUser/createInputsData';
import useUnreactiveState from '../../states/useUnreactiveState';
import useIsSmallDevice from '../../responsive/useIsSmallDevice';
import { fullCapitalizeFormat } from '../../../../helpers/strings';

export default function useSearchUserInput({
  disabled, setRefetchTrigger, initValue, placeholder
}) {
  const isSmallDevice = useIsSmallDevice();
  const [firstName, setFirstName] = useState('');
  const [blockedFirstName, setUnfeactiveFirstName] = useUnreactiveState(firstName, setFirstName);
  const [focused, setFocused] = useState(false);

  const { firstNameInput } = createSearchUserInputsData({
    disabled, firstName, setFirstName, setFocused, isSmallDevice, placeholder
  });

  useEffect(() => {
    if (!isEmpty(initValue)) {
      setFirstName(fullCapitalizeFormat(initValue));
    }
  }, [initValue]);

  useEffect(() => {
    if (!firstName && blockedFirstName) {
      setUnfeactiveFirstName(firstName);
    }
  }, [firstName]);

  return {
    blockedFirstName,
    firstName,
    firstNameInput,
    focused,
    setFirstName,
    setFocused,
    setRefetchTrigger,
    setUnfeactiveFirstName
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Form, Input } from 'antd';
import MLTextArea from '../../../../../shared/MLTextArea';
import MLErrorModal from '../../../../../shared/MLModal/Message';
import useOpenModal from '../../../../../../hooks/shared/mlModal/useOpenModal';
import { UPDATE_STATUS_PERFORMANCE_WALMART } from '../../../../../../graphql/mievolucion/myPerformance/mutations';
import '../../../../../../static/styles/my-team/my-evolution/performance/reject-form.less';
import { GET_NOTIFICATIONS } from '../../../../../../graphql/notifications/queries';
import { CLOSED, PLACEHOLDER_COMMENTS_BY_Q, SENT } from '../../../../../../helpers/myEvolution/myPerformance';

const CommentForm = ({
  form, formRef, bossParams, quarter, setComments, comments
}) => {
  const [updatePerformanceStatus] = useMutation(
    UPDATE_STATUS_PERFORMANCE_WALMART,
    {
      context: { clientName: 'myEvolution' },
      refetchQueries: [
        {
          query: GET_NOTIFICATIONS
        }
      ]
    }
  );

  const { getFieldDecorator, validateFields } = form;
  const { open: showError, refOpen: refShowError } = useOpenModal();

  const handleSubmit = (events, submit) => {
    validateFields(async (err, values) => {
      if (!err) {
        try {
          await updatePerformanceStatus({ variables: values });
          return submit(events);
        } catch (_) {
          showError();
        }
      } else {
        showError({
          title: 'No se puede enviar el avance, ya que falta agregar el comentario de desempeño.',
          message: ''
        });
      }

      return events.closeModal();
    });
  };

  const commentLabel = bossParams ? `Desempeño de ${bossParams.selectedUser.name}` : 'Comentario';
  const commentName = `comment${bossParams ? 'Boss' : 'Colaborate'}`;
  const placeholderKey = bossParams ? 'boss' : 'colaborate';

  return (
    <div className='ml-performance-reject-form'>
      <Form ref={formRef} onSubmit={handleSubmit}>
        {bossParams?.selectedUser?.rut && (
          <Form.Item className='hidden'>{getFieldDecorator('rut')(<Input/>)}</Form.Item>
        )}

        <Form.Item
          label={commentLabel}>{getFieldDecorator(`input[${commentName}]`, {
            rules: [{ required: true, message: 'Este campo es necesario para continuar.' }]
          })(<MLTextArea
            onChange={(e) => {
              const newComments = comments;
              newComments[quarter][commentName] = e.target.value;
              setComments(newComments);
            }}
            maxLength={4000}
            placeholder={PLACEHOLDER_COMMENTS_BY_Q[quarter][placeholderKey] || ''}/>)}
        </Form.Item>

        <Form.Item className='hidden'>{getFieldDecorator('input[status]')(<Input/>)}</Form.Item>
      </Form>

      <MLErrorModal {...{
        title: 'No pudimos devolver la Propuesta',
        message: 'Estamos trabajando en solucionarlo, por favor intenta de nuevo más tarde.',
        refOpen: refShowError
      }}/>
    </div>
  );
};

CommentForm.propTypes = {
  form: PropTypes.object,
  formRef: PropTypes.object,
  bossParams: PropTypes.object,
  srcData: PropTypes.object,
  quarter: PropTypes.string,
  setComments: PropTypes.func,
  comments: PropTypes.object
};

CommentForm.defaultProps = {
  form: {},
  formRef: null,
  bossParams: null,
  srcData: {},
  quarter: '',
  setComments: () => {},
  comments: {}
};

export default Form.create({
  mapPropsToFields(props) {
    const { bossParams, srcData } = props;

    return {
      ...(bossParams ? {
        rut: Form.createFormField({
          value: bossParams.selectedUser.rut
        })
      } : {}),
      'input[status]': Form.createFormField({
        value: bossParams ? CLOSED : SENT
      }),
      'input[commentColaborate]': Form.createFormField({
        value: srcData.commentColaborate
      }),
      'input[commentBoss]': Form.createFormField({
        value: srcData.commentBoss
      })
    };
  }
})(CommentForm);

import React from 'react';
import { Input } from 'antd-v4';
import '../../../static/styles/uniforms/style.less';
import PropTypes from 'prop-types';

const { Search } = Input;

const SearchInTable = ({
  handleSearch, isMobile, placeholder, value
}) => (
  <Search
    className={`uniforms-search-input${isMobile ? ' uniforms-mobile-margin' : ''}`}
    onChange={handleSearch}
    placeholder={placeholder}
    value={value}
  />
);

SearchInTable.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string
};

SearchInTable.defaultProps = {
  handleSearch: () => null,
  isMobile: false,
  placeholder: 'Ingrese texto para buscar',
  defaultValue: ''
};

export default SearchInTable;

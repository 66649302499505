import React, { createRef, useState } from 'react';
import { Carousel, Button } from 'antd';
import { map } from 'lodash';
// Dependencia de estilos de librerías externas. Sería genial deprecarla.
import 'slick-carousel/slick/slick.less';
import 'slick-carousel/slick/slick-theme.less';
import '../../../static/styles/shared/intro/MLIntro/styles.less';

const MLIntro = (props) => {
  const { data, labelFinish = 'Finalizar', finish } = props;
  const [activePosition, setActivePosition] = useState(0);

  const onChange = (number) => {
    setActivePosition(number);
  };
  const ref = createRef();

  const next = (e) => {
    e.preventDefault();
    if (ref.current) {
      ref.current.next();
    }
  };
  const prev = (e) => {
    e.preventDefault();
    if (ref.current) {
      ref.current.prev();
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12  col-md-10 offset-md-1 text-center container-introduction ">
          <Carousel
            ref={ref}
            className=""
            afterChange={onChange}>
            {map(data, (item, i) => (
              <div
                className="card-introduction col-12"
                key={i}
              >
                <p className="title">{item.title}</p>

                {item.subTitle && (
                  <p className='sub-title'>{item.subTitle}</p>
                )}

                {item.description && (
                  <p>{item.description}</p>
                )}

                {item.child && (
                  <div className='card-introduction-children'>
                    {item.child}
                  </div>
                )}
              </div>
            ))}
          </Carousel>

          <div className="row">
            <div className="col-12">
              <Button
                onClick={ data[activePosition + 1] ? next : finish}
                type="primary">
                {
                  data[activePosition + 1] ? 'Continuar' : labelFinish
                }
              </Button>
            </div>

            {activePosition > 0
            && <div className="col-12">
              <p className="prev" onClick={prev}>Volver</p>
            </div>
            }
          </div>

        </div>
      </div>
    </div>
  );
};

export default MLIntro;

/* eslint-disable camelcase */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import IdleTimer from 'react-idle-timer';
import { logout } from '../../../redux/actions/user';
import { isProduction } from '../../../helpers/commons';
import * as storageUtil from '../../../helpers/cookiesUtil';

const Expiry = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const _onIdle = () => {
    const token = storageUtil.getItem('token');

    if (token && isProduction()) {
      dispatch(logout());
      client.resetStore();
    }
  };

  const idle = {};

  return (
    <IdleTimer
      ref={(ref) => { idle.Timer = ref; }}
      onIdle={_onIdle}
      debounce={250}
      timeout={1200000}
    />
  );
};

export default Expiry;

import React from 'react';
import MLModal from './MLModal';

import '../../static/styles/shared/ml-antd-modal-fullscreen.less';

const MLAntdMModalFullScreen = (props) => (
  <MLModal {...{ ...props, fullScreen: true }}/>
);

export default MLAntdMModalFullScreen;

import { useEffect, useState } from 'react';
import useSearchUserQuery from './useSearchUserQuery';

const useSearchUserResults = ({
  blockedFirstName,
  canRefetchQuery,
  firstNameInput,
  focused,
  hideResults,
  setRefetchTrigger,
  selectUser,
  setFirstName,
  setFocused,
  setHideResults
}) => {
  const searchUser = useSearchUserQuery();
  const [searchUserData, setSearchUserData] = useState();

  const onClose = (e) => {
    e.preventDefault();
    setRefetchTrigger(false);
    setFirstName(blockedFirstName);
    setFocused(false);
  };

  const onPick = (e, item) => {
    e.preventDefault();
    selectUser(item, false);
    setFocused(false);
  };

  useEffect(() => {
    if (canRefetchQuery) setRefetchTrigger(false);
  }, [firstNameInput.inputProps.value]);

  useEffect(() => {
    if (canRefetchQuery) {
      searchUser.callQuery({ params: { fullName: firstNameInput.inputProps.value } });
    }
  }, [canRefetchQuery]);

  useEffect(() => {
    if (focused && searchUser.loading) setHideResults(false);
  }, [searchUser.loading]);

  useEffect(() => {
    if (focused) setSearchUserData(searchUser.data);
  }, [searchUser.data]);

  return {
    hideResults,
    searchUser: {
      ...searchUser,
      data: searchUserData
    },
    setRefetchTrigger,
    onClose,
    onPick
  };
};

export default useSearchUserResults;

import { actionTypes } from '../actions/digitalFolder';

export const initialState = {
  files: [],
  loading: false,
  error: false,
  loaded: false
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.CLEAN_SEARCH:
    return {
      ...state,
      files: [],
      loading: false,
      error: false,
      loaded: false
    };
  case actionTypes.FIND_FILE:
    return {
      ...state,
      loading: true,
      error: false,
      loaded: false
    };
  case actionTypes.FIND_FILE_SUCCESS:
    return {
      ...state,
      loading: false,
      files: action.data,
      loaded: true
    };
  case actionTypes.FIND_FILE_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      loaded: true
    };
  default:
    return state;
  }
}

export default reducer;

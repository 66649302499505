import { find } from 'lodash';

export const getSettingValue = (value) => (value?.stringValue
|| value?.booleanValue
|| value?.intValue || null);

export const getValueKeySetting = (listSettings, key) => {
  const setting = find(listSettings, (item) => item.key === key);

  return getSettingValue(setting?.value);
};

import { getEnvVar } from './commons';
import { clearAll } from './cookiesUtil';

export default function () {
  const redirectURI = `${encodeURI(window.location.origin)}${getEnvVar(
    'PUBLIC_URL'
  )}`;
  clearAll();
  const logoutURL = `${getEnvVar(
    'REACT_APP_PINGFED_AUTH_URL'
  )}/idp/startSLO.ping`;
  window.location.href = `${logoutURL}?TargetResource=${redirectURI}&InErrorResource=${redirectURI}`;
}

/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_LIST_INTERESTS_AND_SKILLS = gql`
    query {
        listInterestAndSkills {
            academicTraining{
                haveCoursesOrTraining
                haveEnglishCertification
                updatedAt
                status
                _id
                rut
                levelsOfStudies
                postgraduateName
                collegeCarrers {
                    statusOfStudies
                    collegeCarrer {
                        _id
                        name
                        type
                    }
                }
                coursesOrTraining {
                    _id
                    name
                }
                englishCertification {
                    _id
                    name
                }
                oralEnglishLevel
                writtenEnglishLevel
                platforms {
                
                    platform {
                        _id
                        name
                    }
                    status
                    level
                }
            }
            dataWorkExperience{
                updatedAt
                rut
                yearsExperience
                lastPositions{
                    area{
                        _id
                        name
                    }
                    company
                    duration
                    period {
                      dateInit
                      dateFinish
                    }
                    dateFinished

                duration
                period{
                    dateInit
                    dateFinish
                }
                positionName
                company
                }
                ledTeamBefore
                ledTeams{
                ledTeamMax
                }
                urlLinks
                status
                previousWorkExperienceWalmart
            }
            dataInterestAreas{
                updatedAt
                status
                rut
                interestAreas{
                    area{
                        _id
                        name
                    }
                }
                updatedAt
            }
        }
    }
`;
export const GET_LIST_INTERESTS_AND_SKILLS_DATA_SHORT = gql`
    query {
        listInterestAndSkillsDataShort{
            oralEnglishLevel{
                name
                codeValue
            }
            writtenEnglishLevel{
                name
                codeValue
            }
            yearExperiences{
                name
                codeValue
            }
            platformLevels{
                name
                codeValue
            }
            personsLead{
                name
                codeValue
            }
            levelsOfStudies{
                name
                codeValue
            }
            statusOfStudies{
                name
                codeValue 
            }
        }
    }
`;

export const FIND_WORK_EXPERIENCE_AREA = gql`
    query listOptionsWorkExperienceArea($str: String, $dataComplete: Boolean) {
        listOptionsWorkExperienceArea(str: $str, dataComplete: $dataComplete) {
            _id
            name
        }
    }
`;

export const FIND_AREAS_DETAIL = gql`
    query listOptionsinterestAreasDetail($str: String, $dataComplete: Boolean) {
        listOptionsinterestAreasDetail(str: $str, dataComplete: $dataComplete) {
            _id
            name
        }
    }
`;

export const FIND_PLATFORM = gql`
    query listOptionsPlatforms($str: String, $dataComplete: Boolean) {
        listOptionsPlatforms(str: $str, dataComplete: $dataComplete) {
            _id
            name
        }
    }
`;

export const FIND_COLLEGE_CARRER = gql`
    query listOptionsCollegeCarrer($str: String, $dataComplete: Boolean) {
        listOptionsCollegeCarrer(str: $str, dataComplete: $dataComplete) {
            _id
            name
        }
    }
`;

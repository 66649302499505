import { actionTypes } from '../actions/podium';

export const initialProps = {
  podium: [],
  error: null,
  loading: false
};

function reducer(state = initialProps, action) {
  switch (action.type) {
  case actionTypes.ALL_PODIUM:
    return {
      ...state,
      loading: true
    };
  case actionTypes.ALL_PODIUM_SUCCESS:
    return {
      ...state,
      loading: false,
      podium: action.payload
    };
  case actionTypes.ALL_PODIUM_ERROR:
    return {
      ...state,
      loading: false,
      error: action.payload
    };
  default:
    return state;
  }
}
export default reducer;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../../../../static/styles/shared/MLHistorical/historical.less';
import { find, isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import HistoricalList from '../../../../shared/MLHistorical/list/index';
import MLHistorical from '../../../../shared/MLHistorical';
import getURLParams from '../../../../../helpers/routing/getURLParams';
import MLNotFound from '../../../../shared/MLNotFound';
import {
  GET_EDD,
  GET_PERFORMANCE_EVALUATION_HISTORICAL,
  GET_EDD_HISTORICAL
} from '../../../../../graphql/mievolucion/performanceEvaluation/queries';
import {
  parsedPerformanceEvaluation,
  parsePerformanceEvaluationHistorical
} from '../../../../../helpers/myEvolution/myPerformance/performanceEvaluation';
import EvaluationSelected from './EvaluationSelected';
import LoaderComponent from '../../../../Loader';
import { GET_SETTING } from '../../../../../graphql/settings/queries';

const clientName = 'myEvolution';
const ENABLE_EDD = '2,1';
const DISABLE_EDD = '2,0';
const msgsEDD = {
  NOT_ENABLE_EDD: 'No estás habilitado para el proceso de EDD 2023 dado que no cumples con los requisitos. para mayor información comunícate con el equipo de persona al correo gestiondeldesempeno@walmart.com',
  ENABLE_EDD_ERROR_CURRENT: 'No se creó tu evaluación 2023 dado que el evaluador o validador informado no se encuentra activo comunicate con gestiondeldesempeno@walmart.com.',
  ENABLE_EDD_ERROR_HISTORICAL: 'No se creó tu evaluación 2023 dado que el evaluador o validador informado no se encuentra activo comunicate con gestiondeldesempeno@walmart.com'
};

const Historical = (props) => {
  const { setMessageEDD } = props;
  const { pathname } = useLocation();
  const [evaluationSelected, setEvaluationSelected] = useState(null);

  const query = getURLParams();
  const year = query.get('year');
  const {
    evaluationType
  } = useSelector((state) => state.userReducer.data);
  const enableEDD = evaluationType === ENABLE_EDD;
  const disableEDD = evaluationType === DISABLE_EDD;
  const variables = {};

  const {
    loading: loadingCurrentEDD,
    data: dataCurrentEDD,
    error: errorCurrentEDD
  } = useQuery(GET_EDD, {
    context: { clientName },
    variables,
    fetchPolicy: 'network-only'
  });

  const {
    data: dataEDDHistorical,
    loading: loadingEDDHistorical,
    error: errorEDDHistorical,
    refetch: refetchEDDHistorical
  } = useQuery(GET_EDD_HISTORICAL, {
    context: { clientName },
    variables,
    fetchPolicy: 'network-only'
  });

  const {
    loading: loadingPerformanceYear,
    data: performanceYear
  } = useQuery(GET_SETTING,
    {
      context: { clientName },
      variables: { key: 'YEAR_EDD_ACTIVE' },
      fetchPolicy: 'network-only'
    });
  const { getSetting: { year: activePerformanceYear } = {} } = performanceYear || {};

  const { loading: loadingHistorical, data: dataHistorical } = useQuery(
    GET_PERFORMANCE_EVALUATION_HISTORICAL,
    {
      context: { clientName },
      variables,
      fetchPolicy: 'network-only'
    }
  );

  const { getHistoricalEDD: EDDHistorical = [] } = dataEDDHistorical || {};

  const parsedHistorical = parsePerformanceEvaluationHistorical(
    dataHistorical?.getPerformanceEvaluationHistorial
  );

  const EDDHNew = EDDHistorical?.map(
    (element) => parsedPerformanceEvaluation(element, activePerformanceYear)
  );

  let parsedData = parsedHistorical;
  if (EDDHistorical?.length) {
    parsedData = EDDHNew.concat(parsedData);
  }

  const onSelectItem = () => {
    if (!year) {
      setEvaluationSelected(null);
    } else {
      const newItem = find(
        parsedData,
        (item) => item.year.toString() === year.toString()
      );
      if (newItem) {
        setEvaluationSelected(newItem);
      }
    }
  };

  useEffect(() => {
    if (!loadingCurrentEDD
      && !loadingHistorical
      && !loadingEDDHistorical
      && year
    ) {
      onSelectItem(year);
    } else {
      onSelectItem(null);
    }
  }, [
    dataCurrentEDD,
    dataEDDHistorical,
    dataHistorical,
    loadingCurrentEDD,
    loadingEDDHistorical,
    loadingHistorical,
    year
  ]);

  const getURL = (item) => `${pathname}?year=${item?.year}`;

  const leftScrollComponents = (selected) => ([
    !loadingCurrentEDD
      && isEmpty(parsedData)
      && !loadingHistorical
      && isEmpty(parsedHistorical) && (
      <MLNotFound
        key={1}
        {...{
          img:
              'web/mi-evolucion/servicios/inicio/sin-evaluacion-de-desempeño.png',
          title: 'Sin evaluaciones',
          description: 'No posees ninguna evaluación'
        }}
      />
    ),
    !isEmpty(parsedData)
    && !loadingHistorical
    && !loadingCurrentEDD
    && (
      <HistoricalList
        key={2}
        loading={loadingCurrentEDD}
        data={parsedData}
        getActiveItem={(listItem) => selected && listItem.year === selected.year
        }
        getItemURL={(listItem) => getURL(listItem)}
      />
    )
  ]);

  if (loadingCurrentEDD
    || loadingHistorical
    || loadingEDDHistorical
    || loadingPerformanceYear) {
    return <LoaderComponent />;
  }

  const emptyEDDOrError = isEmpty(dataCurrentEDD) || errorCurrentEDD;

  if (disableEDD && !isEmpty(EDDHistorical) && emptyEDDOrError) {
    setMessageEDD(msgsEDD.NOT_ENABLE_EDD);
  }
  if (disableEDD && isEmpty(EDDHistorical) && emptyEDDOrError) {
    setMessageEDD(msgsEDD.NOT_ENABLE_EDD);
  }
  if (enableEDD && (isEmpty(EDDHistorical) || errorEDDHistorical) && emptyEDDOrError) {
    setMessageEDD(msgsEDD.ENABLE_EDD_ERROR_CURRENT);
  }
  if (enableEDD && !isEmpty(EDDHistorical) && emptyEDDOrError) {
    setMessageEDD(msgsEDD.ENABLE_EDD_ERROR_HISTORICAL);
  }

  return (
    <div>
      {(
        <MLHistorical leftScrollComponents={leftScrollComponents(evaluationSelected)}>
          {year
            && evaluationSelected
            && evaluationSelected?.year?.toString() === year.toString() && (
            <EvaluationSelected
              refetchQueries={[
                {
                  context: { clientName },
                  query: GET_EDD,
                  variables,
                  fetchPolicy: 'network-only'
                }
              ]}
              evaluationSelected={evaluationSelected}
              reload={() => refetchEDDHistorical(variables)}
            />
          )}
        </MLHistorical>
      )}
    </div>
  );
};

export default Historical;

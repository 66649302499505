import { actionTypes } from '../actions/evaluationsReport';

export const initialState = {
  loading: false,
  data: null,
  error: null
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_EVALUATIONS_REPORT:
    return {
      ...initialState,
      loading: true
    };
  case actionTypes.GET_EVALUATIONS_REPORT_SUCCESS:
    return {
      ...state,
      loading: false,
      data: action.data
    };
  case actionTypes.GET_EVALUATIONS_REPORT_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error
    };
  case actionTypes.CLEAN_EVALUATIONS_REPORT:
    return {
      ...initialState
    };
  default:
    return state;
  }
}

export default reducer;

import React from 'react';
import { Button } from 'antd';
import { isEmpty } from 'lodash';
import { capitalizeText } from '../commons';

const MESSAGE_MERIT_ERROR = 'La información de este colaborador no se encuentra disponible, comuniquese con su People Bussiness Partner o encargado de Personas mercado.';
const WITHOUT_INFORMATION = 'Sin información';

const STATUS_KEYS = {
  SENT: 'Enviada',
  PENDING: 'No Enviada',
  NOT_SENT: 'No Enviada',
  SEEN: 'Vista',
  SUSPENDED: 'Suspendida'
};

export const getStatusLabel = (status) => STATUS_KEYS[status] || status;

export const getLetterType = type => (isEmpty(type) ? WITHOUT_INFORMATION : capitalizeText(`Carta ${type}`));

export const renderBtn = (method, loadingInfo, text, record) => {
  if (record.error) return null;
  switch (record.notElegible) {
  case true:
    return <span>-</span>;
  default:
    return (
      <Button
        type='primary'
        className='px-1'
        disabled={record.status === STATUS_KEYS.SENT}
        loading={
          loadingInfo.sendMutationLoader
            && loadingInfo.selected.id === record.key
        }
        onClick={() => method(record.key, capitalizeText(record.name))}
      >
          Enviar carta
      </Button>
    );
  }
};

export const renderAnchor = (method, text, record) => {
  if (record.error) return null;
  switch (record.notElegible) {
  case true:
    return <span>-</span>;
  default:
    return (
      <a
        href='#'
        target='_blank'
        onClick={(e) => {
          e.preventDefault();
          method(record.key, record.name);
        }}
      >
          Ver carta
      </a>
    );
  }
};

export const rendersent = (text, record) => {
  if (record.error) return null;
  switch (record.notElegible) {
  case true:
    return <span>-</span>;
  default:
    return (
      <span className={`${record.status === 'SENT' ? 'sent' : 'pending'}`}>
        {getStatusLabel(record.status)}
      </span>
    );
  }
};

const renderUserFullname = (text, record, openModal) => (
  <span>
    <span>{text}</span>
    {record.error && (
      <i
        onClick={() => openModal({
          message: MESSAGE_MERIT_ERROR,
          icon: 'icon-ml-warning pending-icon-color',
          title:
              'La información de este colaborador no se encuentra disponible'
        })
        }
        className='icon-ml-warning pending-icon-color ml-1'
      />
    )}
  </span>
);

const renderType = (openModal, text, record) => {
  switch (record.notElegible) {
  case true:
    return (
      <span className='d-flex align-items-center'>
          No es elegible
        <Button
          type='link'
          className='info-icon'
          role='presentation'
          rel='noopener noreferrer'
          onClick={() => openModal({ message: record.notElegiblePhrase })}
        >
          <i className='icon-ml-information blue-icon-color ml-1' />
        </Button>
      </span>
    );
  default:
    return <span className='d-flex align-items-center'>{text}</span>;
  }
};

const renderIncrement = (text, record) => {
  switch (record.notElegible) {
  case true:
    return <span>-</span>;
  default:
    return <span>{text}</span>;
  }
};

const renderEvaluation = (text, record) => {
  switch (record.notElegible) {
  case true:
    return <span>-</span>;
  default:
    return <span>{text}</span>;
  }
};

export const getMyTeamTableSrcData = (
  { bossLetterList },
  methods,
  loadingInfo
) => {
  const { letters } = bossLetterList;
  const { sendLetter, openModal, generateLetter } = methods;
  const respTable = letters.map((item) => ({
    name: capitalizeText(item?.fullName || WITHOUT_INFORMATION),
    type: getLetterType(item?.data?.letterCode),
    increment: item?.data?.incrementPercentage
      ? `${item?.data?.incrementPercentage}%`
      : WITHOUT_INFORMATION,
    evaluation: item?.data?.evaluationCategory || WITHOUT_INFORMATION,
    view: 'Ver carta',
    status: getStatusLabel(item.status),
    file: item.file,
    key: item?._id,
    notElegible: !!item.data?.notElegiblePhrase,
    notElegiblePhrase: item?.data?.notElegiblePhrase,
    error: !!item.error
  }));
  return {
    columns: [
      {
        title: 'Colaborador',
        dataIndex: 'name',
        key: 'name',
        className: 'table-td',
        render: (text, record) => renderUserFullname(text, record, openModal)
      },
      {
        title: 'Tipo',
        dataIndex: 'type',
        key: 'type',
        className: 'table-td',
        render: (text, record) => renderType(openModal, text, record)
      },
      {
        title: 'Incremento',
        dataIndex: 'increment',
        key: 'increment',
        className: 'table-td',
        render: renderIncrement
      },
      {
        title: 'Desempeño',
        dataIndex: 'evaluation',
        key: 'evaluation',
        className: 'table-td',
        render: renderEvaluation
      },
      {
        title: 'Visualizar',
        dataIndex: 'view',
        key: 'view',
        className: 'table-td',
        render: (text, record) => renderAnchor(generateLetter, text, record)
      },
      {
        title: 'Estado',
        dataIndex: 'state',
        key: 'state',
        className: 'table-td',
        render: rendersent
      },
      {
        title: 'Envio',
        dataIndex: 'status',
        key: 'status',
        className: 'table-td send-btn',
        render: (text, record) => renderBtn(sendLetter, loadingInfo, text, record)
      }
    ],
    srcData: respTable
  };
};

export const getInfoMessage = (letter) => {
  if (letter.error) {
    return { props: { color: 'warning' }, message: MESSAGE_MERIT_ERROR };
  }
  return {
    message: letter?.data?.notElegiblePhrase
      ? letter.data.notElegiblePhrase
      : ''
  };
};

export const getMyTeamListSrcData = (
  { bossLetterList },
  methods,
  loadingInfo
) => {
  const { letters } = bossLetterList;

  const { sendLetter, generateLetter } = methods;
  const respCards = letters.map((item) => ({
    name: capitalizeText(item?.fullName),
    infoMessage: getInfoMessage(item),
    fields: [
      {
        field: 'Tipo',
        value: getLetterType(item?.data?.letterCode)
      },
      {
        field: 'Incremento',
        value: item?.data?.incrementPercentage
          ? `${item?.data?.incrementPercentage}%`
          : WITHOUT_INFORMATION
      },
      {
        field: 'Desempeño',
        value: item?.data?.evaluationCategory || WITHOUT_INFORMATION
      },
      item.error
        ? {}
        : {
          onClick: (e) => {
            e.preventDefault();
            if (item?._id) {
              generateLetter(item?._id);
            }
            return false;
          },
          field: 'Visualizar',
          value: 'Ver carta'
        },
      item.error
        ? {}
        : {
          field: 'Estado',
          value: getStatusLabel(item.status),
          sent: getStatusLabel(item.status === 'SENT')
        }
    ],
    buttons: item.error
      ? []
      : [
        {
          onClick: () => sendLetter(item._id, capitalizeText(item.data.names)),
          text: 'Enviar carta',
          disabled: item.status === 'SENT',
          loading:
              loadingInfo.sendMutationLoader
              && loadingInfo?.selected?.id === item._id
        }
      ]
  }));
  return respCards;
};

import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_MY_SKILLS_4C } from '../../../graphql/mievolucion/skills/queries';
import LoaderComponent from '../../Loader';
import ErrorMessage from '../../shared/ErrorMessage';
import BehaviorContent, { SkillDetail } from './MyBehaviorContent';
import { useWindowWidthSize } from '../../../helpers/browser';
import { smallDevice } from '../../../helpers/deviceVariables';
import BehaviorContentAccordion from './BehaviorContentAccordion';
import UserProfile from './Profile';
import '../../../static/styles/mievolucion/skills4c/model.less';

const MyBehaviors = () => {
  const width = useWindowWidthSize();
  const isMobile = width <= smallDevice;
  const {
    data: skillsData,
    loading: skillsLoading,
    error
  } = useQuery(
    GET_MY_SKILLS_4C, {
      context: {
        clientName: 'myEvolution'
      },

      fetchPolicy: 'network-only'
    }
  );

  const { listMySkillsBehaviors4C = [] } = skillsData || {};

  const makeSkillStructureAccordion = (skills, columns = 1) => (
    skills.map((skill, index) => ({
      key: `skill-${skill.shortName}`,
      title: skill.shortName,
      children: <SkillDetail key={index} columns={columns} skill={skill} />
    }))
  );

  if (skillsLoading) return <LoaderComponent />;
  if (skillsData) {
    return (
      !isMobile
        ? <BehaviorContent skills={listMySkillsBehaviors4C} />
        : (
          <>
            <div className='ml-3 mr-3'>
              <UserProfile className='mt-4 mb-2 d-block' title='Mi Rol' isMobile={true} />
            </div>
            <BehaviorContentAccordion
              behaviors={makeSkillStructureAccordion(listMySkillsBehaviors4C)}
              className='accordion-skills-4c'
            />
          </>
        )
    );
  }
  if (error) {
    return (<ErrorMessage
      message={error.message || 'Ocurrió un error por favor intenta de nuevo'}
    />);
  }
  return null;
};

export default MyBehaviors;

export const actionTypes = {
  GET_NUESTRA_COMPANIA_CONTENT: 'GET_NUESTRA_COMPANIA_CONTENT',
  GET_NUESTRA_COMPANIA_CONTENT_SUCCESS: 'GET_NUESTRA_COMPANIA_CONTENT_SUCCESS',
  GET_NUESTRA_COMPANIA_CONTENT_FAILURE: 'GET_NUESTRA_COMPANIA_CONTENT_FAILURE'
};

export function getContent() {
  return {
    type: actionTypes.GET_NUESTRA_COMPANIA_CONTENT
  };
}

export function getContentSuccess(data) {
  return {
    type: actionTypes.GET_NUESTRA_COMPANIA_CONTENT_SUCCESS,
    data
  };
}

export function failure(error) {
  return {
    type: actionTypes.GET_NUESTRA_COMPANIA_CONTENT_FAILURE,
    error
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import '../../static/styles/help/main.less';

const AddFile = ({
  message,
  isSelected,
  handleCreateTicket,
  handleFileSelected,
  handleRemoveSelectedFile,
  nameFile,
  inputId,
  disabled
}) => {
  const inputClass = disabled && 'help-inputFile-class-disabled';
  return (
    <div className='help-ticket-description'>
      <div className={`ml-textarea help-add-file-block ${inputClass}`}>
        <p className='help-ticket-comment-add-file-label'>
          {message}
        </p>
        {!isSelected
          ? (
            <>
              <label
                className='add-file-input'
                onChange={handleCreateTicket}
              >
              Haz clic acá para buscar en tu computador
                <input
                  type='file'
                  id={inputId}
                  name={inputId}
                  style={{
                    overflow: 'hidden',
                    position: 'absolute',
                    zIndex: -1
                  }}
                  accept='.doc,.docx,.jpg,.jpeg,.png,.pdf,.xls,.xlsx,.gif,.ppt,.pptx,.png,'
                  onChange={handleFileSelected}
                  disabled={disabled}
                />
              </label>
              <p className='add-file-extensions'>DOCX, JPG, PNG, PDF, PPT, PPTX, XLS, XLSX, GIF. Máximo 5MB.</p>
            </>
          )
          : (
            <>
              <label
                className='text-primary-mio d-flex'
                onClick={handleRemoveSelectedFile}
              >
                <p className='add-file-extensions'>{nameFile}</p>
                <i className='fa fa-times-circle cross-circle-remove-file' />
              </label>
            </>
          )}
      </div>
    </div>);
};

AddFile.propTypes = {
  isSelected: PropTypes.bool,
  handleCreateTicket: PropTypes.func,
  handleFileSelected: PropTypes.func,
  handleRemoveSelectedFile: PropTypes.func,
  nameFile: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  message: PropTypes.string
};

AddFile.defaultProps = {
  isSelected: false,
  handleCreateTicket: () => null,
  handleFileSelected: () => null,
  handleRemoveSelectedFile: () => null,
  nameFile: '',
  inputId: 'addTicketFile',
  message: 'Adjuntar archivo'
};

export default AddFile;

import { setContext } from '@apollo/client/link/context';
import * as storageUtil from '../../helpers/cookiesUtil';

const authLink = setContext((operation, context) => {
  const token = storageUtil.getItem('token');

  return {
    headers: {
      ...context?.headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

export default authLink;

const getUploadOptions = () => ([
  {
    label: 'Buscar colaboradores uno a uno',
    value: 0
  },
  {
    label: 'Subir archivo con colaboradores',
    value: 1
  }
]);

export default getUploadOptions;

import React from 'react';
import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import getClassName from '../../../helpers/strings/getClassName';
import '../../../static/styles/mievolucion/skills4c/accordion.less';

const { Panel } = Collapse;

export const MlArrow = () => (
  <i data-testid='ml-arrow' className="icon-ml-arrow" />
);

const buttonClass = ['first-skill', 'second-skill', 'thrid-skill', 'fourth-skill'];

const BehaviorContentAccordion = ({
  behaviors, defaultActiveKey, bordered, onChange, className
}) => (
  <Collapse
    key={uuidv4()}
    defaultActiveKey={defaultActiveKey}
    onChange={onChange}
    className={getClassName('info-collapse', className)}
    bordered={bordered}
  >
    {behaviors.map(({
      key, title, extra, children, showArrow = false, forceRender = true
    }, index) => (
      <Panel
        header={<div className="info-collapse-title">{title}</div>}
        key={key}
        extra={extra || <MlArrow />}
        showArrow={showArrow}
        forceRender={forceRender}
        className={ `collapse-title ${buttonClass[index]}` }
      >
        {children}
      </Panel>
    ))}
  </Collapse>
);

BehaviorContentAccordion.propTypes = {
  behaviors: PropTypes.array,
  defaultActiveKey: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
  bordered: PropTypes.bool
};

export default BehaviorContentAccordion;

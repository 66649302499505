import { lazy } from 'react';

const Authenticate = lazy(() => import('../../containers/authenticate'));
const Logout = lazy(() => import('../../containers/authenticate/logout'));
const PWAInstall = lazy(() => import('../../containers/pwa-install'));
const Readiness = lazy(() => import('../../containers/readiness'));
const MaintenanceFreeze = lazy(() => import('../../containers/MaintenanceFreeze'));

export default [
  {
    isPublic: true, exact: true, path: '/instalar-pwa', component: PWAInstall
  },
  {
    isPublic: true, exact: true, path: '/readiness', component: Readiness
  },
  {
    isPublic: true, exact: true, path: '/authenticate', component: Authenticate
  },
  {
    isPublic: true, exact: true, path: '/logout', component: Logout
  },
  {
    isPublic: true, exact: true, path: '/maintenance-freeze', component: MaintenanceFreeze
  }
];

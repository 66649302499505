import React from 'react';
import { map, find, orderBy } from 'lodash';
import { useQuery } from '@apollo/client';
import Loading from '../../../../containers/loading';
import InfoMessage from '../../../shared/InfoMessage';
import Select from '../../../shared/forms/Select';
import { GET_SKILL_LEVEL_BEHAVIOR } from '../../../../graphql/mievolucion/evolucionaTEST/queries';

const ChooseSkill = (props) => {
  const {
    setModalOptions,
    listSkills, level, ButtonNext, skillSelected,
    setSkillSelected, userLevel, setBehaviors, listPendingPlans
  } = props;

  const { loading, data } = useQuery(
    GET_SKILL_LEVEL_BEHAVIOR,

    {
      variables: { levelId: userLevel },
      context: { clientName: 'myEvolution' }
    }
  );
  const { listSkillLevelBehavior = [] } = data || {};

  const filterSkills = listSkills.filter((item) => item.name === 'Liderazgo colaborativo') || [];
  const newSkills = map(filterSkills, (skill) => ({ value: skill._id, label: skill.name }));

  const handleChange = (value) => {
    const searchBehavior = listSkillLevelBehavior.filter((item) => item.skillId === value) || [];
    const newSkillSelected = find(listSkills, (skill) => skill._id === value);
    const hasPlanInProcess = !!find(
      listPendingPlans,
      ({ skill }) => skill._id === value
    );

    if (hasPlanInProcess) {
      setModalOptions(
        {
          icon: 'icon-ml-information primary-icon-color',
          visible: true,
          message: 'Para volver a contestar tu TEST, debes haber finalizado todas las acciones de tu Plan de Evolución en proceso.',
          title: 'Tienes acciones pendientes por finalizar',
          type: 'success'
        }
      );
    } else {
      const newBehaviors = searchBehavior.map((behavior) => ({ ...behavior, choice: undefined }));
      setBehaviors(orderBy(newBehaviors, ['id'], ['asc']));
      setSkillSelected(newSkillSelected);
    }
  };
  if (loading) return <Loading />;

  return (
    <div className="container-evolucionaTest">
      <div className='container-choose-skill '>
        <InfoMessage
          message='Elige una competencia para realizar tu autoevaluación.'
        />
        <div>
          <p className='text-title'>Mi Nivel</p>
          <p className='text-value'>{level}</p>
        </div>
        <div>
          <p className='text-title'>Compentencia a evaluar</p>
          <Select
            placeholder='Seleccionar competencia'
            onSelect={handleChange}
            options={newSkills} />
        </div>
        {
          skillSelected
          && <div className="skill-wrapper py-3 px-2 px-xl-5 ">
            <h6 className="text-center mt-1">{skillSelected.name}</h6>
            <p className="mb-1 pt-1">{skillSelected.description}</p>
          </div>
        }
        <ButtonNext disabled={!skillSelected} />
      </div>
    </div>
  );
};

export default ChooseSkill;

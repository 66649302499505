import { Tooltip } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import InfoMessage from '../../shared/InfoMessage';
import '../../../static/styles/shared/Profile/profileHeader.less';
import '../../../static/styles/mievolucion/skills4c/user-profile.less';
import '../../../static/styles/mievolucion/skills4c/tooltip.less';

const UserProfile = ({
  className, title, isMobile
}) => {
  const { data: userData } = useSelector((state) => state.userReducer);
  const { nameUCM } = userData || {};

  const listHeader = [{
    label: 'Mi Tipo de Rol',
    value: nameUCM || 'Sin rol'
  }];

  const message = 'En la sección Mis Conductas podrás visualizar las conductas asociadas a tu rol para cada competencia y aquellas que invitan a desarrollarte para lograr ser la mejor versión de ti mismo.';
  return (
    <>
      <div className={`profile-header profile-header-white user-profile-skills-4c ${className}`}>
        <div className='d-flex flex-column flex-sm-row w-100 container-title-principal'>
          <div>
            <h3>{title}</h3>
          </div>
          {map(listHeader, (header, i) => (
            <div key={i} className='item-profile item-profile-skills-4c'>
              <span className='title'>{header.label}</span>
              <div className='item-profile-content'>
                <span className='text-value'>{header.value}&nbsp;</span>
                <Tooltip overlayClassName='tooltip-skills-4c' trigger='click' placement='right' title='Tu tipo de rol depende de las funciones y el nivel de responsabilidades que ejerces en tu día a día'>
                  <i className='icon-ml-question tooltip-info' />
                </Tooltip>
              </div>
            </div>
          ))}

          {
            isMobile && <InfoMessage
              key={1}
              color='info-message-suave'
              classes='w-100'
              suave={true}
              message={message} />
          }
        </div>
      </div>
      {
        !isMobile && <InfoMessage
          key={1}
          color='info-message-suave'
          classes='mb-3 w-100'
          suave={true}
          message={message} />
      }
    </>

  );
};

UserProfile.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  isMobile: PropTypes.bool
};

UserProfile.defaultProps = {
  title: 'Mi Perfil',
  className: '',
  isMobile: false
};

export default UserProfile;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import getVisibleAPI from '../api/visible';
import { actionTypes, getVisibleSuccess } from '../actions/visible';
import {
  failure, loading, loadComplete
} from '../actions/commons';
import * as storageUtil from '../../helpers/cookiesUtil';

es6promise.polyfill();

function* visible(data) {
  try {
    yield put(loading());
    const token = storageUtil.getItem('token');
    const response = yield call(getVisibleAPI, { data, token });
    yield put(getVisibleSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* visibleSagas() {
  yield all([
    takeLatest(actionTypes.GET_VISIBLE, visible)
  ]);
}

export default visibleSagas;

export const actionTypes = {
  GET_SALARY: 'GET_SALARY',
  GET_SALARY_SUCCESS: 'GET_SALARY_SUCCESS',
  GET_HISTORICAL_BONUS: 'GET_HISTORICAL_BONUS',
  GET_HISTORICAL_BONUS_SUCCESS: 'GET_HISTORICAL_BONUS_SUCCESS',
  LOADING_COMPENSATIONS: 'LOADING_COMPENSATIONS',
  LOADING_COMPENSATIONS_DONE: 'LOADING_COMPENSATIONS_DONE',
  COMPENSATIONS_ERROR: 'COMPENSATIONS_ERROR',
  COMPENSATIONS_RESET_DATA: 'COMPENSATIONS_RESET_DATA'
};
export function getSalary() {
  return {
    type: actionTypes.GET_SALARY
  };
}
export function getSalarySuccess(data) {
  return {
    type: actionTypes.GET_SALARY_SUCCESS,
    data
  };
}
export function getHistoricalBonus() {
  return {
    type: actionTypes.GET_HISTORICAL_BONUS
  };
}
export function getHistoricalBonusSuccess(data) {
  return {
    type: actionTypes.GET_HISTORICAL_BONUS_SUCCESS,
    data
  };
}
export function compensationsError() {
  return {
    type: actionTypes.COMPENSATIONS_ERROR
  };
}
export function resetDataCompensations() {
  return {
    type: actionTypes.COMPENSATIONS_RESET_DATA
  };
}

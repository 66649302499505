import React from 'react';
import AppRouterContextProvider from './context/AppRouterContext';
import AppRouter from './components/Router';

const AppRouterContainer = () => (
  <AppRouterContextProvider>
    <AppRouter/>
  </AppRouterContextProvider>
);

export default AppRouterContainer;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import usePerformance from './usePerformance';
import usePerformancePillars from './usePerformancePillars';
import useWeightsPercentages from './useWeightsPercentages';
import getComponentsPermissions from '../../../helpers/myEvolution/myPerformance/getComponentsPermissions';
import { fullCapitalizeFormat, humanize } from '../../../helpers/strings';
import validateObjectives from '../../../helpers/myEvolution/myPerformance/validateObjectives';
import getEmptyStateProps from '../../../helpers/myEvolution/myPerformance/getEmptyStateProps';

const useTableComponent = (user) => {
  const [bossName, setBossName] = useState(null);
  const {
    linealBoss,
    permissions: {
      MI_EQUIPO_MI_EVOLUCION_DESEMPEÑO_SUBORDINADOS_CON_PULSOS:
        linearBossWithObjectives

    }

  } = useSelector((state) => state.userReducer.data);

  const myPerformance = usePerformance();

  const bossBoss = myPerformance.data?.boss || linealBoss;

  const bossParams = user ? {
    selectedUser: {
      ...user,
      name: fullCapitalizeFormat(user.name),
      fatherLastName: fullCapitalizeFormat(user.fatherLastName)
    },
    bossBoss: bossBoss && { ...bossBoss, name: fullCapitalizeFormat(bossBoss.name) }
  } : null;

  const performancePillars = usePerformancePillars(bossParams);
  const {
    pillarsWeights,
    totalWeights
  } = useWeightsPercentages(myPerformance.data);
  const can = getComponentsPermissions({
    myPerformance,
    bossParams,
    linearBossWithObjectives
  });

  const emptyStateProps = getEmptyStateProps(can, bossParams);
  const objectivesError = validateObjectives(myPerformance.data, totalWeights);

  useEffect(() => {
    (async () => {
      try {
        const sameUserSelected = user && myPerformance.data?.myPerformance?.rut === user.rut;

        if (myPerformance.loading || sameUserSelected) return true;
        const variables = { rut: bossParams?.selectedUser?.rut || '' };

        await myPerformance.callApi({ variables });
      } catch {
        return false;
      }

      return true;
    })();
  }, [user]);

  useEffect(() => {
    if (myPerformance.data) {
      const humanizedBossName = humanize(myPerformance.data.boss.name);
      if (myPerformance.data.boss.rut !== undefined) {
        setBossName(humanizedBossName);
      }
    }
  }, [myPerformance.data]);

  return {
    can,
    bossParams,
    myPerformance,
    performancePillars,
    bossName,
    pillarsWeights,
    totalWeights,
    objectivesError,
    emptyStateProps
  };
};

export default useTableComponent;

import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

export const getPillarAPI = (item) => {
  const slug = item.item.name;
  return axios().get(`${BFF_ENDPOINT}/vivo-saludable/${slug}`).then((resp) => resp.data);
};

export const getPillarsAPI = () => axios().get(`${BFF_ENDPOINT}/vivo-saludable/list/all`).then((resp) => resp.data);

export const getFlickrAlbumAPI = ({ albums }) => axios().post(`${BFF_ENDPOINT}/vivo-saludable/flickr`, { albums }).then((resp) => resp.data);

export const getSocialWorkersApi = () => axios().get(`${BFF_ENDPOINT}/vivo-saludable/socialWorkers/all`).then((resp) => resp.data);

export const postRecipeApi = ({ recipe }) => axios().post(`${BFF_ENDPOINT}/vivo-saludable/cuerpo-sano/recipe`, { recipe }).then((resp) => resp.data);

import gql from 'graphql-tag';

import CalibrationsPage from './fragments';

export const CREATE_CALIBRATION = gql`
    mutation createCalibration(
        $groupName: String!
        $createdBy: String!
        $members: [String]!
        $calibrationMeeting: Date!
    ) {
       createCalibration(
            groupName: $groupName
            createdBy: $createdBy
            members: $members
            calibrationMeeting: $calibrationMeeting
        ) {
            ...CalibrationsPageMain
        }
    }
    ${CalibrationsPage.fragments.main}
`;

export const UPDATE_CALIBRATION = gql`
    mutation updateCalibration(
        $_id: String!
        $status: EvaluationCalibrationStatus!
        $calibrations: [updateCalibInput]
        $add: [String],
        $drop: [String],
        $groupName: String,
        $calibrationMeeting: Date
    ) {
        updateCalibration(
            _id: $_id
            status: $status
            calibrations: $calibrations
            add: $add
            drop: $drop
            groupName: $groupName,
            calibrationMeeting: $calibrationMeeting, 
        ) {
            ...CalibrationsPageMain
        }
    }
    ${CalibrationsPage.fragments.main}
`;

export const UPDATE_EDP_CALIBRATION = gql`
     mutation updatePotentialCalibration(
        $_id: String!
        $status: EvaluationCalibrationStatus!
        $calibrations: [EvaluationCalibrationItemRequestEDP]
    ) {
        updatePotentialCalibration(
            _id: $_id
            status: $status
            calibrations: $calibrations
        ) {
            _id
            createdBy # early was a object
            groupName
            calibrationMeeting
            skillsEvaluated { # skills
            _id
            order
            name
            }
            status
            calibrations {
            _id
            evaluationId {
                _id
                evaluated {
                _id
                rut
                name
                fatherLastName
                motherLastName
                position
                }
            }
            potentialEvaluationId
            potentialSkillResultId
            finalScore {
                evaluation
                skills
                objectives
            }
            finalCategories {
                evaluation { # finalCategory
                _id
                score
                }
                skills { # finalSkillCategory
                _id
                score
                }
                objectives { # finalObjectiveCategory
                _id
                score
                }
            }
            skills { # evolutionSkills and inside has skills object
                _id
                order
                categorySelected { # performanceCategory
                _id
                score
                }
            }
            }
            edpResultsList {
            _id
            name
            order
            }
        }
    }
`;

export const DELETE_CALIBRATION = gql`
    mutation deleteCalibration($_id: String!) {
        deleteCalibration(
            _id: $_id
        ) {
            success
            code
        }
    }
`;

export const UPLOAD_USERS_TO_CALIBRATE = gql`
    mutation($somefile:Upload!){
        uploadUsersToCalibrate(input: {file: $somefile}){
            data
            message
            code
            order
            user {
                fatherLastName
                motherLastName
                name
                position
                rut
                _id
            }
        }
    }
`;

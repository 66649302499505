/* eslint-disable import/prefer-default-export */

import { gql } from '@apollo/client';

export const SAVE_FEEDBACK = gql`
    mutation saveFeedback(
        $subject: String!
        $situation: String
        $feedbackType: FeedbackType!
        $status: FeedbackStatus!
        $destinedTo: [String]!
        $comments: [FeedbackCommentsInput]
        $type: SubjectType!
    ) {
        saveFeedback(
            feedback: {
                subject: $subject
                situation: $situation
                feedbackType: $feedbackType
                status: $status
                destinedTo: $destinedTo
                comments: $comments
                type: $type
            }
        ) {
            title
        }
    }
`;

export const SET_NOT_FEEDBACK_REASON = gql`
    mutation setNotFeedbackReason($reason: NotFeedbackReasonInput!) {
        setNotFeedbackReason(reason: $reason)
    }
`;

export const RESPOND_FEEDBACK = gql`
    mutation respondFeedbackComment(
        $responses: [FeedbackCommentsResponseInput]!
    ) {
        respondFeedbackComment(responses: $responses) {
            title
        }
    }
`;

export const DELETE_FEEDBACK = gql`
    mutation deleteFeedbackComment($feedbackID: String!, $fromUser: String!) {
        deleteFeedbackComment(feedbackID: $feedbackID, fromUser: $fromUser) {
            title
            description
        }
    }
`;

import { useEffect, useState } from 'react';
import { SPECIFIC_OBJECTIVE_KEY, TRANSVERSAL_OBJECTIVE_KEY, pillarName } from '../../../helpers/myEvolution/myPerformance';

const useWeightsPercentages = (dataPerformanceWalmart) => {
  const [transversalPercentage, setTransversalPercentage] = useState(0);
  const [specificPercentage, setSpecificPercentage] = useState(0);
  const objectivesArray = dataPerformanceWalmart?.objectives?.data;
  let sumTransversalPercentage = 0;
  let sumSpecificPercentage = 0;
  if (objectivesArray?.length > 0) {
    objectivesArray.forEach((d) => {
      if (d?.objective?.pillar?.name?.toLowerCase()
      === pillarName[TRANSVERSAL_OBJECTIVE_KEY]?.toLowerCase()) {
        sumTransversalPercentage += d.weight;
      } else {
        sumSpecificPercentage += d.weight;
      }
      return null;
    });
  }

  const getPillarsPercentage = async () => setTransversalPercentage(sumTransversalPercentage);
  const getSpecificPercentage = async () => setSpecificPercentage(sumSpecificPercentage);
  const pillarsWeights = [
    {
      dotColor: 'purple',
      title: `Objetivos ${pillarName[TRANSVERSAL_OBJECTIVE_KEY]}`,
      value: `${transversalPercentage}%`
    },
    {
      dotColor: 'green',
      title: `Objetivos ${pillarName[SPECIFIC_OBJECTIVE_KEY]}`,
      value: `${specificPercentage}%`
    }
  ];

  const totalWeights = [{
    title: 'Ponderación total de objetivos',
    value: `${transversalPercentage + specificPercentage}%`,
    valueBold: true
  }];

  useEffect(() => {
    if (dataPerformanceWalmart) {
      (async () => {
        await getPillarsPercentage();
        await getSpecificPercentage();
      })();
    }
  }, [dataPerformanceWalmart]);

  return {
    pillarsWeights,
    totalWeights
  };
};

export default useWeightsPercentages;

import { lazy } from 'react';

const Home = lazy(() => import('../../../../containers/myTeam/myEvolution/Skills4C'));

export default [
  {
    path: '/mi-equipo/mi-evolucion/competencias-4C',
    section: 'Mi Equipo - Mi Evolución - Competencias 4C',
    component: Home,
    auth: 'MY_TEAM_MY_EVOLUTION_SKILLS_4C',
    subRoutes: [
      {
        exact: true,
        path: '/mi-equipo/mi-evolucion/competencias-4C',
        section: 'Mi Equipo - Mi Evolución - Competencias 4C - Perfil UCM'
      }
    ]

  }
];

import { map } from 'lodash';

export const parsedDataGeneralModel = (generalModel4C) => {
  const res = [];

  if (!generalModel4C) return res;

  map(generalModel4C, (item) => {
    const {
      ucm, name, skills
    } = item;
    res.push({
      ucm,
      title: name,
      skills,
      showAvatar: false,
      titleMainColor: true
    });
  });

  return res;
};

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import {
  actionTypes,
  getValidationSuccess,
  getValidationError,
  loading,
  getAnticipoSuccess,
  getAnticipoSueldoError,
  getAnticipoHistorialSuccess,
  getAnticipoHistorialError,
  loadingHistorial,
  requestError,
  postAnticipoSuccess,
  putAnticipoSuccess,
  deleteAnticipoSuccess,
  changeModalAnticipo
} from '../actions/anticipoSueldo';

import {
  getValidation,
  getAPIAnticipoInformation,
  getAPIAnticipoHistorial,
  postAPIAnticipoSueldo,
  putAPIAnticipoSueldo,
  deleteAPIAnticipoSueldo
} from '../api/anticipoSueldo';

es6promise.polyfill();

const HTTP_BAD_REQUEST_CODE = 400;

function* getAnticipoValidation() {
  try {
    const response = yield call(getValidation);
    yield put(getValidationSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getValidationError(error));
  }
}

function* getAnticipo() {
  try {
    yield put(loading());
    const response = yield call(getAPIAnticipoInformation);
    yield put(getAnticipoSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const title = 'No se pudo cargar esta sección';
    const message = 'Estamos trabajando en solucionarlo, por favor intenta enviarlo de nuevo más tarde.';
    yield put(getAnticipoSueldoError({ error, title, message }));
  }
}

function* getAnticipoHistorial() {
  try {
    yield put(loadingHistorial());
    const response = yield call(getAPIAnticipoHistorial);
    yield put(getAnticipoHistorialSuccess(response.historial));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getAnticipoHistorialError(error));
  }
}

function* postAnticipo(action) {
  try {
    yield put(loading());
    yield call(postAPIAnticipoSueldo, { amount: action?.data?.anticipoAmount });
    yield put(postAnticipoSuccess({ amount: action?.data?.anticipoAmount }));
  } catch (err) {
    const error = get(err, 'response.data');
    const status = get(err, 'response.status');
    if (status === HTTP_BAD_REQUEST_CODE) {
      yield put(requestError({ requestMessage: error.error || error.message }));
    } else {
      yield put(
        requestError({
          requestMessage:
            '¡Lo sentimos! No se puede enviar tu solicitud, acércate a tu encargado de personas.'
        })
      );
    }
  }
}

function* putAnticipo(action) {
  try {
    yield put(loading());
    yield call(putAPIAnticipoSueldo, { amount: action?.data?.anticipoAmount });
    yield put(putAnticipoSuccess());
  } catch (err) {
    const error = get(err, 'response.data');
    const status = get(err, 'response.status');
    if (status === HTTP_BAD_REQUEST_CODE) {
      yield put(requestError({ requestMessage: error.error || error.message }));
    } else {
      yield put(
        requestError({
          requestMessage:
            '¡Lo sentimos! No se puede enviar tu solicitud, acércate a tu encargado de personas.'
        })
      );
    }
  }
}

function* deleteAnticipo() {
  try {
    yield put(loading());
    yield call(deleteAPIAnticipoSueldo);
    yield put(deleteAnticipoSuccess());
  } catch (err) {
    const error = get(err, 'response.data');
    const status = get(err, 'response.status');
    if (status === HTTP_BAD_REQUEST_CODE) {
      yield put(requestError({ requestMessage: error.error || error.message }));
    } else {
      yield put(
        requestError({
          requestMessage:
            '¡Lo sentimos! No se puede enviar tu solicitud, acércate a tu encargado de personas.'
        })
      );
    }
  }
}

function* _showModalAnticipo(action) {
  yield put(changeModalAnticipo(action.data));
}

function* anticipoSueldoSagas() {
  yield all([
    takeLatest(actionTypes.GET_VALIDATION, getAnticipoValidation),
    takeLatest(actionTypes.GET_SAP_ANTICIPO, getAnticipo),
    takeLatest(actionTypes.GET_ANTICIPO_HISTORIAL, getAnticipoHistorial),
    takeLatest(actionTypes.POST_ANTICIPO_SUELDO, postAnticipo),
    takeLatest(actionTypes.PUT_ANTICIPO_SUELDO, putAnticipo),
    takeLatest(actionTypes.DELETE_ANTICIPO_SUELDO, deleteAnticipo),
    takeLatest(actionTypes.SHOW_MODAL_ANTICIPO_STATUS, _showModalAnticipo)
  ]);
}

export default anticipoSueldoSagas;

import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import MLIntro from '../../shared/intro/MLIntro';
import Loading from '../../../containers/loading';
import { SET_SHOW_INTRODUCTION } from '../../../graphql/userSettings/mutations';
import { skills4CIntroductionKey } from '../../../helpers/introductions';
import { MY_EVOLUTION_CONTEXT } from '../../../helpers/apollo';

const dataIntroduction = [
  {
    title: '¿Por qué competencias?',
    description: 'Porque son las habilidades y conductas que impulsamos como Compañía, desde las que cada uno aporta para alcanzar nuestras metas en común.'
  },
  {
    title: '¿Para qué nace el modelo de ADN Walmart?',
    description: 'En Walmart Chile buscamos desarrollar nuevas competencias en todos nuestros colaboradores para que sean la mejor versión de sí mismos y acompañar la transformación de nuestro negocio.'
  },
  {
    title: '¿Cómo es este nuevo modelo de Mis Competencias 4C?',
    description: 'Este modelo tiene 4 competencias, cada competencia tiene una descripción universal.  Además según nuestro nivel de responsabilidad, o perfil, hay conductas asociadas por cada competencia.'
  },
  {
    title: '¡Te invitamos a ser el protagonista de tu evolución!',
    description: ''
  }
];

const Introduction = (props) => {
  const { endIntroduction, refetchQueries } = props;

  const [saveShowIntroduction, { loading }] = useMutation(
    SET_SHOW_INTRODUCTION,
    {
      variables: {
        introduction:
        {
          introductionName: skills4CIntroductionKey,
          status: true
        }
      },
      context: MY_EVOLUTION_CONTEXT,
      onCompleted: () => {
        endIntroduction();
      }
    }
  );

  const finish = () => {
    saveShowIntroduction({
      refetchQueries
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <MLIntro data={dataIntroduction}
      labelFinish='Conocer mis competencias'
      finish={finish}
    />
  );
};

Introduction.propTypes = {
  endIntroduction: PropTypes.func,
  refetchQueries: PropTypes.array
};

Introduction.defaultProps = {
  endIntroduction: () => {},
  refetchQueries: []
};

export default Introduction;

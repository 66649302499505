export const GERENCIAS = [
  'EXPATRIADOS',
  'GERENCIA ASUNTOS CORPORATIVOS',
  'GERENCIA AUDITORIA INTERNA',
  'GERENCIA COMERCIAL',
  'GERENCIA ESTRATEGIA CORPORATIVA',
  'GERENCIA ETICA Y CUMPLIMIENTO',
  'GERENCIA FINANZAS E INMOBILIARIA',
  'GERENCIA INVESTIGACIONES GLOBALES',
  'GERENCIA LEGAL',
  'GERENCIA MARKETING Y CLIENTES',
  'GERENCIA OMNICHANNEL SUPPLY CHAIN',
  'GERENCIA OPERACIONES',
  'GERENCIA PERSONAS',
  'GERENCIA TECNOLOGIA',
  'GERENCIA WALMART DIGITAL'
];

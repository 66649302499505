import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Form, Row, Input, Select, Spin, Col, Modal
} from 'antd';
import { orderBy } from 'lodash';
import InfoMessage from '../../components/shared/InfoMessage';
import {
  createTicket,
  homeHelpDetail,
  addComment
} from '../../redux/actions/help';
import { getBankList } from '../../redux/actions/banks';
import MessageModal from '../../components/shared/ModalMessage';
import MLAntdMModalFullScreen from '../../components/shared/MLAntdModalFullScreen';
import MLTable from '../../components/shared/tables/MLTable';
import useModal from '../../hooks/shared/mlModal/useModal';
import '../../static/styles/mio/benefit.less';
import '../../static/styles/help/main.less';
import Loading from '../loading';

import {
  isValidEmail,
  isValidPhoneNumber,
  completePath
} from '../../helpers/strings';

import {
  formIsValid,
  getColumns,
  getModalError,
  getModalSuccess,
  listOptionsTypeRequest,
  searchTableHelp,
  setDescriptionBankAccount,
  getParsePhone
} from '../../helpers/help';
import AddFile from '../../components/help/AddFile';
import CardTicketAndComment from '../../components/help/CardTicketAndComment';
import { modalInfoMessage, mainMessage } from '../../components/help/messages';
import SearchInTable from '../../components/help/SearchInTable';
import CardIcon from '../../components/shared/cards/InfoCardField';
import optionsTypeRequest from '../../helpers/help/optionsTypeRequest';
import errorKeyCase from '../../helpers/help/errorKeyCase';
import BankAccountForm from './BankAccountForm';
import BusesForm from './BusesForm';
import setDescriptionBuses from '../../helpers/help/setDescriptionBuses';

const { BANK_ACCOUNT, BUSES, OTHERS } = optionsTypeRequest;
const {
  HOME_HELP,
  CREATE_TICKET,
  FILE_TICKET,
  FILE_SIZE,
  INCOMPLETE_BANK_DETAILS,
  INCOMPLETE_BUSES_DETAILS,
  INCOMPATIBLE_FILE,
  COMMENT
} = errorKeyCase;

const imageCL = completePath('app/datos-de-emergencia/cl.png');

const { Option } = Select;
const { TextArea } = Input;

const homeHelp = ({ userData }) => {
  const {
    helpReducer: {
      resComment, resTicket, resHomeHelp, loading, resFileHelp
    },
    banksReducer: { bankListLoading, bankListError, bankList }
  } = useSelector((state) => state);

  const [dataToTable, setDataToTable] = useState([]);
  const [showInputOther, setShowInputOther] = useState(false);
  const [showBankAccount, setShowBankAccount] = useState(false);
  const [showBuses, setShowBuses] = useState(false);
  const [nameFile, setNameFile] = useState('');
  const [isSelected, setIsSelected] = useState(false);
  const [typeRequest, setTypeRequest] = useState('');
  const [bankAccountData, setBankAccountData] = useState({});
  const [busesData, setBusesData] = useState({});
  const [
    isDisabledButtonSentComment,
    setIsDisabledButtonSentComment
  ] = useState(true);
  const [
    isDisabledButtonCreateTicket,
    setIsDisabledButtonCreateTicket
  ] = useState(true);
  const [commentToSend, setCommentToSend] = useState({});
  const [ticketComment, setTicketComment] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [validPhone, setValidPhone] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [parentId, setParentId] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [commentLength, setCommentLength] = useState(0);
  const [disabledAddComment, setDisabledAddComment] = useState('');
  const [inputComment, setInputComment] = useState('');
  const [modalMessage, setModalMessage] = useState({ visible: false });
  const [modalRequestTicket, setModalRequestTicket] = useState(!resTicket);
  const [modalRequestComment] = useState(!resComment);

  const dispatch = useDispatch();
  const modal = useModal();
  const {
    events: { openModal, closeModal }
  } = modal;

  const userEmail = userData?.emailOffice || userData?.emailPersonal || '';
  const userPhone = getParsePhone(userData?.phone) || '';

  const resetValidations = () => {
    setIsDisabledButtonSentComment(true);
    setValidEmail(true);
    setValidPhone(true);
  };

  const getBanks = () => {
    dispatch(getBankList());
  };
  useEffect(() => {
    getBanks();
  }, []);

  useEffect(() => {
    if (resHomeHelp?.data.length > 0 && resHomeHelp?.status === 200) {
      setDataToTable(resHomeHelp?.data);
    } else if (resHomeHelp?.status >= 500) {
      getModalError({
        setModalMessage,
        modalMessage,
        type: HOME_HELP
      });
    }
  }, [resHomeHelp]);

  useEffect(() => {
    if (resTicket && modalRequestTicket) {
      if (resTicket.status === 201) {
        getModalSuccess(setModalMessage, modalMessage, 'ticket');
        dispatch(homeHelpDetail(userData.npres));
        resetValidations();
        closeModal();
      } else {
        getModalError({
          setModalMessage,
          modalMessage,
          type: CREATE_TICKET
        });
        setIsDisabledButtonCreateTicket(false);
      }
    }
  }, [resTicket]);

  const validEmailPhone = () => {
    if (!userPhone || !isValidPhoneNumber(userPhone)) setValidPhone(false);
    if (!userEmail || !isValidEmail(userEmail)) setValidEmail(false);
  };

  useEffect(() => {
    const typeRequestSelected = typeRequest !== '';
    if (typeRequestSelected) {
      setIsDisabledButtonCreateTicket(false);
    }
  }, [typeRequest]);

  const handleOpenModal = (e) => {
    setModalRequestTicket(true);
    openModal(e);
    setShowInputOther(false);
    setIsDisabledButtonCreateTicket(true);
    validEmailPhone();
  };
  const handleCancel = () => {
    closeModal();
  };

  const genericOnChange = (value, setVar) => {
    setVar(value);
    setShowInputOther(value === `${OTHERS.category}--${OTHERS.priority}`);
    setShowBankAccount(
      value === `${BANK_ACCOUNT.category}--${BANK_ACCOUNT.priority}`
    );
    setShowBuses(value === `${BUSES.category}--${BUSES.priority}`);
  };

  const canAddComment = (status) => {
    setCommentLength(0);
    setInputComment('');
    setDisabledAddComment(status === 'Cerrado');
  };

  const handleClickDetail = (record) => {
    const { idCaseNumber, idTicket, status } = record;
    canAddComment(status);
    const isExpandedTicket = expandedRowKeys.find(
      (row) => row === idCaseNumber
    );
    if (isExpandedTicket) {
      setExpandedRowKeys([]);
      setParentId(null);
    } else {
      setExpandedRowKeys(idTicket === parentId ? [] : [idCaseNumber]);
      setParentId(idTicket);
    }
  };

  const onTableRowExpand = (expanded, record) => {
    const { idCaseNumber, status } = record;
    canAddComment(status);
    setParentId(record?.idTicket);
    setExpandedRowKeys(expanded ? [idCaseNumber] : []);
  };

  const handleComment = (value, recordId) => {
    const commentTyped = value.target.value;
    setInputComment(value.target.value);
    if (commentTyped !== '') {
      setCommentLength(commentTyped.length);
      setCommentToSend({
        ...commentToSend,
        [recordId]: value.target.value
      });
    } else {
      setCommentToSend({});
    }
  };

  const handleSearch = (event) => {
    const currValue = event?.target?.value;
    const filteredData = searchTableHelp(currValue, resHomeHelp?.data);
    setDataToTable(filteredData);
  };

  const handleTicket = (value) => {
    const commentTyped = value.target.value;
    if (commentTyped !== '') {
      setTicketComment(commentTyped);
    } else {
      setTicketComment(commentTyped);
    }
  };

  useEffect(() => {
    setIsDisabledButtonSentComment(
      !(Object.values(commentToSend).length > 0 || isSelected)
    );
  }, [commentToSend, isSelected]);

  const updateField = (e) => {
    const { name, value } = e.target;
    switch (name) {
    case 'fono':
      setValidPhone(isValidPhoneNumber(value));
      break;
    case 'email':
      setValidEmail(isValidEmail(value));
      break;
    default:
      break;
    }
  };

  const addTicket = (payload) => {
    dispatch(createTicket(payload));
    setNameFile('');
    setSelectedFile(null);
    setCommentToSend({});
    setIsSelected(false);
  };

  const submitCreateTicket = (e) => {
    updateField(e);
    e.preventDefault();
    const { fono, commentary, email } = e.target;

    if (formIsValid(fono.value, email.value)) {
      const isNewCellPhoneOrEmail = fono.value !== userPhone || email.value !== userEmail;
      const collab = {
        FirstName: userData.name,
        LastName: userData.fatherLastName,
        WM_ApellidoMaterno__pc: userData.motherLastName,
        PersonMobilePhone: fono.value,
        PersonEmail: email.value,
        WM_Correo__pc: userEmail,
        PersonTitle: userData.position,
        WM_NumPersona__c: userData.npres,
        WM_Sociedad__pc: userData.codSociety,
        WM_Home_OFF_Operaciones__pc:
          userData.HOOPP === 'HO' ? 'Home Office' : 'Operaciones',
        isNewCellPhoneOrEmail
      };

      const ticket = {
        Status: 'Nuevo',
        WM_Categoria__c: typeRequest,
        Description: commentary?.value,
        Account: { WM_NumPersona__c: userData.npres },
        Phone: fono.value,
        PersonEmail: email.value
      };

      const initComment = {
        TextBody: commentary?.value,
        FromName: `${userData.name} ${userData.fatherLastName}`,
        FromAddressOld: userEmail,
        FromAddressNew: email.value
      };
      const payload = {
        collab,
        ticket,
        initComment,
        file: selectedFile
      };
      if (showBankAccount) {
        const bankAccountDescription = setDescriptionBankAccount({
          bankAccountData,
          bankList
        });
        if (bankAccountDescription && selectedFile) {
          payload.ticket.Description = bankAccountDescription;
          payload.initComment.TextBody = bankAccountDescription;
          addTicket(payload);
        } else {
          getModalError({
            setModalMessage,
            modalMessage,
            type: INCOMPLETE_BANK_DETAILS
          });
        }
      }
      if (showBuses) {
        const busesDescription = setDescriptionBuses({
          busesData,
          ticketComment
        });
        if (busesDescription) {
          payload.ticket.Description = busesDescription;
          payload.initComment.TextBody = busesDescription;
          addTicket(payload);
        } else {
          getModalError({
            setModalMessage,
            modalMessage,
            type: INCOMPLETE_BUSES_DETAILS
          });
        }
      } else {
        addTicket(payload);
      }
    }
  };

  const handleCreateTicket = (e) => {
    setNameFile(e.target.files[0].name);
  };

  const typeAccept = {
    'image/jpeg': 'JPEG',
    'image/png': 'PNG',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
    'application/vnd.ms-excel': 'XLS',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'DOCX',
    'application/msword': 'DOC',
    'application/pdf': 'PDF',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      'PPTX',
    'application/vnd.ms-powerpoint': 'PPT',
    'image/gif': 'GIF'
  };

  const attachmentAlertMessage = (type = null, fileSize) => {
    if (type != null) {
      getModalError({
        setModalMessage,
        modalMessage,
        type: FILE_SIZE,
        fileSize
      });
    } else {
      getModalError({
        setModalMessage,
        modalMessage,
        type: INCOMPATIBLE_FILE
      });
    }
  };

  const handleFileSelected = (e) => {
    const [file] = Array.from(e.target.files);
    const fileType = file.type;
    const fileSize = file.size;
    const oneMb = 1000000;
    const mbAccept = 5;
    if (fileSize > mbAccept * oneMb || !typeAccept[fileType]) {
      attachmentAlertMessage(typeAccept[fileType], fileSize);
      setNameFile('');
      setIsSelected(false);
      setSelectedFile(null);
    } else {
      setNameFile(file.name);
      setSelectedFile(e.target.files[0]);
      const formData = new FormData();
      formData.append('File', e.target.files[0]);
      setIsSelected(true);
    }
  };

  const handleRemoveSelectedFile = () => {
    setNameFile('');
    setIsSelected(false);
  };

  const submitAddComment = (e) => {
    e.preventDefault();
    const { addCommentText } = e.target;
    const body = {
      ParentId: parentId,
      TextBody: addCommentText.value,
      FromName: `${userData.name} ${userData.fatherLastName}`,
      FromAddress: userEmail,
      HtmlBody: '',
      Subject: ''
    };
    const payload = {
      body,
      file: selectedFile
    };
    dispatch(addComment(payload));
  };

  useEffect(() => {
    if (resComment && modalRequestComment) {
      if (resComment?.status === 201) {
        canAddComment('');
        getModalSuccess(setModalMessage, modalMessage, 'comentario');
        dispatch(homeHelpDetail(userData.npres));
        resetValidations();
        closeModal();
      } else {
        getModalError({
          setModalMessage,
          modalMessage,
          type: COMMENT
        });
      }
    }
  }, [resComment]);

  useEffect(() => {
    if (resFileHelp?.status === 200) {
      resFileHelp.data.click();
    } else if (resFileHelp?.status) {
      getModalError({
        setModalMessage,
        modalMessage,
        type: FILE_TICKET
      });
    }
  }, [resFileHelp]);

  const spinning = !resHomeHelp?.data || loading || bankListLoading;

  return (
    <div>
      <Spin indicator={<Loading />} size='large' spinning={spinning}>
        <div className='help-main'>
          <div className='home-list-page-mobile-horizontal'>
            <InfoMessage color='suave' classes='mt-3' message={mainMessage} />
          </div>
          <Row className='mt-3'>
            <Col xs={24} xl={16} xxl={18}>
              <Button
                type='primary'
                className='help-primary-button'
                onClick={handleOpenModal}
              >
                Crear nuevo ticket
              </Button>
            </Col>
            <Col xs={24} xl={8} xxl={6}>
              <div className='float-right mt-3 mb-3' style={{ width: '100%' }}>
                <SearchInTable
                  handleSearch={handleSearch}
                  placeholder='Buscar número de ticket'
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={24}>
              <MLTable
                className='help-main-table'
                columns={getColumns(handleClickDetail, expandedRowKeys)}
                dataSource={dataToTable}
                pagination={true}
                expandedRowKeys={expandedRowKeys}
                onExpand={onTableRowExpand}
                expandedRowRender={(record) => (
                  <Row
                    className='help-ticket-description'
                    key={record.idCaseNumber}
                  >
                    <Col>
                      <p className='help-ticket-label'>
                        Información de contacto del colaborador(a)
                      </p>
                      <p className='help-ticket-text'>
                        Teléfono: <span>{record.phono}</span>
                      </p>
                      <p className='help-ticket-text'>
                        Correo electrónico: <span>{record.email}</span>
                      </p>
                      <p className='help-ticket-label'>Tema de la solicitud</p>
                      <p className='help-ticket-text'>{record.type}</p>
                      <p className='help-ticket-label'>Comentarios</p>

                      {orderBy(record.threads, ['createdDate'], ['asc']).map(
                        (comment, position) => (
                          <CardTicketAndComment
                            key={comment.comment.Id}
                            dispatch={dispatch}
                            comment={comment}
                            position={position}
                            record={record}
                          />
                        )
                      )}
                    </Col>
                    <Form
                      className='help-add-comment-form'
                      onSubmit={submitAddComment}
                    >
                      <Col xs={24} sm={20}>
                        <Form.Item
                          label='Nuevo Comentario'
                          className='help-add-comment-text-area'
                        >
                          <TextArea
                            className='ml-textarea'
                            id='addCommentText'
                            name='aadCommentText'
                            value={inputComment}
                            placeholder='Escribe tu comentario'
                            maxLength='1000'
                            onChange={(value) => handleComment(value, record.idCaseNumber)
                            }
                            disabled={disabledAddComment}
                          />
                        </Form.Item>
                        <div style={{ width: '100%' }}>
                          <span className='help-textarea-comment-length'>
                            {commentLength || 0}/1000
                          </span>
                        </div>
                      </Col>
                      <Col xs={24}>
                        <Form.Item>
                          <Row className='help-ticket-description'>
                            <Col md={24} lg={12} xxl={8}>
                              <AddFile
                                isSelected={isSelected}
                                handleCreateTicket={handleCreateTicket}
                                handleFileSelected={handleFileSelected}
                                handleRemoveSelectedFile={
                                  handleRemoveSelectedFile
                                }
                                nameFile={nameFile}
                                inputId='addCommentFile'
                                disabled={disabledAddComment}
                              />
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col
                        md={24}
                        xs={24}
                        lg={24}
                        className='mb-4 help-send-comment'
                      >
                        <Form.Item>
                          <Button
                            inputId='addCommentBtn'
                            className='help-primary-button'
                            htmlType='submit'
                            type='primary'
                            loading={loading}
                            disabled={
                              isDisabledButtonSentComment || disabledAddComment
                            }
                          >
                            Enviar comentario
                          </Button>
                        </Form.Item>
                      </Col>
                    </Form>
                  </Row>
                )}
              />
            </Col>
          </Row>
          <Row className='mt-4'>
            <Form.Item className='col-12 text-center'>
              <Button
                className='uniforms-button-download-report by-rut'
                loading={false}
                onClick={handleOpenModal}
                type='primary'
              >
                Crear Ticket
              </Button>
            </Form.Item>
          </Row>
        </div>
      </Spin>
      <MLAntdMModalFullScreen
        {...{
          ...modal.props,
          title: 'Crear Ticket',
          closeModal: handleCancel
        }}
      >
        <Form
          className='help-form-modal-create-ticket'
          onSubmit={submitCreateTicket}
        >
          <Form.Item>
            <div className='help-info-message'>
              <InfoMessage color='suave' message={modalInfoMessage} />
            </div>
          </Form.Item>
          <Form.Item label='Tema de la solicitud'>
            <Select
              placeholder='Seleccionar tipo de solicitud'
              id='typeRequest'
              name='typeRequest'
              onChange={(target) => {
                genericOnChange(target, setTypeRequest);
              }}
            >
              {listOptionsTypeRequest.map((item) => (
                <Option key={item.name} value={item.value}>
                  {item.name}
                </Option>
              ))}
            </Select>
            {showInputOther && (
              <div>
                <Input
                  type='text'
                  id='typeRequestOther'
                  name='typeRequestOther'
                  placeholder='Escribe el tipo de solicitud'
                ></Input>
              </div>
            )}
          </Form.Item>
          {showBuses && (
            <BusesForm busesData={busesData} setBusesData={setBusesData} />
          )}
          {showBankAccount ? (
            <BankAccountForm
              bankAccountData={bankAccountData}
              setBankAccountData={setBankAccountData}
              bankList={bankList}
              loading={bankListLoading}
              error={bankListError}
              getBanks={getBanks}
            />
          ) : (
            <Form.Item
              label='Comentarios'
              className='help-add-comment-text-area'
            >
              <TextArea
                className='ml-textarea'
                id='commentary'
                name='commentary'
                placeholder='Escribe lo que necesites comentar acerca de tu solicitud'
                maxLength='1000'
                onChange={(value) => handleTicket(value)}
              />
              <div style={{ width: '100%' }}>
                <span className='help-textarea-ticket-length'>
                  {ticketComment?.length || 0}/1000
                </span>
              </div>
            </Form.Item>
          )}
          <Form.Item>
            <Row className='help-ticket-description'>
              <Col md={24} lg={16} xl={12} xxl={12}>
                <AddFile
                  message={
                    showBankAccount
                      ? BANK_ACCOUNT.fileDescription
                      : 'Adjuntar archivo'
                  }
                  isSelected={isSelected}
                  handleCreateTicket={handleCreateTicket}
                  handleFileSelected={handleFileSelected}
                  handleRemoveSelectedFile={handleRemoveSelectedFile}
                  nameFile={nameFile}
                  inputId='addTicketFile'
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label='Teléfono de contacto'>
            <Row className='help-ticket-description'>
              <Col md={24} lg={16} xl={12} xxl={12}>
                <CardIcon className='info-card-field-modal help-input-fono'>
                  <Input
                    id='fono'
                    name='fono'
                    addonBefore={
                      <span>
                        <img
                          src={imageCL}
                          className='flag-icon help-input-fono-img'
                          alt='Imagen'
                        />{' '}
                        <a>+56</a>
                      </span>
                    }
                    maxLength={9}
                    pattern='[0-9]*'
                    defaultValue={userPhone}
                    onChange={updateField}
                    onBlur={updateField}
                    placeholder='Ingresar teléfono'
                  />
                  <div
                    className={`ant-form-extra ${
                      !validPhone ? 'has-error' : ''
                    } `}
                  >
                    {!validPhone && 'Ingresa un número de teléfono válido'}
                  </div>
                </CardIcon>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label='Correo electrónico de contacto'>
            <Row className='help-ticket-description'>
              <Col md={24} lg={16} xl={12} xxl={12}>
                <Input
                  className='border-secondary-mibono'
                  type='email'
                  id='email'
                  maxLength={100}
                  name='email'
                  placeholder='Ingresa correo electrónico'
                  defaultValue={userEmail}
                  onChange={updateField}
                  onBlur={updateField}
                />
                <div
                  className={`ant-form-extra ${
                    !validEmail ? 'has-error' : ''
                  } `}
                >
                  {!validEmail && 'Ingresa un correo electrónico válido'}
                </div>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item>
            <Button
              disabled={
                isDisabledButtonCreateTicket || !validPhone || !validEmail
              }
              type='primary'
              htmlType='submit'
              loading={loading}
            >
              Crear Ticket
            </Button>
          </Form.Item>
        </Form>
      </MLAntdMModalFullScreen>
      <Modal></Modal>
      {modalMessage?.visible && <MessageModal {...{ ...modalMessage }} />}
    </div>
  );
};

export default homeHelp;

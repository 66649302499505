import React, { useEffect, useState } from 'react';
import {
  Layout
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fullCapitalizeFormat } from '../../../helpers/strings';
import ModalMessage from '../../shared/ModalMessage';

const { Header } = Layout;

const HeaderComponent = ({
  title, showBack = true, className, customGoBack = null
}) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [modalOptions, setModalOptions] = useState({
    visible: false
  });

  const
    modalLeftIconHeader = useSelector((state) => state.commonsReducer.modalLeftIconHeader);

  const closeModal = () => {
    setModalOptions({
      visible: false
    });
  };

  // eslint-disable-next-line no-useless-escape
  const formatTitle = (route) => fullCapitalizeFormat(decodeURIComponent(route.replace(/\-/g, ' ')));
  const getNameHeader = () => {
    if (title) return title;
    const routes = encodeURI(pathname).split('/');
    if (routes) {
      return formatTitle(routes[routes.length - 1]);
    }
    return '';
  };
  useEffect(() => {
    if (!modalLeftIconHeader.visible) {
      setModalOptions({
        visible: false
      });
    }
  }, [modalLeftIconHeader.visible]);
  const goBack = (e) => {
    e.preventDefault();

    if (modalLeftIconHeader && modalLeftIconHeader.visible) {
      setModalOptions(modalLeftIconHeader);
    } else {
      history.goBack();
    }
  };

  if (pathname === '/milugar') return null;

  return (

    <Header className={`header${className ? ` ${className}` : ''} d-flex align-items-center`}>
      {modalOptions && modalOptions.visible && modalLeftIconHeader.visible
      && <ModalMessage
        {...modalLeftIconHeader}
        closeModal={closeModal}
      />
      }

      {
        showBack
        && <div
          className='contaner-ml-back'
          onClick={customGoBack || goBack}>
          <i className="icon-ml-back" />
        </div>
      }
      <div className="container-title">
        <p id="main-header-title">{getNameHeader()}</p>
        <p id="main-header-custom-title" />
      </div>
    </Header>
  );
};

export default HeaderComponent;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { completePath } from '../helpers/strings';
import '../static/styles/pwa-install/mobileScreen.less';
import PWAInstall from '../containers/pwa-install';
import Version from './Version';

const MobileScreen = ({
  webAccess
}) => {
  const [showInstallPWA, setShowInstallPWA] = useState(false);

  if (showInstallPWA) {
    return <PWAInstall goBack={() => setShowInstallPWA(false)} />;
  }
  return (
    <div className="mobile-screen">
      <img className="img-fluid" src={completePath('web/milugar/logos/logo-mi-lugar.svg')} alt="" />
      <p className="text-title">Instalar App Mi Lugar</p>
      <p className='text-description'>Instala la aplicación y accede a Mi Lugar de forma más rápida y fácil.</p>
      <div className='container-buttons'>
        <Button type='primary'
          onClick={(e) => {
            e.preventDefault();
            setShowInstallPWA(true);
          }}
        >
        Instalar App
        </Button>
        <Button
          onClick={() => webAccess()}
          className='button-border-primary'>
          Continuar a Web
        </Button>
      </div>
      <Version />
    </div>
  );
};

MobileScreen.propTypes = {
  webAccess: PropTypes.any
};

MobileScreen.defaultProps = {
  webAccess: null
};

export default MobileScreen;

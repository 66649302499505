import React from 'react';
import ReportByCode from '../../../../components/reports/ReportByCode';

export default () => (
  <ReportByCode
    jobCode='IDP_REPORT'
    reportDetails= {{
      title: 'Reporte de IDP',
      description:
      'Detalle de los Planes de Desarrollo Individuales vigentes de los colaboradores.'
    }}
  />
);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

const Report = (props) => {
  const dispatch = useDispatch();

  const {
    loading,
    data,
    error,
    clean,
    onSuccess,
    openModalMessageError,
    onClick,
    icon,
    title,
    visible,
    willGetFile
  } = props;

  useEffect(() => {
    if (!loading) {
      if (data) {
        if (willGetFile) window.open(data.url, '_blank');
        onSuccess(props);
        dispatch(clean());
      }

      if (error) {
        openModalMessageError();
      }
    }

    return () => {
      if (!loading && (data || error)) {
        dispatch(clean());
      }
    };
  }, [loading]);

  if (!visible) return null;
  return (
    <div onClick ={() => !loading && onClick()} className='item-report'>
      { loading
        ? <div className="spinner-border spinner-border-sm mr-2" role="status" />
        : <i className={icon}/>
      }
      {title}
    </div>
  );
};

Report.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.object,
  error: PropTypes.object,
  clean: PropTypes.func,
  onSuccess: PropTypes.func,
  openModalMessageError: PropTypes.func,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
  visible: PropTypes.bool,
  willGetFile: PropTypes.bool
};

Report.defaultProps = {
  loading: false,
  data: null,
  error: null,
  clean: () => {},
  onSuccess: () => null,
  openModalMessageError: () => {},
  onClick: () => {},
  icon: '',
  title: '',
  visible: true,
  willGetFile: true
};

export default Report;

import React from 'react';
import PropTypes from 'prop-types';

import PerformanceEvaluationCard from '../../../../shared/PerformanceEvaluation/PerformanceEvaluationCard';
import { VIEW_AS_EVALUATED } from '../../../../../../helpers/myEvolution/evaluations';

const PastEvaluation = ({ evaluationSelected }) => {
  const { evaluation, autoEvaluation } = evaluationSelected || {};

  return (
    <div>
      <PerformanceEvaluationCard
        {...{
          viewAs: VIEW_AS_EVALUATED,
          evaluation,
          autoEvaluation,
          showSteps: false
        }}
      />
    </div>
  );
};

PastEvaluation.propTypes = {
  evaluationSelected: PropTypes.object
};

PastEvaluation.defaultProps = {
  evaluationSelected: {}
};
export default PastEvaluation;

export const actionTypes = {
  GET_EMERGENCY_DATA: 'GET_EMERGENCY_DATA',
  GET_EMERGENCY_DATA_SUCCESS: 'GET_EMERGENCY_DATA_SUCCESS',
  GET_EMERGENCY_DATA_ERROR: 'GET_EMERGENCY_DATA_ERROR',

  UPDATE_EMERGENCY_DATA: 'UPDATE_EMERGENCY_DATA',
  UPDATE_EMERGENCY_DATA_SUCCESS: 'UPDATE_EMERGENCY_DATA_SUCCESS',
  UPDATE_EMERGENCY_DATA_ERROR: 'UPDATE_EMERGENCY_DATA_ERROR',

  RESET_UPDATE_EMERGENCY_DATA: 'RESET_UPDATE_EMERGENCY_DATA'
};

export function updateEmergencyData(data) {
  return {
    type: actionTypes.UPDATE_EMERGENCY_DATA,
    data
  };
}

export function updateEmergencyDataSuccess(payload) {
  return {
    type: actionTypes.UPDATE_EMERGENCY_DATA_SUCCESS,
    payload
  };
}
export function updateEmergencyDataError(error) {
  return {
    type: actionTypes.UPDATE_EMERGENCY_DATA_ERROR,
    error
  };
}

export function getEmergencyData() {
  return {
    type: actionTypes.GET_EMERGENCY_DATA
  };
}

export function getEmergencyDataSuccess(data) {
  return {
    type: actionTypes.GET_EMERGENCY_DATA_SUCCESS,
    payload: { ...data }
  };
}
export function getEmergencyDataError() {
  return {
    type: actionTypes.GET_EMERGENCY_DATA_ERROR
  };
}
export function resetUpdateEmergencyData() {
  return {
    type: actionTypes.RESET_UPDATE_EMERGENCY_DATA
  };
}

import { isEmpty } from 'lodash';

const setDescriptionBuses = ({ busesData, ticketComment }) => {
  const {
    requestType,
    busCompany,
    workPlace,
    shift,
    incidentType,
    route
  } = busesData;

  if (
    isEmpty(requestType)
    || isEmpty(busCompany)
    || isEmpty(workPlace)
    || isEmpty(shift)
    || isEmpty(incidentType)
    || isEmpty(route)
  ) {
    return null;
  }
  return `\nTipo de requerimiento: ${requestType}\nEmpresa de buses: ${busCompany}\nLugar de trabajo: ${workPlace}\nTurno afectado: ${shift}\nTipo de incidente: ${incidentType}\nRuta: ${route}\n\nComentario: ${ticketComment}`;
};

export default setDescriptionBuses;

import { useDispatch, useSelector } from 'react-redux';
import { searchUser } from '../../../../redux/actions/searchUser';

const useSearchUserQuery = () => {
  const dispatch = useDispatch();
  const callQuery = (opts) => dispatch(searchUser(opts));

  const { data, error, loading } = useSelector((state) => state.searchUserReducer);

  return {
    callQuery, data, error, loading
  };
};

export default useSearchUserQuery;

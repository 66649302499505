import React from 'react';
import PropTypes from 'prop-types';
import '../../static/styles/compensaciones/sueldo/section-title.less';

const SectionTitle = ({ title, className, divider }) => (
  <div className={`section-title-component${className ? ` ${className}` : ''}`}>
    <h3>{title}</h3>
    {divider && <hr />}
  </div>
);

SectionTitle.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  divider: PropTypes.bool
};

SectionTitle.defaultProps = {
  title: '',
  className: '',
  divider: true
};

export default SectionTitle;

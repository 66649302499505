import React from 'react';

const PasswordRestore = () => (
  <div className='ml-password-restore'>
    <p>
      Puedes actualizar tu contraseña llamando a la mesa de ayuda o reiniciándola a través del
      {' '}portal Self Service Password Reset (SSPR) debes seguir los siguientes pasos:
    </p>

    <b>Si ya estás registrado</b>

    <span>
      1. Puedes acceder desde un pc o desde tu teléfono móvil para recuperar o cambiar su
      {' '}contraseña a través del siguiente link
    </span>

    {' '}

    <a href='https://passwordreset.microsoftonline.com' target='_blank' rel='noopener noreferrer'>
      https://passwordreset.microsoftonline.com
    </a>

    {' '}
    <span>o</span>
    {' '}

    <a href='https://aka.ms/sspr' target='_blank' rel='noopener noreferrer'>https://aka.ms/sspr</a>

    <p>
      2. Valida tu identidad y podrás cambiar o desbloquear tu contraseña.
    </p>

    <b>
      Si NO te has registrado, debes agregar tu información personal de la siguiente forma:
    </b>

    {' '}

    <p>
      1. Asegúrate que estas dentro de la red de Walmart.
    </p>

    <span>
      2. Regístrate en el siguiente portal:
    </span>

    {' '}

    <a href='https://wmlink/ssprsetup' target='_blank' rel='noopener noreferrer'>https://wmlink/ssprsetup</a>

    <span>
      {' (Si no estás en un equipo dentro de la red debes ingresar con tu VPN) para esto debes ingresar la siguiente información:'}
    </span>

    <p>{'a) Registra y verifica tú número móvil.'}</p>
    <p>{'b) Registra y verifica tú correo electrónico personal.'}</p>
    <p>{'c) Registra 3 preguntas y respuestas de seguridad.'}</p>
  </div>
);

export default PasswordRestore;

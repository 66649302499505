import React from 'react';
import PropTypes from 'prop-types';

import Bullet from '../filters/Bullet';

import '../../../static/styles/shared/bars/iconList.less';

const IconList = ({ classes, options }) => (
  <ul data-testid='icon-list' className={`icon-list d-flex p-0 align-items-center my-3 ${classes}`}>
    {
      options.map((item, i) => (
        <li key={`iconList${i}`}>
          <a className="icon-list-item d-flex align-items-center" onClick={item.onClick}>
            <i className={item.icon}/>
            <span>{item.label}</span>
            {
              item.badge && item.badge > 0 ? <Bullet data-testid='badge' classes="ml-2" number={item.badge}/> : null
            }
          </a>
        </li>
      ))
    }
  </ul>
);

IconList.propTypes = {
  classes: PropTypes.string,
  options: PropTypes.array
};

IconList.defaultProps = {
  classes: '',
  options: []
};

export default IconList;

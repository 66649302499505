import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import 'isomorphic-unfetch';
import { get } from 'lodash';
import {
  getMyCredentialProfileAPI,
  getMyCredentialPictureAPI,
  renewCredentialAPI,
  getListStoresAPI
} from '../api/micredencial';

import {
  actionTypes,
  loading,
  error,
  getMyCredentialProfileSuccess,
  getMyCredentialProfileError,
  getMyCredentialPictureSuccess,
  renewCredentialResponse,
  getListStoresSuccess,
  getListStoresFailure
} from '../actions/micredencial';
import { failure as commonsFailure } from '../actions/commons';
import { getItem } from '../../helpers/cookiesUtil';

es6promise.polyfill();

function* getMyCredentialProfileSaga(action) {
  try {
    const { data } = yield call(getMyCredentialProfileAPI, action.data);

    if (data) {
      const resp = data.userData.idRole !== 2 ? { ...data, show: true }
        : { ...data, show: false };
      yield put(getMyCredentialProfileSuccess(resp));
    } else {
      // Solución rápida. No es la mejor solución.
      yield put(getMyCredentialProfileError('Usuario sin credenciales'));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
    yield put(getMyCredentialProfileError(err));
  }
}

function* getMyCredentialPictureSaga(action) {
  try {
    yield put(loading('GET_MY_CREDENTIAL_PICTURE_LOADING', true));
    const { data } = yield call(getMyCredentialPictureAPI, action.data);
    if (data) yield put(getMyCredentialPictureSuccess(data));
    yield put(loading('GET_MY_CREDENTIAL_PICTURE_LOADING', false));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
    const { response = null } = err;
    if (
      response
        && response.data
        && response.data.description !== 'Imagen no encontrada.') yield put(error(err.message));
    yield put(loading('GET_MY_CREDENTIAL_PICTURE_LOADING', false));
  }
}

function* renewCredentialSaga(action) {
  try {
    yield put(loading('RENEW_CREDENTIAL_LOADING', true));
    const { data } = yield call(renewCredentialAPI, action.data);
    if (data) yield put(renewCredentialResponse(data));
    yield put(loading('RENEW_CREDENTIAL_LOADING', false));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err.message);
    yield put(loading('RENEW_CREDENTIAL_LOADING', false));
    yield put(error(err.message));
  }
}

function* getListStores() {
  try {
    const npres = getItem('user')?.slice(0, -1);
    const { data } = yield call(getListStoresAPI, npres);
    yield put(getListStoresSuccess(data));
  } catch (err) {
    yield put(getListStoresFailure(error));

    const statusCode = get(err, 'response.status');
    yield put(commonsFailure({ error: err, statusCode }));
  }
}

function* miCredencialSagas() {
  yield all([
    takeLatest(actionTypes.GET_MY_CREDENTIAL_PROFILE, getMyCredentialProfileSaga),
    takeLatest(actionTypes.GET_MY_CREDENTIAL_PICTURE, getMyCredentialPictureSaga),
    takeLatest(actionTypes.RENEW_CREDENTIAL, renewCredentialSaga),
    takeLatest(actionTypes.LIST_STORES, getListStores)
  ]);
}

export default miCredencialSagas;

import React from 'react';
import PropTypes from 'prop-types';
import { Card, List } from 'antd';
import { useQuery } from '@apollo/client';
import { capitalizeText } from '../../../../helpers/commons';

const Result = (props) => {
  const {
    user: {
      name,
      secondName,
      fatherLastName,
      motherLastName,
      position
    }
  } = props;
  return (
    <Card>
      <p>
        {capitalizeText(`${name} ${secondName} ${fatherLastName} ${motherLastName}`)}
      </p>

      <p className='text-position'>
        {capitalizeText(position)}
      </p>
    </Card>

  );
};
const SearchUsersResults = (props) => {
  const {
    variables, selectUser, setShowResultsUsers, query
  } = props;

  const { loading, data } = useQuery(
    query,
    { variables, context: { clientName: 'myEvolution' }, fetchPolicy: 'network-only' }
  );

  const { searchUsers = [] } = data || {};

  return (
    <div className="col-12 col-sm-6  col-md-6">
      <div
        className='container-results-search-users'>
        <div className="container-header-results  d-flex justify-content-between align-items-center">
          <p>Resultados:</p>
          <i onClick={(e) => {
            e.preventDefault();
            setShowResultsUsers(false);
          }}
          className='icon-me-people-remove'/>
        </div>
        <List
          locale={{ emptyText: 'No se han encontrado resultados' }}
          bordered
          loading={loading}
          dataSource={searchUsers}
          renderItem={(item) => (
            <List.Item className='user-card' onClick={ (e) => {
              e.preventDefault();
              selectUser(item, false);
            }} >
              <Result user={item} selectUser={selectUser}/>
            </List.Item>
          )}
        />

      </div>
    </div>
  );
};

SearchUsersResults.propTypes = {
  query: PropTypes.object,
  variables: PropTypes.object,
  selectUser: PropTypes.func,
  setShowResultsUsers: PropTypes.func
};

SearchUsersResults.defaultProps = {
  query: {},
  variables: {},
  selectUser: () => {},
  setShowResultsUsers: () => {}
};

export default SearchUsersResults;

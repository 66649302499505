import React from 'react';

import { completePath } from '../../../../helpers/strings';
import Avatar from '../../../../components/shared/Avatar';
import getUrlParams from '../../../../helpers/routing/getURLParams';
import { salaryMyTeam } from '../../../../helpers/compensations/dictionaries';

import '../../../../static/styles/compensaciones/mi-equipo/myTeamCard.less';

const renderValue = ({ label, value }) => {
  switch (label) {
  case 'positioning':
    return (<p className={`value mb-0 ${value >= 80 && value <= 120 ? 'green' : 'pink'}`}>
      {`${value}%`}
    </p>
    );
  default:
    return <p className='value mb-0'>{value}</p>;
  }
};

const renderUserInfo = (userList, selected) => {
  const omitProperies = ['key', 'fullName', 'position', 'rut', 'title'];
  return (
    <div className='card-wrapper'>
      <div className='header-profile d-flex flex-column align-items-center'>
        <Avatar size={56} />
        <p className='mb-0 title'>{userList[selected].fullName}</p>
        <p className='mb-0 subtitle'>{userList[selected].position}</p>
      </div>
      <ul className='list-unstyled'>
        {
          Object.keys(userList[selected])
            .filter((v) => !omitProperies.find((vFilter) => v === vFilter))
            .map((property, i) => (
              <li className='item d-flex flex-column' key={`li${i}`}>
                <p className='label mb-0'>{salaryMyTeam[property]}</p>
                {renderValue({ label: property, value: userList[selected][property] })}
              </li>
            ))
        }
      </ul>
    </div>
  );
};

const MyTeamCard = ({ userList }) => {
  const selected = parseInt(getUrlParams().get('position'), 0);
  return (
    <div className='my-team-card'>
      <div className='banner' style={{ backgroundImage: `url(${completePath('web/compensaciones/banner/mi-equipo-ho-banner.jpg')})` }}/>
      {renderUserInfo(userList, selected)}
    </div>
  );
};

export default MyTeamCard;

import { actionTypes } from '../actions/commons';

export const initialState = {
  error: {
    error: false,
    message: ''
  },
  statusCode: 0,
  loading: false,
  message: '',

  regions: [],
  loadedRegions: false,
  loadingRegions: false,
  errorRegions: false,

  communes: [],
  loadedCommunes: false,
  loadingCommunes: false,
  errorCommunes: false,

  countries: [],
  loadedCountries: false,
  loadingCountries: false,
  errorCountries: false,

  mlHistorical: {
    scrollTop: 0
  },

  modalLeftIconHeader: {
    visible: false,
    title: '',
    message: '',
    icon: '',
    type: ''
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.FAILURE:

    return {
      ...state,
      ...action.payload,
      loading: false
    };

  case actionTypes.LOADING:
    return {
      ...state,
      ...{ loading: true }
    };

  case actionTypes.LOAD_COMPLETE:
    return {
      ...state,
      ...{ loading: false }
    };

  case actionTypes.DISMISS_ERROR:
    return {
      ...state,
      ...{ error: '' }
    };

  case actionTypes.LOADING_API:
    return {
      ...state,
      ...{ [action.loader]: true }
    };

  case actionTypes.LOADING_API_COMPLETE:
    return {
      ...state,
      ...{ [action.loader]: false }
    };

  case actionTypes.GET_REGIONS:
    return {
      ...state,
      loadingRegions: true,
      loadedRegions: false,
      errorRegions: false
    };
  case actionTypes.GET_REGIONS_SUCCESS:
    return {
      ...state,
      loadingRegions: false,
      loadedRegions: true,
      errorRegions: false,
      regions: action.data
    };
  case actionTypes.GET_REGIONS_ERROR:
    return {
      ...state,
      loadingRegions: false,
      errorRegions: true
    };
  case actionTypes.GET_COMMUNES:
    return {
      ...state,
      loadingCommunes: true,
      loadedCommunes: false,
      errorCommunes: false
    };
  case actionTypes.GET_COMMUNES_SUCCESS:
    return {
      ...state,
      loadingCommunes: false,
      loadedCommunes: true,
      errorCommunes: false,
      communes: action.data
    };
  case actionTypes.GET_COMMUNES_ERROR:
    return {
      ...state,
      loadingCommunes: false,
      errorCommunes: true
    };

  case actionTypes.GET_COUNTRIES:
    return {
      ...state,
      loadingCountries: true,
      loadedCountries: false,
      errorCountries: false
    };
  case actionTypes.GET_COUNTRIES_SUCCESS:
    return {
      ...state,
      loadingCountries: false,
      loadedCountries: true,
      errorCountries: false,
      countries: action.data
    };
  case actionTypes.GET_COUNTRIES_ERROR:
    return {
      ...state,
      loadingCountries: false,
      errorCountries: true
    };
  case actionTypes.SET_MODAL_LEFT_HEADER:
    return {
      ...state,
      modalLeftIconHeader: action.payload

    };
  case actionTypes.RESET_MODAL_LEFT_HEADER:
    return {
      ...state,
      modalLeftIconHeader: initialState.modalLeftIconHeader

    };

  case actionTypes.SET_ML_HISTORICAL_PROPS:
    return {
      ...state,
      mlHistorical: { ...action.payload }

    };
  default:
    return state;
  }
}

export default reducer;

import homeHelp from '../../../containers/help';

export default [
  {
    exact: true,
    path: '/centro-de-ayuda',
    section: 'Centro de Ayuda',
    component: homeHelp
  }
];

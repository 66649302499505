import React from 'react';
import PropTypes from 'prop-types';
import DotColor from '../DotColor';
import '../../../../../../../static/styles/shared/MLSpanListHorizontal/MLSpanListHorizontal.less';

const MLSpanListHorizontal = ({ items }) => (
  <div className="ml-span-list-horizontal">
    {items.map((item, i) => (<div
      key={`list-horizontal-${i}`}
      className="ml-span-list-horizontal-item">
      { item.dotColor ? <DotColor color={item.dotColor} /> : <></> }
      <span>{item.title}: </span>
      <span
        className={item.valueBold ? 'ml-span-list-horizontal-item-value-bold' : ''}
      >
        {item.value}
      </span>
    </div>))}
  </div>
);

MLSpanListHorizontal.propTypes = {
  items: PropTypes.array.isRequired
};

MLSpanListHorizontal.defaultProps = {
  items: [
    {
      title: 'No title',
      value: 0
    }
  ]
};
export default MLSpanListHorizontal;

import React from 'react';
import renderStatus from '../../components/help/renderStatus';
import { formatDateHelp } from '../strings';
import { smallDevice } from '../deviceVariables';
import { useWindowWidthSize } from '../browser';

const getColumns = (handleClickDetail, expandedRowKeys) => {
  const width = useWindowWidthSize();
  const isMobile = width <= smallDevice;
  return [
    {
      title: 'N° Ticket',
      dataIndex: 'idCaseNumber',
      key: 'ticketNumber',
      className: 'table-td help-table-td',
      ...(!isMobile && {
        sorter: (a, b) => a.idCaseNumber.localeCompare(b.idCaseNumber),
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'descend'
      })
    }, {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      className: 'table-td help-table-td',
      ...(!isMobile && {
        render: renderStatus,
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortDirections: ['descend', 'ascend']
      })
    }, ...!isMobile ? [{
      title: 'Solicitud',
      dataIndex: 'requestDate',
      key: 'request',
      className: 'table-td help-table-td',
      render: (date) => formatDateHelp(date, 'DD/MM/YYYY'),
      sorter: (a, b) => a?.requestDate.localeCompare(b?.requestDate),
      sortDirections: ['descend', 'ascend']
    }] : [], ...!isMobile ? [{
      title: 'Cierre',
      dataIndex: 'closingDate',
      key: 'closure',
      className: 'table-td help-table-td',
      render: (date) => {
        if (date) {
          return formatDateHelp(date, 'DD/MM/YYYY');
        }
        return '-';
      },
      sorter: (a, b) => a?.closingDate.localeCompare(b?.closingDate),
      sortDirections: ['descend', 'ascend']
    }] : [], {
      title: 'Detalle',
      dataIndex: 'ticketDetail',
      key: 'ticketDetail',
      className: 'table-td help-table-td',
      render: (row, ticket) => (
        <a className="help-ticket-view-detail" onClick={() => handleClickDetail(ticket)}>
          {(expandedRowKeys.length > 0) && (expandedRowKeys[0] === ticket.idCaseNumber)
            ? 'Ocultar'
            : 'Ver'
          } detalle
        </a>
      )
    }];
};

export default getColumns;

import { actionTypes } from '../actions/anticipoSueldo';

export const initialState = {
  loading: false,
  error: false,
  loadingHistorial: false,
  errorHistorial: false,
  anticipoSueldoHistorial: [],
  anticipoSueldo: {},
  message: '',
  title: '',
  allowed: false,
  requestSuccess: false,
  requestMessage: '',
  requestTitle: '',
  showModal: false,
  loadingValidation: false,
  errorValidation: false,
  messageValidation: ''
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_VALIDATION:
    return {
      ...state,
      ...action.data,
      loadingValidation: true,
      allowed: false,
      errorValidation: false
    };
  case actionTypes.GET_VALIDATION_SUCCESS:
    return {
      ...state,
      ...action.data,
      allowed: true,
      loadingValidation: false,
      errorValidation: false
    };
  case actionTypes.GET_VALIDATION_ERROR:
    return {
      ...state,
      ...action.data,
      allowed: false,
      loadingValidation: false,
      errorValidation: true,
      messageValidation: action.data.message
    };

  case actionTypes.LOADING:
    return {
      ...state,
      loading: true
    };

  case actionTypes.LOAD_COMPLETE:
    return {
      ...state,
      loading: false
    };

  case actionTypes.GET_SAP_ANTICIPO_SUCCESS:
    return {
      ...state,
      anticipoSueldo: { ...action.data },
      error: false,
      loading: false
    };

  case actionTypes.GET_ANTICIPO_HISTORIAL_SUCCESS:
    return {
      ...state,
      anticipoSueldoHistorial: [...action.data],
      errorHistorial: false,
      loadingHistorial: false,
      requestSuccess: false
    };

  case actionTypes.GET_SAP_ANTICIPO_ERROR:
    return {
      ...state,
      error: true,
      showModal: true,
      title: action.data.title,
      message: action.data.message
    };

  case actionTypes.GET_ANTICIPO_HISTORIAL_ERROR:
    return {
      ...state,
      anticipoSueldoHistorial: initialState.anticipoSueldoHistorial,
      errorHistorial: true,
      loadingHistorial: false
    };

  case actionTypes.LOADING_HISTORIAL:
    return {
      ...state,
      loadingHistorial: true
    };

  case actionTypes.ANTICIPO_REQUEST_FAILURE:
    return {
      ...state,
      error: true,
      message: action?.data?.requestMessage,
      title: '¡Lo sentimos! No se pudo enviar tu solicitud',
      loading: false,
      showModal: true
    };

  case actionTypes.POST_ANTICIPO_SUELDO_SUCCESS:
    return {
      ...state,
      anticipoSueldo: {
        ...action.data,
        ...state.anticipoSueldo,
        advanceAmount: action?.data?.amount,
        status: true
      },
      error: false,
      loading: false,
      message:
          'Has solicitado tu anticipo exitosamente, este se verá reflejado en tu cuenta el día 15 del mes en curso.',
      title: 'Tu solicitud se ha enviado con éxito',
      requestSuccess: true
    };

  case actionTypes.PUT_ANTICIPO_SUELDO:
    return {
      ...state,
      anticipoSueldo: { ...state.anticipoSueldo, ...action.data },
      error: false,
      loading: true,
      requestSuccess: false
    };

  case actionTypes.PUT_ANTICIPO_SUELDO_SUCCESS:
    return {
      ...state,
      anticipoSueldo: {
        ...action.data,
        ...state.anticipoSueldo,
        advanceAmount: state.anticipoSueldo.anticipoAmount
      },
      error: false,
      loading: false,
      message:
          'Tu anticipo se ha modificado con éxito, este cambio se verá reflejado en tu cuenta el día 15 del mes en curso.',
      title: 'Tu anticipo se ha modificado con éxito',
      requestSuccess: true
    };

  case actionTypes.DELETE_ANTICIPO_SUELDO_SUCCESS:
    return {
      ...state,
      error: false,
      anticipoSueldo: {
        ...action.data,
        ...state.anticipoSueldo,
        advanceAmount: 0,
        status: false
      },
      loading: false,
      message:
          'Tu anticipo ha sido desactivado con éxito, a partir del siguiente mes ya no te será otorgado.',
      title: 'Tu anticipo ha sido desactivado.',
      requestSuccess: true
    };

  case actionTypes.CHANGE_MODAL_ANTICIPO_STATUS:
    return {
      ...state,
      showModal: action.data
    };

  default:
    return state;
  }
}
export default reducer;

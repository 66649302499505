import { actionTypes } from '../actions/companyChart';

export const initialState = {
  loading: false,
  data: null,
  error: ''
};

function reducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
  case actionTypes.GET_COMPANY_CHART:
    return {
      ...state,
      loading: true
    };
  case actionTypes.GET_COMPANY_CHART_SUCCESS:
    return {
      ...state,
      error: false,
      data: payload.result,
      loading: false
    };
  case actionTypes.GET_COMPANY_CHART_ERROR:
    return {
      ...state,
      error: action.error,
      loading: false
    };
  default:
    return state;
  }
}

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import CollegeCarrer from './Collegue';

const CollegueCarrers = (props) => {
  const {
    getFieldDecorator,
    collegeCarrers,
    setCollegeCarrers,
    listStatusOfStudies,
    levelsOfStudies,
    setLevelsOfStudies,
    postgraduateName,
    setPostgraduateName,
    initialLevelsOfStudies,
    listLevelsOfStudies,
    listOptionsCollegeCarrer
  } = props;

  const updateStatusAndCollegeCarrer = (value, index) => {
    const newCollegeCarrer = collegeCarrers.map(
      (item, i) => (index === i ? { ...item, ...value } : item)
    );
    setCollegeCarrers(newCollegeCarrer);
  };

  return (
    <>
      {map(collegeCarrers, ({ collegeCarrer, statusOfStudies }, index) => (
        <CollegeCarrer
          key={index}
          index={index}
          id={collegeCarrer?._id}
          updateStatusAndCollegeCarrer={updateStatusAndCollegeCarrer}
          listStatusOfStudies={listStatusOfStudies}
          getFieldDecorator={getFieldDecorator}
          collegeCarrer={collegeCarrer}
          statusOfStudies={statusOfStudies}
          {
            ...{
              levelsOfStudies,
              setLevelsOfStudies,
              postgraduateName,
              setPostgraduateName,
              initialLevelsOfStudies,
              listLevelsOfStudies,
              listOptionsCollegeCarrer
            }
          }
        />

      ))}
    </>

  );
};
CollegueCarrers.propTypes = {
  getFieldDecorator: PropTypes.func,
  collegeCarrers: PropTypes.array,
  setCollegeCarrers: PropTypes.func,
  listStatusOfStudies: PropTypes.array,
  levelsOfStudies: PropTypes.string,
  setLevelsOfStudies: PropTypes.func,
  postgraduateName: PropTypes.string,
  setPostgraduateName: PropTypes.func,
  initialLevelsOfStudies: PropTypes.string,
  listLevelsOfStudies: PropTypes.array,
  listOptionsCollegeCarrer: PropTypes.array
};

CollegueCarrers.defaultProps = {
  getFieldDecorator: () => {},
  collegeCarrers: [],
  setCollegeCarrers: () => {},
  listStatusOfStudies: [],
  levelsOfStudies: '',
  loadingUpdate: false,
  setLevelsOfStudies: () => {},
  postgraduateName: '',
  setPostgraduateName: () => {},
  initialLevelsOfStudies: '',
  listLevelsOfStudies: [],
  listOptionsCollegeCarrer: []
};
export default CollegueCarrers;

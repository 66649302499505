import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import {
  addLikePost,
  unLikePost
} from '../api/reactionsMural';
import {
  actionTypes,
  likePostExito,
  likePostError,
  unLikepPostError,
  unLikepPostExito
} from '../actions/reactionsMural';

es6promise.polyfill();

function* addNewLikePostAction({ variables }) {
  try {
    const response = yield call(addLikePost, variables);
    yield put(likePostExito(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(likePostError({ error, statusCode }));
  }
}

function* unLikePostAction({ variables }) {
  try {
    const response = yield call(unLikePost, variables);
    yield put(unLikepPostExito(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(unLikepPostError({ error, statusCode }));
  }
}

function* muralSagas() {
  yield all([takeLatest(actionTypes.LIKE_POST, addNewLikePostAction)]);
  yield all([takeLatest(actionTypes.UN_LIKE_POST, unLikePostAction)]);
}
export default muralSagas;

import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from './LinkButton';
import '../../../static/styles/shared/buttons/delete-button.less';

const MLDeleteButton = ({
  text, onClick, className
}) => {
  const getClassName = () => {
    let classNameResult = 'ml-delete-button';
    if (className) classNameResult += ` ${className}`;
    return classNameResult;
  };

  return (
    <LinkButton {...{
      text, onClick, className: getClassName(), iconClassName: 'fa fa-trash'
    }}/>
  );
};

MLDeleteButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

MLDeleteButton.defaultProps = {
  text: 'Eliminar',
  className: '',
  onClick: () => null
};

export default MLDeleteButton;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DetailsRow from '../../../../shared/cards/DetailsRow2';
import Separator from '../../../../shared/Separator';
import Card from '../../../../shared/cards/card';
import { formatDateCard } from '../../../../../helpers/dates';
import EvaluationSteps from '../../Evaluations/EvaluationSteps';
import EvaluationsMessages from '../../Evaluations/EvaluationsMessages';
import {
  COMMUNICATED, VIEW_AS_EVALUATED, VIEW_AS_EVALUATOR, VIEW_AS_VALIDATOR, getLabelStatus,
  getIconStatus
} from '../../../../../helpers/myEvolution/evaluations';

const CardEvaluation = (props) => {
  const {
    customDate,
    contentData,
    status,
    date,
    children,
    from,
    title,
    classes,
    messages,
    viewAs
  } = props;

  return (
    <Card classes={classes}>
      {title
      && <div>
        <div className='card-details-row mt-0'>
          <h3>{title}</h3>
        </div>
        <Separator />
      </div>
      }
      <Fragment>
        <div className='card-details-row container-date-status '>
          <div >
            <i className="icon-ml-calendar-2" />
            <h6>{ customDate || formatDateCard(date)}</h6>
          </div>
          <div >
            <i className={getIconStatus(status)} />
            <h6> {getLabelStatus(status)}</h6>
          </div>

        </div>

        <Separator />
        <DetailsRow contentData={contentData}/>
        <Separator />
        { from === 'myTeam'
      && <EvaluationSteps
        classes='mb-2'
        isCompleted={status.toUpperCase() === COMMUNICATED}
        status={status}
      />
        }

        <EvaluationsMessages
          messages={messages}
          viewAs={viewAs}
        />
        {children}
      </Fragment>

    </Card>
  );
};

CardEvaluation.propTypes = {
  status: PropTypes.string.isRequired,
  contentData: PropTypes.array.isRequired,
  updatedAt: PropTypes.string.isRequired,
  from: PropTypes.string,
  info: PropTypes.object,
  viewAs: PropTypes.oneOf([VIEW_AS_EVALUATED, VIEW_AS_EVALUATOR, VIEW_AS_VALIDATOR])
};

CardEvaluation.defaultProps = {
  status: '',
  contentData: [],
  updatedAt: '',
  from: '',
  info: null,
  viewAs: ''
};
export default CardEvaluation;

import React from 'react';

import MyEvolution from '../../../containers/mievolucion';
import Skills4C from '../../../containers/mievolucion/Skills4C';
import MyBehaviors from '../../../components/mievolucion/Skills4C/MyBehaviors';
import GeneralModel from '../../../components/mievolucion/Skills4C/GeneralModel';
import FrequentQuestions from '../../../components/mievolucion/Skills4C/FrequentQuestions';
import Calibracion from '../../../containers/mievolucion/Calibracion';

import EvolucionaTEST from '../../../containers/mievolucion/EvolucionaTEST';
import Test from '../../../components/mievolucion/EvolucionaTEST/Test';
import EvolutionPlan from '../../../components/mievolucion/EvolucionaTEST/EvolutionPlan';
import PlanDetail from '../../../components/mievolucion/EvolucionaTEST/EvolutionPlan/PlanDetail';
import UsageKeys from '../../../components/mievolucion/EvolucionaTEST/UsageKeys';

import Retro from '../../../containers/mievolucion/Retro';
import MyEvaluationsHistorialRetro from '../../../components/mievolucion/Retro/Historical';
import DetailMyEvaluation from '../../../components/mievolucion/Retro/EvaluationSelected';

import MyPerformanceLayout from '../../../containers/mievolucion/MiDesempeno';
import Pulses from '../../../containers/mievolucion/Pulsos';
import MyPerformanceFrequentQuestions from '../../../components/mievolucion/MyPerformance/FrequentQuestions';
import MyPerformancePerformanceEvaluation from '../../../components/mievolucion/MyPerformance/PerformanceEvaluation';
import MyPerformancePerformanceEvaluationHistorial from '../../../components/mievolucion/MyPerformance/PerformanceEvaluation/Evaluations/Historical';
import Feedback from '../../../components/mievolucion/MyPerformance/Feedback';
import FeedbackHistorical from '../../../components/mievolucion/MyPerformance/Feedback/Historical';
import FeedbackDetails from '../../../components/mievolucion/MyPerformance/Feedback/Historical/FeedbackDetails';
import FrequentQuestionsFeedback from '../../../components/mievolucion/MyPerformance/Feedback/FrequentQuestions';
import InterestsAndSkills from '../../../containers/mievolucion/InterestsAndSkills';
import { PERFORMANCE_YEAR } from '../../../helpers/myEvolution/myPerformance';

import MiDesarrollo from '../../../containers/mievolucion/MiDesarrollo';
import IDPIntroduction from '../../../components/mievolucion/MiDesarrollo/Introduction';
import ManageIDP from '../../../components/mievolucion/MiDesarrollo/ManageIDP';
import IDPEvaluations from '../../../components/mievolucion/MiDesarrollo/ManageIDP/Evaluations';
import IDPActions from '../../../components/mievolucion/MiDesarrollo/ManageIDP/Actions';
import IDPStrengths from '../../../components/mievolucion/MiDesarrollo/ManageIDP/Strengths';
import {
  EVALUATIONS_IDP_ROUTER_SETTINGS,
  ACTIONS_IDP_ROUTER_SETTINGS,
  STRENGTHS_IDP_ROUTER_SETTINGS,
  MI_DESARROLLO_PATH, MANAGE_IDP_PATH
} from '../../../helpers/myEvolution/miDesarrollo';

export default [
  {

    exact: true,
    path: '/mi-evolucion',
    section: 'Mi Evolución',
    component: MyEvolution,
    auth: 'MI_EVOLUCION'
  },
  {
    path: '/mi-evolucion/evolucionaTEST',
    component: EvolucionaTEST,
    headerTitle: 'EvolucionaTEST',
    auth: 'MI_EVOLUCION_MI_CAMINO_EVOLUCIONA_TEST',
    subRoutes: [
      {
        exact: true,
        tabTitle: 'TEST',
        path: '/mi-evolucion/evolucionaTEST/TEST',
        component: Test,
        headerTitle: 'TEST'
      },
      {
        tabTitle: 'Plan de Evolución',
        path: '/mi-evolucion/evolucionaTEST/plan-de-evolucion',
        component: EvolutionPlan,
        headerTitle: 'Plan de Evolución',
        subRoutes: [
          {
            exact: true,
            path: '/mi-evolucion/evolucionaTEST/plan-de-evolucion',
            component: (props) => <PlanDetail {...props} />
          }
        ]
      },
      {
        tabTitle: 'Claves de uso',
        path: '/mi-evolucion/evolucionaTEST/claves-de-uso',
        component: UsageKeys,
        headerTitle: 'Claves de uso'
      }
    ]
  },
  {
    path: '/mi-evolucion/mis-competencias-4C',
    auth: 'MI_EVOLUCION_MI_GESTION_NUEVAS_COMPETENCIAS',
    section: 'Mi Evolución -> Mis competencias 4C',
    component: Skills4C,
    subRoutes: [
      {
        exact: true,
        tabTitle: 'Mis Conductas',
        path: '/mi-evolucion/mis-competencias-4C',
        section: 'Mi Evolución -> Mis competencias 4C -> Mis Conductas',
        component: MyBehaviors
      },
      {
        exact: true,
        tabTitle: 'Modelo Completo',
        path: '/mi-evolucion/mis-competencias-4C/modelo-general',
        section: 'Mi Evolución -> Mis competencias 4C -> Modelo General',
        component: GeneralModel
      },
      {
        exact: true,
        tabTitle: 'Preguntas Frecuentes',
        path: '/mi-evolucion/mis-competencias-4C/preguntas-frecuentes',
        section: 'Mi Evolución -> Mis competencias 4C -> Preguntas Frecuentes',
        component: FrequentQuestions
      }
    ]
  },
  {
    exact: true,
    path: '/mi-evolucion/calibracion',
    component: Calibracion,
    auth: 'MI_EVOLUCION_MI_GESTION_CALIBRACION'
  },
  {
    path: '/mi-evolucion/retroalimentación',
    component: Retro,
    section: 'Mi Evolución -> Mi Gestión -> Retro',
    auth: 'MI_EVOLUCION_MI_GESTION_RETRO',
    subRoutes: [
      {
        tabTitle: 'Historial',
        path: '/mi-evolucion/retroalimentación/historial',
        component: MyEvaluationsHistorialRetro,
        subRoutes: [
          {
            exact: true,
            path: '/mi-evolucion/retroalimentación/historial',
            component: DetailMyEvaluation
          }
        ]
      }
    ]
  },
  {
    path: '/mi-evolucion/mi-desempeño',
    section: 'Mi Evolución -> Mi Gestión -> Mi Desempeño',
    headerTitle: 'Mi Desempeño',
    auth: 'MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO',
    component: MyPerformanceLayout,
    subRoutes: [
      {
        exact: true,
        tabTitle: `Mis pulsos ${PERFORMANCE_YEAR}`,
        path: '/mi-evolucion/mi-desempeño/desempeño',
        auth: 'MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_DESEMPEÑO',
        component: Pulses
      },
      {
        tabTitle: 'Evaluación de Desempeño',
        path: '/mi-evolucion/mi-desempeño/evaluación-de-desempeño',
        component: MyPerformancePerformanceEvaluation,
        auth: 'MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_EDD',
        subRoutes: [
          {
            tabTitle: 'Historial',
            path: '/mi-evolucion/mi-desempeño/evaluación-de-desempeño/historial',
            component: MyPerformancePerformanceEvaluationHistorial,
            subRoutes: [
              {
                exact: true,
                path: '/mi-evolucion/mi-desempeño/evaluación-de-desempeño/historial',
                component: DetailMyEvaluation
              }
            ]
          }
        ]
      },
      {
        exact: true,
        tabTitle: 'Preguntas Frecuentes',
        path: '/mi-evolucion/mi-desempeño/preguntas-frecuentes',
        component: MyPerformanceFrequentQuestions
      },
      {
        path: '/mi-evolucion/mi-desempeño/feedback',
        section: 'Mi Evolución -> Mi Gestión -> Mi Desempeño -> Feedback',
        component: Feedback,
        headerTitle: 'Feedback',
        auth: 'MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_FEEDBACK',
        subRoutes: [
          {
            path: '/mi-evolucion/mi-desempeño/feedback/hacia-mí',
            section: 'Mi Evolución -> Mi Gestión -> Mi Desempeño -> Feedback -> Hacia Mí',
            component: (props) => <FeedbackHistorical {...{
              ...props,
              type: 'mi',
              listTab: 1
            }} />,
            subRoutes: [
              {
                exact: true,
                path: '/mi-evolucion/mi-desempeño/feedback/hacia-mí/detalle-de-feedback',
                section: 'Mi Evolución -> Mi Gestión -> Mi Desempeño -> Feedback -> Hacia Mí -> Detalle de feedback',
                component: (props) => <FeedbackDetails {...{
                  ...props,
                  type: 'mi',
                  listTab: 1
                }} />
              }
            ]
          },
          {
            path: '/mi-evolucion/mi-desempeño/feedback/hacia-otros',
            section: 'Mi Evolución -> Mi Gestión -> Mi Desempeño -> Feedback -> Hacia Otros',
            component: (props) => <FeedbackHistorical {...{
              ...props,
              type: 'otros',
              listTab: 2
            }} />,
            subRoutes: [
              {
                exact: true,
                path: '/mi-evolucion/mi-desempeño/feedback/hacia-otros/detalle-de-feedback',
                section: 'Mi Evolución -> Mi Gestión -> Mi Desempeño -> Feedback -> Hacia Otros -> Detalle de feedback',
                component: (props) => <FeedbackDetails {...{
                  ...props,
                  type: 'otros',
                  listTab: 2
                }} />
              }
            ]
          },
          {
            exact: true,
            path: '/mi-evolucion/mi-desempeño/feedback/preguntas-frecuentes',
            section: 'Mi Evolución -> Mi Gestión -> Mi Desempeño -> Feedback -> Preguntas Frecuentes',
            component: FrequentQuestionsFeedback
          },
          {
            component: () => {
            // eslint-disable-next-line no-console
              console.log('here goes 404');
              return <p>Error 404</p>;
            }
          }
        ]
      }
    ]
  },
  {
    path: '/mi-evolucion/intereses-y-habilidades',
    section: 'Mi Evolución -> Mi Camino -> Intereses y Habilidades',
    headerTitle: 'Intereses y Habilidades',
    component: InterestsAndSkills,
    userValidator: { HOOPP: 'HO' }

  },
  {
    path: '/mi-evolucion/mi-desarrollo',
    auth: 'MI_EVOLUCION_MI_CAMINO_MI_DESARROLLO',
    section: 'Mi Evolución -> Mi Desarrollo',
    component: MiDesarrollo,
    subRoutes: [
      {
        exact: true,
        path: MI_DESARROLLO_PATH,
        section: 'Mi Evolución -> Mi Desarrollo -> ¿Qué es mi desarollo?',
        component: IDPIntroduction
      },
      {
        path: MANAGE_IDP_PATH,
        section: 'Mi Evolución -> Mi Desarrollo -> Gestiona tu IDP',
        component: ManageIDP,
        subRoutes: [
          {
            exact: true,
            component: IDPEvaluations,
            ...EVALUATIONS_IDP_ROUTER_SETTINGS

          },
          {
            exact: true,
            component: IDPStrengths,
            ...STRENGTHS_IDP_ROUTER_SETTINGS
          },
          {
            exact: true,
            component: IDPActions,
            ...ACTIONS_IDP_ROUTER_SETTINGS
          }
        ]
      }
    ]
  }
].filter((v) => !!v);

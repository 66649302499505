import { lazy } from 'react';

const OperationsAcademy = lazy(() => import('../../containers/academia-de-operaciones'));

export default [
  {
    exact: true,
    path: '/academia-de-operaciones',
    section: 'Academia de Operaciones',
    component: OperationsAcademy
  }
];

import gql from 'graphql-tag';

const CalibrationsPage = {};

CalibrationsPage.fragments = {
  main: gql`
  fragment CalibrationsPageMain on CalibResponse {
    _id
    createdBy # early was a object
    groupName
    calibrationMeeting
    skillsEvaluated { # skills
      _id
      order
      name
    }
    status
    calibrations {
      _id
      evaluationId {
        _id
        evaluated {
         _id
         rut
         name
         fatherLastName
         motherLastName
         position
         market
         society
        }
      }
      potentialEvaluationId
      potentialSkillResultId
      finalScore {
        evaluation
        skills
        objectives
      }
      finalCategories {
        evaluation { # finalCategory
          _id
          score
          name
        }
        skills { # finalSkillCategory
          _id
          score
        }
        objectives { # finalObjectiveCategory
          _id
          score
        }
      }
      skills { # evolutionSkills and inside has skills object
        _id
        order
        categorySelected { # performanceCategory
          _id
          score
        }
      }
    }
    edpResultsList {
      _id
      name
      order
    }
  }
  `
};

export default CalibrationsPage;

import React from 'react';
import { Button } from 'antd';
import '../../static/styles/pwa-install/style.less';
import { getLinkPlayStore } from '../../helpers/commons';

const Android = () => {
  const openPlayStore = () => {
    window.open(getLinkPlayStore, '_blank');
  };

  return (
    <div>
      <div className="container-pwa ">
        <div className='container-oops'>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p className="text-new-app">
              Para instalar la nueva versión de Mi Lugar presiona el Boton
              <span className="text-bold"> Ir a la Play Store, </span>
               el cual te llevará directamente a descargar
              <span className="text-bold"> Mi Lugar </span>
               desde la Play Store
            </p>

            <div className='container-links'>
              <Button
                onClick={openPlayStore}
                type="primary">
                  Ir a la Play Store
              </Button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Android;

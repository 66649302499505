import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';

const Skill = (props) => {
  const {
    skill
  } = props;
  if (!skill) return skill;

  const { categorySelected = {} } = skill || {};
  const skillDescription = skill.description?.length > 0 ? skill.description : 'Sin información';
  const addBreakLine = (arrayDescription) => {
    if (!Array.isArray(arrayDescription)) {
      return arrayDescription;
    }
    return arrayDescription.map((ad, i) => {
      if (i === 0) {
        return <p key={i} style={ { padding: '0px', margin: '0px', height: '15px' }}>{ad}</p>;
      }
      return <><br/><p key={i} style={ { padding: '0px', margin: '0px', height: '15px' }}>{ad}</p></>;
    });
  };
  const [open, setOpen] = useState(false);
  const handleSkill = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <div className='body-summary'>
      <div className='column-evaluation-summary left'>
        <div className='container-title-skill'>

          <div
            className='container-title-icon'
            onClick={handleSkill} >

            <div className='container-icon'>
              <span className={`fa fa-${open ? 'minus' : 'plus'}`} />
            </div>
            <p>{skill.name} </p>
          </div>
        </div>
        {
          open
        && <div>
          <div className='description left'>{
            (
              <>
                {skill.description
                && addBreakLine(skillDescription)
                }

                {skill.behaviors
                && map(skill.behaviors, (behavior, indexBehavior) => (
                  <div key={indexBehavior}>
                    <h6>{behavior.title}</h6>
                    <p className='description'>{behavior.description}</p>
                  </div>))}
              </>
            )
          }</div>
        </div>
        }
      </div>
      <div className='column-evaluation-summary right'>
        <div className='container-title-category' >
          <p className='text-category'>{categorySelected?.name || skill?.value}</p>
        </div>
        {
          open
        && <div>
          <p className='description'>{categorySelected?.skillDescription}</p>
        </div>
        }
      </div>
    </div>
  );
};

Skill.propTypes = {
  skill: PropTypes.object
};

Skill.defaultProps = {
  skill: null
};

export default Skill;

import React from 'react';
import {
  Modal,
  Button
} from 'antd';
import { isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import { useWindowWidthSize } from '../../../helpers/browser';
import '../../../static/styles/shared/modalMessage.less';

const ModalComponent = (props) => {
  const width = useWindowWidthSize();
  const {
    children,
    showIcon,
    classesButtons,
    visible,
    closeModal,
    icon,
    message,
    title,
    buttons,
    closeButton,
    loading,
    maskStyle,
    wrapClassName,
    zIndex,
    style,
    bodyStyle
  } = props;
  const hasColumnButtons = (
    width > 991 && buttons?.length > 3
  ) || (width <= 991 && buttons?.length > 1);

  return (
    <Modal
      width='45%'
      visible={visible}
      footer={null}
      centered
      className='modal-message'
      closable={false}
      maskStyle={maskStyle}
      wrapClassName={wrapClassName}
      zIndex={zIndex}
      style={style}
      bodyStyle={bodyStyle}
    >

      {closeButton && (
        <Button className='modal-close-btn' onClick={() => closeModal()}>
          <i className='icon-me-people-remove' />
        </Button>
      )}
      <div className='text-center'>
        { showIcon
        && <i className={props.type === 'error' ? 'icon-me-error' : icon} />
        }
        { title
        && <p className='title'>{title}</p>
        }

        { message
        && <p className='message'>{message}</p>
        }

        {children}

        { isEmpty(buttons) ? null : (
          <div className={`${classesButtons} modal-buttons${hasColumnButtons ? ' modal-buttons-column' : ''}`}>
            {map(buttons, ({
              type, className, text, value, onClickButton, loadingButton = false
            }, i) => (
              <Button
                key={i}
                {...{
                  [type === 'primary' ? 'loading' : 'disabled']: loading || loadingButton,
                  type,
                  className: `login-form-button${className ? ` ${className}` : ''}`,
                  onClick: () => {
                    if (typeof onClickButton === 'function') {
                      onClickButton();
                    } else { closeModal(value || i); }
                  }
                }}
              >
                { text || (props.type === 'error' ? 'Entendido' : 'Cerrar')}
              </Button>
            ))}
          </div>
        )}
      </div>

    </Modal>
  );
};

export const propTypes = {
  visible: PropTypes.bool,
  showIcon: PropTypes.bool,
  classesButtons: PropTypes.string,
  closeModal: PropTypes.func,
  icon: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.string,
  buttons: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  closeButton: PropTypes.bool,
  loading: PropTypes.bool,
  maskStyle: PropTypes.object,
  wrapClassName: PropTypes.string,
  zIndex: PropTypes.number,
  type: PropTypes.string,
  style: PropTypes.object,
  bodyStyle: PropTypes.object
};

export const defaultProps = {
  showIcon: true,
  classesButtons: '',
  visible: false,
  closeModal: () => null,
  icon: 'icon-me-check',
  title: '',
  message: '',
  buttons: [{
    type: 'primary'
  }],
  closeButton: true,
  loading: false,
  maskStyle: {},
  wrapClassName: '',
  zIndex: 1000,
  type: 'warning',
  style: {},
  bodyStyle: {}
};

ModalComponent.propTypes = propTypes;
ModalComponent.defaultProps = defaultProps;

export default ModalComponent;

import { lazy } from 'react';

const returningPlan = lazy(() => import('../../containers/returningPlan'));

export default [
  {
    exact: true,
    path: '/invitacion-voluntaria',
    section: 'Plan de retorno',
    component: returningPlan
  }
];

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';

import { getReturningPlanFormDataAPI, postReturningPlanDataAPI } from '../api/returningPlan';
import {
  actionTypes,
  getReturningFormDataSuccess,
  getReturningFormDataError,
  postReturningPlanDataSuccess,
  postReturningPlanDataError
} from '../actions/returningPlan';
import {
  failure, loading, loadComplete
} from '../actions/commons';

es6promise.polyfill();

export function* returningPlanForm(item) {
  try {
    yield put(loading());
    const response = yield call(getReturningPlanFormDataAPI, item.data);
    yield put(getReturningFormDataSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(getReturningFormDataError({ error, statusCode }));
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

export function* returningPlan({ data }) {
  try {
    yield put(loading());
    const response = yield call(postReturningPlanDataAPI, data);
    yield put(postReturningPlanDataSuccess(response));
    yield put(loadComplete());
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(postReturningPlanDataError({ error, statusCode }));
    yield put(failure({ error, statusCode }));
    yield put(loadComplete());
  }
}

function* returningPlanSagas() {
  yield all([
    takeLatest(actionTypes.GET_RETURNING_PLAN_FORM_DATA, returningPlanForm),
    takeLatest(actionTypes.POST_RETURNING_PLAN_DATA, returningPlan)
  ]);
}

export default returningPlanSagas;

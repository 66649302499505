import React from 'react';
import {
  Button as AntdButton
} from 'antd';

export const Button = ({ loading, label }) => (
  <div className='d-flex justify-content-center'>
    <AntdButton
      loading={loading}
      type="primary" htmlType="submit">
      {label}
    </AntdButton>
  </div>
);

export const Item = (props) => {
  const {
    label,
    description,
    children,
    className

  } = props;

  return (
    <div className={`item-form ${className}`}>
      {label
      && <label>{label}</label>
      }
      {description
      && <p className='text-description'>{description}</p>
      }
      {children}
    </div>
  );
};
export default {
  Item,
  Button
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input
} from 'antd';
import SeveralOptions from '../Form/SeveralInputOptions';
import {
  getInitialSeveralOptionsData,
  getEvaluationsFieldsToUpdate,
  showAlertBeforeSaveIDP,
  EVALUATIONS_HISTORICAL_SETTINGS,
  EVALUATIONS_RESULTS_SETTINGS,
  ASPIRATIONS_SETTINGS,
  getStatusIDP,
  STATUS_IDP,
  MENTOR_OPTIONS
} from '../../../../../helpers/myEvolution/miDesarrollo';
import ModalMessage from '../../../../shared/ModalMessage';
import Form from '../Form';
import useGetIDP from '../../../../../hooks/myEvolution/miDesarrollo/useGetIDP';
import useSaveIDP from '../../../../../hooks/myEvolution/miDesarrollo/useSaveIDP';
import Question from '../Form/Question';
import SearchUser from '../../../../shared/forms/SearchUser';
import RadioComponent from '../../../../shared/forms/Radio';

const Evaluations = (props) => {
  const { headerDescription, headerTitle } = props;

  const [evaluations, setEvaluations] = useState({});
  const [modalMessage, setModalMessage] = useState({ visible: false });

  const {
    historial,
    results,
    aspirations,
    mentor,
    mentorWorksInWalmart
  } = evaluations;

  const {
    success: successIDP,
    loading: loadingIDP,
    IDP,
    error: errorIDP
  } = useGetIDP();

  const { saveIDP, loading: loadingSavingIDP } = useSaveIDP({ setModalMessage });
  const setInitialData = () => {
    const {
      assessment_history = [],
      assessment_insights = [],
      aspirations: aspirationsIDP = [],
      mentor_optional = '',
      mentor_works_in_walmart = true
    } = IDP;

    setEvaluations({
      historial: getInitialSeveralOptionsData(assessment_history),
      results: getInitialSeveralOptionsData(assessment_insights),
      aspirations: getInitialSeveralOptionsData(aspirationsIDP),
      mentor: mentor_optional,
      mentorWorksInWalmart: mentor_works_in_walmart
    });
  };
  useEffect(() => {
    if (!loadingIDP && successIDP) {
      setInitialData();
    }
  }, [IDP, loadingIDP, successIDP]);
  const propsSearchUser = {
    placeholder: 'Ingresa nombre o busca por usuario',
    className: 'container-search-mentor',
    initValue: mentor,
    onSelect: (user) => {
      setEvaluations({
        ...evaluations,
        mentor: user.fullName
      });
    }
  };
  const handleSaveIDP = ({ status = STATUS_IDP.pending }) => {
    const newIDP = getEvaluationsFieldsToUpdate({
      IDP,
      historial,
      results,
      aspirations,
      status,
      mentor,
      mentorWorksInWalmart
    });

    showAlertBeforeSaveIDP({
      status,
      setModalMessage,
      loading: loadingSavingIDP,
      updateIDP: () => { saveIDP(newIDP); }
    });
  };

  return (
    <Form {...{
      title: headerTitle,
      description: headerDescription,
      handleSaveIDP,
      loadingSavingIDP,
      loadingIDP,
      errorIDP,
      ...getStatusIDP(IDP),
      filePath: IDP.filePath,
      IDP: {
        ...IDP,
        assessment_history: historial,
        mentor_optional: mentor,
        mentor_works_in_walmart: mentorWorksInWalmart,
        aspirations,
        assessment_insights: results
      }
    }}
    > { modalMessage.visible
      && <ModalMessage {...{
        ...modalMessage,
        closeModal: () => setModalMessage({ visible: false })
      }}/>}

      <SeveralOptions {...{
        title: EVALUATIONS_HISTORICAL_SETTINGS.title,
        description: EVALUATIONS_HISTORICAL_SETTINGS.description,
        placeholder: EVALUATIONS_HISTORICAL_SETTINGS.placeholder,
        maxOptions: EVALUATIONS_HISTORICAL_SETTINGS.maxOptions,
        maxLength: EVALUATIONS_HISTORICAL_SETTINGS.maxLength,
        listToShow: historial,
        updateValueQuestion: (value) => {
          setEvaluations({
            ...evaluations,
            historial: value

          });
        }
      }} />
      <SeveralOptions {...{
        title: EVALUATIONS_RESULTS_SETTINGS.title,
        description: EVALUATIONS_RESULTS_SETTINGS.description,
        placeholder: EVALUATIONS_RESULTS_SETTINGS.placeholder,
        maxOptions: EVALUATIONS_RESULTS_SETTINGS.maxOptions,
        maxLength: EVALUATIONS_RESULTS_SETTINGS.maxLength,
        listToShow: results,
        updateValueQuestion: (value) => {
          setEvaluations({
            ...evaluations,
            results: value

          });
        }
      }} />
      <SeveralOptions {...{
        title: ASPIRATIONS_SETTINGS.title,
        description: ASPIRATIONS_SETTINGS.description,
        placeholder: ASPIRATIONS_SETTINGS.placeholder,
        maxOptions: ASPIRATIONS_SETTINGS.maxOptions,
        maxLength: ASPIRATIONS_SETTINGS.maxLength,
        listToShow: aspirations,
        updateValueQuestion: (value) => {
          setEvaluations({
            ...evaluations,
            aspirations: value
          });
        }
      }} />

      <Question {...{
        title: 'Mentor (Opcional)',
        description: 'Nombre de tu Mentor actual en el caso de tener uno.',
        descriptionClassName: 'mb-1'

      }}>
        <p className='text-mentor-works-in-walmart'>¿Trabaja tu mentor en Walmart Chile?</p>
        <RadioComponent
          value={mentorWorksInWalmart}
          setValue={(value) => setEvaluations({
            ...evaluations,
            mentorWorksInWalmart: value
          })}
          options={MENTOR_OPTIONS}
        />
        {
          mentorWorksInWalmart
            ? <SearchUser {...propsSearchUser} />
            : <Input
              className='mt-3'
              value={mentor}
              onChange={({ target }) => {
                setEvaluations({
                  ...evaluations,
                  mentor: target.value
                });
              }}
              placeholder={'Nombre y apellido de tu mentor'}
            />
        }

      </Question>
    </Form>
  );
};

Evaluations.propTypes = {
  headerDescription: PropTypes.string,
  headerTitle: PropTypes.string
};

Evaluations.defaultProps = {
  headerDescription: '',
  headerTitle: ''
};

export default Evaluations;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, Radio, Button
} from 'antd';
import { useMutation, useLazyQuery } from '@apollo/client';
import { map, find } from 'lodash';
import { useSelector } from 'react-redux';
import InfoMessage from '../../../../shared/InfoMessage';
import SearchUsers from '../../../../shared/forms/SearchUsers';
import '../../../../../static/styles/mievolucion/myPerformance/feedback/createFeedback/styles.less';
import '../../../../../static/styles/mievolucion/myPerformance/feedback/createFeedback/searchUsers.less';
import Skills from './Skills';
import ButtonBack from './ButtonBack';
import ModalMessage from '../../../../shared/ModalMessage';
import {
  MY_FEEDBACKS, OTHERS_FEEDBACKS, VALIDATE_USER_BEFORE_CREATE_FEEDBACK
} from '../../../../../graphql/mievolucion/feedbacks/queries';
import { GET_SKILLS_BEHAVIORS_4C_BY_UCM } from '../../../../../graphql/mievolucion/skills/queries';
import Loader from '../../../../Loader';
import { listTypeFeedbacks, hasErrorsFormCreatingFeedback, isSkill } from '../../../../../helpers/myEvolution/feedback';
import { SAVE_FEEDBACK } from '../../../../../graphql/mievolucion/feedbacks/mutations';
import MLLoading from '../../../../shared/MLLoading';
import { fullCapitalizeFormat } from '../../../../../helpers/strings';
import { SEARCH_USERS } from '../../../../../graphql/user/queries';

const { TextArea } = Input;
const context = { clientName: 'myEvolution' };
const maxLengthSituation = 150;
const maxLengthFeedback = 4000;
const CreateFeedback = (props) => {
  const { feedbackType, back, form: { getFieldDecorator, validateFields } } = props;

  const [usersSelected, setUsersSelected] = useState(new Map());
  const [userToGiveFeedback, setUserToGiveFeedback] = useState(null);
  const [skillsSelected, setSkillsSelected] = useState(new Map());
  const [type, setType] = useState(null);
  const [situation, setSituation] = useState('');
  const [feedback, setFeedback] = useState('');
  const [showResultsUsers, setShowResultsUsers] = useState(false);

  const {
    additionalField4: loggedUserUCM
  } = useSelector((state) => state.userReducer.data);
  const [modalOptions, setModalOptions] = useState({
    visible: false,
    title: '',
    message: '',
    icon: '',
    type: ''
  });
  const [
    getSkills4C,
    {
      data: { listSkillsBehaviors4CByUCM: listSkills } = [],
      loading: loadingSkills,
      error: errorSkills
    }
  ] = useLazyQuery(GET_SKILLS_BEHAVIORS_4C_BY_UCM, {
    context,
    fetchPolicy: 'network-only'
  });
  useEffect(() => {
    if (feedbackType === 'Requested') {
      getSkills4C({ variables: { UCM: loggedUserUCM } });
    }
  }, [feedbackType]);

  const showMessageWaiting = () => {
    setModalOptions({
      visible: true,
      showIcon: false,
      buttons: [],
      children: <MLLoading
        title='Espera unos segundos, por favor'
      />
    });
  };
  const [
    getValidateUserBeforeCreateFeedback
  ] = useLazyQuery(VALIDATE_USER_BEFORE_CREATE_FEEDBACK, {
    context,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setModalOptions({ visible: false });
    },
    onError: (e) => {
      setModalOptions({
        visible: true,
        icon: 'icon-ml-warning',
        title: e.message || 'Por favor, intente más tarde',
        buttons: [
          {
            text: 'Cerrar',
            type: 'primary',
            onClickButton: () => setModalOptions({ ...modalOptions, visible: false })
          }
        ]
      });
    }
  });

  const closeModal = () => {
    setModalOptions({
      visible: false,
      message: '',
      title: '',
      type: 'success'
    });
  };

  const setUsers = async (myUserSelected, value, action) => {
    if (action === 'add') {
      showMessageWaiting();
      try {
        const {
          data: { validateUserBeforeCreateFeedback: isValidUser = false }
        } = await getValidateUserBeforeCreateFeedback(
          { variables: { rut: value.rut, feedbackType } }
        );
        if (isValidUser) {
          if (feedbackType === 'Given') {
            if (usersSelected.size >= 1) {
              setModalOptions({
                visible: true,
                title: 'Solo puedes escoger a una persona para dar feedback',
                icon: 'icon-ml-warning'
              });
            } else {
              setUserToGiveFeedback(value);
              setUsersSelected(myUserSelected);
              getSkills4C({ variables: { UCM: value.additionalField4 } });
              setModalOptions({ visible: false });
            }
          } else {
            setUsersSelected(myUserSelected);
            setModalOptions({ visible: false });
          }
        }
      } catch (error) {
        setUsersSelected(usersSelected);
      }
    } else {
      if (feedbackType === 'Given') {
        setSkillsSelected(new Map());
        setUserToGiveFeedback(null);
      }
      setUsersSelected(myUserSelected);
    }
  };

  const resetForm = () => {
    setUsersSelected(new Map());
    setSkillsSelected(new Map());
    setSituation('');
    setShowResultsUsers(false);
    setUserToGiveFeedback(null);
    setFeedback('');
  };
  const propsSearchUsers = {
    query: SEARCH_USERS,
    usersSelected,
    setUsersSelected: setUsers,
    label: `${feedbackType === 'Given' ? 'Dar' : 'Pedir'} a`,
    showResultsUsers,
    setShowResultsUsers,
    type: ''
  };
  const propsSkills = {
    skillsSelected,
    setSkillsSelected,
    listSkills,
    feedbackType,
    setModalOptions
  };

  const onChangeFeedbackType = ({ target }) => {
    setType(target.value);
  };

  const [createFeedback, { loading }] = useMutation(
    SAVE_FEEDBACK,
    {
      context,
      onCompleted: () => {
        resetForm();
        setModalOptions({
          visible: true,
          title: feedbackType === 'Given'
            ? '¡El feedback fue enviado con éxito!'
            : '¡Tú solicitud se ha enviado con éxito!',
          message: '',
          type: 'success',
          buttons: [
            {
              text: 'Cerrar',
              type: 'primary',
              loadingButton: loading
            }
          ]
        });
      },
      onError: (errors) => {
        setModalOptions({
          visible: true,
          title: `No se pudo ${feedbackType === 'Given' ? 'dar' : 'pedir'} el feedback.`,
          message:
          errors?.message || 'Estamos trabajando en solucionarlo, por favor intenta pedirlo de nuevo más tarde.',
          type: 'error'
        });
      }
    }
  );
  const saveFeedback = () => {
    const skills = [];
    const destinedTo = [];

    skillsSelected.forEach(({ _id, content, otherSkillName }) => {
      skills.push({ skillID: _id, content, otherSkillName });
    });
    usersSelected.forEach(({ rut }) => {
      destinedTo.push(rut);
    });
    showMessageWaiting();

    createFeedback({
      variables: {
        situation: type === 'FOUR_C' ? '' : situation,
        comments: isSkill(type) ? skills : [{
          content: feedback
        }],
        destinedTo,
        status: feedbackType === 'Given' ? 'Finished' : 'Pending',
        feedbackType,
        type,
        subject: `${feedbackType === 'Given' ? '' : 'Solicitud '} ${find(listTypeFeedbacks, (item) => item.key === type)?.subject || ''}`
      },
      refetchQueries: [
        {
          context,
          query: MY_FEEDBACKS
        },
        {
          context,
          query: OTHERS_FEEDBACKS
        }
      ]
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validateFields((err) => {
      if (!err) {
        if (!hasErrorsFormCreatingFeedback({
          skillsSelected, feedbackType, usersSelected, type, setModalOptions
        })) {
          setModalOptions({
            icon: 'icon-ml-warning',
            visible: true,
            title: feedbackType === 'Given'
              ? `¿Quieres dar este feedback a ${fullCapitalizeFormat(`${userToGiveFeedback.name} ${userToGiveFeedback.fatherLastName}`)}?`
              : '¿Estás seguro que deseas pedir el feedback?',
            buttons: [
              {
                text: 'Cancelar',
                className: 'button-border-primary'
              },
              {
                text: `Si, ${feedbackType === 'Given' ? 'dar' : 'pedir'} feedback`,
                type: 'primary',
                loadingButton: loading,
                onClickButton: () => {
                  saveFeedback();
                }
              }
            ]
          });
        }
      }
    });
  };
  if (loadingSkills) return <Loader/>;
  return (
    <div className='container-fluid create-feedback'>
      <ButtonBack back={back} />
      <div className='container-form-create-feedback'>
        {modalOptions?.visible
        && <ModalMessage
          {...modalOptions}
          closeModal={closeModal}
        />
        }
        <Form
          layout='vertical'
          name='control-ref'
          onSubmit={handleSubmit}
        >
          <p className='text-title'>{feedbackType === 'Given' ? 'Dar' : 'Pedir' } Feedback</p>
          <Form.Item className='mb-0'>
            <InfoMessage
              color='warning'
              message={feedbackType === 'Given'
                ? 'Elige a una persona para dar feedback sobre el tema que prefieras.'
                : 'Puedes pedirle feedback a una o más personas a la vez.'} />
          </Form.Item>
          <SearchUsers {...propsSearchUsers} />
          {
            !showResultsUsers
          && <div>
            <Form.Item className='mt-4 mb-4'
              label='Selecciona el tema sobre el que pides feedback:'
            >
              {getFieldDecorator('type', {
                initialValue: type,
                rules: [{ required: true, message: 'Selecciona el tema sobre el que pides feedback' }]
              })(
                <Radio.Group
                  className='ml-radio-button'
                  onChange={onChangeFeedbackType}
                >
                  {
                    map(listTypeFeedbacks, (list, i) => (list.key === 'FOUR_C' && errorSkills
                      ? null : (
                        <Radio key={i} value={list.key}>{list.label}</Radio>
                      )))
                  }
                </Radio.Group>
              )}
            </Form.Item>

            { type === 'FOUR_C'

              ? <Skills {...propsSkills} />
              : type !== null

            && <div>
              <div className='container-textarea'>
                <Form.Item
                  name='situation'
                  label='Situación o contexto:'
                >
                  {getFieldDecorator('situation', {
                    initialValue: situation,
                    rules: [{ required: true, message: 'Este campo es necesario para continuar.' }]
                  })(
                    <div>
                      <TextArea
                        value={situation}
                        maxLength={maxLengthSituation}
                        placeholder={find(listTypeFeedbacks, (item) => item.key === type)?.placeholder || ''}
                        onChange={(e) => {
                          setSituation(e.target.value);
                        }}
                      />
                      <p className='text-info-input mb-0'>{`${situation.length}/${maxLengthSituation}`}</p>
                    </div>
                  )}
                </Form.Item>
              </div>
              {feedbackType === 'Given'
              && <div className='container-textarea container-given-feedback'>
                <Form.Item
                  name='feedback'
                  label='Feedback'
                >
                  {getFieldDecorator('feedback', {
                    initialValue: feedback,
                    rules: [{ required: true, message: 'Es necesario completar este campo para poder enviar el feedback.' }]
                  })(
                    <div>
                      <TextArea
                        value={feedback}
                        maxLength={maxLengthFeedback}
                        placeholder={'Escribe el feedback.'}
                        onChange={(e) => {
                          setFeedback(e.target.value);
                        }}
                      />
                      <p className='text-info-input mb-0'>{`${feedback.length}/${maxLengthFeedback}`}</p>
                    </div>
                  )}
                </Form.Item>
              </div>
              }
            </div>
            }
          </div>
          }
          <div className='d-flex justify-content-center container-button-create-feedback'>
            <Button
              disabled={!type }
              type='primary' htmlType='submit' className='login-form-button'>
              {`${feedbackType === 'Given' ? 'Dar' : 'Pedir'} Feedback`}
            </Button>
          </div>
        </Form>
      </div>
      <ButtonBack back={back} />
    </div>
  );
};

CreateFeedback.propTypes = {
  back: PropTypes.func,
  feedbackType: PropTypes.oneOf(['Given', 'Requested']),
  form: PropTypes.object
};

CreateFeedback.defaultProps = {
  back: () => {},
  feedbackType: 'Given',
  form: {}
};
export default Form.create({ name: 'feedback-network' })(CreateFeedback);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SeveralOptions from '../Form/SeveralInputOptions';
import {
  DEVELOPMENT_EDUCATION_SETTINGS,
  DEVELOPMENT_EXPOSURE_SETTINGS,
  DEVELOPMENT_EXPERIENCE_SETTINGS,
  DEVELOPMENT_COMMENT_SETTINGS,
  getInitialSeveralOptionsData,
  getActionsFieldsToUpdate,
  showAlertBeforeSaveIDP,
  getStatusIDP,
  STATUS_IDP
} from '../../../../../helpers/myEvolution/miDesarrollo';
import ModalMessage from '../../../../shared/ModalMessage';
import TextArea from '../Form/TextArea';
import Form from '../Form';
import useGetIDP from '../../../../../hooks/myEvolution/miDesarrollo/useGetIDP';
import useSaveIDP from '../../../../../hooks/myEvolution/miDesarrollo/useSaveIDP';

const Actions = (props) => {
  const { headerDescription, headerTitle } = props;

  const [developmentActions, setDevelopmentActions] = useState({});
  const [modalMessage, setModalMessage] = useState({ visible: false });

  const {
    developmentExperience, developmentExposure, developmentEducation, additionalRemarks
  } = developmentActions;

  const {
    success: successIDP,
    loading: loadingIDP,
    IDP,
    error: errorIDP
  } = useGetIDP(
  );

  const { saveIDP, loading: loadingSavingIDP } = useSaveIDP({ setModalMessage });
  const setInitialData = () => {
    const {
      development_experience = [],
      development_exposure = [],
      development_education = [],
      additional_remarks = ''
    } = IDP;

    setDevelopmentActions({
      developmentExperience: getInitialSeveralOptionsData(development_experience),
      developmentExposure: getInitialSeveralOptionsData(development_exposure),
      developmentEducation: getInitialSeveralOptionsData(development_education),
      additionalRemarks: additional_remarks
    });
  };
  useEffect(() => {
    if (!loadingIDP && successIDP) {
      setInitialData();
    }
  }, [IDP, loadingIDP, successIDP]);

  const handleSaveIDP = ({ status = STATUS_IDP.pending }) => {
    const newIDP = getActionsFieldsToUpdate({
      IDP,
      developmentExperience,
      developmentExposure,
      developmentEducation,
      additionalRemarks,
      status
    });

    showAlertBeforeSaveIDP({
      status,
      setModalMessage,
      loading: loadingSavingIDP,
      updateIDP: () => { saveIDP(newIDP); }
    });
  };

  return (
    <Form {...{
      description: headerDescription,
      title: headerTitle,
      handleSaveIDP,
      loadingSavingIDP,
      loadingIDP,
      errorIDP,
      ...getStatusIDP(IDP),
      filePath: IDP.filePath,
      IDP: {
        ...IDP,
        development_experience: developmentExperience,
        development_exposure: developmentExposure,
        development_education: developmentEducation,
        additional_remarks: additionalRemarks
      }
    }}
    > { modalMessage.visible
      && <ModalMessage {...{
        ...modalMessage,
        closeModal: () => setModalMessage({ visible: false })
      }}/>}

      <SeveralOptions {...{
        title: DEVELOPMENT_EXPERIENCE_SETTINGS.title,
        description: DEVELOPMENT_EXPERIENCE_SETTINGS.description,
        placeholder: DEVELOPMENT_EXPERIENCE_SETTINGS.placeholder,
        maxOptions: DEVELOPMENT_EXPERIENCE_SETTINGS.maxOptions,
        maxLength: DEVELOPMENT_EXPERIENCE_SETTINGS.maxLength,
        listToShow: developmentExperience,
        updateValueQuestion: (value) => {
          setDevelopmentActions({
            ...developmentActions,
            developmentExperience: value

          });
        }
      }} />
      <SeveralOptions {...{
        title: DEVELOPMENT_EXPOSURE_SETTINGS.title,
        description: DEVELOPMENT_EXPOSURE_SETTINGS.description,
        placeholder: DEVELOPMENT_EXPOSURE_SETTINGS.placeholder,
        maxOptions: DEVELOPMENT_EXPOSURE_SETTINGS.maxOptions,
        maxLength: DEVELOPMENT_EXPOSURE_SETTINGS.maxLength,
        listToShow: developmentExposure,
        updateValueQuestion: (value) => {
          setDevelopmentActions({
            ...developmentActions,
            developmentExposure: value

          });
        }
      }} />
      <SeveralOptions {...{
        title: DEVELOPMENT_EDUCATION_SETTINGS.title,
        description: DEVELOPMENT_EXPERIENCE_SETTINGS.description,
        placeholder: DEVELOPMENT_EXPERIENCE_SETTINGS.placeholder,
        maxOptions: DEVELOPMENT_EXPERIENCE_SETTINGS.maxOptions,
        maxLength: DEVELOPMENT_EXPERIENCE_SETTINGS.maxLength,
        listToShow: developmentEducation,

        updateValueQuestion: (value) => {
          setDevelopmentActions({
            ...developmentActions,
            developmentEducation: value
          });
        }
      }} />

      <TextArea {...{
        title: DEVELOPMENT_COMMENT_SETTINGS.title,
        description: DEVELOPMENT_COMMENT_SETTINGS.description,
        maxLength: DEVELOPMENT_COMMENT_SETTINGS.maxLength,
        onChange: (value) => {
          setDevelopmentActions({
            ...developmentActions,
            additionalRemarks: value
          });
        },
        value: additionalRemarks
      }}/>

    </Form>
  );
};

Actions.propTypes = {
  headerTitle: PropTypes.string
};

Actions.defaultProps = {
  headerTitle: ''
};

export default Actions;

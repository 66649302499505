import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

export const getAgreementAPI = (item) => {
  const agreement = item.item.name;
  return axios().get(`${BFF_ENDPOINT}/agreements/${agreement}`).then((resp) => resp.data);
};

export const getAgreementsAPI = () => axios().get(`${BFF_ENDPOINT}/agreements/list/all`).then((resp) => resp.data);

export const actionTypes = {
  ALL_TITLES_CHART: 'ALL_TITLES_CHART',
  ALL_TITLES_CHART_SUCCESS: 'ALL_TITLES_CHART_SUCCESS',
  ALL_TITLES_CHART_ERROR: 'ALL_TITLES_CHART_ERROR'
};

export const getTitleChart = () => ({
  type: actionTypes.ALL_TITLES_CHART,
  payload: true
});

export const getTitleChartSuccess = (data) => ({
  type: actionTypes.ALL_TITLES_CHART_SUCCESS,
  payload: data
});

export const getTitleChartrror = (error) => ({
  type: actionTypes.ALL_TITLES_CHART_ERROR,
  payload: error
});

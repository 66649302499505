import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Upload, Button } from 'antd';
import InfoMessage from '../InfoMessage';
import '../../../static/styles/shared/MLUpload/ml-upload.less';

const MLUpload = ({
  label, name, disabled, onDisabled, callMutation, onSuccess, onError, errorMessageBottom
}) => {
  const [fileList, setFileList] = useState([]);
  const [requestErrors, setRequestErrors] = useState(null);

  const uploadProps = {
    name,
    disabled,
    multiple: false,
    fileList,
    customRequest: async (options) => {
      const { onSuccess: onRequestSuccess, onError: onRequestError, file } = options;

      try {
        const { data } = await callMutation({ file });
        const responseData = Object.values(data).pop();

        onRequestSuccess();
        onSuccess({ file, responseData });
      } catch (error) {
        const errors = error.graphQLErrors;

        onRequestError(errors);
        onError(errors);
        setFileList([]);
        setRequestErrors((errors));
      }
    },
    onChange: (info) => {
      const newFileList = [...info.fileList].slice(-1);

      if (requestErrors) setRequestErrors(null);
      setFileList(newFileList.slice(-1));
    }
  };

  return (
    <div className='ml-upload' {...{ ...(disabled ? { onClick: onDisabled } : null) }}>
      {label ? <h6>{label}</h6> : null}

      <Upload {...uploadProps}>
        {fileList.length ? null : (
          <>
            <Button>
              Seleccionar archivo
            </Button>

            No se ha seleccionado un archivo
          </>
        )}
      </Upload>

      {requestErrors ? (
        <InfoMessage
          suave
          color="error"
          message={
            <div>
              <p>No se ha podido subir el archivo seleccionado debido a las siguientes razones:</p>

              {requestErrors.map(({ message }, key) => (
                <Fragment key={key}>
                  <span key={key}>- {message}</span>
                  <br/>
                </Fragment>
              ))}

              {errorMessageBottom ? (
                <div className="ml-upload-error-message-bottom">
                  {errorMessageBottom}
                </div>
              ) : null}
            </div>
          }
        />
      ) : null}
    </div>
  );
};

MLUpload.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onDisabled: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  errorMessageBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

MLUpload.defaultProps = {
  label: '',
  name: 'file',
  disabled: false,
  onDisabled: () => null,
  onSuccess: () => null,
  onError: () => null,
  errorMessageBottom: ''
};

export default MLUpload;

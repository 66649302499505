import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import '../../../../../static/styles/mievolucion/objectives2022/ObjectivesOpenButtonModal.less';

const ObjectivesOpenButtonModal = ({
  text, onClick
}) => (
  <Button onClick={onClick} type='link'>
    <span className="objectives-open-button">{text}</span>
  </Button>
);

ObjectivesOpenButtonModal.propTypes = {
  onOpen: PropTypes.func,
  text: PropTypes.string
};

ObjectivesOpenButtonModal.defaultProps = {
  onOpen: () => null,
  text: 'Abrir Modal'
};

export default ObjectivesOpenButtonModal;

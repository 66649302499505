import { actionTypes } from '../actions/calibrationsReport';

export const initialState = {
  loadingCalibrationReport: false,
  calibrationReport: null,
  errorCalibrationReport: null
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_CALIBRATIONS_REPORT:
    return {
      ...initialState,
      loadingCalibrationReport: true,
      errorCalibrationReport: null
    };
  case actionTypes.GET_CALIBRATIONS_REPORT_SUCCESS:
    return {
      ...state,
      loadingCalibrationReport: false,
      calibrationReport: action.data,
      errorCalibrationReport: null
    };
  case actionTypes.GET_CALIBRATIONS_REPORT_ERROR:
    return {
      ...state,
      loadingCalibrationReport: false,
      errorCalibrationReport: action.error
    };
  case actionTypes.CLEAN_CALIBRATIONS_REPORT:
    return {
      ...initialState
    };
  default:
    return state;
  }
}

export default reducer;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useModal from '../../../../hooks/shared/mlModal/useModal';
import ModalMessage from '../../ModalMessage';

const MLModalMessage = ({
  type,
  icon,
  title,
  message,
  okButtonText,
  cancelButtonText,
  openButtonText,
  handleOpen,
  handleOk,
  handleCancel,
  OpenButton,
  buttons,
  refOpen,
  defaultCancel,
  defaultOk,
  loading
}) => {
  const modal = useModal();

  const { openModal, closeModal } = modal.events;
  const onOpen = () => (handleOpen || openModal)(openModal);
  const onOk = () => (handleOk || closeModal)(modal.events);
  const onCancel = () => !modal?.props?.okHandling && (handleCancel || closeModal)(modal.events);

  const defaultButtons = [
    ...(defaultCancel ? [
      {
        loadingButton: !!modal?.props?.okHandling,
        text: cancelButtonText,
        className: 'ant-btn-default',
        onClickButton: onCancel
      }
    ] : []),
    ...(defaultOk ? [
      {
        loadingButton: !!modal?.props?.okHandling,
        text: okButtonText,
        type: 'primary',
        onClickButton: onOk
      }
    ] : [])
  ];

  const modalButtons = (buttons?.length || !buttons) ? buttons : defaultButtons;

  useEffect(() => {
    refOpen(() => openModal);
  }, []);

  return (
    <>
      {OpenButton && <OpenButton onClick={onOpen} text={openButtonText}/>}

      <ModalMessage {...{
        loading,
        type,
        icon,
        title,
        message,
        closeModal: onCancel,
        buttons: modalButtons,
        ...modal.props
      }}/>
    </>
  );
};

const propTypes = {
  defaultCancel: PropTypes.bool,
  defaultOk: PropTypes.bool,
  OpenButton: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  okButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  openModal: PropTypes.func,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  buttons: PropTypes.array,
  refOpen: PropTypes.func,
  loading: PropTypes.bool
};

MLModalMessage.propTypes = propTypes;

const defaultProps = {
  defaultCancel: false,
  defaultOk: true,
  OpenButton: null,
  type: 'error',
  title: 'Ha ocurrido un error repentino',
  message: '',
  okButtonText: 'Entendido',
  cancelButtonText: 'Cancelar',
  openModal: null,
  handleOk: null,
  handleCancel: null,
  buttons: [],
  refOpen: () => null,
  loading: false
};

MLModalMessage.defaultProps = defaultProps;

export default MLModalMessage;

import React from 'react';
import PropTypes from 'prop-types';

import { useDrop } from 'react-dnd';

const Dropable = (props) => {
  const {
    x, y, accept, children, classes
  } = props;

  const [{ canDrop, isOver }, drop] = useDrop({
    accept,
    canDrop: (item) => item.currentX !== x && item.currentY === y,
    drop: () => ({ x, y }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const getColor = () => {
    if (!isOver && canDrop) return '#DAECE1';
    if (isOver && canDrop) return '#D1E3F2';
    return '';
  };
  return (
    <td ref={drop}
      style={{ backgroundColor: getColor() }}
      className={classes}
    >
      <div className="td-children-wrapper">
        {children}
      </div>
    </td>
  );
};

Dropable.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  accept: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  classes: PropTypes.string
};

Dropable.defaultProps = {
  x: NaN,
  y: NaN,
  accept: '',
  children: <></>,
  classes: ''
};

export default Dropable;

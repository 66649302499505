import React from 'react';
import {
  find, map, omit
} from 'lodash';
import { formatDateCard } from '../dates';
import PendingCircle from '../../components/solicitudes/shared/PendingCircle';
import { fullCapitalizeFormat } from '../strings';
import {
  VALIDATED, COMMUNICATED, REJECTED, DRAFT
} from './evaluations';

export const isEvaluationStarted = (lastStepEvaluation) => {
  if (!lastStepEvaluation) return false;
  if (lastStepEvaluation.component === 'SKILLS' && lastStepEvaluation.step === 0) {
    return false;
  }
  return true;
};
export const shapeSkillsToSend = (skills) => {
  let newSkills = [];

  newSkills = map(skills, (skill) => {
    let { categorySelected = {} } = skill || {};
    skill = omit(skill, '__typename');
    categorySelected = omit(categorySelected, '__typename');

    return ({
      ...skill,
      behaviors: map(skill.behaviors, (behavior) => omit(behavior, '__typename')),
      categorySelected: {
        _id: categorySelected._id,
        name: categorySelected.name,
        description: categorySelected.description,
        finalSkillDescription: categorySelected.finalSkillDescription,
        skillDescription: categorySelected.skillDescription,
        score: categorySelected.score
      }
    });
  });

  return newSkills;
};
export const showErrorMessage = (setModalMessage = () => {}) => {
  setModalMessage({
    visible: true,
    title: 'No se ha podido enviar la evaluación',
    message: 'Ha ocurrido un problema al intentar enviar la evaluación, por favor inténtalo de nuevo más tarde.',
    type: 'error'
  });
};

const getTypeEvaluation = (value) => {
  switch (value) {
  case '1':
    return 'RETRO';
  case '2':
    return 'EDD';
  default:
    return null;
  }
};

const getIsUserEnabled = (value) => {
  switch (value) {
  case '1':
    return true;
  case '0':
    return false;
  default:
    return false;
  }
};

export const getInfoEvaluation = (value) => {
  if (value) {
    const data = value.split(',');
    if (data.length === 2) {
      return { typeEvaluation: getTypeEvaluation(data[0]), isEnabled: getIsUserEnabled(data[1]) };
    }
  }
  return { typeEvaluation: '', isEnabled: false };
};
export const parsedDataHistoricalMyEvaluations = (list) => {
  const res = [];

  if (!list) return res;

  map(list, (item, i) => {
    const evaluationCommunicated = item.status === COMMUNICATED;
    const date = formatDateCard(item.createdAt);
    const showButtonOpinion = evaluationCommunicated && !item.hasOpinion;
    res.push({
      number: list.length - i,
      id: item._id,
      title: `Retroalimentación Nº ${list.length - i}`,
      date,
      showDate: true,
      showAvatar: false,
      evaluation: { ...item, date },
      status: item.status,
      showButtonOpinion,
      customStatus: <></>,
      leftTitle: showButtonOpinion ? <PendingCircle /> : null
    });
  });

  return res;
};

export const getSuccessTitleByType = (status, fullNameEvaluated, evaluatorNameAndSurname) => {
  switch (status) {
  case COMMUNICATED:
    return `¡Se comunicó con éxito la evaluación a ${fullCapitalizeFormat(fullNameEvaluated)}`;
  case VALIDATED:
    return 'Se aprobó la evaluación con éxito.';
  case REJECTED:
    return `Se envió la evaluación rechazada a ${evaluatorNameAndSurname}`;
  case DRAFT:
    return 'La evaluación ha sido guardada exitosamente';
  default:
    return '';
  }
};

export const getErrorsToShowInModal = (status) => {
  let title = '';
  let complementMessage = '';
  switch (status) {
  case COMMUNICATED:
    title = 'No se ha podido comunicar la evaluación';
    complementMessage = 'comunicar';
    break;
  case VALIDATED:
    title = 'No se ha podido enviar la aprobación de la evaluación';
    complementMessage = 'aprobar';
    break;
  case REJECTED:
    title = 'No se ha podido enviar el motivo de rechazo';
    complementMessage = 'rechazar';
    break;
  case DRAFT:
    title = 'La evaluación no se ha podido guardar';
    complementMessage = 'guardar';
    break;
  default:
    title = '';
  }
  const message = `Ha ocurrido un problema al intentar ${complementMessage} la evaluación, por favor inténtalo de nuevo más tarde.`;

  return { title, message };
};

export const noFoundPropsListHistorical = (listTab) => {
  const img = 'web/mi-evolucion/servicios/inicio/sin-evaluacion-de-desempeño.png';
  const key = listTab ? listTab.key : '';

  switch (key) {
  case 1:
    return {
      img,
      title: 'Sin evaluaciones',
      description: 'Sin evaluaciones'
    };
  case 2:
    return {
      img,
      title: 'Sin evaluaciones pendientes por validar'
    };
  default:
    return {
      img
    };
  }
};

export const parsedDataMyTeamSubordinates = (list) => {
  const res = [];

  if (!list) return res;

  map(list, (item) => {
    const {
      fatherLastName, name,
      dni, fullName, position, hasNotification, evaluationType
    } = item;
    res.push({
      rut: dni,
      title: fullCapitalizeFormat(fullName),
      subTitle: position,
      leftTitle: hasNotification ? <PendingCircle /> : null,
      ...item,
      fullName: fullCapitalizeFormat(fullName),
      firstName: fullCapitalizeFormat(name),
      fatherLastName: fullCapitalizeFormat(fatherLastName),
      evaluationType
    });
  });

  return res;
};
export const parsedDataListToValidate = (list) => {
  const res = [];
  if (!list) return res;
  map(list, (item) => {
    const {
      user: {
        rut: rutUser,
        fatherLastName, name,
        fullName,
        position,
        evaluationType,
        dateOld
      },
      toValidate
    } = item;

    res.push({
      rut: rutUser,
      title: fullName,
      subTitle: position,
      leftTitle: toValidate ? <PendingCircle /> : null,
      ...item,
      fullName,
      dateOld,
      firstName: fullCapitalizeFormat(name),
      fatherLastName: fullCapitalizeFormat(fatherLastName),
      evaluationType
    });
  });

  return res;
};

export const modalMessagesFinishedEvaluation = ({
  strengths,
  opportunitiesToImprove,
  handleFinishedEvaluation,
  skillsEvaluation
}) => {
  const message = '';
  let title = '';
  let buttons = [
    {
      text: 'Cerrar',
      type: 'primary'
    }
  ];

  const invalidSkills = find(skillsEvaluation, (skill) => !skill.category);

  const emptyStrengths = strengths.trim().length === 0;
  const emptyOpportunities = opportunitiesToImprove.trim().length === 0;
  if (invalidSkills) {
    title = 'Hay competencía(s) donde no les has asignado categoría';
  } else if (emptyStrengths || emptyOpportunities) {
    title = 'Ingresa sus oportunidades de mejora para continuar.';
    if (emptyStrengths && emptyOpportunities) {
      title = 'Ingresa sus fortalezas y oportunidades de mejora para continuar.';
    } else if (emptyStrengths) {
      title = 'Ingresa sus fortalezas para continuar.';
    }
  } else {
    title = '¿Estás seguro que desea enviar la evaluación a validación?';
    buttons = [
      {
        text: 'cancelar',
        className: 'button-border-primary'
      },
      {
        text: 'Sí, enviar',
        type: 'primary',
        onClickButton: handleFinishedEvaluation
      }
    ];
  }
  return { message, title, buttons };
};

export const RETRO_URL = '/mi-evolucion/retroalimentación';

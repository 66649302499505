import React, { useState, useRef } from 'react';
import { filter } from 'lodash';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import RetroCard from '../shared/Retro/RetroCard';
import Evaluation from '../shared/Retro/EvaluationSummary';
import MLModalFullScreen from '../../shared/MLModalFullScreen';
import ModalMessage from '../../shared/ModalMessage';
import {
  showErrorMessage
} from '../../../helpers/myEvolution/retro';
import { SAVE_OPINION } from '../../../graphql/mievolucion/retro/mutations';
import OpinionEvaluationRETRO from './ModalOpinion';
import { GET_NOTIFICATIONS } from '../../../graphql/notifications/queries';
import { COMMUNICATED, VIEW_AS_EVALUATED, getEvaluatorAndValidator } from '../../../helpers/myEvolution/evaluations';

const clientName = 'myEvolution';

const EvaluationSelected = (props) => {
  const {
    listQuestions,
    refetchQueries,
    evaluation: currentEvaluation
  } = props;
  const [evaluation, setEvaluation] = useState(currentEvaluation);
  const [bigModal, setBigModal] = useState({ visible: false });

  const {
    _id: evaluationID,
    status,
    step: stepEvaluation, status: statusEvaluation,
    hasOpinion,
    createdAt,
    evaluator,
    info
  } = evaluation;

  const userHasEvaluationCommunicated = statusEvaluation === COMMUNICATED;

  const modalRef = useRef();

  const [modalMessage, setModalMessage] = useState({
    visible: false,
    title: '',
    message: '',
    icon: '',
    type: ''
  });

  const [saveRetroOpinion, { loading: loadingSavingOpinion }] = useMutation(
    SAVE_OPINION,
    {
      context: { clientName },
      onError: () => {
        showErrorMessage(setModalMessage);
      },
      refetchQueries: [
        {
          query: GET_NOTIFICATIONS
        },
        ...refetchQueries
      ]
    }
  );

  const closeBigModal = () => {
    setBigModal({ visible: false });
  };

  const closeModal = () => {
    setModalMessage({
      visible: false,
      message: '',
      title: '',
      type: 'success'
    });
  };
  const sendOpinion = (answers) => {
    const questions = filter(answers.map((item) => (
      {
        question:
        {
          _id: item._id,
          name: item.name
        },
        value: item.value
      }
    )), (item) => item.value);

    saveRetroOpinion({
      variables: { _id: evaluationID, opinion: questions }

    }).then((response) => {
      if (response) {
        const { data } = response;
        setEvaluation(data.saveRetroOpinion);
      }
      setBigModal({ visible: false });
      setModalMessage({
        visible: true,
        title: '¡Tu opinión se envió con éxito!',
        type: 'success'
      });
    });
  };

  return (
    <div className='container-evaluation-detail'>
      {modalMessage.visible
      && <ModalMessage
        zIndex={2001}
        {...modalMessage}
        closeModal={closeModal}
      />
      }
      <RetroCard
        {...{
          status,
          step: stepEvaluation,
          contentData: getEvaluatorAndValidator(evaluation.evaluator, evaluation.validator),
          date: createdAt,
          from: 'myEvaluation',
          info,
          viewAs: VIEW_AS_EVALUATED
        }}
      >

        {
          userHasEvaluationCommunicated

            && <Evaluation
              classes="mt-3"
              from='myEvaluation'
              evaluation={evaluation}
            />

        }
        {
          status === COMMUNICATED && !hasOpinion
          && <div className='container-button-opinion'>

            <Button
              className=''
              onClick={() => setBigModal({ visible: true })}
              type='primary'>
              Opinar sobre mi evaluación
            </Button>
          </div>
        }
        {
          bigModal.visible
          && <MLModalFullScreen
            zIndex={2000}
            ref={modalRef}
            visible={bigModal.visible}
            closeModal= {closeBigModal}
            title={'Opinión Evaluación'}
          >

            <OpinionEvaluationRETRO
              message='Por favor entréganos tu opinión acerca de la conversación de desempeño que tuviste recientemente con tu jefatura. En una escala de 1 a 5 por favor evalúa cada uno de los siguientes puntos.'
              loading={loadingSavingOpinion}
              sendOpinion={sendOpinion}
              questions={listQuestions}
              closeOpinion={closeBigModal}
              evaluator={evaluator}
            />

          </MLModalFullScreen>
        }
      </RetroCard>
    </div>
  );
};

EvaluationSelected.propTypes = {
  evaluation: PropTypes.object,
  listQuestions: PropTypes.array,
  refetchQueries: PropTypes.array
};

EvaluationSelected.defaultProps = {
  listQuestions: [],
  refetchQueries: [],
  evaluation: {}
};
export default EvaluationSelected;

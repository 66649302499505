import React from 'react';
import '../../static/styles/shared/errorMessage.less';
import PropTypes from 'prop-types';

const ErrorMessage = (props) => {
  const { message, classes } = props;
  return (
    <div className={`error-message ${classes}`}>
      <i
        className='icon-me-error'
      />
      <p>{message}</p>
    </div>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
  classes: PropTypes.string
};

ErrorMessage.defaultProps = {
  message: 'Se ha producido un error. Por favor inténtalo de nuevo más tarde.',
  classes: ''
};

export default ErrorMessage;

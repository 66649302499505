import React from 'react';
import { useSelector } from 'react-redux';
import InfoMessage from '../../shared/InfoMessage';
import { IDP_MORE_DETAIL_LINK, UCMs_TO_FILL_IDP_IN_ENGLISH } from '../../../helpers/myEvolution/miDesarrollo';

const MainMessage = () => {
  const {
    nameUCM
  } = useSelector((state) => state.userReducer.data);
  return (
    <div>
      <InfoMessage
        color='info-message-suave'
        suave={true}
        classes='message-manage-IDP'
        message={
          <span>
        Cuando completes las secciones, tienes que cerrar el plan Mi desarrollo (IDP) presionando el botón “Finalizar IDP“. Tu plan finalizado será enviado a tu líder. <span className='text-bold'> Importante: cada vez que edites tu plan, se activará el botón para que puedas actualizar y cerrar de nuevo tu IDP. </span><a href={IDP_MORE_DETAIL_LINK} target='_blank' rel='noopener noreferrer'>Si quieres conocer más del IDP ingresa aquí.</a>
          </span>
        }
      />
      {
        UCMs_TO_FILL_IDP_IN_ENGLISH.includes(nameUCM)
      && <InfoMessage
        color='info-message-suave'
        suave={true}
        classes='message-manage-IDP message-fill-IDP-in-english'
        message={
          <span>
        Debido a tu cargo debes ingresar la información en <b>inglés</b>.
          </span>
        }
      />
      }
    </div>

  );
};

export default MainMessage;

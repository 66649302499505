import React, { useState, useEffect } from 'react';
import {
  useRouteMatch
} from 'react-router-dom';
import '../../../static/styles/shared/MLHistorical/historical.less';
import { find, isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import HistoricalList from '../../shared/MLHistorical/list/index';
import MLHistorical from '../../shared/MLHistorical';
import getURLParams from '../../../helpers/routing/getURLParams';
import Error from '../../shared/Error';
import EvaluationSelected from './EvaluationSelected';
import {
  GET_MY_RETROS,
  GET_LIST_OPINION
} from '../../../graphql/mievolucion/retro/queries';
import { GET_SETTING } from '../../../graphql/settings/queries';
import MLNotFound from '../../shared/MLNotFound';
import { parsedDataHistoricalMyEvaluations, RETRO_URL } from '../../../helpers/myEvolution/retro';
import { getSettingValue } from '../../../helpers/settings';

const clientName = 'myEvolution';
const Historical = () => {
  const [evaluationSelected, setEvaluationSelected] = useState(null);
  const query = getURLParams();
  const { path } = useRouteMatch();
  const id = query.get('id');

  const {
    loading: loadingMyRetros,
    data: dataMyRetros,
    error: errorMyRetros, refetch: refetchMyRetros
  } = useQuery(GET_MY_RETROS,
    {
      context: { clientName },
      fetchPolicy: 'network-only'
    });

  const {
    loading: loadingQuestions,
    data: dataQuestions, error: errorQuestions,
    refetch: refetchQuestions
  } = useQuery(GET_LIST_OPINION,
    {
      context: { clientName }
    });

  const {
    loading: loadingEnableCommunication,
    data: enableCommunication
  } = useQuery(GET_SETTING,
    {
      context: { clientName },
      variables: { key: 'ENABLE_RETRO_COMMUNICATION' }
    });
  const { getSetting: setting = {} } = enableCommunication || {};
  const isEnableCommunication = getSettingValue(setting?.value);

  const { listQuestionsRetroOpinion: listQuestions = [] } = dataQuestions || {};
  const { myRetros = [] } = dataMyRetros || {};

  const parsedData = (() => parsedDataHistoricalMyEvaluations(
    myRetros, isEnableCommunication
  ))();

  const onSelectItem = () => {
    if (!id) {
      setEvaluationSelected(null);
    } else {
      const newItem = find(parsedData, (item) => item.id === id);
      if (newItem) {
        setEvaluationSelected(newItem);
      }
    }
  };

  useEffect(() => {
    if (!loadingMyRetros && id) {
      onSelectItem(id);
    } else {
      onSelectItem(null);
    }
  }, [id, loadingMyRetros]);

  const getURL = (item) => {
    const complement = `retroalimentación-Nº-${item.number}`;
    if (path === RETRO_URL) {
      return `${path}/historial/${complement}?id=${item.id}`;
    }
    if (path === `${RETRO_URL}`) {
      return `${path}/historial/${complement}?id=${item.id}`;
    }
    if (path.match(`${RETRO_URL}/historial`)) {
      return `${path}/${complement}?id=${item.id}`;
    }

    return null;
  };
  const leftScrollComponents = [
    (!loadingMyRetros && !loadingEnableCommunication && isEmpty(parsedData))
      ? (
        <MLNotFound key={1} {...{
          img: 'web/mi-evolucion/servicios/inicio/sin-evaluacion-de-desempeño.png',
          title: 'Sin evaluaciones',
          description: 'No posees ninguna evaluación'
        }} />
      ) : (
        <HistoricalList
          key={2}
          loading={loadingMyRetros || loadingQuestions}
          data={parsedData}
          getActiveItem={(listItem) => evaluationSelected && listItem.id === evaluationSelected.id}
          getItemURL={(listItem) => getURL(listItem)}
        />)
  ];
  return (
    <div>
      {errorMyRetros
        ? <Error
          reload={ () => (errorMyRetros
            ? refetchMyRetros() : errorQuestions && refetchQuestions())}
        />
        : <MLHistorical
          leftScrollComponents={leftScrollComponents}
        >

          {
            id && evaluationSelected && evaluationSelected.id === id
              && <EvaluationSelected
                listQuestionsWorkerOpinion={[]}
                {...evaluationSelected}
                listQuestions={listQuestions}
                refetchQueries = {[
                  {
                    context: { clientName },
                    query: GET_MY_RETROS
                  }
                ]}
              />

          }
        </MLHistorical>
      }

    </div>
  );
};

export default Historical;

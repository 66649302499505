import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { find } from 'lodash';
import { useWindowWidthSize } from '../../../../helpers/browser';
import Tabs from '../../../shared/MLTabs/MLSecondaryTabsWithChildren';
import CreateFeedback from './CreatedFeedback';
import '../../../../static/styles/mievolucion/myPerformance/feedback/styles.less';
import ButtonCreateFeedback from './ButtonCreateFeedback';

const Feedback = ({ children }) => {
  const width = useWindowWidthSize();

  const { pathname, search } = useLocation();
  const [createFeedback, setCreateFeedback] = useState({ active: false, type: 'Given' });

  const tabs = [
    {
      title: 'Hacia mí',
      key: '/mi-evolucion/mi-desempeño/feedback/hacia-mí',
      children
    },
    {
      title: 'Hacia otros',
      key: '/mi-evolucion/mi-desempeño/feedback/hacia-otros',
      children
    },
    {
      title: 'Preguntas Frecuentes',
      key: '/mi-evolucion/mi-desempeño/feedback/preguntas-frecuentes',
      children
    }
  ];

  const hideTabs = (() => {
    if (width <= 575) {
      switch (pathname) {
      case '/mi-evolucion/mi-desempeño/feedback/historial/feedback-para-mi':
      case '/mi-evolucion/mi-desempeño/feedback/historial/feedback-hacia-otros': {
        const queryParams = new URLSearchParams(search);
        return !!queryParams.get('id');
      }
      default:
      }
    }

    return false;
  })();

  const activeTab = (find(tabs, ({ key }) => pathname.includes(key)) || {});

  const handleCreateFeedback = (e, type) => {
    e.preventDefault();
    setCreateFeedback({ active: true, type });
  };
  return (
    <div className='container-feedback'>
      <div>
        { !createFeedback.active
          ? <div className='container-buttons-create-feedback'>
            <ButtonCreateFeedback
              onClick={ (e) => handleCreateFeedback(e, 'Requested')}
              type='Requested'
              className='button-requested' />
            <ButtonCreateFeedback
              onClick={ (e) => handleCreateFeedback(e, 'Given')}
              type='Given'
              className='button-given'/>
          </div>
          : <div>
            <CreateFeedback
              back={() => { setCreateFeedback({ active: false }); }}
              feedbackType={createFeedback.type} />
          </div>
        }

        { !createFeedback.active
          && <Tabs
            className='mb-0 pt-0'
            classNameTabPane='mb-0 pt-0'
            options={tabs}
            defaultActiveKey={activeTab.key}
            activeTab={activeTab.key}
            hideTabs={hideTabs}
          />
        }
      </div>
    </div>
  );
};

export default Feedback;

import { lazy } from 'react';

const Notifications = lazy(() => import('../../containers/notifications'));
const PendingRequets = lazy(() => import('../../containers/notifications/pending-requests'));

export default [
  {
    exact: true,
    path: '/notificaciones',
    section: 'Notificaciones',
    component: Notifications
  },
  {
    exact: true,
    path: '/notificaciones/acciones-pendientes',
    section: 'Notificaciones -> Acciones Pendientes',
    component: PendingRequets
  }
];

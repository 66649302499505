import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Select, Input
} from 'antd';
import { map } from 'lodash';
import { Item } from '../Form';

const { Option } = Select;

const Area = (props) => {
  const {
    updateArea,
    index,
    area,
    listOptionsinterestAreasDetail
  } = props;
  const onChangeArea = (value) => {
    updateArea({
      area: {
        _id: value
      }
    },
    index);
  };
  const refAreaSelector = useRef();
  return (
    <div className='form-area'>
      <div className='row'>
        <Item
          label={`Area ${index + 1}`}
          className='col-12 col-md-12 col-lg-6 pb-4'
        >
          <Select
            ref={refAreaSelector}
            {
              ...(area._id ? { defaultValue: area._id } : null)
            }
            {
              ...(area._id ? { value: area._id } : null)
            }
            showSearch
            allowClear
            placeholder="Escribe una opción. Ej: Administración"
            notFoundContent={(<div
              className='container-select-other-area-detail'
              onClick={(e) => {
                e.preventDefault();
                if (refAreaSelector?.current?.rcSelect?.setOpenState) {
                  refAreaSelector.current.rcSelect.setOpenState(false);
                }
                onChangeArea('OTHER');
              }}>
              <p>Otra</p>
            </div>)}
            onChange={(value) => onChangeArea(value)}
            filterOption={ (input, option) => (
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            }
          >
            {map(listOptionsinterestAreasDetail, (item) => (
              <Option key={item._id} value={item._id}>{item.name}</Option>
            ))}
            <Option value={'OTHER'}>Otra</Option>
          </Select>
        </Item>

      </div>
      { area?._id === 'OTHER'

      && <div className='row'>
        <Item
          label='¿Cuál?'
          className='col-12 col-md-12 col-lg-6 pb-4'
        >
          <Input
            onChange={({ target }) => {
              updateArea({
                area: {
                  name: target.value,
                  _id: 'OTHER'
                }
              },
              index);
            }}
            placeholder='Ingresa otra área de tu interés' />

        </Item>
      </div>
      }
    </div>
  );
};
Area.propTypes = {
  updateArea: PropTypes.func,
  index: PropTypes.number,
  interestAreas: PropTypes.array,
  area: PropTypes.object,
  listOptionsinterestAreasDetail: PropTypes.array
};

Area.defaultProps = {
  updateArea: () => {},
  index: 0,
  interestAreas: [],
  area: {},
  listOptionsinterestAreasDetail: []
};

export default Area;

import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { filter, find, isBoolean } from 'lodash';
import { useSelector } from 'react-redux';
import {
  GET_LIST_QUESTIONS
} from '../../../../graphql/questions/queries';
import {
  SAVE_PULSE_SURVEY
} from '../../../../graphql/mievolucion/myPerformance/mutations';
import Questions from '../../../shared/MLOpinion/Questions';
import ButtonSave from '../../../shared/MLOpinion/ButtonSave';
import MLError from '../../../shared/Error';
import Loading from '../../../../containers/loading';
import useOpenModal from '../../../../hooks/shared/mlModal/useOpenModal';
import ModalInfo from '../../../shared/MLModal/Message';
import MLAntdMModalFullScreen from '../../../shared/MLAntdModalFullScreen';
import YesNoInput from '../../../shared/MLOpinion/YesNoInput';
import '../../../../static/styles/shared/MLOpinion/styles.less';
import InfoMessage from '../../../shared/InfoMessage';
import { fullCapitalizeFormat } from '../../../../helpers/strings';

const context = { clientName: 'myEvolution' };

const PulseSurvey = ({
  performanceID,
  closeModalOpinion,
  isOpenModalOpinion,
  surveyType
}) => {
  const { open: openModalInfo, refOpen: refModalInfo } = useOpenModal();

  const {
    userReducer = {}
  } = useSelector((state) => ({
    userReducer: state.userReducer
  }));
  const { data: loggedUser } = userReducer || {};
  const { linealBoss } = loggedUser || {};

  const bossName = linealBoss ? `${linealBoss.name} ${linealBoss.secondName} ${linealBoss.fatherLastName} ${linealBoss.motherLastName}`.trim() : '';

  const {
    loading: loadingQuestions,
    data: dataQuestions, error: errorQuestions,
    refetch: refetchQuestions
  } = useQuery(GET_LIST_QUESTIONS,
    {
      context,
      variables: { type: surveyType }
    });

  const { listQuestions = [] } = dataQuestions || {};

  const [questionsAndAnswers, setAnswers] = useState([]);
  const [userHadConversation, setUserHadConversation] = useState(null);

  useEffect(() => {
    if (!loadingQuestions && listQuestions.length) {
      setAnswers(listQuestions.map((question) => (
        { ...question, value: null }
      )));
    }
  }, [listQuestions, loadingQuestions]);

  const onCancel = () => {
    openModalInfo({
      icon: 'icon-ml-warning',
      visible: true,
      title: '¿Estás seguro que deseas finalizar esta acción?',
      buttons: [
        {
          text: 'No, cancelar',
          className: 'button-border-primary'
        },
        {
          text: 'Sí, salir',
          type: 'primary',
          onClickButton: closeModalOpinion
        }
      ]
    });
  };

  const [savePulseSurvey, {
    loading: updateLoading
  }] = useMutation(SAVE_PULSE_SURVEY, {
    context,
    onCompleted: () => {
      openModalInfo({
        title: 'Las respuestas se enviaron con éxito, muchas gracias.',
        icon: 'icon-me-check success-icon-color',
        defaultOk: false,
        buttons: [
          {
            text: 'Cerrar',
            type: 'primary',
            onClickButton: closeModalOpinion
          }
        ]
      });
    },
    onError: () => {
      openModalInfo({
        type: 'error',
        title: 'No se pudo enviar tu opinión. Disculpa las molestias.'

      });
    }
  });

  const sendOpinion = (answers) => {
    const questions = answers.map((item) => (
      { questionId: item._id, question: item.name, value: item.value }
    ));
    savePulseSurvey({
      variables: {
        userHadConversationWithBoss: userHadConversation,
        performanceID,
        questions: filter(questions,
          (item) => item.value && item.value !== null && item.value.trim().length > 0)
      }
    });
  };

  if (loadingQuestions) {
    return <Loading />;
  }
  if (errorQuestions) {
    return <MLError
      reload={() => refetchQuestions({ type: surveyType })}/>;
  }
  const handleSuccessOk = async (events) => {
    await events.closeModal();
  };

  const disabledButton = () => {
    if (userHadConversation) {
      return !find(questionsAndAnswers,
        (item) => item.value !== null && item.value.trim().length > 0);
    }
    if (isBoolean(userHadConversation)) {
      return false;
    }

    return true;
  };
  return (
    <div>
      <MLAntdMModalFullScreen
        {...{
          title: 'Conversación de Desempeño',
          visible: isOpenModalOpinion,
          closeModal: onCancel,
          closable: false
        }}
      >
        <div className='d-flex justify-content-center'>

          <div className='col-12 col-sm-12 col-md-8 col-lg-6 box-evaluation-opinion'>
            <InfoMessage
              color='warning'
              message={`Esta información nos servirá para acompañarte en tu desarrollo y mejorar tu experiencia trabajando en la compañía. No compartiremos esta información con ${fullCapitalizeFormat(bossName)}.`}
            />

            <div className='box-questions'>
              <YesNoInput
                {...{
                  yesValue: true,
                  noValue: false,
                  question: { name: '¿Tuviste la conversación de desarrollo con tu líder?' },
                  onChange: (e) => {
                    setUserHadConversation(e.target.value);
                  }
                }}/>
            </div>

            { userHadConversation
        && <Questions {...{
          questionsAndAnswers,
          setAnswers,
          loading: updateLoading,
          sendOpinion,
          closeOpinion: closeModalOpinion,
          questions: listQuestions,
          bossName
        }}/>
            }
            <ButtonSave {
              ...{
                loading: updateLoading,
                disabledButton: disabledButton(),
                saveOpinion: () => sendOpinion(questionsAndAnswers),
                labelButton: 'Enviar respuestas'
              }
            } />
          </div>
        </div>
        <ModalInfo {...{
          refOpen: refModalInfo, type: 'success', handleOk: handleSuccessOk
        }}/>

      </MLAntdMModalFullScreen>
    </div>
  );
};

PulseSurvey.propTypes = {
  closeModalOpinion: PropTypes.func,
  performanceID: PropTypes.string,
  isOpenModalOpinion: PropTypes.bool
};

PulseSurvey.defaultProps = {
  closeModalOpinion: () => {},
  performanceID: null,
  isOpenModalOpinion: false
};

export default PulseSurvey;

/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import { find } from 'lodash';
import StepContainer from './StepContainer';
import Step from './Step';
import Sliders from './Sliders';
import useStatusSlider from '../../../hooks/shared/mlStep/useStatusSlider';
import '../../../static/styles/shared/MLStep/styles.less';

const Status = ({
  title,
  step,
  status,
  stepLabels,
  isCompleted,
  classes,
  finishedMark,
  closedMark
}) => {
  const statusSlider = useStatusSlider({ data: stepLabels });
  const selectedStep = find(stepLabels, (item) => item.value === step);

  return (
    <div className={`box-container-status ${classes} ${isCompleted && 'is-completed'}`}>
      {title && <p className='box-container-status-title'>{title}</p>}

      <div className='ml-status-steps-container'>
        <div className='ml-status-steps' style={statusSlider.style}>
          <div className='separator'/>
          <div className='ml-status-labels d-flex justify-content-between align-items-center'>
            { stepLabels.map((data, i) => {
              const showIconCheck = finishedMark && (selectedStep.numberStatus - 1 > i
             || (isCompleted && selectedStep.numberStatus === 3));
              const { modalWithFixedLayout } = data;

              return (
                <Row key={i} {...{ type: 'flex' }}>
                  <StepContainer {...{ modalWithFixedLayout }}>
                    <Step {...{
                      i, selectedStep, status, data, showIconCheck, isCompleted, closedMark
                    }}/>
                  </StepContainer>
                </Row>
              );
            })}
          </div>
        </div>

        <Sliders {...statusSlider.props}/>
      </div>
    </div>
  );
};

Status.propTypes = {
  step: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool,
  finishedMark: PropTypes.bool,
  closedMark: PropTypes.bool,
  classes: PropTypes.string
};

Status.defaultProps = {
  step: '',
  status: '',
  isCompleted: false,
  finishedMark: true,
  closedMark: true,
  classes: ''
};
export default Status;

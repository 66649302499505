import React from 'react';
import { useDispatch } from 'react-redux';
import AdminFileListReportsSockets from '../../../../shared/list/AdminFileListReportsSockets';
import {
  getEDPReport
} from '../../../../../redux/actions/admin';
import { yearCurrentEvaluationPotential } from '../../../../../helpers/myEvolution/evaluations';
import useReport, { REPORT_NAMES } from '../../../../../hooks/reports/useReport';

const EDPReports = () => {
  const dispatch = useDispatch();
  const edpEvaluationsReports = useReport(REPORT_NAMES.EDP_EVALUATIONS);

  const reportsToShow = [
    {
      title: 'Evaluación de Potencial',
      report: edpEvaluationsReports,
      startFetch: () => dispatch(getEDPReport(yearCurrentEvaluationPotential)),
      isAsync: true
    }
  ];
  return (
    <>
      {reportsToShow.map((item, index) => <AdminFileListReportsSockets
        key={index}
        {...item}
      />)}
    </>
  );
};

export default EDPReports;

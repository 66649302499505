import { actionTypes } from '../actions/faq';

export const initialState = {
  faqs: null
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_FAQS_SUCCESS:
    return {
      ...state,
      ...{ faqs: action.data }
    };

  default:
    return state;
  }
}

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../../containers/loading';
import InfoCard from '../shared/cards/InfoCard';
import Error from '../shared/Error';
import InfoMessage from '../shared/InfoMessage';
import { getEnvVar } from '../../helpers/commons';

const REACT_APP_API_REPORTS = getEnvVar('REACT_APP_API_REPORTS');

export const NoConnectionMessage = () => (<InfoMessage
  classes='mb-3'
  color='warning'
>
  <span>
    La conexión con el servicio de reportes aún no ha iniciado,
    por favor espera o contacta al administrador.
  </span>
</InfoMessage>);

const Reports = (props) => {
  const {
    loading, files, error, reload
  } = props;

  const formatTitle = (title) => (title.replace(/_/g, ' ').toLowerCase());
  const getFileURL = (string) => {
    const result = `${REACT_APP_API_REPORTS}/getFile?file=${string}`;
    return result;
  };
  const getItems = () => (
    <div className='container-fluid'>
      <div className={'row'}>
        {files.map((item, i) => (
          <InfoCard
            key={i} title={formatTitle(item)}
            className='col-sm-6 col-md-6 card-download'>
            <a
              href={getFileURL(item)}
              rel="noopener noreferrer"
              target="_blank"
              key={i}
            >
              <div className='d-flex flex-row align-items-center'>
                <i className='fa fa-download color-download'/>
                <p className='mb-0 text-download'>Descargar</p>
              </div>
            </a>
          </InfoCard>
        ))}
      </div>
    </div>
  );

  if (loading) {
    return (<Loading/>);
  }
  if (error) {
    return (<Error reload={reload} />);
  }
  return (
    <div>
      { files.length === 0
        ? <InfoMessage message='Sin reportes'/>
        : typeof files !== 'undefined'
        && files.length > 0
        && getItems()
      }
    </div>

  );
};

Reports.propTypes = {
  files: PropTypes.array,
  loading: PropTypes.bool
};

Reports.defaultProps = {
  files: [],
  loading: {},
  reload: null
};

export default Reports;

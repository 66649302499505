import { useState } from 'react';

const useModal = () => {
  const [modal, setModal] = useState({
    visible: false,
    okHandling: false
  });

  const openModal = (params) => setModal({ ...modal, ...params, visible: true });

  const closeModal = () => setModal({ ...modal, visible: false });

  const startLoading = (values) => setModal({
    ...modal, visible: true, okHandling: true, values
  });

  const endLoading = () => setModal({ ...modal, visible: true, okHandling: false });

  return {
    props: {
      ...modal,
      closeModal
    },
    events: {
      openModal,
      closeModal,
      startLoading,
      endLoading
    },
    setModal
  };
};

export default useModal;

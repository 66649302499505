/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const IDP = `
  data{
    user{
      name
      fatherLastName
      secondName
      indefiniteContractDate
      antiquity
      dateChangeSociety
      dateOld
      firstIncomeDate
      codSociety
      position
      fullName
    }
    _id
    rut
    leader_profile
    imgProfile
    manager
    assessment_history
    aspirations
    assessment_insights
    development_partners
    mentor_optional
    key_strenghts
    areas_for_development
    development_priorities
    measures_of_success
    development_experience
    development_exposure
    development_education
    additional_remarks
    visible
    last_changes
    status
    statusEvaluations
    statusStrenghts
    statusActions
    filePath
    mentor_works_in_walmart
  }
`;
export const GET_IDP = gql`
    query getIDP($rut: String!) {
      getIDP(rut: $rut) {
        success
        message
        ${IDP}
      }
    }
`;

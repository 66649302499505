import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { getEnvVar } from './commons';

export const initGA = (
  trackingCode = getEnvVar('REACT_APP_GA_TRACKING_CODE'),
  trackingCodeGA4 = getEnvVar('REACT_APP_GA4_TRACKING_CODE') || 'no_akeyless_value'
) => {
  ReactGA.initialize(trackingCode, {
    debug: false,
    gaOptions: {
      cookieDomain: 'none',
      siteSpeedSampleRate: 100
    }
  });
  ReactGA4.initialize(trackingCodeGA4, {
    testMode: false,
    gaOptions: {
      cookieDomain: 'none',
      siteSpeedSampleRate: 100
    }
  });
};

export const logPageView = (path) => {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
};

export const logModalView = (path) => {
  ReactGA.modalview(path);
};

export const logEvent = (category, action, label) => {
  ReactGA.event({ category, action, label });
  ReactGA4.event({ category, action, label });
};

export const titleCase = (str = '') => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const formatEvent = (text) => titleCase(text).split(' ').join('-');

import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CreateRoutes } from './route';
import routes from '../routes';
import routesDisabledMiLugar from '../routes/disabledMiLugar';
import { AppRouterContext } from '../context/AppRouterContext';
import { getEnvVar } from '../../helpers/commons';

const AppRouter = () => {
  const { milugarEnabled, routesContainerReef } = useContext(AppRouterContext);

  return (
    <div ref={routesContainerReef}>
      <Router basename={getEnvVar('PUBLIC_URL')}>
        <CreateRoutes
          {...{
            routes:
              !milugarEnabled.error && milugarEnabled.data
                ? routes
                : routesDisabledMiLugar
          }}
        />
      </Router>
    </div>
  );
};

export default AppRouter;

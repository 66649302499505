import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

const getVisibleAPI = ({ data }) => {
  const { screen } = data;
  return axios().get(`${BFF_ENDPOINT}/panelAdmin/screen/${screen}`).then((resp) => resp.data);
};

export default getVisibleAPI;

import React from 'react';
import MLCollapsibleElements from '../../shared/collapses/MLCollapsibleElements';
import FirstLogin from './FirstLogin';
import OutsideAccess from './OutsideAccess';
import PasswordRestore from './PasswordRestore';
import '../../../static/styles/login/login-help/ml-login-help-collapsible.less';

const LoginHelpOptions = () => {
  const data = [
    {
      key: '1',
      title: 'Ingreso por primera vez',
      children: <FirstLogin/>
    },
    {
      key: '2',
      title: 'Olvidé mi contraseña',
      children: <PasswordRestore/>
    },
    {
      key: '3',
      title: 'Acceso a Mi Lugar fuera de la red de Walmart desde un computador de escritorio o en un celular',
      children: <OutsideAccess/>
    }
  ];

  return (
    <div>
      <MLCollapsibleElements {...{ data, className: 'ml-login-help-collapsible' }}/>
    </div>
  );
};

export default LoginHelpOptions;

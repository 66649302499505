import { gql } from '@apollo/client';

export const UPDATE_EDD = gql`
    mutation updateEDD(
        $evaluationId: ID!
        $skills: [skillsInputEDD]
        $objectives: [objectivesInputEDD]
        $strengths: String
        $opportunities: String
    ) {
       updateEDD 
        (
            evaluationId: $evaluationId,
            skills: $skills,
            objectives: $objectives
            strengths: $strengths
            opportunities: $opportunities
        ) {
          _id
          finalCategories {
            evaluation {
              name
              description
            }
            objectives {
              name
              finalObjetivesDescription
            }
            skills {
              name
              finalSkillDescription
            }
          }
        }
    }
`;

export const UPDATE_EDD_STATUS = gql`
    mutation updateStatusEDD(
        $evaluationId: ID!
        $statusInput: statusEDD! 
        $reason:String 
        $strengths: String
        $opportunities: String
    ) {
       updateStatusEDD 
        (
            evaluationId: $evaluationId,
            statusInput: $statusInput,
            reason: $reason
            strengths: $strengths
            opportunities: $opportunities
        ) {
          _id
        }
    }
`;

export const UPDATE_EDD_STATUS_BY_ADM = gql`
    mutation updateStatusEDDByAdm(
        $evaluationId: ID!
        $statusInput: statusEDD! 
        $reason:String 
        $strengths: String
        $opportunities: String
    ) {
      updateStatusEDDByAdm 
        (
            evaluationId: $evaluationId,
            statusInput: $statusInput,
            reason: $reason
            strengths: $strengths
            opportunities: $opportunities
        ) {
          _id
          status
        }
    }
`;

export const SAVE_EDD_OPINION = gql`
    mutation saveEDDOpinion(
        $_id: String!
        $opinion: [OpinionEDDInput]!
        $userHadConversationWithBoss: Boolean!
    ) {
      saveEDDOpinion 
        (
          _id: $_id,
          opinion: $opinion,
          userHadConversationWithBoss: $userHadConversationWithBoss
        ) {
          year
        }
    }
`;

export const actionTypes = {
  GET_SA_USER: 'GET_INDIVIDUAL',
  GET_SA_USER_SUCCESS: 'GET_INDIVIDUAL_SUCCESS',
  ADD_INDIVIDUAL: 'ADD_INDIVIDUAL',
  ADD_INDIVIDUAL_SUCCESS: 'ADD_INDIVIDUAL_SUCCESS',
  CHANGE_WIZARD_STEP: 'CHANGE_WIZARD_STEP',
  RESET_CASE: 'RESET_CASE',
  GET_SA_CATEGORIES: 'GET_SA_CATEGORIES',
  GET_SA_CATEGORIES_SUCCESS: 'GET_SA_CATEGORIES_SUCCESS',
  ADD_REGISTER: 'ADD_REGISTER',
  ADD_REGISTER_SUCCESS: 'ADD_REGISTER_SUCCESS',
  ADD_ADVISORY: 'ADD_ADVISORY',
  ADD_ADVISORY_SUCCESS: 'ADD_ADVISORY_SUCCESS',
  DISMISS_MODAL: 'DISMISS_MODAL',
  RESET_FORM: 'RESET_FORM',
  ADD_REVIEW: 'ADD_REVIEW',
  ADD_REVIEW_SUCCESS: 'ADD_REVIEW_SUCCESS',
  CLOSE_CASE: 'CLOSE_CASE',
  CLOSE_CASE_SUCCESS: 'CLOSE_CASE_SUCCESS',
  GET_LOCAL_INFO: 'GET_LOCAL_INFO',
  GET_LOCAL_INFO_SUCCESS: 'GET_LOCAL_INFO_SUCCESS',
  GET_USER_FROM_GRUPAL: 'GET_USER_FROM_GRUPAL',
  GET_USER_FROM_GRUPAL_SUCCESS: 'GET_USER_FROM_GRUPAL_SUCCESS',
  RESET_USER_FROM_MODAL: 'RESET_USER_FROM_MODAL',
  ADD_TO_LOCAL_LIST: 'ADD_TO_LOCAL_LIST',
  ADD_SA_GROUP_REGISTER: 'ADD_SA_GROUP_REGISTER',
  ADD_SA_GROUP_REGISTER_SUCCESS: 'ADD_SA_GROUP_REGISTER_SUCCESS',
  DELETE_USER_GROUP_ATTENTION: 'DELETE_USER_GROUP_ATTENTION',
  EDIT_GROUP_REGISTER: 'EDIT_GROUP_REGISTER'
};

export function editGroupRergister(data) {
  return {
    type: actionTypes.EDIT_GROUP_REGISTER,
    payload: data
  };
}

export function deleteUserGroupAttention(data) {
  return {
    type: actionTypes.DELETE_USER_GROUP_ATTENTION,
    payload: data
  };
}

export function resetUserFromModal() {
  return {
    type: actionTypes.RESET_USER_FROM_MODAL
  };
}

export function addGroupRegister(data) {
  return {
    type: actionTypes.ADD_SA_GROUP_REGISTER,
    payload: data
  };
}

export function addGroupRegisterSuccess(data) {
  return {
    type: actionTypes.ADD_SA_GROUP_REGISTER_SUCCESS,
    payload: data
  };
}

export function addToLocalList(user) {
  return {
    type: actionTypes.ADD_TO_LOCAL_LIST,
    payload: user
  };
}

export function getLocalInfo(data) {
  return {
    type: actionTypes.GET_LOCAL_INFO,
    payload: data
  };
}

export function getLocalInfoSuccess(data) {
  return {
    type: actionTypes.GET_LOCAL_INFO_SUCCESS,
    payload: data
  };
}

export function getSACategories(data) {
  return {
    type: actionTypes.GET_SA_CATEGORIES,
    payload: data
  };
}

export function getSACategoriesSuccess(data, type) {
  return {
    type: actionTypes.GET_SA_CATEGORIES_SUCCESS,
    payload: {
      data,
      type
    }
  };
}

export function addRegister(data) {
  return {
    type: actionTypes.ADD_REGISTER,
    payload: data
  };
}

export function addRegisterSuccess(data) {
  return {
    type: actionTypes.ADD_REGISTER_SUCCESS,
    payload: data
  };
}

export function addAdvisory(data) {
  return {
    type: actionTypes.ADD_ADVISORY,
    payload: data
  };
}

export function addAdvisorySuccess(data) {
  return {
    type: actionTypes.ADD_ADVISORY_SUCCESS,
    payload: data
  };
}

export function addReview(data) {
  return {
    type: actionTypes.ADD_REVIEW,
    payload: data
  };
}

export function addReviewSuccess(data) {
  return {
    type: actionTypes.ADD_REVIEW_SUCCESS,
    payload: data
  };
}

export function getSAUser(rut) {
  return {
    type: actionTypes.GET_SA_USER,
    payload: rut
  };
}

export function getSAUserSuccess(data) {
  return {
    type: actionTypes.GET_SA_USER_SUCCESS,
    payload: data
  };
}

export function getUserFromGrupal(data) {
  return {
    type: actionTypes.GET_USER_FROM_GRUPAL,
    payload: data
  };
}

export function getUserFromGrupalSuccess(data) {
  return {
    type: actionTypes.GET_USER_FROM_GRUPAL_SUCCESS,
    payload: data
  };
}

export function addIndividualAttention(data) {
  return {
    type: actionTypes.ADD_INDIVIDUAL,
    payload: data
  };
}

export function addIndividualAttentionSuccess(data) {
  return {
    type: actionTypes.ADD_INDIVIDUAL_SUCCESS,
    payload: data
  };
}

export function changeWizardStep(step) {
  return {
    type: actionTypes.CHANGE_WIZARD_STEP,
    payload: step
  };
}

export function resetCaseBtns() {
  return {
    type: actionTypes.RESET_CASE
  };
}

export function resetForm() {
  return {
    type: actionTypes.RESET_FORM
  };
}

export function dismissModal() {
  return {
    type: actionTypes.DISMISS_MODAL
  };
}

export function closeCaseSuccess(data) {
  return {
    type: actionTypes.CLOSE_CASE_SUCCESS,
    payload: data
  };
}

export function closeCase() {
  return {
    type: actionTypes.CLOSE_CASE
  };
}

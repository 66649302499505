import { actionTypes } from '../actions/returningPlan';

export const initialState = {
  loading: false,
  pushing: false,
  formData: {},
  data: {},
  formError: null,
  error: null
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_RETURNING_PLAN_FORM_DATA:
    return {
      ...state,
      loading: true,
      formError: null
    };

  case actionTypes.GET_RETURNING_PLAN_FORM_DATA_SUCCESS:
    return {
      ...state,
      loading: false,
      formData: action.data
    };

  case actionTypes.GET_RETURNING_PLAN_FORM_DATA_ERROR:
    return {
      ...state,
      loading: false,
      formError: action.error
    };

  case actionTypes.POST_RETURNING_PLAN_DATA:
    return {
      ...state,
      pushing: true,
      error: null
    };

  case actionTypes.POST_RETURNING_PLAN_DATA_SUCCESS:
    return {
      ...state,
      pushing: false,
      data: action.data
    };

  case actionTypes.POST_RETURNING_PLAN_DATA_ERROR:
    return {
      ...state,
      pushing: false,
      error: action.error
    };

  case actionTypes.CLEAN_ERRORS:
    return {
      ...state,
      formError: null,
      error: null
    };

  default:
    return state;
  }
}

export default reducer;

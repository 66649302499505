import { useState } from 'react';
import { message } from 'antd';
import usePerformance from './usePerformance';

const useSuccessMeasure = ({ defaultData, getFieldsValue, setFieldsValue }) => {
  const { data: performance = {} } = usePerformance();
  const { quarterCurrent: currentQuarter } = performance;

  const [defaultDeleted, deleteDefault] = useState(false);
  const fields = getFieldsValue();
  const successMeasures = fields.successMeasures || (
    (!defaultDeleted && defaultData.length) ? defaultData : [{ description: '' }]
  );

  const filterSuccessMeasuresLength = () => {
    const res = successMeasures.filter(({ updates = {} }) => {
      switch (updates[currentQuarter]?.status) {
      case 'FINISHED':
      case 'CANCELLED':
        return true;
      default:
      }
      return false;
    }).length;

    return res;
  };

  const [successMeasuresLength, increaseSuccessMeasuresLength] = useState(successMeasures.length);

  const addSucessMeasure = async () => (
    filterSuccessMeasuresLength() === 5 ? null : (
      increaseSuccessMeasuresLength(successMeasuresLength + 1)
    )
  );

  const copySucessMeasure = async (description) => {
    navigator.clipboard.writeText(description);
    message.success('Texto copiado');
  };

  const removeSucessMeasure = async (index) => {
    const newSucessMeasure = {};

    for (let i = 0, j = 0; i < successMeasuresLength; i += 1) {
      if (i !== index) {
        const measure = {};

        if (successMeasures[i]) {
          Object.keys(successMeasures[i]).map((measureKey) => (Object.assign(measure, {
            [`successMeasures[${j}][${measureKey}]`]: successMeasures[i][measureKey]
          })));

          Object.assign(newSucessMeasure, measure);
          j += 1;
        }
      }
    }

    await increaseSuccessMeasuresLength(successMeasuresLength - 1);
    if (!defaultDeleted) deleteDefault(true);

    setFieldsValue(newSucessMeasure);
  };

  return {
    addSucessMeasure,
    copySucessMeasure,
    removeSucessMeasure,
    filterSuccessMeasuresLength,
    successMeasuresLength
  };
};

export default useSuccessMeasure;

import useSearchUserInput from './useSearchUserInput';
import getSearchUserClassNames from '../../../../helpers/searchUser/getClassNames';
import getSearchUserBarProps from '../../../../helpers/searchUser/getSearchBarProps';
import getSearchUserSelectedProps from '../../../../helpers/searchUser/getSelectedUserProps';
import useSearchUserResults from './useSearchUserResults';
import useSearchUserValidator from './useSearchUserValidator';
import useSearchUserSelection from './useSearchUserSelection';

const useSearchUser = (props) => {
  const {
    className, onSelect
  } = props;
  const userInput = useSearchUserInput(props);
  const inputValidators = useSearchUserValidator(userInput);
  const userSelection = useSearchUserSelection({ ...userInput, ...inputValidators, onSelect });
  const resultsProps = useSearchUserResults({ ...userInput, ...inputValidators, ...userSelection });
  const { firstNameInput, focused } = userInput;
  const { canRefetchQuery, showResultsUsers } = inputValidators;
  const userSelectedProps = getSearchUserSelectedProps({ ...userSelection, ...props });

  const {
    containerClassName, searchBarClassName
  } = getSearchUserClassNames(className, focused);

  const searchBarProps = getSearchUserBarProps({
    searchBarClassName, ...userInput, ...props
  });

  return {
    containerClassName,
    firstNameInput,
    resultsProps,
    searchBarProps,
    userSelectedProps,
    canRefetchQuery,
    showResultsUsers
  };
};

export default useSearchUser;

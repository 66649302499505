import React, { Fragment } from 'react';
import { capitalize } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import MLSeparator from '../../../../../../shared/Separator';
import MLLinkButton from '../../../../../../shared/buttons/LinkButton';
import MLSimpleCollapse from '../../../../../../shared/MLSimpleCollapse';
import '../../../../../../../static/styles/mievolucion/myPerformance/obective-boss-success-measures.less';

const BossSuccessMeasures = ({ successMeasuresBoss, copySucessMeasure, bossName }) => (
  <>
    <MLSimpleCollapse {...{ title: `Medidas de éxito de ${bossName}` }}>
      <div className='my-performance-objective-boss-success-measures'>
        <Row type='flex' className='my-performance-objective-boss-success-measures-title'>
          <Col lg={18}>
            <span>Medidas de éxito de {bossName}</span>
          </Col>

          <Col lg={6}>
            <span>Fecha límite</span>
          </Col>
        </Row>

        {successMeasuresBoss.map((measure, i) => {
          const dateMeasure = moment(Number(measure.dateMeasure));

          return (
            <Fragment key={i}>
              <Row type='flex' className='my-performance-objective-boss-success-measures-item'>
                <Col lg={18}>
                  <span>{i + 1}.</span>
                  &nbsp;
                  <span>{measure.description}</span>
                </Col>

                <Col lg={6}>
                  <span>
                    {capitalize(
                      dateMeasure.format('ddd, DD MMM YYYY').replaceAll('.', '')
                    )}
                  </span>
                </Col>
              </Row>

              <MLLinkButton {...{
                text: 'Copiar texto', onClick: () => copySucessMeasure(measure?.description)
              }}/>

              {i + 1 < successMeasuresBoss.length && <MLSeparator/>}
            </Fragment>
          );
        })}
      </div>
    </MLSimpleCollapse>
  </>
);

BossSuccessMeasures.propTypes = {
  bossName: PropTypes.string,
  successMeasuresBoss: PropTypes.array,
  copySucessMeasure: PropTypes.func
};

BossSuccessMeasures.defaultProps = {
  bossName: 'Jefe',
  successMeasuresBoss: [],
  copySucessMeasure: () => null
};

export default BossSuccessMeasures;

import { map, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const COMMON_VARIABLES_LIST = { dataComplete: true };
export const FORMAT_DATE = 'DD MMM [,] YYYY';
export const WITHOUTINFO = 'WITHOUTINFO';
export const DEFAULT_INPUTS = [
  { name: '', _id: uuidv4() }
];

export const MAX_AREAS_DETAILS = 3;
export const DEFAULT_SEARCH_AREA = 'a';
export const AREA_DETAIL = {
  area: {
    name: ''
  }
};
export const DEFAULT_AREAS_DETAILS = Array(MAX_AREAS_DETAILS).fill(AREA_DETAIL);

export const COLLEGE_CARRER = {
  statusOfStudies: null,
  collegeCarrer: {
    name: ''
  }
};
export const DEFAULT_COLLEGE_CARRER = Array(1).fill(COLLEGE_CARRER);

export const PLATFORM = [
  {
    level: WITHOUTINFO,
    platform: {
      name: ''
    }
  }
];
export const DEFAULT_PLATFORMS = Array(1).fill(PLATFORM);

export const POSITION = {
  area: {
    name: ''
  },
  company: '',
  duration: WITHOUTINFO,
  period: {
    dateInit: null,
    dateFinish: null
  },
  positionName: ''
};

export const DEFAULT_POSITIONS = Array(1).fill(POSITION);

export const getAcademicTrainingInput = (validateFields, state) => {
  const {
    postgraduateName,
    levelsOfStudies,
    collegeCarrers,
    haveCoursesOrTraining,
    coursesOrTraining,
    haveEnglishCertification,
    englishCertification,
    platforms

  } = state;
  let input;
  validateFields((err, values) => {
    if (!err) {
      const { writtenEnglishLevel, oralEnglishLevel } = values;
      input = {
        postgraduateName: postgraduateName || '',
        levelsOfStudies: levelsOfStudies || WITHOUTINFO,
        writtenEnglishLevel: writtenEnglishLevel || WITHOUTINFO,
        oralEnglishLevel: oralEnglishLevel || WITHOUTINFO,
        haveCoursesOrTraining: WITHOUTINFO,
        coursesOrTraining: [],
        haveEnglishCertification: WITHOUTINFO,
        englishCertification: [],
        platforms: []

      };
      if (!isEmpty(collegeCarrers)) {
        const notCollegeCarrers = [];
        map(collegeCarrers, (item) => {
          if (!isEmpty(item?.collegeCarrer?._id)) {
            notCollegeCarrers.push(
              {
                collegeCarrer: item.collegeCarrer._id,
                statusOfStudies: item.statusOfStudies || WITHOUTINFO
              }
            );
          }
        });
        input.collegeCarrers = notCollegeCarrers;
      }

      if (haveCoursesOrTraining) {
        input.haveCoursesOrTraining = haveCoursesOrTraining;
        const notEmptyCourses = [];
        map(coursesOrTraining, (item) => {
          if (!isEmpty(item.name)) {
            notEmptyCourses.push({ name: item.name });
          }
        });
        if (!isEmpty(notEmptyCourses)) {
          input.coursesOrTraining = notEmptyCourses;
        }
      }

      if (haveEnglishCertification) {
        input.haveEnglishCertification = haveEnglishCertification;
        const notEmptyEnglishCertification = [];
        map(englishCertification, (item) => {
          if (!isEmpty(item.name)) {
            notEmptyEnglishCertification.push({ name: item.name });
          }
        });
        if (!isEmpty(notEmptyEnglishCertification)) {
          input.englishCertification = notEmptyEnglishCertification;
        }
      }
      if (!isEmpty(platforms)) {
        const notEmptyPlatforms = [];
        map(platforms, (item) => {
          if (!isEmpty(item?.platform?._id)) {
            notEmptyPlatforms.push({
              platform: item.platform._id,
              level: item.level || WITHOUTINFO
            });
          }
        });
        if (!isEmpty(notEmptyPlatforms)) {
          input.platforms = notEmptyPlatforms;
        }
      }
    }
  });
  return input;
};

export const labelStatus = (status = '') => {
  switch (status.toLowerCase()) {
  case 'FINISHED':
    return 'Completo';
  default:
    return 'Pendiente';
  }
};

export const statusClasses = (status = '') => {
  switch (status.toLowerCase()) {
  case 'completed':
    return 'icon-me-check success-icon-color';
  default:
    return 'icon-me-feedback-pending pending-icon-color';
  }
};

import { actionTypes } from '../actions/rankingReco';

export const initialState = {
  called: false,
  loading: false,
  data: [],
  error: ''
};

function reducer(state = initialState, action) {
  const { variables, payload } = action;

  switch (action.type) {
  case actionTypes.GET_RANKING_RECO:
    return {
      ...state,
      mgmt: variables.mgmt,
      loading: true
    };
  case actionTypes.GET_RANKING_RECO_SUCCESS:
    return {
      ...state,
      called: true,
      data: payload.result,
      error: false,
      loading: false
    };
  case actionTypes.GET_RANKING_RECO_ERROR:
    return {
      ...state,
      called: true,
      data: [],
      error: action.error,
      loading: false
    };
  case actionTypes.RESET_RANKING_RECO:
    return {
      ...initialState
    };
  default:
    return state;
  }
}

export default reducer;

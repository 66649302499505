import { actionTypes } from '../actions/uniforms';

export const initialState = {
  uniformsByRut: [],
  loadingUniformsByRut: false,
  errorUniformsByRut: null,
  loadedUniformsByRut: null,

  uniformsByGarment: [],
  loadingUniformsByGarment: false,
  errorUniformsByGarment: null,
  loadedUniformsByGarment: null,

  uniforms: {
    data: null,
    error: false,
    loading: false,
    loaded: false,
    updated: false
  },
  uniformsAdmin: {
    data: null,
    error: false,
    loading: false,
    loaded: false
  },
  uniformsPermissions: {
    data: null,
    error: false,
    loading: false,
    loaded: false
  },
  deliveries: {
    data: null,
    error: false,
    loading: false,
    loaded: false,
    saving: false,
    saved: false
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_UNIFORMS_BY_RUT_REPORT:
    return {
      ...state,
      loadingUniformsByRut: true,
      errorUniformsByRut: null,
      loadedUniformsByRut: false
    };
  case actionTypes.GET_UNIFORMS_BY_RUT_REPORT_SUCCESS:
    return {
      ...state,
      loadingUniformsByRut: false,
      uniformsByRut: [{ ...action.data }],
      errorUniformsByRut: null,
      loadedUniformsByRut: true
    };
  case actionTypes.GET_UNIFORMS_BY_RUT_REPORT_ERROR:
    return {
      ...state,
      loadingUniformsByRut: false,
      errorUniformsByRut: action.error,
      loadedUniformsByRut: false
    };
  case actionTypes.GET_UNIFORMS_BY_GARMENT_REPORT:
    return {
      ...state,
      loadingUniformsByGarment: true,
      errorUniformsByGarment: null,
      loadedUniformsByGarment: false
    };
  case actionTypes.GET_UNIFORMS_BY_GARMENT_REPORT_SUCCESS:
    return {
      ...state,
      loadingUniformsByGarment: false,
      uniformsByGarment: [{ ...action.data }],
      errorUniformsByGarment: null,
      loadedUniformsByGarment: true
    };
  case actionTypes.GET_UNIFORMS_BY_GARMENT_REPORT_ERROR:
    return {
      ...state,
      loadingUniformsByGarment: false,
      errorUniformsByGarment: action.error,
      loadedUniformsByGarment: false
    };
  case actionTypes.UPDATE_UNIFORMS:
    return {
      ...state,
      uniforms: {
        ...state.uniforms,
        error: false,
        updated: false
      }
    };
  case actionTypes.UPDATE_UNIFORMS_SUCCESS:
    return {
      ...state,
      uniforms: {
        ...state.uniforms,
        error: false,
        loading: false,
        data: {
          ...state.uniforms.data,
          ...action.payload
        },
        updated: true
      }
    };
  case actionTypes.UPDATE_UNIFORMS_FAILURE:
    return {
      ...state,
      uniforms: {
        ...state.uniforms,
        error: true,
        loading: false,
        updated: false
      }
    };
  case actionTypes.GET_UNIFORMS:
    return {
      ...state,
      uniforms: {
        ...state.uniforms,
        error: false,
        loading: true,
        loaded: false
      }
    };
  case actionTypes.GET_UNIFORMS_SUCCESS:
    return {
      ...state,
      uniforms: {
        ...state.uniforms,
        error: false,
        loading: false,
        loaded: true,
        data: {
          ...state.uniforms.data,
          ...action.payload
        }
      }
    };
  case actionTypes.GET_UNIFORMS_FAILURE:
    return {
      ...state,
      uniforms: {
        ...state.uniforms,
        error: true,
        loading: false,
        loaded: false
      }
    };
  case actionTypes.GET_UNIFORMS_ADMIN:
    return {
      ...state,
      uniformsAdmin: {
        ...state.uniformsAdmin,
        error: false,
        loading: true,
        loaded: false
      }
    };
  case actionTypes.GET_UNIFORMS_ADMIN_SUCCESS:
    return {
      ...state,
      uniformsAdmin: {
        ...state.uniformsAdmin,
        error: false,
        loading: false,
        loaded: true,
        data: {
          ...state.uniformsAdmin.data,
          ...action.payload
        }
      }
    };
  case actionTypes.GET_UNIFORMS_ADMIN_FAILURE:
    return {
      ...state,
      uniformsAdmin: {
        ...state.uniformsAdmin,
        error: true,
        loading: false,
        loaded: false
      }
    };
  case actionTypes.GET_UNIFORMS_PERMISSIONS:
    return {
      ...state,
      uniformsPermissions: {
        ...state.uniformsPermissions,
        error: false,
        loading: true,
        loaded: false
      }
    };
  case actionTypes.GET_UNIFORMS_PERMISSIONS_SUCCESS:
    return {
      ...state,
      uniformsPermissions: {
        ...state.uniformsPermissions,
        error: false,
        loading: false,
        loaded: true,
        data: {
          ...state.uniformsPermissions.data,
          ...action.payload
        }
      }
    };
  case actionTypes.GET_UNIFORMS_PERMISSIONS_FAILURE:
    return {
      ...state,
      uniformsPermissions: {
        ...state.uniformsPermissions,
        error: true,
        loading: false,
        loaded: false
      }
    };
  case actionTypes.UPDATE_UNIFORMS_CONVENTION_GROUP:
    return {
      ...state,
      uniformsPermissions: {
        ...state.uniformsPermissions,
        error: false,
        saving: true,
        saved: false
      }
    };
  case actionTypes.UPDATE_UNIFORMS_CONVENTION_GROUP_SUCCESS:
    return {
      ...state,
      uniformsPermissions: {
        ...state.uniformsPermissions,
        error: false,
        errorData: {},
        saving: false,
        saved: true,
        data: {
          ...state.uniformsPermissions.data,
          ...action.payload
        }
      }
    };
  case actionTypes.UPDATE_UNIFORMS_CONVENTION_GROUP_FAILURE:
    return {
      ...state,
      uniformsPermissions: {
        ...state.uniformsPermissions,
        error: true,
        errorData: action.payload,
        saving: false,
        saved: false
      }
    };
  case actionTypes.DELETE_UNIFORMS_PERMISSIONS:
    return {
      ...state,
      uniformsPermissions: {
        ...state.uniformsPermissions,
        error: false,
        deleting: true,
        deleted: false
      }
    };
  case actionTypes.DELETE_UNIFORMS_PERMISSIONS_SUCCESS:
    return {
      ...state,
      uniformsPermissions: {
        ...state.uniformsPermissions,
        error: false,
        deleting: false,
        deleted: true,
        data: {
          ...state.uniformsPermissions.data
        }
      }
    };
  case actionTypes.DELETE_UNIFORMS_PERMISSIONS_FAILURE:
    return {
      ...state,
      uniformsPermissions: {
        ...state.uniformsPermissions,
        error: true,
        deleting: false,
        deleted: false
      }
    };
  case actionTypes.GET_UNIFORMS_DELIVERIES:
    return {
      ...state,
      deliveries: {
        ...state.deliveries,
        error: false,
        loading: true,
        loaded: false
      }
    };
  case actionTypes.GET_UNIFORMS_DELIVERIES_SUCCESS:
    return {
      ...state,
      deliveries: {
        ...state.deliveries,
        error: false,
        loading: false,
        loaded: true,
        data: {
          ...state.deliveries.data,
          ...action.payload
        }
      }
    };
  case actionTypes.GET_UNIFORMS_DELIVERIES_FAILURE:
    return {
      ...state,
      deliveries: {
        ...state.deliveries,
        error: true,
        loading: false,
        loaded: false
      }
    };
  case actionTypes.SET_UNIFORMS_DELIVERIES:
    return {
      ...state,
      deliveries: {
        ...state.deliveries,
        error: false,
        saving: true,
        saved: false
      }
    };
  case actionTypes.SET_UNIFORMS_DELIVERIES_SUCCESS:
    return {
      ...state,
      deliveries: {
        ...state.deliveries,
        error: false,
        saving: false,
        saved: true,
        data: {
          ...state.deliveries.data,
          ...action.payload
        }
      }
    };
  case actionTypes.SET_UNIFORMS_DELIVERIES_FAILURE:
    return {
      ...state,
      deliveries: {
        ...state.deliveries,
        error: true,
        saving: false,
        saved: false
      }
    };
  default:
    return state;
  }
}

export default reducer;

export const actionTypes = {
  FAILURE: 'FAILURE',
  DISMISS_ERROR: 'DISMISS_ERROR',
  GET_EXCEL: 'GET_EXCEL',
  GET_EXCEL_SUCCESS: 'GET_EXCEL_SUCCESS',
  GET_EXCEL_BENEFITS: 'GET_EXCEL_BENEFITS',
  GET_EXCEL_BENEFITS_SUCCESS: 'GET_EXCEL_BENEFITS_SUCCESS',
  GET_ANALYTIC: 'GET_ANALYTIC',
  GET_ANALYTIC_SUCCESS: 'GET_ANALYTIC_SUCCESS',
  SET_INITIAL_DATE: 'SET_INITIAL_DATE'
};

export function failure(error) {
  return {
    type: actionTypes.FAILURE,
    error
  };
}

export function dismissError() {
  return {
    type: actionTypes.DISMISS_ERROR
  };
}

export function setInitialDate(data) {
  return {
    type: actionTypes.SET_INITIAL_DATE,
    data
  };
}

export function getExcel(data) {
  return {
    type: actionTypes.GET_EXCEL,
    data
  };
}

export function getExcelSuccess(data) {
  return {
    type: actionTypes.GET_EXCEL_SUCCESS,
    data
  };
}

export function getExcelBenefits(data) {
  return {
    type: actionTypes.GET_EXCEL_BENEFITS,
    data
  };
}

export function getExcelBenefitsSuccess(data) {
  return {
    type: actionTypes.GET_EXCEL_BENEFITS_SUCCESS,
    data
  };
}

export function getAnalytic() {
  return {
    type: actionTypes.GET_ANALYTIC
  };
}

export function getAnalyticSuccess(data) {
  return {
    type: actionTypes.GET_ANALYTIC_SUCCESS,
    data
  };
}

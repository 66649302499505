import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import CollegeCarrers from './CollegeCarrers';
import CoursesOrTraining from './CoursesOrTraining';
import EnglishLevel from './EnglishLevel';
import Platforms from './Platforms';
import {
  DEFAULT_INPUTS, DEFAULT_COLLEGE_CARRER, DEFAULT_PLATFORMS, getAcademicTrainingInput,
  WITHOUTINFO, COMMON_VARIABLES_LIST
} from '../../../../helpers/myEvolution/interestsAndSkills';
import {
  FIND_COLLEGE_CARRER, FIND_PLATFORM
} from '../../../../graphql/mievolucion/interestsAndSkills/queries';
import { MY_EVOLUTION_CONTEXT } from '../../../../helpers/apollo';
import { Button } from '../Form';
import MLError from '../../../shared/Error';
import Loader from '../../../Loader';

const context = MY_EVOLUTION_CONTEXT;

const AcademicTraining = (props) => {
  const {
    form: {
      getFieldDecorator, validateFields
    },
    loadingUpdate,
    updateData
  } = props;

  const {
    coursesOrTraining: initialCoursesOrTraining = [],
    englishCertification: initialEnglishCertification,
    platforms: initialplatforms,
    collegeCarrers: initialCollegeCarrers = [],
    levelsOfStudies: initialLevelsOfStudies = WITHOUTINFO,
    postgraduateName: initialPostgraduateName = null,
    writtenEnglishLevel: initialWrittenEnglishLevel,
    oralEnglishLevel: initialOralEnglishLevel,
    haveCoursesOrTraining: initialHaveCoursesOrTraining,
    haveEnglishCertification: initialHaveEnglishCertification,
    listLevelsOfStudies,
    listOralEnglishLevel,
    listWrittenEnglishLevel,
    listPlatformLevels,
    listStatusOfStudies
  } = props || {};
  const [levelsOfStudies, setLevelsOfStudies] = useState(
    isEmpty(initialLevelsOfStudies) ? null : initialLevelsOfStudies
  );
  const [postgraduateName, setPostgraduateName] = useState(
    isEmpty(initialPostgraduateName) ? null : initialPostgraduateName
  );
  const [collegeCarrers, setCollegeCarrers] = useState(
    isEmpty(initialCollegeCarrers) ? DEFAULT_COLLEGE_CARRER : initialCollegeCarrers
  );

  const [haveCoursesOrTraining, setHaveCoursesOrTraining] = useState(
    initialHaveCoursesOrTraining
  );
  const [coursesOrTraining, setCoursesOrTraining] = useState(
    initialCoursesOrTraining.length === 0 ? DEFAULT_INPUTS : initialCoursesOrTraining
  );

  const [haveEnglishCertification, setHaveEnglishCertification] = useState(
    initialHaveEnglishCertification
  );
  const [englishCertification, setEnglishCertification] = useState(
    initialEnglishCertification.length === 0 ? DEFAULT_INPUTS : initialEnglishCertification
  );

  const [platforms, setPlatforms] = useState(
    isEmpty(initialplatforms) ? DEFAULT_PLATFORMS : initialplatforms
  );
  const {
    loading: loadingListCollegeCarrer,
    error: errorListCollegeCarrer,
    data: dataListCollegeCarrer,
    refetch: refetchListCollegeCarrer
  } = useQuery(FIND_COLLEGE_CARRER, {
    variables: COMMON_VARIABLES_LIST,
    context,
    fetchPolicy: 'network-only'
  });
  const {
    loading: loadingListPlatform,
    error: errorListPlatform,
    data: dataListPlatform,
    refetch: refetchListPlatform
  } = useQuery(FIND_PLATFORM, {
    variables: COMMON_VARIABLES_LIST,
    context,
    fetchPolicy: 'network-only'
  });
  const { listOptionsCollegeCarrer } = dataListCollegeCarrer || {};
  const { listOptionsPlatforms } = dataListPlatform || {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const input = getAcademicTrainingInput(validateFields, {
      postgraduateName,
      levelsOfStudies,
      collegeCarrers,
      haveCoursesOrTraining,
      coursesOrTraining,
      haveEnglishCertification,
      englishCertification,
      platforms
    });
    if (input) {
      updateData({ variables: { input } });
    }
  };

  if (loadingListCollegeCarrer || loadingListPlatform) {
    return <Loader />;
  }

  if (errorListCollegeCarrer || errorListPlatform) {
    return (<MLError
      reload={() => {
        const refetch = errorListCollegeCarrer ? refetchListCollegeCarrer : refetchListPlatform;
        refetch({ variables: COMMON_VARIABLES_LIST });
      }}
    />);
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <CollegeCarrers
          {
            ...{
              levelsOfStudies,
              setLevelsOfStudies,
              postgraduateName,
              setPostgraduateName,
              initialLevelsOfStudies,
              listLevelsOfStudies,
              setCollegeCarrers,
              collegeCarrers,
              listStatusOfStudies,
              getFieldDecorator,
              listOptionsCollegeCarrer
            }
          }
        />

        <CoursesOrTraining
          {...{
            haveCoursesOrTraining, coursesOrTraining, setHaveCoursesOrTraining, setCoursesOrTraining
          }}
        />
        <EnglishLevel
          {...{
            getFieldDecorator,
            writtenEnglishLevel: initialWrittenEnglishLevel,
            oralEnglishLevel: initialOralEnglishLevel,
            englishCertification,
            haveEnglishCertification,
            setEnglishCertification,
            setHaveEnglishCertification,
            listOralEnglishLevel,
            listWrittenEnglishLevel
          } }
        />
        <Platforms
          {
            ...{
              getFieldDecorator,
              platforms,
              setPlatforms,
              listPlatformLevels,
              listOptionsPlatforms
            }
          }
        />
        <Button
          loading={loadingUpdate}
          label='Guardar mi formación académica'
        />
      </Form>
    </>

  );
};
AcademicTraining.propTypes = {
  form: PropTypes.object,
  loadingUpdate: PropTypes.bool,
  updateData: PropTypes.func,
  coursesOrTraining: PropTypes.array,
  englishCertification: PropTypes.array,
  platforms: PropTypes.array,
  collegeCarrers: PropTypes.array,
  levelsOfStudies: PropTypes.string,
  postgraduateName: PropTypes.string,
  writtenEnglishLevel: PropTypes.string,
  oralEnglishLevel: PropTypes.string,
  listLevelsOfStudies: PropTypes.array,
  listOralEnglishLevel: PropTypes.array,
  listWrittenEnglishLevel: PropTypes.array,
  listPlatformLevels: PropTypes.array,
  listStatusOfStudies: PropTypes.array
};

AcademicTraining.defaultProps = {
  form: () => {},
  loadingUpdate: false,
  updateData: () => {},
  coursesOrTraining: [],
  englishCertification: [],
  platforms: [],
  collegeCarrers: [],
  levelsOfStudies: '',
  postgraduateName: '',
  writtenEnglishLevel: '',
  oralEnglishLevel: '',
  listLevelsOfStudies: [],
  listOralEnglishLevel: [],
  listWrittenEnglishLevel: [],
  listPlatformLevels: [],
  listStatusOfStudies: []
};
export default AcademicTraining;

import { gql } from '@apollo/client';

export const GET_COLLABORATORS_IDPS = gql`
  query getCollaboratorsIdps {
    getCollaboratorsIdps {
      success
      message
      data {
          rut
          fullName
          antiquity
          position
          EDDResultLastYear
          EDPResultLastYear
          IDPLastUpdated
          IDPStatus
          IDPFilePath
      }
    }
  }
`;

export const GET_COLLABORATORS_BY_GERENCIA_IDPS = gql`
  query getIdpsByGerencia ($gerencia: String!){
    getIdpsByGerencia (gerencia: $gerencia){
      success
      message
      data {
          rut
          fullName
          antiquity
          position
          EDDResultLastYear
          EDPResultLastYear
          IDPLastUpdated
          IDPStatus
          IDPFilePath
      }
    }
  }
`;

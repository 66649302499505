import { actionTypes } from '../actions/analytic';

export const initialState = {
  data: null
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.POST_ANALYTIC_REQUEST_SUCCESS:
    return {
      ...state,
      data: action.data
    };
  default:
    return state;
  }
}

export default reducer;

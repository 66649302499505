import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, List } from 'antd';
import ListItem from './item';
import '../../../../static/styles/shared/MLHistorical/historical-list.less';

const HistoricalList = ({
  loading,
  showPending,
  data,
  getItemURL,
  getItem,
  getActiveItem,
  title,
  onTitleClick,
  onClickItem,
  noLinks,
  noPadding
}) => (
  <Card loading={loading} className='mievolucion-historical-list-wrapper'>
    {
      title && <div className='list-title-wrapper d-flex align-items-center mb-2'>
        <Link to={onTitleClick} className='mb-1'>{title}</Link>
        <i className='icon-ml-arrow' />
      </div>
    }
    <List
      className={`mievolucion-historical-list ${noPadding && 'p-0'}`}
      loading={false}
      itemLayout='horizontal'
      dataSource={data}
      renderItem={(itemData) => {
        const { _id } = itemData;
        const isActive = getActiveItem(itemData);
        const itemURL = getItemURL(itemData);
        const parsedItemData = getItem ? getItem(itemData) : itemData;

        return (
          noLinks ? (
            <ListItem
              key={_id}
              {...parsedItemData}
              isActive={isActive}
              showPending={showPending}
              pointer={true}
              onClick={() => onClickItem(itemData)}
            />
          ) : (
            <Link to={itemURL}>
              <ListItem
                key={_id}
                {...parsedItemData}
                isActive={isActive}
                showPending={showPending}
              />
            </Link>
          )
        );
      }}
    />
  </Card>
);

HistoricalList.propTypes = {
  loading: PropTypes.bool,
  showPending: PropTypes.bool,
  data: PropTypes.array,
  getItemURL: PropTypes.func,
  getItem: PropTypes.func,
  getActiveItem: PropTypes.func,
  title: PropTypes.string,
  onTitleClick: PropTypes.func,
  onClickItem: PropTypes.func,
  noLinks: PropTypes.bool,
  noPadding: PropTypes.bool
};

HistoricalList.defaultProps = {
  loading: false,
  showPending: false,
  data: [],
  getItemURL: () => null,
  getItem: null,
  getActiveItem: () => null,
  title: '',
  onTitleClick: () => null,
  onClickItem: () => null,
  noLinks: false,
  noPadding: false
};

export default HistoricalList;

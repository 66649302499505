/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_PERMISSION_FILE = gql`
query getPermissionsFile {
  getPermissionsFile {
        success
        url
        title
      }
    }
`;

import { lazy } from 'react';

const MyDocuments = lazy(() => import('../../containers/documentos'));
const SalaryLiquidations = lazy(() => import('../../containers/documentos/liquidaciones-de-sueldo'));
const MyCredential = lazy(() => import('../../containers/documentos/MyCredential'));
const CertificadoAntiguedad = lazy(() => import('../../containers/documentos/CertificadoAntiguedad'));
const DigitalFolder = lazy(() => import('../../containers/documentos/DigitalFolder'));

export default [
  {
    exact: true,
    path: '/documentos',
    section: 'Documentos',
    component: MyDocuments
  },
  {
    exact: true,
    path: '/documentos/liquidaciones-de-sueldo',
    section: 'Documentos -> Liquidaciones de Sueldo',
    component: SalaryLiquidations
  },
  {
    exact: true,
    path: '/documentos/mi-credencial',
    section: 'Documentos -> Mi Credencial',
    component: MyCredential
  },
  {
    exact: true,
    headerTitle: 'Certificado de Relación Laboral',
    path: '/documentos/certificado-de-relacion-laboral',
    section: 'Documentos -> Certificado de Relación Laborals',
    component: CertificadoAntiguedad
  },
  {
    exact: true,
    headerTitle: 'Carpeta Digital',
    path: '/documentos/carpeta-digital',
    section: 'Documentos -> Carpeta Digital',
    component: DigitalFolder
  }
];

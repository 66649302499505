import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import AdminFileList from '../../../shared/list/AdminFileList';
import MessageModal from '../../../shared/ModalMessage';
import { SEARCH_AUDITS } from '../../../../graphql/queries';
import {
  UPLOAD_CATEGORIES_IN_OBJECTIVES,
  UPLOAD_MASSIVE_OBJECTIVES_CURRENT,
  UPLOAD_MASSIVE_PERMISSIONS
} from '../../../../graphql/mievolucion/myPerformance/mutations';
import { PERFORMANCE_YEAR } from '../../../../helpers/myEvolution/myPerformance';

const msgUploadFileObj = 'El archivo está siendo procesado, va a llegar un mensaje a tu correo con el resultado de la subida.';

const Uploads = () => {
  const [modal, setModal] = useState({ visible: false });

  const {
    refetch: refetchAudit
  } = useQuery(SEARCH_AUDITS, {
    variables: { input: { module: 'OBJECTIVES' } },
    context: { clientName: 'compensations' },
    fetchPolicy: 'network-only'
  });

  const onCompleted = (message) => {
    refetchAudit();
    setModal({
      visible: true,
      type: 'success',
      title: message || 'El archivo se ha cargado exitosamente.',
      buttons: [
        {
          text: 'Cerrar',
          type: 'primary',
          onClickButton: () => setModal({ ...modal, visible: false })
        }
      ]
    });
  };

  const onError = (e) => {
    setModal({
      visible: true,
      type: 'error',
      title: 'No se ha podido realizar la carga',
      message: e.message || 'Ha ocurrido un problema al intentar cargar el archivo, por favor inténtalo de nuevo más tarde.',
      buttons: [
        {
          text: 'Cerrar',
          type: 'primary',
          onClickButton: () => setModal({ ...modal, visible: false })
        }
      ]
    });
  };

  const [uploadMassiveCategoryByObjective, {
    loading: categoryByObjectiveLoading
  }] = useMutation(UPLOAD_CATEGORIES_IN_OBJECTIVES, {
    context: { clientName: 'myEvolution' },
    onCompleted: (response) => {
      onCompleted(response?.uploadCategoriesInObjectives?.message);
    },
    onError: (e) => {
      onError(e);
    }
  });

  const [uploadMassivePermissions, {
    loading: uploadLoadingPermission
  }] = useMutation(UPLOAD_MASSIVE_PERMISSIONS, {
    context: { clientName: 'myEvolution' },
    onCompleted: () => {
      onCompleted();
    },
    onError: (e) => {
      onError(e);
    }
  });

  const [uploadMassiveObjectivesCurrent, {
    loading: uploadLoadingCurrent
  }] = useMutation(UPLOAD_MASSIVE_OBJECTIVES_CURRENT, {
    context: { clientName: 'myEvolution' },
    onCompleted: () => {
      onCompleted(msgUploadFileObj);
    },
    onError: (e) => {
      onError(e);
    }
  });

  const [uploadMassiveObjectivesCurrentWithoutCascade, {
    loading: uploadLoadingCurrentWithoutCascade
  }] = useMutation(UPLOAD_MASSIVE_OBJECTIVES_CURRENT, {
    context: { clientName: 'myEvolution' },
    onCompleted: () => {
      onCompleted(msgUploadFileObj);
    },
    onError: (e) => {
      onError(e);
    }
  });

  const uploadListPermission = [
    {
      title: 'Permisos',
      type: 'upload',
      graphqlMutation: uploadMassivePermissions,
      graphqlParams: {
        type: 'MY_PERFORMANCE',
        title: 'Permisos'
      }
    }
  ];

  const uploadListCurrent = [
    {
      title: `Objetivos ${PERFORMANCE_YEAR}`,
      type: 'upload',
      graphqlMutation: uploadMassiveObjectivesCurrent,
      graphqlParams: {
        type: 'MY_PERFORMANCE',
        title: `Objetivos ${PERFORMANCE_YEAR}`,
        cascade: true,
        onlyPulse: false
      }
    }
  ];

  const uploadListCurrentWithoutCascade = [
    {
      title: `Objetivos ${PERFORMANCE_YEAR}`,
      type: 'upload',
      graphqlMutation: uploadMassiveObjectivesCurrentWithoutCascade,
      graphqlParams: {
        type: 'MY_PERFORMANCE',
        title: `Objetivos ${PERFORMANCE_YEAR}`,
        cascade: false
      }
    }
  ];

  const categoryByObjectiveList = [
    {
      title: 'Carga de resultados de Objetivos',
      type: 'upload',
      graphqlMutation: uploadMassiveCategoryByObjective,
      graphqlParams: {
        title: 'Carga de resultados de Objetivos'
      }
    }
  ];

  const cardsUploadsFiles = [{
    title: 'Carga de Permisos',
    list: uploadListPermission,
    loading: uploadLoadingPermission,
    error: false,
    demoFile: true
  },
  {
    title: `Carga de archivos  ${PERFORMANCE_YEAR}`,
    list: uploadListCurrent,
    loading: uploadLoadingCurrent,
    error: false
  },
  {
    title: `Carga de archivos ${PERFORMANCE_YEAR} (sin cascadear)`,
    list: uploadListCurrentWithoutCascade,
    loading: uploadLoadingCurrentWithoutCascade,
    error: false
  },
  {
    title: 'Carga de resultados de Objetivos',
    list: categoryByObjectiveList,
    loading: categoryByObjectiveLoading,
    error: false
  }
  ];

  return (
    <>
      {cardsUploadsFiles.map((item, index) => <AdminFileList key={index} {...item} />)}

      { modal?.visible
      && <MessageModal {...{ ...modal }} />
      }

    </>
  );
};

export default Uploads;

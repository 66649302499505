import React from 'react';
import { Tag } from 'antd';
import { unformatString } from '../../strings';
import { stringFilter } from '../../table';

export const buildTable = (data) => ({
  columns: [
    {
      title: 'Colaborador',
      dataIndex: 'fullName',
      key: 'fullName',
      className: 'table-td',
      sorter: (a, b) => stringFilter(a, b, 'fullName')

    },
    {
      width: 100,
      title: 'RUT',
      dataIndex: 'rut',
      key: 'rut',
      className: 'table-td',
      sorter: (a, b) => stringFilter(a, b, 'rut')

    },
    {
      title: 'Cargo',
      dataIndex: 'position',
      key: 'position',
      className: 'table-td',
      sorter: (a, b) => stringFilter(a, b, 'position')
    },
    {
      title: 'C.F. Desempeño Evaluada',
      dataIndex: 'finalCategoryEvaluated',
      key: 'finalCategoryEvaluated',
      className: 'table-td',
      sorter: (a, b) => stringFilter(a, b, 'finalCategoryEvaluated')

    },
    {
      title: 'C.F. Desempeño Calibrada',
      dataIndex: 'finalCategoryCalibratedDetails',
      key: 'finalCategoryCalibratedDetails',
      className: 'table-td',
      sorter: (a, b) => stringFilter(a, b, 'finalCategoryCalibrated'),
      render: ({ name, color }) => (
        <Tag color={color}>
          {name}
        </Tag>
      )
    },
    {
      title: 'C.F. Competencias Calibrada',
      dataIndex: 'finalSkillCategory',
      key: 'finalSkillCategory',
      className: 'table-td',
      sorter: (a, b) => stringFilter(a, b, 'finalSkillCategory')

    },
    {
      title: 'C.F. Objetivos',
      dataIndex: 'finalObjectiveCategory',
      key: 'finalObjectiveCategory',
      className: 'table-td',
      sorter: (a, b) => stringFilter(a, b, 'finalObjectiveCategory')

    },
    {
      title: 'C.F. Potencial Evaluada',
      dataIndex: 'edpResultCategory.name',
      key: 'edpResultCategory.name',
      className: 'table-td',
      sorter: (a, b) => stringFilter(a, b, 'edpResultCategory.name')

    },
    {
      title: 'C.F. Potencial Calibrada',
      dataIndex: 'potentialSkillResult.name',
      key: 'potentialSkillResult.name',
      className: 'table-td',
      sorter: (a, b) => stringFilter(a, b, 'potentialSkillResult.name')

    }
  ],
  srcData: data
});

const getValuesToFilter = (keysArray, object) => keysArray.reduce((acum, current) => {
  const field = object[current];
  if (field) acum.push(field);
  return acum;
}, []).join('-');

export const handleTableSearch = ({ fieldsToCheck, data, callback }) => (event) => {
  const typedValue = unformatString(event?.target?.value);
  const filtered = data.filter((item) => {
    const regex = new RegExp(typedValue, 'i');
    const mixedFields = getValuesToFilter(fieldsToCheck, item);
    return regex.test(unformatString(mixedFields));
  });
  callback(filtered);
};

import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

export const updateEmergencyDataAPI = (data) => axios().post(`${BFF_ENDPOINT}/user/usermetadata2/update`, data).then((resp) => resp.data);

export const getEmergencyDataAPI = () => axios().get(`${BFF_ENDPOINT}/user/usermetadata2`).then((resp) => resp.data);

export const getContactDataAPI = () => axios().get(`${BFF_ENDPOINT}/user/contact`).then((resp) => resp.data);
export const updateContactDataAPI = (data) => axios().post(`${BFF_ENDPOINT}/user/contact`, data).then((resp) => resp.data);

import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { Item } from '../../Form';
import {
  PLATFORM
} from '../../../../../helpers/myEvolution/interestsAndSkills';
import Platform from './Platform';

const Platforms = (props) => {
  const {
    getFieldDecorator,
    platforms,
    setPlatforms,
    listPlatformLevels,
    listOptionsPlatforms
  } = props;

  const updatePlatform = (value, index) => {
    const newPlatforms = platforms.map(
      (item, i) => (i === index ? { ...item, ...value } : item)
    );
    setPlatforms(newPlatforms);
  };
  const addOtherPlatform = (e) => {
    e.preventDefault();
    const newPlatforms = Object.assign([], platforms);
    newPlatforms.push(
      PLATFORM
    );
    setPlatforms(newPlatforms);
  };

  return (
    <>
      <Item
        label='¿Manejas alguna de estas platformas y/o Metodologías?'
        description='(Máximo 6)'
      >
        {map(platforms, ({ platform, level }, i) => (
          <Platform
            key={i}
            index={i}
            updatePlatform={updatePlatform}
            listPlatformLevels={listPlatformLevels}
            getFieldDecorator={getFieldDecorator}
            platform={platform}
            level={level}
            platforms={platforms}
            setPlatforms={setPlatforms}
            listOptionsPlatforms={listOptionsPlatforms}
          />

        ))}
        { platforms.length < 6
            && <p className='text-add-other' onClick={addOtherPlatform}>Agregar otra</p>
        }
      </Item>
    </>
  );
};

Platforms.propTypes = {
  getFieldDecorator: PropTypes.func,
  platforms: PropTypes.array,
  setPlatforms: PropTypes.func,
  listPlatformLevels: PropTypes.array,
  listOptionsPlatforms: PropTypes.array
};

Platforms.defaultProps = {
  getFieldDecorator: () => {},
  platforms: [],
  setPlatforms: () => {},
  listPlatformLevels: [],
  listOptionsPlatforms: []
};

export default Platforms;

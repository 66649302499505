import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { completePath } from '../../helpers/strings';
import BannerLayout from '../../layouts/banner';
import Help from './homeHelp';
import { homeHelpDetail } from '../../redux/actions/help';
import { useWindowWidthSize } from '../../helpers/browser';
import { smallDevice } from '../../helpers/deviceVariables';
import Tabs from '../../components/shared/MLTabs';

const HelpGrid = () => {
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state?.userReducer?.data);
  const width = useWindowWidthSize();
  const isMobile = width <= smallDevice;

  const {
    miCredencialReducer: {
      stores
    }
  } = useSelector(({ miCredencialReducer }) => ({ miCredencialReducer }));

  const bannerConfig = {
    imageBanner: completePath('web/uniformes/uniformes-banner.jpg'),
    imageAvatar: completePath('web/uniformes/uniformes-avatar.jpg'),
    title: 'Centro de Ayuda'
  };

  useEffect(() => {
    dispatch(homeHelpDetail(dataUser.npres));
  }, []);

  const tabs = [
    {
      title: 'Mis Consultas',
      children: <Help stores={stores} dispatch={dispatch} userData={dataUser} />
    }
  ];

  return (
    <BannerLayout {...bannerConfig} childrenClassName="px-8" showBanner={!isMobile}>
      <Tabs options={tabs} titleAnalyticsEvent="Centro-de-Ayuda" />
    </BannerLayout>
  );
};

export default HelpGrid;

import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ label }) => (
  <div className="ant-col ant-form-item-label">
    <label className="ant-form-item-required">
      {label}
    </label>
  </div>
);

Label.propTypes = {
  label: PropTypes.string
};

Label.defaultProps = {
  label: ''
};

export default Label;

import React from 'react';
import PropTypes from 'prop-types';
import '../../static/styles/shared/MLBoxMessage.less';

const MLBoxMessage = (props) => {
  const {
    color,
    boxTitle,
    classes,
    separateTitle
  } = props;

  const { label: boxTitleLabel, value: boxTitleValue } = boxTitle || {};
  return (
    <div
      className={`
       ml-box-message${color ? ` ${color}` : ''} ${classes}
      `}
    >
      {boxTitle
        && <div className={`box-title ${separateTitle ? 'box-title-separated' : ''}`}>
          {boxTitleLabel
          && <span data-testid="box-title-label" className='title'>{`${boxTitleLabel}${boxTitleValue ? ': ' : ''}`}</span>
          }
          { boxTitleValue
          && <span data-testid="box-title-value" className='value'>{boxTitleValue}</span>
          }
        </div>
      }

      {
        props.children && (props.children)
      }
    </div>
  );
};

MLBoxMessage.propTypes = {
  boxTitle: PropTypes.object,
  color: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.string,
  separateTitle: PropTypes.bool
};

MLBoxMessage.defaultProps = {
  boxTitle: {},
  color: 'default',
  children: <></>,
  classes: '',
  separateTitle: false
};

export default MLBoxMessage;

import React from 'react';
import { Tabs } from 'antd';
import Message from '../../../../shared/InfoMessage';
import Feedback from './Feedback';
import PerformanceEvaluation from './PerformanceEvaluation';
import EDP from './EDP';
import PerformanceWalmart from './PerformanceWalmart';
// import Performance from './Performance';
// Performance hacía REFERENCIA A Pulsos 2021 revisar si se puede borrar
// import useSocket from '../../../../../hooks/sockets/useSocket';
// import { NoConnectionMessage } from '../../../../AdminReports/Reports';
import { PERFORMANCE_YEAR } from '../../../../../helpers/myEvolution/myPerformance';

const { TabPane } = Tabs;

const Reports = () => {
  // const { connected } = useSocket();

  const tabs = [
    {
      title: `Pulsos ${PERFORMANCE_YEAR}`,
      key: `/administrador/mi-evolucion/desempeño/reportes/desempeño-${PERFORMANCE_YEAR}`,
      children: <PerformanceWalmart />
    },
    {
      title: 'Feedback',
      key: '/administrador/mi-evolucion/desempeño/reportes/feedback',
      children: <Feedback />
    },
    {
      title: 'Evaluación de desempeño',
      key: '/administrador/mi-evolucion/desempeño/reportes/evaluación-de-desempeño',
      children: <PerformanceEvaluation />
    },
    {
      title: 'Evaluación de potencial',
      key: 'administrador/mi-evolucion/desempeño/reportes/evaluación-de-potencial',
      children: <EDP />
    }
  ];

  return (
    <>
      <Message
        classes='mb-3'
        color='warning'
      >
        <span>Te recomendamos que generes
          <span className='text-bold'> un reporte a la vez, </span>
          para que así sea más rápida la generación y descarga de éste.
        </span>
      </Message>

      <Tabs>
        {tabs.map((item) => (
          <TabPane
            key={item.key}
            tab={item.title}
            className='pt-4'
          >
            {item.children}
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default Reports;

import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from '@redux-devtools/extension';

import rootReducer from './reducers';
import rootSaga from './sagas';
import { getEnvVar } from '../helpers/commons';

const sagaMiddleware = createSagaMiddleware();

const bindMiddleware = (middleware) => {
  if (!JSON.parse(getEnvVar('REACT_APP_BUILD_SW'))) {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

function configureStore(initialState = {}) {
  const store = createStore(
    rootReducer,
    initialState,
    bindMiddleware([sagaMiddleware])
  );

  sagaMiddleware.run(rootSaga);
  // store.runSagaTask = () => {
  //   store.sagaTask = sagaMiddleware.run(rootSaga);
  // };

  // store.runSagaTask();
  return store;
}

export default configureStore;

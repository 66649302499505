const DESEMPENO_EXCELENCIA = 'Desempeño de excelencia';
const DESEMPENO_EXITOSO = 'Desempeño exitoso';
const DESEMPENO_DESARROLLAR = 'Desempeño por desarrollar';
const DESEMPENO_EXCELENCIA_ABB = 'Desempeño de excelencia';
const DESEMPENO_EXITOSO_ABB = 'Desempeño exitoso';
const DESEMPENO_DESARROLLAR_ABB = 'Por desarrollar';

export const RESULTS_DESCRIPTIONS = {
  [DESEMPENO_EXCELENCIA]: DESEMPENO_EXCELENCIA_ABB,
  [DESEMPENO_EXITOSO]: DESEMPENO_EXITOSO_ABB,
  [DESEMPENO_DESARROLLAR]: DESEMPENO_DESARROLLAR_ABB
};

export const EVALUATION_TYPE_ENABLED = '2,1';

import React, { useRef, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import {

  Button
} from 'antd';
import { useHistory } from 'react-router-dom';
import Slider from './Slider';
import Loading from '../../../../containers/loading';
import ChooseSkill from './ChooseSkill';
import Evaluation from './Evaluation';
import CreatePlan from './CreatePlan';
import { GET_SKILLS } from '../../../../graphql/mievolucion/skills/queries';
import { setModalLeftIconHeader, resetModalLeftIconHeader } from '../../../../redux/actions/commons';
import InfoMessage from '../../../shared/InfoMessage';
import ErrorMessage from '../../../shared/Error';
import { GET_EVALUATION_PLAN_HISTORICAL } from '../../../../graphql/mievolucion/evolucionaTEST/queries';
import { GET_ME } from '../../../../graphql/user/queries';

const CompleteModel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading: skillsLoading,
    data: skillsData,
    error: errorSkills,
    refetch: refetchSkills
  } = useQuery(
    GET_SKILLS,
    {
      context: { clientName: 'myEvolution' }
    }
  );
  const [carouselState, setCarouselState] = useState({
    activeSlide: 0
  });
  const {
    additionalField4: userLevel, rut
  } = useSelector((state) => state.userReducer.data);

  const goBack = () => {
    dispatch(resetModalLeftIconHeader());
    history.goBack();
  };

  const {
    loading: pendingPlansLoading,
    data: dataPendingPlans,
    error: errorPedingPlans,
    refetch: refetchPendingPlans
  } = useQuery(
    GET_EVALUATION_PLAN_HISTORICAL, {
      context: {
        clientName: 'myEvolution'
      },
      variables:
      {
        dni: rut,
        status:
         ['PENDING']
      }
    }
  );
  const { listSkillLevelBehaviorActionPlan: listPendingPlans = [] } = dataPendingPlans || {};

  const {
    loading: loadingME,
    data: dataMe
  } = useQuery(
    GET_ME, {
      context: {
        clientName: 'myEvolution'
      }
    }
  );
  const { me } = dataMe || {};
  const { profileUCM } = me || {};

  const [modalOptions, setModalOptions] = useState({
    visible: false,
    title: '',
    message: '',
    icon: '',
    type: ''
  });

  const { listSkills = [] } = skillsData || {};
  const [skillSelected, setSkillSelected] = useState(null);
  const [behaviors, setBehaviors] = useState(null);
  const [behaviorChoices, setBehaviorChoices] = useState(null);
  const ref = useRef();

  useEffect(() => {
    dispatch(setModalLeftIconHeader(
      {
        icon: 'icon-ml-warning',
        visible: true,
        message: 'Si sales del TEST antes de haber creado tu Plan de Evolución, entonces tu avance hasta el momento no se guardará',
        title: '¿Estás seguro que deseas salir del TEST?',
        type: 'success',
        buttons: [
          {
            text: 'Salir del TEST',
            className: 'button-border-primary',
            onClickButton: goBack
          },
          {
            text: 'Continuar el TEST',
            type: 'primary'
          }
        ]
      }
    ));
    return () => {
      dispatch(resetModalLeftIconHeader());
    };
  }, []);

  const closeModal = () => {
    setModalOptions({
      visible: false,
      message: '',
      title: '',
      type: 'success'
    });
  };

  const next = () => {
    if (carouselState.activeSlide === 1) {
      setCarouselState({ activeSlide: 2 });
    }
    ref.current.next();
  };

  const findLevel = () => (profileUCM ? profileUCM.name : null);

  const ButtonNext = ({ disabled = false, onClick = next }) => (
    <div className='d-flex justify-content-center'>
      <Button type='primary'
        disabled={disabled}
        onClick={onClick}
      >
      Siguiente
      </Button>
    </div>
  );
  const propsSteps = {
    skillSelected,
    setSkillSelected,
    ButtonNext,
    level: findLevel(),
    listSkills,
    userLevel,
    behaviors,
    setBehaviors,
    rut,
    next,
    mainActiveSlide: carouselState.activeSlide,
    setModalOptions,
    behaviorChoices,
    setBehaviorChoices
  };

  const data = [
    {
      title: 'Elegir Competencia',
      child: <ChooseSkill
        listPendingPlans={listPendingPlans}
        {...propsSteps} />
    },
    {
      title: 'Evaluar Conductas',
      child: <Evaluation
        {...propsSteps} />
    },
    {
      title: 'Crear Plan de Evaluación',
      child: <CreatePlan
        {...propsSteps}
      />
    }
  ];
  if (errorSkills || errorPedingPlans) {
    return (<ErrorMessage
      reload={() => {
        if (errorSkills) {
          refetchSkills();
        } else {
          refetchPendingPlans();
        }
      }}
    />);
  }

  if (skillsLoading || pendingPlansLoading || loadingME) {
    return <Loading />;
  }
  return (
    <div>
      {findLevel()
        ? <Slider
          ref={ref}
          data={data}
          carouselState={carouselState}
          setCarouselState={setCarouselState}
          closeModal={closeModal}
          modalOptions={modalOptions}
        />
        : <InfoMessage
          message='No tienes un perfil UCM asignado'
        />
      }
    </div>
  );
};

export default CompleteModel;

import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import 'isomorphic-unfetch';
import { get } from 'lodash';

import {

  getEmergencyDataAPI,
  updateEmergencyDataAPI
} from '../api/profile';

import {
  actionTypes,
  getEmergencyDataSuccess,
  getEmergencyDataError,
  updateEmergencyDataSuccess,
  updateEmergencyDataError
} from '../actions/profile';

es6promise.polyfill();

function* getEmergencyData() {
  try {
    const response = yield call(getEmergencyDataAPI);
    yield put(getEmergencyDataSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    yield put(getEmergencyDataError({ error: true, message: error }));
  }
}
function* updateEmergencyData(action) {
  try {
    const { data: { body, typeContact } } = action;
    const resp = yield call(updateEmergencyDataAPI, body);

    if (resp.error) {
      yield put(updateEmergencyDataError({ error: true, message: resp.error.message }));
    } else {
      yield put(updateEmergencyDataSuccess({ resp, typeContact }));
    }
  } catch (err) {
    const error = get(err, 'resp.data');
    yield put(updateEmergencyDataError({ error: true, message: error }));
  }
}

function* profileSagas() {
  yield all([

    takeLatest(actionTypes.GET_EMERGENCY_DATA, getEmergencyData),
    takeLatest(actionTypes.UPDATE_EMERGENCY_DATA, updateEmergencyData)
  ]);
}

export default profileSagas;

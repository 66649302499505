import React from 'react';
import MLTabs from '../../../components/shared/MLTabs/MLTabsByRouteSimple';

const MiDesarrolloTabs = ({
  children, tabs, className, firstTab
}) => (
  <div className={className}>
    <MLTabs
      options={tabs}
      firstTab={firstTab}
    >
      {children}
    </MLTabs>
  </div>
);

export default MiDesarrolloTabs;

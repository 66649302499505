import React from 'react';
import PropTypes from 'prop-types';
import MLRadioGroup from '../../../shared/forms/Radio';
import getUploadOptions from '../../../../helpers/myEvolution/calibracion/getUploadOptions';

const SelectionRadios = ({ value, setValue }) => (
  <div className="calibration-stepper-one-selection-radios">
    <p className='text-label mt-4'>Elige la forma en que quieres agregar los colaboradores</p>
    <MLRadioGroup {...{ options: getUploadOptions(), setValue, value }}/>
  </div>
);

SelectionRadios.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func
};

SelectionRadios.defaultProps = {
  value: 0,
  setValue: () => null
};

export default SelectionRadios;

import createSearchUserInputData from './createInputData';

const createSearchUserInputsData = ({
  setFirstName,
  firstName,
  disabled,
  setFocused,
  isSmallDevice,
  placeholder = 'Buscar por nombre, usuario...'
}) => {
  const firstNameInput = createSearchUserInputData({
    input: {
      disabled,
      name: 'fullName',
      placeholder,
      onChange: (e) => {
        setFirstName(e.target.value);
      },
      onFocus: () => setFocused(true),
      onBlur: () => setFocused(isSmallDevice),
      value: firstName
    }
  });

  return { firstNameInput };
};

export default createSearchUserInputsData;

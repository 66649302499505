export const salaryMyTeam = {
  fullName: 'Nombre',
  position: 'Posición',
  dateOld: 'Ingreso',
  positionOld: 'Antigüedad último cargo',
  band: 'Banda',
  positioning: 'Posicionamiento',
  salary: 'Sueldo base actual',
  validDueDate: 'Último incremento',
  reason: 'Motivo último incremento',
  performanceCategory: 'Desempeño',
  talentCategory: 'Talento'
};

export const shapeSalaryMyTeam = (data) => (
  data.map((obj) => Object.keys(obj).reduce((acum, item) => {
    if (salaryMyTeam[item]) acum[salaryMyTeam[item]] = obj[item];
    return acum;
  }, {}))
);

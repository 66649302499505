/* eslint-disable no-useless-escape */
import numeral from 'numeral';
import {
  isAndroid
} from 'react-device-detect';
import { isEmpty } from 'lodash';

export const getEnvVar = (key) => {
  if (process.env.REACT_APP_IS_STRATI && JSON.parse(process.env.REACT_APP_IS_STRATI)) {
    return window?.__ENV__?.[key] || 'no-window-env-key';
  }
  return process.env?.[key] || 'no-process-env-key';
};

const REACT_APP_NODE_ENV = getEnvVar('REACT_APP_NODE_ENV');

export const performanceCategory = (value) => {
  const fields = (isEmpty(value) ? ';' : value).split(';');
  switch (fields[0]) {
  case '1':
    return 'No desarrollado';

  case '2':
    return 'Casi desarrollado';
  case '3':
    return 'Desarrollado';

  case '4':
    return 'Desarrollado con Excelencia';
  case '5':
    return 'Sobresaliente';
  default:
    return null;
  }
};

export const capitalizeText = (text) => {
  if (typeof text === 'string') {
    const split = text.split(' ');
    const formatedText = split.map((item) => (item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())).join(' ');
    return formatedText;
  } return null;
};

export const amountParse = (number) => {
  let data = numeral(number).format('0,0');
  data = data.replace(/\,/g, '.');
  return data;
};

export const isProduction = () => /production/i.test(REACT_APP_NODE_ENV);
export const isStaging = () => /staging/i.test(REACT_APP_NODE_ENV);
export const isDev = () => /dev[el|elop|elopment]?/i.test(REACT_APP_NODE_ENV);

export const isPWAInstalled = () => window.matchMedia('(display-mode: standalone)').matches;
export const playStoreID = 'cl.walmartchile.milugar';

export const getLinkPlayStore = 'https://play.google.com/store/apps/details?id=cl.walmartchile.milugar';

export const checkingIsAppIsIstalled = async (setAppAndroidInstalled = () => {}) => {
  if ('getInstalledRelatedApps' in navigator) {
    try {
      const relatedApps = await navigator?.getInstalledRelatedApps();
      const { location: { origin } } = window;
      const nativeApp = relatedApps.find((app) => app.id === playStoreID && app.url.match(origin));
      if (nativeApp) {
        setAppAndroidInstalled(true);
      } else {
        setAppAndroidInstalled(false);
      }
    } catch (error) {
      setAppAndroidInstalled(false);
    }
  } else if (isAndroid) {
    setAppAndroidInstalled(true);
  } else {
    setAppAndroidInstalled(false);
  }
};
export const getLinkInstallPWA = () => {
  if (isAndroid) {
    return getLinkPlayStore;
  }
  return '/instalar-pwa';
};
export const hell = () => '';

export const getDaysBack = (dateFrom, dateTo) => {
  try {
    let daysDifference;
    if (dateFrom) {
      const startDate = new Date(dateFrom);
      let endDate;
      if (!dateTo) {
        endDate = new Date();
      } else {
        endDate = new Date(dateTo);
      }
      daysDifference = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
    } else {
      daysDifference = 2;
    }
    return daysDifference;
  } catch (err) {
    return 2;
  }
};

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { isEmpty } from 'lodash';
import { getUser } from '../../redux/actions/user';
import useAuthReducers from './useAuthReducers';
import clearAndRedirect from '../helpers/clearAndRedirect';
import { getEnvVar } from '../../helpers/commons';
import { getItem } from '../../helpers/cookiesUtil';

const useAuthentication = ({ history, location }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const token = getItem('token');
  const client = useApolloClient();
  const dispatch = useDispatch();
  const { pathname } = location;
  const reducers = useAuthReducers();

  const REACT_APP_LINK_CREDENTIALS = getEnvVar('REACT_APP_LINK_CREDENTIALS');

  const {
    commonsReducer: { statusCode },
    userReducer
  } = reducers;
  const { me } = userReducer;

  useEffect(() => {
    if (userReducer?.auth?.redirectToCredProduct) {
      const rut = getItem('user');
      window.location.href = `${REACT_APP_LINK_CREDENTIALS}?token=${token}&rut=${rut}`;
    } else if (!me && userReducer?.auth?.redirectToMio) {
      history.push('/mio');
    }
  }, [userReducer?.auth]);

  useEffect(() => {
    if (!me && userReducer?.auth?.redirectToMio) {
      setAuthenticated(true);
    } else if (
      (!me && !userReducer.loading)
      || (!isEmpty(userReducer.auth) && !me && !userReducer.loading)
    ) {
      // Mounted component with storage token.
      dispatch(getUser());
    } else if (!authenticated) {
      // Set authenticated state after login.
      setAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    if (!userReducer.auth) {
      if (pathname === '/') {
        history.push('/milugar');
      }
      return;
    }
    if (pathname === '/') {
      dispatch(getUser());
      history.push('/milugar');
    } else if (pathname.match('index.html')) {
      dispatch(getUser());
      history.push('/milugar');
    }
  }, [userReducer.auth]);

  useEffect(() => {
    // Access errors handling.
    const sessionsExpired = me && statusCode === 401;
    if (sessionsExpired || !me || statusCode === 401) {
      if (!token) {
        client.resetStore();
        setAuthenticated(false);
        clearAndRedirect(history);
      }
    }
  }, [userReducer.me, statusCode]);

  return authenticated;
};

export default useAuthentication;

import axios from '../../helpers/axios';
import { getEnvVar } from '../../helpers/commons';

const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

export const getValidation = () => axios().get(`${BFF_ENDPOINT}/anticipo-sueldo/validation`).then((resp) => resp.data);

const mockRequestsService = () => new Promise((resolve) => {
  setTimeout(() => resolve({
    advanceAmount: 9999,
    status: true,
    maxAmount: 99999999,
    phrase: 'phrase'
  }),
  1000);
});

export const getAPIAnticipo = async () => {
  const response = await mockRequestsService();
  return response;
};
export const getAPIAnticipoInformation = () => axios().get(`${BFF_ENDPOINT}/anticipo-sueldo/info`).then((resp) => resp.data);

export const getAPIAnticipoHistorial = () => axios().get(`${BFF_ENDPOINT}/anticipo-sueldo/historial`).then((resp) => resp.data);

export const postAPIAnticipoSueldo = (anticipo) => axios().post(`${BFF_ENDPOINT}/anticipo-sueldo/`, anticipo).then((resp) => resp.data);
export const putAPIAnticipoSueldo = (anticipo) => axios().put(`${BFF_ENDPOINT}/anticipo-sueldo/`, anticipo).then((resp) => resp.data);
export const deleteAPIAnticipoSueldo = () => axios().delete(`${BFF_ENDPOINT}/anticipo-sueldo/`).then((resp) => resp.data);

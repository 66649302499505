import React from 'react';
import { useSelector } from 'react-redux';
import useGetIDP from '../../../hooks/myEvolution/miDesarrollo/useGetIDP';
import { getProfileInfo } from '../../../helpers/myEvolution/miDesarrollo';
import ProfileHeader from '../../shared/Headers/Profile';
import MLLoading from '../../shared/MLLoading';

const IDPProfile = () => {
  const {
    linealBoss,
    position
  } = useSelector((state) => state.userReducer.data);

  const {
    IDP,
    loading: loadingIDP
  } = useGetIDP();

  if (loadingIDP) return <MLLoading />;

  return (
    <div className='mi-desarrollo-profile'>
      <ProfileHeader
        title='Perfil'
        className='profile-header-white-v2 mi-desarrollo-profile'
        listHeader={getProfileInfo({ position, linealBoss, IDP })}
        collapseProfile
      />
    </div>
  );
};

export default IDPProfile;

export const loadingActions = () => {
  const loadings = ['GET_MY_CREDENTIAL_PROFILE', 'GET_MY_CREDENTIAL_PICTURE', 'RENEW_CREDENTIAL'];
  return loadings
    .map((v) => ({ [`${v}_LOADING`]: `${v}_LOADING` }))
    .reduce((r, c) => ({ ...r, ...c }), {});
};

export const actionTypes = {
  ...loadingActions(),
  GET_MY_CREDENTIAL_PROFILE: 'GET_MY_CREDENTIAL_PROFILE',
  GET_MY_CREDENTIAL_PROFILE_SUCCESS: 'GET_MY_CREDENTIAL_PROFILE_SUCCESS',
  GET_MY_CREDENTIAL_PROFILE_ERROR: 'GET_MY_CREDENTIAL_PROFILE_ERROR',
  GET_USER_CREDENTIAL_ADMIN: 'GET_USER_CREDENTIAL_ADMIN',
  GET_USER_CREDENTIAL_ADMIN_SUCCESS: 'GET_USER_CREDENTIAL_ADMIN_SUCCESS',
  GET_USER_CREDENTIAL_ADMIN_ERROR: 'GET_USER_CREDENTIAL_ADMIN_ERROR',
  GET_MY_CREDENTIAL_PICTURE: 'GET_MY_CREDENTIAL_PICTURE',
  GET_MY_CREDENTIAL_PICTURE_SUCCESS: 'GET_MY_CREDENTIAL_PICTURE_SUCCESS',
  RENEW_CREDENTIAL: 'RENEW_CREDENTIAL',
  RENEW_CREDENTIAL_RESPONSE: 'RENEW_CREDENTIAL_RESPONSE',
  ERROR: 'ERROR',
  LIST_STORES: 'LIST_STORES',
  LIST_STORES_SUCCESS: 'LIST_STORES_SUCCESS',
  LIST_STORES_FAILURE: 'LIST_STORES_FAILURE'
};

export function loading(loadingType, data) {
  return {
    type: actionTypes[loadingType],
    data
  };
}

export function error(data) {
  return {
    type: actionTypes.ERROR,
    data
  };
}

export function renewCredential(data) {
  return {
    type: actionTypes.RENEW_CREDENTIAL,
    data
  };
}

export function renewCredentialResponse(data) {
  return {
    type: actionTypes.RENEW_CREDENTIAL_RESPONSE,
    data
  };
}

export function getMyCredentialProfile(data) {
  return {
    type: actionTypes.GET_MY_CREDENTIAL_PROFILE,
    data
  };
}

export function getMyCredentialProfileSuccess(data) {
  return {
    type: actionTypes.GET_MY_CREDENTIAL_PROFILE_SUCCESS,
    data
  };
}

export function getMyCredentialProfileError(err) {
  return {
    type: actionTypes.GET_MY_CREDENTIAL_PROFILE_ERROR,
    error: err
  };
}

export function getMyCredentialPicture(data) {
  return {
    type: actionTypes.GET_MY_CREDENTIAL_PICTURE,
    data
  };
}

export function getMyCredentialPictureSuccess(data) {
  return {
    type: actionTypes.GET_MY_CREDENTIAL_PICTURE_SUCCESS,
    data
  };
}

export function getListStores() {
  return {
    type: actionTypes.LIST_STORES
  };
}

export function getListStoresSuccess(data) {
  return {
    type: actionTypes.LIST_STORES_SUCCESS,
    data
  };
}

export function getListStoresFailure(err) {
  return {
    type: actionTypes.LIST_STORES_FAILURE,
    err
  };
}

export const actionTypes = {
  ADD_MURAL_COMMENT_POST: 'ADD_MURAL_COMMENT_POST',
  ADD_MURAL_COMMENT_SUCCESS: 'ADD_MURAL_COMMENT_SUCCESS',
  ADD_MURAL_COMMENT_ERROR: 'ADD_MURAL_COMMENT_ERROR',
  RESET_COMMENTS: 'RESET_MURAL_COMMENTS'
};

export const addPostComment = (variables = { postId: '', reply: '' }) => ({
  type: actionTypes.ADD_MURAL_COMMENT_POST,
  variables
});

export const addPostCommentSuccess = (payload) => ({
  type: actionTypes.ADD_MURAL_COMMENT_SUCCESS,
  payload
});

export const addPostCommentError = (error) => ({
  type: actionTypes.ADD_MURAL_COMMENT_ERROR,
  error
});

import { clone, forEach } from 'lodash';

const sanitizeVariables = (variables, action) => {
  const variablesCopy = clone(variables);

  forEach(variables, (value, index) => {
    if (typeof value === 'string') {
      variablesCopy[index] = action(value);
    }

    const isFile = value instanceof File;
    const isArray = Array.isArray(value);
    const isObject = value && typeof value === 'object';

    if (!isFile && (isArray || isObject)) {
      variablesCopy[index] = sanitizeVariables(value, action);
    }
  });

  return variablesCopy;
};

export default sanitizeVariables;

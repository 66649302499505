import React, { Fragment, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { find, capitalize } from 'lodash';
import {
  Form
} from 'antd';
import moment from 'moment';
import MLHistorical from '../../components/shared/MLHistorical';
import HistoricalList from '../../components/shared/MLHistorical/list';
import '../../static/styles/mievolucion/interestsAndSkills.less';
import {
  UPDATE_WORK_EXPERIENCE, UPDATE_ACADEMIC_TRAINING, UPDATE_AREAS
} from '../../graphql/mievolucion/interestsAndSkills/mutations';
import { GET_LIST_INTERESTS_AND_SKILLS, GET_LIST_INTERESTS_AND_SKILLS_DATA_SHORT, FIND_AREAS_DETAIL } from '../../graphql/mievolucion/interestsAndSkills/queries';
import AcademicTraining from '../../components/mievolucion/InterestsAndSkills/AcademicTraining';
import AreasOfInterest from '../../components/mievolucion/InterestsAndSkills/AreasOfInterest';
import WorkExperience from '../../components/mievolucion/InterestsAndSkills/WorkExperience';
import getURLParams from '../../helpers/routing/getURLParams';
import MessageModal from '../../components/shared/ModalMessage';
import MLError from '../../components/shared/Error';
import { completePath } from '../../helpers/strings';
import BannerLayout from '../../layouts/banner';
import { smallDevice } from '../../helpers/deviceVariables';
import { useWindowWidthSize } from '../../helpers/browser';
import { labelStatus, statusClasses } from '../../helpers/myEvolution/interestsAndSkills';
import { MY_EVOLUTION_CONTEXT } from '../../helpers/apollo';

moment.locale('es');
const context = MY_EVOLUTION_CONTEXT;

const NewSkills = (props) => {
  const width = useWindowWidthSize();
  const [formActive, setFormActive] = useState(null);
  const [modal, setModal] = useState({ visible: false });
  const {
    form: {
      getFieldDecorator, validateFields
    }

  } = props;

  const banner = {
    imageBanner: `${completePath('web/mi-evolucion/banner/intereses-y-habilidades-banner.jpg')}`,
    imageAvatar: `${completePath('web/mi-evolucion/avatars/intereses-y-habilidades-avatar.jpg')}`,
    title: 'Intereses y habilidades',
    subtitle: 'Mi Evolución'
  };
  const {
    loading,
    data,
    error,
    refetch
  } = useQuery(
    GET_LIST_INTERESTS_AND_SKILLS,
    {
      context
    }
  );
  const {
    loading: loadingShortList,
    data: dataShortList,
    error: errorShortList,
    refetch: refetchShortList
  } = useQuery(
    GET_LIST_INTERESTS_AND_SKILLS_DATA_SHORT,
    {
      context
    }
  );

  const { listInterestAndSkillsDataShort } = dataShortList || {};
  const {
    levelsOfStudies: listLevelsOfStudies,
    oralEnglishLevel: listOralEnglishLevel,
    writtenEnglishLevel: listWrittenEnglishLevel,
    platformLevels: listPlatformLevels,
    statusOfStudies: listStatusOfStudies,
    yearExperiences: listYearExperiences,
    personsLead: listPersonsLead
  } = listInterestAndSkillsDataShort || {};

  const listShort = {
    listLevelsOfStudies,
    listOralEnglishLevel,
    listWrittenEnglishLevel,
    listPlatformLevels,
    listStatusOfStudies,
    listYearExperiences,
    listPersonsLead
  };
  const query = getURLParams();
  const key = query.get('key');

  const { listInterestAndSkills } = data || {};
  const { academicTraining, dataInterestAreas, dataWorkExperience } = listInterestAndSkills || {};

  const getInfoActiveForm = () => {
    switch (formActive?.key) {
    case '0':
      return { data: academicTraining, mutation: UPDATE_ACADEMIC_TRAINING };
    case '1':
      return { data: dataWorkExperience, mutation: UPDATE_WORK_EXPERIENCE };
    case '2':
      return { data: dataInterestAreas, mutation: UPDATE_AREAS };

    default:
      return { data: null, mutation: UPDATE_ACADEMIC_TRAINING };
    }
  };

  const [updateData,
    { loading: loadingUpdate }] = useMutation(getInfoActiveForm().mutation, {
    context,
    refetchQueries: [
      {
        context,
        query: GET_LIST_INTERESTS_AND_SKILLS
      },
      {
        context,
        query: FIND_AREAS_DETAIL,
        variables: { dataComplete: true }
      }
    ],
    onCompleted: () => {
      setModal({
        visible: true,
        type: 'success',
        title: 'Los datos han sido guardados con éxito'
      });
    },
    onError: () => {
      setModal({
        visible: true,
        type: 'error',
        title: 'Los datos no han sido guardados con éxito, intenta más tarde'
      });
    }
  });

  const commonProps = {
    ...listShort,
    loadingUpdate,
    updateData,
    form: {
      getFieldDecorator, validateFields
    },
    ...getInfoActiveForm().data
  };
  const renderInfo = ({ status, updateAt }) => (
    <div className='box-last-update'>
      {status !== 'PENDING'
        && <div className='item'>
          <i className="icon-ml-calendar-2 primary-icon-color" />
          <p className='ml-2'>{capitalize(moment(updateAt)?.format('ddd[,] DD MMM YYYY')?.replaceAll('.', ''))}</p>
        </div>
      }
      <div className='item'>
        <i className={statusClasses(status)} />
        <p className='ml-2'>{labelStatus(status)}</p>
      </div>
    </div>
  );

  const parsedData = () => {
    if (!data || !dataShortList) return [];
    const res = [
      {
        showAvatar: false,
        key: '0',
        id: 'formación-académica',
        title: 'Formación académica',
        status: academicTraining.status,
        slug: '/mi-evolucion/intereses-y-habilidades/formación-académica?key=0',
        component: (_props) => <AcademicTraining {..._props} />,
        customDescription: renderInfo(academicTraining)
      },
      {
        showAvatar: false,
        key: '1',
        id: 'experiencia-laboral',
        title: 'Experiencia laboral',
        status: dataWorkExperience.status,
        slug: '/mi-evolucion/intereses-y-habilidades/experiencia-laboral?key=1',
        component: (_props) => <WorkExperience {..._props} />,
        customDescription: renderInfo(dataWorkExperience)
      },
      {
        showAvatar: false,
        key: '2',
        id: 'áreas-de-interes',
        title: 'Áreas de interés',
        status: dataInterestAreas.status,
        slug: '/mi-evolucion/intereses-y-habilidades/áreas-de-interes?key=2',
        component: (_props) => <AreasOfInterest {..._props} />,
        customDescription: renderInfo(dataInterestAreas)
      }
    ];

    return res;
  };

  useEffect(() => {
    const form = find(parsedData(), (item) => item.key === key);
    if (key && form) {
      setFormActive(form);
    } else {
      setFormActive(null);
    }
  }, [key, listInterestAndSkills, loading]);

  const leftScrollComponents = [
    error || errorShortList

      ? <MLError reload={ () => (error ? refetch() : refetchShortList()) } />
      : <HistoricalList
        key={0}
        loading={loading || loadingShortList}
        data={parsedData()}
        getActiveItem={(listItem) => formActive && listItem.key === formActive.key}
        getItemURL={(listItem) => listItem.slug}
      />

  ];

  return (
    <BannerLayout {...banner}
      showBanner={width > smallDevice}
      childrenClassName='p-0 container-interests-and-skills'
    >
      <div className={`box-intro ${formActive && width <= smallDevice && 'd-none'}`}>
        <p className='title'>¡Cuéntanos de ti!</p>
        <p className='description'>Mantén actualizados tus datos de formación académica y
          comparte con nosotros tus intereses laborales.
          Empodérate y sé tu mejor versión.</p>
        <p className='milugar'>¡Este es el Lugar!</p>
      </div>
      <Fragment>
        { modal.visible
      && <MessageModal {...{
        ...modal,
        closeModal: () => setModal({ visible: false })
      }}/>

        }
        <MLHistorical
          classes=''
          leftScrollComponents={leftScrollComponents}
        >
          {
            formActive
          && <div className='form'>
            <div className='container-title'>
              <p>{formActive.title}</p>
            </div>
            <div className='container-status'>
              <i className={statusClasses(formActive.status)} />
              <p>{labelStatus(formActive.status)}</p>
            </div>
            <formActive.component {...commonProps }/>
          </div>

          }
        </MLHistorical>
      </Fragment>
    </BannerLayout>

  );
};

export default Form.create()(NewSkills);

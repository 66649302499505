import { lazy } from 'react';

const MyEvolutionTeamEvaluationsHome = lazy(() => import('../../../../../containers/myTeam/myEvolution/Evaluations/Home'));

export default [
  {
    exact: true,
    path: '/mi-equipo/mi-evolucion/evaluaciones',
    section: 'Mi Equipo - Mi Evolución - Evaluaciones - General',
    component: MyEvolutionTeamEvaluationsHome
  }
];

import React from 'react';
import { useDispatch } from 'react-redux';
import AdminFileListReportsSockets from '../../../../shared/list/AdminFileListReportsSockets';
import {
  getEDDAutoevaluationsReport,
  getEDDEvaluationsReport,
  getEDDOpinionEvaluationReport
} from '../../../../../redux/actions/admin';
import { yearCurrentEvaluation } from '../../../../../helpers/myEvolution/evaluations';
import useReport, { REPORT_NAMES } from '../../../../../hooks/reports/useReport';
import useSocket from '../../../../../hooks/sockets/useSocket';

const PerformanceEvaluationReports = () => {
  const ws = useSocket();
  const dispatch = useDispatch();

  const eddAutoReport = useReport(REPORT_NAMES.EDD_AUTOEVALUATION);
  const eddEvalsReport = useReport(REPORT_NAMES.EDD_EVALUATION);
  const eddOpinionsReports = useReport(REPORT_NAMES.EDD_OPINION);

  const reportsToShow = [
    {
      title: 'Autoevaluaciones de desempeño',
      report: eddAutoReport,
      startFetch: () => dispatch(getEDDAutoevaluationsReport(yearCurrentEvaluation)),
      isAsync: true
    },
    {
      title: 'Evaluaciones de desempeño',
      report: eddEvalsReport,
      startFetch: () => dispatch(getEDDEvaluationsReport(yearCurrentEvaluation)),
      isAsync: true
    },
    {
      title: 'Opinión de evaluaciones de desempeño Comunicadas',
      report: eddOpinionsReports,
      startFetch: () => dispatch(getEDDOpinionEvaluationReport(ws.socket.id, yearCurrentEvaluation))
    }
  ];
  return (
    <>
      {reportsToShow.map((item, index) => <AdminFileListReportsSockets
        key={index}
        {...item}
      />)}
    </>
  );
};

export default PerformanceEvaluationReports;

import React from 'react';
import Loader from 'react-loaders';
import 'loaders.css/loaders.css';
import '../../static/styles/shared/MLLoading.less';
import PropTypes from 'prop-types';

const MLLoading = (props) => {
  const { title, message, className } = props;

  const getClassName = () => {
    let res = 'container-loading d-flex flex-column justify-content-center align-items-center';
    if (className) res += ` ${className}`;
    return res;
  };

  return (
    <div className={getClassName()}>
      <Loader type="ball-pulse" color="#007bc4" />
      {title && <h1 className="text-center">{title}</h1>}
      {message && <p className="text-center">{message}</p>}
    </div>
  );
};

MLLoading.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  className: PropTypes.string
};

MLLoading.defaultProps = {
  title: '',
  message: '',
  className: ''
};

export default MLLoading;

export const actionTypes = {
  GET_VALORATIONS_TO_ME: 'GET_VALORATIONS_TO_ME',
  GET_VALORATIONS_TO_ME_SUCCESS: 'GET_VALORATIONS_TO_ME_SUCCESS',
  GET_VALORATIONS_TO_ME_ERROR: 'GET_VALORATIONS_TO_ME_ERROR',
  GET_VALORATIONS_TO_OTHERS: 'GET_VALORATIONS_TO_OTHERS',
  GET_VALORATIONS_TO_OTHERS_SUCCESS: 'GET_VALORATIONS_TO_OTHERS_SUCCESS',
  GET_VALORATIONS_TO_OTHERS_ERROR: 'GET_VALORATIONS_TO_OTHERS_ERROR',
  RESET_MIS_RECONOCIMIENTOS: 'RESET_MIS_RECONOCIMIENTOS'
};

export function getValorationsToMe() {
  return {
    type: actionTypes.GET_VALORATIONS_TO_ME
  };
}

export function getValorationsToMeSuccess(payload) {
  return {
    type: actionTypes.GET_VALORATIONS_TO_ME_SUCCESS,
    payload
  };
}

export function getValorationsToMeError(error) {
  return {
    type: actionTypes.GET_VALORATIONS_TO_ME_ERROR,
    error
  };
}

export function getValorationsToOthers() {
  return {
    type: actionTypes.GET_VALORATIONS_TO_OTHERS
  };
}

export function getValorationsToOthersSuccess(payload) {
  return {
    type: actionTypes.GET_VALORATIONS_TO_OTHERS_SUCCESS,
    payload
  };
}

export function getValorationsToOthersError(error) {
  return {
    type: actionTypes.GET_VALORATIONS_TO_OTHERS_ERROR,
    error
  };
}

/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

const Step = ({
  isCompleted, closedMark, selectedStep, status, showIconCheck, data, i
}) => (
  <div
    className='container-status'
  >
    <div className='box-container-number'>
      <div className={`border-circle ${
        isCompleted ? 'active' : selectedStep.numberStatus - 1 === i ? 'active' : (!closedMark || !(selectedStep.numberStatus - 1 > i)) && 'inactive'} `}>
        <div
          // eslint-disable-next-line no-nested-ternary
          data-testid="icon"
          className={` ${showIconCheck && isCompleted ? 'icon-done '
            : showIconCheck && status.toLowerCase() !== 'finished' ? 'icon-done-primary'
              : `container-number ${selectedStep.numberStatus - 1 === i || isCompleted ? 'active' : ''}`

          }`}>
          {
            showIconCheck
              ? <i className='icon-me-check' />
              : <span>{i + 1}</span>
          }
        </div>
      </div>
    </div>

    <p className={` ${(selectedStep.numberStatus - 1 === i && status.toLowerCase() !== 'finished') || isCompleted ? 'text-active lol' : ''}`}>{data.label}</p>

    <p className='ml-steps-status-description'>{data.description}</p>
  </div>
);

Step.propTypes = {
  isCompleted: PropTypes.bool,
  closedMark: PropTypes.bool,
  selectedStep: PropTypes.object,
  status: PropTypes.string,
  showIconCheck: PropTypes.bool,
  data: PropTypes.object,
  i: PropTypes.number
};

Step.defaultProps = {
  isCompleted: false,
  closedMark: false,
  selectedStep: {},
  status: '',
  showIconCheck: false,
  data: {},
  i: 0
};

export default Step;

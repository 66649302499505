import React from 'react';
import PropTypes from 'prop-types';

const Back = (props) => {
  const { back } = props;

  return (
    <div
      className='container-button-back'
      onClick={(e) => {
        e.preventDefault();
        back();
      }}
    >
      <i className='icon-ml-back'/>
      <span>Volver</span>
    </div>

  );
};

Back.propTypes = {
  back: PropTypes.func
};

Back.defaultProps = {
  back: () => {}
};

export default Back;

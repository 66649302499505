import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { setMLFormatDate } from '../../../../../helpers/dates';
import InfoMessage from '../../../../shared/InfoMessage';
import AdvanceMonthsBoxes from './AdvanceMonthsBoxes';
import ModalMessage from '../../../../shared/ModalMessage';
import AdvanceSuccessMeasureModalMessage from './AdvanceSuccessMeasureModalMessage';
import AdvanceModalInfo from './AdvanceModalInfo';
import usePerformance from '../../../../../hooks/myEvolution/myPerformance/usePerformance';
import { FIRST_PULSE } from '../../../../../helpers/myEvolution/myPerformance';

const ListOfSuccessMeasures = ({
  bossMode,
  dataSuccessMeasures: data,
  isMobile,
  currentQuarter,
  showAdvanceModal,
  livePulse
}) => {
  const { data: performance = {} } = usePerformance();
  const { status } = performance;
  const [modal, setModal] = useState({
    visible: false,
    title: 'Avance de las medida de éxito',
    icon: 'icon-me-info-regular primary-icon-color',
    showIcon: !isMobile,
    wrapClassName: 'list-of-measures-modal-container',
    message: AdvanceSuccessMeasureModalMessage(isMobile),
    buttons: [
      {
        text: 'Entendido',
        type: 'primary',
        onClickButton: () => {
          setModal({
            ...modal,
            visible: false
          });
        }
      }
    ],
    closeButton: !!isMobile,
    closeModal: () => setModal({ ...modal, visible: false }),
    style: { maxWidth: isMobile ? '90%' : '584px' },
    bodyStyle: { padding: isMobile && '6px 0 0 0' }
  });
  let successMeasures = null;
  const moreThanOneMeasure = data.length > 1;
  const isSuccessMeasures = data.length > 0;

  const pendingByColaborate = status?.current === 'PENDING_BY_COLABORATE';
  const returned = status?.current === 'RETURNED';

  if (isSuccessMeasures) {
    successMeasures = data.map((measure, indexMeasure) => {
      if (!measure.updates) {
        const statuses = { status: 'WITHOUTINFO' };
        measure.updates = {
          q1: statuses,
          q2: statuses,
          q3: statuses,
          q4: statuses
        };
      }
      const untouchedMeasures = ['WITHOUTINFO', null, '-'].includes(measure.updates[currentQuarter]?.status);
      const shouldEditObjective = untouchedMeasures && (pendingByColaborate || returned);
      return (
        <div className='list-of-success-measures' key={uuidv4()}>
          {!bossMode && shouldEditObjective && indexMeasure === 0 && <Row
            className='mb-2'
            gutter={[32, 16]}>
            {
              currentQuarter !== FIRST_PULSE

            && <Col span={24}>
              <InfoMessage
                message='Edita el objetivo para agregar el avance de tus medidas de éxito'
                suave={true}
              />
            </Col>
            }
          </Row>}
          <Row gutter={[32, 0]}>
            <Col span={isMobile ? 24 : 15}>
              <p className='objective-body'>
                <span className='index-measure'>{indexMeasure + 1}. </span>
                <span className='description-measure'>{measure.description}</span>
              </p>
            </Col>
            <Col span={isMobile ? 24 : 9}>
              <p>{setMLFormatDate(Number(measure.dateMeasure))}</p>
            </Col>
          </Row>
          {
            currentQuarter !== FIRST_PULSE
          && <Row gutter={[32, 0]} className='mb-4'>
            <Col span={24}>
              { showAdvanceModal && livePulse
            && <AdvanceModalInfo
              onClick={() => setModal({
                ...modal,
                visible: true
              })}
            />
              }
              { livePulse
              && <AdvanceMonthsBoxes
                data={measure?.updates}
                isMobile={isMobile}
              />
              }
            </Col>
          </Row>
          }

          {moreThanOneMeasure && indexMeasure !== data.length - 1
            && <Row gutter={[32, 0]}>
              <Col>
                <div className='expanded-separator'/>
              </Col>
            </Row>}
        </div>);
    });
  } return (<>
    <Row
      gutter={[32, 0]}>
      <Col span={isMobile ? 24 : 15}>
        <p className='objective-title'>Medidas de éxito:</p>
      </Col>
      <Col span={isMobile ? 24 : 9}>
        <p className='objective-title'>Fecha límite:</p>
      </Col>
    </Row>
    {successMeasures}
    <ModalMessage { ...modal }/>
  </>);
};

ListOfSuccessMeasures.propTypes = {
  bossMode: PropTypes.bool,
  dataSuccessMeasures: PropTypes.array,
  isMobile: PropTypes.bool,
  currentQuarter: PropTypes.string,
  showAdvanceModal: PropTypes.bool
};

ListOfSuccessMeasures.defaultProps = {
  bossMode: false,
  dataSuccessMeasures: [],
  isMobile: false,
  currentQuarter: FIRST_PULSE,
  showAdvanceModal: true
};

export default ListOfSuccessMeasures;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import '../../../../static/styles/shared/MLModal/ml-modal-with-fixed-layout.less';

const DefaultOpenButton = ({
  text, onClick
}) => (
  <Button type="primary" onClick={onClick}>
    {text}
  </Button>
);

DefaultOpenButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string
};

DefaultOpenButton.defaultProps = {
  onClick: () => null,
  text: 'Abrir Modal'
};

export default DefaultOpenButton;

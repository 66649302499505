import React from 'react';
import MLSpanListHorizontal from './shared/MLSpanListHorizontal';
import MLSpanListVertical from './shared/MLSpanListVertical';

const WeightsTotalAndByPillar = ({
  isMobile, pillarsWeights, totalWeights
}) => (
  <>{
    isMobile
      ? <MLSpanListVertical
        items={{ pillarsWeights, totalWeights }}
      />
      : <div className="mo-weights row-flex">
        <MLSpanListHorizontal
          items={totalWeights}
        />
        <MLSpanListHorizontal
          items={pillarsWeights}
        />
      </div>
  }</>);

export default WeightsTotalAndByPillar;

import { useLocation } from 'react-router-dom';

const useSectionName = () => {
  const { pathname } = useLocation();

  return pathname.substring(
    1, pathname.length
  ).split('/')[0];
};

export default useSectionName;

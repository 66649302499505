/* eslint-disable no-shadow */
import React, { useState, useRef, useEffect } from 'react';
import {
  Carousel
} from 'antd';
import { useMutation } from '@apollo/client';
import InfoMessage from '../../../shared/InfoMessage';
import { completePath } from '../../../../helpers/strings';
import GeneralResult from './GeneralResult';
import { SAVE_SKILL_LEVEL_BEHAVIOR_CHOICE } from '../../../../graphql/mievolucion/evolucionaTEST/mutations';

const messages = [
  'Trabajo de forma autónoma, relacionandome solo con mi equipo y jefaturas para el logro de los objetivos.',
  'Me identifico muy poco con la conducta.',
  'Me identifico con una parte de la conducta.',
  'Me identifico con la mayor parte de la conducta.',
  'Me identifico 100% con la conducta.'
];
const Evaluation = (props) => {
  const {
    setBehaviorChoices,
    mainActiveSlide,
    setModalOptions,
    ButtonNext, skillSelected, behaviors, setBehaviors, rut, next: nextMainSlider
  } = props;
  const refSliderEvaluation = useRef();

  const [getActionsByBehaviors, { loading }] = useMutation(
    SAVE_SKILL_LEVEL_BEHAVIOR_CHOICE,
    {
      context: { clientName: 'myEvolution' },
      onCompleted: (response) => {
        const { saveSkillLevelBehaviorChoice } = response;
        const newBehaviorChoices = saveSkillLevelBehaviorChoice.map(
          (behaviorActions) => ({
            choice: behaviorActions.choice,
            behavior: {
              ...behaviorActions.behavior,
              actions: behaviorActions.behavior.actions.map((item) => ({ ...item, checked: false }))
            }
          })
        );
        setBehaviorChoices(newBehaviorChoices);
        nextMainSlider();
      },
      onError: () => {
        setModalOptions({
          visible: true,
          title: 'Ocurrió un error guardando la información',
          message: 'Por favor intenta de nuevo',
          type: 'error'
        });
      }
    }
  );

  const [carouselState, setCarouselState] = useState({
    activeSlide: 0
  });
  const [showGeneralResult, setShowGeneralResult] = useState(false);

  const carouselSettings = {
    dots: false,
    autoplay: false,
    swipeToSlide: false,
    swipe: false,
    afterChange: (current) => {
      setCarouselState({ activeSlide: current });
    }
  };

  const slickGoToInitial = () => {
    if (refSliderEvaluation && refSliderEvaluation.current) {
      setShowGeneralResult(false);
      refSliderEvaluation.current.slick.slickGoTo(0);
    }
  };
  useEffect(() => {
    slickGoToInitial();
    setCarouselState({ activeSlide: 0 });
  }, [mainActiveSlide]);

  const next = () => {
    refSliderEvaluation.current.next();
  };
  const handleEditTest = () => {
    setCarouselState({ activeSlide: 0 });
    slickGoToInitial();
    setShowGeneralResult(false);
  };

  const renderStep = () => behaviors.map((data, indexBehavior) => (
    <div key={indexBehavior} >
      <div className='container-evaluation'>
        <h1>{skillSelected.name}</h1>
        <h6>Conducta Nº{indexBehavior + 1}</h6>
        <p>{data.name}</p>
        <div className="container-emoticons">
          <div className='box-emoticons'>
            <div className='line' />
            <div className='emoticons'>
              {messages.map((message, indexEmoticon) => (
                <div key={indexEmoticon} className={data.choice === (indexEmoticon + 1) ? 'img-active' : 'img-inactive'}>
                  <img
                    onClick={() => {
                      const newBehaviors = behaviors.map((behavior, indexSetValueEmoticon) => (
                        {
                          ...behavior,
                          choice: indexBehavior === indexSetValueEmoticon
                            ? (indexEmoticon + 1)
                            : behavior.choice

                        }
                      ));
                      setBehaviors(newBehaviors);
                    }}
                    className="img-fluid" src={completePath(`web/mi-evolucion/servicios/inicio/emoticones-evolucionaTEST/${indexEmoticon + 1}.png`)} alt="" />
                </div>
              ))
              }
            </div>
          </div>
        </div>
        {data.choice

     && <div className='d-flex justify-content-center align-items-center'>
       <div className='box-text-emoticons'>
         <div className={`triangle-evaluation-${data.choice}`} />
         <div className={`evaluation-${data.choice}`}>
           <p>
             {messages[data.choice - 1]}
           </p>
         </div>
       </div>
     </div>
        }
      </div>
      <ButtonNext
        disabled={!data.choice}
        onClick={
          behaviors.length - 1 === carouselState.activeSlide
            ? (e) => {
              e.preventDefault();
              setShowGeneralResult(true);
            }
            : next
        }
      />
    </div>
  ));

  const getActions = () => {
    const choices = behaviors.map(({ _id, choice }) => ({ behaviorId: _id, choice, dni: rut }));

    getActionsByBehaviors({
      variables: {
        choices
      }
    });
  };

  return (
    <div className="container-evolucionaTest">
      { !showGeneralResult
        ? <div>
          {
            skillSelected && behaviors.length === 0
              ? <InfoMessage
                message={`Lo sentimos, no hay conductas asociadas a ${skillSelected.name}`}
              />
              : <InfoMessage
                classes='mb-4'
                message='Selecciona el emoticón que más te identifique en cada conducta.'
              />

          }
          <Carousel {...carouselSettings} ref={refSliderEvaluation}>
            {
              behaviors
              && (renderStep())
            }
          </Carousel>
        </div>
        : <GeneralResult
          getActions={getActions}
          handleEditTest={handleEditTest}
          ButtonNext={ButtonNext}
          messages={messages}
          behaviors={behaviors}
          loading={loading}
          skillSelected={skillSelected} />
      }
    </div>
  );
};

export default Evaluation;

import qs from 'qs';
import { map } from 'lodash';
import axiosNative from 'axios';
import axios from '../../helpers/axios';

import { completePath } from '../../helpers/strings';
import { getEnvVar } from '../../helpers/commons';
import { getItem } from '../../helpers/cookiesUtil';

const BFF_ENDPOINT = getEnvVar('REACT_APP_PUBLIC_BFF_ENDPOINT');

export const getMainBenefits = () => [
  {
    title: 'Teletrabajo',
    picture: 'teletrabajo.jpg'
  },
  {
    title: 'Titulo 2',
    picture: 'teletrabajo.jpg'
  }
];

export const getCategories = () => axios().get(`${BFF_ENDPOINT}/category/list`).then((resp) => resp.data);

export const getBenefitStatus = ({ benefit }) => axios().get(`${BFF_ENDPOINT}/benefit/${benefit}`).then((resp) => resp.data);

export const postBenefitRequestFile = ({ file, benefit }) => {
  const data = new FormData();
  data.append('file', file[0]);

  const token = getItem('token');
  return axiosNative({
    method: 'post',
    url: `${BFF_ENDPOINT}/benefit/${benefit}`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  })
    .then((resp) => resp.data)
    .catch((err) => err);
};

export const postBenefitRequest = (params) => axios().post(`${BFF_ENDPOINT}/benefit/${params.benefit}`, params).then((resp) => resp.data.response);

export const getBenefitDetails = ({ id, params = null }) => axios().get(`${BFF_ENDPOINT}/benefit/submenu/${id}${(params && params.category) !== 'null' ? `?category=${params.category}` : ''}`, params).then((resp) => resp.data);

export const getHomeBenefits = (params) => getCategories(params).then((response) => ({
  name: 'Mis beneficios',
  items: map(response, (o) => ({
    _id: o._id,  // eslint-disable-line
    nameCategory:
          o.nameCategory,
    image: o.image,
    webCover: o.webCover,
    link: `mio/benefit/${o.benefits[0]._id}`  // eslint-disable-line
  }))
}));

export const getHealthyLife = () => ({
  name: 'Vida Saludable',
  items: [
    {
      nameCategory: 'Mi Salud',
      webCover: completePath('web/mio/servicios/vivo-saludable/servicios/mi-salud.jpg')
    },
    {
      nameCategory: 'Mi apoyo',
      webCover: completePath('web/mio/servicios/vivo-saludable/servicios/mi-apoyo.jpg')
    },
    {
      nameCategory: 'Mis finanzas',
      webCover: completePath('web/mio/servicios/vivo-saludable/servicios/mis-finanzas.jpg')
    }
  ]
});

export const getHumanResourcesInitiatives = () => ({
  name: 'Iniciativas de Recursos Humanos',
  items: [
    {
      nameCategory: 'Vive nuestra cultura',
      webCover: completePath('web/mio/servicios/vivo-saludable/servicios/vive-nuestra-cultura.jpg')
    },
    {
      nameCategory: 'Conoce tu bono anual',
      webCover: completePath('web/mio/servicios/vivo-saludable/servicios/bono-anual.jpg')
    },
    {
      nameCategory: 'Programa de apadrinamiento',
      webCover: completePath('web/mio/servicios/vivo-saludable/servicios/programa-de-apadrinamiento.jpg')
    },
    {
      nameCategory: 'Elige formarte',
      webCover: completePath('web/mio/servicios/vivo-saludable/servicios/elije-formarte.jpg')
    }
  ]
});

export const getNetworks = () => ({
  name: 'Red de convenios',
  items: [
    {
      nameCategory: 'Salud'
    },
    {
      nameCategory: 'Educación'
    },
    {
      nameCategory: 'Hogar'
    },
    {
      nameCategory: 'Ocio'
    },
    {
      nameCategory: 'Regalos'
    },
    {
      nameCategory: 'Belleza'
    },
    {
      nameCategory: 'Familia'
    },
    {
      nameCategory: 'Servicios'
    }
  ]
});

export const getHomeCategories = (params) => Promise.all([
  getHomeBenefits(params)
  // getHealthyLife(),
  // getHumanResourcesInitiatives(),
  // getNetworks()
]).then((response) => response);

export const getTeamRequests = (params) => axios().get(`${BFF_ENDPOINT}/user/requests/benefits?${qs.stringify(params)}`).then((resp) => resp.data);

export const postRequestStatus = ({
  benefit, status, observations = ''
}) => axios().post(`${BFF_ENDPOINT}/admin/benefit/${benefit}/request/${status}`, { observations }).then((resp) => resp.data);

export const getFlickrAlbum = ({ benefit }) => axios().get(`${BFF_ENDPOINT}/benefit/${benefit}/flickr`).then((resp) => resp.data);

export const getMyBenefitsRequested = (params) => axios().get(`${BFF_ENDPOINT}/user/own/benefits?${qs.stringify(params)}`).then((resp) => resp.data);

export const getMonthlyStatus = ({ month }) => axios().get(`${BFF_ENDPOINT}/user/month/${month}/status`).then((resp) => resp.data);

export const getGiftDayWeeksAPI = ({ benefitId }) => axios().get(`${BFF_ENDPOINT}/benefit/${benefitId}/metadata`).then((resp) => resp.data);

export const getSchoolWinnersAPI = () => axios().get(`${BFF_ENDPOINT}/benefit/school-stimulus-winners`).then((resp) => resp.data);

import { isEqual } from 'lodash';
import { useState } from 'react';

export function useRootLayout() {
  const [rootLayoutProps, setRootLayoutProps] = useState({
    headerTitle: null,
    hideBanner: false,
    section: null
  });

  const headerProps = { headerTitle: null };

  const setRootLayoutPropsCallback = (newProps) => {
    const res = { ...newProps };
    const headerTitleHTML = document.getElementById('main-header-title');
    const headerCustomTitleHTML = document.getElementById('main-header-custom-title');
    const childrenContainerHTML = document.getElementById('main-layout-children-container');

    if (res.headerTitle !== headerProps.headerTitle && headerTitleHTML) {
      headerProps.headerTitle = res.headerTitle;

      if (res.headerTitle) {
        headerTitleHTML.style.display = 'none';
        headerCustomTitleHTML.style.display = 'block';
      } else {
        headerTitleHTML.style.display = 'block';
        headerCustomTitleHTML.style.display = 'none';
      }

      headerCustomTitleHTML.innerHTML = headerProps.headerTitle;
    }

    if (childrenContainerHTML) {
      if (res.hideBanner) {
        childrenContainerHTML.classList.add('banner-layout-hidden');
      } else {
        childrenContainerHTML.classList.remove('banner-layout-hidden');
      }
    }
  };

  const changeAppLayoutProps = (opts) => {
    setRootLayoutPropsCallback(opts);
    if (!isEqual({ ...opts, ...rootLayoutProps }, rootLayoutProps)) {
      setRootLayoutProps(opts);
    }
  };

  return changeAppLayoutProps;
}

import React, { Fragment } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import getObjectivesValidateValues from '../../../../../../../helpers/myEvolution/myPerformance/getObjectivesValidateValues';

const isNumber = (str) => /^[0-9]*(\.[0-9]*)?$/.test(str);

const ObjectiveWeight = ({ form, FormItem, successMeasuresOnly }) => {
  const { getFieldDecorator } = form;
  const { minWeightInput, maxWeightInput } = getObjectivesValidateValues();

  const handleKeyPress = (e) => {
    const { value } = e.target;
    if (!isNumber(value + e.key)) e.preventDefault();
  };

  const handleChange = (e) => {
    const { value } = e.target;
    e.target.value = value.replace(/[^\d.]/g, '');
  };

  const handleFocus = (e) => {
    const { value } = e.target;
    if (!isNumber(value)) {
      e.preventDefault();
      e.target.value = value.slice(0, value.length - 1);
    }
  };

  const handleBlur = (e) => {
    let { value } = e.target;
    if (value.length) value += '%';
    e.target.value = value;
  };

  const getClassName = () => {
    let classNameResult = 'objective-weight-field';
    if (!successMeasuresOnly) classNameResult += ' objective-weight-field-with-leyend';
    return classNameResult;
  };

  return (
    <FormItem
      className={getClassName()}
      label={(
        <>
          <i className='icon-ml-ponderacion color-weighing'/>
          <label>Ponderación:</label>
        </>
      )}
    >
      {getFieldDecorator('weight', {
        rules: [
          {
            validator: (_, value, callback) => {
              const weight = parseFloat(value.replace(/[^\d.]/g, ''));

              if (weight < minWeightInput || weight > maxWeightInput) {
                return callback('Ingresa una ponderación entre 5% y 50%.');
              }

              return callback();
            }
          }
        ]
      })(
        <Input
          placeholder='Ej: 25%'
          onKeyPress={handleKeyPress}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={successMeasuresOnly}
        />
      )}
    </FormItem>
  );
};

ObjectiveWeight.propTypes = {
  form: PropTypes.object,
  FormItem: PropTypes.elementType,
  successMeasuresOnly: PropTypes.bool
};

ObjectiveWeight.defaultProps = {
  form: {},
  FormItem: Fragment,
  successMeasuresOnly: false
};

export default ObjectiveWeight;

import React from 'react';
import PropTypes from 'prop-types';
import '../../../../../static/styles/mievolucion/potential-evaluation/donut.less';

const Donut = ({ className }) => (
  <div className={`legend-donut${className ? ` ${className}` : ''}`}/>
);

Donut.propTypes = {
  className: PropTypes.string
};

Donut.defaultProps = {
  className: ''
};

export default Donut;

import { actionTypes } from '../actions/profile';

export const initialState = {
  emergencyData: {},
  finishedEmergencyData: false,
  loadingEmergencyData: false,
  errorEmergencyData: false,

  loadingUpdateEmergencyData: false,
  errorUpdateEmergencyData: false,
  successUpdateEmergencyData: false
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_EMERGENCY_DATA:
    return {
      ...state,
      loadingEmergencyData: true,
      finishedEmergencyData: false,
      errorEmergencyData: false,
      loadingUpdateEmergencyData: false,
      errorUpdateEmergencyData: false,
      successUpdateEmergencyData: false
    };

  case actionTypes.GET_EMERGENCY_DATA_SUCCESS:
    return {
      ...state,
      // emergencyData: { mainContact: false, secondaryContact: false },
      emergencyData: action.payload.data,
      loadingEmergencyData: false,
      finishedEmergencyData: true,
      errorEmergencyData: false
    };

  case actionTypes.GET_EMERGENCY_DATA_ERROR:
    return {
      ...state,
      loadingEmergencyData: false,
      errorEmergencyData: true
    };

  case actionTypes.UPDATE_EMERGENCY_DATA:
    return {
      ...state,
      loadingUpdateEmergencyData: true,
      errorUpdateEmergencyData: false,
      successUpdateEmergencyData: false
    };

  case actionTypes.UPDATE_EMERGENCY_DATA_SUCCESS:
    // eslint-disable-next-line no-case-declarations
    const updateEmergencyData = state.emergencyData;
    if (action.payload.typeContact === 'mainContact') {
      updateEmergencyData.mainContact = action.payload.resp;
    } else {
      updateEmergencyData.secondaryContact = action.payload.resp;
    }
    return {
      ...state,
      emergencyData: updateEmergencyData,
      loadingUpdateEmergencyData: false,
      errorUpdateEmergencyData: false,
      successUpdateEmergencyData: true
    };

  case actionTypes.UPDATE_EMERGENCY_DATA_ERROR:
    return {
      ...state,
      loadingUpdateEmergencyData: false,
      errorUpdateEmergencyData: true,
      successUpdateEmergencyData: false
    };

  case actionTypes.RESET_UPDATE_EMERGENCY_DATA:
    return {
      ...state,
      loadingUpdateEmergencyData: false,
      errorUpdateEmergencyData: false,
      successUpdateEmergencyData: false
    };
  default:
    return state;
  }
}

export default reducer;

import { lazy } from 'react';

const NuestraCompania = lazy(() => import('../../containers/nuestraCompania'));
const MissionAndValues = lazy(() => import('../../containers/nuestraCompania/missionAndValues'));

export default [
  {
    exact: true,
    path: '/nuestra-compania',
    section: 'Nuestra Compañía',
    component: NuestraCompania
  },
  {
    exact: true,
    path: '/nuestra-compania/mision-y-valores',
    section: 'Nuestra Compañía -> Misión y Valores',
    component: MissionAndValues
  }
];

import React from 'react';
import PropTypes from 'prop-types';
import BannerLayout from '../../layouts/banner';
import '../../static/styles/my-team/my-evolution/container.less';
import { useWindowWidthSize } from '../../helpers/browser';
import { smallDevice } from '../../helpers/deviceVariables';

const Container = ({ children, banner }) => {
  const width = useWindowWidthSize();

  return (banner && width > smallDevice ? (
    <BannerLayout {...banner}>{children}</BannerLayout>
  ) : (
    children
  ));
};

Container.propTypes = {
  banner: PropTypes.object,
  children: PropTypes.element
};

Container.defaultProps = {
  banner: null,
  children: <></>
};

export default Container;

// import React, { useEffect, useRef } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';
import AdminFileListReportsSockets from '../../../../shared/list/AdminFileListReportsSockets';
import {
  getFeedbackNetworkReport,
  getCompetenciesReport,
  getPerformanceReport,
  getStatusFeedbackNetworkReport
} from '../../../../../redux/actions/admin';
import useReport, { REPORT_NAMES } from '../../../../../hooks/reports/useReport';
import useSocket from '../../../../../hooks/sockets/useSocket';

const PerformanceReports = () => {
  const ws = useSocket();
  const dispatch = useDispatch();

  const performanceReport = useReport(REPORT_NAMES.PERFORMANCE);
  const feedbackNetworksReport = useReport(REPORT_NAMES.FEEDBACK_NETWORKS);
  const competenciesReport = useReport(REPORT_NAMES.COMPETENCIES);
  const statusFeedbackNetworkReport = useReport(REPORT_NAMES.STATUS_FEEDBACK_NETWORK);

  const reportsToShow = [
    {
      title: 'Reporte de Performance',
      report: performanceReport,
      startFetch: () => dispatch(getPerformanceReport(ws.socket.id, 2021))
    },
    {
      title: 'Red de Feedback',
      report: feedbackNetworksReport,
      startFetch: () => dispatch(getFeedbackNetworkReport(ws.socket.id, 2021))
    },
    {
      title: 'Competencias',
      report: competenciesReport,
      startFetch: () => dispatch(getCompetenciesReport(ws.socket.id, 2021))
    },
    {
      title: 'Resultados Red de Feedback',
      report: statusFeedbackNetworkReport,
      startFetch: () => dispatch(getStatusFeedbackNetworkReport(ws.socket.id))
    }
  ];
  return (
    <>
      {reportsToShow.map((item, index) => <AdminFileListReportsSockets
        key={index}
        {...item}
      />)}
    </>
  );
};

export default PerformanceReports;

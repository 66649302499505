import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import MLTable from '../../../../shared/tables/MLTable';
import MLModalWithFixedLayout from '../../../../shared/MLModal/WithFixedLayout';
import EditObjective from './forms/EditObjective';
import MLLinkButton from '../../../../shared/buttons/LinkButton';
import ObjectivesOpenButtonModal from './ObjectivesOpenButtonModal';
import ModalMessage from '../../../../shared/ModalMessage';
import MLModalMessage from '../../../../shared/MLModal/Message';
import modalObjectivesChildren from './modalObjectivesChildren';
import Message from '../../../../shared/InfoMessage';
import { DELETE_OBJECTIVE_WALMART } from '../../../../../graphql/mievolucion/objectives/mutations';
import getType from '../../../../../helpers/myEvolution/myPerformance/getType';
import borderDiv from './borderDiv';
import ListOfSuccessMeasures from './ListOfSuccessMeasures';
import MLAddButton from '../../../../shared/buttons/AddButton';
import AdvanceComments from './AdvanceComments';
import AdvanceModalHeader from './AdvanceModalHeader';
import '../../../../../static/styles/mievolucion/objectives2022/myPerformanceTwoGroupsTable.less';
import {
  FIRST_PULSE, SPECIFIC_OBJECTIVE_KEY, pillarName
} from '../../../../../helpers/myEvolution/myPerformance';

const ObjectiveDeleteModal = MLModalMessage;

const MyPerformanceTwoGroupsTable = ({
  actionsVisible,
  disabled,
  editAdvance,
  closePerformance,
  bossName,
  dataPerformanceWalmart,
  expandedRowKeys,
  handleExpand,
  isMobile,
  refetch,
  visibleReport,
  commentFormRef,
  bossParams,
  addButton,
  withPulseAndManualObjective,
  showBossInputComment,
  livePulse
}) => {
  const { quarterCurrent: currentQuarter, comments } = dataPerformanceWalmart || {};
  const q1 = currentQuarter === FIRST_PULSE;
  const bossMode = !!bossParams;
  const [dataToTable, setDataToTable] = useState(({}));
  const [dimensions, setDimensions] = useState(null);
  const [mountedTable, setMountedTable] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    visible: false,
    title: 'Error',
    icon: 'icon-me-info-regular primary-icon-color',
    message: 'Ha ocurrido un error',
    buttons: [
      {
        text: 'Entendido',
        type: 'primary',
        onClickButton: () => {
          setModalMessage({
            ...modalMessage,
            visible: false
          });
        }
      }
    ]
  });
  const [deleteObjectiveWalmartById] = useMutation(DELETE_OBJECTIVE_WALMART, {
    context: { clientName: 'myEvolution' }
  });
  const [modal, setModal] = useState({
    visible: false,
    title: 'Objetivos visibles u ocultos a tus reportes',
    icon: 'icon-me-info-regular primary-icon-color',
    message: 'Tus reportes verán tus objetivos como base para definir los suyos, excepto los que hayas dejado ocultos.',
    buttons: [
      {
        text: 'Entendido',
        type: 'primary',
        onClickButton: () => {
          setModal({
            ...modal,
            visible: false
          });
        }
      }
    ],
    closeModal: () => setModal({ ...modal, visible: false })
  });

  const handlerApproveDelete = async (events, objectiveId) => {
    events.startLoading();
    try {
      await deleteObjectiveWalmartById({
        variables: {
          input: { objective: objectiveId }
        }
      });
      await events.closeModal();
      refetch();
    } catch (_) {
      events.endLoading();
    }
  };

  const objectiveRefs = useRef([]);

  useEffect(() => {
    if (mountedTable) {
      const array = objectiveRefs.current.map((node) => (node.getBoundingClientRect()));
      setDimensions(array);
    }
  }, [mountedTable]);

  const descriptionMyObjectives = !bossMode
  && withPulseAndManualObjective && <MLModalWithFixedLayout
    title='¿Cómo definir mi propuesta de objetivos?'
    openButtonText='¿Cómo defino mi propuesta de objetivos?'
    OpenButton={ObjectivesOpenButtonModal}
  >
    {modalObjectivesChildren()}
  </MLModalWithFixedLayout>;

  const handleEdit = async ({ events }) => {
    await events.closeModal();
    refetch();
  };

  const handleClickVisibleObjective = () => setModal({
    ...modal,
    visible: true
  });
  const handleExpandedRowRender = (data, key) => {
    const dataLength = data.successMeasures.length;
    const isSuccessMeasures = dataLength > 0;
    const isActionsVisible = !isMobile && actionsVisible;
    const noSuccessMeasures = data.weight === '0%' && <Message
      color='info'
      message={isMobile
        ? 'Edita desde el computador este objetivo para agregar ponderación y medidas de éxito.'
        : 'Edita este objetivo para agregar ponderación y medidas de éxito.'
      }
      suave={true}
    />;

    const visibleIcon = data.visible ? 'icon-ml-ojo-ver' : 'icon-ml-login-ocultar-contrasena';
    const visibleText = data.visible ? 'visible' : 'oculto';
    return (<div key={`expanded-row-${key}`} className='expanded-row'>
      {<><Row gutter={[32, 0]}>
        { data?.pillar?.name !== pillarName[SPECIFIC_OBJECTIVE_KEY]
        && <Col span={isMobile ? 24 : 15} className='objective-col left'>
          <p className='objective-title'>Descripción del objetivo:</p>
          <p className='objective-body'>{data.objectiveDescription || 'Sin descripción'}</p>
        </Col>
        }

      </Row>
      <div className='expanded-separator'/></>}
      {(!isMobile) && <div className='expanded-separator'/>}

      {isSuccessMeasures && (
        <ListOfSuccessMeasures
          livePulse={livePulse}
          bossMode={bossMode}
          dataSuccessMeasures={data.successMeasures}
          isMobile={isMobile}
          currentQuarter={currentQuarter}
        />
      )}

      {isActionsVisible && (
        <Row>
          <Col span={isMobile ? 24 : 15}>
            {/* HTML text like <p/> contents inside another <p/> gets warnings */}
            <p>{noSuccessMeasures}</p>
          </Col>
        </Row>
      )}

      {(isSuccessMeasures && visibleReport) && <>
        <div className='expanded-separator'/>
        <Row>
          <Col>
            <i className={`${visibleIcon} color-objective-visible size-icon`}/>
            <span className='objective-body'>Objetivo {visibleText} para tus reportes.</span>
            <i className='icon-me-info-regular primary-icon-color size-icon-info' onClick={handleClickVisibleObjective}/>
          </Col>
        </Row></>
      }

      {/* Success measures button */}
      {!bossParams && editAdvance && (
        <>
          <div className='expanded-separator'/>

          <MLModalWithFixedLayout key={`success-measures-edit-${key}`} {...{
            FormComponent: (props) => (
              <EditObjective {
                ...{
                  ...props,
                  currentQuarter,
                  srcData: { ...data, successMeasuresOnly: true, currentQuarter }
                }
              }/>
            ),
            className: 'pulse-advance-modal',
            title: 'Editar medidas de éxito',
            Header: AdvanceModalHeader,
            OpenButton: MLAddButton,
            onFormSubmit: handleEdit,
            openButtonText: 'Agregar medidas de éxito',
            okButtonText: 'Guardar y salir',
            cancelConfirm: {
              title: '¿Quieres salir sin guardar los cambios?',
              message: 'Perderás la información que hayas ingresado.'
            }
          }}/>
        </>
      )}
    </div>);
  };
  useEffect(() => {
    if ((dataPerformanceWalmart && bossName) || (bossMode && dataPerformanceWalmart)) {
      const boss = { name: bossName };
      const { objectives: { data }, statusByQuarter } = dataPerformanceWalmart;
      // TODO: quitar !isMobile cuando se desarrolle lo de mobile
      const isActionsVisible = !isMobile && actionsVisible;
      const tableInfo = [];
      const getSuccessMeasureFields = (successMeasures) => successMeasures?.map(
        ({ description, dateMeasure, updates }) => ({ description, dateMeasure, updates })
      );

      data.map((d, index) => {
        tableInfo.push({
          id: d._id,
          key: index,
          objectiveName: d.objective?.name,
          objectiveDescription: d.objective?.description,
          pillar: d.objective?.pillar,
          type: getType(d.objective?.pillar.name),
          weight: `${d.weight}%`,
          weightBoss: d.weightBoss && `${d.weightBoss}%`,
          action: 'action',
          visible: d.visible,
          successMeasures: getSuccessMeasureFields(d.successMeasures),
          successMeasuresBoss: getSuccessMeasureFields(d.successMeasuresBoss),
          boss,
          statusByQuarter
        });
        return null;
      });

      const tableColumns = [
        {
          title: () => <div>{!bossMode ? 'Mis' : ''} Objetivos {descriptionMyObjectives}</div>,
          dataIndex: 'objectives',
          key: 'objectives',
          className: `table-td ${isActionsVisible ? 'header-my-objectives-root' : 'header-my-objectives'}`,
          render: (_, dataObj, index) => (
            <div
              className='wrapper-border-objective'
              ref={(el) => { objectiveRefs.current[index] = el; }}
            >
              {borderDiv({
                dimensions,
                index,
                rowsQuantity: data.length,
                type: dataObj.type
              })}
              <div
                className='objective-row'
                key={`objective-row-${index}`}
              >
                {dataObj.objectiveName}
              </div></div>
          )
        },
        {
          title: 'Ponderación',
          dataIndex: 'weight',
          key: 'weight',
          className: `table-td ${isActionsVisible ? 'header-weight-root' : 'header-weight'}`
        }
      ];
      if (isActionsVisible) {
        tableColumns.push({
          title: 'Acciones',
          dataIndex: 'action',
          key: 'action',
          className: 'table-td action-table-td',
          render: (key, user) => (<div
            className='actions-buttons'
            key={`action-${key}`}>
            <MLModalWithFixedLayout key={`action-edit-${key}`} {...{
              FormComponent: (props) => (
                <EditObjective {...{
                  ...props,
                  currentQuarter,
                  srcData: { ...user, successMeasuresOnly: disabled, currentQuarter }
                }}/>
              ),
              ...(disabled ? {
                className: 'pulse-advance-modal',
                title: 'Editar medidas de éxito',
                Header: AdvanceModalHeader
              } : {
                title: 'Editar objetivo'
              }),
              okButtonText: 'Guardar y salir',
              OpenButton: MLLinkButton,
              onFormSubmit: handleEdit,
              openButtonText: 'Editar',
              cancelConfirm: {
                title: '¿Quieres salir sin guardar los cambios?',
                message: 'Perderás la información que hayas ingresado.'
              }
            }}/>
            <ObjectiveDeleteModal {...{
              ...(disabled ? {
                buttons: [
                  {
                    text: 'Entendido',
                    type: 'primary'
                  }
                ],
                icon: 'icon-me-info-regular primary-icon-color',
                title: 'Estos objetivos ya fueron aprobados por tu jefatura',
                message: `Si necesitas modificarlos, envía un mail a gestiondeldesempeno@walmart.com, indicando el motivo y dejando en copia a ${bossName}.`
              } : {
                icon: 'icon-ml-warning pending-icon-color',
                title: 'Eliminar Objetivo',
                message: '¿Seguro que desea eliminar este objetivo?',
                okButtonText: 'Sí, eliminar'
              }),
              type: 'info',
              OpenButton: MLLinkButton,
              defaultCancel: true,
              openButtonText: 'Eliminar',
              handleOk: (events) => handlerApproveDelete(events, user.id)
            }}/>

          </div>)
        });
      }
      const dataTable = ({
        columns: tableColumns,
        srcData: orderBy(tableInfo, ['type'], 'desc')
      });
      setMountedTable(true);
      setDataToTable(dataTable);
    }
  }, [dataPerformanceWalmart, bossName, dimensions]);
  return (<div className='ml-my-evolution-my-performance-objectives-table'>
    <MLTable
      columnAndData={dataToTable}
      expandedRowKeys={expandedRowKeys}
      expandedRowRender={(data, key) => handleExpandedRowRender(data, key)}
      onExpand={(expanded, user) => handleExpand(expanded, user)}
    />

    {addButton}

    {!q1 && (
      <AdvanceComments {...{
        showBossInputComment,
        comments,
        currentQuarter,
        formRef: commentFormRef,
        editAdvance,
        closePerformance,
        isMobile,
        bossParams,
        messages: {
          bossHasNoCommented: {
            current: {
              color: 'warning',
              message: bossParams ? (
                `Aún no has escrito acerca del desempeño de ${bossParams.selectedUser.name}.`
              ) : (
                'Tu jefatura aún no ha escrito acerca de tu desempeño.'
              )
            },
            previous: {
              color: 'info',
              message: bossParams ? (
                'No hubo comentarios por parte de jefatura.'
              ) : (
                'Tu jefatura no escribió acerca de tu desempeño.'
              )
            }
          }
        }
      }}/>
    )}

    <ModalMessage {...{ ...modal }}/>
    <ModalMessage { ...modalMessage }/>
  </div>);
};

MyPerformanceTwoGroupsTable.propTypes = {
  objectiveClosedButAdvanceNotSent: PropTypes.bool,
  actionsVisible: PropTypes.bool,
  editAdvance: PropTypes.bool,
  showComments: PropTypes.bool,
  bossName: PropTypes.string,
  dataPerformanceWalmart: PropTypes.object,
  expandedRowKeys: PropTypes.array,
  handleExpand: PropTypes.func,
  isMobile: PropTypes.bool,
  refetch: PropTypes.func,
  visibleReport: PropTypes.bool,
  commentFormRef: PropTypes.object,
  bossParams: PropTypes.object,
  addButton: PropTypes.element,
  withPulseAndManualObjective: PropTypes.bool,
  showBossInputComment: PropTypes.bool
};

MyPerformanceTwoGroupsTable.defaultProps = {
  objectiveClosedButAdvanceNotSent: false,
  actionsVisible: true,
  editAdvance: false,
  showComments: false,
  bossName: '',
  dataPerformanceWalmart: null,
  expandedRowKeys: [],
  handleExpand: () => null,
  isMobile: false,
  refetch: () => null,
  visibleReport: false,
  commentFormRef: null,
  bossParams: null,
  addButton: <></>,
  withPulseAndManualObjective: false,
  showBossInputComment: false
};
export default MyPerformanceTwoGroupsTable;

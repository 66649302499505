import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloseButton from './buttons/CloseButton';
import '../../static/styles/shared/infoMessage.less';

const InfoMessage = (props) => {
  const {
    message, classes, type, color, suave, closable, onClose
  } = props;

  const [hidden, hide] = useState(false);

  const closeInfoMessage = () => {
    hide(true);
    onClose();
  };

  let mainClass = 'info-message';
  if (suave) mainClass += ` ${mainClass}-suave`;
  if (hidden) mainClass += ' hidden';

  return (
    <div
      className={`
        ${mainClass}${classes ? ` ${classes}` : ''}${color === 'default' ? '' : ` info-message-${color}`}
      `}
    >
      {closable && <CloseButton onClick={closeInfoMessage}/>}

      {type === 'info' && <i className="icon-me-info-bold" />}

      {
        props.children && (props.children)
      }

      {typeof message === 'string' ? (
        <p>{message}</p>
      ) : (
        message
      )}
    </div>
  );
};

InfoMessage.propTypes = {
  message: PropTypes.any,
  classes: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.element,
  suave: PropTypes.bool,
  closable: PropTypes.bool,
  onClose: PropTypes.func
};

InfoMessage.defaultProps = {
  message: '',
  classes: '',
  type: 'info',
  color: 'default',
  children: <></>,
  suave: false,
  closable: false,
  onClose: () => null
};

export default InfoMessage;

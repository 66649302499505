/* eslint-disable max-len */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import InfoCollapse from '../../../shared/collapses/InfoCollapse';

const questions = [
  {
    title: '¿Qué es el feedback?',
    child: <p>Son comentarios y/o conversaciones entre 2 o más personas que ayudan a potenciar tu desarrollo y habilidades buscando ser la mejor versión de ti mismo.</p>
  },
  {
    title: '¿Por qué Feedback Frecuente?',
    child: <p>Para conocer otras miradas, reflexionar y aprender sobre cómo seguir potenciando el desarrollo de tus habilidades y así alcanzar los objetivos comunes.</p>
  },
  {
    title: '¿A quiénes puedo pedir/dar feedback?',
    child: <p>Puedes dar feedback a jefes, pares, clientes, etc, a esto le llamamos feedback 360°. Es clave que hayas trabajado con la o las personas recientemente.</p>
  },
  {
    title: '¿Cuál es la forma correcta para tener una conversación de feedback?',
    child: <>
      <p>
        Para tener un feedback objetivo y completo, te sugerimos tener en consideración lo siguiente:
      </p>
      <ul className="pl-2 mt-2">
        <li>
          <b>Situación</b>:
            Instancia en la cual interactuaste con la otra persona (ej: reunión de trabajo, presentación de proyecto, etc.), sé muy claro, incluso puedes agregar fecha.
            Esto ayuda a la otra persona a situarse en el minuto en que ocurrió la interacción. No pidas feedback generalizado ni amplio, para eso es mejor que coordines
            una reunión 1 a 1. Te sugerimos que no pase más de un mes entre la situación y la fecha en que pidas o entregues el feedback.
        </li>
        <li>
          <b>Competencia</b>:
            Debes seleccionar aquella competencia la cual hayan puesto en práctica en la situación sobre la cual quieres pedir/dar feedback. Te sugerimos que elijas no más de 2 competencias para una misma situación.
        </li>
        <li>
          <b>Descripción</b>:
            Aplica solo cuando das un feedback, y corresponde a lo que observaste en la conducta seleccionada para la situación de la que estás entregando feedback.
        </li>
      </ul>
      <p>
      Estos 3 conceptos ayudarán a que ambas partes tengan contexto y que el mensaje sea efectivo para nuestro desarrollo.
      </p>
    </>
  },
  {
    title: '¿Para qué puedo usar el módulo de Feedback?',
    child: <p>Esta herramienta nace para reforzar la cultura de feedback, pero es importante que sepas que esto no reemplaza las conversaciones podamos tener fuera de la plataforma. Recuerda que, si quieres profundizar algún feedback del módulo, puedes generar una reunión para aclarar las duda que necesites resolver.</p>
  }
];

const FrequentQuestions = () => (
  <div className="">
    {
      questions.map((question) => (
        <InfoCollapse key={uuidv4()} data={question} paneKey={uuidv4()} />
      ))
    }
  </div>
);

export default FrequentQuestions;

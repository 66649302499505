import React from 'react';
import { Row } from 'antd';
import TableComponent from '../../../components/mievolucion/MyPerformance/Performance/TableComponent';
import { useWindowWidthSize } from '../../../helpers/browser';
import { smallDevice } from '../../../helpers/deviceVariables';
import useTableComponent from '../../../hooks/myEvolution/myPerformance/useTableComponent';
import PulsesSteps from '../../../components/mievolucion/MyPerformance/Performance/PulsesComponent';
import ErrorMessage from '../../../components/shared/ErrorMessage';

const Pulsos = () => {
  const width = useWindowWidthSize();
  const isMobile = width <= smallDevice;
  const tableProps = useTableComponent();

  return (
    <div className='ml-my-evloution-my-performance'>
      {
        tableProps.myPerformance.error
          ? <ErrorMessage message={'Ocurrió un error al cargar tu desempeño'} />
          : <div className="">
            <Row>
              <PulsesSteps isMobile={isMobile} loading={tableProps.myPerformance.loading} />
            </Row>
            <Row>
              <TableComponent isMobile={isMobile} tableProps={tableProps} />
            </Row>
          </div>
      }
    </div>
  );
};

export default Pulsos;

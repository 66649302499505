import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MainBtn from '../../../../shared/buttons/MainBtn';
import MLButton from '../../../../shared/buttons/MLButton';
import { STATUS_IDP } from '../../../../../helpers/myEvolution/miDesarrollo';
import MLLoading from '../../../../shared/MLLoading';
import MLError from '../../../../shared/Error';
import MLModal from '../../../../shared/MLModal';
import { IDP_TEMPLATE } from './IDPTemplate';

const Form = ({
  title,
  description,
  children,
  handleSaveIDP,
  status,
  statusActions,
  statusEvaluations,
  statusStrenghts,
  loadingSavingIDP,
  loadingIDP,
  errorIDP,
  filePath,
  IDP
}) => {
  const [showModalIDPPreview, setShowModalIDPPreview] = useState(false);
  if (loadingSavingIDP || loadingIDP) return <MLLoading />;

  if (errorIDP) return <MLError />;

  const hasOtherStatusFinished = statusActions === STATUS_IDP.finished
  && statusEvaluations === STATUS_IDP.finished
  && statusStrenghts === STATUS_IDP.finished;

  return (
    <div>
      <div className='IDP-form'>
        <div className='IDP-form-header'>
          {title}:{' '}
          <span className='IDP-form-header-description'>{description}</span>
        </div>
        <div className='IDP-form-body'>
          {children}
          {
            STATUS_IDP.pending === status
        && <><div className='container-update-button'>
          <MainBtn
            onClick={() => setShowModalIDPPreview(true)}
            text={'Previsualizar IDP '} />
          <MainBtn
            onClick={handleSaveIDP} text={'Guardar'} />
        </div>
        </>
          }

        </div>
      </div>
      {
        STATUS_IDP.pending === status
      && <div className='container-button-finish'>
        <MLButton
          disabled={!hasOtherStatusFinished}
          className='button-finish-IDP'
          onClick={() => handleSaveIDP({ status: STATUS_IDP.finished })}
          text={'Finalizar IDP '} />
      </div>
      }

      {
        status === STATUS_IDP.finished
          && <div className='container-download-IDP'>
            <a href={filePath} target='_blank' rel='noopener noreferrer'>Descargar IDP</a>
          </div>
      }

      { showModalIDPPreview
      && <MLModal {...{
        title: 'Previsualizacíon',
        fullScreen: true,
        visible: showModalIDPPreview,
        closeModal: () => setShowModalIDPPreview(false)
      }}>
        <div dangerouslySetInnerHTML={{ __html: IDP_TEMPLATE(IDP) }}/>
        <div className='container-fluid'>
          <div className='row p-4 justify-content-md-center'>
            <div className='col-sm-12 col-md-4 col-lg-4'>
              <div className='container-update-button mt-3'>
                <MainBtn onClick={() => setShowModalIDPPreview(false)} text={'Cerrar '} />
              </div>
            </div>
          </div>
        </div>
      </MLModal>
      }
    </div>

  );
};
Form.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any,
  handleSaveIDP: PropTypes.func,
  status: PropTypes.string,
  statusActions: PropTypes.string,
  statusEvaluations: PropTypes.string,
  statusStrenghts: PropTypes.string,
  loadingSavingIDP: PropTypes.bool,
  loadingIDP: PropTypes.bool,
  errorIDP: PropTypes.bool,
  filePath: PropTypes.string,
  IDP: PropTypes.object
};

Form.defaultProps = {
  title: '',
  description: '',
  children: <></>,
  handleSaveIDP: () => {},
  status: '',
  statusActions: '',
  statusEvaluations: '',
  statusStrenghts: '',
  loadingSavingIDP: false,
  loadingIDP: false,
  errorIDP: false,
  filePath: null,
  IDP: {}
};

export default Form;

import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { GET_IDP } from '../../../graphql/mievolucion/miDesarrollo/queries';
import { MY_EVOLUTION_CONTEXT } from '../../../helpers/apollo';
import { SAVE_IDP } from '../../../graphql/mievolucion/miDesarrollo/mutations';
import { STATUS_IDP } from '../../../helpers/myEvolution/miDesarrollo';

const context = MY_EVOLUTION_CONTEXT;

const useSaveIDP = ({ setModalMessage }) => {
  const {
    rut: loggedUserRut
  } = useSelector((state) => state.userReducer.data);

  const [callAPI, { loading }] = useMutation(SAVE_IDP, {
    context,
    onCompleted: (response) => {
      const success = get(response, 'saveIDP.success', false);
      const statusIDP = get(response, 'saveIDP.data.status');
      const isPending = statusIDP === STATUS_IDP.pending;
      if (success) {
        setModalMessage({
          visible: true,
          title: isPending ? 'Actualización exitosa' : 'Finalización éxitosa'
        });
      } else {
        setModalMessage({
          type: 'error',
          visible: true,
          title: 'No pudimos enviar tu Plan de Desarrollo',
          message: 'Estamos trabajando en una solución. Puedes volver más tarde e intentarlo de nuevo.'
        });
      }
    },
    onError: (e) => {
      setModalMessage({
        type: 'error',
        visible: true,
        title: e.message || 'Por favor, intente más tarde',
        buttons: [
          {
            text: 'Cerrar',
            type: 'primary',
            onClickButton: () => setModalMessage({ visible: false })
          }
        ]
      });
    },
    refetchQueries: [
      {
        context,
        query: GET_IDP,
        variables: { rut: loggedUserRut }
      }
    ]
  });

  const saveIDP = (IDP) => {
    callAPI({ variables: { input: IDP } });
  };

  return {
    loading,
    saveIDP
  };
};
export default useSaveIDP;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings } from '../../redux/actions/settings';
import { getItem, setItem } from '../../helpers/cookiesUtil';

const useMiLugarEnabled = () => {
  const calledCookie = getItem('milugarEnabledCalled');
  const called = !!calledCookie && JSON.parse(calledCookie);
  const dispatch = useDispatch();
  const settingsReducer = useSelector((state) => state.settingsReducer);
  const defaultVariables = { key: 'ENABLED_MI_LUGAR' };
  const initialState = { ...settingsReducer, variables: defaultVariables, loading: true };
  const [settingsState, setSettingsState] = useState(initialState);

  const {
    data, error, loading, variables
  } = settingsReducer;

  const fetch = () => {
    setItem('milugarEnabledCalled', true);
    dispatch(getSettings({ variables: defaultVariables }));
  };

  useEffect(() => {
    if (variables.key === 'ENABLED_MI_LUGAR') {
      setSettingsState({
        ...settingsState, data, error, loading
      });
    }
  }, [data, error, loading]);

  useEffect(() => {
    if (!settingsState.loading) setItem('milugarEnabledCalled', false);
  }, [settingsState.loading]);

  useEffect(() => {
    setItem('milugarEnabledCalled', false);
  }, []);

  return { ...settingsState, called, fetch };
};

export default useMiLugarEnabled;

import { actionTypes } from '../actions/report';

export const initialState = {
  error: null,
  errorRequest: null
};

function reducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.GET_EXCEL_SUCCESS:
    return {
      ...state,
      ...{ miExcel: action.data }
    };

  case actionTypes.GET_EXCEL_BENEFITS_SUCCESS:
    return {
      ...state,
      ...{ miExcel: action.data }
    };

  case actionTypes.GET_ANALYTIC_SUCCESS:
    return {
      ...state,
      ...{ miReport: action.data }
    };

  case actionTypes.DISMISS_ERROR:
    return {
      ...state,
      error: null,
      errorRequest: null
    };
  default:
    return state;
  }
}

export default reducer;

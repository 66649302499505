import React from 'react';

import { Checkbox } from 'antd';

import '../../../static/styles/shared/forms/checkBoxGroup.less';

const CheckboxGroup = Checkbox.Group;

const CheckBoxGroup = (props) => (
  <CheckboxGroup
    className="ml-check-box-group"
    {...props}
  />
);

export default CheckBoxGroup;

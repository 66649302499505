import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import { humanize } from '../../../../helpers/strings';
import Avatar from '../../Avatar';
import PendingCircle from '../../../solicitudes/shared/PendingCircle';
import { statusClasses, labelStatus } from '../../../../helpers/requests';

const { Item } = List;

const HistoricalListItem = ({
  title,
  subTitle,
  showDate,
  date,
  status = '',
  isActive,
  showPending,
  noIcon,
  noArrow,
  showAvatar = true,
  leftTitle,
  customStatus = null,
  pointer,
  onClick,
  additionalInfo = null,
  customDescription = null,
  onlyShowStatus,
  titleMainColor,
  textBeforeDate,
  showStatusName,
  titleConnectors,
  subTitleConnectors,
  user
}) => {
  const description = showDate || (status && date);
  return (

    <Item
      style={pointer ? { cursor: 'pointer' } : {}}
      className={
        `mievolucion-historical-list-item${isActive ? ' mievolucion-historical-list-item-active' : ''}`
      }
      onClick={onClick}
    >
      <Item.Meta
        className={`meta-${description ? 'with-description' : 'without-description'}`}
        title={
          <Fragment>

            { showAvatar
            && <Avatar />
            }

            <div className='mievolucion-historical-list-item-title-text pr-0'>
              <div className='flex flex-row justify-content-between align-items-start'>
                <h5 className={titleMainColor ? 'title-main-color' : ''}>{humanize(title, titleConnectors)}</h5>
                { leftTitle }
              </div>
              <div className='d-flex flex-row justify-content-between'>
                {subTitle && <h6>{humanize(subTitle, subTitleConnectors)}</h6>}
                {onlyShowStatus
                && <div className='only-show-status'>
                  <span className={statusClasses(status)} />
                </div>
                }
              </div>
              {user?.availableVacations && <div className='d-flex flex-row justify-content-between mt-1'>
                <h6 style={{ fontWeight: 'bold' }}>Saldo de Vacaciones: {Number(user?.availableVacations).toString()}</h6>
              </div>}
              {additionalInfo && additionalInfo }
            </div>

            { !noIcon && (
              <div className='mievolucion-historical-list-item-title-icon'>
                <Fragment>
                  {showPending && status.toLowerCase() === 'pending' ? <PendingCircle/> : <Fragment/>}
                  {noArrow ? <Fragment/> : <i className='icon-ml-forward' />}
                </Fragment>
              </div>
            )}

          </Fragment>
        }
        description={ customDescription || description && (
          <Fragment>

            <div className='mievolucion-historical-list-item-date d-flex align-items-center'>
              <div className='mievolucion-historical-list-item-description-icon'>
                <i className='icon-ml-calendar-2' />
              </div>
              <h6 className='mievolucion-historical-list-item-date-value'>{textBeforeDate} {date}</h6>
            </div>

            <div className='mievolucion-historical-list-item-description-icon'>
              { customStatus
                || <h6 className='d-flex justify-content-center align-items-center'>
                  <i className={statusClasses(status)} />
                  {showStatusName
                  && <span className='pl-2'> {labelStatus(status)}</span>
                  }
                </h6>
              }
            </div>

          </Fragment>
        )}
      />
    </Item>

  );
};

HistoricalListItem.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  showDate: PropTypes.bool,
  date: PropTypes.any,
  showCalendar: PropTypes.bool,
  status: PropTypes.string,
  isActive: PropTypes.bool,
  showPending: PropTypes.bool,
  noIcon: PropTypes.bool,
  noArrow: PropTypes.bool,
  showAvatar: PropTypes.bool,
  leftTitle: PropTypes.element,
  customStatus: PropTypes.element,
  pointer: PropTypes.bool,
  onClick: PropTypes.func,
  onlyShowStatus: PropTypes.bool,
  titleMainColor: PropTypes.bool,
  textBeforeDate: PropTypes.string,
  showStatusName: PropTypes.bool,
  titleConnectors: PropTypes.array,
  subTitleConnectors: PropTypes.array,
  user: PropTypes.object
};

HistoricalListItem.defaultProps = {
  title: '',
  subTitle: '',
  showDate: false,
  date: '',
  showCalendar: false,
  status: '',
  isActive: false,
  showPending: false,
  noIcon: false,
  noArrow: false,
  showAvatar: true,
  leftTitle: <></>,
  customStatus: null,
  pointer: false,
  onClick: () => null,
  onlyShowStatus: false,
  titleMainColor: false,
  textBeforeDate: '',
  showStatusName: true,
  titleConnectors: [],
  subTitleConnectors: [],
  user: {}
};

export default HistoricalListItem;

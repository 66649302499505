import React from 'react';
import { isString } from 'lodash';
import PropTypes from 'prop-types';
import '../../../static/styles/shared/cards/infoCardField.less';

const InfoCardField = (props) => {
  const {
    classNameIcon, title, children, className, inputError
  } = props;

  return (
    <div className={`d-flex flex-row info-card-field ${className} ${inputError && 'input-error'}`}>
      <i className={classNameIcon}/>
      <div className="d-flex flex-column w-100">
        { isString(title) ? <p className='title'>{title}</p> : title}
        {children}
      </div>
    </div>
  );
};

InfoCardField.propTypes = {
  classNameIcon: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
};

InfoCardField.defaultProps = {
  classNameIcon: '',
  id: '',
  title: '',
  children: <></>,
  className: ''
};

export default InfoCardField;

import moment from 'moment';
import { actionTypes } from '../actions/micredencial';

export const initialState = {
  myCredentialProfile: { called: false, loading: false },
  credentialAdmin: { called: false, loading: false },
  myCredentialPicture: {},
  renewCredentialData: {
    status: false,
    description: ''
  },
  error: false,
  stores: [],
  loadingStores: false,
  errorStores: false,
  loadedStores: false
};

function reducer(state = initialState, action) {
  const split = action.type.includes('LOADING') ? 'loading' : action.type;
  switch (split) {
  case 'loading':
    return {
      ...state,
      [action.type]: action.data,
      error: false
    };
  case actionTypes.GET_MY_CREDENTIAL_PROFILE:
    return {
      ...state,
      myCredentialProfile: {
        ...state.myCredentialProfile,
        called: true,
        loading: true,
        error: false
      },
      GET_MY_CREDENTIAL_PROFILE_LOADING: true,
      error: false
    };
  case actionTypes.GET_MY_CREDENTIAL_PROFILE_SUCCESS:
    return {
      ...state,
      myCredentialProfile: {
        ...state.myCredentialProfile,
        ...action.data,
        expiredAt: moment().add(10, 'minutes'),
        loading: false,
        error: false
      },
      GET_MY_CREDENTIAL_PROFILE_LOADING: false,
      error: false
    };
  case actionTypes.GET_MY_CREDENTIAL_PROFILE_ERROR:
    return {
      ...state,
      myCredentialProfile: {
        ...state.myCredentialProfile,
        loading: false,
        error: action.error.message
      },
      GET_MY_CREDENTIAL_PROFILE_LOADING: false,
      error: true
    };
  case actionTypes.GET_USER_CREDENTIAL_ADMIN:
    return {
      ...state,
      credentialAdmin: {
        loading: true,
        error: false
      }
    };
  case actionTypes.GET_USER_CREDENTIAL_ADMIN_SUCCESS:
    return {
      ...state,
      credentialAdmin: {
        expiredAt: moment().add(10, 'minutes'),
        called: true,
        show: action.payload,
        loading: false,
        error: false
      }
    };
  case actionTypes.GET_USER_CREDENTIAL_ADMIN_ERROR:
    return {
      ...state,
      credentialAdmin: {
        loading: false,
        called: true,
        error: true
      }
    };
  case actionTypes.GET_MY_CREDENTIAL_PICTURE_SUCCESS:
    return {
      ...state,
      myCredentialPicture: action.data,
      error: false
    };
  case actionTypes.RENEW_CREDENTIAL_RESPONSE:
    return {
      ...state,
      renewCredentialData: action.data,
      error: false
    };
  case actionTypes.ERROR:
    return {
      ...state,
      error: true
    };

  case actionTypes.LIST_STORES:
    return {
      ...state,
      loadingStores: true,
      errorStores: false,
      loadedStores: false
    };
  case actionTypes.LIST_STORES_SUCCESS:
    return {
      ...state,
      loadingStores: false,
      stores: action.data.listStores.map((store) => ({
        store: store.idStore
      })),
      loadedStores: true
    };
  case actionTypes.LIST_STORES_FAILURE:
    return {
      ...state,
      loadingStores: false,
      errorStores: true,
      loadedStores: false
    };
  default:
    return state;
  }
}

export default reducer;

import * as Cookies from 'js-cookie';

const in12Hours = 12 / 24;

export function setItem(name, value) {
  return Cookies.set(name, value, { expires: in12Hours });
}

export function getItem(name) {
  return Cookies.get(name);
}

export function removeItem(name) {
  return Cookies.remove(name);
}

export function clearAll() {
  Object.keys(Cookies.get()).forEach((cookie) => {
    if (!cookie.includes('milugar_session')) {
      Cookies.remove(cookie);
    }
  });
  return true;
}

import React, { useState } from 'react';
import { Tooltip } from 'antd-v4';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import '../../../static/styles/shared/Profile/profileHeader.less';
import { isMobile } from 'react-device-detect';

const ProfileHeader = (props) => {
  const {
    listHeader,
    title,
    children,
    className,
    collapseProfile
  } = props;
  const [showProfileData, setShowProfileData] = useState(false);

  const InfoProfile = map(listHeader, (data, i) => (
    <div key={i} className='item-profile'>
      <div className='d-flex flex-row justify-content-center align-items-center'>
        <span className='title'>{data.label}</span>
        {
          data.tooltip
            && <Tooltip
              placement='right'
              title={data.messageTooltip}
              className='ml-2 cursor-pointer'
            >
              <i className='icon-ml-question primary-icon-color' />
            </Tooltip>
        }
      </div>
      {
        data.customValue || <span className='text-value'>{data.value}</span>
      }
    </div>
  ));

  if (isMobile && collapseProfile) {
    return (
      <div>
        <div className={`profile-header ${className}`}>
          <div className='d-flex flex-column flex-sm-row w-100'>
            <div onClick={() => setShowProfileData(!showProfileData)} className='title-profile'>
              <h3>{title}</h3>
              <i data-testid='ml-arrow' className={`icon-ml-arrow ${showProfileData ? 'profile-opened' : 'profile-closed'}`} />
            </div>
            {showProfileData
            && <div className='body-profile-header'>
              {InfoProfile}
              {children}
            </div>
            }
          </div>
        </div>
      </div>

    );
  }

  return (
    <div className={`profile-header ${className}`}>
      <div className='d-flex flex-column flex-sm-row w-100'>
        <div className='title-profile'>
          <h3>{title}</h3>
        </div>
        {InfoProfile}
        {children}
      </div>
    </div>
  );
};

ProfileHeader.propTypes = {
  listHeader: PropTypes.array,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.element,
  collapseProfile: PropTypes.bool
};

ProfileHeader.defaultProps = {
  listHeader: [],
  title: 'Mi Perfil',
  children: null,
  className: '',
  collapseProfile: false
};
export default ProfileHeader;
